.#{$component-name} {
  &.esg-humanrights {
    .track-animation {
      &__cover {
        background-image: url(/img/pc/esg-humanrights-main.png);
      }

      &__dimmed {
        background-color: #c3e8b9;

        &.#{$showClass} {
          opacity: 1;
        }
      }

      &__highlight-area {
        width: 1136px;
        text-align: center;
      }
    }

    

    .anchor-section{
      .section{
        &__title{
          .text-highlight{
            text-wrap: nowrap;
          }
        }
        &__desc {
          padding-right: 60px;
        }
      }

      .js-nav-section {
        .section__right-box {
          .section__title {
            &.pc_text {
              display: block;
            }
            &.mo_text {
              display: none;
            }
          }
        }
      }

    }

    .ir-common__button {
      height: 34px;
      margin-top: 40px;
      padding-left: 46px;
      font-weight: 800;
      font-size: 18px;
      line-height: 1.89;
      letter-spacing: -0.5px;

      &::before {
        width: 30px;
        height: 30px;
      }

      &:last-child {
        margin-left: 38px;
      }
    }

    .esg-humanrights {

      &__btn{
        &.kor{
          display: block;
        }
        &.eng{
          display: none;
        }
        @at-root .navercorp.esg-humanrights.lang-en {
          .esg-humanrights__btn.kor{
            display: none;
          }
          .esg-humanrights__btn.eng{
            display: block;
          }
        }
      }

      &__img {
        width: 100%;
        height: 644px;
        margin-top: 70px;
        background-image: url(../img/pc/esg-policy.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &__text {
        margin-top: 80px;
        font-weight: 800;
        font-size: 26px;
        line-height: 1.54;
        letter-spacing: -0.8px;
        color: var(--default);
      }
    }

    .js-nav-section {
      &.policy, &.organization {
        max-width: 1920px;
        padding: 0;
      }

      &.policy {
        padding-top: 45px;
      }

      &.organization {
        padding-top: 180px;

        .esg-humanrights {


          &__img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 720px;
            background-color: #007dff;
            background-image: unset;
            margin-top: 30px;

            &:after {
              display: block;
              width: 1038px;
              height: 600px;
              background-image: url(/img/pc/esg-organization.png);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              content: '';
            }

            @at-root .navercorp.esg-humanrights.lang-en  {
              .js-nav-section.organization .esg-humanrights__img:after {
                background-image: url(/img/pc/esg-organization-en.png);
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.esg-humanrights {
      .esg-humanrights {
        &__img {
          height: 540px;
        }

        &__text {
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
        }
      }

      .anchor-section {
        .section {
          &__desc {
            padding-right: 40px;
          }
        }
      }

      .js-nav-section {
        &.policy {
          padding-top: 120px;
        }

        &.organization {
          padding-top: 160px;

          .esg-humanrights {
            &__img {
              height: 620px;

              &:after {
                width: 870px;
                height: 503px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.esg-humanrights {
      .track-animation {
        &__highlight-area {
          width: 910px;
        }
        &__highlight-text {
          text-align: center;
        }
      }

      .section__right-box {
        max-width: none;
      }

      .esg-humanrights {
        &__img {
          height: 576px;
        }
      }

      .js-nav-section {
        &.policy {
          .ir-common__button {
            margin-left: 30px;
          }

          .section {
            &__right-box {
              padding: 0;
            }

            &__title {
              padding: 0 30px;
            }

            &__desc {
              padding: 0 30px;
            }
          }
        }

        &.organization {
          padding-top: 140px;

          .esg-humanrights {
            &__img {
              height: 576px;

              &:after {
                width: 800px;
                height: 463px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.esg-humanrights {
      .track-animation {
        &__highlight-area {
          width: 648px;
        }
        &__highlight-text {
          text-align: left;
        }
      }

      .ir-common__button {
        height: 24px;
        margin-top: 14px;
        padding-left: 31px;
        font-size: 16px;
        line-height: normal;
        letter-spacing: -0.5px;

        &:first-child {
          margin-top: 30px;
        }

        &:before {
          width: 24px;
          height: 24px;
        }
      }

      .esg-humanrights {
        &__img {
          height: 432px;
          margin-top: 60px;
        }

        &__text {
          margin-top: 60px;
          font-weight: 700;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.4px;
        }
      }

      .js-nav-section {
        &.organization {
          padding-top: 120px;

          .esg-humanrights {
            &__img {
              height: 708px;
              margin-top: 20px;

              &:after {
                width: 360px;
                height: 607px;
                background-image: url(/img/mo/esg-organization-mo.png);
              }

              @at-root .navercorp.esg-humanrights.lang-en  {
                .js-nav-section.organization .esg-humanrights__img:after {
                  background-image: url(/img/mo/esg-organization-mo-en.png);
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.esg-humanrights {
      .track-animation {
        &__cover {
          background-image: url(/img/mo/esg-humanrights-main-mo.png);
        }

        &__highlight-area {
          width: auto;
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 27px;
        }
      }

      .ir-common__button {
        &:last-child {
          margin-top: 15px;
        }
      }

      .esg-humanrights {
        &__img {
          height: 375px;
          margin-top: 80px;
        }
      }

      .anchor-section {
        .js-nav-section {
          .section__right-box {
            .section__title {
              &.pc_text {
                display: none;
              }
              &.mo_text {
                display: block;
              }
            }
          }
        }
      }

      .js-nav-section {

        &.policy {
          padding-top: 85px;

          .section {
            &__title {
              padding: 0 20px;
            }

            &__desc {
              padding: 0 20px;
            }
          }

          .ir-common__button {
            margin-left: 20px;
          }
        }

        &.organization {
          padding-top: 100px;

          .esg-humanrights {
            &__img {
              height: 600px;

              &:after {
                width: 300px;
                height: 506px;
              }
            }
          }
        }
      }
    }
  }
}
