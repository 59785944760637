.#{$component-name} {
  &.esg-environment {
    .track-animation {
      &__dimmed {
        background-color: #00ce82;

        &.#{$showClass} {
          opacity: 1;
        }
      }

      &__cover {
        background-image: url(/img/pc/environment-img.png);
      }

      &__highlight-area {
        text-align: center;
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 40px;
      height: 100%;
    }

    .anchor-nav__menu {
      .btn-report {
        display: block;
        margin-top: 13px;
        padding: 13px 14px 13px 16px;
        background-color: $color-default;
        color: $color-white;
        font-size: 12px;
        font-weight: 700;

        &:hover {
          background-color: $color-point;
        }

        &.type-eng {
          display: none;
        }

        @at-root .navercorp.lang-en .anchor-nav__menu .btn-report {
          &.type-kor {
            display: none;
          }
          &.type-eng {
            display: block;
          }
        }
      }
    }

    .js-nav-section {
      padding: 45px 228px 100px 227px;

      .cont__label {
        height: auto;
        padding: 0;
        background: none;
        font-size: 22px;
        font-weight: 800;
        line-height: 34px;
        letter-spacing: -0.01px;
        color: var(--default);

        &+.section__title {
          margin-top: 20px;
        }
      }
    }

    .section {
      &__title {
        font-size: 62px;
        line-height: 88px;

        &-wrap {
          text-align: center;
        }
      }

      &__sub-title {
        margin-top: 140px;
        font-size: 48px;
        font-weight: 800;
        line-height: 68px;
        letter-spacing: -1.5px;
        text-align: center;
        color: var(--default);
      }

      &__desc {
        margin-top: 40px;
      }
    }

    .cont {
      &__box {
        margin-top: 60px;
      }

      &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;

        &+.cont__list {
          margin-top: 20px;
        }

        &.type-full {
          .cont__item {
            flex-direction: row;
            justify-content: space-between;
            grid-column: 1/3;
            min-height: 652px;
          }
        }

        &.type-multi {
          .cont__item {
            &:first-child {
              grid-column: 1/2;
              grid-row: 1/3;
            }
          }
        }

        &.swiper-wrapper {
          display: flex;
        }
      }

      &__item {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: 398px;
        padding: 40px;
        box-shadow: 0 0 59px 10px rgba(0,0,0,.04);
        background-color: $color-white;
        background-color: var(--reverse);

        &.text-center {
          justify-content: center;
          text-align: center;
          align-items: center;
        }

        &.text-white {
          .title, .desc {
            color: $color-white;
          }
        }

        .text-box, .thumb-box {
          position: relative;
          z-index: 1;
          width: 70%;
        }

        .thumb-box {
          flex-shrink: 0;
        }

        .bg-thumb-box {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;

          video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;

            &.pc_show {
              &.type-eng {
                display: none;
              }

              @at-root .navercorp.lang-en {
                .cont__item .bg-thumb-box video.pc_show {
                  &.type-kor {
                    display: none;
                  }
                  &.type-eng {
                    display: block;
                  }
                }
              }
            }

          }
        }

        .title {
          font-size: 38px;
          font-weight: 800;
          line-height: 54px;
          letter-spacing: -1.5px;
          color: var(--default);

          em {
            font-size: 64px;
            line-height: 60px;
            letter-spacing: -1.5px;
          }
        }

        .desc {
          margin-top: 30px;
          color: #6e6e73;
          font-size: 22px;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: -.6px;
          color: var(--color-sub03);
        }

        .sup {
          color: inherit;
          font-size: 14px;
          font-weight: 700;
          line-height: 1;
          vertical-align: super;
        }

        .step {
          position: absolute;
          top: 24px;
          left: 24px;
          width: 92px;
          padding: 0 15px;
          font-size: 16px;
          font-weight: 800;
          line-height: 44px;
          text-align: center;
          background-color: rgba(17, 194, 145, 0.2);
          color: var(--color-sub);
        }
      }
    }

    .outlink {
      display: inline-block;
      position: absolute;
      left: 40px;
      bottom: 40px;
      z-index: 1;
      border-radius: 50%;
      @include sp-desktop('ic-outlink-b');
      background-color: var(--outlinkBg);

      &:hover {
        background-color: $color-point;
      }

      &.black {
        @include sp-desktop('ic-outlink-w');
        background-color: var(--outlinkBg02);

        &:hover {
          background-color: #666;
        }
      }

      &.white {
        @include sp-desktop('ic-outlink-circle-w');
        background-color: transparent;

        &:hover {
          opacity: .6;
        }
      }

      &.top {
        bottom: auto;
        top: 40px;
      }

      &.right {
        left: auto;
        right: 40px;
      }
      
      &-mo {
        display: none;
      }
    }

    .content04 {
      text-align: center;

      .btn-download {
        display: inline-block;
        margin-top: 40px;
        padding: 16px 25px;
        color: $color-white;
        font-size: 14px;
        letter-spacing: -.2px;
        background-color: var(--color-sub02);
        font-weight: 700;
        transition: background .3s;

        &:hover {
          background-color: $color-point;
        }
      }
    }

    .content05 {
      overflow: hidden;

      .type-full {
        .cont__item {
          background-color: #0bc190;
        }

        .thumb-box {
          top: 50%;
          right: 20px;
          width: 581px;
          height: 523px;
          transform: translateY(-50%);
        }

        .desc {
          width: 100%;
        }
      }

      .type-multi {
        .cont__item {
          min-height: 405px;
        }
      }

      .ico-forest {
        .title::before {
          display: block;
          width: 270px;
          height: 248px;
          margin: 0 auto 30px;
          background: url(/img/pc/esg-envi-illust02.png) no-repeat center;
          background-size: 100% auto;
          content: '';
        }
        
        .title{ 
          font-size: 64px;
          font-weight: 800;
          line-height: 64px;
          letter-spacing: -1.6px;
          color: var(--default);
          .desc{
            font-size: 64px;
            line-height: 88px;
            letter-spacing: -2px;
          }
        }
      }

      .ico-water {
        .title::after {
          display: inline-block;
          width: 88px;
          height: 116px;
          background: url(/img/pc/esg-envi-illust03.png) no-repeat center;
          background-size: 100% auto;
          vertical-align: middle;
          content: '';
        }
      }

      .ico-tree {
        .title::after {
          display: inline-block;
          width: 73px;
          height: 116px;
          margin-left: 6px;
          background: url(/img/pc/esg-envi-illust04.png) no-repeat center;
          background-size: 100% auto;
          vertical-align: middle;
          content: '';
        }
      }

      .swiper {
        margin: 0 -60px;

        &-wrapper {
          padding: 60px 40px;
          width: 100%;
        }

        &-slide {
          justify-content: flex-start;
          width: calc((100% - 160px)/3);
          min-height: 655px;
          padding: 94px 24px 24px;
          box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.04);
          text-align: center;

          .desc {
            margin-top: 36px;
          }

          .image {
            display: block;
            width: 393px;
            height: 328px;
            margin: 0 auto;
          }

          .outlink {
            left: 24px;
            bottom: 24px;
          }
        }

        &-pagination {
          display: none;
        }

      }
    }

    .content06 {
      .type-full {
        .cont__item {
          background-color: #1e73fd;
        }

        .thumb-box {
          top: 50%;
          right: 51px;
          width: 515px;
          height: 465px;
          transform: translateY(-50%);
        }

        .desc {
          width: 400px;
        }
      }

      .cont__wrap {
        .cont__item {
          &:first-child {
            min-height: 915px;
            background: url(/img/pc/esg-envi-illust09.png) no-repeat center bottom 78px;
            background-size: 427px 395px;
            background-color: var(--reverse);

            .desc {
              width: 545px;
            }
          }

          &:nth-of-type(2) {
            min-height: 830px;
            background: url(/img/pc/esg-envi-illust10.png) no-repeat center bottom 68px;
            background-size: 429px 396px;
            background-color: var(--reverse);
          }

          &:nth-of-type(3) {
            .title {
              position: relative;

              &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 419px;
                height: 148px;
                background: url(/img/pc/esg-envi-illust11.png) no-repeat center;
                background-size: 100% auto;
                transform: translate(-50%, -50%);
                content: '';
              }
            }
          }

          &:nth-of-type(4) {
            .title {
              position: relative;
              width: fit-content;

              &::after {
                position: absolute;
                top: -30px;
                right: -36px;
                width: 52px;
                height: 67px;
                background: url(/img/pc/esg-envi-illust12.png) no-repeat center;
                background-size: 100% auto;
                content: "";
                @at-root .navercorp.esg-environment.lang-en {
                  .content06 .cont__wrap .cont__item:nth-of-type(4) .title::after {
                    top: -21px;
                    right: -54px;
                  }
                }
              }

              .underline {
                position: relative;

                &:after {
                  position: absolute;
                  left: -13px;
                  bottom: -16px;
                  width: 180px;
                  height: 10px;
                  background: url(/img/pc/esg-envi-illust13.png) no-repeat center;
                  background-size: 100% auto;
                  content: "";

                  @at-root .navercorp.esg-environment.lang-en {
                    .content06 .cont__wrap .cont__item:nth-of-type(4) .underline::after {
                      left: 5px;
                      bottom: -10px;
                      width: 258px;
                      height: 8px;
                    }
                  }

                }
              }
            }
          }
        }
      }
    }

    .content07 {
      .title {
        .row {
          display: block;
          margin-top: 30px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .cont__list {
        &:first-child {
          .cont__item {
            &:first-child {
              width: 100%;
              height: 100%;
              background: url(/img/pc/esg-envi-gak.png) no-repeat center;
              background-size: 100% cover;
            }

            &:nth-of-type(2) {
              .title {
                &::after {
                  display: inline-block;
                  width: 88px;
                  height: 110px;
                  margin-top: -18px;
                  margin-left: 10px;
                  background: url(/img/pc/esg-envi-illust14.png) no-repeat center;
                  background-size: 100% auto;
                  vertical-align: middle;
                  content: '';
                }
              }
            }

            &:nth-of-type(3) {
              width: 100%;
              height: 100%;
              background: url(/img/pc/esg-envi-green.png) no-repeat center;
              background-size: 100% auto;
            }
          }
        }

        &:nth-of-type(2) {
          .cont__item {
            &:first-child {
              width: 100%;
              height: 100%;
              background: url(/img/pc/esg-envi-eco.png) no-repeat center;
              background-size: 100% auto;
            }

            &:nth-of-type(2) {
              .title {
                &::after {
                  display: inline-block;
                  width: 90px;
                  height: 110px;
                  margin-left: 15px;
                  background: url(/img/pc/esg-envi-illust15.png) no-repeat center;
                  background-size: 100% auto;
                  vertical-align: middle;
                  content: '';
                }
              }
            }

            &:nth-of-type(3) {
              .title em {
                font-size: 60px;
                line-height: 83px;
                letter-spacing: -1.9px;

                &::after {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  width: 516px;
                  height: 99px;
                  background: url(/img/pc/esg-envi-illust16.png) no-repeat center;
                  background-size: 100% auto;
                  transform: translate(-45%, -73%);
                  content: '';
                }
              }
            }
          }
        }
      }

      .cont__item {
        &:nth-of-type(2) {
          .title {
            font-size: 0;

            em {
              font-weight: 900;
              vertical-align: middle;
              font-size: 64px;
              line-height: 88px;
              letter-spacing: -0.01;
            }

            .unit {
              margin-left: 10px;
              font-size: 50px;
              letter-spacing: -.01px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .content08 {
      overflow: hidden;
      padding-bottom: 160px;

      .swiper {
        overflow: unset;
        margin-top: 0;

        &-wrapper {
          padding: 80px 0;
        }

        &-pagination {
          font-weight: 800;
          color: var(--default);
        }

        &-navigation {
          position: absolute;
          left: 50%;
          bottom: 0;
          z-index: 10;
          width: 176px;
          transform: translateX(-50%);
        }

        .swiper-button-prev, .swiper-button-next {
          top: auto;
          bottom: 0;
          width: 40px;
          height: 40px;
          background: var(--btn-circle-arrow-left) no-repeat center;
          background-size: 100% auto;

          &:hover, &.is-active
            {
            background-image: url(/img/common/btn-circle-arrow-left-hover.png);
          }

          &::after {
            content: none;
          }
        }

        .swiper-button-next {
          transform: rotate(180deg);

          &:hover, &.is-active {
            transform: rotate(180deg);
            background-image: url(/img/common/btn-circle-arrow-left-hover.png);
          }
        }
      }

      .cont__item {
        flex-direction: row;
        justify-content: space-between;
        width: 1345px;
        min-height: 710px;

        .title {
          font-size: 38px;
          line-height: 54px;
          letter-spacing: -1.5px;
        }

        .point {
          display: block;
          color: #0dcb67;
        }

        &:first-child {
          background: url(/img/pc/esg-envi-story01.png) no-repeat center;
          background-size: 100% auto;

          .thumb-box {
            top: 39px;
            right: 69px;
            width: 735px;
            height: 551px;
          }
        }

        &:nth-of-type(2) {
          background: url(/img/pc/esg-envi-story02.png) no-repeat center;
          background-size: 100% auto;
        }

        &:nth-of-type(3) {
          background: url(/img/pc/esg-envi-story03.png) no-repeat center;
          background-size: 100% auto;
        }
      }
    }

    .content09 {
      overflow: hidden;
      position: relative;
      max-width: 100%;
      padding: 0;
      background-color: #77DCCC;

      .section__title-wrap {
        position: absolute;
        top: 110px;
        left: 50%;
        z-index: 10;
        transform: translateX(-50%);
      }

      .cont__label {
        padding: 6px 22px;
        background-color: #23adf2;
        color: $color-white;

        &+.section__title {
          margin-top: 30px;
        }
      }

      .event-spot-pc {
        height: 1128px;

        .spot-box {
          position: absolute;
          left: 50%;
          top: 0;
        }
      }

      .bg-thumb-box {
        overflow: hidden;
        position: relative;
        top: 0;
        left: 50%;
        width: 1677px;
        height: 100%;
        transform: translateX(-50%);

        video {
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .event-spot-mo {
        display: none;
      }

      .spot-link {
        position: absolute;
        // animation: ani_moving 2s ease-in-out infinite 0.2s;
        z-index: 10;

        &::before {
          display: block;
          transition: opacity 0.2s linear;
          content: '';
        }
      }

      .spot1 {
        top: 207px;
        right: 420px;

        @at-root .navercorp.lang-en {
          .content09 .spot1 {
            top: 207px;
            right: 390px;
          }
        }

        &::before {
          width: 196px;
          height: 63px;
          background: url(/img/pc/esg-event-spot-link01.png) no-repeat center;
          background-size: 100% auto;
        }

        &:hover {
          &::before {
            background: url(/img/pc/esg-event-spot-link01-hover.png) no-repeat center;
            background-size: 100% auto;
          }
        }

        @at-root .navercorp.lang-en {
          .content09 {
            .spot1 {
              &::before {
                width: 229px;
                height: 63px;
                background: url(/img/pc/esg-event-spot-link01-en.png) no-repeat center;
                background-size: 100% auto;
              }
      
              &:hover {
                &::before {
                  background: url(/img/pc/esg-event-spot-link01-hover-en.png) no-repeat center;
                  background-size: 100% auto;
                }
              }
            } 
          }
        }
      }

      .spot2 {
        top: 540px;
        left: 50%;
        margin-left: -162px;

        &::before {
          width: 152px;
          height: 63px;
          background: url(/img/pc/esg-event-spot-link02.png) no-repeat center;
          background-size: 100% auto;
        }

        &:hover {
          &::before {
            background: url(/img/pc/esg-event-spot-link02-hover.png) no-repeat center;
            background-size: 100% auto;
          }
        }

        @at-root .navercorp.lang-en {
          .content09 {
            .spot2 {
              &::before {
                width: 236px;
                height: 63px;
                background: url(/img/pc/esg-event-spot-link02-en.png) no-repeat center;
                background-size: 100% auto;
              }
      
              &:hover {
                &::before {
                  background: url(/img/pc/esg-event-spot-link02-hover-en.png) no-repeat center;
                  background-size: 100% auto;
                }
              }
            } 
          }
        }
      }

      .spot3 {
        top: 431px;
        left: 330px;

        &::before {
          width: 182px;
          height: 63px;
          background: url(/img/pc/esg-event-spot-link03.png) no-repeat center;
          background-size: 100% auto;
        }

        &:hover {
          &::before {
            background: url(/img/pc/esg-event-spot-link03-hover.png) no-repeat center;
            background-size: 100% auto;
          }
        }

        @at-root .navercorp.lang-en {
          .content09 {
            .spot3 {
              &::before {
                width: 194px;
                height: 63px;
                background: url(/img/pc/esg-event-spot-link03-en.png) no-repeat center;
                background-size: 100% auto;
              }
      
              &:hover {
                &::before {
                  background: url(/img/pc/esg-event-spot-link03-hover-en.png) no-repeat center;
                  background-size: 100% auto;
                }
              }
            } 
          }
        }
      }

      .spot-notice {
        position: absolute;
        left: 50%;
        bottom: 40px;
        z-index: 10;
        padding: 14px 22px;
        border-radius: 8px;
        background-color: #655bff;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: 26px;
        text-align: center;
        white-space: nowrap;
        transform: translateX(-50%);
      }
    }

    .content10 {
      padding: 40px 0 0;

      .report-sentence {
        .cont__item {
          min-height: 744px;
          box-shadow: none;
          background-color: #0ac090;
        }

        .mo_show {
          display: none;
        }
      }

      .report-info {
        max-width: 1345px;
        margin: 80px auto 0;

        .cont__item {
          min-height: 338px;
          box-shadow: none;
          border: 1.2px solid;
          background-color: var(--background01);
          border-color: var(--border02);

          &:hover {
            border-color: var(--blackTit);
          }

          .title {
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -1px;
          }

          .desc {
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -.8px;
            color: var(--color-sub03);
          }
        }
      }

      .report-notice {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        max-width: 1345px;
        height: 455px;
        margin: 100px auto 0;
        background-color: rgb(210, 249, 224);

        &::before, &::after {
          content: "";
          position: absolute;
        }

        &::before {
          top: 53px;
          left: 32px;
          width: 103px;
          height: 93px;
          background: url(/img/pc/esg-envi-twinkle.png) no-repeat center;
          background-size: contain;
        }

        &::after {
          top: 200px;
          right: 27px;
          width: 94px;
          height: 123px;
          background: url(/img/pc/esg-envi-water.png) no-repeat center;
          background-size: contain;
        }

        .text-box {
          position: relative;
          z-index: 1;
          text-align: center;
        }

        .title {
          font-size: 62px;
          font-weight: 800;
          line-height: 88px;
          letter-spacing: -2px;
        }

        .desc {
          margin-top: 30px;
          color: $color-default;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: -.6px;
          line-height: 34px;
          text-align: center;
        }

        .btn-wrap {
          justify-content: center;
          margin-top: 40px;
        }

        .btn-report {
          position: relative;
          margin-left: 20px;
          padding: 13px 30px;
          background-color: $color-default;
          color: $color-white;
          font-size: 18px;
          font-weight: 800;
          line-height: 30px;
          letter-spacing: -0.5px;
          transition: all .3s;

          &:hover {
            background: $color-point;
          }

          &:first-child {
            margin-left: 0;
          }

          &.type-text {
            padding: 13px 48px 13px 20px;
            background-color: transparent;
            color: $color-default;
            transition: all .3s;

            &:hover {
              color: $color-point;
              transition: background .3s;
              &::after {
                @include sp-desktop('ic-arrow-right-on');
              }
            }
  
            &::after {
              position: absolute;
              top: 50%;
              right: 16px;
              @include sp-desktop('ic-arrow-right');
              transform: translateY(-50%);
              content: '';
            }
          }
        }

        .video-box {
          [class*='bg'] {
            position: absolute;
          }

          .bg1 {
            bottom: -36px;
            left: 5px;
            width: 401px;
            height: 244px;
          }

          .bg2 {
            bottom: -30px;
            right: 283px;
            width: 174px;
            height: 136px;
          }

          .bg3 {
            top: -17px;
            right: 122px;
            width: 183px;
            height: 226px;
          }
        }
      }
    }

    .accordion {
      max-width: 1345px;
      margin: 100px auto 0;

      &__list {
        background-color: var(--bgColor);
      }

      &__item {
        &.is-active {
          .accordion__title::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }

      &__title {
        position: relative;
        width: 100%;
        padding: 30px 104px 30px 30px;
        font-size: 20px;
        font-weight: 800;
        line-height: 32px;
        letter-spacing: -.5px;
        text-align: left;
        color: var(--color-sub05);

        &::after {
          position: absolute;
          right: 40px;
          top: 50%;
          @include sp-desktop('ic-dropdown-gray');
          transform: translateY(-50%);
          content: '';
        }
      }

      &__content-inner {
        padding: 0 40px 50px 30px;
      }

      .notice-item {
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -.5px;
        color: var(--graphTitle);
      }
    }
  }
}

.theme-dark {
  .navercorp.esg-environment {
    .outlink {
      @include sp-desktop('ic-outlink-w');

      &.white {
        @include sp-desktop('ic-outlink-circle-w');
        background-color: transparent;
      }
    }

    .content07 {
      .outlink {
        @include sp-desktop('ic-outlink-circle-w');
        background-color: transparent;
      }
    }

    .content08 {
      .outlink {
        @include sp-desktop('ic-outlink-b');
        background-color: #dcdbdc;

      }
    }

    .accordion {
      &__title {
        &:after {
          @include sp-desktop('ic-dropdown-white');
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.esg-environment {
      .js-nav-section {
        padding: 50px 193px 100px 192px;

        .cont__label {
          font-size: 20px;

          &+.section__title {
            margin-top: 14px;
          }
        }
      }

      .section {
        &__title {
          font-size: 48px;
          line-height: 68px;
        }

        &__sub-title {
          margin-top: 120px;
          font-size: 42px;
          line-height: 64px;
          letter-spacing: -1.8px;
        }

        &__desc {
          margin-top: 36px;
          line-height: 1.55;
        }
      }

      .cont {
        &__list {
          &.type-full {
            .cont__item {
              min-height: 550px;
            }
          }
        }

        &__item {
          min-height: 335px;
          padding: 36px;

          .title {
            font-size: 32px;
            line-height: 54px;
            letter-spacing: -1.5px;

            em {
              font-size: 64px;
              line-height: 60px;
              letter-spacing: -1.5px;
            }
          }

          .desc {
            margin-top: 20px;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -.5px;
          }

          .sup {
            font-size: 12px;
          }

          .step {
            width: 74px;
            padding: 0 10px;
            font-size: 14px;
            line-height: 37px;
          }
        }
      }

      .outlink {
        left: 36px;
        bottom: 36px;

        &.top {
          top: 36px;
        }

        &.right {
          right: 36px;
        }
      }

      .content04 {
        .btn-download {
          margin-top: 30px;
        }
      }

      .content05 {
        .type-full {
          .thumb-box {
            width: 490px;
            height: 441px;
          }

          .desc {
            width: 100%;
          }
        }

        .type-multi {
          .cont__item {
            min-height: 340px;
          }
        }

        .ico-forest {
          .title::before {
            width: 227px;
            height: 208px;
            margin: 0 auto 18px;
          }
        }

        .ico-water {
          .title::after {
            width: 74px;
            height: 97px;
          }
        }

        .ico-tree {
          .title::after {
            width: 61px;
            height: 97px;
            margin-left: 3px;
          }
        }

        .swiper {
          &-slide {
            min-height: 550px;
            padding: 81px 24px 24px;
            box-shadow: 0 0 50px 20px rgba(0, 0, 0, 0.04);

            .image {
              width: 330px;
              height: 275px;
            }
          }
        }
      }

      .content06 {
        .type-full {
          .thumb-box {
            right: 41px;
            width: 435px;
            height: 392px;
          }

          .desc {
            width: 318px;
          }
        }

        .cont__wrap {
          .cont__item {
            &:first-child {
              min-height: 787px;
              background-position: center bottom 66px;
              background-size: 360px 333px;

              .desc {
                width: 459px;
              }
            }

            &:nth-of-type(2) {
              min-height: 700px;
              background-position: center bottom 59px;
              background-size: 360px 333px;
            }

            &:nth-of-type(3) {
              .title {
                &::after {
                  width: 405px;
                  height: 132px;
                }
              }
            }

            &:nth-of-type(4) {
              .title {
                &::after {
                  top: -21px;
                  right: -33px;
                  width: 44px;
                  height: 56px;
                  @at-root .navercorp.esg-environment.lang-en {
                    .content06 .cont__wrap .cont__item:nth-of-type(4) .title::after {
                      top: -14px;
                      right: -48px;
                    }
                  }
                }

                .underline {
                  &:after {
                    left: -8px;
                    bottom: -14px;
                    width: 144px;
                    height: 8px;

                  @at-root .navercorp.esg-environment.lang-en {
                    .content06 .cont__wrap .cont__item:nth-of-type(4) .underline::after {
                      left: 6px;
                      bottom: -10px;
                      width: 210px;
                      height: 8px;
                    }
                  }
                  }
                }
              }
            }
          }
        }
      }

      .content07 {
        .title {
          .row {
            margin-top: 26px;
          }
        }

        .cont__list {
          &:first-child {
            .cont__item {
              &:nth-of-type(2) {
                .title {
                  &::after {
                    width: 74px;
                    height: 93px;
                    margin-top: -18px;
                    margin-left: 10px;
                  }
                }
              }
            }
          }

          &:nth-of-type(2) {
            .cont__item {
              &:nth-of-type(2) {
                .title {
                  &::after {
                    width: 76px;
                    height: 93px;
                    margin-left: 5px;
                  }
                }
              }

              &:nth-of-type(3) {
                .title em {
                  font-size: 50px;
                  line-height: 70px;
                  letter-spacing: -1.6px;

                  &::after {
                    width: 435px;
                    height: 83px;
                  }
                }
              }
            }
          }
        }

        .cont__item {
          &:nth-of-type(2) {
            .title {
              .unit {
                margin-left: 4px;
                font-size: 46px;
              }
              em {
                font-size: 62px;
                line-height: 88px;
                letter-spacing: -0.01px;
              }
            }
          }
        }
      }

      .content08 {
        padding-bottom: 160px;
        .swiper-wrapper {
          padding-bottom: 80px;
        }

        .cont__item {
          width: 1135px;
          min-height: 600px;

          .title {
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -1px;
          }


          &:first-child {
            .thumb-box {
              top: 30px;
              right: 58px;
              width: 620px;
              height: 465px;
            }
          }
        }
      }

      .content09 {
        max-width: 1520px;
        padding: 0;

        .section__title-wrap {
          top: 110px;
        }

        .cont__label {
          padding: 2px 20px;
          font-size: 18px;

          &+.section__title {
            margin-top: 30px;
          }
        }

        .event-spot-pc {
          height: 940px;
        }

        .spot1 {
          top: 173px;
          right: 347px;

          @at-root .navercorp.lang-en {
            .content09 .spot1 {
              top: 173px;
              right: 322px;
            }
          }

          &::before {
            width: 163px;
            height: 52px;
          }

          @at-root .navercorp.lang-en {
            .content09 {
              .spot1 {
                &::before {
                  width: 192px;
                  height: 52px;
                }
              } 
            }
          }
        }

        .spot2 {
          top: 450px;
          margin-left: -135px;

          &::before {
            width: 127px;
            height: 52px;
          }

          @at-root .navercorp.lang-en {
            .content09 {
              .spot2 {
                &::before {
                  width: 198px;
                  height: 52px;
                }
              } 
            }
          }
        }

        .spot3 {
          top: 360px;
          left: 280px;

          &::before {
            width: 152px;
            height: 52px;
          }

          @at-root .navercorp.lang-en {
            .content09 {
              .spot3 {
                &::before {
                  width: 161px;
                  height: 52px;
                }
              } 
            }
          }
        }
        
        .bg-thumb-box {
          width: 1396px;
          video {
            margin-left: 1px;
          }
        }
        
      }

      .content10 {
        padding: 30px 0 0;

        .report-sentence {
          .cont__item {
            min-height: 628px;
          }
        }

        .report-info {
          max-width: 1135px;
          margin: 70px auto 0;

          .cont__item {
            min-height: 285px;
            padding: 28px 36px;

            .title {
              font-size: 26px;
              line-height: 38px;
              letter-spacing: -.8px;
            }

            .desc {
              font-size: 22px;
              letter-spacing: -.6px;
            }

            .outlink {
              right: 30px;
              bottom: 30px;
            }
          }
        }

        .report-notice {
          max-width: 1135px;
          height: 384px;

          &::before {
            top: 46px;
            left: 27px;
            width: 86px;
            height: 78px;
          }

          &::after {
            top: 170px;
            right: 24px;
            width: 78px;
            height: 103px;
          }

          .title {
            font-size: 48px;
            line-height: 68px;
            letter-spacing: -1.5px;
          }

          .desc {
            margin-top: 20px;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -.5px;
          }

          .btn-wrap {
            margin-top: 36px;
          }

          .btn-report {
            margin-left: 18px;
            padding: 10px 24px 9px;
            font-size: 16px;
            line-height: 28px;

            &.type-text {
              padding: 10px 35px 9px 18px;

              &::after {
                right: 10px;
              }
            }
          }

          .video-box {
            .bg1 {
              bottom: -27px;
              height: 206px;
            }

            .bg2 {
              bottom: -32px;
              right: 218px;
              height: 125px;
            }

            .bg3 {
              right: 78px;
              height: 205px;
            }
          }
        }
      }

      .accordion {
        margin-top: 80px;
        max-width: 1135px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.esg-environment {
      .js-nav-section {
        padding: 120px 50px 0;

        .cont__label {
          font-size: 16px;
          line-height: 30px;

          &+.section__title {
            margin-top: 14px;
          }
        }
      }

      .track-animation {
        &__highlight-text {
          padding: 0 60px;
          text-align: center;
        }
      }

      .section {
        &__sub-title {
          margin-top: 80px;
          font-size: 38px;
          line-height: 54px;
          letter-spacing: -1.5px;
        }

        &__desc {
          margin-top: 30px;
          font-size: 22px;
          line-height: 34px;
          letter-spacing: -.6px;
        }
      }

      .cont {
        &__box {
          margin-top: 60px;
        }

        &__list {
          gap: 24px;

          &.type-full {
            .cont__item {
              min-height: 427px;

              .desc {
                font-size: 18px;
                line-height: 30px;
              }
            }
          }
        }

        &__item {
          min-height: 260px;

          .title {
            font-size: 26px;
            line-height: 38px;
            letter-spacing: -0.8px;

            em {
              font-size: 42px;
              line-height: 48px;
              letter-spacing: -1.5px;
            }
          }

          .desc {
            margin-top: 16px;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.5px;
          }

          .sup {
            font-size: 11px;
          }

          .step {
            padding: 0 14px;
            font-size: 13px;
            line-height: 35px;
          }
        }
      }

      .content04 {
        .btn-download {
          margin-top: 30px;
        }
      }

      .content05 {
        .type-full {
          .thumb-box {
            right: 0;
            width: 380px;
            height: 343px;
          }
        }

        .type-multi {
          .cont__item {
            min-height: 260px;
          }
        }

        .ico-forest {
          .title::before {
            width: 182px;
            height: 166px;
            margin: 0 auto 20px;
          }
          .title{
            font-size: 42px;
          }
        }

        .ico-water {
          .title::after {
            width: 58px;
            height: 76px;
          }
        }

        .ico-tree {
          .title::after {
            width: 47px;
            height: 75px;
            margin-left: 6px;
          }
        }

        .swiper {
          margin: 0 -50px;

          &-wrapper {
            padding: 50px;
          }

          &-slide {
            width: calc((100% - 148px)/3);
            min-height: 427px;
            padding: 68px 15px 24px;
            box-shadow: 0 0 39px 16px rgba(0, 0, 0, 0.04);

            .image {
              width: 264px;
              height: 220px;
            }

            .desc {
              margin-top: 11px;
            }
          }

          &-pagination {
            display: none;
          }

        }
      }

      .content06 {
        .type-full {
          .thumb-box {
            right: 0;
            width: 380px;
            height: 342px;
          }
        }

        .cont__wrap {
          .cont__item {
            &:first-child {
              min-height: 662px;
              background-position: center bottom 40px;
              background-size: 280px 259px;

              .desc {
                width: 363px;
              }
            }

            &:nth-of-type(2) {
              min-height: 565px;
              background-position: center bottom 40px;
              background-size: 280px 259px;
            }

            &:nth-of-type(3) {
              .title {
                &::after {
                  width: 287px;
                  height: 100px;
                }
              }
            }

            &:nth-of-type(4) {
              .title {
                &::after {
                  top: -18px;
                  right: -23px;
                  width: 35px;
                  height: 45px;
                  @at-root .navercorp.esg-environment.lang-en {
                    .content06 .cont__wrap .cont__item:nth-of-type(4) .title::after {
                      top: -13px;
                      right: -37px;
                    }
                  }
                }

                .underline {
                  &:after {
                    left: -5px;
                    bottom: -12px;
                    width: 117px;
                    height: 7px;

                    @at-root .navercorp.esg-environment.lang-en {
                      .content06 .cont__wrap .cont__item:nth-of-type(4) .underline::after {
                    left: 5px;
                    bottom: -9px;
                    width: 179px;
                    height: 9px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .content07 {
        .title {
          .row {
            margin-top: 15px;
          }
        }

        .cont__list {
          &:first-child {
            .cont__item {
              &:nth-of-type(2) {
                .title {
                  &::after {
                    width: 51px;
                    height: 64px;
                    margin-top: -14px;
                    margin-left: 0;
                  }
                }
              }
            }
          }

          &:nth-of-type(2) {
            .cont__item {
              &:nth-of-type(2) {
                .title {
                  &::after {
                    width: 53px;
                    height: 65px;
                    margin-left: 4px;
                  }
                }
              }

              &:nth-of-type(3) {
                .title em {
                  font-size: 38px;
                  line-height: 54px;
                  letter-spacing: -1.5px;

                  &::after {
                    width: 300px;
                    height: 58px;
                    transform: translate(-50%, -60%);
                  }
                }
              }
            }
          }
        }

        .cont__item {
          &:nth-of-type(2) {
            .title {
              .unit {
                margin-left: 6px;
                font-size: 36px;
                line-height: 54px;
              }
              em {
                font-size: 48px;
                line-height: 68px;
                letter-spacing: -1.5px;
              }
            }
          }
        }
      }

      .content08 {
        .cont__item {
          width: 828px;
          min-height: 418px;

          .title {
            font-size: 22px;
            line-height: 34px;
            letter-spacing: -.6px;
          }

          &:first-child {
            .thumb-box {
              top: 6px;
              right: 24px;
              width: 400px;
              height: 300px;
            }
          }
        }
      }

      .content09 {
        max-width: none;
        margin-top: 140px;
        padding: 0;

        .section__title-wrap {
          top: 80px;
        }

        .cont__label {
          font-size: 18px;
          line-height: 34px;
          padding: 2px 19px;

          &+.section__title {
            margin-top: 24px;
            width: 550px;
          }
        }

        .event-spot-pc {
          display: none;
        }

        .event-spot-mo {
          display: block;
          height: 940px;

          .swiper {
            overflow: unset;
            height: 100%;
          }

          .swiper-slide {
            background-repeat: no-repeat;
          }

          .slide1 {
            width: 288px !important;
            background-image: url(/img/pc/esg-event-spot01.png);
            background-size: 100% auto;
          }

          .slide2 {
            width: 1024px !important;
            background-image: url(/img/pc/esg-event-spot02.png);
            background-size: 100% auto;
          }

          .slide3 {
            width: 288px !important;
            background-image: url(/img/pc/esg-event-spot03.png);
            background-size: 100% auto;
          }
        }

        .spot1 {
          top: 107px;
          left: 80px;
        }

        .spot2 {
          top: 479px;
          margin-left: -223px;
        }

        .spot3 {
          top: 356px;
          left: -177px;
        }

        .bg-thumb-box {
          width: 100%;
          video {
            margin-left: 0;
          }
        }
      }

      .content10 {
        padding: 0 50px;

        .report-sentence {
          .cont__item {
            min-height: 377px;
          }
        }

        .report-info {
          max-width: none;
          margin-top: 50px;

          .cont__item {
            min-height: 221px;
            padding: 24px;

            .title {
              font-size: 22px;
              line-height: 34px;
              letter-spacing: -.6px;
            }

            .desc {
              margin-top: 12px;
              color: #6e6e73;
              font-size: 16px;
              line-height: 28px;
              letter-spacing: -.5px;
            }

            .outlink {
              right: 24px;
              bottom: 24px;
            }
          }
        }

        .report-notice {
          max-width: none;
          height: 498px;
          margin-top: 80px;

          &::before {
            top: 55px;
            left: 69px;
            width: 76px;
            height: 68px;
          }

          &::after {
            top: auto;
            bottom: 74px;
            right: 45px;
          }

          .video-box {
            .bg1 {
              left: 30px;
              bottom: -19px;
              height: 196px;
            }

            .bg2 {
              right: 143px;
              bottom: -15px;
            }

            .bg3 {
              top: -21px;
              right: 21px;
              height: 201px;
            }
          }
        }
      }

      .accordion {
        max-width: none;
        margin-top: 60px;

        &__title {
          padding: 30px 104px 30px 40px;
        }

        &__content-inner {
          padding: 0 40px 50px 40px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.esg-environment {
      .track-animation {
        &__highlight-area {
          max-width: 770px;
          text-align: left;
        }
        &__highlight-text {
          text-align: left;;
        }
      }

      .js-nav-section {
        padding: 120px 36px 0;

        .cont__label {
          &+.section__title {
            margin-top: 10px;
          }
        }
      }

      .section {
        &__title {
          font-size: 42px;
          line-height: 64px;
          letter-spacing: -1.8px;

          &-wrap {
            &+.cont__box, &+.cont__wrap {
              margin-top: 40px;
            }
          }
        }

        &__sub-title {
          margin-top: 50px;
          font-size: 32px;
          line-height: 48px;
          letter-spacing: -1px;

          &+.cont__box {
            margin-top: 40px;
          }
        }

        &__desc {
          margin-top: 20px;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: -.5px;
        }
      }

      .cont {
        &__box {
          margin-top: 20px;
        }

        &__list {
          gap: 20px;

          &.type-full {
            .cont__item {
              min-height: 322px;

              .desc {
                font-size: 16px;
                line-height: 28px;
              }
            }
          }
        }

        &__item {
          min-height: 240px;
          padding: 24px;

          .title {
            font-size: 22px;
            line-height: 34px;
            letter-spacing: -.6px;

            em {
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -1px;
            }
          }

          .desc {
            margin-top: 14px;
            font-size: 14px;
            line-height: 28px;
            letter-spacing: -0.5px;
            min-width: 184px;
          }

          .sup {
            font-size: 9px;
          }

          .step {
            top: 18px;
            left: 18px;
            width: 57px;
            padding: 0 10px;
            font-size: 10px;
            line-height: 26px;
          }
        }
      }

      .outlink {
        left: 24px;
        bottom: 24px;
        &-pc {
          display: none;
        }
        &-mo {
          display: block;
        }
      }

      .content05 {
        .type-full {
          .thumb-box {
            width: 320px;
            height: 289px;
          }

          .desc {
            width: 100%;
          }
        }

        .type-multi {
          .cont__item {
            min-height: 195px;

            .title {
              em {
                font-size: 42px;
                line-height: 54px;
              }
            }
          }
        }

        .ico-forest {
          .title::before {
            width: 146px;
            height: 133px;
            margin: 0 auto 30px;
          }
          .title{
            font-size: 42px;
          }
        }

        .ico-water {
          .title::after {
            width: 46px;
            height: 61px;
            margin-top: -3px;
            margin-left: -5px;
          }
        }

        .ico-tree {
          .title::after {
            width: 38px;
            height: 61px;
            margin-left: 8px;
          }
        }

        .swiper {
          margin: 0 -40px;

          &-wrapper {
            padding: 40px;
          }

          &-slide {
            width: calc((100% - 120px)/3);
            min-height: 322px;
            padding: 48px 18px 18px;
            box-shadow: 0 0 29px 10px rgba(0,0,0,.04);

            .image {
              width: 170px;
              height: 142px;
            }

            .desc {
              margin-top: 15px;
              line-height: 24px;
            }

            .outlink {
              left: 18px;
              bottom: 18px;
            }
          }

          &-pagination {
            text-align: center;
            font-size: 0;

            &-bullet {
              display: inline-block;
              width: 10px;
              height: 10px;
              margin: 0 5px;
              border-radius: 5px;
              background-color: #d8d8d8;
              opacity: 1;
            }
            &-bullet-active {
              background-color: #565656;
            }  
          }
          

        }
      }

      .content06 {
        .type-full {
          .thumb-box {
            right: 11px;
            width: 290px;
            height: 261px;
          }
        }

        .cont__wrap {
          .cont__item {
            &:first-child {
              min-height: 546px;
              background-position: center bottom 35px;
              background-size: 200px 185px;

              .desc {
                width: 279px;
              }
            }

            &:nth-of-type(2) {
              min-height: 457px;
              background-position: center bottom 41px;
              background-size: 200px 185px;
            }

            &:nth-of-type(3) {
              .title {
                &::after {
                  width: 245px;
                  height: 79px;
                }
              }
            }

            &:nth-of-type(4) {
              .title {
                &::after {
                  right: -19px;
                  width: 28px;
                  height: 46px;
                  @at-root .navercorp.esg-environment.lang-en {
                    .content06 .cont__wrap .cont__item:nth-of-type(4) .title::after {
                      top: -13px;
                      right: -25px;
                    }
                  }
                }

                .underline {
                  &:after {
                    left: -3px;
                    bottom: -13px;
                    width: 97px;
                    height: 12px;
                    @at-root .navercorp.esg-environment.lang-en {
                      .content06 .cont__wrap .cont__item:nth-of-type(4) .underline::after {
                      left: 3px;
                      bottom: -11px;
                      width: 155px;
                      height: 13px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .content07 {
        .cont__list {
          .cont__item {
            min-height: 196px;
            padding: 22px 24px;
          }

          &:first-child {
            .cont__item {
              &:nth-of-type(2) {
                .title {
                  &::after {
                    width: 44px;
                    height: 55px;
                    margin-top: -14px;
                    margin-left: 0;
                  }
                  .unit {
                    font-size: 30px;
                    line-height: 48px;
                    letter-spacing: -0.01px;
                  }

                  em {
                    font-size: 42px;
                    line-height: 64px;
                    letter-spacing: -1.8px;
                  }
                }
              }
            }
          }

          &:nth-of-type(2) {
            .cont__item {
              &:nth-of-type(2) {
                .title {
                  &::after {
                    width: 50px;
                    height: 61px;
                    margin-left: 0;
                  }
                }
                .unit {
                  font-size: 30px;
                  line-height: 48px;
                  letter-spacing: -0.01px;
                }

                em {
                  font-size: 42px;
                  line-height: 64px;
                  letter-spacing: -1.8px;
                }
              }

              &:nth-of-type(3) {
                .title em {
                  font-size: 32px;
                  line-height: 42px;
                  letter-spacing: -1px;

                  &::after {
                    width: 222px;
                    height: 48px;
                    transform: translate(-50%, -90%);
                  }
                }
              }
            }
          }
        }

        .cont__item {
          &:nth-of-type(2) {
            .title {
              .unit {
                margin-left: 8px;
                font-size: 30px;
                line-height: 48px;
              }
            }
          }
        }
      }

      .content08 {
        .swiper {
          &-wrapper {
            padding: 40px 0 78px;
          }
        }

        .cont__item {
          width: 629px;
          min-height: 318px;
          box-shadow: 0 0 27px 11px rgba(0, 0, 0, 0.04);

          .title {
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -.5px;
          }

          .outlink {
            top: 24px;
            right: 24px;
          }

          &:first-child {
            .thumb-box {
              top: 13px;
              right: 14px;
              width: 300px;
              height: 225px;
            }
          }
        }
      }

      .content09 {
        margin-top: 100px;
        padding: 0;

        .cont__label {
          padding: 0 16px;
          font-size: 12px;
          line-height: 28px;

          &+.section__title {
            margin-top: 15px;
          }
        }

        .event-spot-mo {
          height: 810px;

          .slide1 {
            width: 372px !important;
            background-image: url(/img/mo/esg-event-spot01-tb.png);
            background-size: 100% auto;
          }

          .slide2 {
            width: 768px !important;
            background-image: url(/img/mo/esg-event-spot02-tb.png);
            background-size: 100% auto;
          }

          .slide3 {
            width: 372px !important;
            background-image: url(/img/mo/esg-event-spot03-tb.png);
            background-size: 100% auto;
          }
        }

        .spot1 {
          top: 47px;
          left: 135px;
        }

        .spot2 {
          top: 363px;
          margin-left: -192px;
        }

        .spot3 {
          top: 259px;
          left: -105px;
        }
      }

      .content10 {
        padding: 0 36px;

        .report-sentence {
          margin-top: 40px;

          .cont__item {
            min-height: 285px;
          }
        }

        .report-info {
          margin-top: 40px;

          .cont__item {
            min-height: 166px;
            padding: 22px 24px;

            .title {
              font-size: 20px;
              line-height: 32px;
              letter-spacing: -.5px;
            }

            .desc {
              margin-top: 8px;
              color: var(--color-sub03);
              font-size: 14px;
              line-height: 22px;
              letter-spacing: -.3px;
            }
          }
        }

        .report-notice {
          height: 375px;

          &::before {
            top: 34px;
            left: 47px;
            width: 69px;
            height: 62px;
          }

          &::after {
            bottom: 66px;
            right: 30px;
            width: 62px;
            height: 82px;
          }

          .title {
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -1px;
          }

          .desc {
            margin-top: 14px;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.5px;
            width: 640px;
          }

          .btn-wrap {
            margin-top: 33px;
            ;
          }

          .btn-report {
            margin-left: 14px;
            padding: 12px 22px;
            font-size: 14px;
            line-height: normal;
            letter-spacing: -.3px;

            &.type-text {
              padding: 12px 27px 10px 14px;

              &::after {
                right: 8px;
                @include sp-mobile('ic-arrow-right');
              }
            }
          }

          .video-box {
            .bg1 {
              left: 28px;
              bottom: -12px;
              height: 142px;
            }

            .bg2 {
              right: 70px;
              bottom: -12px;
              height: 103px;
            }

            .bg3 {
              top: -18px;
              right: -20px;
              height: 148px;
            }
          }
        }
      }

      .accordion {
        margin-top: 60px;

        &__title {
          padding: 21px 84px 21px 30px;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -.3px;

          &::after {
            right: 30px;
          }
        }

        &__content-inner {
          padding: 3px 30px 50px 30px;
        }

        .notice-item {
          font-size: 14px;
          line-height: 26px;
          letter-spacing: -.6px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.esg-environment {
      .track-animation {
        &__cover {
          background-image: url(/img/mo/environment-img.png);
        }

        &__highlight-area {
          max-width: 375px;
          text-align: left;
        }

        &__highlight-text {
          padding: 0 20px;
        }
      }

      .anchor-section{
        video {
          position: absolute;
          top: 0;
          left: auto;
          height: 100%;
        }
      }

      .js-nav-section {
        padding: 80px 20px 0;
        width: 100%;

        .cont__label {
          font-size: 14px;
          line-height: 28px;
          letter-spacing: normal;

          &+.section__title {
            margin-top: 6px;
          }
        }
      }

      .section {
        &__title {
          font-size: 32px;
          line-height: 48px;
          letter-spacing: -1px;
            .text-highlight {
              white-space: nowrap;
            }
        }

        &__sub-title {
          margin-top: 40px;
          font-size: 28px;
          line-height: 44px;
          letter-spacing: -1px;
        }

        &__desc {
          margin-top: 24px;
          padding: 0 30px;
        }
      }

      .cont {
        &__box {
          margin-top: 40px;
        }

        &__list {
          display: flex;
          flex-wrap: wrap;
          gap: unset;

          &.type-full {
            .cont__item {
              flex-direction: column;
              min-height: 505px;
            }
          }

          &.swiper-wrapper {
            flex-wrap: nowrap;
          }
        }

        &__item {
          width: 100%;
          padding: 24px;
          box-shadow: 0 0 50px 20px rgba(0, 0, 0, 0.04);

          .title {
            em {
              font-size: 32px;
              line-height: 40px;
              letter-spacing: -1px;
            }
          }

          &.text-center {
            .desc {
              margin-top: 8px;
              font-size: 14px;
              line-height: 26px;
              letter-spacing: -0.3px;
            }
          }

          .text-box, .thumb-box {
            width: 100%;
          }

          .text-box {
            position: relative;
            z-index: 2;
          }

          .title {
            font-size: 26px;
            line-height: 34px;
            letter-spacing: -.6px;
            @at-root .navercorp.esg-environment.lang-en {
              .cont__item .title {
                font-size: 22px;
              }
            }
          }

          .desc {
            margin-top: 16px;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -.5px;
            @at-root .navercorp.esg-environment.lang-en {
              .cont__item .desc {
                font-size: 14px;
              }
            }
          }

          .step {
            top: 20px;
            left: 20px;
            width: 74px;
            font-size: 14px;
            line-height: 37px;
          }
        }
      }

      .content05 {
        .type-full {
          .thumb-box {
            top: auto;
            right: -50%;
            bottom: 24px;
            width: 300px;
            height: 271px;
            transform: translateX(-50%);

          }
        }

        .type-multi {
          .cont__item {
            padding: 33px 24px;
            margin-top: 15px;

            &:first-child {
              margin-top: 0;
            }

            .title {
              em {
                font-size: 42px;
                line-height: 64px;
              }
            }

            .desc {
              margin-top: 4px;
              line-height: 26px;
            }
          }
        }

        .cont__item {
          @at-root .navercorp.esg-environment.lang-en {
            .content05 .cont__list.type-full .cont__item {
              min-height: 550px;
            }
          }
        }

        .ico-forest {
          .title::before {
            width: 104px;
            height: 95px;
            margin: 0 auto 19px;
          }
          .title{ 
            font-size: 42px;
        }
      }

        .ico-water {
          .title::after {
            width: 39px;
            height: 63px;
          }
        }

        .ico-tree {
          .title::after {
            width: 39px;
            height: 63px;
            margin-left: 4px;
          }
        }

        .swiper {
          &-wrapper {
            padding: 40px 0 40px;
            gap: unset;
          }

          &-slide {
            width: 315px;
            min-height: 450px;
            padding: 81px 17px 20px;

            .desc {
              margin-top: 5px;
              color: $color-default;
              font-size: 18px;
              line-height: 30px;
              @at-root .theme-dark & {
                color: var(--color-sub03);
              }
            }

            .image {
              width: 280px;
              height: 234px;
            }

            .outlink {
              left: 20px;
              bottom: 20px;
            }

            &:first-child {
              margin-right: 8px;
            }
          }

          .cont__item + .cont__item {
            margin-left: 7px;
            margin-right: 8px;
          } 
          
          &-pagination {
            display: block;
            text-align: center;
            font-size: 0;

            &-bullet {
              display: inline-block;
              width: 10px;
              height: 10px;
              margin: 0 5px;
              border-radius: 5px;
              background-color: #d8d8d8;
              opacity: 1;
            }
            &-bullet-active {
              background-color: #565656;
            }  
          }
          
          
        }
      }

      .content06 {
        .type-full {
          .thumb-box {
            top: auto;
            right: -50%;
            bottom: 41px;
            width: 244px;
            height: 220px;
            transform: translateX(-50%);
          }
        }

        .cont__wrap {
          .cont__box {
            margin-top: 20px;
          }

          .cont__item {
            min-height: 217px;
            margin-top: 15px;

            @at-root .navercorp.esg-environment.lang-en {
              .content06 .cont__list.type-full .cont__item {
                min-height: 610px;
              }
            }

            &:first-child {
              min-height: 503px;
              background-position: center bottom 35px;
              background-size: 254px 235px;
              margin-top: 0;
              @at-root .navercorp.esg-environment.lang-en {
                .content06 .cont__wrap .cont__item:first-child {
                  min-height: 550px;
                }
              }

              .desc {
                width: auto;
                font-size: 14px;
              }
            }

            &:nth-of-type(2) {
              min-height: 503px;
              background-position: center bottom 70px;
              background-size: 238px 220px;

              @at-root .navercorp.esg-environment.lang-en {
                .content06 .cont__wrap .cont__item:nth-of-type(2) {
                  min-height: 590px;
                }
              }
            }

            &:nth-of-type(3) {
              .title {
                &::after {
                  width: 263px;
                  height: 80px;
                }
              }
            }

            
           

            &:nth-of-type(4) {
              .title {
                &::after {
                  top: -12px;
                  right: -17px;
                  width: 28px;
                  height: 36px;
                  @at-root .navercorp.esg-environment.lang-en {
                    .content06 .cont__wrap .cont__item:nth-of-type(4) .title::after {
                      top: -8px;
                      right: -25px;                  
                    }
                  }
                }

                .underline {
                  &:after {
                    left: -5px;
                    bottom: -9px;
                    width: 115px;
                    height: 6px;
                    @at-root .navercorp.esg-environment.lang-en {
                      .content06 .cont__wrap .cont__item:nth-of-type(4) .title .underline:after {
                        width: 150px;
                        height: 17px;
                        top: 21px;
                        left: 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .content07 {
        .title {
          .row {
            margin-top: 20px;
          }
        }

        .cont__list {
          .cont__item {
            min-height: 217px;
            padding: 24px;

            &.text-center {
              padding: 24px 20px;
            }
          }

          &:first-child {
            .cont__item {
              &:first-child {
                min-height: 520px;
                background: url(/img/mo/esg-envi-gak.png) no-repeat center;
                background-size: 100% auto;
              }

              &.text-center {
                margin: 32px 0 20px 0;
              }

              &:nth-of-type(2) {
                .title {
                  &::after {
                    width: 40px;
                    height: 50px;
                    margin-top: -10px;
                  }
                  .unit {
                    font-size: 30px;
                    line-height: 48px;
                    letter-spacing: -0.01px;
                  }
                  em {
                    font-size: 40px;
                    line-height: 54px;
                    letter-spacing: -0.01px;
                  }
                }
              }

              &:nth-of-type(3) {
                min-height: 305px;
                background: url(/img/mo/esg-envi-green.png) no-repeat center;
                background-size: 100% auto;
              }
            }
          }

          &:nth-of-type(2) {
            .cont__item {
              &:first-child {
                min-height: 520px;
                background: url(/img/mo/esg-envi-eco.png) no-repeat center;
                background-size: 100% auto;
              }

              &.text-center {
                margin-top: 20px;
              }

              &:nth-of-type(2) {
                .title {
                  &::after {
                    width: 50px;
                    height: 61px;
                    margin-left: 0;
                  }
                  .unit {
                    font-size: 30px;
                    line-height: 48px;
                    letter-spacing: -0.01px;
                  }
                  em {
                    font-size: 40px;
                    line-height: 54px;
                    letter-spacing: -0.01px;
                  }
                }
                
              }

              &:nth-of-type(3) {
                .title em {
                  font-size: 32px;
                  line-height: 48px;
                  letter-spacing: -1px;

                  &::after {
                    width: 236px;
                    height: 70px;
                    transform: translate(-50%, -60%);
                  }
                }
              }
            }
          }
        }

        .cont__item {
          &:nth-of-type(2) {
            .title {
              .unit {
                margin-left: 6px;
              }
            }
          }
        }
      }

      .content08 {
        .swiper-wrapper {
          padding-bottom: 70px;
          width: 100%;
          padding-right: 20px;
        }

        .cont__item {
          width: 315px;
          min-height: 450px;

          .text-box {
            margin-top: 35px;
          }

          .title {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.5px;
          }

          &:first-child {
            background: url(/img/mo/esg-envi-story01.png) no-repeat center;
            background-size: 100% auto;
            left: 0;
            margin-right: 8px;


            .thumb-box {
              position: absolute;
              top: auto;
              bottom: 38px;
              right: 50%;
              width: 278px;
              height: 209px;
              transform: translateX(50%);
            }
          }

          &:nth-of-type(2) {
            background: url(/img/mo/esg-envi-story02.png) no-repeat center;
            background-size: 100% auto;
          }

          &:nth-of-type(3) {
            background: url(/img/mo/esg-envi-story03.png) no-repeat center;
            background-size: 100% auto;
          }

          .outlink {
            top: 20px;
            right: 20px;
          }
        }

        .cont__item + .cont__item {
          margin-left: 7px;
          margin-right: 8px;
        }
      }

      .content09 {
        padding: 0;

        .section__title-wrap {
          top: 30px;
          width: 100%;
        }

        .cont__label {
          padding: 0 10px;
          font-size: 14px;

          &+.section__title {
            width: 370px;
            margin: 17px auto;
          }
        }

        .event-spot-mo {
          height: 617px;

          .slide1 {
            width: 269px !important;
            background-image: url(/img/mo/esg-event-spot01-m.png);
            background-size: 100% auto;
          }

          .slide2 {
            width: 375px !important;
            background-image: url(/img/mo/esg-event-spot02-m.png);
            background-size: 100% auto;
          }

          .slide3 {
            width: 269px !important;
            background-image: url(/img/mo/esg-event-spot03-m.png);
            background-size: 100% auto;
          }
        }

        .spot1 {
          top: 93px;
          left: 73px;

          &::before {
            zoom: 0.7;
          }
        }

        .spot2 {
          top: 287px;
          margin-left: -122px;

          &::before {
            zoom: 0.7;
          }
        }

        .spot3 {
          top: 224px;
          left: -22px;

          &::before {
            zoom: 0.7;
          }
        }

        .spot-notice {
          left: 70px;
          right: 70px;
          bottom: 21px;
          max-width: 400px;
          padding: 10px 22px;
          margin: 0 auto;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -.3px;
          white-space: inherit;
          transform: none;
        }
      }

      .content10 {
        padding: 0 20px;

        .report-sentence {

          .cont__item {
            min-height: 536px;
          }

          .pc_show {
            display: none !important;
          }

          .mo_show {
            display: block;
            width: auto;
            height: 100%;
            margin: 0 auto;
            &.type-kor {
              display: block;
            }
            &.type-eng {
              display: none;
            }

            @at-root .navercorp.lang-en {
              .cont__item .bg-thumb-box .mo_show {
                &.type-kor {
                  display: none;
                }
                &.type-eng {
                  display: block;
                }
              }
            }


          }
        }

        .report-info {
          margin-top: 50px;

          .cont__list {
            gap: 20px;
          }

          .cont__item {
            min-height: 195px;

            .title {
              font-size: 22px;
              line-height: 34px;
              letter-spacing: -.6px;
            }

            .outlink {
              right: 20px;
              bottom: 20px;
            }
          }
        }

        .report-notice {
          height: 596px;

          &::before {
            top: 37px;
            left: 29px;
            width: 61px;
            height: 54px;
          }

          &::after {
            bottom: 49px;
            right: 13px;
          }

          .title {
            text-align: center;
          }

          .desc {
            padding: 0 15px;
            width: 390px;
            margin: 16px auto;
          }

          .btn-wrap {
            flex-direction: row;
            margin-top: 30px;
          }

          .video-box {
            .bg1 {
              left: 10px;
              height: 136px;
            }

            .bg2 {
              display: none;
            }

            .bg3 {
              top: -44px;
              right: -5px;
              height: 174px;
            }
          }
        }
      }

      .accordion {
        margin-top: 50px;

        &__title {
          padding: 21px 64px 21px 20px;

          &::after {
            right: 20px;
          }
        }

        &__content-inner {
          padding: 6px 20px 30px 20px;
        }

        .notice-item {
          letter-spacing: -.5px;
        }
      }
    }
  }
}
