.#{$component-name} {
  &.service-all {
    .section {
      padding-left: 455px;
    }

    .tab__area {
      margin-bottom: 60px;
    }

    .js-nav-section {
      padding: 60px 227px 60px 0;
      text-align: left;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .section__title {
      margin-top: 0;
      font-size: 26px;
      line-height: 1.46;
      letter-spacing: -0.8px;
      color: #111;
    }

    .table {
      margin-top: 30px;
      border-top-color: var(--tableTopBorder);
      border-bottom: none;

      &__tr {
        border-top: none;
      }

      &__tbody-td {
        display: flex;
        margin-right: 20px;
        padding-right: 20px;
        border-bottom: 1px solid var(--tbodyBorder);
        align-items: center;

        &:last-child {
          margin-right: 0;
        }

        &.empty {
          border-bottom: none;
        }
      }

      &__name, &__box {
        padding: 0;
        white-space: nowrap
      }

      &__box {
        font-size: 0;
        text-align: right;
      }

      &__link {
        margin-right: 12px;
        vertical-align: middle;

        &:last-child {
          margin-right: 0;
        }

        &.home {
          @include sp-desktop('btn-share-home2');

          &:hover {
            @include sp-desktop('btn-share-home2-on');
          }
        }

        &.google-play {
          @include sp-desktop('btn-share-googleplay');

          &:hover {
            @include sp-desktop('btn-share-googleplay-on');
          }
        }

        &.app-store {
          @include sp-desktop('btn-share-appstore');

          &:hover {
            @include sp-desktop('btn-share-appstore-on');
          }
        }

        &.one-store {
          @include sp-desktop('btn-share-onestore');

          &:hover {
            @include sp-desktop('btn-share-onestore-on');
          }
        }
      }
    }
  }
}

.theme-dark {
  .navercorp.service-all {
    .table {
      &__link {
        &.home {
          @include sp-desktop('btn-share-home2-on');

          &:hover {
            @include sp-desktop('btn-share-home2');
          }
        }

        &.google-play {
          @include sp-desktop('btn-share-googleplay-on');

          &:hover {
            @include sp-desktop('btn-share-googleplay');
          }
        }

        &.app-store {
          @include sp-desktop('btn-share-appstore-on');

          &:hover {
            @include sp-desktop('btn-share-appstore');
          }
        }

        &.one-store {
          @include sp-desktop('btn-share-onestore-on');

          &:hover {
            @include sp-desktop('btn-share-onestore');
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.service-all {
      .section {
        padding-left: 388px;
      }

      .js-nav-section {
        padding: 60px 0;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.service-all {
      .tab__area {
        margin-bottom: 0;
      }

      .section {
        max-width: none;
        padding: 0 30px;
      }

      .js-nav-section {

        &:first-child {
          padding-top: 100px;
        }
      }

    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.service-all {
      .section__title {
        font-size: 24px;
        line-height: 1.42;
        letter-spacing: -0.67px;
      }

      .js-nav-section {
        padding: 50px 0;

        &:first-child {
          padding-top: 80px;
        }
      }

      .table {
        margin-top: 40px;

        &__tbody-td {
          margin-right: 12px;
          padding: 16px 12px 16px 0;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.service-all {
      .section {
        padding: 0 20px;
      }

      .js-nav-section {
        padding: 53px 0;

        &:first-child {
          padding-top: 65px;
        }
      }

      .table {
        &__tr {
          flex-direction: column;
        }

        &__tbody-td {
          width: 100%;
          margin-right: 0;
          padding: 16px 0;

          &.empty {
            padding: 0;
          }
        }
      }
    }
  }
}


// 마지막 앵커 영역이 짧아 생기는 이슈 해결
@media screen and (min-width: 1920px) and (min-height: 923px) {
  .#{$component-name} {
    &.service-all {
      .js-nav-section:last-child {
        margin-top: -300px;
        padding-top: 300px;
      }
    }
  }
}

@media screen and (max-width: 1919px) and (min-height: 640px) and (max-height: 922px) {
  .#{$component-name} {
    &.service-all {
      .js-nav-section:last-child {
        margin-top: -150px;
        padding-top: 150px;
      }
    }
  }
}
