.#{$component-name} {
  &.esg-ethics {
    .container {
      padding-bottom: 100px;
    }

  

    .track-animation {
      &__cover {
        background-image: url(/img/pc/esg-ethics-main.png);
      }

      &__dimmed {
        background: #fcefe5;

        &.#{$showClass} {
          opacity: 1;
        }
      }

      &__highlight-area {
        width: 1300px;
        text-align: center;
      }
    }

    .anchor-section {
      .js-nav-section {
        .section__right-box {
          .section__title {
            &.pc_text {
              display: block;
            }
            &.mo_text {
              display: none;
            }
          }
        }
      }
    }

    .ir-common {
      &__button {
        &.download {
          @include multi-ellipsis(1);
          width: 449px;
          margin: 0;
          margin-left: -1px;
          line-height: 52px;
          font-size: 12px;

          &:hover {
            z-index: 1;
          }

          &:first-child {
            margin-left: 0;
          }
        }

        &.outlink {
          height: 34px;
          padding-left: 40px;
          font-size: 18px;
          line-height: 34px;
          font-weight: 800;
          letter-spacing: -0.5px;
          &::before {
            width: 30px;
            height: 30px;
          }
        }

        &.type-eng {
          display: none;
        }

        @at-root .#{$component-name}.esg-ethics .lang-en .ir-common__button {
          &.type-kor {
            display: none;
          }

          &.type-eng {
            display: inline-flex;
          }

          & + .ir-common__button {
            margin-left: 0;
          }
        }
      }
    }

    .section {
      &__desc {
        width: 1112px;
        line-height: 1.62;
        margin-bottom: 30px;

        @at-root .navercorp.esg-ethics.lang-en {
          .section__desc {
            width: auto;
            padding-right: 100px;
          }
        }
      }

      &__title{
        .text-highlight{
          z-index: -1;
        }
      }
    }

    .esg-ethics {
      &__norm-img {
        width: 100%;
        margin-top: 60px;
      }

      &__download {
        display: flex;
        margin-top: 120px;
        font-size: 0;

        .eng {
          display: none;
        }
        .kor{
          display: block;
          text-wrap: nowrap;
        }
        @at-root .navercorp.esg-ethics.lang-en {
          .kor {
            display: none;
            font-size: 0; 
          }
          .eng{
            display: -webkit-inline-box;
            width: 100%;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }

          .js-nav-section .download{
            display: flex;
            gap: 9px;
            &.type3 {
              &.eng {
                width: 100%;
              }
            }
          }
        }

      }

      &__list {
        margin-top: 80px;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-top: 40px;

        &:first-child {
          margin-top: 0;
        }

        &-img {
          flex-shrink: 0;
          width: 434px;
          height: 312px;
        }

        &-text {
          max-width: 544px;
          margin-left: 143px;

          @at-root .navercorp.esg-ethics.lang-en {
            .esg-ethics__item-text {
              width: max-content;
              max-width: 100%;
              padding-right: 130px;
            }
          }
        }

        .text-box {
          display: flex;

          &.sub {
            padding-left: 7px;
          }
        }

        .dot {
          margin: 7px 4px 0 0;
          color: var(--searchTxt);

          @at-root .theme-dark & {
            color: var(--color-sub);
          }
        }

        .title {
          display: block;
          margin-bottom: 10px;
          font-weight: 800;
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
          color: var(--default);
        }

        .text {
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.4px;
          color: var(--color-sub);
        }
      }

      &__promise {
        &-title {
          font-weight: 800;
          font-size: 48px;
          line-height: 1.67;
          letter-spacing: -1.29px;
          color: $color-white;
          text-align: center;
        }

        &-text {
          display: block;
          margin-top: 36px;
          font-size: 16px;
          letter-spacing: -0.5px;
          font-weight: 700;
          color: #fff;
          text-align: center;
        }
      }

      &__sub-title {
        margin-top: 100px;
        font-weight: 800;
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1px;
        color: var(--default);
      }

      &__report {
        .esg-ethics__sub-title {
          margin-top: 140px;
        }
      }

      &__table {
        width: 1232px;
        margin-top: 50px;

        &-tr {
          display: flex;
          border-top: 1px solid;
          border-color: var(--border01);
          padding-bottom: 40px;
        }

        &-td {
          flex-shrink: 0;
          max-width: 505px;
          margin-left: 321px;
          padding: 30px 0 80px;

          &:first-child {
            width: 275px;
            margin-left: 0;
          }

          .title {
            font-weight: 800;
            font-size: 16px;
            line-height: 1.88;
            letter-spacing: -0.5px;
            color: #a1a1a6;
          }

          .text {
            margin-top: 11px;
            font-weight: 700;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
            color: var(--default);
          }

          .esg-ethics__outlink {
            &.eng {
              display: none;
            }
            &.kor{
              display: inline-block;
            }
            @at-root .navercorp.esg-ethics.lang-en {
              .esg-ethics__outlink.kor {
                display: none; 
              }
              .esg-ethics__outlink.eng{
                display: inline-block;
                width: auto;
              }
            }
          }
        }
      }

      &__outlink {
        margin-top: 30px;
        border-bottom: 1px solid;
        font-weight: 800;
        font-size: 14px;
        line-height: 1.86;
        letter-spacing: -0.3px;
        transition: all 0.3s;
        color: var(--default);
        border-color: var(--default);

        &:after {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: 4px;
          vertical-align: middle;
          background: var(--btn-outlink-sm) no-repeat center;
          background-size: contain;
          transition: background 0.3s;
          content: '';
        }

        &:hover {
          color: #ff5f00;
          border-color: #ff5f00;

          &:after {
            background: url(../img/common/btn-outlink-sm-on.png) no-repeat center;
            background-size: contain;
          }
        }
      }
    }

    .js-nav-section {
      max-width: 1920px;
      margin-bottom: 0;
      padding: 0;
      padding-top: 200px;

      &.norm {
        margin-top: 0;
        padding-top: 45px;
        padding-bottom: 200px;

        .esg-ethics {
          &__item {
            &:first-child {
              .esg-ethics__item-img {
                background: url(../img/pc/esg-ethics-norm01.png) no-repeat center;
                background-size: cover;
              }
            }

            &:nth-child(2) {
              .esg-ethics__item-img {
                background: url(../img/pc/esg-ethics-norm02.png) no-repeat center;
                background-size: cover;
              }
            }

            &:nth-child(3) {
              .esg-ethics__item-img {
                background: url(../img/pc/esg-ethics-norm03.png) no-repeat center;
                background-size: cover;
              }
            }

            &:nth-child(4) {
              .esg-ethics__item-img {
                background: url(../img/pc/esg-ethics-norm04.png) no-repeat center;
                background-size: cover;
              }
            }

            &:last-child {
              .esg-ethics__item-img {
                background: url(../img/pc/esg-ethics-norm05.png) no-repeat center;
                background-size: cover;
              }
            }
          }
        }
      }

      &.promise {
        background-color: #007dff;
        padding-top: 0;
        height: 645px;
        display: flex;
        align-items: center;

        .esg {
          &__inner {
            width: 1200px;
            margin: 0 auto;
            padding: 92px 0;
          }
        }
      }

      &.counsel {
        padding-top: 80px;
        margin-top: 100px;

        .esg-ethics {
          &__list {
            margin-top: 50px;
          }

          &__item {
            &:first-child {
              .esg-ethics__item-img {
                background: url(../img/pc/esg-ethics-counsel01.png) no-repeat center;
                background-size: cover;
              }
            }

            &:nth-child(2) {
              .esg-ethics__item-img {
                background: url(../img/pc/esg-ethics-counsel02.png) no-repeat center;
                background-size: cover;
              }
            }

            &:last-child {
              .esg-ethics__item-img {
                background: url(../img/pc/esg-ethics-counsel03.png) no-repeat center;
                background-size: cover;
              }
            }

            .title {
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.6px;

            }

            .text {
              margin-top: 4px;
              color: #a1a1a6;
            }
          }
        }
      }
    }

  }
}

.theme-dark {
  .navercorp.esg-ethics {
    .js-nav-section {
      &.norm {
        .esg-ethics__item {
          &:first-child {
            .esg-ethics__item-img {
              background-image: url(/img/pc/esg-ethics-norm01-dark.png);
            }
          }

          &:nth-child(2) {
            .esg-ethics__item-img {
              background-image: url(/img/pc/esg-ethics-norm02-dark.png);
            }
          }

          &:nth-child(3) {
            .esg-ethics__item-img {
              background-image: url(/img/pc/esg-ethics-norm03-dark.png);
            }
          }

          &:nth-child(4) {
            .esg-ethics__item-img {
              background-image: url(/img/pc/esg-ethics-norm04-dark.png);
            }
          }

          &:last-child {
            .esg-ethics__item-img {
              background-image: url(/img/pc/esg-ethics-norm05-dark.png);
            }
          }
        }
      }

      &.counsel {
        .esg-ethics__item {
          &:first-child {
            .esg-ethics__item-img {
              background-image: url(/img/pc/esg-ethics-counsel01-dark.png);
            }
          }

          &:nth-child(2) {
            .esg-ethics__item-img {
              background-image: url(/img/pc/esg-ethics-counsel02-dark.png);
            }
          }

          &:last-child {
            .esg-ethics__item-img {
              background-image: url(/img/pc/esg-ethics-counsel03-dark.png);
            }
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.esg-ethics {

      .section {
        &__desc {
          width: 1040px;
        }
      }

      .esg-ethics {

        &__download {
          @at-root .navercorp.esg-ethics.lang-en {
  
            .kor {
              display: none;
              font-size: 0; 
            }
            .eng{
              .download {
                display: flex;
                gap: 9px;
              }
            }

            .js-nav-section .download.type3 {
              &.kor {
                display: none;
              }
              &.eng {
                width: 100%;
  
                &::before {
                  right: 12px;
                }
              }
            }

          }
        }

        &__item {
          &-img {
            width: 365px;
            height: 262px;
          }

          &-text {
            max-width: 540px;
            margin-left: 117px;
          }

          .title {
            margin-bottom: 12px;
            font-size: 22px;
            line-height: 1.91;
            letter-spacing: -0.6px;
          }

          .text {
            font-size: 14px;
            line-height: 2;
            letter-spacing: -0.3px;
            font-weight: 700;
            max-width: 670px;
            word-break: break-word;
          }
        }

        &__report {
          .esg-ethics__sub-title {
            margin-top: 120px;
          }
        }

        &__table {
          &-td {
            margin-left: 233px;
          }
        }
      }

      .js-nav-section {
        padding-top: 180px;

        &.promise {
          height: 614px;
        }

        &.counsel {
          padding-top: 80px;
          margin-top: 80px;

          .esg-ethics {
            &__item {
              .title {
                font-size: 18px;
                line-height: 1.67;
                letter-spacing: -0.5px;
              }

              .text {
                line-height: 1.86;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {

    &__cover {
      background-image: url(/img/pc/esg-ethics-main.png);
    }

    &.esg-ethics {
      .track-animation {
        &__highlight-area {
          width: 780px;
        }
        &__highlight-text {
          text-align: center;
        }
      }

      .ir-common {
        &__button {
          &.download {
            width: auto;
            flex: 1;
            padding-right: 50px;
          }

          &.outlink {
            height: 30px;
            padding-left: 38px;
            font-size: 16px;
            line-height: 30px;
            &::before {
              width: 28px;
              height: 28px;
            }
          }
        }
      }

      .section {
        &__desc {
          width: auto;
          max-width: 852px;

          @at-root .navercorp.esg-ethics.lang-en {
            .section__desc {
              max-width: 100%;
              padding-right: 0;
            }
          }
        }
      }

      .esg-ethics {
        &__download {
          margin-top: 100px;
          padding: 0 30px;

            @at-root .navercorp.esg-ethics.lang-en {
              .esg-ethics__download {
                .kor {
                  display: none;
                  font-size: 0; 
                }

                .eng{
                  font-size: 12px;
                  width: 100%;
                  height: 68px;
                  line-height: 2;
                  display: flex;
                  align-items: center;

                  &.type3 {
                    width: 100%;
                    height: 72px;
                  }
                }
              }
            }

        }

        &__item {
          margin-top: 30px;

          &-text {
            margin-left: 60px;
            padding-right: 30px;
            
            @at-root .navercorp.esg-ethics.lang-en {
              .esg-ethics__item-text {
                padding-right: 0;
              }
            }
            
          }

          .title {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }

        &__promise {
          &-title {
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
          }

          &-text {
            margin-top: 26px;
            font-size: 14px;
            letter-spacing: -0.5px;
          }
        }

        &__sub-title {
          font-size: 30px;
          line-height: 1.6;
          letter-spacing: -1.18px;
        }

        &__report {
          .esg-ethics__sub-title {
            margin-top: 100px;
          }
        }

        &__table {
          width: 100%;
          margin-top: 40px;

          &-td {
            max-width: 452px;
            margin-left: 245px;
            padding: 20px 0;

            &:first-child {
              width: 247px;
            }
          }
        }
      }

      .js-nav-section {
        padding-top: 140px;

        &.norm {
          padding-top: 120px;
          padding-bottom: 140px;

          .section__title {
            padding: 0 30px;
            font-size: 48px;
          }

          .section__right-box {
            padding: 0;
          }

          .esg {
            &__title {
              padding: 0 30px;
            }
          }

          .esg-ethics {
            &__list {
              padding: 0 30px;
            }
          }
        }

        &.promise {
          height: 394px;
          .esg {
            &__inner {
              width: 780px;
              padding: 62px 0;
            }
          }
        }

        &.counsel {
          padding-top: 140px;
          margin-top: 0;

          .esg-ethics {
            &__list {
              margin-top: 40px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.esg-ethics {
      .track-animation {
        &__highlight-area {
          width: 640px;
        }
        &__highlight-text {
          text-align: left;
        }
      }

        .js-nav-section {

          &.promise {
            height: 357px;
          }
          &.norm {
            .section__right-box {
              .section__title {
                font-size: 32px;
              }
            }
          }
        }

      .container {
        padding-bottom: 70px;
      }

      .ir-common {
        &__button {
          &.download {
            width: 236px;
          }
        }
      }

      .esg {
        &__title {
          font-size: 32px;
          line-height: 1.5;
          letter-spacing: -1px;
        }
      }

      .esg-ethics {


        &__download {
          margin-top: 80px;

          @at-root .navercorp.esg-ethics.lang-en {
            .js-nav-section .esg-ethics__download {
              .eng{
                font-size: 12px;
                width: 100%;
                height: 72px;
                line-height: 2;
                letter-spacing: -0.3px;
                display: flex;
                align-items: center;

                &.type3 {
                  height: 110px;
                  width: 100%;
                }

                &:nth-child(8) {
                  margin-top: -18px;
                }
              }
            }
          }


        }

        &__list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 60px;
        }

        &__item {
          display: block;
          width: calc(50% - 10px);
          margin-top: 60px;
          margin-right: 20px;

          &:nth-child(2) {
            margin-top: 0;
          }

          &:last-child, &:nth-child(2n + 0) {
            margin-right: 0;
          }

          &-img {
            width: 100%;
            height: 246px;
          }

          &-text {
            max-width: none;
            margin-left: 0;
          }

          .title {
            margin: 20px 0 10px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }

          .text {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__promise {
          &-title {
            font-size: 26px;
            line-height: 1.54;
            letter-spacing: -0.8px;
          }

          &-text {
            margin-top: 24px;
            font-size: 12px;
            letter-spacing: -0.5px;
          }
        }

        &__sub-title {
          margin-top: 80px;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
        }

        &__report {
          .esg-ethics__sub-title {
            margin-top: 80px;
          }
        }

        &__table {
          margin-top: 30px;

          &-tr {
            justify-content: space-between;
          }

          &-td {
            flex: 1;
            max-width: none;
            margin-left: 0;
            padding: 20px 0 50px;

            &:first-child {
              width: auto;
            }

            &:last-child {
              padding-left: 40px;
            }

            .title {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }

            .text {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }
        }

        &__outlink {
          margin-top: 20px;
          font-size: 12px;
          line-height: 2.17;
          letter-spacing: -0.3px;
        }
      }

      .js-nav-section {
        padding-top: 120px;

        &.norm {
          padding-bottom: 100px;
        }
        
        &.promise {
          .esg {
            &__inner {
              width: auto;
              padding: 92px 64px;
            }
          }
        }

        &.counsel {
          padding-top: 120px;

          .esg-ethics {
            &__list {
              margin-top: 30px;
            }

            &__item {
              .dot {
                margin-top: 5px;
              }

              .title {
                font-size: 16px;
                line-height: 1.75;
                letter-spacing: -0.5px;
              }

              .text {
                margin-top: 2px;
                font-size: 12px;
                line-height: 2.17;
                letter-spacing: -0.3px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.esg-ethics {
      .track-animation {
        &__cover {
          background-image: url(/img/mo/esg-ethics-main-mo.png);
        }

        &__highlight-area {
          width: auto;
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 20px;
        }
      }

      .anchor-section {
        .js-nav-section {
          .section__right-box {
            .section__title {
              &.pc_text {
                display: none;
              }
              &.mo_text {
                display: block;
              }
            }
          }
        }
      }

      .container {
        padding-bottom: 40px;
      }

      .cont__title {
        padding: 0 30px;
      }

      .ir-common {
        &__button {
          &.download {
            width: 100%;
            margin-left: 0;
            margin-top: 6px;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      .esg-ethics {
        &__download {
          flex-direction: column;
          margin-top: 60px;
          padding: 0 20px;

          @at-root .navercorp.esg-ethics.lang-en {
            .js-nav-section .esg-ethics__download {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
              width: 100%;
              gap: 6px;
              padding: 6px 20px;

              .kor {
                display: none;
                font-size: 0; 
              }
              .eng{
                width: 100%;
                height: 80px;
                line-height: 1.86;
                letter-spacing: -0.3px;
                gap: 6px;
                margin-top: 0;

                &.type3 {
                  display: flex;
                  height: 80px;
                }
                &:nth-child(4), &:nth-child(6) {
                  height: 54px;
                }
                &:nth-child(8) {
                  margin-top: 5px;
                }
              }

            }


            .js-nav-section.promise {
                height: 760px;
            }
          }

    

          
        }

        &__list {
          margin-top: 60px;
          padding: 0;
        }

        &__item {
          width: 100%;
          margin-top: 60px;
          margin-right: 0;

          &:nth-child(2) {
            margin-top: 60px;
          }

          &-img {
            width: 100%;
            height: 240px;
          }

          &-text {
            padding-right: 0;
          }

          .title {
            margin: 20px 0 12px;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }

        &__promise {
          &-text {
            margin-top: 40px;
            font-size: 14px;
            letter-spacing: -0.44px;
          }
        }

        &__sub-title {
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
        }

        &__table {
          &-tr {
            flex-wrap: wrap;
            border-top: 0;
          }

          &-td {
            flex: none;
            width: 100%;
            padding: 20px 0 60px;
            border-top: 1px solid $color-gray4;
            @at-root .theme-dark & {
              border-top: 1px solid #2F2E30;
            }

            &:first-child {
              width: 100%;
            }

            &:last-child {
              padding-left: 0;
            }
          }
        }
      }

      .js-nav-section {
        padding-top: 100px;

        &.norm {
          padding-top: 85px;

          .section__title {
            padding: 0 20px;
            font-size: 32px;

            &.eng {
              padding: 0 14px;
              font-size: 30px;
              overflow: initial;
              -webkit-line-clamp: inherit;
              -webkit-box-orient: vertical;
              text-overflow: initial;
            }

            .text-highlight{
              text-wrap: nowrap;
            }
          }

          .esg-ethics {
            &__list {
              padding: 0 20px;
            }
          }
        }

        &.promise {
          height: 536px;

          .esg {
            &__inner {
              width: auto;
              height: 100%;
              padding: 60px 27px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
          }
        }

        &.counsel {
          margin-top: 80px;

          .esg-ethics {
            &__item {
              .title {
                font-size: 18px;
                line-height: 1.67;
                letter-spacing: -0.5px;
              }

              .text {
                margin-top: 8px;
                font-size: 14px;
                line-height: 1.71;
                letter-spacing: -0.3px;
              }

              .dot {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
