.#{$component-name} {
  .proposal-popup {
    &__inner {
      max-width: 500px;
      padding: 40px 40px 50px;
    }

    &__title {
      font-size: 32px;
      font-weight: 800;
      line-height: 48px;
      letter-spacing: -1px;
      color: var(--default);
    }

    .register-info {
      margin-top: 40px;

      span {
        display: block;
        font-size: 22px;
        line-height: 42px;
      }

      .title {
        font-weight: 800;
        letter-spacing: -.6px;
        color: var(--default);
      }

      .num {
        color: $color-point;
        font-size: 20px;
        font-weight: 900;
      }
    }

    &__text {
      margin-top: 20px;
      padding-top: 30px;
      border-top: 1px solid var(--border01);

      .desc {
        margin-top: 10px;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -.3px;
        color: var(--numBg);

        &:first-child {
          margin-top: 0;
        }

        &.is-bold {
          font-weight: 800;
          color: var(--default);
        }
      }
    }

    .btn-wrap {
      justify-content: center;
      margin-top: 40px;
      padding: 0;
    }

    .btn-close {
      display: block;
      position: absolute;
      top: 14px;
      right: 14px;
      width: 40px;
      height: 40px;

      &::after {
        display: block;
        width: 100%;
        height: 100%;
        background: url(/img/common/ic-close-popup.png) no-repeat center;
        background-size: contain;
        content: '';
      }
    }
  }

  .proposal-cancel-popup {
    .proposal-popup {
      &__inner {
        max-width: 450px;
        padding: 54px 44px 44px;
      }

      &__text {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        font-size: 22px;
        font-weight: 800;
        line-height: 34px;
        letter-spacing: -.6px;
        text-align: center;
        color: var(--default);
      }
    }

    .btn-wrap {
      margin-top: 30px;
    }
  }

  .proposal-inquiry-popup {
    .proposal-popup {
      &__inner {
        width: 450px;
        padding: 54px 44px 44px;
      }

      &__text {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        font-size: 22px;
        font-weight: 800;
        line-height: 34px;
        letter-spacing: -.6px;
        text-align: center;
        color: var(--default);
      }

      &__desc {
        margin-top: 17px;
        font-size: 14px;
        letter-spacing: -.3px;
        line-height: 1.71;
        color: var(--flowerDesc);
        text-align: center;
      }
    }

    .btn-wrap {
      margin-top: 30px;

      .ir-common__button.apply {
        width: 160px;
      }
    }
  }

  .proposal-agree-popup {
    .proposal-popup {
      &__inner {
        width: 450px;
        padding: 54px 20px 44px;
      }

      &__text {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        font-size: 22px;
        font-weight: 800;
        line-height: 34px;
        letter-spacing: -.6px;
        text-align: center;
        color: var(--default);
      }
    }

    .btn-wrap {
      width: 160px;
      margin: 30px auto 0;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {}
}

@include breakpoint(tablet) {
  .#{$component-name} {}
}

@include breakpoint(tablet-small) {
  .#{$component-name} {}
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .proposal-popup {
      &__inner {
        max-width: 335px;
        padding: 30px 30px 40px;
      }

      &__title {
        font-size: 26px;
        line-height: 38px;
        letter-spacing: -.8px;
      }

      .register-info {
        margin-top: 26px;

        span {
          font-size: 16px;
          line-height: 30px;
        }

        .title {
          letter-spacing: -.5px;
        }

        .num {
          font-size: 14px;
        }
      }

      &__text {
        margin-top: 14px;
        padding-top: 20px;

        .desc {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: -.2px;
        }
      }

      .btn-wrap {
        margin-top: 30px;
      }

      .btn-close {
        top: 8px;
        right: 8px;
        width: 36px;
        height: 36px;
      }
    }

    .proposal-cancel-popup {
      .proposal-popup {
        &__inner {
          max-width: 335px;
          padding: 44px 40px 40px;
        }

        &__text {
          font-size: 18px;
          line-height: 30px;
          letter-spacing: -.5px;
        }
      }

      .btn-wrap {
        margin-top: 22px;
      }
    }

    .proposal-inquiry-popup {
      .proposal-popup {
        &__wrap {
          padding: 0 30px;
        }

        &__inner {
          width: 100%;
          max-width: 315px;
          padding: 27px 30px 28px;
        }
  
        &__text {
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -.5px;
        }
  
        &__desc {
          margin-top: 15px;
          font-size: 12px;
          letter-spacing: -.26px;
          line-height: 1.67;
        }
      }
  
      .btn-wrap {
        flex-direction: row;
        margin-top: 20px;
        
        .ir-common__button.apply {
          width: 100%;
          max-width: 160px;
        }
      }

      .btn-close {
        display: none;
      }
    }

    .proposal-agree-popup {
      .proposal-popup {
        &__inner {
          width: 315px;
          padding: 26px 20px 28px;
        }

        &__text {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -.5px;
        }
      }

      .btn-wrap {
        margin-top: 20px;
      }
    }

  }
}
