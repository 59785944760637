.#{$component-name} {
  &.fintech {
    .section__visual {
      background-image: url(/img/pc/service-fintech-main.jpg);

      &::before {
        content:'';
        width: 100%;
        height: 100vh;
        display: block;
      }
    }

    .service__bg {
      height: 87.963%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .js-nav-section {
      &.naver-pay {
        .service__bg {
          background-image: url(/img/pc/service-fintech-pay-bg.png);
        }
      }

      &.stock, &.property {
        .service__img-wrap {
          height: 1006px;
        }

        .service__img-box {
          position: absolute;
          top: 0;
          left: 50%;
          min-width: 1340px;
          height: 100%;
          transform: translateX(-50%);
        }

        .service__img {
          position: absolute;
          bottom: 0;
          width: 380px;
          height: 801px;

          img{
            position: relative;
            top: 1px;
          }

          &:first-child {
            left: 0;
          }

          &:nth-child(2) {
            top: 0;
            bottom: auto;
            left: 50%;
            transform: translateX(-50%);
          }

          &:last-child {
            right: 0;
          }
        }

        .service__bg {
          top: 153px;
          height: 69.186%;
          background-color: #01d791;
          transform: translate(-50%, 0);
        }
      }

      &.property {
        .service__bg {
          background-color: #2c72f1;
        }
      }

      &.finance {
        .section__img {
          margin-top: 100px;
          padding-top: 36.574%;
          background-image: url(/img/pc/service-fintech-finance.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }

    .fintech {
      &__list {
        max-width: 1118px;
        margin: 100px auto 0;
        border-top: 1px solid var(--border01);
        text-align: left;

        &-head {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding-top: 80px;
        }

        .num {
          font-weight: 700;
          font-size: 16px;
          color: var(--default);
        }

        .title {
          margin-top: 20px;
          font-weight: 800;
          font-size: 32px;
          width: fit-content;
          line-height: 1.5;
          letter-spacing: -1px;
          color: var(--default);
        }

        .text {
          width: 549px;
          font-weight: 700;
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
          color: var(--color-sub);
        }
      }

      &__img-wrap {
        display: flex;
        justify-content: end;
        margin-top: 80px;

        .img-default {
          display: block;
        }

        .img-dark {
          display: none;
        }
      }

      &__img {
        width: 549px;
        height: 692px;
        background-color: #f2f6ff;
      }

      &__accordion {
        position: relative;
        max-width: 1118px;
        min-height: 692px;
        margin: 80px auto 0;

        .img-box {
          // position: absolute;
          // top: 0;
          // right: 0;
          // width: 549px;
          background-color: var(--privacyBg);

          opacity: 0;
          visibility: hidden;
          position: absolute;
          top: 0;
          right: 0;
          width: 549px;
          transition: all 1s;
        }

        &-list {
          width: 460px;
        }

        &-item {
          padding: 20px 0;
          border-top: 1px solid var(--border01);

          &:last-child {
            border-bottom: 1px solid var(--border01);
          }

          &.is-active {
            .accordion__content {
              max-height: none;
              height: 100%;
            }

            .fintech__accordion-button {
              &:after {
                background-image: var(--ic-accordion-close);
              }

              &:hover {
                &:after {
                  background-image: url(/img/common/ic-accordion-close-orange.png);
                }
              }
            }

            .img-box {
              opacity: 1;
              visibility: visible;
            }
          }

          &:only-child {
            padding: 0;
            border: none;

            .fintech__accordion-text {
              margin-top: 0;
            }
          }
        }

        &-button {
          position: relative;
          width: 100%;
          padding-right: 30px;
          text-align: left;

          &:after {
            position: absolute;
            top: 50%;
            right: 10px;
            width: 18px;
            height: 18px;
            background-image: var(--ic-accordion);
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);
            transition: all .3s;
            content: '';
          }

          &:hover {
            &:after {
              background-image: url(/img/common/ic-accordion-orange.png);
            }

            .fintech__accordion-title {
              color: $color-point;
            }
          }
        }

        &-title {
          font-weight: 800;
          font-size: 22px;
          line-height: 1.91;
          letter-spacing: -0.6px;
          transition: all .3s;
        }

        &-text {
          margin-top: 20px;
          padding-right: 30px;
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
          text-align: left;
          color: var(--color-sub);

          .sub-text {
            display: block;
            margin-top: 23px;
          }
        }
      }

      &__finance {
        max-width: 1345px;
        margin: 100px auto 0;

        &-list {
          display: flex;
          flex-wrap: wrap;
          padding-top: 20px;
          border-top: 1px solid var(--border01);
          text-align: left;
        }

        &-item {
          width: calc((100% - 40px) / 3);
          margin-right: 20px;
          margin-top: 80px;

          &:first-child {
            .img {
              &:after {
                background-image: url(/img/pc/service-fintech-icon01.png);
              }
            }
          }

          &:nth-child(2) {
            .img {
              &:after {
                background-image: url(/img/pc/service-fintech-icon02.png);
              }
            }

            .title {
              align-items: flex-end;
              &::after {
                position: relative;
                top: -4px;
              }
            }
          }

          &:nth-child(3) {
            margin-right: 0;

            .img {
              &:after {
                background-image: url(/img/pc/service-fintech-icon03.png);
              }
            }
          }

          &:nth-child(4) {
            .img {
              &:after {
                background-image: url(/img/pc/service-fintech-icon04.png);
              }
            }
          }

          &:last-child {
            .img {
              &:after {
                background-image: url(/img/pc/service-fintech-icon05.png);
              }
            }
          }
        }

        &-link {
          width: 100%;
          height: 100%;

          &:hover {
            color: $color-point;
            .title {
              &::after {
                background: url(/img/common/btn-outlink-on.png) no-repeat center;
                background-size: 100% auto;
              }
            }
          }
        }

        .img {
          position: relative;
          width: 100%;
          height: 290px;
          background-color: var(--privacyBg);

          &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 188px;
            height: 188px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transform: translate(-50%, -50%);
            content: '';
          }
        }

        .title {
          display: flex;
          align-items: center;
          margin-top: 20px;
          font-weight: 800;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
          transition: all .3s;

          &:after {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-left: 4px;
            background: var(--btn-outlink) no-repeat center;
            background-size: contain;
            vertical-align: middle;
            transition: background .3s;
            content: '';
          }
        }

        .text {
          margin-top: 16px;
          font-size: 16px;
          line-height: 1.88;
          letter-spacing: -0.5px;
          color: #6e6e73;
        }
      }
    }
  }
}

.theme-dark {
  .navercorp.fintech {
    .fintech {
      &__img-wrap {
        .img-default {
          display: none;
        }

        .img-dark {
          display: block;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.fintech {
      .js-nav-section {
        &.stock, &.property {
          .service__img-wrap {
            height: 832px;
          }

          .service__img-box {
            min-width: 1128px;
          }

          .service__bg {
            top: 128px;
            height: 69.712%;
          }

          .service__img {
            width: 316px;
            height: 666px;
          }
        }
      }

      .fintech {
        &__list {
          max-width: 944px;

          &-head {
            padding-top: 62px;
          }

          .text {
            position: relative;
            top: 31px;
            width: 460px;
          }
        }

        &__img {
          width: 460px;
          height: 580px;
        }

        &__accordion {
          max-width: 944px;
          min-height: 580px;

          &-list {
            width: 420px;
          }

          .img-box {
            width: 460px;
          }
        }

        &__finance {
          max-width: 1135px;

          &-list {
            padding-top: 0;
          }

          &-item {
            margin-top: 60px;
          }

          .img {
            height: 243px;

            &:after {
              width: 158px;
              height: 158px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.fintech {
      .service__bg {
        height: 78.999%;
      }

      .js-nav-section {
        &.stock, &.property {
          .service__img-wrap {
            height: 698px;
          }

          .service__text{
            max-width: 840px;
            margin: 120px auto;
          }

          .service__img-box {
            min-width: 836px;
          }

          .service__bg {
            top: 138px;
            height: 60.173%;
          }

          .service__img {
            width: 252px;
            height: 531px;
          }
        }

        &.finance {
          .section__img {
            margin-top: 90px;
            padding-top: 41.017%;
          }
        }
      }

      .fintech {
        &__list {
          max-width: 800px;
          margin: 80px auto 0px;
          &-head {
            padding-top: 50px;
          }

          .title {
            margin-top: 16px;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          .text {
            position: relative;
            top: 25px;
            width: 385px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }
        }

        &__img-wrap {
          margin-top: 60px;
        }

        &__img {
          width: 390px;
          height: 492px;
        }

        &__accordion {
          max-width: 800px;
          min-height: 492px;

          .img-box {
            width: 390px;
          }

          &-list {
            width: 328px;
          }

          &-item {
            padding: 18px 0;
          }

          &-title {
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          &-text {
            margin-top: 12px;
            padding-bottom: 6px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__finance {
          max-width: none;
          margin: 80px 30px 0;

          .img {
            height: 205px;

            &:after {
              width: 133px;
              height: 133px;
            }
          }

          .title {
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          .text {
            margin-top: 10px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.fintech {
      .service__bg {
        height: 83.898%;
      }

      .js-nav-section {

        .service__text{
          max-width: 680px;
          margin: 60px auto;
        }

        &.stock, &.property {
          .service__img-wrap {
            height: 518px;
          }

          
          .service__text{
            max-width: 710px;
            margin: 60px auto;
         }

          .service__img-box {
            min-width: 622px;
          }

          .service__bg {
            top: 91px;
            height: 64.674%;
          }

          .service__img {
            width: 190px;
            height: 400px;
          }
        }

        &.finance {
          .section__img {
            margin-top: 60px;
            padding-top: 43.62%;
          }
        }
      }

      .fintech {
        &__list {
          max-width: 720px;
          margin: 60px auto 0;

          .title {
            margin-top: 10px;
          }

          .text {
            width: 354px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__img {
          width: 348px;
          height: 439px;
        }

        &__accordion {
          max-width: 708px;
          min-height: 439px;
          margin: 60px auto 0;
          .img-box {
            width: 348px;
          }

          &-list {
            width: 310px;
          }
        }

        &__finance {
          &-item {
            width: calc((100% - 12px) / 2);
            margin-top: 80px;
            margin-right: 12px;

            &:first-child {
              margin-top: 50px;
            }

            &:nth-child(2) {
              margin-right: 0;
              margin-top: 50px;
            }

            &:nth-child(3) {
              margin-right: 12px;
            }

            &:nth-child(4) {
              margin-right: 0;
            }
          }

          .img {
            height: 232px;

            &:after {
              width: 151px;
              height: 151px;
            }
          }

          .title {
            margin-top: 16px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          .text {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.fintech {
      .section__visual {
        background-image: url(/img/mo/service-fintech-main.jpg);
      }

      .fintech {
        &__list {
          .text {
            max-width: 330px;
          }
        }
      }

      .service__bg {
        height: 84.083%;
      }

      .js-nav-section {
        &.naver-pay {
          .service__bg {
            background-image: url(/img/mo/service-fintech-pay-bg.png);
          }
        }

        &.stock, &.property {
          .service__img-wrap {
            height: 527px;
          }

          .service__img-box {
            max-width: 622px;
            min-width: 375px;
          }

          .service__bg {
            top: 86px;
            height: 67.365%;
          }

          .service__img {
            width: 200px;
            height: 421px;

            &:first-child {
              left: -132px;
            }

            &:last-child {
              right: -132px;
            }
          }
        }

        &.finance {
          .section__img {
            margin-top: 60px;
            padding-top: 100%;
            background-image: url(/img/mo/service-fintech-finance.jpg);
          }
        }
      }

      .fintech {
        &__list {
          margin: 60px 20px 0;

          &:last-of-type {
            border-top: none;

            .fintech__list-head {
              padding-top: 0;
            }
          }

          &-head {
            display: block;
            padding-top: 62px;
          }

          .num {
            line-height: 2.13;
          }

        }

        &__img-wrap {
          display: block;
          margin-top: 70px;
        }

        &__img {
          width: 100%;
          height: auto;
          overflow: hidden;
        }

        &__accordion {
          position: static;
          width: 100%;
          min-height: auto;

          .img-box {
            position: static;
            width: 100%;
            min-height: 422px;
            margin-top: 34px;
          }

          &-item{
            &.is-active{
              padding-bottom: 60px;
            }
          }

          &-list {
            width: 100%;
          }
        }

        &__finance {
          margin: 60px 20px 0;

          &-list {
            display: block;
          }

          &-item {
            width: 100%;
            margin-right: 0;
            margin-top: 60px;

            &:first-child {
              margin-top: 30px;
            }
          }

          .img {
            height: 223px;

            &:after {
              width: 145px;
              height: 145px;
            }
          }
        }
      }
    }
  }
}
