.#{$component-name} {
  .ir-schedule-popup {
    &__inner {
      max-width: 570px;
      padding: 40px 50px 50px;
    }

    &__title {
      font-size: 38px;
      font-weight: 800;
      line-height: 58px;
      letter-spacing: -1.5px;
      color: var(--default);
    }

    &__table {
      table-layout: fixed;
      width: 100%;
      margin-top: 60px;
      text-align: left;

      tr {
        border-top: 1px solid var(--halfNum);
        font-size: 16px;
        line-height: 30px;

        &:last-child {
          border-bottom: 1px solid var(--halfNum);
        }
      }

      th, td {
        padding: 20px 0;
      }

      th {
        width: 25%;
        font-weight: 800;
        letter-spacing: -.5px;
        color: var(--default);
      }

      td {
        color: var(--scheduleColor);
      }
    }

    &__desc {
      margin-top: 12px;
      color: var(--flowerDesc);
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -.3px;
    }

    .btn-wrap {
      justify-content: center;
      margin-top: 70px;
    }

    .dimmed {
      background-color: #111;
      opacity: .3;
    }

    .btn-close {
      display: block;
      position: absolute;
      top: 40px;
      right: 32px;
      width: 36px;
      height: 36px;

      &::after {
        display: block;
        width: 100%;
        height: 100%;
        background: url(/img/common/ic-close-popup.png) no-repeat center;
        background-size: contain;
        content: '';
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .ir-schedule-popup {
      &__wrap {
        padding: 0 20px;
      }

      &__inner {
        padding: 30px 30px 40px;
      }

      &__title {
        font-size: 26px;
        line-height: 38px;
        letter-spacing: -.8px;
      }

      &__table {
        margin-top: 50px;

        tr {
          font-size: 14px;
          line-height: 26px;
        }

        th, td {
          padding: 14px 0;
        }

        th {
          width: 27%;
          letter-spacing: -.3px;
        }

        td span {
          display: block;
        }
      }

      &__desc {
        margin-top: 10px;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: -.26px;
      }

      .btn-wrap {
        margin-top: 40px;

        .ir-common__button.apply {
          height: 54px;
          font-size: 16px;
        }
      }

      .btn-close {
        top: 30px;
        right: 20px;
      }
    }
  }
}
