.#{$component-name} {
  &.service-c2c {
    .section {
      &__visual {
        background-image: url(../img/pc/service-c2c-main.jpg);
      }
    }

    .service__img {
      img {
        width: 101%;
        position: relative;
        left: -1px;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.service-c2c {

      
      .js-nav-section {
        .service__text {
          max-width: 880px;
          margin: 120px auto;
        }
      }


    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.service-c2c {
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.service-c2c {

      .js-nav-section {
        .service__text {
          max-width: 680px;
          margin: 60px auto;
        }
      }

    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.service-c2c {
      .section {
        &__visual {
          background-image: url(../img/mo/service-c2c-main.png);
        }
      }

      .service {
        &__img {
          &-wrap {
            margin-top: 60px;
          }
        }
      }
    }
  }
}
