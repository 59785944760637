.#{$component-name} {
  &.project-flower {
    .container {
      padding-bottom: 0;
    }

    .footer {
      position: relative;
      z-index: 2;
    }

    .js-nav-section {
      max-width: 1920px;
      margin: 0 auto;
    }

    .anchor-section {
      margin-top: 0;
    }

    .section__outlink {
      &-desc {
        color: var(--color-sub04);
      }
    }

    .track-animation {
      &__cover {
        background-image: url(/img/pc/project-flower-main.png);
      }

      &__dimmed {
        background-color: #bdd7ff;

        &.#{$showClass} {
          opacity: 1;
        }
      }

      &__highlight-area {
        width: 1136px;
        text-align: center;
      }
    }

    .half {

      &__img {
        background: var(--flowerBg);

        &:first-child {
          background-color: var(--flowerBg);
        }

        .image {
          position: static;
          width: 100%;
          transform: none;

          &.white {
            display: block;
          }

          &.dark {
            display: none;
          }
        }
      }

      &__info {
        padding: 105px 123px;

        .title {
          margin-top: 30px;
          color: var(--default);
        }

        .text {
          margin-top: 30px;
          font-size: 18px;
          font-weight: 700;
          line-height: 1.89;
          color: var(--inputColor);
        }

        .number {
          font-size: 64px;
          font-weight: 700;
          color: var(--halfNum);
        }
      }

      &__box {
        height: auto;
        min-height: 900px;
        &:last-child {
          align-items: center;
          border-bottom: 1px solid var(--border01);
        }
      }
    }

    .content04 {
      padding-top: 0;

      .section {
        &__outlink {
          position: relative;
          padding-right: 20px;
          font-weight: 800;
          font-size: 16px;
          line-height: 1.75;
          transition: all .3s;

          &:before {
            position: absolute;
            right: 0;
            top: 50%;
            width: 20px;
            height: 20px;
            background: var(--ic-outlink02) no-repeat center;
            background-size: contain;
            transform: translateY(-50%);
            transition: background .3s;
            content: '';
          }

          &:hover {
            color: $color-point;
            &:before {
              background: url(../img/common/btn-outlink-sm-on.png) no-repeat center;
              background-size: contain;
            }
          }

          &-list {
            margin-top: 80px;
          }

          &-item {
            margin-top: 30px;

            &:first-child {
              margin-top: 0;
            }
          }

          &-desc {
            margin-top: 8px;
            font-weight: 700;
            font-size: 14px;
            color: $color-gray2;
            line-height: 1.86;
          }
        }
      }
    }

    .content05,
    .content06 {
      position: relative;
      background: white;
      z-index: 2;
      background-color: var(--background01);
    }

    .content05 {
      padding-top: 200px;

      .section {
        &__video-wrap {
          overflow: hidden;
          position: relative;
          margin-top: 100px;
          height: 644px;

          .image-deco,
          .section__video {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            transform: translate(-50%, -50%);
          }

          .image-deco {
            height: 100%;
            min-height: auto;
          }

          .section__video {
            width: 100%;
            height: auto;
            min-height: auto;
            // object-fit: none;
          }
        }

        &__contents {
          &-list {
            display: flex;
            justify-content: space-between;
            padding-right: 218px;
            margin-top: 80px;
          }

          &-item {
            width: 390px;
            color: $color-default;

            .content {
              &-title {
                font-weight: 800;
                font-size: 22px;
                line-height: 1.55;
                letter-spacing: -0.6px;
                color: var(--default);
              }

              &-desc {
                margin-top: 20px;
                font-size: 16px;
                line-height: 1.75;
                letter-spacing: -0.4px;
                color: var(--color-sub);

                &2 {
                  display: block;
                  margin-top: 24px;
                  font-weight: 700;
                  font-size: 12px;
                  color: var(--flowerDesc);
                  line-height: 1.67;
                }
              }
            }
          }
        }
      }
    }

    .content06 {
      padding-top: 200px;
      padding-bottom: 160px;

      .accordion {
        margin-top: 80px;
        margin-right: 60px;

        &__item {
          border-top: 1px solid var(--border01);

          &:last-child {
            border-bottom: 1px solid var(--border01);
          }

          &:hover {
            .accordion__title {
              color: $color-point;

              &::after {
                background: url(/img/common/ic-accordion-orange.png) no-repeat;
                background-size: 100%;
              }
            }
          }

          &.#{$activeClass} {
            .accordion__title::after {
              background-image: var(--ic-accordion-close);
            }

            &:hover {
              .accordion__title::after {
                background-image: url(/img/common/ic-accordion-close-orange.png);
              }
            }
          }
        }

        &__title {
          position: relative;
          display: block;
          width: 100%;
          padding: 36px 0;
          font-weight: 700;
          font-size: 26px;
          line-height: 1.46;
          text-align: left;
          letter-spacing: -0.8px;
          transition: all 0.3s;
          color: var(--default);

          &::after {
            position: absolute;
            top: 50%;
            right: 30px;
            width: 32px;
            height: 32px;
            background: var(--ic-accordion) no-repeat;
            background-size: 100%;
            transform: translateY(-50%);
            transition: all 0.3s;
            content: '';
          }
        }

        &__content-inner {
          padding-bottom: 40px;
        }
      }

      .system {
        &__outlink {
          display: inline-flex;
          align-items: center;
          gap: 16px;
          width: 24%;
          height: 100px;
          border-bottom: 1px solid var(--border01);
          box-sizing: border-box;
          color: var(--default);

          &.border-none {
            border-bottom: 0;
          }

          &-wrap {
            display: flex;
            gap: 1.33%;
            flex-wrap: wrap;
          }

          &-icon {
            display: inline-block;
            width: 46px;
            height: 46px;
            background: white;
            border: 1px solid $color-gray4;
            border-radius: 12px;
            box-sizing: border-box;

            &.ic-shopping {
              background: none;
              border: none;
            }
            &.ic-dcommerce {
              background: none;
              border: none;
            }
            &.ic-cloud {
              background: none;
              border: none;
              width: 48px;
              height: 48px;
            }
          }

          &-text {
            font-weight: 700;
            line-height: 1.75;
          }
        }
      }
    }
  }
}

.theme-dark {
  .navercorp.project-flower {
    .half__img {
      .image {
        &.white {
          display: none;
        }

        &.dark {
          display: block;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.project-flower {
      .half {
        &__info {
          padding: 105px 106px;
        }
      }

      .content05 {
        padding-top: 160px;

        .section {
          &__video-wrap {
            margin-top: 80px;
            height: 540px;
          }

          &__contents {
            &-list {
              padding-right: 162px;
              margin-top: 60px;
            }

            &-item {
              width: 340px;

              .content {
                &-title {
                  font-size: 18px;
                }

                &-desc {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .content06 {
        padding-top: 180px;

        .system {
          &__outlink {
            &.border-none-pc {
              border-bottom: none;
            }
            &.border-pc {
              border-bottom: 1px solid var(--border01);
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.project-flower {
      .js-nav-section {
        &:first-of-type {
          padding-top: 0;
        }
      }
      .track-animation {
        &__highlight-text {
          text-align: center;
        }
      }

      .half {
        &__list {
          flex-direction: column;
          border: none;
        }

        &__box {
          height: auto;
          min-height: 434px;
          &:last-child {
            border-bottom: none;
          }
        }

        &__img {
          position: static;
          height: 768px;

          .image {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 58.59%;
            height: auto;
            transform: translate(-50%, -50%);
          }
        }

        &__info {
          padding: 40px 30px 190px;

          .title {
            margin-top: 15px;
            font-size: 48px;
          }

          .text {
            margin-top: 18px;

            br {
              display: none;
            }
          }

          .number {
            font-size: 56px;
          }
        }
      }

      .content04 {
        .section {
          &__project-flower {
            display: block;

            &>div {
              &:last-child {
                padding-bottom: 160px;
              }
            }
          }

          &__outlink {
            &-list {
              margin-top: 47px;
            }

            &-dest {
              margin-top: 7px;
            }
          }
        }
      }

      .content05 {
        padding-top: 0;

        .section {
          &__video-wrap {
            margin-top: 60px;
            height: 406px;

            .image-deco {
              width: 100%;
              height: auto;
            }
          }

          &__contents {
            &-list {
              gap: 62px;
              padding-right: 0;
              margin-top: 50px;
            }

            &-item {
              width: auto;

              .content {
                &-title {
                  font-size: 16px;
                }

                &-desc {
                  margin-top: 16px;
                  font-size: 12px;

                  &2 {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }

      .content06 {
        padding-top: 140px;
        padding-bottom: 140px;

        .accordion {
          margin-right: 0;
          margin-top: 60px;

          &__content-inner {
            padding-bottom: 30px;
          }

          &__title {
            padding: 27px 0;
            font-size: 22px;

            &::after {
              right: 19px;
            }
          }
        }

        .system {
          &__outlink {
            width: 31.2%;
            height: 90px;

            &.border-none-tb {
              border-bottom: none;
            }

            &.border-tb {
              border-bottom: 1px solid var(--border01);
            }

            &-wrap {
              gap: 3.2%;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.project-flower {

      .track-animation {
        &__highlight-area {
          width: 648px;
        }
        &__highlight-text {
          text-align: left;
        }
      }

      .half {

        &__box {
          &:last-child {
            border-bottom: none;
          }
        }

        &-list{
          &:last-child{
            .half__info{
              padding: 36px 30px 120px;

            }
          }
        }

        &__info {
          padding: 30px 30px 180px;

          .title {
            margin-top: 21px;
            font-size: 42px;

            br {
              display: block;
            }
          }

          .text {
            margin-top: 21px;
            line-height: 1.67;

            br {
              display: block;
            }
          }

          .number {
            font-size: 48px;
          }
        }
      }

      .content04 {
        .section {
          &__content {
            padding: 30px 30px 180px;
          }

          &__outlink {
            &-list {
              margin-top: 50px;
            }
          }
        }
      }

      .content05 {
        .section {
          &__video-wrap {
            margin-top: 60px;
            height: 298px;

            // .image-deco {
            //   width: 80px;
            // }

            .section__video {
              width: 100%;
              height: auto;
            }
          }

          &__contents {
            &-list {
              display: block;
              padding-right: 0;
            }

            &-item {
              margin-top: 60px;

              &:first-child {
                margin-top: 0;
              }

              .content {
                &-title {
                  font-size: 16px;
                }

                &-desc {
                  font-size: 14px;

                  &2 {
                    margin-top: 10px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      .content06 {
        padding-bottom: 120px;

        .accordion {
          margin-top: 40px;

          &__title {
            padding: 20px 0;
            font-size: 18px;

            &::after {
              width: 18px;
              height: 18px;
            }
          }
        }

        .system {
          &__outlink {
            width: 48.5%;
            height: 84px;

            &.border-none-tb-sm {
              border-bottom: none;
            }

            &.border-tb-sm {
              border-bottom: 1px solid var(--border01);
            }

            &-wrap {
              gap: 1.5%;
            }


            &-text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.project-flower {
      .track-animation {
        &__cover {
          background-image: url(/img/mo/project-flower-main.png);
        }

        &__highlight-area {
          width: auto;
          max-width: 375px;
          text-align: left;
        }

        &__highlight-text {
          padding: 0 27px;
        }
      }

      .half {
        &__img {
          height: auto;

          .image {
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            transform: none;
          }
        }

        &__box {
          height: auto;
          min-height: 334px;
          &:last-child {
            border-bottom: none;
          }
        }

        &__info {
          padding: 20px 20px 120px;

          .title {
            margin-top: 16px;
            font-size: 32px;
          }

          .text {
            margin-top: 20px;
            font-size: 16px;
            line-height: 1.75;

            br {
              display: none;
            }
          }

          .number {
            font-size: 38px;
          }
        }
      }

      .content04 {
        .section {
          &__project-flower {
            &>div {
              &:last-child {
                padding-bottom: 120px;
              }
            }
          }

          &__outlink {
            &-item {
              margin-top: 30px;
            }

            &-desc {
              margin-top: 6px;
            }
          }
        }
      }

      .content05 {
        .section {
          &__video-wrap {
            height: 400px;
            margin: 60px -20px 0 -20px;

            .image-deco {
              width: auto;
              height: 100%;
            }

            .section__video {
              width: auto;
              height: 100%;
            }
          }

          &__contents {
            &-list {
              margin-top: 60px;
            }

            &-item {
              .content {
                &-desc {
                  margin-top: 16px;

                  &2 {
                    margin-top: 20px;
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }

      .content06 {
        padding-top: 100px;
        padding-bottom: 100px;

        .accordion {
          margin-top: 40px;

          &__title {
            display: flex;
            align-items: center;
            padding: 19px 28px 19px 0;
            font-size: 16px;

            &::after {
              right: 10px;
            }
          }
        }

        .system {
          &__outlink {
            width: 100%;

            &.border-none-mo {
              border-bottom: none;
            }

            &.border-mo {
              border-bottom: 1px solid var(--border01);
            }

            &-wrap {
              gap: 0;
            }
          }
        }
      }
    }
  }
}
