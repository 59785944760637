.#{$component-name} {
  &.media-brand-video {
    .tab__area {
      margin-bottom: 100px;
    }
  }

  .section-brand-video {
    .card-contents {
      &__item {
        &-inner {
          &:hover {
            .card-contents__img {
              &::after {
                background: url(/img/common/ic-media-play-hover.png) no-repeat center;
                background-size: cover;
              }

              .img {
                transform: scale(1.05);
              }
            }
          }
        }
      }

      &__img {
        &::after {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 40px;
          height: 40px;
          background: url(/img/common/ic-media-play.png) no-repeat center;
          background-size: cover;
          content: '';
          transition: background .3s;
        }

        .img {
          transition: transform .3s;
        }
      }
      
      .contents-nodata {
        width: 100%;
        
        .search-result__text {
          display: block;
          text-align: center;
        }
      }

    }

    .pagination {
      margin-top: 100px;
    }
  }
}

html.theme-dark {
  .navercorp.media-brand-video {
    .dropdown__select {
      &:hover {
        box-shadow: 0px 0px 0px 1px #6e6e73;
      }
    }

    .input__box {
      &:hover {
        box-shadow: 0px 0px 0px 1px #6e6e73;
      }

      &.is-active {
        box-shadow: 0px 0px 0px 1px #6e6e73;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    .section-brand-video {
      .card-contents {
        &__img {
          &::after {
            width: 36px;
            height: 36px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.media-brand-video {
      .tab__area {
        margin-bottom: 75px;
      }
    }

    .section-brand-video {
      .pagination {
        margin-top: 80px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.media-brand-video {
      .tab__area {
        margin-bottom: 60px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {}
}
