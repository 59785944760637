.#{$component-name} {
  &.companion {
    .container {
      padding-bottom: 0;
    }

    .track-animation {
      &__highlight-area {
        width: 1370px;
      }

      &__highlight-text {
        text-align: center;
      }
    }

    .js-nav-section {
      padding-bottom: 200px;

      &.content06 {
        padding-bottom: 96px;
      }
    }

    .section__right-box {
      max-width: none;
      padding: 0 60px;
      padding-left: 26.77%;
    }

    .cover {
      position: relative;
    }

    .content03 {
      .cover {
        background: url(../img/pc/tech-develop-main.jpg) no-repeat center / cover;
      }

      .info {
        &__thumbnail-list {
          max-width: 1800px;
          margin: 160px auto 0;
        }

        &__thumbnail-item {
          width: calc((100% - 60px) / 4);

          &:nth-child(n + 5) {
            margin-top: 55px;
          }
        }

        &__title {
          margin-top: 20px;
        }

        &__desc {
          margin-top: 0;
          color: $color-white;
          letter-spacing: -0.5px;
          width: max-content;
        }

        &__year {
          margin-top: 8px;
          font-size: 16px;
          line-height: 1.88;
          letter-spacing: -0.5px;
          color: #a1a1a6;
        }
      }
    }

    .content04 {
      .cover {
        background: url(../img/pc/tech-edu-main.jpg) no-repeat center / cover;
      }
    }

    .content05 {
      .cover {
        background: url(../img/pc/tech-synergy-main.jpg) no-repeat center / cover;
      }

      .info {
        &__thumbnail-list {
          margin-top: 160px;
        }

        &__contents {
          padding-right: 0;
        }

        &__link {
          &:hover {
            .info__title {
              color: $color-point;
              transition: color .3s;
            }
            .info__title::after {
              background: url(../img/common/btn-outlink-on.svg);
              background-size: contain;
            }
          }
        }

        &__title {
          &::after {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-left: 3px;
            background-image: url(/img/common/ic-outlink-white3.png);
            background-repeat: no-repeat;
            background-size: contain;
            vertical-align: middle;
            transition: background .3s;
            content: '';
          }
        }

        &__sub-title {
          margin-top: 24px;
          font-weight: 700;
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
          color: $color-white;
        }

        &__desc {
          margin-top: 5px;
          letter-spacing: -0.5px;
        }
      }
    }

    .content06 {
      .cover {
        background: url(/img/pc/tech-research-main.jpg) no-repeat center / cover;
      }
    }

    .companion {
      &__edu {
        display: flex;
        justify-content: space-between;
        padding: 60px 0 54px;
        border-top: 1px solid rgba($color-white, 0.2);

        &:first-child {
          margin-top: 160px;
          padding: 0 0 54px;
          border: none;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &-img {
          width: 663px;
        }

        .title {
          width: 502px;
          margin-right: 180px;
          font-weight: 800;
          font-size: 38px;
          line-height: 1.42;
          letter-spacing: -1.5px;
          color: $color-white;
        }

        .info {
          margin-top: 40px;

          &__item {
            padding: 26px 20px 26px 0;
            border-top: 1px solid rgba($color-white, 0.2);
            &:first-child {
              border-top: 0;
            }
          }

          &__link {
            width: 100%;
            
            &:hover {
              .info__arrow {
                background-image: url(/img/common/ic-outlink-circle-black-hover.png);
                background-size: contain;
              }
            }
          }

          &__head {
            display: flex;
            justify-content: space-between;
          }

          &__arrow {
            background-image: url(/img/common/ic-outlink-gray.png);
            background-repeat: no-repeat;
            background-size: contain;
            width: 24px;
            height: 24px;
            margin-top: 3px;
            transition: background .3s;
          }

          &__title {
            margin-top: 0;
            font-weight: 800;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
            color: $color-white;
          }

          .text-box {
            margin-top: 16px;
          }

          .text {
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
            color: #86868b;

            &:last-child {
              margin-top: 6px;
            }
          }
        }
      }

      &__research {
        margin-top: 160px;

        &-item {
          display: flex;
          justify-content: space-between;
          padding: 53px 0 50px;
          border-bottom: 1px solid rgba($color-white, 0.2);

          &:first-child {
            padding-top: 0;
          }
        }

        &-text {
          .title {
            font-weight: 800;
            font-size: 38px;
            line-height: 1.42;
            letter-spacing: -1.5px;
            color: $color-white;
          }

          .text-box {
            margin-top: 32px;
          }

          .text {
            font-weight: 700;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
            color: #86868b;

            &:last-child {
              margin-top: 8px;
            }
          }
        }

        &-img {
          width: 435px;
        }
      }

      &__global {
        padding-top: 60px;

        .section__title {
          font-weight: 800;
          font-size: 38px;
          line-height: 1.42;
          letter-spacing: -1.5px;
          color: $color-white;
        }

        &-wrap {
          margin-top: 70px;

          &.is-active {
            .dotted-line {
              animation: showMap 0.3s linear forwards;
              animation-delay: 1s;
            }

            .nation-box {
              animation: showMap 0.3s linear forwards;

              &:after {
                animation: underToTop 0.3s linear forwards;
                animation-delay: 0.3s;
              }
            }
          }
        }

        &-img {
          position: relative;
          width: 1345px;
        }

        .dotted-line {
          position: absolute;
          top: 56px;
          left: 206px;
          width: 844px;
          opacity: 0;
        }

        .nation {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .nation-box {
          position: absolute;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background-color: #ff5f00;

          &:after {
            position: absolute;
            top: -54px;
            left: 50%;
            height: 46px;
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateX(-50%);
            opacity: 0;
            content: '';
          }

          &.france {
            top: 187px;
            left: 182px;

            &:after {
              width: 76px;
              background-image: url(/img/pc/france.png);
            }
          }

          &.south-east-asia {
            top: 316px;
            left: 438px;

            &:after {
              width: 135px;
              background-image: url(/img/pc/south-east-asia.png);
            }
          }

          &.korea {
            top: 179px;
            left: 529px;

            &:after {
              width: 68px;
              background-image: url(/img/pc/korea.png);
            }
          }

          &.japan {
            top: 158px;
            left: 605px;

            &:after {
              width: 70px;
              background-image: url(/img/pc/japan.png);
            }
          }

          &.canada {
            top: 118px;
            right: 385px;

            &:after {
              width: 80px;
              background-image: url(/img/pc/canada.png);
            }
          }

          &.united-states {
            top: 179px;
            right: 280px;

            &:after {
              width: 120px;
              background-image: url(/img/pc/united-states.png);
            }
          }
        }

        &-list {
          margin-top: 100px;
        }

        &-item {
          display: flex;
          border-top: 1px solid rgba($color-white, 0.2);

          .text-box {
            margin-right: 140px;
          }

          .info {
            flex: 1;
          }

          .info-box {
            display: flex;
            justify-content: space-between;
            min-height: 171px;
            padding: 24px 0 64px;
            border-top: 1px solid rgba($color-white, 0.2);

            &:first-child {
              border-top: 0;
            }
          }

          .country {
            width: 201px;
            padding-top: 24px;
            font-weight: 800;
            font-size: 24px;
            line-height: 1.58;
            letter-spacing: -0.01px;
          }

          .text {
            width: 306px;
            margin-right: 129px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
            opacity: 0.6;
          }

          .img {
            display: flex;
            flex-direction: column;
          }

          img {
            height: auto;
            object-fit: contain;

            &:first-child {
              margin-bottom: 26px;
            }

            &.naver-labs-europe {
              width: 250px;
            }

            &.naver-cloud {
              width: 191px;
            }

            &.naver-labs {
              width: 193px;
            }

            &.seoul-university {
              width: 176px;
            }

            &.kaist {
              width: 126px;
            }

            &.universitat-tubingen {
              width: 171px;
            }

            &.zholding {
              width: 171px;
            }

            &.hust {
              width: 103px;
            }

            &.ptit {
              width: 50px;
            }

            &.toronto {
              width: 149px;
            }

            &.nyu {
              width: 105px;
            }

            &.washington {
              width: 152px;
            }
          }
        }

        &-link {
          display: flex;
          align-items: center;

          &:after {
            display: block;
            position: relative;
            top: -13px;
            background-image: url(/img/common/ic-outlink-gray.png);
            background-repeat: no-repeat;
            background-size: contain;
            width: 24px;
            height: 24px;
            margin-left: 20px;
            transition: background .3s;
            content: '';
          }
          
          &:hover {
            &:after {
              background-image: url(/img/common/ic-outlink-circle-black-hover.png);
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.companion {
      .section__right-box {
        padding: 0 40px;
        padding-left: 20.5%;
      }

      .info__list-container.type-right {
        padding-left: 20.5%;
      }

      .js-nav-section {
        padding-bottom: 180px;
      }

        .full-sticky {
          .cont__desc {
            padding-left: 43.3%;
          }
        }

      .content03 {
        .cont__wrap {
          .br_pc {
            display: none;
          }
        }

        .info {
          &__thumbnail-list {
            max-width: 1520px;
            margin-top: 140px;
          }
        }
      }

      .content05 {
        .info {
          &__thumbnail-list {
            margin-top: 140px;
          }

          &__title {
            margin-top: 25px;
          }
        }
      }

      .companion {
        &__edu {
          &:first-child {
            margin-top: 120px;
          }

          .title {
            width: 507px;
            margin-right: 120px;
          }

          &-img {
            width: 605px;
          }
        }

        &__research {
          &-img {
            width: 397px;
          }

          &-item {
            padding: 40px 0;
          }

          &-text {
            .title {
              display: block;
              margin-top: 30px;
            }
          }
        }

        &__global {
          &-wrap {
            &.is-active {
              .nation-box {
                &:after {
                  animation: underToTop-pc 0.3s linear forwards;
                  animation-delay: 0.3s;
                }
              }
            }
          }

          &-img {
            width: 1200px;
          }

          .dotted-line {
            top: 51px;
            left: 183px;
            width: 753px;
          }

          .nation-box {
            width: 17px;
            height: 17px;

            &:after {
              top: -47px;
              height: 41px;
            }

            &.france {
              top: 166px;
              left: 162px;

              &:after {
                width: 68px;
              }
            }

            &.south-east-asia {
              top: 280px;
              left: 390px;

              &:after {
                width: 120px;
              }
            }

            &.korea {
              top: 157px;
              left: 471px;

              &:after {
                width: 61px;
              }
            }

            &.japan {
              top: 140px;
              left: 539px;

              &:after {
                width: 62px;
              }
            }

            &.canada {
              top: 103px;
              right: 343px;

              &:after {
                width: 72px;
              }
            }

            &.united-states {
              top: 157px;
              right: 250px;

              &:after {
                width: 107px;
              }
            }
          }

          &-item {
            .text-box {
              margin-right: 183px;
            }

            .text {
              margin-right: 59px;
            }

            .img {
              margin-top: 5px;
            }
          }

          &-link {
            margin-top: 5px;
            &::after {
              top: -11px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.companion {
      .js-nav-section {
        padding-bottom: 120px;
      }
      .track-animation {
        &__highlight-area {
          width: auto;
        }

        &__highlight-text {
          padding: 0 50px;
        }
      }

      .section__right-box {
        padding: 0 30px;
      }

      .info__list-container.type-right {
        padding: 0 30px;
      }

      .content03 {
        .info {
          &__thumbnail-list {
            justify-content: start;
            max-width: none;
            margin-top: 80px;
            padding: 0 30px;
          }

          &__thumbnail-item {
            width: calc((100% - 40px) / 3);
            margin-right: 20px;

            &:nth-child(3n) {
              margin: 0;
            }

            &:nth-child(n + 4) {
              margin-top: 80px;
            }
          }

          &__contents {
            padding-right: 0;
          }
        }
      }

      .content05 {
        .info {
          &__thumbnail-list {
            margin-top: 100px;
          }

          &__title {
            margin-top: 22px;
          }
        }
      }

      .content06 {
        padding-bottom: 42px !important;
      }

      .companion {
        &__edu {
          .title {
            flex-shrink: 0;
            width: 100%;
            max-width: 390px;
            margin-right: 101px;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          &-img {
            width: auto;
          }
        }

        &__research {
          margin-top: 100px;

          &-img {
            width: 472px;
          }

          &-text {
            width: 100%;
            max-width: 390px;
          }

          .title {
            margin-top: 0;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          &-item {
            &:last-child {
              border-bottom: 0;
            }
          }
        }

        &__global {
          padding-top: 80px;

          .section__title {
            font-size: 42px;
            line-height: 1.43;
            letter-spacing: -1.8px;
          }

          &-wrap {
            position: relative;
            height: 468px;
            margin-top: 60px;

            &.is-active {
              .nation-box {
                &:after {
                  animation: underToTop-tab 0.3s linear forwards;
                  animation-delay: 0.3s;
                }
              }
            }
          }

          &-img {
            position: absolute;
            top: 0;
            left: 50%;
            width: 1024px;
            height: 100%;
            transform: translateX(-50%);
          }

          .global-map {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
          }

          .dotted-line {
            top: 44px;
            left: 156px;
            width: 642px;
          }

          .nation-box {
            width: 15px;
            height: 15px;

            &:after {
              height: 35px;
            }

            &.france {
              top: 143px;
              left: 137px;

              &:after {
                width: 58px;
              }
            }

            &.south-east-asia {
              top: 240px;
              left: 332px;

              &:after {
                width: 102px;
              }
            }

            &.korea {
              top: 136px;
              left: 402px;

              &:after {
                width: 52px;
              }
            }

            &.japan {
              top: 121px;
              left: 459px;

              &:after {
                width: 53px;
              }
            }

            &.canada {
              top: 90px;
              right: 293px;

              &:after {
                width: 61px;
              }
            }

            &.united-states {
              top: 136px;
              right: 214px;

              &:after {
                width: 91px;
              }
            }
          }

          &-list {
            margin-top: 60px;
          }

          &-item {
            .text-box {
              margin-right: 102px;
            }

            .info-box {
              min-height: 150px;
              padding: 20px 0 78px;
            }

            .country {
              width: 144px;
              padding-top: 20px;
              font-size: 18px;
              line-height: 1.67;
              letter-spacing: -0.5px;
            }

            .text {
              width: 308px;
              margin-right: 0;
            }

            img {
              &:first-child {
                margin-bottom: 23px;
              }

              &.naver-labs-europe {
                width: 215px;
              }

              &.naver-cloud {
                width: 164px;
              }

              &.naver-labs {
                width: 166px;
              }

              &.seoul-university {
                width: 151px;
              }

              &.kaist {
                width: 108px;
              }

              &.universitat-tubingen {
                width: 147px;
              }

              &.zholding {
                width: 147px;
              }

              &.hust {
                width: 89px;
              }

              &.ptit {
                width: 43px;
              }

              &.toronto {
                width: 128px;
              }

              &.nyu {
                width: 90px;
              }

              &.washington {
                width: 131px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.companion {
      .js-nav-section {
        padding-bottom: 100px;
      }

      .content03 {
        .info {
          &__thumbnail-list {
            margin-top: 100px;
          }

          &__thumbnail-item {
            width: calc((100% - 12px) / 2);
            margin-right: 12px;

            &:nth-child(3n) {
              margin-right: 12px;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }

            &:nth-child(n + 3) {
              margin-top: 60px;
            }

            &:last-child {
              .info__contents {
                min-height: 115px;
              }
            }
          }

          &__title {
            margin-top: 15px;
          }

          &__desc {
            margin-top: 3px;
            letter-spacing: -0.3px;
          }

          &__year {
            margin-top: 10px;
            font-size: 14px;
            letter-spacing: -0.3px;
          }
        }
      }

      .content05 {
        .info {
          &__title {
            margin-top: 17px;
          }

          &__sub-title {
            margin-top: 20px;
            font-size: 16px;
            line-height: 1.88;
            letter-spacing: -0.5px;
          }

          &__desc {
            letter-spacing: -0.3px;
          }
        }
      }

      .content06 {
        padding-bottom: 10px !important;
      }

      .companion {
        &__edu {
          padding: 30px 0 50px;

          &:first-child {
            margin-top: 80px;
            padding: 0 0 50px;
          }

          .title {
            width: 280px;
            margin-right: 80px;
            font-size: 22px;
            line-height: 1.73;
            letter-spacing: -0.4px;
          }

          .info {
            margin-top: 30px;

            &__title {
              font-size: 18px;
              line-height: 1.67;
              letter-spacing: -0.5px;
            }

            &__item {
              padding: 20px 10px 20px 0;
            }

            .text-box {
              margin-top: 10px;
            }

            .text {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;

              &:last-child {
                margin-top: 10px;
              }
            }
          }
        }

        &__research {
          margin-top: 101px;

          &-item {
            padding: 41px 0 30px;
          }

          &-img {
            width: 348px;
          }

          &-text {
            .title {
              font-size: 18px;
              line-height: 1.89;
              letter-spacing: -0.5px;
            }

            .text-box {
              margin-top: 12px;
            }

            .text {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;

              &:last-child {
                margin-top: 0;
              }
            }
          }
        }

        &__global {
          padding-top: 70px;

          .section__title {
            font-size: 36px;
            line-height: 1.39;
            letter-spacing: -1.8px;
          }

          &-wrap {
            height: 351px;
            margin-top: 40px;

            &.is-active {
              .nation-box {
                &:after {
                  animation: underToTop-tab-sm 0.3s linear forwards;
                  animation-delay: 0.3s;
                }
              }
            }
          }

          &-img {
            width: 768px;
          }

          .dotted-line {
            top: 33px;
            left: 115px;
            width: 484px;
          }

          .nation-box {
            width: 11px;
            height: 11px;

            &:after {
              height: 26px;
            }

            &.france {
              top: 106px;
              left: 104px;

              &:after {
                width: 44px;
              }
            }

            &.south-east-asia {
              top: 180px;
              left: 249px;

              &:after {
                width: 77px;
              }
            }

            &.korea {
              top: 101px;
              left: 301px;

              &:after {
                width: 39px;
              }
            }

            &.japan {
              top: 89px;
              left: 345px;

              &:after {
                width: 40px;
              }
            }

            &.canada {
              top: 66px;
              right: 220px;

              &:after {
                width: 46px;
              }
            }

            &.united-states {
              top: 101px;
              right: 160px;

              &:after {
                width: 69px;
              }
            }
          }

          &-list {
            margin-top: 60px;
          }

          &-item {
            display: block;
            min-height: auto;
            border-top: 0;

            &:nth-child(2) {
              .info-box {
                padding-bottom: 40px;
                &:last-child {
                  padding-bottom: 90px;
                }
              }
            }

            &:last-child {
              .info-box {
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }

            .img {
              margin-top: 0;
            }

            img {
              margin-bottom: 26px;

              &:first-child {
                margin-bottom: 26px;
              }

              &.naver-labs-europe {
                width: 200px;
              }

              &.naver-cloud {
                width: 153px;
              }

              &.naver-labs {
                width: 154px;
              }

              &.seoul-university {
                width: 141px;
              }

              &.kaist {
                width: 101px;
              }

              &.universitat-tubingen {
                width: 137px;
              }

              &.zholding {
                width: 137px;
              }

              &.hust {
                width: 82px;
              }

              &.ptit {
                width: 40px;
              }

              &.toronto {
                width: 119px;
              }

              &.nyu {
                width: 84px;
              }

              &.washington {
                width: 122px;
              }
            }

            .text-box {
              margin-right: 0;
              padding-bottom: 24px;
            }

            .info-box {
              display: block;
              min-height: auto;
              padding: 25px 0 90px;
              border-top: 1px solid rgba($color-white, 0.2);

              &:first-child {
                border-top: 1px solid rgba($color-white, 0.2);
              }
            }

            .country {
              width: auto;
              padding-top: 0;
              font-size: 24px;
              line-height: 1.58;
              letter-spacing: -0.01px;
            }

            .text {
              width: auto;
            }
          }

          &-link {
            margin-top: 0;

            &:after {
              top: -13px;
              width: 20px;
              height: 20px;
              margin-left: 11px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.companion {
      .track-animation {
        &__highlight-area {
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 30px;
          text-align: left;
        }
      }


      .section__right-box {
        padding: 0 20px;
      }

      .cover {
        background-image: url(../img/mo/tech-companion-bg.png);
      }

      .info__list-container.type-right {
        padding: 0 20px;
      }

      .content03 {
        padding-bottom: 0;
        .cover {
          background-image: url(/img/mo/tech-develop-main-mo.jpg);
        }
        .full-sticky {
          .cont__desc {
            padding: 0;
          }
        }
        .info {
          &__thumbnail-list {
            margin-top: 80px;
            padding: 0 20px;
          }

          &__thumbnail-item {
            width: 100%;
            margin-right: 0;
          }

          &__title {
            margin-top: 20px;
          }
        }
      }

      .content04 {
        margin-top: 160px;
        padding-bottom: 0;

        .cover {
          background-image: url(/img/mo/tech-edu-main-mo.jpg);
        }
      }

      .content05 {
        margin-top: 160px;
        padding-bottom: 0;

        .cover {
          background-image: url(/img/mo/tech-synergy-main-mo.jpg);
        }
      }

      .content06 {
        margin-top: 160px;
        padding-bottom: 70px !important;

        .cover {
          background-image: url(/img/mo/tech-research-main-mo.jpg);
        }

        .section__right-box {
          padding: 0;
        }
      }

      .companion {
        &__edu {
          display: block;
          border-top: 0;

          &:first-child {
            margin-top: 100px;
          }

          .title {
            width: 100%;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          &-img {
            width: 100%;
            margin-top: 40px;
          }

          .info__item {
            padding-right: 0;
          }

          .info__arrow {
            width: 20px;
            height: 20px;
          }
        }

        &__research {
          padding: 0 20px;

          &-item {
            display: block;
            padding-top: 30px;
            border-bottom: 0;

            .title {
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.6px;
            }

            .text {
              &:last-child {
                margin-top: 4px;
              }
            }
          }

          &-img {
            width: 100%;
            margin-top: 40px;
          }
        }

        &__global {
          padding-top: 30px;

          .section__title {
            padding: 0 20px;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
          }

          &-wrap {
            overflow-y: auto;
            height: 549px;

            &.is-active {
              .nation-box {
                &:after {
                  animation: underToTop-pc 0.3s linear forwards;
                  animation-delay: 0.3s;
                }
              }
            }
          }

          &-img {
            left: 0;
            transform: translate(0);
            width: 1200px;
          }

          .dotted-line {
            top: 51px;
            left: 183px;
            width: 753px;
          }

          .nation-box {
            width: 17px;
            height: 17px;

            &:after {
              top: -47px;
              height: 41px;
            }

            &.france {
              top: 166px;
              left: 162px;

              &:after {
                width: 68px;
              }
            }

            &.south-east-asia {
              top: 280px;
              left: 390px;

              &:after {
                width: 120px;
              }
            }

            &.korea {
              top: 157px;
              left: 471px;

              &:after {
                width: 61px;
              }
            }

            &.japan {
              top: 140px;
              left: 539px;

              &:after {
                width: 62px;
              }
            }

            &.canada {
              top: 103px;
              right: 343px;

              &:after {
                width: 72px;
              }
            }

            &.united-states {
              top: 157px;
              right: 250px;

              &:after {
                width: 107px;
              }
            }
          }

          &-list {
            padding: 0 20px;
          }
        }
      }
    }
  }
}
