.#{$component-name} {
  .contents-detail-popup, .contents-detail-popup2 {
    overflow: auto;
    height: 100vh;
    min-height: 865px;
    background-color: var(--reverse);

    .dimmed {
      display: none;
    }

    &__inner {
      position: relative;
      flex-wrap: wrap;
      max-width: 1800px;
      margin: 0 auto;
    }

    &__box {
      width: 100%;
      margin: 0;

      .swiper-pagination {
        bottom: 20px;
        max-width: 1300px;
        padding-right: 20px;
        color: $color-white;
        font-size: 16px;
        font-weight: 800;
        text-align: right;
        pointer-events: none;
      }
    }

    &__item {
      display: flex;
    }

    &__video {
      position: relative;
      width: 1300px;
      padding-top: 40.6112%;
      flex-shrink: 0;
      background-size: cover;

      .pzp {
        position: absolute;
        top: 0;
        left: 0;
        height: 100% !important;
      }
    }

    &__image {
      width: 1300px;
      height: 865px;
      background: no-repeat center;
      background-size: cover;
    }

    &__txt {
      margin-left: 65px;

      .category {
        display: inline-block;
        margin-right: 5px;
        padding: 0 8px;
        background-color: var(--paginationBg);
        color: $color-white;
        font-size: 12px;
        font-weight: 800;
        line-height: 24px;
      }

      .title {
        display: block;
        margin-top: 12px;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: var(--default);
      }

      .type {
        display: block;
        margin-top: 10px;
        color: #86868b;
        font-size: 14px;
        font-weight: 700;
      }

      .ir-common__button.download.type3 {
        width: 200px;
        margin-top: 40px;
        font-size: 12px;
      }
    }

    &__btn {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 92px;
      height: 44px;
      margin-left: 465px;

      .swiper-button-prev, .swiper-button-next {
        width: 44px;
        height: 44px;
        background-color: var(--paginationBg);
        background: url(/img/common/swiper-btn.png) no-repeat center;
        background-size: 100% auto;
        transition: all .3s;

        &::after {
          content: none;
        }

        &.swiper-button-disabled {
          opacity: 1;
          background: url(/img/common/swiper-btn-disable.png) no-repeat center;
          background-size: 100% auto;
        }

        &:hover {
          background: url(/img/common/swiper-btn-hover.png) no-repeat center;
          background-size: 100% auto;
        }

        @at-root .theme-dark & {
          background: url(/img/common/swiper-btn-gray.png) no-repeat center;
          background-size: 100% auto;

          &.swiper-button-disabled {
            opacity: 0.3;
            background: url(/img/common/swiper-btn-disable.png) no-repeat center;
            background-size: 100% auto;
          }
        }
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-button-next {
        right: 0;
        transform: rotate(180deg);
      }
    }

    .btn-close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 110;
      @include sp-desktop('ic-close-popup');
      
      @at-root .theme-dark & {
        @include sp-desktop('ic-close-popup-w');
      }

    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    .contents-detail-popup, .contents-detail-popup2 {

      &__inner {
        max-width: 1520px;
      }

      &__box {
        .swiper-pagination {
          max-width: 1108px;
        }
      }

      &__video {
        width: 1108px;
        height: 623px;
      }

      &__image {
        width: 1108px;
        padding-top: 737px;
      }

      &__txt {
        margin-left: 48px;
      }

      &__btn {
        margin-left: 396px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .contents-detail-popup, .contents-detail-popup2 {
      padding: 100px 0 0;
      min-height: 0;

      &__inner {
        align-items: flex-start;
        max-width: none;
      }

      &__box {
        .swiper-pagination {
          top: 0;
          bottom: auto;
          max-width: none;
          padding-top: calc(66.7% - 38px);
        }
      }

      &__item {
        flex-direction: column;
      }

      &__video {
        width: 100%;
        height: auto;
        padding-top: 56.348%;
      }

      &__image {
        width: 100vw;
        height: 0;
        padding-top: 66.7%
      }

      &__txt {
        min-height: 688px;
        margin-left: 0;
        padding: 30px 30px 50px;
      }

      &__btn {
        left: 30px;
        bottom: 50px;
        margin-left: 0;
      }

      .btn-close {
        top: 10px;
        right: 10px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .contents-detail-popup, .contents-detail-popup2 {
      padding: 80px 0 0;

      &__box {
        .swiper-pagination {
          padding-top: calc(66.668% - 34px);
          font-size: 14px;
        }
      }

      &__image {
        padding-top: 66.668%;
      }

      &__txt {
        min-height: 412px;
        height: auto;
        padding: 20px 30px 50px;

        .title {
          font-size: 20px;
          line-height: 34px;
          letter-spacing: -.01px;
        }

        .type {
          margin-top: 6px;
          font-size: 12px;
          line-height: 22px;
        }

        .ir-common__button.download.type3 {
          margin-top: 30px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .contents-detail-popup, .contents-detail-popup2 {

      &__box {
        .swiper-pagination {
          padding-top: calc(66.668% - 32px);
        }
      }

      &__txt {
        min-height: 375px;
        padding: 20px 20px 50px;
      }

      &__btn {
        left: 20px;
      }
    }
  }
}
