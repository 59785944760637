.#{$component-name} {
  &.ir-stock {
    .container {
      overflow: hidden;
    }

    .pagination {
      margin-top: 52px;
    }

    .section__title {
      margin-top: 100px;
    }

    .stock-arrow {
      display: flex;
      align-items: center;

      &::before {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 6px;
        content: '';
      }

      &.up {
        &::before {
          background: url(../img/common/ic-stock-up.png) no-repeat center;
          background-size: contain;
        }
      }

      &.down {
        &::before {
          background: url(../img/common/ic-stock-down.png) no-repeat center;
          background-size: contain;
        }
      }

      &.upper {
        &::before {
          background: url(../img/common/ic-stock-up2.png) no-repeat center;
          background-size: contain;
        }
      }

      &.lower {
        &::before {
          background: url(../img/common/ic-stock-down2.png) no-repeat center;
          background-size: contain;
        }
      }
    }

    .#{$bdClass} {
      font-weight: 700;
      color: var(--box-text);
    }

    .#{$ebClass} {
      font-weight: 800;
      color: var(--default);
    }

    .table {
      &__tbody {
        &-td {
          &.border {
            border-right: 1px solid var(--border01);
          }

          &.color-blue {
            color: #4078ff;
          }

          &.color-red {
            color: #ff2c2c;
          }

          &.listed-date {
            &:after {
              position: relative;
              left: 4px;
              font-size: 12px;
              content: '*';
            }
          }
        }
      }

      &__tfoot {
        padding: 20px 0;

        .disclaimer {
          position: relative;
          left: 4px;
          font-size: 12px;
          color: var(--secDesc);
          letter-spacing: -0.26px;
          line-height: 1.8;

          &:before {
            margin-right: 5px;
            content: '*';
          }
        }
      }

      &.table__price {
        margin: 0 59px 0 0;
        border: 0;

        .table {
          &__tbody {
            div {
              &:nth-child(3) {
                padding-left: 30px;
              }

              &:last-child {
                padding-right: 10px;
              }
            }

            &-td {
              padding-right: 30px;
              text-align: right;
            }

            &-list {
              &:last-child {
                .table__tbody-th {
                  color: var(--default);
                }

                .table__tbody-td {
                  padding-right: 10px;
                }
              }
            }
          }

          &__tfoot {
            border-top: 1px solid var(--border01);
          }

          &__tr {
            &:first-child {
              border-color: var(--border04);
            }

            &:last-child {
              border-bottom-color: var(--border04);
            }
          }
        }
      }

      &.table__transaction {
        display: flex;
        border-top: 0;
        margin-top: 0;

        .table__transaction-sell,
        .table__transaction-buy {
          width: 50%;
          flex-shrink: 0;
        }

        .table__transaction-sell {
          .table {
            &__thead {
              border-right: 1px solid var(--border01);

              &-th {
                &:last-child {
                  padding-right: 30px;
                }
              }
            }

            &__tbody {
              &-td {
                padding-right: 30px;
              }
            }
          }
        }

        .table__transaction-buy {
          .table {
            &__thead {
              &-th {
                &:first-child {
                  padding-left: 30px;
                }

                &:last-child {
                  padding-right: 10px;
                }
              }
            }

            &__tbody {
              &-th {
                padding-left: 30px;
              }

              &-td {
                padding-right: 10px;
              }
            }
          }
        }

        .table {
          &__thead {
            &-th {
              &:last-child {
                text-align: right;
              }
            }
          }

          &__tbody {
            &-td {
              text-align: right;
            }
          }

          &__tr {
            display: flex;

            &:first-child {
              border-color: var(--border04);
            }
          }
        }
      }
    }

    .tab__panels {
      margin: 0 auto;
    }

    .ir-stock {
      &__section {
        &.ir-stock-day {
          .section__title {
            margin-top: 160px;
          }

          .table {
            margin-top: 30px;

            &__thead {
              &-th {
                &:first-child {
                  flex: none;
                  width: 13.5%;
                }

                &:last-child {
                  flex: none;
                  width: 8%;
                }
              }
            }

            &__tbody {
              div {
                &:first-child {
                  flex: none;
                  width: 13.5%;
                }

                &:last-child {
                  flex: none;
                  width: 8%;
                }
              }

              &-th {
                font-weight: 400;
              }
            }

            &__info {
              margin-top: 15px;
            }
          }
        }
      }

      &__area {
        margin-top: 30px;
        font-size: 14px;
        line-height: 2.14;

        &-info {
          display: flex;
          align-items: start;
          padding: 30px 0;
          border-top: 1px solid var(--border04);
          border-bottom: 1px solid var(--border04);

          &:first-child {
            align-items: center;
          }

          &:last-child {
            padding: 0;
            border: none;
          }
        }

        &-group {
          display: flex;

          &:last-child {
            width: 515px;
            margin-left: 202px;

            .ir-stock__area-box {
              flex: 1;

              &:last-child {
                margin-left: 0;
              }
            }

            p {
              &:first-child {
                padding-bottom: 0;
              }

              span {
                margin-top: 6px;

                &:first-child {
                  width: auto;
                  margin-right: 0;
                }
              }
            }
          }

          strong {
            min-width: 300px;
            font-size: 44px;
            line-height: 1.55;
            color: var(--default);

            &:before {
              order: 1;
              width: 30px;
              height: 30px;
              margin-right: 0;
              margin-left: 4px;
            }

            &:after {
              display: inline-block;
              width: 1px;
              height: 80px;
              background-color: var(--border01);
              order: 2;
              margin-left: 85px;
              content: '';
            }
          }

          &:last-child {
            p {
              display: block;

              span {
                display: block;
              }
            }
          }
        }

        &-box {
          &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 20px;
          }

          p {
            display: flex;

            &:first-child {
              padding-bottom: 6px;
            }

            span {
              &:first-child {
                width: 70px;
                margin-right: 27px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ir-stock {
      .ir-stock {
        &__section {
          &.ir-stock-day {
            .table {
              &__thead {
                &-th {
                  &:first-child {
                    width: 13%;
                  }

                  &:last-child {
                    width: 9.5%;
                  }
                }
              }

              &__tbody {
                div {
                  &:first-child {
                    width: 13%;
                  }

                  &:last-child {
                    width: 9.5%;
                  }
                }
              }
            }
          }
        }

        &__area {
          &-group {
            &:last-child {
              width: 510px;
              margin-left: 151px;
            }

            strong {
              &:after {
                margin-left: 33px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir-stock {

      .section__title {
        margin-top: 80px;
      }

      .table {
        &.table__price {
          margin-right: 30px;

          .table {
            &__tbody {
              div {
                &:nth-child(3) {
                  padding-left: 20px;
                }
              }

              &-td {
                padding-right: 20px;
              }
            }
          }
        }

        &.table__transaction {
          .table__transaction-sell {
            .table {
              &__thead {
                &-th {
                  &:last-child {
                    padding-right: 20px;
                  }
                }
              }

              &__tbody {
                &-td {
                  padding-right: 20px;
                }
              }
            }
          }

          .table__transaction-buy {
            .table {
              &__thead {
                &-th {
                  &:first-child {
                    padding-left: 20px;
                  }
                }
              }

              &__tbody {
                &-th {
                  padding-left: 20px;
                }
              }
            }
          }
        }
      }

      .ir-stock {
        &__section {
          &.ir-stock-day {
            .section__title {
              margin-top: 120px;
            }

            .table {
              &__thead {
                &-th {
                  &:first-child {
                    width: 12%;
                  }

                  &:last-child {
                    width: 9.5%;
                  }
                }
              }

              &__tbody {
                div {
                  &:first-child {
                    width: 12%;
                  }

                  &:last-child {
                    width: 9.5%;
                  }
                }
              }
            }
          }
        }

        &__area {
          &-group {
            &:last-child {
              width: 435px;
              margin-left: 87px;
            }

            strong {
              min-width: 200px;
              font-size: 38px;
              line-height: 1.68;

              &:after {
                margin-left: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ir-stock {
      .table {
        font-size: 12px;
        line-height: 1.83;

        &__thead {
          &-th {
            padding: 14px 0;
          }
        }

        &__tbody {
          div {
            padding: 14px 0;
          }

          &-td {
            &.listed-date {
              &:after {
                left: 2px;
                font-size: 10px;
              }
            }
          }
        }

        &__tfoot {
          padding: 15px 0;
        }

        &.table__price {
          margin-right: 20px;

          .table {
            &__tbody {
              div {
                &:nth-child(3) {
                  padding-left: 16px;
                }

                &:last-child {
                  padding-right: 0;
                }
              }

              &-td {
                padding-right: 16px;
              }

              &-list {
                &:last-child {
                  .table__tbody-td {
                    padding-right: 0;
                  }
                }
              }
            }

          }
        }

        &.table__transaction {
          .table__transaction-sell {
            .table {
              &__thead {
                &-th {
                  &:last-child {
                    padding-right: 17px;
                  }
                }
              }

              &__tbody {
                &-td {
                  padding-right: 17px;
                }
              }
            }
          }

          .table__transaction-buy {
            .table {
              &__thead {
                &-th {
                  &:first-child {
                    padding-left: 16px;
                  }

                  &:last-child {
                    padding-right: 0
                  }
                }
              }

              &__tbody {
                &-th {
                  padding-left: 16px;
                }

                &-td {
                  padding-right: 0;
                }
              }
            }
          }
        }
      }

      .ir-stock {
        &__section {
          &.ir-stock-day {
            .section__title {
              margin-top: 100px;
            }

            .table {
              width: 964px;
              margin-top: 40px;

              &__wrap {
                overflow-x: scroll;
                margin-right: -30px;
                margin-top: 0;

                &::-webkit-scrollbar {
                  height: 4px;
                  margin-top: -2px;
                }

                &::-webkit-scrollbar-thumb {
                  border-radius: 2px;
                  background-color: #cececf;
                }
              }

              &__thead {
                &-th {
                  padding: 10px 0;

                  &:first-child {
                    width: 10.5%;
                  }

                  &:last-child {
                    width: 9%;
                  }
                }
              }

              &__tbody {
                div {
                  padding: 10px 0;

                  &:first-child {
                    width: 10.5%;
                  }

                  &:last-child {
                    width: 9%;
                  }
                }
              }
            }
          }
        }

        &__area {
          font-size: 12px;
          line-height: 1.83;

          &-group {
            &:last-child {
              width: 100%;
              margin-left: 44px;

              .ir-stock__area-box {
                &:last-child {
                  flex: none;
                  width: 20%;
                }
              }

              p {
                span {
                  margin-top: 4px;
                }
              }
            }

            strong {
              min-width: 100px;
              font-size: 28px;
              line-height: 1.71;

              &::before {
                margin-left: 5px;
                width: 24px;
                height: 24px;
              }

              &:after {
                height: 60px;
                margin-left: 15px;
              }
            }
          }

          &-box {
            &:last-child {
              margin-left: 15px;
            }

            p {
              &:first-child {
                padding-bottom: 4px;
              }

              span {
                &:first-child {
                  flex-shrink: 0;
                  margin-right: 13px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.ir-stock {
      .section__title {
        margin-top: 60px;
      }

      .table {
        &__tfoot {
          .disclaimer {
            font-size: 10px;
          }
        }

        &.table__price {
          .table {
            &__thead {
              &-th {
                padding: 10px 0;
              }
            }

            &__tbody {
              div {
                padding: 10px 0;

                &:nth-child(2) {
                  padding-right: 10px;
                }

                &:nth-child(3) {
                  padding-left: 10px;
                }
              }
            }
          }
        }

        &.table__transaction {
          margin-top: 60px;
          border-top: 1px solid $color-default;

          .table__transaction-sell {
            .table {
              &__thead {
                &-th {
                  &:last-child {
                    padding-right: 10px;
                  }
                }
              }

              &__tbody {
                &-td {
                  padding-right: 10px;
                }
              }
            }
          }

          .table__transaction-buy {
            .table {
              &__thead {
                &-th {
                  &:first-child {
                    padding-left: 10px;
                  }
                }
              }

              &__tbody {
                &-th {
                  padding-left: 10px;
                }
              }
            }
          }


          .table {
            &__thead {
              &-th {
                padding: 10px 0;
              }
            }

            &__tbody {
              div {
                padding: 10px 0;
              }
            }
          }
        }

        &__info {
          font-size: 10px;
          line-height: 1.8;
          text-align: left;
        }
      }

      .ir-stock {
        &__section {
          &.ir-stock-day {
            .section__title {
              margin-top: 100px;
            }

            .table__wrap {
              margin-right: -20px;
            }
          }
        }

        &__area {
          margin-top: 40px;

          &-group {
            width: 100%;
            padding: 20px 0;

            &:last-child {
              width: 100%;
              margin-left: 0;
              padding: 16px 0 30px;
              border-top: 1px solid #e4e6ea;
              
              @at-root .theme-dark & {
                border-top: 1px solid #6e6e73;
              }

              .ir-stock__area-box {
                &:last-child {
                  width: 23%;
                }
              }
            }

            strong {
              &:after {
                height: 48px;
                margin-left: 22px;
              }
            }
          }

          &-info {
            flex-direction: column;
            padding: 0;
          }
        }
      }

      .pagination {
        margin-top: 80px;
      }
    }
  }
}
