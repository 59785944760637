.#{$component-name} {
  &.ir {
    .cont__board {
      margin-top: 12px;
    }

    .board {
      &__inner {
        display: flex;
        max-width: 1800px;
        margin: 0 auto;
        padding-bottom: 76px;
      }

      &__main {
        width: 100%;

        .main {
          &__img {
            width: 100%;
            height: 100%;
            padding: 36px 36px 50px 36px;
            background: url(/img/pc/ir-board-main-bg.png) no-repeat center center;
            background-size: cover;
          }

          &__wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            height: 100%;
          }

          &__title {
            max-width: 307px;
            font-weight: 800;
            font-size: 64px;
            line-height: 1.38;
            letter-spacing: -2px;
            color: $color-white;
          }

          &__download {
            padding-top: 51.9%;

            .download {
              &__item {
                margin-top: 18px;

                &:first-child {
                  margin-top: 0;
                }
              }
            }

            .ir-common__button {
              display: inline-block;
              padding-left: 34px;
              text-indent: 14px;
              font-weight: 800;
              font-size: 20px;
              line-height: 2.1;
            }
          }
        }
        .quick-link__button {
          position: absolute;
          bottom: 0;
          right: 0;
          &:hover{
            @at-root .theme-dark & {
              background-image: url(/img/common/ic-quicklink-black.png);
              background-color: #fff;
            }
          }
        }
      }

      &__sub {
        display: flex;
        width: 100%;
        margin-left: 11px;

        .sub {
          &__list {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-left: 10px;

            &:first-child {
              margin-left: 0;
            }
          }

          &__item {
            position: relative;
            height: 100%;
            margin-top: 10px;

            &:first-child {
              margin-top: 0;
            }

            &::after {
              display: block;
              content: '';
              padding-bottom: 100%;
            }

            &.public-letter .sub__link {
              color: $color-white;
              background-color: $color-point;

              .txt-wrap {
                justify-content: flex-end;
              }

              &:hover {

                .tit,
                .txt {
                  color: $color-white;
                }

                .quick-link__button {
                  background-image: url(/img/common/ic-quicklink-black.png);
                  background-color: $color-white;
                }
              }
            }

            &.stock .sub__link {
              padding-bottom: 20px;

              .quick-link__button {
                margin-bottom: 10px;
              }
            }
          }

          &__link {
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 30px;
            font-weight: 700;
            background-color: var(--bgColor02);

            &:hover {

              .tit,
              .txt {
                color: $color-point;
              }

              .quick-link__button {
                background-color: $color-point;
              }
            }

            .num-wrap {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;

              .num-txt {
                display: inline-block;
                width: 100%;
                font-size: 22px;
                line-height: 1.55;
                letter-spacing: -0.6px;
                color: var(--newsDate);
              }
            }

            .num-info {
              position: relative;
              display: inline-block;
              width: 100%;
              padding-left: 24px;
              text-indent: 4px;
              font-size: 18px;
              line-height: 1.67;
              color: #ff2c2c;

              &::before {
                position: absolute;
                display: block;
                top: 50%;
                left: 0;
                width: 24px;
                height: 24px;
                transform: translateY(-50%);
                content: '';
              }

              &.up {
                &::before {
                  background: url(../img/common/ic-stock-up.png) no-repeat center;
                  background-size: contain;
                }
              }

              &.down {
                color: #4078ff;

                &::before {
                  background: url(../img/common/ic-stock-down.png) no-repeat center;
                  background-size: contain;
                }
              }

              &.upper {
                &::before {
                  background: url(../img/common/ic-stock-up2.png) no-repeat center;
                  background-size: contain;
                }
              }

              &.lower {
                color: #4078ff;

                &::before {
                  background: url(../img/common/ic-stock-down2.png) no-repeat center;
                  background-size: contain;
                }
              }
            }

            .txt-wrap {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-top: 4px;

              .count {
                font-size: 62px;
                line-height: 1.42;
                letter-spacing: -2px;
              }

              .date {
                font-size: 14px;
                line-height: 2;
                color: var(--newsDate);
              }
            }

            .tit {
              font-size: 32px;
              line-height: 1.5;
              letter-spacing: -1px;
              transition: 0.3s;
            }

            .txt {
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.6px;
              transition: 0.3s;
            }
          }
        }
      }
    }

    .js-nav-section {
      max-width: 1800px;
      margin: 0 auto;
      padding-left: 460px;
    }
  }

  &.ir-investor {
    .section__title {
      font-size: 36px;
      line-height: 1.61;
      letter-spacing: -1.5px;
    }

    .js-nav-section {
      padding: 45px 0 115px 460px;

      &:last-child {
        padding-bottom: 0;
      }

      .card__btn {
        .see-all {
          width: 210px;
          &::after {
            right: 10px;
          }

        }
      }
    }

    .ir-table {
      margin-top: 30px;
      letter-spacing: -.3px;

      colgroup col:last-child {
        width: 172px;
      }

      .tit {
        padding: 0;
      }

      .link {
        overflow: hidden;
        display: block;
        width: 50%;
        padding: 20px 20px 20px 0;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--color-sub);
      }

      .date {
        color: var(--color-sub);
      }

      .ir-common__button {
        &.download{
          margin-left: 16px;

          @at-root .#{$component-name}.ir-investor.lang-en {
            .ir-table .ir-common__button.download {
              margin-left: 3px;
            }
          }
        }
      }
      
    }

    .ir-finance {
      .tab {
        &__list {
          display: inline-block;
          padding: 0;
          border-bottom: 1px solid var(--border01);
          white-space: nowrap;
        }

        &__cta {
          margin-left: 74px;
          padding: 5px 0;
          font-weight: 700;
          font-size: 20px;
          line-height: 2.1;
          color: var(--tab-cta02);

          &:first-child {
            margin-left: 0;
          }

          &:hover {
            color: var(--color-sub);
          }

          &.#{$activeClass} {
            border-width: 2px;
            color: var(--default);
          }
        }
      }

      .cont__card {
        margin-top: 45px;
      }

      .card {
        &__wrap {
          margin-top: 60px;

          &:first-child {
            margin-top: 0;
          }
        }

        &__item {
          .txt {
            margin-top: 21px;
          }

          .btn__wrap {
            margin-top: 17px;
            .btn-download{
              &:hover{
                @at-root .theme-dark & {
                  background-image: url(/img/common/ic-download-black.png);
                  background-color: $color-point;
                }
              }
            }
          }
        }

        &__title {
          font-weight: 700;
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
        }

        &__tab {
          overflow-x: auto;
          overflow-y: hidden;

          &::-webkit-scrollbar {
            height: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cececf;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ir {
      .board {
        &__inner {
          max-width: 1520px;
        }

        &__main {
          .main__img {
            padding: 30px 30px 40px 30px;
          }

          .main__title {
            font-size: 48px;
            line-height: 1.5;
            letter-spacing: 0;
          }

          .main__download {
            .ir-common__button {
              padding-left: 30px;
              font-size: 14px;
              text-indent: 12px;
            }

            .download__item {
              margin-top: 14px;
            }
          }
        }

        &__sub {
          margin-left: 6px;

          .sub__list {
            margin-left: 6px;
          }

          .sub__item {
            margin-top: 6px;

            &.stock .sub__link {
              padding-bottom: 26px;

              .quick-link__button {
                margin-bottom: 0;
              }
            }
          }

          .sub__link {
            padding: 26px;

            .num-wrap {
              .num-txt {
                font-size: 16px;
                line-height: 1.75;
                letter-spacing: -0.5px;
              }

              .num-info {
                font-size: 16px;
                line-height: 1.75;
                text-indent: 2px;
              }
            }

            .txt-wrap {
              .count {
                font-size: 50px;
                line-height: 1.36;
                letter-spacing: -1.5px;
              }

              .date {
                font-size: 12px;
              }
            }

            .tit {
              font-size: 26px;
              line-height: 1.46;
              letter-spacing: -0.8px;
            }

            .txt {
              font-size: 16px;
              line-height: 1.88;
              letter-spacing: -0.5px;
            }
          }
        }
      }

      .js-nav-section {
        max-width: 1520px;
        padding-left: 386px;
      }
    }

    &.ir-investor {
      .ir-table {
        colgroup col:last-child {
          width: 133px;
        }
      }

      .ir-finance {
        .tab {
          &__cta {
            margin-left: 53px;

            &:first-child {
              margin-left: 0;
            }
          }
        }

        .card {
          &__list-wrap {
            margin-top: 30px;
          }

          &__item {
            .txt {
              margin-top: 16px;
            }

            .btn__wrap {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir {
      .cont__board {
        margin-top: 10px;
        padding-bottom: 60px;
      }

      .board {
        &__inner {
          max-width: none;
          padding: 0 30px;
        }

        &__main {
          .main__img {
            padding: 20px;
          }

          .main__title {
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
          }

          .main__download {
            padding-top: 54.3%;

            .ir-common__button {
              padding-left: 24px;
              text-indent: 8px;
              font-size: 12px;
              line-height: 2.1;
            }

            .download__item {
              margin-top: 10px;
            }
          }
        }

        &__sub {
          .sub__item {
            &.stock .sub__link {
              padding-bottom: 16px;
            }
          }

          .sub__link {
            padding: 16px;

            .tit {
              font-size: 18px;
              line-height: 1.67;
              letter-spacing: -0.5px;
            }

            .txt {
              font-size: 12px;
              line-height: 1.83;
              letter-spacing: -0.26px;
            }

            .num-wrap {
              .num-txt {
                font-size: 12px;
                line-height: 1.83;
                letter-spacing: -0.26px;
              }

              .num-info {
                padding-left: 16px;
                font-size: 12px;
                line-height: 1.83;

                &::before {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .txt-wrap {
              margin-top: 10px;

              .count {
                font-size: 32px;
                line-height: 1.19;
                letter-spacing: -0.5px;
              }

              .date {
                font-size: 10px;
                line-height: 2.2;
              }
            }
          }
        }
      }

      .js-nav-section {
        max-width: none;
      }
    }

    &.ir-investor {
      .js-nav-section {
        padding: 60px 30px;
      }

      .section__title {
        font-size: 30px;
        line-height: 1.6;
        letter-spacing: -1px;
      }

      .ir-table {
        margin-top: 40px;

        colgroup col:last-child {
          width: 142px;
        }
      }

      .ir-finance {
        margin: 0;

        .tab {
          &__cta {
            margin-left: 48px;
            padding: 9px 0;
            font-size: 16px;
            line-height: 1.8;

            &:first-child {
              margin-left: 0;
            }
          }
        }

        .cont__card {
          margin-top: 32px;
        }

        .card__tab .ir-common__tab-item {
          font-size: 16px;
          line-height: 1.88;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ir {
      .cont__board {
        padding-bottom: 50px;
      }

      .board__inner {
        flex-direction: column;
      }

      .board__main {
        .main__img {
          background-image: url(/img/mo/ir-board-main-bg-tab.png);
        }

        .main__wrap {
          min-height: 400px;
        }

        .main__title {
          line-height: 1.44;
        }

        .main__download {
          padding-top: 30.3%;
        }
      }

      .board__sub {
        margin-left: 0;

        .sub__list {
          margin-top: 6px;

          &:first-child {
            flex-direction: column-reverse;
            margin-top: 0;
          }
        }

        .sub__item {
          min-height: 164px;

          &::after {
            display: none;
          }

          &.stock {
            margin-top: 6px;

            .sub__link {
              padding: 16px 20px 9px 11px;
            }
          }
        }

        .sub__link {
          padding: 14px 20px 20px;

          .num-wrap {
            height: auto;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 0 4px 0 9px;

            .num-txt {
              font-size: 12px;
              line-height: 1.83;
              letter-spacing: -0.26px;
              text-align: end;
            }

            .num-info {
              font-size: 12px;
              line-height: 1.83;
              text-indent: 4px;
            }
          }

          .txt-wrap {
            align-items: baseline;

            .count {
              font-size: 36px;
              line-height: 1.61;
              letter-spacing: -0.5px;
            }
          }

          .tit {
            font-size: 16px;
            line-height: 1.88;
          }

          .txt {
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: -0.3px;
          }
        }
      }
    }

    &.ir-investor {
      .js-nav-section {
        padding: 50px 30px;
      }

      .ir-table {
        margin-top: 30px;

        colgroup {
          display: table-column-group;

          col:last-child {
            width: 118px;
          }
        }

        thead {
          display: table-header-group;

          th {
            padding: 14px 0;
          }
        }

        tbody tr {
          display: table-row;
          min-height: auto;

          &:first-child {
            border-top-color: #e4e6ea;
          }
        }

        .tit {
          font-size: 14px;
          padding: 30px 0;
        }

        .link {
          width: 80%;
          padding: 14px 0;
        }

        .date {
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          padding: 30px 0;
        }
      }

      .ir-common__button.see-all {
        width: 172px;
      }

      .ir-finance {
        .tab {
          &__cta {
            margin-left: 59px;

            &:first-child {
              margin-left: 0;
            }
          }
        }

        .card {
          &__list {
            margin: -6px 0 0 -6px;
          }

          &__item {
            margin: 6px 0 0 6px;

            .btn__wrap {
              margin-top: 0;
            }
          }

          &__link {
            padding: 10px 20px 16px 20px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.ir {
      .cont__board {
        margin-top: 0;
      }

      .board__inner {
        padding: 0 20px;
      }

      .board__main {
        .main__img {
          padding: 16px 20px 20px;
          background-image: url(/img/mo/ir-board-main-bg-mo.png);
        }

        .main__wrap {
          min-height: 404px;
        }

        .main__download {
          .ir-common__button {
            height: 24px;
            line-height: 2;
          }

          .ir-common__button::before {
            width: 24px;
            height: 24px;
          }
        }
      }

      .board__sub {
        flex-direction: column;

        .sub__list {
          width: 100%;

          &:last-child {
            margin-left: 0;
          }
        }

        .sub__item.stock {
          .sub__link {
            padding: 16px 20px 9px;
          }

          .txt-wrap {
            margin-top: 59px;
          }
        }

        .sub__link {
          justify-content: flex-start;

          .txt {
            margin-top: 12px;
            margin-bottom: 0;
          }

          .num-wrap {
            padding: 0;
          }

          .txt-wrap {
            margin-top: 40px;

            .count {
              font-size: 34px;
              line-height: 1.71;
            }
          }
        }
      }
    }

    &.ir-investor {
      .js-nav-section {
        padding: 50px 20px;
      }

      .section__title {
        font-size: 24px;
        line-height: 1.58;
        letter-spacing: -0.8px;
      }

      .ir-table {
        margin-top: 40px;

        colgroup, thead {
          display: none;
        }

        tbody {
          tr {
            display: flex;
            min-height: 102px;
            padding: 10px 0 30px;

            &:first-child {
              border-top-color: #1d1d1f;
            }
          }
        }

        .tit {
          line-height: 26px;
        }

        .link {
          padding: 0;
          @include multi-ellipsis(2);
          white-space: unset;
        }

        .date {
          margin-top: 10px;
          color: #a1a1a6;
          font-weight: 700;
          line-height: 26px;
        }

      }

      .ir-finance {
        .tab {
          &__list {
            display: block;
            padding: 0 0 1px 0;

            &::-webkit-scrollbar {
              height: 4px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #cececf;
              border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
          }

          &__cta {
            margin-left: 30px;
            padding: 7px 0;
            font-size: 14px;
            line-height: 2.14;

            &:first-child {
              margin-left: 0;
            }

            &.#{$activeClass} {
              border-width: 1px;
              position: relative;
              top: 1px;
            }
          }
        }

        .card {
          &__wrap {
            margin-top: 40px;
          }

          &__tab {
            overflow: hidden;

            .ir-common__tab-item {
              font-size: 14px;
              line-height: 2.14;
            }
          }

          &__list-wrap {
            margin-top: 40px;
          }

          &__list {
            margin: -6px 0 0 0;
          }

          &__item {
            margin: 6px 0 0 0;

            .txt {
              margin-top: 12px;
            }

            .btn__wrap {
              margin-top: 18px;
            }
          }

          &__link {
            padding: 20px 16px 16px 20px;
          }
        }
      }
    }
  }
}
