.#{$component-name} {
  &.ir-reference {
    .container {
      padding-top: 0;
    }

    .common {
      &__title {
        border: 0;

        &-inner {
          padding-top: 150px;
          padding-bottom: 80px;
        }
      }
    }

    .ir-common {
      &__button {
        margin-left: 27px;
        padding-left: 32px;
        font-weight: 800;
        font-size: 16px;
        line-height: 1.88;
        letter-spacing: normal;

        &:first-child {
          margin-left: 0;
        }

        &.download {
          &:before {
            width: 24px;
            height: 24px;
          }

          &.type2 {
            padding-left: 32px;

            &:hover {
              color: $color-point;
            }
          }

          &.webcast {
            height: auto;
            font-weight: 800;
            font-size: 16px;
            line-height: 1.88;
            color: var(--default);

            &:before {
              width: 24px;
              height: 24px;
              background: var(--color-sub02) url(../img/common/ic-webcast-white.png) no-repeat center;
              background-size: contain;
            }

            &:hover {
              &:before {
                background: $color-point url(../img/common/ic-webcast-white.png) no-repeat center;
                background-size: contain;
              }
            }
          }
        }
      }
    }

    .tab__panels {
      max-width: none;
      padding: 0;
    }

    .dropdown {
      &__select {
        bottom: -6px;
      }
    }

    .table {
      &__thead {
        &-th {
          flex: unset;
        }
      }

      &__tbody {
        div {
          flex: unset;
          font-weight: 400;
        }
      }
    }

    .ir-reference {
      &__contents {
        // display: none;

        &.is-active {
          display: block;
        }

        &.result {
          text-align: center;

          .table {
            margin-top: 100px;

            .ir-common {
              &__button {
                padding-left: 26px;
                font-weight: 700;
                font-size: 14px;
                line-height: 2.14;

                &:before {
                  width: 20px;
                  height: 20px;
                }

                &:first-child {
                  margin-left: 16px;
                }
  
                @at-root .#{$component-name}.lang-en {
                  .ir-reference__contents.result .table .ir-common__button {
                    &:first-child {
                      margin-left: 3px;
                    }
                  }
                }
              }
            }

            &__thead {
              &-th {
                width: 89.5%;

                &:last-child {
                  width: 10.5%;
                }
              }
            }

            &__tbody {
              div {
                width: 89.5%;

                &:last-child {
                  width: 10.5%;
                }
              }
            }
          }
        }

        &.report {
          .table {
            &__thead {
              &-th {
                width: 89.5%;

                &:last-child {
                  width: 10.5%;
                }
              }
            }

            &__tbody {
              div {
                width: 89.5%;
                @at-root .theme-dark & {
                  color: #fff;
                }
                &:last-child {
                  width: 10.5%;
                }

              }
            }

            .ir-common__button {
              padding-left: 26px;
              font-weight: 700;
              font-size: 14px;
              line-height: 2.14;

              &::before {
                width: 20px;
                height: 20px;
              }

              &:first-child {
                margin-left: 16px;
              }

              @at-root .#{$component-name}.lang-en {
                .ir-reference__contents.report .table .ir-common__button {
                  &:first-child {
                    margin-left: 3px;
                  }
                }
              }
            }

          }
        }

        &.faq {
          .table {
            margin-top: 30px;

            &__tbody {
              &-td {
                padding: 30px 0;
                vertical-align: top;
                font-weight: 800;
                font-size: 22px;
                line-height: 1.91;
                letter-spacing: -0.6px;

                &:first-child {
                  margin-right: 14px;
                }
              }

              .table-text {
                display: block;
                margin-top: 20px;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.88;
                letter-spacing: -0.4px;
                color: $color-gray3;
              }
            }

            &__tr {
              align-items: start;
            }
          }
        }
      }

      &__section {
        max-width: 1800px;
        margin: 160px auto 0;
        padding-left: 460px;

        &.ir-reference-result, &.ir-reference-audit, &.ir-reference-question {
          margin-top: 100px;
        }

        .section__title {
          text-align: left;
        }
      }

      &__title {
        font-weight: 800;
        font-size: 38px;
        line-height: 1.53;
        letter-spacing: -1.5px;
        text-align: left;
      }

      &__latest {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        padding: 22px 9px 22px 0;
        border-top: 1px solid var(--numBg);
        border-bottom: 1px solid var(--border01);

        &-title {
          font-weight: 700;
          font-size: 22px;
          line-height: 1.91;
          letter-spacing: -0.6px;
          color: var(--default);
        }
      }

      &__btn-more {
        margin-top: 80px;
        width: 46px;
        height: 46px;
        background: #f6f7fb url(../img/common/ic-pagination-arrow.png) no-repeat center;
        background-size: contain;
        border-radius: 50%;
        transform: rotate(-90deg);
        @at-root .theme-dark & {
          margin-top: 80px;
          width: 46px;
          height: 46px;
          background: #424245 url(../img/common/ic-pagination-arrow-black.png) no-repeat center;
          background-size: contain;
          border-radius: 50%;
          transform: rotate(-90deg);
        }
      }

      &__head {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.theme-dark {
  .ir-reference__btn-more {
    background-image: url(/img/common/btn-circle-arrow-left.png);
    background-color: transparent;
  }
}

//$screen-lg: 1600 - 1919px;
@include breakpoint(pc) {
  .#{$component-name} {
    &.ir-reference {
      .ir-reference {
        &__contents {
          &.result {
            .table {
              &__thead {
                &-th {
                  width: 87.5%;

                  &:last-child {
                    width: 11.5%;
                  }
                }
              }

              &__tbody {
                div {
                  width: 87.5%;

                  &:last-child {
                    width: 11.5%;
                  }
                }
              }

              @at-root .#{$component-name}.lang-en {
                .ir-reference__contents .table .ir-common__button {
                  margin-left: 2px;
                }
              }
            }
          }

          &.report {
            .table {
              &__thead {
                &-th {
                  width: 87.5%;

                  &:last-child {
                    width: 12.5%;
                  }
                }
              }

              &__tbody {
                div {
                  width: 87.5%;

                  &:last-child {
                    width: 12.5%;
                  }
                }
              }
            }
          }
        }

        &__section {
          max-width: 1520px;
          padding-left: 386px;
        }
      }
    }
  }
}

//$screen-md: 1024 - 1599px;
@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir-reference {
      .common {
        &__title {
          &-inner {
            padding-top: 140px;
            padding-bottom: 70px;
          }
        }
      }

      .dropdown {
        &__select {
          bottom: auto;
        }
      }

      .ir-reference {
        &__contents {
          &.result {
            .table {
              &__thead {
                &-th {
                  width: 85.5%;

                  &:last-child {
                    width: 14.5%;
                  }
                }
              }

              &__tbody {
                div {
                  width: 85.5%;

                  &:last-child {
                    width: 14.5%;
                  }
                }
              }
            }
          }

          &.report {
            .anchor-case {
              &__section {
                section[id^=section] {
                  margin-top: 120px;

                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
            }

            .ir-reference__section {
              position: relative;

              &.ir-reference-audit {
                z-index: 14;
              }

              &.ir-reference-affairs {
                z-index: 13;
              }

              &.ir-reference-annual {
                z-index: 12;
              }

            }

            .table {
              &.is-dropdown {
                margin-top: 40px;
              }

              &__thead {
                &-th {
                  width: 85%;
                  &:last-child {
                    width: 15%;
                  }
                }
              }

              &__tbody {
                div {
                  width: 85%;
                  
                  &:last-child {
                    width: 15%;
                  }
                }
              }
            }
          }

          &.faq {
            .table {
              margin-top: 40px;
            }
          }
        }

        &__section {
          max-width: none;
          margin-top: 120px;
          padding: 0 30px;

          &:first-child {
            margin-top: 80px;
          }
        }

        &__head {
          align-items: end;
        }

        &__latest {
          margin-top: 40px;
        }
      }
    }
  }
}

//$screen-xmd: 768 - 1023px;
@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ir-reference {
      .ir-common {
        &__select {
          width: 200px;
          margin-top: 0;
        }
      }

      .dropdown {
        &__select {
          margin-top: -5px;
        }
      }

      .ir-reference {
        &__contents {
          width: auto;

          &.result {
            .table {
              margin-top: 80px;

              &__thead {
                &-th {
                  width: 83.5%;
                  padding: 14px 0;

                  &:last-child {
                    width: 16.5%;
                  }
                }
              }

              &__tbody {
                div {
                  width: 83.5%;
                  padding: 14px 0;

                  &:last-child {
                    width: 16.5%;
                  }
                }
              }
            }
          }

          &.report {
            .table {
              &.is-dropdown {
                margin-top: 35px;
              }

              &__thead {
                &-th {
                  display: none;
                }
              }

              &__tbody {
                div {
                  width: 100%;
                  padding: 0;

                  &:last-child {
                    width: 100%;
                    padding-top: 30px;
                  }
                }

                &-th {
                  font-size: 16px;
                  line-height: 1.88;
                  letter-spacing: normal;
                }
              }

              &__tr {
                flex-direction: column;
                align-items: start;
                padding: 10px 0 27px;
              }
            }

            .anchor-case {
              &__section {
                section[id^=section] {
                  margin-top: 100px;

                  &:first-child {
                    margin-top: 0;
                  }

                  &:last-child {
                    margin-bottom: 120px;
                  }
                }
              }
            }
          }

          &.faq {
            .anchor-case {
              &__section {
                section[id^=section] {
                  &:last-child {
                    margin-bottom: 120px;
                  }

                  .section__title {
                    padding-left: 20px;
                  }
                }
              }
            }

            .table {
              &__tbody {
                &-td {
                  padding: 20px 0 30px;
                  font-size: 16px;
                  line-height: 1.88;
                  letter-spacing: -0.5px;

                  &:first-child {
                    margin-right: 10px;
                  }
                }

                .table-text {
                  font-size: 14px;
                  line-height: 1.86;
                  letter-spacing: -0.3px;
                }
              }
            }
          }
        }

        &__section {
          margin-top: 100px;

          &.ir-reference-result, &.ir-reference-audit, &.ir-reference-question {
            margin-top: 76px;
          }
        }

        &__head {
          align-items: center;
        }

        &__latest {
          flex-direction: column;
          align-items: start;
          margin-top: 40px;
          padding: 10px 0 30px;

          &-title {
            text-align: left;
            padding: 0 0 50px;
            font-weight: 800;
            line-height: 1.53;
          }
        }

        &__btn-more {
          margin-top: 60px;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

//$screen-sm: 375 - 767px;
@include breakpoint(mobile) {
  .#{$component-name} {
    &.ir-reference {
      .common {
        &__title {
          &-inner {
            padding-top: 112px;
            padding-bottom: 41px;
          }
        }
      }

      .ir-common {
        &__button {
          display: flex;
          height: auto;
          margin-top: 14px;
          margin-left: 0;
          line-height: 1.86;

          &:first-child {
            margin-top: 0;
            margin-left: 0 !important;
          }

          &:nth-child(2) {
            order: 2;
          }

          &:nth-child(3) {
            order: 1;
          }
        }

        &__select {
          width: 100%;
          margin-top: 40px;
        }
      }

      .dropdown {
        &__select {
          width: 100%;
          margin-top: 39px;
        }
      }

      .ir-reference {
        &__contents {
          &.result {
            .table {
              &__thead {
                display: none;
              }

              &__tbody {
                div {
                  width: 100%;
                  padding: 0;

                  &:last-child {
                    width: 100%;
                    padding-top: 30px;
                  }
                }

                &-th {
                  font-size: 16px;
                  line-height: 1.88;
                  letter-spacing: normal;
                }
              }

              &__tr {
                flex-direction: column;
                align-items: start;
                padding: 10px 0 30px;
              }
            }
          }

          &.report {
            .table {
              &.is-dropdown {
                margin-top: 40px;
              }

              &__tr {
                padding: 10px 0 30px;
              }
            }

            .anchor-case {
              &__section {
                section[id^=section] {
                  &:last-child {
                    margin-bottom: 100px;
                  }
                }
              }
            }
          }

          &.faq {
            .anchor-case {
              &__section {
                section[id^=section] {
                  &:last-child {
                    margin-bottom: 100px;
                  }

                  .section__title {
                    padding-left: 0;
                  }
                }
              }
            }
          }

          &__head {
            flex-direction: column;
            align-items: start;
          }

          &__title {
            font-size: 26px;
            letter-spacing: -0.8px;
            line-height: 1.46;
          }

          &__latest {
            display: block;
            margin-top: 40px;
            padding: 10px 0 30px;
            text-align: left;

            &-area {
              display: flex;
              flex-direction: column;
            }
          }
        }

        &__section {
          margin-top: 100px;
          padding: 0 20px;

          &.ir-reference-result, &.ir-reference-audit, &.ir-reference-question {
            margin-top: 60px;
          }
        }

        &__head {
          flex-direction: column;
          align-items: start;
        }
      }
    }
  }
}
