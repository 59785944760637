.#{$component-name} {
  &.contact {
    &.lang-en {
      .text-ko {
        display: none;
      }
    }

    .common__title {
      @at-root .theme-dark & {
        border-bottom: 1px solid var(--border05);
      }
    }

    .section__inner {
      max-width: 1800px;
      margin: 0 auto;
      padding-left: 460px;
      padding-top: 60px;
    }

    .contact {
      &__list {
        margin-top: 100px;
      }

      &__item {
        display: flex;

        &-name {
          width: 116px;
          font-weight: 800;
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
          color: var(--default);
        }

        &-info {
          padding-left: 172px;
          font-weight: 700;
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;

          .text-ko {
            color: var(--default);
          }

          .text-en {
            color: #a1a1a6;
          }

          .tel {
            margin-top: 16px;
            font-weight: 700;
            color: var(--default);
          }
        }
      }

      &__subsidiary {
        .section__inner {
          padding-top: 100px;
        }

        .contact {
          &__item {
            padding: 30px 0;
            border-bottom: 1px solid var(--border01);

            &:first-child {
              padding-top: 0;
            }
          }
        }
      }

      &__center {
        .section__inner {
          padding-top: 120px;
        }

        &-list {
          display: flex;
          margin-top: 80px;
        }

        &-item {
          width: 374px;
          margin-right: 6px;
          background-color: var(--bgColor02);

          &:first-child {
            .icon-img {
              background-image: var(--naver-contact-icon01);
            }
          }

          &:nth-child(2) {
            .icon-img {
              background-image: var(--naver-contact-icon02);
            }
          }

          &:last-child {
            margin-right: 0;

            .icon-img {
              background-image: var(--naver-contact-icon03);
            }
          }
        }

        &-link {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 20px 20px 70px 20px;

          &:hover {
            .arrow {
              background-color: $color-point;
            }
          }

          .icon-img {
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }

          .title {
            margin-top: 20px;
            font-weight: 800;
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          .text {
            width: 300px;
            margin-top: 8px;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: -0.3px;
            
            &:nth-child(3){
              margin: 0;
            }
          }

          .arrow {
            position: absolute;
            bottom: 20px;
            right: 20px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--color-sub02);
            background-image: url(/img/common/ic-outlink-white.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transition: all .3s;
          }

          .bottom-area {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
          }
        }
      }
    }

    .map {
      margin-top: 80px;
      height: 550px;
      background-image: url(/img/pc/contact-map.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
}

@media (min-width: 1600px) {
  .theme-dark .anchor-nav__menu.theme-black {
    .menu__link {
      color: #000;
      opacity: .3;
      &.is-active {
        opacity: 1; 
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.contact {
      .section__inner {
        max-width: 1520px;
        padding-left: 386px;

        .contact{

          &__item{
            
          }
          &__link{
            .bottom-area{
              .text{
                width: 245px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.contact {
      .section__inner {
        max-width: none;
        padding: 60px 30px 0;
      }

      .contact {
        &__item {
          &-info {
            padding-left: 110px;
          }
        }

        &__center {
          &-list {
            margin-top: 60px;
          }

          &-link {
            .title {
              margin-top: 16px;
              line-height: 30px;
            }

            .text {
              margin-top: 10px;
            }
          }

          &-item {
            width: calc((100% - 12px)/3);
          }
        }
      }

      .map {
        height: 480px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.contact {
      .contact {
        &__list {
          margin-top: 50px;
        }

        &__item {
          display: block;

          &-name {
            width: auto;
            line-height: 1.67;
          }

          &-info {
            padding: 22px 0 0;
            line-height: 1.71;

            .text-en {
              margin-top: 8px;
            }

            .tel {
              margin-top: 12px;
            }
          }
        }

        &__subsidiary {
          .section__inner {
            padding-top: 80px;
          }
        }

        &__center {
          .section__inner {
            padding-top: 100px;
          }

          &-list {
            flex-wrap: wrap;
            margin-top: 50px;
          }

          

          &-item {
            width: 351px;

            &:nth-child(2) {
              margin-right: 0;
              
              @at-root .navercorp.contact .contact__center-item:nth-child(2) .icon-img {
                position: relative;
                top: 12px;
              }
            }

            &:last-child {
              margin-top: 6px;
            }
          }

          &-link {
            .icon-img {
              width: 24px;
              height: 24px;
            }

            .title {
              margin-left: 10px;
              margin-top: 0;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: -0.5px;

              @at-root .navercorp.contact .contact__center-link .title {
                height: 48px;
              }
            }

            .text {
              font-size: 12px;
              line-height: 1.83;
              letter-spacing: -0.26px;
            }

            .arrow {
              width: 24px;
              height: 24px;
            }

            .top-area {
              display: flex;
            }
          }
        }
      }

      .map {
        height: 375px;
        margin-top: 50px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.contact {

      .common__title {
        border-bottom: 1px solid var(--border01);
      }
      
      .section__inner {
        padding: 60px 20px 0;
      }

      .contact {
        &__center {
          &-item {
            width: 100%;
            margin: 10px 0 0;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-top: 10px;
            }
          }

          &-link {
            padding-bottom: 54px;

            .title {
              height: auto;
            }

            .text {
              margin-top: 0;
            }
          }

          .bottom-area {
            padding-top: 20px;
          }
        }
      }
    }
  }
}
