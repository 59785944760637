.#{$component-name} {
  &.esg-main {
    .container {
      padding: 0;
    }

    .track-animation {
      &__cover {
        background-image: url(../img/pc/esg-main.jpg);
      }

      &__dimmed {
        opacity: .3;

        &.#{$showClass} {
          opacity: .7;
        }
      }

      &__main-area {
        width: 1113px;
      }

      &__highlight-area {
        width: 1345px;
      }
    }

    .anchor-section {
      .js-nav-section {
        .section__right-box {
          .section__title {
            display: block;
            &.tab_text {
              display: none;
            }
          }
        }
      }
    }

    .anchor-section {
      margin-top: 0;

      .menu {

        &__item {
          .menu__link{
            @at-root .theme-dark & {
              color: var(--default);  
            }
          }
        }
      }

      .section__title{
        .text-highlight{
          white-space: nowrap;
        }
      }
    }

    .anchor-nav {
      margin-bottom: 0;

      &__menu{
        .menu__link{
          @at-root .theme-dark & {
            color: var(--default);  
          }
        }
      }
    }

    .ir-common__button {
      &.download {
        position: relative;
        height: 30px;
        margin-top: 30px;
        padding-left: 40px;
        font-weight: 800;
        font-size: 18px;
        letter-spacing: -0.5px;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          transform: rotate(-90deg);
        }
      }

      &.see-all {
        margin-top: 72px;
      }
    }

    .esg {
      &__btn {
        position: absolute;
        bottom: 70px;
        left: 50%;
        width: 44px;
        height: 50px;
        background: url(../img/common/ic-arrow-down.png) no-repeat center;
        background-size: contain;
        transform: translateX(-50%);
        z-index: 1;
      }

      &__title {
        font-weight: 800;
        font-size: 72px;
        line-height: 1.36;
        letter-spacing: -2.4px;

        .point {
          display: block;
          position: relative;
          width: max-content;

          &:after {
            position: absolute;
            bottom: 16px;
            left: 0;
            width: 100%;
            height: 50%;
            background-color: #49edc2;
            z-index: -1;
            content: '';
          }
        }
      }

      &__list {
        display: flex;
      }

      &__item {
        width: 472px;
        margin-right: 21px;

        &:last-child {
          margin-right: 0;
        }

        &-img {
          width: 100%;
        }

        &-title {
          display: block;
          margin-top: 20px;
          font-weight: 800;
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
          transition: all .3s;
          color: var(--default);
        }

        &-text {
          margin-top: 16px;
          font-weight: 700;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.5px;
          color: #86868b;
          color: var(--listSub);
        }
      }

      &__social {
        &-scroll {
          overflow: hidden;
          position: relative;
          margin-top: 100px;
          padding-right: 60px;

          &:hover {
            button {
              opacity: 1;

              &.swiper-button-disabled {
                opacity: 0;
              }
            }
          }
        }

        &-navigation {
          position: absolute;
          top: 218px;
          left: 0;
          width: 100%;

          button {
            position: absolute;
            top: 40px;
            width: 36px;
            height: 36px;
            background: url(../img/common/btn-square-prev.png) no-repeat center;
            background-size: contain;
            transition: all .3s;
            opacity: 0;

            &:hover {
              background: url(../img/common/btn-square-prev-on.png) no-repeat center;
              background-size: contain;
            }
          }

          .btn-next {
            right: 0;
            left: auto;
            transform: rotate(180deg);

            &:after {
              width: 100%;
              height: 100%;
              opacity: 0;
            }

            &.swiper-button-prev {
              &.swiper-button-disabled {
                opacity: 0;
              }
            }
          }

          .btn-prev {
            // display: none;
            left: 0;
            right: auto;

            &:after {
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }
      }

      &__news {
        margin-top: 200px;

        h4 {
          font-weight: 700;
          font-size: 36px;
          line-height: 1.61;
          letter-spacing: -0.01px;
        }

        &-list {
          max-width: 1458px;
          margin-top: 26px;
        }

        &-item {
          border-top: 1px solid #ddd;
        }

        &-link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px 20px 28px 0;
          font-weight: 700;
          font-size: 22px;
          line-height: 1.91;
          letter-spacing: -0.6px;
          transition: all .3s;

          &:hover {
            color: $color-point;

            .quick-link__button {
              background: $color-point url(../img/common/ic-quicklink.png) no-repeat center;
              background-size: contain;
            }
          }

          .text {
            @include multi-ellipsis(1);
          }

          .quick-link__button {
            flex-shrink: 0;
          }
        }
      }
    }

    .js-nav-section {
      overflow: hidden;
      max-width: 1920px;
      margin-top: 30px;
      margin-bottom: 0;

      &.propulsion {
        margin-top: 0;
        padding-top: 0;
      }

      &.propulsion, &.flower {
        .section__right-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .esg {
          &__box {
            &:last-child {
              overflow: hidden;
              position: relative;
              flex-shrink: 0;
              width: 840px;
              height: 1080px;
              margin-left: 138px;
            }

            .section__video {
              position: absolute;
              top: 50%;
              left: 50%;
              width: auto;
              height: 100%;
              transform: translate(-50%, -50%);

              &-wrap {
                position: relative;
                overflow: hidden;
              }
            }
          }
        }
      }

      &.eco {
        max-width: 1920px;
        padding: 0;

        .section__desc {
          width: 1000px;
        }

        .esg {
          &__graph {
            width: 100%;
            height: 816px;
            margin-top: 100px;
          }

          &__item {
            margin-top: 100px;

            &-title {
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.6px;
            }

            &-text {
              margin-top: 12px;
              padding-right: 108px;
            }
          }
        }
      }

      &.ethics {
        margin-top: 200px;
        padding-top: 0;

        .esg {
          &__list {
            margin-top: 70px;
          }

          &__item {
            &-text {
              min-height: 84px;
            }

            &:hover {
              .ir-common__button.download {
                color: #ff5f00;

                &::before {
                  background-color: #ff5f00;
                }
              }
            }
          }
        }
      }

      &.flower {
        .section__desc {
          padding-right: 45px;
        }

        .esg {
          &__box {
            &:last-child {
              margin-left: 94px;
              background: url(../img/pc/esg-flower.png) no-repeat center;
              background-size: cover;
            }
          }
        }
      }

      &.social {
        .esg {
          &__list {
            width: max-content;
            margin-top: 0;
          }

          &__link {
            &:hover {
              .esg {
                &__item-title {
                  color: $color-point;

                  &:after {
                    background: url(../img/common/btn-outlink-on.svg) no-repeat center;
                    background-size: contain;
                  }
                }
              }
            }
          }

          &__item {
            &-title {
              display: flex;
              align-items: center;

              &:after {
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-left: 4px;
                background: var(--btn-outlink) no-repeat center;
                background-size: contain;
                transition: background .3s;
                content: '';
              }
            }

            &-text {
              padding-right: 15px;
            }
          }
        }
      }

              
      .scroll-wrap {
        overflow-y: auto;

        &::-webkit-scrollbar {
          opacity: 0;
        }
      }
    }

    .graph {
      overflow: hidden;
      display: flex;

      &.is-active {
        .graph__bar {
          &:nth-child(2) {
            .graph__bar-top {
              animation: topbar 1s ease-in-out forwards;
              animation-delay: .5s;
            }
          }

          &:nth-child(3) {
            .graph__bar-top {
              animation: topbar01 1s ease-in-out forwards;
              animation-delay: .4s;
            }

            .graph__percent {
              animation: fadein 1s ease-in-out forwards;
            }
          }

          &:nth-child(4) {
            .graph__bar-top {
              animation: topbar02 1s ease-in-out forwards;
              animation-delay: .3s;
            }

            .graph__percent {
              animation: fadein 1s ease-in-out forwards;
              animation-delay: .1s;
            }
          }

          &:nth-child(5) {
            .graph__bar-top {
              animation: topbar03 1s ease-in-out forwards;
              animation-delay: .2s;
            }

            .graph__percent {
              animation: fadein 1s ease-in-out forwards;
              animation-delay: .2s;
            }
          }

          &:nth-child(6) {
            .graph__bar-top {
              animation: topbar04 1s ease-in-out forwards;
              animation-delay: .1s;
            }

            .graph__percent {
              animation: fadein 1s ease-in-out forwards;
              animation-delay: .3s;
            }
          }

          &:last-child {
            .graph__bar-top {
              animation: topbar05 1s ease-in-out forwards;
            }

            .graph__percent {
              animation: fadein 1s ease-in-out forwards;
              animation-delay: .4s;
            }
          }
        }
      }

      &__bar {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;
        font-weight: 800;
        color: $color-white;

        &:first-child {
          background-color: #26d595;
        }

        &:nth-child(2) {
          background-color: #40daa1;
        }

        &:nth-child(3) {
          background-color: #59dfae;
        }

        &:nth-child(4) {
          background-color: #73e4ba;
        }

        &:nth-child(5) {
          background-color: #99ebcd;
        }

        &:nth-child(6) {
          background-color: #ccf5e6;

          .graph__bar-top {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;

            .graph__percent {
              position: absolute;
              bottom: 0;
              left: 0;
              flex-shrink: 0;
              margin: 0;
              margin-left: 35px;
            }

            .num, .percent {
              color: $color-white;
            }

            .num {
              font-size: 96px;
            }

            .percent {
              font-size: 43px;
            }

            .text {
              flex-shrink: 0;
              margin-bottom: 34px;
              margin-left: 8px;
              font-size: 21.6px;
              line-height: 1.33;
              letter-spacing: -0.72px;
            }
          }

          .graph__year {
            position: absolute;
            top: 0;
            left: 0;
          }

          .num, .percent {
            color: #0dc094;
          }
        }

        &:last-child {
          background-color: #e6faf3;

          .graph__bar-top {
            display: flex;
            justify-content: end;
            flex-direction: column;
            position: relative;
            width: 100%;
          }

          .graph__year {
            position: absolute;
            top: 0;
            left: 0;
          }

          .num, .percent {
            color: #0dc094;
          }
        }

        &-top {
          height: 0%;
          background-color: #00ce82;

          .arrow {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            flex-shrink: 0;
            display: block;
            width: 66px;
            height: 76px;
            margin: 0 auto 32px;
            background: url(../img/common/ic-graph-arrow.png) no-repeat center;
            background-size: contain;
          }
        }
      }

      &__year {
        display: block;
        margin-top: 30px;
        margin-left: 20px;
        font-weight: 700;
        font-size: 24px;
        color: #b0f0d8;
      }

      &__percent {
        margin-left: 24px;
        margin-bottom: 20px;
        font-size: 45.6px;
        letter-spacing: -0.01px;
        opacity: 0;
        animation: fadein 1s ease-in-out forwards;

        .percent {
          margin-left: -10px;
        }
      }
    }

    .relate-content {
      margin-top: 70px;
      padding: 0 0 130px;
      background-color: #fbfbfd;
      background-color: var(--relateContent);
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.esg-main {
      .track-animation {
        &__main-area {
          width: 945px;
        }

        &__highlight-area {
          width: 1135px;
        }
      }

      .content {
        h2 {
          width: 700px;
          margin-left: 328px;
          font-size: 72px;
          line-height: 1.36;
          letter-spacing: -2.4px;
        }

        .cont__text {
          margin-left: 136px;

          .txt, .em {
            font-size: 48px;
            line-height: 1.58;
            letter-spacing: -1.5px;
          }
        }
      }

      .section__title {
        font-size: 54px;
        line-height: 1.44;
        letter-spacing: -1.8px;
      }

      .esg {
        &__btn {
          bottom: 60px;
        }

        &__title {
          font-size: 64px;
          line-height: 1.38;
          letter-spacing: -2px;
        }

        &__item {
          width: 365px;
          margin-right: 20px;

          &-title {
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.5px;
          }
        }

        &__social {
          &-scroll {
            margin-top: 80px;
            padding-right: 40px;
          }

          &-navigation {
            top: 165px;
          }
        }

        &__news {
          h4 {
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: normal;
          }

          &-list {
            max-width: 1232px;
            margin-top: 30px;
          }
        }
      }

      .js-nav-section {
        &.propulsion, &.flower {
          .esg {
            &__box {
              &:last-child {
                width: 700px;
                height: 900px;
                margin-left: 147px;
              }
            }
          }
        }

        &.eco {
          margin-top: 90px;

          .section__desc {
            width: 850px;
          }

          .esg {
            &__graph {
              height: 680px;
              margin-top: 80px;
            }

            &__list {
              margin-top: 82px;
            }

            &__item {
              margin-top: 0;

              &-title {
                font-size: 18px;
                line-height: 1.67;
                letter-spacing: -0.5px;
              }

              &-text {
                padding-right: 0;
              }
            }
          }
        }

        &.ethics {
          margin-top: 200px;

          .esg {
            &__list {
              margin-top: 60px;
            }

            &__item {
              &-text {
                padding-right: 0;
              }
            }
          }
        }

        &.flower {
          .section__desc {
            padding-right: 0;
          }

          .esg {
            &__box {
              &:last-child {
                margin-left: 77px;
              }
            }
          }
        }

        &.social {
          .section__desc {
            width: 665px;
          }

          .esg {
            &__item {
              &-text {
                padding-right: 0;
              }
            }
          }
        }
                
        .scroll-wrap {
          overflow-y: auto;
  
          &::-webkit-scrollbar {
            opacity: 0;
          }
        }
      }

      .relate-content {
        margin-top: 100px;
        padding: 60px 0 128px;
      }

      .graph {
        &__bar {
          &:nth-child(6) {
            .graph__bar-top {
              .graph__percent {
                margin-left: 20px;
              }

              .num {
                font-size: 80px;
                letter-spacing: -2px;
              }

              .percent {
                margin-left: -2px;
                font-size: 36px;
              }

              .text {
                margin-top: 3px;
                margin-left: 6px;
                margin-bottom: 30px;
                font-size: 18px;
                letter-spacing: -0.6px;
              }
            }
          }

          &-top {
            .arrow {
              width: 60px;
              height: 69px;
              margin-bottom: 32px;
            }
          }
        }

        &__year {
          margin-top: 26px;
          font-size: 24px;
        }

        &__percent {
          margin-left: 20px;
          font-size: 38px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.esg-main {
      .track-animation {
        &__main-area {
          width: 805px;
        }

        &__highlight-area {
          width: 805px;
        }
      }


      .ir-common__button {
        &.see-all {
          margin-top: 50px;
        }
      }

      .js-nav-section {
        padding: 0;
      }

      .anchor-section {
        .js-nav-section {
          .section__right-box {
            .section__title {
              &.pc_text {
                display: none;
              }
              &.tab_text {
                display: block;
              }
            }
          }
        }
      }
      

      .content {
        height: 100vh;

        .cont__inner {
          width: 100%;
          padding: 0 110px;
        }

        h2 {
          margin-left: 0;
          font-size: 72px;
          line-height: 1.36;
          letter-spacing: -2.4px;
        }

        .cont__text {
          width: 100%;
          margin-left: 0;
          padding: 0;

          .txt, .em {
            font-size: 42px;
            line-height: 1.52;
            letter-spacing: -1.8px;
          }
        }
      }

      .section__title {
        font-size: 48px;
        line-height: 1.42;
        letter-spacing: -1.5px;
      }

      .esg {
        &__btn {
          bottom: 80px;
          width: 44px;
          height: 50px;
        }

        &__title {
          font-size: 48px;
          line-height: 1.42;
          letter-spacing: -1.5px;

          .point {
            &:after {
              bottom: 12px;
            }
          }
        }

        &__item {
          width: 308px;
          margin-right: 20px;

          &-title {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }

        &__social {
          &-scroll {
            margin-top: 60px;
            padding-right: 14px;
          }

          &-navigation {
            top: 132px;
          }
        }

        &__news {
          margin-top: 140px;

          h4 {
            padding: 0 30px;
            letter-spacing: -1px;
          }

          &-list {
            max-width: none;
            width: 100%;
          }

          .quick-link__button {
            width: 32px;
            height: 32px;
          }
        }
      }

      .js-nav-section {
        margin-top: 140px;

        &.propulsion, &.flower {
          .section__right-box {
            display: block;
            padding: 0;
          }

          .esg {
            &__box {
              &:first-child {
                padding: 140px 30px 0;
              }

              &:last-child {
                margin-top: 60px;
                width: 100%;
                height: 576px;
                margin-left: 0;
              }
            }

            &__title {
              margin-top: 0;
            }
          }
        }

        &.eco {
          margin-top: 140px;

          .section__desc {
            width: 755px;
          }

          .esg {
            &__graph {
              height: 576px;
              margin-top: 80px;
            }

            &__list {
              margin-top: 60px;
            }

            &__title {
              padding-left: 50px;
            }
          }
        }

        &.ethics {
          margin-top: 140px;

          .esg {
            &__list {
              margin-top: 60px;
            }

            &__item {
              &-text {
                min-height: 112px;
              }
            }
          }
        }

        &.flower {
          .esg {
            &__box {
              &:first-child {
                padding-top: 0;
              }
            }
          }
        }

        &.social {
          .section__right-box {
            padding-right: 0;
          }

          .section__title {
            padding-right: 30px;
          }

          .section__desc {
            width: 521px;
          }

          .esg {
            &__list {
              width: max-content;
            }

            &__item {
              width: 300px;
              margin-right: 16px;
            }
          }
        }

        .scroll-wrap {
          overflow-y: auto;
  
          &::-webkit-scrollbar {
            opacity: 0;
          }
        }
        
      }

      .relate-content {
        margin-top: 170px;
        padding: 60px 0 80px;
      }

      .graph {

        .graph__year {
          opacity: 0;
          transition: opacity 1s ease-in-out;
          transition-delay: .5s;
        }

        &.is-active {
          .graph__bar {
            &:nth-child(2) {
              .graph__bar-top {
                animation: topbar-tab 1s ease-in-out forwards;
                animation-delay: .5s;
              }
            }

            &:nth-child(3) {
              .graph__bar-top {
                animation: topbar01-tab 1s ease-in-out forwards;
                animation-delay: .4s;
              }
            }

            &:nth-child(4) {
              .graph__bar-top {
                animation: topbar02-tab 1s ease-in-out forwards;
                animation-delay: .3s;
              }
            }

            &:nth-child(5) {
              .graph__bar-top {
                animation: topbar03-tab 1s ease-in-out forwards;
                animation-delay: .2s;
              }
            }

            &:nth-child(6) {
              .graph__bar-top {
                animation: topbar04-tab 1s ease-in-out forwards;
                animation-delay: .1s;
              }
            }

            &:last-child {
              .graph__bar-top {
                animation: topbar05-tab 1s ease-in-out forwards;
              }
            }
          }

          .graph__year {
            opacity: 1;
          }
        }

        &__bar {
          &:nth-child(6) {
            .graph__bar-top {
              .graph__percent {
                margin-left: 16px;
              }

              .num {
                font-size: 50px;
                letter-spacing: -1px;
              }

              .percent {
                font-size: 24px;
              }

              .text {
                margin-bottom: 25px;
                margin-left: 4px;
                font-size: 12px;
                letter-spacing: -0.4px;
              }
            }
          }

          &-top {
            .arrow {
              width: 48px;
              height: 55px;
              margin-bottom: 30px;
            }
          }
        }

        &__year {
          font-size: 16px;
        }

        &__percent {
          margin-left: 16px;
          margin-bottom: 16px;
          font-size: 28px;

          .percent {
            margin-left: -7px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.esg-main {
      .track-animation {
        &__main-area {
          width: 608px;
        }

        &__main-text {
          font-size: 64px;
        }

        &__highlight-area {
          width: 608px;
        }
      }

      .graph__year {
        font-size: 12px;
      }

      .graph__bar {
        &:nth-child(6) {
          .graph__bar-top {
            .num {
              font-size: 37px;
            }
            .percent {
              font-size: 18px;
            }
            .text {
              font-size: 9px;
            }
          }
        }
        
        .graph__percent {
          .num {
            font-size: 21px;
          }
          .percent {
            font-size: 21px;
          }
        }
      }

      .graph__bar-top {
        .num {
          font-size: 37px;
        }
      }

      .container {
        margin-bottom: 0;
      }

      .ir-common__button {
        &.download {
          height: 28px;
          font-size: 16px;

          &::before {
            width: 28px;
            height: 28px;
          }
        }

        &.see-all {
          margin-top: 60px;
        }
      }

      .content {
        .cont__inner {
          padding: 0 80px;

          .cont__text {
            .txt, .em {
              font-size: 32px;
              line-height: 1.5;
              letter-spacing: -1px;
            }
          }
        }

        h2 {
          width: 400px;
          padding-left: 16px;
          font-size: 48px;
          line-height: 1.42;
          letter-spacing: -1.5px;
        }
      }

      .section__title {
        font-size: 32px;
        line-height: 1.42;
        letter-spacing: -1.5px;
      }

      .section__right-box {
        &.is-scroll {
          padding-right: 0;
        }
      }

      .esg {
        &__btn {
          bottom: 40px;
          width: 39px;
          height: 44px;
        }

        &__title {
          font-size: 38px;
          line-height: 1.42;
          letter-spacing: -1.5px;
        }

        &__list {
          width: max-content;
          padding-right: 30px;
        }

        &__item {
          width: 315px;

          &-text {
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }
        }

        &__social {
          &-navigation {
            display: none;
          }
        }

        &__news {
          margin-top: 120px;

          h4 {
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
          }

          &-link {
            padding: 20px 16px 20px 0;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }

          .quick-link__button {
            width: 24px;
            height: 24px;
          }
        }
      }

      .js-nav-section {
        margin-top: 120px;

        &.propulsion {
          .esg {
            &__box {
              &:first-child {
                padding-top: 80px;
              }

              .section__video {
                width: 100%;
                height: auto;
              }
            }
          }
        }

        &.propulsion, &.flower {
          .esg {
            &__box {
              &:last-child {
                height: 432px;
              }
            }
          }
        }

        &.eco {
          margin-top: 120px;

          .section__desc {
            width: auto;
            padding-left: 0;
          }

          .esg {
            &__graph {
              height: 432px;
              margin-top: 60px;
            }

            &__title {
              padding-left: 0;
            }

            &__item {
              &-text {
                font-size: 14px;
                line-height: 1.71;
                letter-spacing: -0.3px;
              }
            }
          }

          .ir-common__button.download {
            margin-left: 0;
          }
        }

        &.ethics {
          .section__title {
            padding-right: 30px;
          }
        }

        &.flower {
          .esg {
            &__box {
              &:first-child {
                padding-top: 0;
              }

              &:last-child {
                background: url(../img/mo/esg-flower-sm.png) no-repeat center;
                background-size: contain;
              }
            }
          }
        }

        &.social {
          .section__desc {
            width: 585px;
          }

          .esg {
            &__item {
              width: 315px;
            }
          }
        }
      }

      .scroll-wrap {
        overflow-y: auto;

        &::-webkit-scrollbar {
          opacity: 0;
        }
      }

      .relate-content {
        margin-top: 125px;
      }

      .graph {
        &__bar {
          &:nth-child(6) {
            .graph__bar-top {
              .graph__percent {
                margin-left: 12px;
              }

              .num {
                font-size: 37.5px;
                letter-spacing: -0.75px;
              }

              .percent {
                font-size: 18px;
              }

              .text {
                margin-bottom: 13px;
                font-size: 9px;
                letter-spacing: -0.3px;
              }
            }
          }

          &-top {
            .arrow {
              width: 36px;
              height: 42px;
              margin-bottom: 24px;
            }
          }
        }

        &__year {
          margin-top: 20px;
          margin-left: 15px;
          font-size: 12px;
        }

        &__percent {
          margin-left: 15px;
          margin-bottom: 12px;
          font-size: 21px;

          .percent {
            margin-left: -5px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.esg-main {
      .track-animation {
        &__main-area {
          width: auto;
          max-width: 315px;
        }

        &__main-text {
          font-size: 38px;
          line-height: 54px;
          letter-spacing: -1.5px;

          span {
            display: inline;
          }
        }

        &__highlight-area {
          width: auto;
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 20px;
          text-align: left;
        }
      }

      .container {
        margin-bottom: 0;
      }

      .ir-common__button {
        &.see-all {
          margin-top: 50px;
        }
      }

      .anchor-nav {
        margin-bottom: 0;
      }

      .anchor-section {
        .js-nav-section {
          .section__right-box {
            .section__title {
              &.pc_text {
                display: block;
              }
              &.tab_text {
                display: none;
              }
            }
          }
        }
      }
      

      .content {
        .cont__inner {
          padding: 0 20px;

          .cont__text {
            .txt, .em {
              font-size: 26px;
              line-height: 1.54;
              letter-spacing: -0.8px;
            }
          }
        }

        h2 {
          width: 100%;
          left: 0;
          padding: 0 10px;
          line-height: 1.5;
          letter-spacing: -1.5px;
        }
      }

      .section__title {
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1.8px;
      }

      .esg {
        &__btn {
          width: 28px;
          height: 32px;
        }

        &__title {
          font-size: 36px;
          line-height: 1.39;
          letter-spacing: -1.8px;
        }

        &__list {
          width: 100%;
          padding-right: 20px;
        }

        &__news {
          h4 {
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: normal;
          }

          &-link {
            padding: 20px 4px 20px 0;
            font-size: 16px;
            line-height: 1.75;

            .text {
              @include multi-ellipsis(2);
              max-width: 240px;
            }
          }
        }
      }

      .js-nav-section {
        &.propulsion, &.flower {
          .esg {
            &__box {
              &:first-child {
                padding: 80px 20px 0;
              }

              &:last-child {
                height: 500px;
              }

              .section__video {
                width: auto;
                height: 100%;
              }
            }
          }
        }

        &.eco {
          .esg {
            &__graph {
              height: 500px;
            }

            &__list {
              display: block;
            }

            &__item {
              width: 100%;
              margin-top: 70px;
              margin-right: 0;

              &:first-child {
                margin-top: 0;
              }

              &-img {
                width: 100%;
              }

              &-text {
                font-size: 16px;
                line-height: 1.75;
                letter-spacing: -0.5px;
              }
            }
          }
        }

        &.ethics {
          margin-top: 120px;

          .section__title {
            padding-right: 20px;
          }

          .esg {
            &__list {
              display: block;
              margin-top: 50px;
            }

            &__item {
              width: 100%;
              margin-top: 70px;
              margin-right: 0;

              &:first-child {
                margin-top: 0;
              }

              &-img {
                width: 100%;
              }

              &-title {
                font-size: 22px;
                line-height: 1.55;
                letter-spacing: -0.5px;
              }

              &-text {
                height: auto;
                min-height: auto;
              }
            }
          }
        }

        &.flower {
          .esg {
            &__box {
              &:first-child {
                padding-top: 0;
              }

              &:last-child {
                background: url(../img/mo/esg-flower-mo.png) no-repeat center;
                background-size: contain;
              }
            }
          }
        }

        &.social {
          .section__title {
            padding-right: 20px;
          }

          .section__desc {
            width: auto;
          }
        }
      }

      .relate-content {
        margin-top: 100px;
        padding: 36px 0 80px;
      }

      .graph {
        &.is-active {
          .graph__bar {
            &:nth-child(3) {
              .graph__bar-top {
                animation: topbar01-mo 1s ease-in-out forwards;
                animation-delay: .2s;
              }
            }

            &:nth-child(4) {
              .graph__bar-top {
                animation: topbar02-mo 1s ease-in-out forwards;
                animation-delay: .1s;
              }
            }

            &:nth-child(6) {
              .graph__bar-top {
                animation: topbar04-mo 1s ease-in-out forwards;
              }
            }

            &:last-child {
              .graph__bar-top {
                animation: topbar05-mo 1s ease-in-out forwards;
              }
            }
          }
        }

        &__bar {
          &:first-child, &:nth-child(2), &:nth-child(5) {
            display: none;
          }

          &:nth-child(3) {
            .graph__bar-top {
              height: 20%;
            }
          }

          &:nth-child(4) {
            .graph__bar-top {
              height: 40%;
            }
          }

          &:nth-child(6) {
            width: 25%;

            .graph__year {
              font-size: 14px;
            }

            .graph__bar-top {
              height: 66%;
              align-items: normal;

              .graph__percent {
                z-index: 1;
                margin-left: 16px;
                white-space: nowrap;
              }
              .num {
                font-size: 58px;
              }
              .percent {
                font-size: 30px;
              }
              .text {
                position: relative;
                z-index: 1;
                margin-bottom: 30px;
                font-size: 14px;
                white-space: nowrap;
              }
            }
          }

          &:last-child {
            .graph__bar-top {
              height: 86%;
            }
          }

          &-top {
            align-items: center;

            .arrow {
              width: 32px;
              height: 36px;
              margin-bottom: 28px;
            }
          }
        }

        &__year {
          margin-left: 13px;
          font-size: 14px;
        }

        &__percent {
          font-size: 24px;
          letter-spacing: -0.01px;
        }
      }
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes topbar {
  0% {
    height: 0;
  }

  100% {
    height: 10%;
  }
}

@keyframes topbar01 {
  0% {
    height: 0;
  }

  100% {
    height: 21%;
  }
}

@keyframes topbar02 {
  0% {
    height: 0;
  }

  100% {
    height: 30%;
  }
}

@keyframes topbar03 {
  0% {
    height: 0;
  }

  100% {
    height: 38.5%;
  }
}

@keyframes topbar04 {
  0% {
    height: 0;
  }

  100% {
    height: 47.5%;
  }
}

@keyframes topbar05 {
  0% {
    height: 0;
  }

  100% {
    height: 80%;
  }
}

@keyframes topbar-tab {
  0% {
    height: 0;
  }

  100% {
    height: 14%;
  }
}

@keyframes topbar01-tab {
  0% {
    height: 0;
  }

  100% {
    height: 25%;
  }
}

@keyframes topbar02-tab {
  0% {
    height: 0;
  }

  100% {
    height: 33%;
  }
}

@keyframes topbar03-tab {
  0% {
    height: 0;
  }

  100% {
    height: 41.5%;
  }
}

@keyframes topbar04-tab {
  0% {
    height: 0;
  }

  100% {
    height: 50%;
  }
}

@keyframes topbar05-tab {
  0% {
    height: 0;
  }

  100% {
    height: 81%;
  }
}

@keyframes topbar01-mo {
  0% {
    height: 0;
  }

  100% {
    height: 20%;
  }
}

@keyframes topbar02-mo {
  0% {
    height: 0;
  }

  100% {
    height: 40%;
  }
}

@keyframes topbar04-mo {
  0% {
    height: 0;
  }

  100% {
    height: 66%;
  }
}

@keyframes topbar05-mo {
  0% {
    height: 0;
  }

  100% {
    height: 86%;
  }
}
