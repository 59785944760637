.#{$component-name} {
  &.publicletter-detail {

    .container {
      padding: 0;
    }

    .common {
      &__title {
        background-color: var(--black);
        color: $color-white;

        &-inner {
          padding: 192px 0 52px;
        }

        h2 {
          padding-top: 17px;
          font-size: 48px;
          line-height: 1.5;
          letter-spacing: -1.5px;
          color: $color-white;
        }

        span.publicletter-detail__date {
          display: block;
          padding-top: 150px;
          font-weight: 700;
          font-size: 14px;
          line-height: 2.14;
          color: $color-white;
        }
      }
    }

    .ir-common {
      &__button {
        &.top {
          position: absolute;
          top: 0;
          left: -460px;
        }

        &.is-show-mo {
          display: none;
        }
      }
    }

    .detail {
      font-size: 16px;
      line-height: 1.88;
      letter-spacing: -0.5px;

      &__title {
        font-weight: 700;
        font-size: 22px;
        line-height: 1.55;
        letter-spacing: -0.6px;
      }

      &__item {
        padding-top: 60px;

        &.title {
          padding-top: 60px;
          font-weight: 700;
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }

        &-txt {
          padding-top: 30px;

          &:first-child {
            padding-top: 0;
          }
        }

        &.ratio {
          padding-bottom: 20px;

          .ratio {
            &-title {
              display: block;
              font-size: 16px;
              line-height: 1.88;
              letter-spacing: -0.5px;
            }
  
            &-img {
              width: 100%;
              height: 246px;
              margin-top: 20px;
              background-color: $color-default;
            }
  
            &-sources {
              display: block;
              margin-top: 20px;
              font-size: 12px;
              line-height: 1.83;
              letter-spacing: -0.3px;
            }
          }
        }

        &.sign {
          width: 300px;
          height: 102px;
          padding-top: 0;
          margin-top: 100px;
          background-color: $color-default;
        }

        &.naver {
          padding-top: 30px;
        }

        &.ceo {
          padding-top: 5px;
        }
      }
    }

    .publicletter-detail {
      position: relative;
      max-width: 880px;
      padding-left: 0;
      margin: 80px auto 160px;

      &__btn {
        display: flex;
        justify-content: space-between;
        margin-top: 160px;
      }
    }

    .media-list {
      margin-top: 100px;

      .title {
        max-width: 453px;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.publicletter-detail {
      .ir-common {
        &__button {
          &.top {
            left: -386px;
          }
        }
      }

      .publicletter-detail {
        max-width: 750px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.publicletter-detail {
      .common {
        &__title {
          &-inner {
            max-width: 718px;
            padding: 183px 0 40px;
          }

          h2 {
            font-size: 42px;
            line-height: 1.52;
            letter-spacing: -1.8px;
          }

          span.publicletter-detail__date {
            padding-top: 149px;
            line-height: 1.86;
          }
        }
      }

      .ir-common {
        &__button {
          &.top {
            display: none;
          }
        }
      }

      .publicletter-detail {
        max-width: 718px;
        margin: 80px auto 140px;
      }

      .media-list {
        margin-top: 80px;

        .title {
          max-width: 350px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.publicletter-detail {
      .common {
        &__title {
          &-inner {
            max-width: none;
            padding: 183px 30px 40px;
          }

          h2 {
            font-size: 38px;
            line-height: 1.53;
            letter-spacing: -1.5px;
          }

          span.publicletter-detail__date {
            padding-top: 118px;
            line-height: 1.86;
          }
        }
      }

      .publicletter-detail {
        max-width: none;
        margin: 60px auto 120px;
        padding: 0 30px;
      }

      .detail {
        font-size: 14px;
        line-height: 1.86;
        letter-spacing: -0.3px;

        &__title {
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }

        &__item {
          &.title {
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.4px;
          }
        }
      }

      .publicletter-detail {
        &__btn {
          margin-top: 80px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.publicletter-detail {
      .common {
        &__title {
          &-inner {
            padding: 183px 20px 50px
          }

          h2 {
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
          }

          span.publicletter-detail__date {
            padding-top: 127px;
          }
        }
      }

      .ir-common {
        &__button {
          &.list {
            display: none;
          }

          &.is-show-mo {
            display: flex;
            float: right;
            margin-top: 30px;

            &:after {
              display: block;
              content: '';
              clear: both;
            }
          }
        }
      }

      .detail {
        &__item {
          &.ratio {
            .ratio {
              &-title {
                font-size: 14px;
                line-height: 1.86;
                letter-spacing: -0.3px;
              }
            }
          }
        }
      }

      .publicletter-detail {
        margin: 60px auto 182px;
        padding: 0 20px;
      }

      .publicletter-detail {
        &__btn {
          margin-top: 100px;
        }
      }

      .media-list {
        margin-top: 40px;

        .title {
          max-width: 235px;
        }
      }
    }
  }
}
