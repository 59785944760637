.#{$component-name} {
  &.tech-ai {
    .container {
      padding-bottom: 0;
    }

    .track-animation {
      &__highlight-area {
        width: 1222px;
      }

      &__highlight-text {
        text-align: center;
      }

      &__dimmed {
        &.is-show {
          opacity: .85;
        }
      }
    }

    .full-sticky {
      &::after {
        background: rgba(0, 0, 0, 0.4);
      }

      .cont__title {
        margin-top: 0;
      }

      .cont__desc {
        letter-spacing: -0.6px;
      }
    }

    .section__right-box {
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 60px 0 515px;
    }

    .half {
      &__list {
        border-top: 1px solid #333;
      }
    }

    .cont__desc {
      .outlink {
        display: flex;
        align-items: center;
        margin-top: 30px;
        color: $color-white;
        font-size: 18px;
        font-weight: 800;
        line-height: 34px;
        letter-spacing: -.5px;
        transition: color .3s;

        &::before {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-right: 10px;
          background-image: url(/img/common/ic-outlink-circle-white.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transition: background .3s;
          border-radius: 50vh;
          content: '';
        }
  
        &:hover {
          color: $color-point;
          &:before {
            background: url(/img/common/ic-outlink-circle-black-hover.png) no-repeat center;
            background-size: contain;
          }
        }

        &.black {
          color: $color-default;

          &::before {
            background-image: url(/img/common/ic-outlink-circle-black.png);
          }
  
          &:hover {
            color: $color-point;
            &:before {
              background: url(/img/common/ic-outlink-circle-hover.png) no-repeat center;
              background-size: contain;
            }
          }
        }
      }
    }

    .content03 {
      position: relative;

      .cover {
        background-image: url('/img/pc/tech-ai-generative-bg.png');

        &::after {
          background: rgba(255, 255, 255, 0.3);
        }
      }

      .cont__logo {
        width: 618px;
      }

      .cont__desc {
        color: $color-default;
      }

      .generative-obj {
        picture {
          display: block;
          max-width: 1600px;
          width: 100%;
          margin: 0 auto;
        }

        &--en {
          display: none;
        }

        @at-root .navercorp.tech-ai {
          &.lang-en .generative-obj {
            &--ko {
              display: none;
            }
            &--en {
              display: block;
            }
          }
        }
      }
    }

    .content04 {
      padding-bottom: 200px;

      .cover {
        position: static;
        background-image: url(/img/pc/tech-ai-hyperclovax-bg.png);

        &::after {
          content: none;
        }
      }

      .cont__logo {
        width: 512px;
        margin-top: 14px;
      }

      .ai-info {
        padding: 100px 0;

        &__item {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 60px 0 80px;
          // border-top: 1px solid rgba(255, 255, 255, 0.2);

          &:first-child {
            border-top: 0;
          }
        }

        &__text {
          width: 528px;

          .title {
            display: block;
            font-size: 38px;
            font-weight: 800;
            line-height: 54px;
            letter-spacing: -1.5px;
            color: $color-white;
          }

          .desc {
            margin-top: 32px;
            color: #a1a1a6;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: -0.5px;
          }
        }

        &__thumb {
          width: 663px;
          background: $color-default;

          img {
            display: block;
            width: 442px;
            margin: 0 auto;
          }
        }
      }

      .ai-example {
        &__title {
          display: block;
          font-size: 38px;
          font-weight: 800;
          line-height: 54px;
          letter-spacing: -1.5px;
          color: $color-white;
        }

        .info__thumbnail {
          &-list {
            margin-top: 40px;
          }

          &-item {
            width: calc(50% - 10px);

            .info__outlink {
              &::before {
                background: url(../img/common/btn-outlink-white.png);
                background-size: contain;
                vertical-align: middle;
                content: "";
              }

              &:hover {
                &::before {
                  background: url(../img/common/btn-outlink-on.svg);
                  background-size: contain;
                  vertical-align: middle;
                  transition: background .3s;
                  content: "";
                }
              }
            }

          }
        }
      }
    }

    .content05 {
      .cover {
        background-image: url(/img/pc/tech-ai-clovax-bg.png);

        &::after {
          background: rgba(0, 0, 0, 0.6);
        }
      }

      .cont__logo {
        width: 299px;
        margin-top: 18px;
      }

      .clovax-obj {
        justify-content: flex-end;
        padding-top: 112px;

        picture {
          max-width: 1382px;
          margin: 0 auto -86px;
        }

        &::after {
          background: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .content06 {
      .cover {
        background-image: url(/img/pc/tech-ai-cue-bg.png);

        &::after {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      .cont__title,
      .cont__desc {
        color: $color-default;
      }

      .cont__logo {
        width: 184px;
        margin-top: 18px;
      }

      .cue-obj {
        justify-content: flex-end;

        picture {
          max-width: 1382px;
          margin: 0 auto -235px;
        }
      }
    }

    .content07 {
      .half {
        &__list {
          border-bottom: 1px solid #333;
          .half__box {
            &:first-child {
              position: relative;

              &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 768px;
                height: 519px;
                transform: translate(-50%, -50%);
                content: '';
              }
            }
          }

          .half__box {
            &:first-child {
              background: url(/img/pc/tech-ai-ad-bg.png) no-repeat center;
              background-size: cover;

              &::before {
                background: url(/img/pc/tech-ai-ad-tablet.png) no-repeat center;
                background-size: 100% auto;
              }
            }
          }

          .title {
            img {
              width: 430px;
            }
          }
        }

        &__box {
          &:last-child {
            align-items: center;
          }
        }

        &__info {
          padding: 0 217px 0 123px;

          .title img {
            display: block;
          }

          .name {
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -1px;
          }

          .text {
            margin-top: 60px;
            color: $color-white;
            font-size: 22px;
            line-height: 42px;
            letter-spacing: -0.6px;
          }
        }
      }
    }

    .content08 {
      .half {
        &__list {
          border-top: 0;

          .half__box {
            &:first-child {
              position: relative;

              &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 768px;
                height: 519px;
                transform: translate(-50%, -50%);
                content: '';
              }
            }
          }

          .half__box {
            &:first-child {
              background: url(/img/pc/tech-ai-writing-bg.png) no-repeat center;
              background-size: cover;

              &::before {
                background: url(/img/pc/tech-ai-writing-tablet.png) no-repeat center;
                background-size: 100% auto;
              }
            }
          }

          .title {
            img {
              width: 561px;
            }
          }
        }

        &__box {
          &:last-child {
            align-items: center;
          }
        }

        &__info {
          padding: 0 217px 0 123px;

          .title img {
            display: block;
          }

          .name {
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -1px;
          }

          .text {
            margin-top: 60px;
            color: $color-white;
            font-size: 22px;
            line-height: 42px;
            letter-spacing: -0.6px;
          }
        }
      }
    }

    .content09 {
      .half {
        &__list {
          .half__box {
            &:first-child {
              position: relative;

              &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 768px;
                height: 519px;
                transform: translate(-50%, -50%);
                content: '';
              }
            }
          }

          .half__box {
            &:first-child {
              background: url(/img/pc/tech-ai-connect-bg.png) no-repeat center;
              background-size: cover;

              &::before {
                background: url(/img/pc/tech-ai-connect-tablet.png) no-repeat center;
                background-size: 100% auto;
              }
            }
          }

          .title {
            img {
              width: 614px;
            }
          }
        }

        &__box {
          &:last-child {
            align-items: center;
          }
        }

        &__info {
          padding: 0 217px 0 123px;

          .title img {
            display: block;
          }

          .name {
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -1px;
          }

          .text {
            margin-top: 60px;
            color: $color-white;
            font-size: 22px;
            line-height: 42px;
            letter-spacing: -0.6px;
          }
        }
      }
    }

    .content10 {
      .cover {
        position: relative;
        background-image: url(/img/pc/tech-ai-clovastudio-bg.png);
        background-position: bottom;

          &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.6);
          }
      }

      .full-sticky {
        .cont__desc {
          margin-top: 98px;
        }
      }

      .cont__logo {
        width: 454px;
        margin-top: 16px;
      }

      .info {
        &__thumbnail {
          padding: 46px 0;
          background-color: $color-default;

          &-list {
            padding: 160px 0 140px;
          }

          &-item {
            width: calc((100% - 40px) / 3);
          }

          img {
            display: block;
            width: 197px;
            margin: 0 auto;
          }
        }

        &__contents {
          padding-right: 10%;
          padding-bottom: 60px;
        }

        &__title {
          margin-top: 20px;
          line-height: 1.88;
          letter-spacing: -0.5px;
        }

        &__desc {
          min-height: 90px;
          margin-top: 10px;
          letter-spacing: -0.5px;
        }

        &__tag {
          display: flex;
          flex-wrap: wrap;
          margin-top: 30px;
          margin-left: -6px;

          .tag {
            margin-top: 6px;
            margin-left: 6px;
            color: #86868b;
            font-size: 12px;
            font-weight: 800;
            line-height: 22px;
            padding: 1px 8px;
            background-color: $color-default;
          }
        }
      }
    }

    .content11 {
      .cover {
        position: relative;

        .video__player {
          height: 100%;
        }

        &::after {
          background: rgba(0, 0, 0, 0.6);
        }
      }

      .list-box {
        padding: 100px 0 200px;

        &__title {
          font-size: 22px;
          font-weight: 700;
          line-height: 42px;
          letter-spacing: -0.6px;
          color: $color-white;

          span {
            display: block;
          }
        }

        &__list {
          margin-top: 140px;
        }

        &__item {
          display: flex;
          padding: 60px 0 80px;
          border-top: 1px solid #2f2e30;

          .num {
            flex-shrink: 0;
            font-size: 16px;
            line-height: 34px;
            letter-spacing: -0.5px;
          }

          .title {
            flex-shrink: 0;
            width: 210px;
            margin-left: 95px;
            font-size: 26px;
            font-weight: 800;
            line-height: 38px;
            letter-spacing: -0.8px;
            color: $color-white;
          }

          .text {
            margin-left: 130px;
            padding-right: 214px;
            font-size: 16px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: -0.5px;
            color: rgba(255, 255, 255, 0.6);
          }

          .point {
            margin-bottom: 24px;
            color: $color-white;
          }
        }
      }

      .notice-board {
        &__top {
          display: flex;
        }

        &__title {
          flex-shrink: 0;
          width: 256px;
          font-size: 48px;
          font-weight: 800;
          line-height: 66px;
          letter-spacing: -1.5px;
          color: $color-white;
        }

        &__desc {
          margin-left: 200px;
          padding-right: 300px;
          font-size: 18px;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: -0.5px;
          color: $color-white;
        }

        .card__sub {
          padding-top: 80px;

          .sub__list {
            margin: -30px 0 0 -20px;
          }

          .sub__item {
            width: calc((100% - 60px) / 3);
            margin: 30px 0 0 20px;
          }

          .sub__img {
            background-image: none;
          }

          .sub__txt {
            min-height: 289px;
            padding: 30px 20px;
            background-color: $color-default;
            color: $color-white;

            .category {
              font-size: 14px;
              line-height: 28px;
            }

            .news-tit {
              margin-top: 12px;
            }

            .news-date {
              line-height: 28px;
            }
          }

          .pagination {
            margin-top: 80px;
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.tech-ai {
      .section__right-box {
        max-width: 1600px;
        padding: 0 40px 0 328px;
      }

      .content03 {
        .generative-obj {
          picture {
            max-width: 1200px;
          }
        }
      }

      .content04 {
        .ai-info {
          padding: 80px 0 90px;

          &__text {
            width: 528px;
          }

          &__thumb {
            width: 606px;

            img {
              width: 404px;
            }
          }
        }
      }

      .content05 {
        .clovax-obj {
          picture {
            max-width: 1150px;
            margin-bottom: -93px;
          }
        }
      }

      .content06 {
        .cue-obj {
          picture {
            max-width: 1150px;
            margin-bottom: -200px;
          }
        }
      }

      .content08 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 648px;
                  height: 440px;
                }
              }
            }
          }

          &__info {
            padding: 0 95px 0 90px;
          }
        }
      }

      .content07 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 648px;
                  height: 440px;
                }
              }
            }
          }

          &__info {
            padding: 0 95px 0 90px;
          }
        }
      }

      .content09 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 648px;
                  height: 440px;
                }
              }
            }
          }

          &__info {
            padding: 0 95px 0 90px;
          }
        }
      }

      .content10 {
        .info {
          &__thumbnail {
            padding: 42px 0;

            &-list {
              padding: 140px 0;
            }

            img {
              width: 180px;
            }
          }

          &__contents {
            padding-right: 0;
          }

          &__desc {
            margin-top: 12px;
          }
        }
      }

      .content11 {
        .list-box {
          &__item {
            .title {
              margin-left: 78px;
            }

            .text {
              margin-left: 175px;
              padding-right: 74px;
            }
          }
        }

        .notice-board {
          padding-bottom: 203px;

          &__desc {
            margin-left: 224px;
            padding-right: 100px;
          }

          .card__sub {
            .sub__list {
              margin: -30px 0 0 -8px;
            }

            .sub__item {
              width: calc((100% - 24px) / 3);
              margin: 30px 0 0 8px;
            }

            .sub__txt {
              min-height: 269px;
              padding: 20px;

              .news-tit {
                font-size: 22px;
                line-height: 34px;
              }

              .news-date {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.tech-ai {
      .track-animation {
        &__highlight-area {
          width: auto;
        }

        &__highlight-text {
          padding: 0 50px;
        }
      }

      .section__right-box {
        max-width: none;
        padding: 0 30px;
      }

      .content03 {
        .generative-obj {
          padding: 75px 0;

          picture {
            max-width: 518px;
          }
        }
      }

      .content04 {
        .ai-info {
          &__item {
            padding: 40px 0 50px;
          }

          &__text {
            width: 44.814%;

            .title {
              font-size: 26px;
              line-height: 38px;
              letter-spacing: -0.8px;
            }

            .desc {
              margin-top: 24px;
              line-height: 28px;
            }
          }

          &__thumb {
            width: 48.964%;

            img {
              width: 66.74%;
            }
          }
        }

        .ai-example {
          &__title {
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -1px;
          }

          .info__thumbnail-list {
            margin-top: 20px;
          }
        }
      }

      .content05 {
        .clovax-obj {
          justify-content: center;
          position: relative;
          min-height: auto;
          margin: 177px 0;
          padding: 98.926% 0 0 0;

          picture {
            position: absolute;
            top: 50%;
            left: 106px;
            margin: 0;
            height: 100%;
            transform: translateY(-50%);

            img {
              width: auto;
              height: 100%;
            }
          }

          .cont__wrap {
            width: auto;
          }
        }
      }

      .content06 {
        .full-sticky {
          background-image: url(/img/mo/tech-ai-cue-bg.png);
          background-size: cover;
        }

        .cue-obj {
          justify-content: center;
          position: relative;
          min-height: auto;
          margin: 129px 0;
          padding: 108.301% 0 0 0;

          picture {
            position: absolute;
            top: 50%;
            left: 106px;
            margin: 0;
            height: 100%;
            transform: translateY(-50%);

            img {
              width: auto;
              height: 100%;
            }
          }
        }
      }

      .content08 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 415px;
                  height: 283px;
                }
              }
            }

            .title img {
              width: 385px;
            }
          }

          &__info {
            padding: 0 50px;

            .name {
              margin-top: 12px;
              font-size: 22px;
              line-height: 34px;
              letter-spacing: -0.6px;
            }

            .text {
              margin-top: 40px;
              font-size: 16px;
              line-height: 28px;
              letter-spacing: -0.5px;
            }
          }
        }
      }

      .content07 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 415px;
                  height: 283px;
                }
              }
            }

            .title img {
              width: 296px;
            }
          }

          &__info {
            padding: 0 50px;

            .name {
              margin-top: 12px;
              font-size: 22px;
              line-height: 34px;
              letter-spacing: -0.6px;
            }

            .text {
              margin-top: 40px;
              font-size: 16px;
              line-height: 28px;
              letter-spacing: -0.5px;
            }
          }
        }
      }

      .content09 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 415px;
                  height: 283px;
                }
              }
            }

            .title img {
              width: 410px;
            }
          }

          &__info {
            padding: 0 50px;

            .name {
              margin-top: 12px;
              font-size: 22px;
              line-height: 34px;
              letter-spacing: -0.6px;
            }

            .text {
              margin-top: 40px;
              font-size: 16px;
              line-height: 28px;
              letter-spacing: -0.5px;
            }
          }
        }
      }

      .content10 {
        .full-sticky {
          background-position: 10.6% center;
        }

        .info {
          &__thumbnail {
            padding: 50px 0;

            &-list {
              padding: 80px 0 120px;
            }

            &-item {
              width: calc((100% - 20px) / 2);
            }

            img {
              width: 214px;
            }
          }

          &__contents {
            padding-bottom: 0;
          }
        }
      }

      .content11 {
        .list-box {
          padding: 80px 0 60px;

          &__list {
            margin-top: 100px;
          }

          &__title {
            font-size: 18px;
            line-height: 32px;
            letter-spacing: -0.5px;

            span {
              display: inline;
            }
          }

          &__item {
            .title {
              margin-left: 63px;
            }

            .text {
              margin-left: 87px;
              padding-right: 0;
            }
          }
        }

        .notice-board {
          padding-bottom: 180px;

          &__top {
            flex-direction: column;
          }

          &__title {
            width: auto;
            font-size: 32px;
            line-height: 48px;
            letter-spacing: -1px;
          }

          &__desc {
            margin-top: 26px;
            margin-left: 0;
            padding-right: 0;
            line-height: 32px;
          }

          .card__sub {
            .sub__list {
              margin: -24px 0 0 -5px;
            }

            .sub__item {
              width: calc((100% - 15px) / 3);
              margin: 24px 0 0 5px;
            }

            .sub__txt {
              min-height: 190px;
              padding: 16px 20px 20px;

              .category {
                font-size: 12px;
                line-height: 26px;
              }

              .news-tit {
                margin-top: 10px;
                font-size: 18px;
                line-height: 28px;
              }

              .news-date {
                font-size: 12px;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.tech-ai {
      .harf {
        &__list {
          border-top: 0;
        }
      }

      .cont__desc {
        .outlink {
          margin-top: 26px;
          font-size: 16px;
          line-height: 28px;

          &::before {
            width: 24px;
            height: 24px;
          }
        }
      }

      .content03 {
        .cont__logo {
          width: 441px;
        }

        .generative-obj {
          padding: 45px 0;

          picture {
            max-width: 400px;
          }
        }
      }

      .content04 {
        padding-bottom: 100px;

        .ai-info {
          padding: 30px 0 40px;

          &__item {
            padding: 50px 0 60px;
          }

          &__text {
            width: 44.07%;

            .title {
              font-size: 18px;
              line-height: 34px;
              letter-spacing: -0.5px;
            }

            .desc {
              margin-top: 12px;
              font-size: 14px;
              line-height: 26px;
              letter-spacing: -0.3px;
            }
          }

          &__thumb {
            width: 49.154%;

            img {
              width: 66.67%;
            }
          }
        }

        .ai-example {
          .info__thumbnail-list {
            margin-top: 12px;
          }
        }
      }

      .content05 {
        .cont__logo {
          width: 221px;
          margin-top: 6px;
        }

        .clovax-obj {
          margin: 131px 0;
          padding-top: 98.96%;

          picture {
            left: 74px;
          }
        }
      }

      .content06 {
        .cont__logo {
          width: 136px;
          margin-top: 6px;
        }

        .cue-obj {
          margin: 95px 0;
          padding-top: 108.204%;

          picture {
            left: 74px;
          }
        }
      }

      .content07 {
        .half {
          &__list {
            border: none;
            .half__box {
              &:first-child {
                &::before {
                  width: 646px;
                  height: 438px;
                }
              }
            }

            .title img {
              width: 242px;
            }
          }

          &__info {
            padding: 40px 30px 100px;

            .name {
              font-size: 18px;
              line-height: 30px;
              letter-spacing: -0.5px;
            }

            .text {
              padding-right: 25%;
            }
          }
        }
      }

      .content08 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 646px;
                  height: 438px;
                }
              }
            }

            .title img {
              width: 315px;
            }
          }

          &__info {
            padding: 40px 30px 100px;

            .name {
              font-size: 18px;
              line-height: 30px;
              letter-spacing: -0.5px;
            }

            .text {
              padding-right: 25%;
            }
          }
        }
      }

      .content09 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 646px;
                  height: 438px;
                }
              }
            }
          }

          &__info {
            padding: 40px 30px 100px;

            .name {
              font-size: 18px;
              line-height: 30px;
              letter-spacing: -0.5px;
            }

            .text {
              padding-right: 25%;
            }
          }
        }
      }

      .content10 {
        .cont__logo {
          width: 336px;
          margin-top: 8px;
        }

        .info {
          &__thumbnail {
            padding: 37px 0;

            &-list {
              padding: 80px 0 100px;
            }

            &-item {
              width: calc((100% - 12px) / 2);
            }

            img {
              width: 158px;
            }
          }

          &__tag {
            margin-top: 14px;
          }
        }
      }

      .content11 {
        .list-box {
          padding: 80px 0 30px;

          &__list {
            margin-top: 60px;
          }

          &__title {
            font-size: 16px;
            line-height: 28px;
          }

          &__item {
            flex-direction: column;
            padding: 30px 0 80px;

            .num {
              font-size: 14px;
              line-height: 28px;
            }

            .title {
              width: auto;
              margin-top: 4px;
              margin-left: 0;
              font-size: 22px;
              font-weight: 700;
              line-height: 34px;
              letter-spacing: -0.6px;

              br {
                display: none;
              }
            }

            .text {
              margin-top: 30px;
              margin-left: 0;
              padding-right: 28px;
              font-size: 14px;
              font-weight: 400;
              line-height: 26px;
              letter-spacing: -0.3px;
            }

            .point {
              margin-bottom: 50px;
              font-size: 16px;
              font-weight: 700;
              line-height: 28px;
              letter-spacing: -0.5px;
            }
          }
        }

        .notice-board {
          &__desc {
            font-size: 14px;
            line-height: 26px;
            letter-spacing: -0.3px;
          }

          .card__sub {
            padding-top: 60px;

            .sub__list {
              margin: -20px 0 0 -6px;
            }

            .sub__item {
              width: calc((100% - 12px) / 2);
              margin: 20px 0 0 6px;

              &:nth-child(3) {
                display: inline-block;
              }
            }

            .sub__txt {
              min-height: 223px;
              padding: 16px 20px;

              .category {
                line-height: 22px;
              }

              .news-tit {
                margin-top: 16px;
                line-height: 30px;
              }
            }

            .pagination {
              margin-top: 60px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.navercorp.tech-ai {
      .track-animation {
        &__highlight-area {
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 30px;
          text-align: left;
        }
      }

      .section__right-box {
        padding: 0 20px;
      }

      .content03 {
        .cont__logo {
          width: 335px;
        }

        .generative-obj {
          padding: 80px 0;
          picture {
            max-width: none;
          }
        }
      }

      .content04 {
        padding-bottom: 160px;

        .cont__logo {
          width: 272px;
          margin-top: 11px;
        }

        .ai-info {
          padding: 80px 0 40px;

          &__item {
            flex-direction: column;
            padding: 0 0 60px;
          }

          &__text {
            order: 2;
            width: 100%;
            margin-top: 20px;
          }

          &__thumb {
            order: 1;
            width: 100%;

            img {
              width: 66.57%;
            }
          }
        }

        .ai-example {
          .info__thumbnail-item {
            width: 100%;
          }

          .info__thumbnail-list {
            margin-top: 40px;
          }
        }
      }

      .content05 {
        .cover {
          background-image: url(/img/mo/tech-ai-clovax-bg.png);
        }

        .cont__logo {
          width: 166px;
          margin-top: 8px;
        }

        .clovax-obj {
          min-height: 100vh;
          margin: 0;
          padding-top: 0;

          picture {
            position: static;
            transform: none;
            height: auto;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .content06 {
        .full-sticky {
          width: 100%;
          background-image: url(/img/mo/tech-ai-cue-bg-mo.png);
          background-size: cover;
        }
        .cont__logo {
          width: 102px;
          margin-top: 8px;
        }

        .cue-obj {
          min-height: 100vh;
          margin: 0;
          padding-top: 0;

          picture {
            position: static;
            transform: none;
            height: auto;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .content07 {
        .half {
          &__list {
            border: none;
            .half__box {
              &:first-child {
                &::before {
                  width: 338px;
                  height: 229px;
                }
              }
            }
          }

          &__info {
            padding: 60px 20px 120px;

            .name {
              margin-top: 10px;
              font-size: 22px;
              line-height: 34px;
              letter-spacing: -0.6px;
            }

            .text {
              padding-right: 0;
            }
          }
        }
      }

      .content08 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 338px;
                  height: 229px;
                }
              }
            }
          }

          &__info {
            padding: 60px 20px 120px;

            .name {
              margin-top: 10px;
              font-size: 22px;
              line-height: 34px;
              letter-spacing: -0.6px;
            }

            .text {
              padding-right: 0;
            }
          }
        }
      }

      .content09 {
        .half {
          &__list {
            .half__box {
              &:first-child {
                &::before {
                  width: 338px;
                  height: 229px;
                }
              }
            }

            .title img {
              width: 332px;
            }
          }

          &__info {
            padding: 60px 20px 120px;

            .name {
              margin-top: 10px;
              font-size: 22px;
              line-height: 34px;
              letter-spacing: -0.6px;
            }

            .text {
              padding-right: 0;
            }
          }
        }
      }

      .content10 {
        .cover {
          background-position: 32.5% center;
        }

        .cont__logo {
          width: 252px;
        }

        .full-sticky {
          .cont__desc {
            margin-top: 52px;
          }
        }

        .info {
          &__thumbnail {
            padding: 35px 0 36px;

            &-list {
              padding: 60px 0 160px;
            }

            &-item {
              width: 100%;
            }

            img {
              width: 152px;
            }
          }

          &__tag {
            margin-top: 14px;
          }
        }
      }

      .content11 {
        .list-box {
          padding: 60px 0 100px;

          &__list {
            margin-top: 80px;
          }

          &__title {
            font-size: 16px;
            line-height: 28px;

            span {
              display: block;
              margin-top: 12px;

              &:first-child {
                margin-top: 0;
              }
            }
          }

          &__item {
            padding: 20px 0 80px;

            .text {
              margin-top: 40px;
              padding-right: 0;
            }

            .point {
              margin-bottom: 20px;
            }
          }
        }

        .notice-board {
          padding-bottom: 64px;
          .card__sub {
            padding-top: 0;
            .sub__list {
              margin: 0;
              .pagination {
                padding-top: 0;
              }
            }

            .sub__item {
              width: 100%;
              margin: 8px 0 0 0;

              &:first-child {
                margin-top: 0;
              }
            }

            .sub__txt {
              padding: 14px 20px;

              .category {
                line-height: 26px;
              }

              .news-tit {
                margin-top: 8px;
              }
            }
          }
        }
      }

      .card__sub-wrap {
        padding-top: 0;
      }
    }
  }
}
