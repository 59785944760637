.#{$component-name} {
  &.research {

    .section {
      max-width: 1800px;
      margin: 0 auto;
      padding: 80px 0 0 460px;

      .scroll-title__list {
        transform: none;
        padding:0;
          overflow-y: auto;
          &::-webkit-scrollbar {
            opacity: 0;
          }

        &-txt{
          display: none;
          font-weight: 700;
          font-size: 18px;
          color: var(--default);
        }
      }

      &.is-empty {
        .search-result__text {
          &.no-result {
            display: block;
            margin-top: 20px;
            padding: 40px 0 17px;
            border-top: 1px solid var(--border01);
          }
        }

        .search-result__list {
          display: block;
        }

        .scroll-title__list-txt {
          display: none;
        }

        .card__sub {
          &.is-list-view {
            display: none;
          }
        }

        .pagination {
          display: none;
        }
      }

      &.is-search {
        .util__item {
          justify-content: space-between;
        }

        .scroll-title__list-txt {
          display: block;
        }
      }
    }

    .util__item {
      display: flex;
      justify-content: end;
      align-items: center;

      .input__box {
        margin: 1px;
        box-shadow: 0px 0px 0px 1px var(--searchBox);

        input {
          color: var(--searchTxt);
        }

        &:hover {
          box-shadow: 0px 0px 0px 1px var(--default);
        }

        .btn-delete {
          &:hover {
            @include sp-desktop('ic-search-delete-sm-b');
          }
        }
      }
    }

    .card__sub {
      padding-top: 20px;

      &.is-list-view {
        .sub__item {
          &:last-child {
            border-bottom: 0;
          }
        }

        .sub__img {
          width: 240px;
          padding-top: 160px;
        }

        .sub__txt {
          padding-left: 145px;

          .news-tit {
            font-size: 24px;
            line-height: 1.58;
            letter-spacing: normal;
          }

          .news-date {
            margin-top: 0;
            font-size: 14px;
          }
        }
      }
    }

    .pagination {
      margin-top: 60px;
    }

    .research {
      &__search {
        overflow-y: scroll;
        position: relative;
        max-width: 1800px;
        margin: 80px auto 0;

        &::-webkit-scrollbar {
          opacity: 0;
        }
      }

      &__dimmed {
        display: none;
        width: 100%;
        height: 100vh;
        background-color: $color-white;
      }
    }

    .scroll-title__list {
      // display: none;
      // position: absolute;
      // top: 0;
      // left: 0;
      width: 100%;
    }

    .scroll-title__list-txt {
      display: block;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.research {
      .section {
        max-width: 1520px;
        padding-left: 386px;
      }

      .research {
        &__search {
          width: 1520px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.research {
      .section {
        max-width: none;
        padding: 56px 30px 0;
      }

      .card__sub {
        padding-top: 24px;

        &.is-list-view {
          .sub__link {
            padding: 20px 0;
          }

          .sub__img {
            width: 154px;
            padding-top: 102px;
          }

          .sub__txt {
            padding-left: 92px;

            .news-tit {
              font-size: 22px;
              line-height: 1.91;
              letter-spacing: -0.8px;
            }

            .news-date {
              margin-top: -5px;
            }
          }
        }
      }

      .research {
        &__search {
          width: 100%;
          margin-top: 56px;
          padding: 0 10px 0 30px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.research {
      .section {
        padding: 60px 30px 0;
      }

      .card__sub {
        padding-top: 20px;

        &.is-list-view {
          .sub__link {
            padding: 16px 0;
          }

          .sub__img {
            width: 115px;
            padding-top: 76px;
          }

          .sub__txt {
            padding-left: 65px;

            .news-tit {
              font-size: 16px;
              line-height: 1.88;
              letter-spacing: -0.5px;
            }

            .news-date {
              margin-top: 0;
              font-size: 12px;
              line-height: 1.67;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.research {
      .util__item {
        flex-direction: column;
        width: 100%;
        margin: 0 auto;

        .input__box {
          width: 100%;
          margin: 1px 2px 1px 0;
        }
      }

      .scroll-title__list-txt {
        width: 100%;
        margin-top: 30px;
        order: 1;
      }

      .section {
        padding: 60px 20px 0;
      }

      .card__sub {
        padding-top: 30px;

        &.is-list-view {
          .sub__link {
            padding: 14px 0;
          }

          .sub__img {
            width: 103px;
            padding-top: 69px;
          }

          .sub__txt {
            margin-top: -6px;
            padding-left: 20px;

            .news-tit {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }

            .news-date {
              margin-top: 7px;
            }
          }
        }
      }

      .research {
        &__search {
          padding: 0 0 0 20px;
        }
      }
    }
  }
}
