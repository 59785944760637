.#{$component-name} {
  &.naver-proposal {
    .section__wrap {
      max-width: 1800px;
      margin: 0 auto;
      padding: 0 204px 0 460px;

      &:first-child {
        padding-top: 60px;
      }
    }

    .js-nav-section {
      padding: 80px 0;

      &:first-child {
        padding-top: 43px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    .page-info {
      font-size: 26px;
      font-weight: 800;
      line-height: 40px;
      letter-spacing: -.8px;
      color: var(--accordionNum);
    }

    .section__title {
      font-size: 22px;
      font-weight: 800;
      line-height: 42px;
      letter-spacing: -.6px;

      &+.guide__list {
        margin-top: 40px;
      }
    }

    .link-box {
      &__wrap {
        margin-top: 30px;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        padding: 20px 0 30px;

        &:nth-of-type(n+2) {
          border-top: 1px solid var(--border01);
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      &__item {
        width: calc(100%/3);
        min-height: 190px;
        margin-top: 10px;

        .title {
          font-size: 20px;
          font-weight: 800;
          line-height: 30px;
          letter-spacing: -0.6px;

          &-sub {
            display: block;
          }
        }

        .icon {
          position: absolute;
          left: 14px;
          bottom: 14px;
          width: 80px;
          height: 80px;

          .white {
            display: block;
          }

          .dark {
            display: none;
          }
        }

        .ico-link {
          position: absolute;
          right: 24px;
          bottom: 24px;

          &::after {
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: var(--outlinkBg02) url(/img/common/ic-quicklink.png) no-repeat center;
            background-size: contain;
            transition: background 0.3s;
            content: '';
          }

          &.outlink {
            &::after {
              transform: rotate(-45deg);
            }
          }
        }
      }

      &__link {
        display: block;
        position: relative;
        width: calc(100% - 10px);
        height: 100%;
        margin: 0 auto;
        padding: 20px 24px 24px;
        background-color: var(--bgColor02);
        &:hover {
          .ico-link::after {
            background-color: $color-point;
          }
        }
        
        &--en {
          display: none;
        }

        @at-root .navercorp.naver-proposal {
            &.lang-en .link-box__link {
              &--ko {
                display: none;
              }
              &--en {
                display: block;
              }
            }
        } 
      }
    }

    .section {
      &__box {
        margin-bottom: 100px;
        padding-top: 20px;
        padding-right: 302px;
        border-top: 1px solid var(--border01);
        line-height: 1.57;
        color: var(--default);

        &:first-child {
          padding-top: 60px;
          border-top: 0;
        }

        &:last-child {
          margin-bottom: 80px;
        }

        &.is-no-border {
          border-top: none;
          padding-top: 0;
        }

        &-inner {
          margin-top: 60px;
        }
      }
    }

    .btn-wrap {
      justify-content: center;
      padding-right: 302px;
    }

    .guide {
      &__item {
        position: relative;
        padding-left: 6px;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -.5px;
        color: var(--color-sub);

        &::before {
          position: absolute;
          top: 14px;
          left: 0;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background-color: var(--color-sub);
          content: '';
        }
      }
    }

    .input {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;

      &__item {
        width: 44.413%;
        margin-top: 40px;

        .dropdown__select {
          width: 100%;

          .dropdown__selected-value{
            span{
              @at-root .theme-dark & {
                color: #a1a1a6;
              }
            }
          }
        }

        &.type-full {
          width: 100%;
        }
      }

      &__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.86;
        letter-spacing: -0.3px;
        margin-bottom: 10px;
        color: var(--default);
      }

      &__text {
        display: block;
        margin-top: 8px;
        color: var(--secDesc);
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -.26px;
      }
    }

    .ir-common__checkbox {
      label {
        font-size: 16px;
      }
    }

    .inform {
      overflow: auto;
      max-height: 206px;
      margin-top: 17px;
      padding: 24px;
      border: 1px solid var(--border01);

      &::-webkit-scrollbar {
        width: 26px;
      }

      &::-webkit-scrollbar-thumb {
        background: #f1f1f4;
        background-clip: padding-box;
        border: 10px solid transparent;
        border-radius: 23px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      .text {
        color: var(--box-text);
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -.3px;

        &.is-bold {
          font-weight: 800;
        }
      }
    }

    .proposal-state {
      position: relative;
      padding-left: 150px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 120px;
        height: 120px;
        background: url(/img/pc/ic-naver-partnerships.png) no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
        content: '';
      }

      &__title {
        font-size: 38px;
        font-weight: 800;
        line-height: 58px;
        letter-spacing: -1.5px;
        color: var(--default);
      }

      &__text {
        display: flex;
        margin-top: 12px;

        &>div {
          margin-right: 40px;
          font-size: 0;

          &:last-child {
            margin-right: 0;
          }
        }

        .title {
          font-size: 16px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: -.3px;
          color: var(--default);
        }

        .num {
          margin-left: 16px;
          font-size: 16px;
          font-weight: 900;
          line-height: 30px;
          color: var(--default);
        }
      }
    }

    .register-info {
      margin-top: 50px;
      font-size: 22px;
      line-height: 42px;

      &__num {
        display: flex;
      }

      .title {
        margin-right: 20px;
        font-weight: 800;
        letter-spacing: -.6px;
        color: var(--default);
      }

      .num {
        color: $color-point;
        font-weight: 900;
      }
    }

    .input__file-input {
      width: calc(100% - 140px);
      cursor: pointer;
    }

    .input__file-delete {
      z-index: 10;
    }
 
    .selectedFileMessage {
      position: absolute;
      z-index: 0;
      left: 16px;
      font-size: 14px;
      color: var(--inputColor);
      -webkit-text-fill-color: var(--inputColor);
      background-color: transparent;
      width: 200px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  &.naver-proposal-inquire {
    .page-info {
      padding: 60px 0 37px;
    }

    .section__title {
      font-size: 26px;
      line-height: 38px;
      letter-spacing: -.8px;
    }

    .content02 {
      .link-box {
        &__item {
          .icon {
            left: 24px;
            bottom: 30px;
          }

          &.cloud {
            .title {
              line-height: 30px;
            }

            .icon {
              width: 165px;
              height: 18px;
            }
          }

          &.snow {
            .icon {
              width: 83px;
              height: 20px;
            }
          }

          &.labs {
            .icon {
              width: 173px;
              height: 18px;
            }
          }

          &.webtoon {
            .icon {
              left: 11px;
              bottom: 0;
              width: 76px;
              height: 76px;
            }
          }

          &.financial {
            .icon {
              bottom: 23px;
              width: 124px;
              height: 32px;
            }
          }
        }
      }
    }
  }

  &.naver-proposal-guide {
    .common-list-layout .section {
      padding-left: 0;
    }

    .section__box {
      position: relative;
      margin-bottom: 120px;
      padding-left: 460px;

      &:first-child {
        margin-bottom: 0 !important;
        padding: 80px 302px 100px 460px;
      }
    }

    .section__num {
      position: absolute;
      top: 30px;
      left: 0;
      color: var(--numColor);
      font-size: 110px;
      font-weight: 700;
      line-height: 0.82;
    }

    .btn-wrap {
      padding: 0 302px 0 460px;
    }

  }

  &.naver-proposal-complete {
    .section__box {
      &:last-child {
        border-bottom: 1px solid var(--border01);
      }

      &.info-top {
        margin-bottom: 30px;
        padding-top: 0;
      }

      &.info-text {
        margin-top: 30px;
        padding: 30px 0 50px;

        p {
          font-size: 14px;
          line-height: 26px;
          letter-spacing: -.3px;
          color: var(--color-sub);
        }
      }
    }

    .section__title {
      font-size: 38px;
      line-height: 58px;
      letter-spacing: -1.5px;
    }
  }

  &.naver-proposal-register {
    .ir-common {
      &__enquiry {
        position: relative;
        .ir-common__input {
          height: 360px;
          white-space: pre-wrap;
        }
      }
    }
  }
}

html.theme-dark {
  .navercorp.naver-proposal {
    .link-box__item {
      .icon {
        .white {
          display: none;
        }

        .dark {
          display: block;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.naver-proposal {
      .section__wrap {
        max-width: 1520px;
        padding: 0 0 0 384px;
      }

      .section__box {
        padding-right: 97px;
      }

      .btn-wrap {
        padding-right: 97px;
      }

      .link-box{
        &__item {
          .title {
            width: 220px;
          }
        }
      }
    }

    &.naver-proposal-guide {
      .section__box {
        padding-left: 386px;

        &:first-child {
          padding: 80px 97px 100px 386px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.naver-proposal {
      .section__wrap {
        max-width: none;
        padding: 0 30px;
      }

      .js-nav-section {
        padding: 60px 0;
      }

      .page-info {
        font-size: 22px;
        line-height: 34px;
        letter-spacing: -.6px;
      }

      .section__box {
        padding-right: 0;

        &:first-child {
          margin-bottom: 90px;
        }
        
      }

      .btn-wrap {
        padding-right: 0;
      }

      .link-box {
        &__wrap {
          margin-top: 0;
        }

        &__list {
          margin: 0 -5.5px;

          &:first-child {
            padding: 30px 0;
          }
        }

        &__item {
          margin-top: 11px;

          .title{
            width: 220px;
          }

          .icon {
            left: 12px;
            bottom: 12px;
          }

          .ico-link {
            right: 20px;
            bottom: 20px;
          }
        }

        &__link {
          width: calc(100% - 11px);
          padding: 16px 20px 20px;
        }

      }

      .input {
        &__item {
          width: 47.823%;
        }
      }

      .proposal-state {
        &__title {
          font-size: 32px;
          line-height: 57px;
          letter-spacing: -1px;
        }
      }
    }

    &.naver-proposal-inquire {
      .content02 {
        .link-box {
          &__item {
            .icon {
              left: 20px;
              bottom: 26px;
            }

            &.webtoon {
              .title {
                line-height: 30px;
              }
            }
          }
        }
      }
    }

    &.naver-proposal-guide {
      .common-list-layout {
        .section {
          padding: 0 30px;
        }
      }

      .section__box {
        padding-left: 0;

        &:first-child {
          padding: 80px 0 100px;
        }
      }

      .section__num {
        display: none;
      }

      .btn-wrap {
        padding: 0;
      }
    }

    &.naver-proposal-complete {
      .section__title {
        font-size: 32px;
        line-height: 48px;
        letter-spacing: -1px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.naver-proposal {
      .js-nav-section {
        padding: 50px 0;

        &:first-child {
          padding-top: 0;
        }
      }

      .page-info {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -.5px;
      }

      .link-box {
        &__list {
          margin: 0 -3px;
          padding: 16px 0 24px;

          &:first-child {
            padding: 24px 0;
          }
        }

        &__item {
          width: 50%;
          min-height: 150px;
          margin-top: 6px;

          .title {
            font-size: 18px;
            line-height: 28px;
            letter-spacing: -.5px;

            &-sub {
              font-size: 12px;
              line-height: 22px;
              letter-spacing: -.26px;
            }
          }

          .icon {
            width: 60px;
            height: 60px;
          }

          .ico-link::after {
            width: 24px;
            height: 24px;
          }
        }

        &__link {
          width: calc(100% - 6px);
          padding: 18px 20px 20px;
        }

      }

      .section__box {
        margin-bottom: 80px;
        padding-top: 10px;
        padding-right: 0;

        &:first-child {
          margin-bottom: 60px;
          padding-top: 40px;
        }

        &-inner {
          margin-top: 50px;
        }
      }

      .section__title {
        &+.guide__list {
          margin-top: 30px;
        }
      }

      .guide {
        &__item {
          font-size: 14px;
          line-height: 26px;
          letter-spacing: -.3px;
        }
      }

      .input {
        margin-top: 0;

        &__title {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: -.26px;
        }

        &__item {
          width: 47.318%;
        }
      }

      .ir-common__checkbox label {
        font-size: 14px;
        letter-spacing: -.3px;
      }

      .inform {
        margin-top: 20px;
        padding: 20px;

        .text {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: -.26px;
        }
      }

      .register-info {
        font-size: 18px;
        line-height: 34px;
      }

      .proposal-state {
        padding-left: 120px;

        &::before {
          width: 100px;
          height: 100px;
        }

        &__title {
          font-size: 26px;
          line-height: 38px;
          letter-spacing: -.8px;
        }

        &__text {
          .title {
            font-size: 14px;
            line-height: 26px;
          }

          .num {
            font-size: 14px;
            line-height: 26px;
          }
        }
      }
    }

    &.naver-proposal-inquire {
      .page-info {
        padding: 60px 0;
      }

      .section__title {
        font-size: 22px;
        line-height: 42px;
        letter-spacing: -0.6px;
      }

      .content02 {
        .link-box {
          &__item {
            .icon {
              left: 20px;
              bottom: 26px;
            }

            &.cloud {
              .icon {
                width: 123px;
                height: 13px;
              }
            }

            &.snow {
              .icon {
                width: 68px;
                height: 16px;
              }
            }

            &.labs {
              .icon {
                width: 122px;
                height: 13px;
              }
            }

            &.webtoon {
              .icon {
                left: 12px;
                bottom: 2px;
                width: 60px;
                height: 60px;
              }
            }

            &.financial {
              .icon {
                bottom: 19px;
                width: 100px;
                height: 26px;
              }
            }
          }
        }
      }
    }

    &.naver-proposal-guide {
      .section__box {
        margin-bottom: 80px;

        &:first-child {
          padding: 60px 0 80px;
        }
      }
    }

    &.naver-proposal-complete {
      .section__title {
        font-size: 26px;
        line-height: 38px;
        letter-spacing: -.8px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.naver-proposal {
      .section__wrap {
        padding: 0 20px;
      }

      .section__box {
        padding-top: 10px;

        &:last-child {
          margin-bottom: 60px;
        }
      }

      .page-info {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -.5px;
      }

      .link-box {
        &__list {
          margin: 0;
          padding: 20px 0;

          &:first-child {
            padding: 26px 0 20px;
          }
        }

        &__item {
          width: 100%;
          min-height: 143px;
          margin-top: 4px;

          .title{
            line-height: 26px;
          }
        }

        &__link {
          width: 100%;
        }
      }

      .input {
        &__item {
          width: 100%;
          margin-top: 30px;

          &:first-child {
            margin-top: 40px;
          }

          &.type-file {
            order: 5;
          }
        }
      }

      .inform {
        max-height: 342px;
        padding: 16px;
      }

      .register-info {
        &__num {
          flex-direction: column;

          .num {
            margin-top: 6px;
          }
        }
      }

      .proposal-state {
        padding-left: 0;

        &::before {
          display: block;
          position: unset;
          transform: none;
          margin-bottom: 20px;
          margin-left: -12px;
        }

        &__text {
          flex-direction: column;
          margin-top: 30px;

          &>div {
            margin-top: 4px;
            margin-right: 0;

            &:first-child {
              margin-top: 0;
            }
          }

          .title {
            display: inline-block;
            min-width: 80px;
          }

          .num {
            margin-left: 26px;
          }
        }
      }
    }

    &.naver-proposal-inquire {
      .page-info {
        padding: 40px 0 60px;

        br {
          display: none;
        }
      }
    }

    &.naver-proposal-guide {
      .common-list-layout {
        .section {
          padding: 0 20px;
        }
      }

      .section__box {
        &:first-child {
          padding: 40px 0 60px;
        }
      }
    }
  }
}
