.#{$component-name} {
  &.ethics-center {
    .common__title {
      border-bottom: 0;

      &-inner {
        padding-top: 150px;
        padding-bottom: 81px;
      }
    }

    .ir-common {
      &__checkbox {
        margin-top: 20px;

        &.is-failed {
          input {
            border-color: #f30;
          }
        }
      }

      &__button {
        background-color: var(--default);
        color: var(--reverse);

        &:hover {
          background-color: $color-point;
          color: $color-white;
        }
      }

      &__input {
        font-size: 14px;
        line-height: 2;
        letter-spacing: -0.3px;

        &:hover {
          box-shadow: none;
          border-color: #a1a1a6;
        }

        &.is-failed {
          &:hover {
            border-color: #f30;
          }
        }
      }

      input {
        border-color: var(--color-button02);
      }
    }

    .section {
      max-width: 1800px;
      margin: 0 auto;
      padding-top: 60px;
      padding-left: 460px;

      &__sub-title {
        width: 900px;
        font-weight: 800;
        font-size: 26px;
        line-height: 1.54;
        letter-spacing: -0.8px;
        color: var(--secDesc);
      }

      &__info {
        &-text {
          display: flex;
          color: var(--default);

          .dot {
            margin-top: 5px;
            margin-right: 3px;
          }

          .text {
            font-size: 16px;
            line-height: 1.88;
            letter-spacing: -0.3px;
          }
        }
      }
    }

    .ethics-center {
      &__top-btn {
        position: relative;
        max-width: 1800px;
        margin: 0 auto;
      }

      &__btn {
        position: absolute;
        top: 63px;
        left: 0;
        width: 30px;
        height: 30px;
        background: var(--ic-back) no-repeat center;
        background-size: contain;
      }

      &__section {
        margin-top: 100px;
        padding-top: 20px;
        border-top: 1px solid var(--border03);

        &-title {
          display: block;
          font-weight: 800;
          font-size: 22px;
          line-height: 1.91;
          letter-spacing: -0.6px;
          color: var(--default);
        }

        &-head {
          display: flex;
          justify-content: space-between;
        }

        &.info {
          margin-top: 80px;

          .ethics-center__list {
            margin-top: 25px;

            &.checkbox-list {
              margin-top: 100px;
            }
          }
        }

        &.form, &.collection {
          // display: none;
        }

        &.form {
          .ethics-center__section-title {
            padding-bottom: 20px;
          }

          &.form-content {
            width: 1038px;
          }

          &.form-info {
            .ir-common {
              &__checkbox {
                margin-top: 7px;

                label {
                  font-size: 14px;
                  line-height: 1.86;
                  letter-spacing: -0.3px;
                }
              }
            }
          }
        }

        &.collection {
          width: 1038px;

          .ethics-center__list {
            margin-top: 25px;
          }
        }

        &.check {
          margin-top: 100px;

          .ethics-center {
            &__input {
              margin-top: 60px;
            }
          }
        }
      }

      &__box {
        width: 1038px;
        margin-top: 60px;
        padding: 24px 28px 25px 20px;
        border: 1px solid var(--border01);

        .text {
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;
          color: $color-gray3;
          color: var(--box-text);

          .em {
            font-weight: 800;
          }
        }
      }

      &__list {
        &.checkbox-list {

          // display: none;
          .ethics-center__item {
            .text {
              font-size: 16px;
              line-height: 1.88;
              letter-spacing: -0.3px;
              color: var(--default);
            }

            .dot {
              margin-top: 5px;
              color: var(--default);
            }
          }
        }
      }

      &__item {
        display: flex;

        .dot {
          margin: 3px 3px 0 0;
        }
      }

      &__input {
        display: flex;
        justify-content: space-between;
        width: 1038px;
        margin-top: 40px;

        &.input-detail {
          margin-top: 12px;

          .input {
            width: 100%;
          }
        }

        .input {
          width: 461px;

          &__title {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 1.86;
            color: var(--default);
          }

          &__text {
            display: block;
            margin-top: 8px;
            font-size: 12px;
            line-height: 1.67;
            letter-spacing: -0.26px;
            color: var(--secDesc);
          }
        }

        .ir-common__enquiry {
          .ir-common__input {
            height: 244px;
            padding: 23px 24px;
          }
        }
      }

      &__bottom-btn {
        // display: none;
        width: 1038px;
        margin-top: 80px;
        text-align: center;

        &.result {
          display: block !important;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ethics-center {
      .section {
        max-width: 1520px;
        padding-left: 386px;
      }

      .ethics-center {
        &__top-btn {
          max-width: 1520px;
          padding-left: 386px;
        }

        &__section {
          width: 1038px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ethics-center {
      .container {
        margin-bottom: 140px;
      }

      .common__title {
        &-inner {
          padding-top: 140px;
          padding-bottom: 68px;
        }
      }

      .section {
        max-width: none;
        padding: 60px 30px 0;

        &__sub-title {
          width: 100%;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
        }

        &__info {
          &-text {
            .dot {
              margin-top: 3px;
            }

            .text {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }
        }
      }

      .ethics-center {
        &__top-btn {
          display: none;
        }

        &__section {
          width: 100%;

          &.info {
            margin-top: 80px;

            .ethics-center__list {
              &.checkbox-list {
                margin-top: 80px;
              }
            }
          }

          &.form {
            &.form-content {
              width: 100%;
              margin-top: 80px;
            }
          }

          &.collection {
            width: 100%;
          }

          &.check {
            margin-top: 80px;
          }
        }

        &__box {
          width: 100%;
        }

        &__input {
          width: 100%;

          .input {
            width: calc(50% - 21px);
          }
        }

        &__bottom-btn {
          width: 100%;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ethics-center {
      .container {
        margin-bottom: 100px;
      }

      .ir-common__checkbox {
        margin-top: 16px;

        label {
          font-size: 14px;
          line-height: 2;
          letter-spacing: -0.3px;
        }
      }

      .section {
        &__sub-title {
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }
      }

      .ethics-center {
        &__section {
          margin-top: 60px;
          padding-top: 30px;

          &.info {
            margin-top: 60px;

            .ethics-center__list {
              &.checkbox-list {
                margin-top: 60px;
              }
            }
          }

          &.form {
            &.form-content {
              margin-top: 60px;
            }

            &.form-info {
              .ir-common__checkbox {
                margin-top: 4px;

                label {
                  font-size: 12px;
                  line-height: 1.83;
                  letter-spacing: -0.3px;
                }
              }
            }

            .ethics-center__section-title {
              padding-bottom: 30px;
            }
          }

          &.check {
            .ethics-center__section-title {
              font-size: 22px;
              line-height: 1.64;
              letter-spacing: -0.6px;
            }
          }

          &-title {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }

        &__box {
          padding: 20px;

          .text {
            font-size: 12px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__list {
          &.checkbox-list {
            .ethics-center__item {
              margin-top: 4px;

              .text {
                font-size: 14px;
                line-height: 1.83;
                letter-spacing: -0.3px;
              }
            }
          }
        }

        &__item {
          margin-top: 1px;

          &:first-child {
            margin-top: 0;
          }
        }

        &__input {
          margin-top: 30px;

          &.input-detail {
            margin-top: 30px;
          }

          .input {
            width: calc(50% - 17px);

            &__title {
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: normal;
            }
          }
        }

        &__bottom-btn {
          margin-top: 60px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.ethics-center {
      .common__title {
        &-inner {
          padding-top: 110px;
          padding-bottom: 40px;
        }
      }

      .section {
        padding: 60px 20px 0;

        &__sub-title {
          font-size: 16px;
          line-height: 1.88;
          letter-spacing: -0.5px;
        }
      }

      .ethics-center {
        &__box {
          padding: 14px 16px;
        }

        &__section {
          &.info {
            .ir-common__checkbox {
              display: flex;

              input {
                margin-top: 2px;
              }
            }
          }

          &.form {
            &.form-info {
              margin-top: 80px;
            }
          }

          &.collection {
            margin-top: 80px;
          }

          &.check {
            margin-top: 60px;
          }
        }

        &__list {
          &.checkbox-list {
            .ethics-center__item {
              .dot {
                margin-top: 3px;
              }
            }
          }
        }

        &__item {
          .dot {
            margin-top: 0;
          }
        }

        &__input {
          display: block;

          &.input-detail {
            .input__text {
              margin-top: 18px;
            }
          }

          .input {
            width: 100%;

            &:last-child {
              margin-top: 30px;
            }
          }

          .ir-common__enquiry {
            .ir-common__input {
              height: 324px;
            }
          }
        }

        &__bottom-btn {
          margin-top: 50px;

          .ir-common__button {
            width: 100%;
            height: 54px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
