.#{$component-name} {
  &.digital-twin {
    .track-animation {
      &__highlight-area {
        width: 1470px;
      }

      &__highlight-text {
        text-align: center;
      }
    }

    .container {
      padding-bottom: 0;
    }

    .full-sticky {
      &.is-hide {
        &:after {
          display: none;
        }
      }
    }

    .cover {
      position: relative;

      .video__player {
        height: 100%;
      }
    }

    .content02 {
      .em {
        display: block;
      }
    }

    .content03 {
      // TODO
      .cover {
        background: url('../img/pc/tech-digital-twin-thumb.jpg') no-repeat center / cover;
      }

      .info {
        &__thumbnail-list {
          margin-top: 160px;
        }
      }
    }

    .content04 {
      .cover {
        background: url('../img/pc/tech-digital-twin-alike-bg01.png') no-repeat center / cover;
        &::after{
          display: none;
        }
      }

      .video__player {
        height: 100%;

      }

      .alike-view {
        position: relative;
        height: 100vh;

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
        }

        .cont {
          &__wrap {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          &__desc {
            margin-top: 18px;
            padding-left: 52%;
            padding-right: 9.38%;
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }
        }

        &:nth-child(3) {
          background: url(../img/pc/tech-digital-twin-alike-bg02.png) no-repeat center / cover;
        }
        &:nth-child(4) {
          background: url(../img/pc/tech-digital-twin-alike-bg03.png) no-repeat center / cover;
        }
        &:nth-child(5) {
          background: url(../img/pc/tech-digital-twin-alike-bg04.png) no-repeat center / cover;
        }
        &:nth-child(6) {
          background: url(../img/pc/tech-digital-twin-alike-bg05.png) no-repeat center / cover;
        }
      }

      .logo {
        height: 72px;
        padding-left: 52%;

        img {
          width: auto;
          height: 100%;
        }
      }
    }

    .content05 {
      .cover {
        background: url('../img/pc/tech-digital-twin-mapping-bg.png') no-repeat center / cover;
      }

      .half__list {
        .half__box {
          background-color: #000;
        }
        .half__box:first-child {
          background: url('../img/pc/tech-digital-twin-thumb.jpg') no-repeat center / cover;
        }
        &:nth-child(2) {
          .half__box:first-child {
            background-image: url('../img/pc/tech-digital-twin-thumb.jpg');
          }
        }
        &:nth-child(3) {
          .half__box:first-child {
            background-image: url('../img/pc/tech-digital-twin-thumb.jpg');
          }
        }
      }
    }

    .content06 {
      padding-bottom: 111px;

      .cont__inner {
        max-width: none;
        padding-top: 208px;
        padding-bottom: 208px;
        padding-left: 20.88%;
        padding-right: 10.83%;
      }

      .cont__title {
        color: $color-white;
      }

      .cont__title {
        margin-top: 0;
        font-size: 64px;
        line-height: 1.38;
        letter-spacing: -2px;
      }

      .cont__desc {
        margin-top: 100px;
        padding-left: 48.93%;
        font-weight: 700;
        font-size: 22px;
        line-height: 1.91;
        letter-spacing: -0.6px;
        color: $color-white;
      }

      .info__title {
        &:after {
          display: inline-block;
          width: 25px;
          height: 25px;
          margin-left: 4px;
          background: url(/img/common/ic-outlink-white3.png) no-repeat center;
          background-size: contain;
          vertical-align: middle;
          content: '';
        }
      }

      .info__link {
        &:hover {
          .info__title {
            transition: color .3s;

            &::after {
              transition: background .3s;
            }

            &:hover {
              color: $color-point;
              &::after {
                background: url(/img/common/btn-outlink-sm-on.png) no-repeat center;
                background-size: contain;
              }
            }
          }
        }
      }
    }

    .half__img {
      top: 60px;
      left: 50%;
      width: 640px;
      height: 640px;
      transform: translateX(-50%);
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.digital-twin {
      .content06 {
        padding-bottom: 140px;
        
        .cont__inner {
          padding-top: 140px;
          padding-bottom: 140px;
          padding-left: 20.5%;
          padding-right: 13%;
        }

        .cont__title {
          font-size: 64px;
          line-height: 1.38;
          letter-spacing: -2px;
        }

        .cont__desc {
          margin-top: 100px;
          padding-left: 35.3%;
        }
      }
      
      .half__img {
        top: 50px;
        width: 540px;
        height: 540px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.digital-twin {
      .track-animation {
        &__highlight-area {
          width: auto;
        }

        &__highlight-text {
          padding: 0 50px;
        }
      }

      .content03 {
        .info {
          &__thumbnail-list {
            margin-top: 120px;
          }
        }
      }

      .content04 {
        margin-top: 120px;

        .alike-view {
          .cont__inner {
            padding-left: 0;
            padding-right: 13%;
          }

          .cont__desc {
            margin-top: 10px;
            padding-left: 51.765%;
            padding-right: 0;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.4px;
          }
        }

        .logo {
          height: 46px;
          padding-left: 51.765%;
        }
      }

      .content06 {
          padding-bottom: 120px;

        .cont__inner {
          height: auto;
          padding: 180px 2.92% 180px 11.03%;
        }

        .cont__title {
          font-size: 64px;
          line-height: 1.38;
          letter-spacing: -2px;
        }

        .cont__desc {
          padding-left: 37.11%;
          font-size: 22px;
          line-height: 1.91;
          letter-spacing: -0.6px;
        }

        .info {
          &__thumbnail-list {
            padding: 100px 30px 0;
          }
        }
      }

      .half__img {
        width: 400px;
        height: 400px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.digital-twin {
      .content02 {
        .cont__inner {
          padding: 0 70px;
        }
      }

      .content03 {
        .info {
          &__thumbnail-list {
            margin-top: 100px;
          }
        }
      }

      .content04 {
        margin-top: 100px;

        .alike-view {
          .cont__inner {
            padding-right: 11.72%;
          }

          .cont__desc {
            margin-top: 14px;
            padding-left: 48.674%;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        .logo {
          padding-left: 48.674%;
        }
      }

      .content06 {
        padding-bottom: 100px;

        .cont__inner {
          height: auto;
          padding: 120px 80px;
        }

        .cont__title {
          font-size: 42px;
          line-height: 1.42;
          letter-spacing: -1.8px;
        }

        .cont__desc {
          margin-top: 60px;
          padding-left: 32.897%;
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }
      }

      .half__img {
        top: 18px;
        width: 540px;
        height: 540px;
      }

      .half__info {
        padding-top: 77px;
      }


    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.digital-twin {
      .track-animation {
        &__highlight-area {
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 30px;
          text-align: left;
        }
      }

      .content02 {
        .cont__inner {
          padding: 0 30px;
        }

        .em {
          display: inline;
        }
      }

      .content03 {
        // TODO
        .cover {
          background-image: url('/img/mo/tech-digital-twin-thumb.jpg');
        }
      }

      .content04 {
        margin-top: 160px;

        .cover {
          background-image: url('../img/mo/tech-digital-twin-alike-bg01.png');
        }

        .alike-view {
          .cont__inner {
            justify-content: center;
            padding: 0 20px;
          }

          .cont__desc {
            padding-left: 0;
          }

          &:nth-child(3) {
            background-image: url('../img/mo/tech-digital-twin-alike-bg02.png');
          }
          &:nth-child(4) {
            background-image: url('../img/mo/tech-digital-twin-alike-bg03.png');
          }
          &:nth-child(5) {
            background-image: url('../img/mo/tech-digital-twin-alike-bg04.png');
          }
          &:nth-child(6) {
            background-image: url('../img/mo/tech-digital-twin-alike-bg05.png');
          }
        }

        .logo {
          padding-left: 0;
        }
      }

      .content05 {
        .cover {
          background-image: url('../img/mo/tech-digital-twin-mapping-bg.png');
        }

        .half__list {
          // TODO
          .half__box:first-child {
            background-image: url('/img/mo/tech-digital-twin-thumb.jpg');
          }
          // TODO
          &:nth-child(2) {
            .half__box:first-child {
              background-image: url('/img/mo/tech-digital-twin-thumb.jpg');
            }
          }
          // TODO
          &:nth-child(3) {
            .half__box:first-child {
              background-image: url('/img/mo/tech-digital-twin-thumb.jpg');
            }
          }
        }
      }

      .content06 {
        padding-bottom: 160px;
        .cont__inner {
          padding: 130px 20px 80px;
        }

        .cont__title {
          font-size: 36px;
          line-height: 1.39;
          letter-spacing: -1.8px;
          padding: 0;
        }

        .cont__desc {
          margin-top: 40px;
          padding-left: 0;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.5px;
        }

        .info {
          &__thumbnail-list {
            padding: 0 20px;
          }
        }
      }

      .half {
        &__box {
          &:first-child {
            height: 422px;
          }
        }

        &__img {
          width: 335px;
          height: 335px;
          margin-top: 40px;
        }

        &__info {
          padding-top: 40px;
        }
      }

      @at-root .tech.lang-en {
        .section__outlink {
          .txt {
            width: 90%;
          }
        }
      }

    }
  }
}
