body {
  opacity: 0;
}
html.theme-dark {
  background-color: #060505;
}

body,
input,
select,
textarea,
button,
a {
  font-weight: 400;
  font-family: 'NanumSquareNeo', sans-serif;
  color: $color-default;
  word-break: keep-all;
  word-wrap: break-word;
  -ms-word-break: keep-all;
  -ms-word-wrap: break-word;
}

a, button {
  color: var(--default);
}

input, textarea {
  background-color: var(--transparent);
  color: var(--color-sub02);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px var(--transparent) inset !important;
    -webkit-text-fill-color: var(--default) !important;
}

.blind,
caption,
caption span,
legend {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  text-indent: -9999px;
  white-space: nowrap;
}

.skip {
  height: 0;
}

.skip a {
  display: block;
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 25px;
  text-align: center;
}

.skip a:focus,
#skip a:active {
  position: absolute;
  top: 0;
  z-index: 100;
  padding: 10px 0;
  background: #000;
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.is-scroll-lock {
  overflow: hidden;
  height: 100%;
}

a[href^='tel'] {
  color: inherit;
  text-decoration: none;
}

img {
  width: 100%;
}

.br_tab,  // 1024~1599px 사용
.br_tab-sm, // 768px~1023px 사용
.br_mo {  // ~767px 사용
  display: none;
}

.container {
  padding-top: 70px;
  padding-bottom: 160px;
}

.section__title {
  font-weight: 800;
  font-size: 38px;
  line-height: 1.53;
  letter-spacing: -1.5px;
  color: var(--default);
}

.is-no-scroll {
  overflow: hidden;
}

/* ir-common */
.ir {
  main {
    // padding: 50px 0;

    .dark {
      padding: 15px;
      background: #494949;
    }

    .box {
      max-width: 1440px;
      margin: 0 auto;

      .inner {
        display: flex;
        padding: 45px 20px;

        &+.inner {
          border-top: 1px solid $color-default;
        }

        .title {
          width: 150px;
        }

        .cont {
          width: calc(100% - 150px);
        }
      }
    }
  }
}

.btn-wrap,
.select-wrap {
  display: flex;
}

.quick-link__button {
  overflow: hidden;
  width: 34px;
  height: 34px;
  text-indent: -9999px;
  background: var(--color-sub02) url(../img/common/ic-quicklink.png) no-repeat center;
  background-size: contain;
  border-radius: 50%;
  transition: background 0.3s;

  &:hover {
    background-color: $color-point;
  }
}

.dimmed {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-header + 1;
  content: '';
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.4);
}

.common {
  &__title {
    border-bottom: 1px solid var(--border01);

    @at-root .theme-dark & {
      border-bottom: 1px solid var(--border05);
    }

    &-inner {
      position: relative;
      max-width: 880px;
      margin: 0 auto;
      padding: 80px 0 139px;

      h2 {
        width: 100%;
        font-weight: 800;
        font-size: 64px;
        line-height: 1.38;
        letter-spacing: -2px;
        color: var(--default);
      }
    }
  }
}

.ir-common {
  &__sub-title {
    max-width: 880px;
    margin: 60px auto 0;
    font-weight: 800;
    font-size: 26px;
    color: #bbbbbe;
    line-height: 40px;
    letter-spacing: -0.8px;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    position: relative;
    height: 20px;
    padding-left: 26px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    transition: all 0.3s;

    &+& {
      margin-left: 10px;
    }

    &:focus {
      outline: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: background 0.3s;
    }

    &.download {
      &:before {
        width: 20px;
        height: 20px;
        background: var(--buttonDownlodBefore) url(../img/common/ic-download-white.png) no-repeat center;
        background-size: contain;
        border-radius: 50%;
      }

      &:hover {
        color: $color-point;

        &:before {
          background-color: $color-point;
        }
      }

      &.type2 {
        height: 34px;
        padding-left: 48px;
        font-weight: 400;
        font-size: 22px;
        color: white;

        &:before {
          width: 34px;
          height: 34px;
          background: $color-white url(../img/common/ic-download-black.png) no-repeat center;
          background-size: contain;
          border-radius: 50%;
        }

        &:hover {
          color: $color-point;

          &:before {
            background: $color-point url(../img/common/ic-download-white.png) no-repeat center;
            background-size: contain;
          }
        }
      }

      &.type3 {
        width: 270px;
        height: 52px;
        padding: 0 20px;
        border: 1px solid $color-gray4;
        color: var(--buttonType3);
        border-color: var(--buttonType3Border);

        &:before {
          left: auto;
          right: 20px;
          background: var(--ic-download) no-repeat center;
          background-size: contain;
          background-color: var(--buttonType3Background);
        }

        &:hover {
          border-color: $color-point;

          &:before {
            background: $color-point url(../img/common/ic-download-white.png) no-repeat center;
            background-size: contain;
          }
        }
      }

      &.webcast {
        &:before {
          background: $color-white url(../img/common/ic-webcast-black.png) no-repeat center;
          background-size: contain;
        }

        &:hover {
          &:before {
            background: $color-point url(../img/common/ic-webcast-white.png) no-repeat center;
            background-size: contain;
          }
        }
      }
    }

    &.delete {
      height: 24px;
      padding-left: 34px;
      color: var(--box-text);
      font-weight: 400;

      &:before {
        width: 24px;
        height: 24px;
        background: $color-default url(../img/common/ic-delete.png) no-repeat center;
        background-size: contain;
        border-radius: 50%;
      }

      &:hover {
        color: $color-point;

        &:before {
          background-color: $color-point;
        }
      }
    }

    &.outlink {
      &:before {
        width: 20px;
        height: 20px;
        background: var(--color-sub02) url(../img/common/ic-outlink-white.png) no-repeat center;
        background-size: contain;
        border-radius: 50%;
      }

      &:hover {
        color: $color-point;

        &:before {
          background-color: $color-point;
        }
      }

      &.type2 {
        &:before {
          background: var(--graphBar) var(--ic-outlink03) no-repeat center;
          background-size: contain;
        }

        &:hover {
          &:before {
            background: $color-point url(../img/common/ic-outlink-white.png) no-repeat center;
            background-size: contain;
          }
        }
      }
    }

    &.see-all {
      position: relative;
      width: 200px;
      height: 48px;
      padding: 0 16px;
      color: $color-white;
      background-color: var(--neswBtn);

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 16px;
        width: 24px;
        height: 24px;
        background: url(../img/common/ic-arrow.png) no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
      }

      &:hover {
        background: $color-point;
      }
    }

    &.apply {
      width: 200px;
      height: 48px;
      padding: 0;
      border: 1px solid var(--default);
      background: var(--default);
      font-size: 14px;
      color: var(--reverse);
      justify-content: center;

      &:hover {
        background: $color-point;
        border-color: $color-point;
        color: $color-white;
      }

      &.type2 {
        background: var(--transparent);
        color: var(--default);

        &:hover {
          color: $color-point;
        }
      }
    }

    &.list {
      width: 130px;
      height: 52px;
      padding: 0 18px;
      border: 1px solid var(--border01);
      font-size: 14px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 8px;
        width: 32px;
        height: 32px;
        background: var(--ic-list) no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
        transition: all 0.3s;
      }

      &:hover {
        color: $color-point;

        &:after {
          background-image: url(/img/common/ic-list-hover.png);
        }
      }

      @at-root .navercorp.lang-en {
        .ir-common__button.list {
          width: 160px;
          padding-right: 50px;
        }
      }
    }

    &.tag {
      position: relative;
      height: 36px;
      padding: 5px 14px;
      background: var(--storyCategory);
      color: var(--default);
      letter-spacing: -0.3px;

      &+.ir-common__button {
        margin-left: 8px;
      }

      &.type2 {
        background: #1d1d1f;
        height: 24px;
        padding: 1px 8.5px;
        font-weight: 500;
        font-size: 12px;
        color: #fff;

        &:hover {
          background: $color-point;
          color: #fff;
        }
      }

      &:hover {
        color: $color-point;
      }

      &.is-active {
        background: var(--numBg);
        color: #fff;

        &:hover {
          background: $color-point;
          color: #fff;
        }
      }
    }
  }

  &__tab {
    display: flex;
    justify-content: space-between;
    width: 440px;
    border-bottom: 1px solid $color-gray4;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      position: relative;
      display: inline-block;
      padding-bottom: 4px;
      font-weight: 700;
      font-size: 22px;
      color: #bbbbbe;
      line-height: 42px;
      transition: color 0.3s;

      &:hover,
      &.#{$activeClass} {
        color: $color-default;
      }

      &.#{$activeClass} {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background: $color-black;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;

    &-arrow {
      width: 32px;
      height: 32px;
      background: url(../img/common/ic-pagination-arrow.png) no-repeat center;
      background: $color-bg-blue url(../img/common/ic-pagination-arrow.png) no-repeat center;
      background-size: contain;
      border-radius: 50%;
      font-size: 0;
      text-indent: -9999px;
      transition: background 0.3s;

      &.right {
        transform: rotate(180deg);
      }

      &:hover {
        background-color: #f1f1f4;
      }
    }

    &-list {
      margin: 0 60px;
    }

    &-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      font-weight: 800;

      &:hover,
      &.#{$activeClass} {
        background: $color-bg-blue;
        border-radius: 50%;
        color: #a1a1a6;

        &.style-none {
          background: transparent;
          color: $color-default;
        }
      }
    }
  }

  &__input {

    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;

    &.type-text {
      width: 100%;
      height: 48px;
      padding: 11px 16px;
      line-height: 48px;
      border: 1px solid var(--border01);
      color: var(--box-text);

      &:hover {
        box-shadow: none;
      }

      &:focus {
        outline: none;
        border-color: var(--default);
      }

      &.is-able {
        border-color: $color-gray4;
      }

      &.is-failed {
        border: 2px solid #ff3300;
      }
    }

    &.type-radio {
      position: relative;
      width: 24px;
      height: 24px;
      cursor: pointer;
      border: none;

      &::before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: var(--selectBox);
        border: 2px solid var(--radioBorder);
        border-radius: 50%;
        box-sizing: border-box;
      }

      &:hover {
        &::before {
          border-color: #a1a1a6;
        }
      }

      &:checked {
        &::before {
          background: var(--tableTopBorder);
          border-color: var(--tableTopBorder);
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background: $color-white;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
        }
      }

      &.is-failed {
        &::before {
          border-color: #ff3300;
        }
      }

      &+span {
        font-size: 14px;
        line-height: 24px;
        margin-left: 10px;
        color: var(--color-sub);
      }
    }

    &-label {
      display: inline-flex;
      min-width: 60px;
      margin-right: 30px;
      align-items: center;
      line-height: 24px;
     
    }
    
    &-label:last-child {
      margin-right: 0;
    }
  }

  &__radio {
    font-size: 0;

    &-title {
      display: block;
      margin-bottom: 19px;
      font-size: 14px;
      font-weight: 700;
    }
  }

  &__checkbox {
    position: relative;
    padding-left: 36px;
    font-size: 0;

    input {
      position: absolute;
      top: 3px;
      left: 0;
      width: 24px;
      height: 24px;
      border: 2px solid var(--border01);
      vertical-align: middle;
      cursor: pointer;

      &:hover {
        border-color: #a1a1a6;
      }

      &:checked {
        background-color: var(--default);
        background-image: var(--checkbox);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: none;
      }

      &:focus-visible {
        outline: 2px solid $color-point;
      }
    }

    label {
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.5px;
      vertical-align: middle;
      pointer-events: none;
      color: var(--default);
    }
  }

  &__enquiry {
    &-title {
      margin-bottom: 10px;
      display: block;
      font-weight: 700;
    }

    .ir-common__input {
      width: 100%;
      height: 120px;
      line-height: normal;
      resize: none;

      &.type-text {
        font-size: 14px;
        line-height: 26px;
        font-weight: 700;
      }

      &:hover {
        box-shadow: none;
        border-color: #a1a1a6;
      }

      &.is-failed {
        border: 2px solid #f30;
      }
    }
  }
}

/* input-search */
.input-search__box {
  position: relative;

  .ir-common__input.type-text {
    padding-right: 60px;
  }

  .btn-delete {
    display: none;
    position: absolute;
    top: 50%;
    right: 42px;
    margin-right: 20px;
    transform: translateY(-50%);
    @include sp-desktop('ic-search-delete-sm');
    @at-root .theme-dark & {
      @include sp-mobile('ic-search-delete-sm-g');
    }
  }

  .btn-search {
    position: absolute;
    top: 50%;
    right: 18px;
    display: inline-block;
    transform: translateY(-50%);
    @include sp-desktop('ic-search');
    @at-root .theme-dark & {
      @include sp-desktop('ic-search-w');
      @include sp-mobile('ic-search-w');
    }
  }

  &.#{$activeClass} {
    .ir-common__input.type-text {
      padding-right: 96px;
    }

    .btn-delete {
      display: block;
    }
  }
}

.input__file {
  display: flex;
  position: relative;
  align-items: center;
  border-color: var(--color-button02);

  .ir-common__input {
    padding-right: 50px;
    pointer-events: none;
  }

  &-box {
    display: flex;
    position: relative;
    width: 100%;

    .ir-common__input {
      border-right: 0;
    }
  }

  &-delete {
    position: absolute;
    top: 50%;
    right: 14px;
    z-index: 1;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #bbbbbe;
    transform: translateY(-50%);
    transition: all .3s;
    background-color: var(--deleteBtn);

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      background: url(../img/common/ic-delete.png) no-repeat center;
      background-size: contain;
      transform: translate(-50%, -50%);
      content: '';
    }

    &:hover {
      background-color: $color-default;
    }
  }

  &-input {
    position: absolute;
    top: 0;
    z-index: 1;
    left: 0;
    right: 100px;
    height: calc(100% - 2px);
    margin: 1px 0 0 1px;
    padding: 15px 0 14px 14px;
    overflow: hidden;
    font-size: 14px;
    color: transparent;
    -webkit-text-fill-color: transparent;
    background-color: transparent;
    opacity: 0;

    &::file-selector-button {
      display: none;
    }
  }

  label {
    flex-shrink: 0;
    width: 100px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: 1px solid var(--border01);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.3px;
    box-sizing: border-box;
    color: var(--default);
    cursor: pointer;

    &:hover {
      border-color: #a1a1a6;
    }

    &:active {
      border-color: $color-default;
    }
  }
}

.calendar {
  border-top: 1px solid var(--border04);

  &-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px 0 60px;

    &__month {
      padding: 0 73px;
      font-size: 26px;
      font-weight: 800;
      line-height: 38px;
      color: var(--default);
    }

    &__btn {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;

      &:hover {
        &::before {
          background: url(../img/common/ic-pagination-arrow2-hover.png) no-repeat center;
          background-size: contain;
        }
      }

      &::before {
        display: inline-block;
        width: 100%;
        height: 100%;
        background: var(--ic-pagination03) no-repeat center;
        background-size: contain;
        content: '';
      }

      &-next {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-table {
    table-layout: fixed;
    width: 100%;
    color: $color-gray;
    font-size: 14px;
    line-height: 30px;
    text-align: left;

    &__wrap {
      border-bottom: 1px solid var(--border01);
    }

    colgroup col {
      &:first-child {
        width: 168px;
      }
    }

    thead th {
      padding: 20px 0 20px 33px;
      font-weight: 900;
      color: var(--listSub);

      &:first-child {
        padding-left: 6px;
      }
    }

    tbody {
      tr {
        border-top: 1px solid var(--inputBox);
      }

      td {
        height: 183px;

        &:first-child {
          .cell {
            padding-left: 6px;
          }
        }

        &.is-disabled {
          pointer-events: none;

          .state {
            color: var(--halfNum);
          }
        }

        &.is-past {
          pointer-events: none;

          .day,
          .state {
            color: var(--halfNum);
          }
        }

        &:hover {
          .day {
            position: relative;
            z-index: 1;
            &::after {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: -1;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              background-color: #F1F1F4;
              transform: translate(-50%, -50%);
              content: '';
            }
          }
        }

        &.is-active {
          .day {
            position: relative;
            z-index: 1;
            color: $color-white;

            &::after {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: -1;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              background-color: $color-point;
              transform: translate(-50%, -50%);
              content: '';
            }
          }

          .state {
            color: $color-point;
          }
        }
      }
    }

    .cell {
      display: block;
      height: 100%;
      padding: 16px 0 16px 33px;
    }

    .day {
      color: var(--scheduleColor);
      font-weight: 800;
    }

    .holiday {
      color: $color-point;
    }

    .state {
      margin-top: 40px;
      color: var(--listSub);
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.5px;
    }
  }

  &-guide {
    display: none;
  }
}

.table {
  width: 100%;
  margin-top: 70px;
  border-top: 1px solid var(--border04);
  border-bottom: 1px solid var(--border01);
  font-size: 14px;
  color: $color-gray3;
  line-height: 2.14;
  letter-spacing: -0.3px;
  text-align: left;

  .table-blind {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    font-size: 0;
    text-indent: -9999px;
    white-space: nowrap;
  }

  &.is-caption {
    margin-top: 0;
  }

  &.is-dropdown {
    margin-top: 30px;
  }

  &__caption {
    margin-top: 42px;
    margin-bottom: 12px;
    font-size: 14px;
    text-align: right;
    letter-spacing: -0.3px;
  }

  &__thead {
    display: flex;

    &-th {
      flex: 1;
      padding: 20px 0;
      font-weight: 800;
      color: var(--default);
    }
  }

  &__tbody {
    &-th {
      padding: 20px 0;
      font-weight: 700;
      color: var(--default);
    }

    div {
      flex: 1;
      padding: 20px 0;
      color: var(--box-text);

      &.#{$bdClass} {
        font-weight: 700;
        color: var(--default);
      }

      &.#{$ebClass} {
        font-weight: 800;
        color: var(--default);
      }
    }
  }

  &__tr {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--border01);
  }

  &__info {
    margin-top: 30px;
    font-size: 12px;
    color: var(--secDesc);
    text-align: right;
    letter-spacing: -0.26px;
  }
}

/* ir-table */
.ir-table {
  width: 100%;
  table-layout: fixed;
  font-size: 14px;
  line-height: 30px;
  text-align: left;

  th,
  td {
    padding: 20px 0;

    &:first-child {
      padding: 20px 20px 20px 0;
    }
  }

  td {
    &:first-child {
      @at-root .theme-dark & {
        color: #a1a1a6;
      }
    }

    &.title {
      @at-root .theme-dark & {
        color: #a1a1a6;
      }
    }
  }

  thead {
    tr {
      border-top: 1px solid var(--border04);
    }

    th {
      font-weight: 800;
      letter-spacing: -0.3px;
      color: var(--default);
    }
  }

  tbody tr {
    border-top: 1px solid var(--border01);

    &:last-child {
      border-bottom: 1px solid var(--border01);
    }
  }

  .date {
    color: #a1a1a6;

    @at-root .theme-dark & {
      color: #6e6e73;
    }
  }

  .ir-common__button {
    font-weight: 700;
    text-transform: none;

    &.download {
      @at-root .navercorp.lang-en & {
        margin-left: 30px;
      }
    }
  }

  .empty {
    td {
      letter-spacing: -0.3px;
    }
  }
}


.section__title {
  &.#{$activeClass} {
    .text-highlight {
      &::after {
        width: 101%;
        height: 109.62%;
      }
    }
  }
}
.text-highlight {
  position: relative;
  color: $color-default;
  z-index: 0;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 0;
    height: 100%;
    background-color: #49edc2;
    transition: width .4s;
    content: '';
  }
}

/* component */
.#{$component-name} {
  .card__sub {
    overflow: hidden;

    .sub {
      &__list {
        margin: -10px 0 0 -10px;
        font-size: 0;
      }

      &__item {
        display: inline-block;
        width: calc((100% - 30px) / 3);
        margin: 10px 0 0 10px;
      }

      &__img {
        overflow: hidden;
        width: 100%;
        padding-top: 66.5%;
        background: no-repeat 50%;
        background-image: url('/img/common/thumnail.png');
        background-size: cover;
      }

      &__link {
        display: inline-block;
        width: 100%;
        height: 100%;

        &:hover {

          .category,
          .news-tit {
            color: $color-point;
          }
        }
      }

      &__txt {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 298px;
        padding: 22px 24px 24px 24px;
        background-color: var(--bgColor02);

        .category {
          font-weight: 800;
          font-size: 16px;
          line-height: 1.88;
          transition: all 0.3s;
        }

        .news-tit {
          margin-top: 20px;
          font-weight: 700;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
          @include multi-ellipsis(3);
          transition: all 0.3s;
        }

        .news-date {
          margin-top: 40px;
          font-weight: 700;
          font-size: 14px;
          line-height: 2.14;
          color: var(--newsDate);
        }
      }
    }

    &.is-list-view {
      .sub {
        &__list {
          margin: 0;
        }

        &__item {
          display: block;
          width: auto;
          margin: 0;
          border-bottom: 1px solid var(--inputBox);

          &:first-child {
            border-top: 1px solid var(--inputBox);
          }
        }

        &__link {
          display: flex;
          align-items: start;
          padding: 20px 0;
        }

        &__title {
          padding: 30px 0 23px;

          .title {
            font-size: 22px;
            font-weight: 700;
            line-height: 1.55;
            letter-spacing: -0.6px;
          }
        }

        &__img {
          flex-shrink: 0;
          overflow: hidden;
          width: 13.334%;
          padding-top: 8.889%;
          background: no-repeat 50%;
          background-image: url('/img/common/thumnail.png');
          background-size: cover;
        }

        &__txt {
          flex-direction: row;
          width: 100%;
          min-height: 0;
          padding: 0 0 0 101px;
          background: none;

          .tit-wrap {
            display: flex;
          }

          .category {
            width: 228px;
            margin-top: -2px;
            padding-right: 20px;
            font-weight: 700;
            font-size: 14px;
            line-height: 2;
            transition: all 0.3s;
            @at-root .theme-dark & {
              color: var(--secDesc);
            }
          }

          .news-date {
            margin-top: 4px;
            font-weight: 700;
            font-size: 14px;
            line-height: 2;
            transition: all 0.3s;
          }

          .news-tit {
            max-width: 521px;
            margin-top: 2px;
            font-weight: 800;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
            transition: all 0.3s;
            @include multi-ellipsis(2);
          }
        }
      }
    }

    &.is-scroll-view {
      max-width: 1800px;
      margin: 0 auto;

      h4 {
        font-weight: 700;
        font-size: 26px;
        letter-spacing: -0.8px;
        color: var(--default);
      }

      .sub {
        &__list {
          margin: 56px 0 0 0;
        }

        &__item {
          width: calc((100% - 60px)/4);
          margin: 0 20px 0 0;

          &:last-child {
            margin-right: 0;
          }
        }

        &__txt {
          min-height: 212px;
          padding: 20px 0 0 0;
          background-color: unset;

          .news-tit {
            margin-top: 10px;
            font-size: 22px;
            line-height: 1.55;
          }
        }
      }
    }
  }

  .banner-slide {
    .slide {
      &__link {
        position: relative;
        &.eng {
          display: none;
        }
        @at-root .navercorp.media-event.lang-en {
          .slide__link.eng {
            display: block;
          }
          .slide__link.kor {
            display: none;
          }
        }

        &::before {
          content: '';
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .1);
        }
      }

      &__txt-area {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 1400px;
      }

      &__title {
        font-weight: 900;
        font-size: 90px;
        line-height: 1.13;
        letter-spacing: -0.01px;
        color: $color-white;
      }

      &__sub-title,
      &__date {
        font-weight: 800;
        font-size: 22px;
        line-height: 1.55;
        letter-spacing: -0.01px;
        color: $color-white;
      }

      &__sub-title {
        margin-top: 26px;
      }

      &__date {
        margin-top: 12px;
      }
    }

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: var(--background01);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      width: 100%;
    }

    .swiper-pagination {
      display: flex;
      bottom: 40px;
      padding: 0 60px;

      &-bullet {
        width: 100%;
        height: 5px;
        text-indent: -9999px;
        border-radius: 0;
        opacity: 0.6;
        background-color: $color-white;

        &-active {
          opacity: 1;
        }
      }
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0;
      margin-left: 20px;
      transition: background-color 0.3s;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        background-color: #cececf;
      }
    }
    .swiper-pagination-bullets .swiper-pagination-bullet-active{
      opacity: .7;
    }
  }

  .card-contents {
    &:not(.swiper-wrapper) {
      display: flex;
      flex-wrap: wrap;
    }

    margin: 40px -10px 0;

    &__item {
      width: 33.333%;
      height: 100%;
      margin-top: 60px;

      &:nth-of-type(-n+3) {
        margin-top: 0;
      }

      &-inner {
        display: block;
        width: calc(100% - 20px);
        height: 100%;
        margin: 0 auto;
      }
    }

    &__img {
      overflow: hidden;
      position: relative;

      .img {
        padding-top: 62.534%;
        background: no-repeat center;
        background-size: cover;
      }
    }

    &__txt {
      min-height: 135px;

      .date {
        display: block;
        margin-top: 12px;
        font-size: 12px;
        line-height: 2.16;
        color: var(--color-sub03);

        & + .title {
          margin-top: 4px !important;
        }
      }

      .category {
        display: inline-block;
        margin-top: 16px;
        padding: 1px 8px;
        background-color: var(--neswBtn);
        color: $color-white;
        font-size: 12px;
        font-weight: 800;
        line-height: 22px;
      }

      .title {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 10px;
        color: var(--default);
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
      }

      .year {
        display: inline-block;
        margin-top: 16px;
        color: var(--yearColor);
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  .procedure-info {
    &__list {
      display: flex;
      flex-wrap: wrap;
      font-size: 0;
    }

    &__item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      margin-left: 40px;
      padding: 16px 30px 16px 20px;
      font-weight: 800;
      background-color: var(--bgColor02);

      &::after {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: -30px;
        width: 20px;
        height: 20px;
        background: url(../img/common/ic-arrow2.png) no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
        content: '';
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      .num {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--numBg);
        font-size: 15px;
        color: $color-white;
      }

      .txt {
        margin-left: 12px;
        font-size: 18px;
        line-height: 34px;
        letter-spacing: -.5px;
        color: var(--default);
        
        @at-root .navercorp.lang-en {
          .procedure-info__item .txt {
            font-size: 16px;
            width: auto;
          }
        }
      }

      &.is-active {
        .num {
          background: url(../img/common/ic-check-orange.png) no-repeat center;
          background-size: contain;
          font-size: 0;
        }
      }
    }
  }
}

@include breakpoint(desktop) {
  .br_desktop-n { // 1920px 사용x
    display: none 
  }
}

@include breakpoint(pc) {
  .br_desktop {
    display: none;
  }

  .quick-link__button {
    width: 30px;
    height: 30px;
  }

  .common {
    &__title {
      &-inner {
        max-width: 750px;
      }
    }
  }

  .ir-common {
    &__button {
      &.download {
        &.type2 {
          height: 30px;
          padding-left: 42px;
          font-size: 14px;

          &:before {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    &__tab {
      width: 374px;
    }
  }

  .calendar {
    &-table {
      thead th {
        padding: 20px 0;
      }

      tbody {
        td {
          height: 182px;
        }
      }

      .cell {
        padding: 16px 0;
      }
    }
  }

  .text-highlight {
    &::after {
      bottom: -5px;
    }
  }

  /* component */
  .#{$component-name} {
    .card__sub {
      .sub__list {
        margin: -6px 0 0 -6px;
      }

      .sub__item {
        width: calc((100% - 18px) / 3);
        margin: 6px 0 0 6px;
      }

      .sub__txt {
        min-height: 249px;
        padding: 16px 20px 20px;

        .category {
          font-size: 14px;
          line-height: 1.86;
        }

        .news-tit {
          margin-top: 12px;
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.6px;
        }

        .news-date {
          margin-top: 24px;
          font-size: 12px;
          line-height: 1.83;
        }
      }

      &.is-list-view {
        .sub {
          &__img {
            width: 15.79%;
            padding-top: 10.527%;
          }

          &__txt {
            padding-left: 48px;

            .category {
              width: 193px;
            }
          }
        }
      }

      &.is-scroll-view {
        max-width: 1520px;

        .sub {
          &__list {
            margin-top: 43px;
          }

          &__txt {
            min-height: 180px;

            .category {
              font-size: 14px;
            }

            .news-tit {
              margin-top: 12px;
              font-size: 18px;
              line-height: 1.56;
            }
          }
        }
      }
    }

    .banner-slide {
      .slide {
        &__txt-area {
          max-width: 1200px;
        }

        &__title {
          font-size: 90px;
          line-height: 1.13;
        }

        &__sub-title,
        &__date {
          font-size: 22px;
          line-height: 1.55;
        }

        &__sub-title {
          margin-top: 30px;
        }
      }

      .swiper-pagination-bullet {
        height: 4px;
      }

      .swiper-pagination {
        bottom: 30px;
        padding: 0 40px;
      }
    }

    .card-contents {
      &__txt {
        min-height: 107px;
      }
    }
  }
}

@include breakpoint(tablet) {
  
  .br_pc {
    display: none;
  }

  .br_tab {
    display: block;
  }

  .quick-link__button {
    width: 24px;
    height: 24px;
  }

  .container {
    padding-bottom: 140px;
  }

  .section__title {
    font-size: 32px;
    line-height: 1.5;
    letter-spacing: -1px;
  }

  .common {
    &__title {
      &-inner {
        max-width: none;
        padding: 70px 30px 120px;
        white-space: normal;

        h2 {
          font-size: 48px;
          line-height: 1.42;
          letter-spacing: -1.5px;
        }
      }
    }
  }

  .ir-common {
    &__button {
      transition: all 0.3s;
      &.download {
        &.type2 {
          height: 24px;
          padding-left: 32px;
          font-size: 12px;

          &:before {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &__tab {
      width: 317px;

      &-item {
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  /* input-search */
  .input-search__box {
    .ir-common__input.type-text {
      padding-right: 56px;
    }

    .btn-delete {
      margin-right: 12px;
    }

    .btn-search {
      @include sp-mobile('ic-search-mo');
      @at-root .theme-dark .category-filter .input__box .btn-search {
        @include sp-mobile('ic-search-small-w');
      }
    }

    &.#{$activeClass} {
      .ir-common__input.type-text {
        padding-right: 78px;
      }
    }
  }

  .calendar {
    &-table {
      colgroup col {

        &:first-child,
        &:last-child {
          width: 142px;
        }
      }
    }
  }

  /* component */
  .#{$component-name} {
    .card__sub {
      .sub__txt {
        min-height: 212px;
        padding: 16px 20px;

        .category {
          font-size: 12px;
          line-height: 2.2;
        }

        .news-tit {
          margin-top: 10px;
          font-size: 18px;
          line-height: 1.75;
          letter-spacing: -0.4px;
        }

        .news-date {
          margin-top: 20px;
          font-size: 12px;
          line-height: 2.2;
        }
      }

      &.is-list-view {
        .sub {
          &__link {
            padding: 16px 0;
          }

          &__img {
            width: 20.747%;
            padding-top: 13.797%;
          }

          &__title {
            padding: 25px 0 20px;

            .title {
              font-size: 16px;
              line-height: 1.75;
              letter-spacing: -0.5px;
            }
          }

          &__txt {
            padding-left: 46px;

            .category {
              width: 124px;
              margin-top: 2px;
              font-size: 12px;
            }

            .news-tit {
              max-width: 472px;
              font-size: 18px;
              line-height: 1.67;
            }

            .news-date {
              margin-top: 2px;
              font-size: 12px;
            }
          }
        }
      }

      &.is-scroll-view {
        max-width: none;
        padding: 0;

        h4 {
          padding-left: 30px;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.01px;
        }

        .scroll-wrap {
          overflow-y: auto;

          &::-webkit-scrollbar {
            opacity: 0;
          }
        }

        .sub {
          &__list {
            width: max-content;
            margin: 24px auto 0;
            padding: 0 30px;
          }

          &__item {
            width: 315px;
            margin-right: 12px;

            .news-tit {
              font-size: 16px;
              line-height: 1.63;
              letter-spacing: -0.5px;
            }

            .news-date {
              font-size: 12px;
            }
          }
        }
      }
    }

    .banner-slide {
      .slide {
        &__txt-area {
          max-width: 720px;
        }

        &__title {
          font-size: 56px;
          line-height: 1.14;
        }

        &__sub-title,
        &__date {
          font-size: 16px;
          line-height: 1.63;
        }

        &__sub-title {
          margin-top: 24px;
        }

        &__date {
          margin-top: 8px;
        }
      }

      .swiper-pagination {
        bottom: 24px;
        padding: 0 30px;
      }
    }

    .card-contents {
      &__txt {
        .title {
          font-size: 16px;
          letter-spacing: -.5px;
        }

        .year {
          margin-top: 14px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .br_tab {
    display: none;
  }

  .br_tab-sm {
    display: block;
  }

  .container {
    padding-top: 64px;
    padding-bottom: 120px;
  }

  .section__title {
    font-size: 26px;
    line-height: 1.46;
    letter-spacing: -0.8px;
  }

  .common {
    &__title {
      &-inner {
        padding-top: 76px;

        h2 {
          font-size: 38px;
          line-height: 1.52;
          letter-spacing: -1.8px;
        }
      }
    }
  }

  .ir-common {
    &__button {
      transition: none;
      &.see-all {
        width: 160px;
        height: 40px;
        padding: 0 20px;
        font-size: 12px;

        &:after {
          width: 16px;
          height: 16px;
          right: 12px;
        }
      }
    }

    &__select {
      width: 168px;
    }

    &__tab {
      width: 351px;
    }

    &__input-label {
      min-width: 50px;
    }
  }

  .input__file {
    &-delete {
      width: 16px;
      height: 16px;
    }

    &-input {
      right: 86px;
    }

    label {
      width: 86px;
      font-size: 12px;
      letter-spacing: normal;
    }
  }

  .calendar {
    &-nav {
      padding: 15px 0;
      border-bottom: 1px solid $color-gray4;
      
      @at-root .theme-dark & {
        border-bottom: #2F2E30;
      }

      &__month {
        padding: 0 72px;
        font-size: 20px;
        line-height: 42px;
      }
    }

    &-table {
      line-height: 24px;
      text-align: center;

      &__wrap {
        padding: 0 7.5px;
      }

      colgroup col {

        &:first-child,
        &:last-child {
          width: auto;
        }
      }

      thead th {
        font-size: 12px;
        font-weight: 800;
        line-height: 1;
        padding: 22px 0;

        &:first-child {
          padding-left: 0;
        }
      }

      tbody {
        tr {
          border-top: none;
        }

        td {
          height: 64px;

          &:first-child {
            .cell {
              padding-left: 0;
            }
          }

          &.is-disabled {
            .day {
              color: #dddee1;
            }
          }
        }
      }

      .cell {
        padding: 11px 0;
      }

      .state {
        display: none;
      }
    }

    &-guide {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 12px;

      span {
        margin-left: 20px;
        font-size: 12px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.3px;

        @at-root .theme-dark & {
          color: var(--searchTxt);
        }

        &:first-child {
          margin-left: 0;

          &::before {
            background-color: $color-gray3;
          }
        }

        &::before {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 5px;
          border-radius: 50%;
          background-color: $color-gray4;
          vertical-align: middle;
          content: '';
        }
      }
    }
  }

  /* ir-table */

  .ir-table {
    line-height: 26px;

    colgroup,
    thead {
      display: none;
      tr{
        border-top: 1px solid var(--border04);
      }
    }

    tbody tr {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      min-height: 112px;

      &:first-child {
        border-top-color: $color-default;
      }
    }

    td {
      padding: 30px 0;

      &:first-child {
        padding: 10px 0;
        font-weight: 700;

        @at-root .theme-dark & {
          color: #fff;
        }
      }

      &:nth-child(3) {
        padding-right: 26px;
      }
    }

    .tit {
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
    }

    .date {
      width: 100%;
      padding: 0;
      font-size: 12px;
      font-weight: 700;
      line-height: 22px;
    }

    .empty {
      align-content: center;

      td {
        padding: 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -0.5px;
      }
    }
  }

  /* component */
  .#{$component-name} {
    .card__sub {
      .sub__item {
        width: calc((100% - 12px) / 2);
      }

      .sub__txt {
        min-height: 223px;
        padding: 16px 20px 20px;

        .category {
          font-size: 12px;
          line-height: 1.83;
        }

        .news-tit {
          margin-top: 14px;
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }

        .news-date {
          margin-top: 28px;
          font-size: 12px;
          line-height: normal;
        }
      }

      &.is-list-view {
        .sub {
          &__link {
            padding: 14px 0;
          }

          &__img {
            width: 18.363%;
            padding-top: 12.29%;
          }

          &__title {
            padding-bottom: 16px;

            .title {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }

          &__txt {
            padding-left: 46px;

            .category {
              width: 100px;
              margin-top: 1px;
              font-size: 10px;
            }

            .news-tit {
              max-width: 342px;
              margin-top: 0;
              font-size: 14px;
              line-height: 1.86;
            }

            .news-date {
              margin-top: 2px;
              font-size: 10px;
            }
          }
        }
      }

      &.is-scroll-view {
        .sub {
          &__item {
            &:nth-child(3) {
              display: inline-block;
            }
          }
        }
      }
    }

    .banner-slide {
      .slide {
        &__txt-area {
          max-width: 540px;
        }

        &__title {
          font-size: 42px;
          line-height: 1.19;
        }

        &__sub-title,
        &__date {
          font-size: 12px;
          line-height: 1.67;
        }

        &__sub-title {
          margin-top: 12px;
        }

        &__date {
          margin-top: 8px;
        }
      }

      .swiper-pagination {
        bottom: 20px;
        padding: 0 20px;
      }

      .swiper-pagination-bullets .swiper-pagination-bullet {
        height: 3px;
        margin-left: 12px;
      }
    }

    .card-contents {
      margin: 30px -6px 0;

      &__item {
        width: 50%;
        margin-top: 50px;

        &:nth-of-type(3) {
          margin-top: 50px;
        }

        &-inner {
          width: calc(100% - 12px);
        }
      }

      &__txt {
        min-height: 134px;

        .year {
          margin-top: 16px;
        }
      }
    }

    .procedure-info {
      &__item {
        padding: 11px 24px 11px 16px;

        .num {
          width: 24px;
          height: 24px;
          font-size: 12px;
        }

        .txt {
          margin-left: 10px;
          font-size: 16px;
          letter-spacing: -.44px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .br_tab-sm {
    display: none;
  }

  .br_mo {
    display: block;
  }

  .container {
    padding-bottom: 40px;
  }

  .ir {
    main {
      .box {
        .inner {
          display: block;

          .title {
            margin-bottom: 30px;
          }

          .cont {
            width: 100%;
          }
        }
      }
    }
  }

  .btn-wrap {
    flex-direction: column;

    .ir-common {
      &__button {
        transition: none;
        width: 100%;

        &.tag {
          width: auto;
        }
      }
    }
  }

  .common {
    &__title {
      border-bottom: none;
      
      &-inner {
        padding: 48px 20px 92px;

        h2 {
          font-size: 38px;
          line-height: 1.53;
          letter-spacing: -1.5px;
        }
      }
    }
  }

  .ir-common {
    &__button {
      &+& {
        margin-left: 0;
        margin-top: 10px;
      }

      &.download {
        &.type2 {
          height: 20px;
          padding-left: 28px;

          &:before {
            width: 20px;
            height: 20px;
          }
        }

        &.type3 {
          width: 100%;
        }
      }
    }

    &__select {
      width: 100%;

      &.wide {
        width: 100%;
      }
    }

    &__tab {
      overflow: hidden;
      overflow-x: scroll;
      justify-content: flex-start;
      width: 100%;
      font-size: 14px;
      white-space: nowrap;

      &-item {
        padding-bottom: 8px;

        &+.ir-common__tab-item {
          margin-left: 28px;
        }

        &.#{$activeClass}::before {
          height: 1px;
        }
      }
    }

    &__pagination {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 335px;

      &-list {
        margin: 0;
      }
    }

    &__input {
      &.type-text {
        width: 100%;
        max-width: none;
      }
    }
  }

  .ir-table{
    thead {
      tr{
        border-top: 1px solid var(--border04);
      }
    }

    .ir-common__button {
      &.download {
        display: none;
      }
    }
  }

  .calendar {
    &-nav {
      &__month {
        padding: 0 87px;
      }
    }

    &-table {
      &__wrap {
        margin: 0 -8px;
        padding: 0;
      }
    }
  }

  /* component */
  .#{$component-name} {
    .card__sub {
      .sub__list {
        flex-direction: column;
        margin: -6px 0 0 0;
      }

      .sub__item {
        width: 100%;
        margin: 6px 0 0 0;
      }

      .sub__item:nth-child(3) {
        display: block;
      }

      .sub__txt {
        padding: 14px 20px 20px;

        .news-tit {
          margin-top: 12px;
        }

        .news-date {
          margin-top: 24px;
          font-size: 10px;
          line-height: 2.2;
        }
      }

      &.is-list-view {
        .sub {
          &__link {
            padding: 25px 0;
          }

          &__img {
            width: 30.75%;
            padding-top: 20.6%;
            min-width: 108px;
            min-height: 72px
          }

          &__title {
            padding-top: 18px;
          }

          &__txt {
            display: block;
            margin-top: -2px;
            padding-left: 20px;

            .tit-wrap {
              display: block;
            }

            .category {
              display: none;
              width: auto;
              margin-top: 0;
              font-size: 10px;
            }

            .news-tit {
              @include multi-ellipsis(3);
              @at-root .navercorp.lang-en  {
                .card__sub.is-list-view .sub__txt .news-tit {
                  letter-spacing: 0;
                }
              }
            }

            .news-date {
              display: block;
              margin-top: 7px;
            }
          }
        }
      }

      &.is-scroll-view {
        h4 {
          padding-left: 20px;
        }

        .sub {
          &__list {
            flex-direction: unset;
            padding: 0 20px;
          }
        }
      }
    }

    .banner-slide {
      .slide {
        &__txt-area {
          max-width: 287px;
        }

        &__title {
          font-size: 36px;
          line-height: 1.28;
        }

        &__sub-title,
        &__date {
          font-size: 14px;
          line-height: 1.71;
        }

        &__sub-title {
          margin-top: 16px;
        }
      }

      .swiper-pagination {
        bottom: 30px;
        padding: 0 14px;
      }

      .swiper-pagination-bullets .swiper-pagination-bullet {
        margin-left: 8px;
      }
    }

    .card-contents {
      margin: 30px 0 0;

      &__item {
        width: 100%;

        &:nth-of-type(2) {
          margin-top: 50px;
        }

        &-inner {
          width: 100%;
        }
      }

      &__txt {
        min-height: 107px;
      }
    }

    .procedure-info {
      &__list {
        flex-direction: column;
      }

      &__item {
        margin-top: 30px;
        margin-left: 0;

        &::after {
          top: auto;
          right: auto;
          bottom: -25px;
          left: 18px;
          transform: rotate(90deg);
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
    
    .section__title {
      &.#{$activeClass} {
        .text-highlight {
          &::after {
            height: 114.3%;
            bottom: -3px;
          }
        }
      }
    }

  }
}
