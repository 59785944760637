.#{$component-name} {
  &.ir-notice-detail {
    .container {
      padding: 0;
      padding-bottom: 160px;
    }

    .common {
      &__title {
        background-color: var(--black);
        color: $color-white;

        &-inner {
          padding: 192px 0 52px;
        }

        h2 {
          padding-top: 17px;
          font-size: 48px;
          line-height: 1.5;
          letter-spacing: -1.5px;
          color: $color-white;
        }

        span.ir-notice-detail__date {
          display: block;
          padding-top: 150px;
          font-weight: 700;
          font-size: 14px;
          line-height: 2.14;
          color: $color-white;
        }
      }
    }

    .ir-common {
      &__button {
        &.top {
          position: absolute;
          top: 0;
          left: -460px;
        }

        &.is-show-mo {
          display: none;
        }

        &.type3 {
          &:hover {
            color: $color-point;
          }
        }
      }
    }

    .anchor-nav__menu {
      opacity: 0;
    }

    .detail {
      font-size: 16px !important;
      line-height: 1.88 !important;
      letter-spacing: -0.5px !important;
      color: var(--color-sub);

      &.is-old-editor {
        p, span {
          font-family: "NanumSquareNeo", sans-serif !important;
        }
        div, p, span, ul, li, a, th, td, h1, h2, h3, h4, h5, h6, button, strong, i {
          color: var(--default);
        }
      }

      &__title {
        padding-bottom: 60px;
        font-weight: 700;
        font-size: 22px;
        line-height: 1.55;
        letter-spacing: -0.6px;
        text-align: center;
        color: var(--default);
      }

      &__text {
        &.center {
          margin-bottom: 10px;
          text-align: center;
        }
      }

      &__below {
        display: flex !important;
        justify-content: center;
        padding: 60px 0 !important;

        &:before, &:after {
          display: block !important;
          margin: 0 3px !important;
          content: '-' !important;
        }

        &::before {
          margin-left: 0 !important;
        }

        &-txt {
          margin-right: 72px !important;

          &:last-child {
            margin-right: 0 !important;
          }
        }
      }

      &__item {
        display: flex !important;
        padding-top: 30px !important;

        .num {
          display: block !important;
          margin-right: 12px !important;
        }

        &:first-child {
          padding-top: 0 !important;
        }

        &.sub-text, &.sub-dot {
          margin-top: 5px !important;
          padding-top: 0 !important;
          padding-left: 35px;

          .num {
            margin-right: 7px !important;
          }
        }

        &.sub-text-first {
          margin-top: 10px !important;
        }

        &.sub-dot {
          margin-top: 5px !important;
          padding-left: 60px;
        }

        &.sub-item {
          padding-top: 5px !important;
          padding-left: 80px;

          .num {
            margin-right: 4px !important;
          }
        }

        &.item-text {
          padding-top: 10px !important;
          padding-left: 25px;
        }

        &.detail__notice {
          display: block !important;
          margin-top: 30px;
          padding: 20px 20px 25px 20px !important;
          border: 1px solid var(--border01);

          .detail__notice {
            &-title {
              text-align: center;
            }

            &-text {
              margin-top: 20px;

              span {
                display: block;
                margin-top: 5px;

                &:first-child {
                  margin-top: 0;
                }
              }
            }

            &-area {
              margin-top: 20px;

              &:last-child {
                display: flex;
                margin-top: 33px;

                .detail__notice {
                  &-box {
                    display: block;
                    flex: 1;
                  }
                }
              }

              &.is-border-show {
                border: 1px solid var(--border01);

                .detail__notice {
                  &-box {
                    border-bottom: 1px solid var(--border01);

                    &:last-child {
                      border-bottom: 0;
                    }
                  }

                  &-name {
                    border-right: 1px solid var(--border01);

                    &:last-child {
                      border-right: 0;
                    }
                  }
                }
              }
            }

            &-box {
              display: flex;
            }

            &-name {
              flex: 1;
              padding: 3px 19px 5px;
            }
          }
        }

        table {
          width: 100%;
          margin-bottom: 30px !important;
          border: 1px solid var(--border01);
          text-align: center;

          thead {
            background-color: var(--bgColor02);
            border-bottom: 1px solid var(--border01);
            color: var(--default);

            th {
              padding: 4px 0;
              font-weight: 400;

              &:first-child {
                width: 66%;
                border-right: 1px solid var(--border01);
              }
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid var(--border01);

              &:last-child {
                border: 0;
              }
            }

            th {
              padding: 4px 19px;
              font-weight: 400;
              text-align-last: left;
            }

            td {
              padding: 4px 0;
              border-left: 1px solid var(--border01);

              &.day {
                width: 33%;
                padding: 4px 19px;
                text-align: left;
              }
            }
          }
        }

        &.center {
          justify-content: center;
          text-align: center;
        }

        &.date {
          padding: 40px 0 30px !important;
        }
      }
    }

    .ir-notice-detail {
      position: relative;
      max-width: 880px;
      padding-left: 0;
      margin: 80px auto 0;

      &__btn {
        display: flex;
        justify-content: space-between;
        margin-top: 160px;
      }

      .media-list {
        margin-top: 100px;

        .title {
          max-width: 453px;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ir-notice-detail {
      .ir-common {
        &__button {
          &.top {
            left: -386px;
          }
        }
      }

      .ir-notice-detail {
        max-width: 750px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir-notice-detail {
      .container {
        padding-bottom: 140px;
      }

      .common {
        &__title {
          &-inner {
            max-width: 718px;
            padding: 183px 0 40px;
          }

          h2 {
            font-size: 42px;
            line-height: 1.52;
            letter-spacing: -1.8px;
          }

          span.ir-notice-detail__date {
            padding-top: 149px;
            line-height: 1.86;
          }
        }
      }

      .ir-common {
        &__button {
          &.top {
            display: none;
          }
        }
      }

      .ir-notice-detail {
        max-width: 718px;
        margin: 80px auto 0;
      }

      .media-list {
        margin-top: 80px;

        .title {
          max-width: 350px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ir-notice-detail {
      .container {
        padding-bottom: 120px;
      }

      .common {
        &__title {
          &-inner {
            max-width: none;
            padding: 183px 30px 40px;
          }

          h2 {
            font-size: 38px;
            line-height: 1.53;
            letter-spacing: -1.5px;
          }

          span.ir-notice-detail__date {
            padding-top: 118px;
            line-height: 1.86;
          }
        }
      }

      .ir-notice-detail {
        max-width: none;
        margin: 60px auto 0;
        padding: 0 30px;
      }

      .detail {
        font-size: 14px !important;
        line-height: 1.86 !important;
        letter-spacing: -0.3px !important;

        &__title {
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }

        &__below {
          padding: 40px 0 !important;

          &-txt {
            margin-right: 66px;
          }
        }

        &__item {
          &.sub-text {
            padding-left: 10px;

            .num {
              margin-right: 9px !important;
            }
          }

          &.sub-dot {
            padding-left: 35px;

            .num {
              margin-right: 3px !important;
            }
          }

          &.sub-item {
            padding-left: 52px;

            .num {
              margin-right: 3px !important;
            }
          }

          &.detail__table {
            padding-top: 26px !important;
          }

          &.date {
            padding: 60px 0 10px !important;
          }
        }
      }

      .ir-notice-detail {
        &__btn {
          margin-top: 80px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.ir-notice-detail {
      .container {
        padding-bottom: 182px;
      }

      .common {
        &__title {
          &-inner {
            padding: 183px 20px 50px
          }

          h2 {
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
          }

          span.ir-notice-detail__date {
            padding-top: 127px;
          }
        }
      }

      .ir-common {
        &__button {
          &.list {
            display: none;
          }

          &.is-show-mo {
            display: flex;
            float: right;
            margin-top: 30px;

            &:after {
              display: block;
              content: '';
              clear: both;
            }
          }
        }
      }

      .detail {
        &__item {
          &.detail__notice {
            padding: 20px 12px 18px 10px !important;
            font-size: 12px;

            .detail__notice {
              &-area {
                &:last-child {
                  display: block;
                  margin-top: 19px;
                }
              }

              &-name {
                padding: 7px 8px;
              }
            }
          }

          &.detail__table {
            padding-top: 40px !important;
            font-size: 12px;
          }

          table {
            thead {
              th {
                padding: 7px 8px;

                &:first-child {
                  width: 64%;
                }
              }
            }

            tbody {
              th {
                padding: 7px 8px;
              }

              td {
                padding: 7px 8px;

                &.day {
                  padding: 7px 8px;
                }
              }
            }
          }
        }

      }

      .ir-notice-detail {
        margin: 60px auto 0;
        padding: 0 20px;
      }

      .ir-notice-detail {
        &__btn {
          margin-top: 100px;
        }
      }

      .media-list {
        margin-top: 40px;

        .title {
          max-width: 235px;
        }
      }
    }
  }
}
