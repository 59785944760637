.#{$component-name} {
  .fade-in {
    opacity: 1 !important;
  }

  .graph-area {
    position: relative;
    margin-top: 69px;
    margin-left: 12px;
    margin-right: 12px;

    .graph {
      overflow: hidden;

      &-view {
        position: relative;
        height: 524px;
        margin-top: 52px;

        .reduction-box {
          position: absolute;
          top: -66px;
          left: 50%;
          margin-left: -13px;
          display: flex;
          flex-direction: column-reverse;
          will-change: transition, opacity;
          opacity: 0;
          transition: opacity 0.3s linear 1s;

          &__title {
            display: block;
            margin-top: 7px;
            margin-left: -30px;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.5px;
            color: var(--default);

            @at-root .navercorp.lang-en {
              .graph-view .reduction-box__title {
                text-align: left;
                line-height: 1.46;
              }
            }

          }

          &__desc {
            position: relative;
            height: 94px;
            padding-right: 50px;
            box-sizing: border-box;

            &::after {
              content: "";
              position: absolute;
              top: 23px;
              right: 0;
              @include sp-desktop('esg-graph-arrow');
            }
          }

          &__figure {
            display: block;
            width: 145px;
            font-family: Testbed, sans-serif;
            font-weight: 800;
            color: var(--default);

            .text {
              font-size: 78px;
              font-weight: bold;
            }

            .unit {
              font-size: 44px;
              letter-spacing: -0.92px;
            }
          }
        }
      }

      &-desc {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column-reverse;
        will-change: transition, opacity;
        opacity: 0;
        transition: opacity 0.3s linear 1s;

        .title {
          display: block;
          line-height: 34px;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.4px;
          text-align: left;
          color: var(--graphTitle);
        }

        .legend-list {
          display: inline-block;
          vertical-align: top;
          margin-top: 26px;
          margin-bottom: auto;
          text-align: left;

          li {
            position: relative;
            line-height: 14px;
            font-size: 12px;
            letter-spacing: -0.3px;
            color: var(--color-sub);

            &+li {
              margin-top: 13px;
            }

            &::before {
              content: "";
              display: inline-block;
              vertical-align: top;
              width: 14px;
              height: 14px;
              margin-right: 8px;
              border-radius: 50%;
              background-color: #e0e0e0;
            }

            &.green:before {
              background-color: #0dcb67;
            }

            &.blue:before {
              background-color: #3b86fe;
            }
          }
        }
      }
    }
  }

  .graph-viewer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .graph-box {
      display: flex;
      position: relative;
      flex: 50%;

      &:first-of-type {
        align-items: flex-end;

        .graph-bar {
          background-color: var(--graphBar);
        }
      }

      &:last-of-type .graph-bar {
        background-color: #0eca6b;
      }

      &.fade-in-line .middle::before {
        opacity: 1;
      }
    }

    .graph-line {
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-top: -11px;
      left: 16px;
      right: 16px;
      width: 0;
      height: 26px;
      overflow: hidden;
      box-sizing: border-box;

      .image {
        display: block;
        height: 100%;

        .animation-area {
          display: block;
          position: relative;
          height: 100%;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: -4px;
            margin: auto 0;
            width: 13px;
            height: 13px;
            background-color: #3b86fe;
            border-radius: 50%;
          }

          &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: -2px;
            margin: auto 0;
            width: 13px;
            height: 13px;
            background-color: #3b86fe;
            border-radius: 50%;
          }
        }

        img {
          width: 100%;
          height: 100%;
          -o-object-fit: fill;
          object-fit: fill;
        }
      }

      .tooltip {
        position: absolute;
        right: -9px;
        bottom: 20px;
        width: 161px;
        height: 44px;
        opacity: 0;
        
        &.type-eng {
          display: none;
        }

        @at-root .navercorp.lang-en {
          .graph-viewer .graph-line .tooltip {
            &.type-kor {
              display: none;
            }
            &.type-eng {
              display: block;
              right: -9px;
              bottom: 20px;
              width: 169px;
              height: 61px;
              opacity: 0;
            }
          }
        }
      }

      &.fade-in-line {
        width: calc(100% - 32px);
        transition: width 1s cubic-bezier(0.71, 0.12, 0.31, 0.86);
      }

      &.fade-in-tooltip {
        overflow: visible;
      }

      &.fade-in-tooltip .tooltip {
        opacity: 1;
        bottom: 28px;
        transition: all 0.5s linear;
      }
    }

    .graph-bar {
      position: relative;
      -ms-flex: 1;
      flex: 1;
      height: 0;
      transition: height 1s cubic-bezier(0.71, 0.12, 0.31, 0.86);

      &.middle::before {
        content: "";
        position: absolute;
        top: -163px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 1px;
        height: 167px;
        background-color: #999;
        opacity: 0;
        transition: opacity 0.5s linear;
      }

      &+.graph-bar {
        margin-left: 7px;
      }

      .year {
        display: block;
        padding-top: 6px;
        font-size: 11px;
        font-weight: 800;
        letter-spacing: -0.3px;
        text-align: center;
        color: #fff;
        word-break: break-all;
      }
    }
  }


  @include breakpoint(pc) {
    .graph-viewer {
      .graph-bar {
        &+.graph-bar {
          margin-left: 13px;
        }

        &.middle::before {
          top: -250px;
          height: 254px;
        }

        .year {
          padding-top: 8px;
          font-size: 14px;
        }
      }
    }

    @at-root .navercorp.lang-en {
      .graph-viewer .graph-line .tooltip {
        &.type-kor {
          display: none;
        }
        &.type-eng {
          display: block;
          right: -9px;
          bottom: 20px;
          width: 142px;
          height: 52px;
          opacity: 0;
        }
      }
    }

  }

  @include breakpoint(tablet) {

            
    @at-root .navercorp.lang-en {
      .graph-view .reduction-box__title {
        margin-left: 14px;
      }
    }

    .graph-area {
      margin-top: 70px;
      margin-left: 7px;
      margin-right: 7px;

      .graph-view {
        height: 320px;
        margin-top: 78px;

        .reduction-box {
          top: -80px;
          margin-left: 10px;

          &__desc {
            height: 85px;
            padding-right: 50px;

            &::after {
              top: 17px;
            }
          }

          &__figure {
            width: 137px;

            .text {
              font-size: 72px;
            }

            .unit {
              font-size: 40px;
              letter-spacing: -0.83px;
            }
          }
        }
      }

      .graph-desc {
        top: 0;

        .legend-list {
          margin-top: 20px;
        }
      }
    }

    .graph-viewer {
      .graph-bar {
        &+.graph-bar {
          margin-left: 10px;
        }

        .year {
          padding-top: 6px;
          font-size: 12px;
        }
      }

      .graph-line {
        .tooltip {
          bottom: 30px;
          right: 0;
          width: 128px;
          height: 37px;
        }

        &.fade-in-tooltip .tooltip {
          bottom: 38px;
        }

        .image .animation-area {
          &::before, &::after {
            width: 12px;
            height: 12px;
          }

          &::after {
            bottom: -4px;
          }
        }
      }
    }
  }

  @include breakpoint(tablet-small) {
    .graph-area {
      margin-top: 60px;
      margin-left: 0;
      margin-right: 0;

      .graph-view {
        height: 298px;
        margin-top: 66px;

        .reduction-box {
          top: -66px;
          margin-left: 12px;

          &__title {
            margin-top: -2px;
            margin-left: -22px;
            font-size: 12px;
          }

          &__desc {
            height: 76px;
            padding-right: 40px;

            &::after {
              top: 11px;
              @include sp-mobile('esg-graph-arrow');
            }
          }

          &__figure {
            width: 105px;

            .text {
              font-size: 54px;
            }

            .unit {
              font-size: 30px;
              letter-spacing: 0px;
            }
          }
        }
      }

      .graph-desc {
        bottom: -10px;

        .title {
          line-height: 22px;
          font-size: 14px;
          letter-spacing: -0.3px;
        }

        .legend-list {
          margin-top: 13px;

          li+li {
            margin-top: 10px;
          }
        }
      }
    }

    .graph-viewer {
      .graph-line {
        left: 14px;
        right: 14px;
        height: 39px;

        &.fade-in-line {
          width: calc(100% - 28px);
        }

        .image .animation-area {
          &::before {
            bottom: -1px;
          }

          &::after {
            bottom: -3px;
          }
        }
      }

      .graph-bar {
        &.middle::before {
          top: -220px;
          height: 224px;
        }

        .year {
          font-size: 10px;
        }
      }
    }
  }

  @include breakpoint(mobile) {
    .graph-area {
      .graph-view {
        height: 222px;
        margin-top: 65px;

        .reduction-box {
          top: -64px;
          left: 14px;
          margin-left: 0;

          &__title {
            letter-spacing: -0.32px;
          }
        }
      }

      .graph-desc {
        flex-direction: row;
        position: static;
        margin-top: 35px;

        .legend-list {
          margin-top: 0;
          margin-left: auto;
          margin-right: 24px;

          li+li {
            margin-top: 14px;
          }
        }
      }
    }

    .graph-viewer {
      .graph-line {
        margin-top: -18px;
        height: 42px;

        &.fade-in-tooltip {
          .tooltip {
            right: -12px;
            bottom: 22px;
          }
        }

        .image .animation-area {
          top: 3px;

          &::before, &::after {
            width: 6.6px;
            height: 6.6px;
          }

          &::before {
            bottom: -7px;
          }
        }
      }

      .graph-bar + .graph-bar {
        margin-left: 7px;
      }

      .graph-bar.middle::before {
        top: -204px;
        height: 207px;
      }
    }
  }
}
