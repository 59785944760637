.#{$component-name} {
  &.robotics {
    .track-animation {
      &__highlight-area {
        width: 1412px;
      }

      &__highlight-text {
        text-align: center;
      }
    }

    .container {
      padding-bottom: 0;
    }

    .content03 {
      padding: 160px 0 200px;
    }

    .content04 {
      .cover {
        position: relative;
        // TODO
        background: url(../img/pc/tech-robotics-img-1.png) no-repeat center / cover;

        .video__player {
          height: 100%;
        }
      }

      .full-sticky {
        .logo {
          width: 211px;
        }
      }

      .section__img-explanation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1494px;
        margin: 168px auto 0;

        &-text {
          margin-bottom: 34px;
          font-weight: 700;
          font-size: 19px;
          line-height: 1.86;
          text-align: center;
          color: #86868b;

          .em {
            font-weight: 800;
            color: white;
          }
        }

        .img {
          &-arrow {
            width: 82px;
          }

          &-left {
            width: 283px;
          }

          &-center {
            width: 470px;
          }

          &-right {
            width: 367px;
          }
        }
      }

      .info__thumbnail-list {
        margin: 0 auto;
        padding: 173px 0 200px;
      }

      .info__contents {
        padding-right: 134px;
      }
      
      .info__title {
        padding-bottom: 9px;
        line-height: 0;
        
        &--arcbrain {
          .img-logo {
            width: 168px;
            height: 25px;
          }
        }
        
        &--arceye {
          .img-logo {
            width: 140px;
            height:29px;
          }
        }
      }
    }

    .content05 {
      padding-bottom: 140px;

      .cover {
        position: relative;
        // TODO
        background: url(../img/pc/tech-robotics-img-1.png) no-repeat center / cover;

        .video__player {
          height: 100%;
        }
      }

      .half {
        &__list {
          &:first-child {

            // TODO
            .half__box:first-child {
              background: url(../img/pc/tech-robotics-img-2.jpg) no-repeat center / cover;
            }
          }

          &:nth-child(2) {

            // TODO
            .half__box:first-child {
              background: url(../img/pc/tech-robotics-img-2.jpg) no-repeat center / cover;
            }
          }

          &:nth-child(3) {

            // TODO
            .half__box:first-child {
              background: url(../img/pc/tech-robotics-img-2.jpg) no-repeat center / cover;
            }
          }

          &:nth-child(4) {

            // TODO
            .half__box:first-child {
              background: url(../img/pc/tech-robotics-img-2.jpg) no-repeat center / cover;
            }
          }
          &__img {
            background-color: #000;
          }
        }

        &__box {
          .image {
            &.top-right {
              width: 720px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.robotics {
      .track-animation {
        &__highlight-area {
          width: 1222px;
        }
      }

      .content03 {
        padding: 160px 0 170px;
      }

      .content04 {
        .section__img-explanation {
          max-width: 1305px;
          margin: 140px auto 0;

          &-text {
            margin-bottom: 29px;
            font-size: 16px;
          }

          .img {
            &-arrow {
              width: 68px;
            }

            &-left {
              width: 236px;
            }

            &-center {
              width: 392px;
            }

            &-right {
              width: 305px;
            }
          }
        }

        .info__thumbnail-list {
          padding: 143px 0 259px;
        }

        .info__contents {
          padding-right: 27px;
        }
      }

      .content05 {
        .half {
          &__box {
            .image {
              &.top-right {
                width: 600px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.robotics {
      .track-animation {
        &__highlight-area {
          width: auto;
        }

        &__highlight-text {
          padding: 0 50px;
        }
      }

      .content03 {
        padding: 120px 0;
      }

      .content04 {
        .section__img-explanation {
          max-width: 836px;
          margin: 110px auto 0;

          &-text {
            margin-bottom: 21px;
            font-size: 14px;
          }

          .img {
            &-arrow {
              width: 44px;
            }

            &-left {
              width: 151px;
            }

            &-center {
              width: 250px;
            }

            &-right {
              width: 196px;
            }
          }
        }

        .info__thumbnail-list {
          padding: 146px 30px 120px;
        }

        .info__contents {
          padding-right: 22px;
        }
        
        .info__title {
          padding-bottom: 5px;
          
          &--arcbrain {
            .img-logo {
              width: 134px;
              height: 20px;
            }
          }
          
          &--arceye {
            .img-logo {
              width: 116px;
              height:24px;
            }
          }
        }
      }

      .content05 {
        padding-bottom: 120px;

        .half {
          &__box {
            .image {
              &.top-right {
                width: 440px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.robotics {
      .content03 {
        padding: 100px 0;
      }

      .content04 {
        .full-sticky {
          .logo {
            width: 189px;
          }
        }

        .section__img-explanation {
          max-width: 630px;
          margin: 97px auto 0;

          &-text {
            margin-bottom: 20px;
            font-size: 12px;
          }

          .img {
            &-arrow {
              width: 33px;
            }

            &-left {
              width: 114px;
            }

            &-center {
              width: 188px;
            }

            &-right {
              width: 148px;
            }
          }
        }

        .info__thumbnail-list {
          padding: 119px 30px 100px;
        }

        .info__contents {
          padding-right: 28px;
        }
        
        .info__title {
          margin-top: 16px;
          padding-bottom: 1px;
          
          &--arcbrain {
            .img-logo {
              width: 107px;
              height: 16px;
            }
          }
          
          &--arceye {
            .img-logo {
              width: 89px;
              height:19px;
            }
          }
        }
      }

      .content05 {
        padding-bottom: 0;

        .half {
          &__box {
            .image {
              &.top-right {
                width: 580px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.robotics {
      .track-animation {
        &__highlight-area {
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 30px;
          text-align: left;
        }
      }

      .content03 {
        padding: 100px 0 120px;
      }

      .content04 {
        .cover {
          // TODO
          background-image: url(../img/mo/tech-robotics-img-1.png);
        }

        .full-sticky {
          .logo {
            width: 173px;
          }
        }

        .section__img-explanation {
          flex-direction: column;
          max-width: none;
          margin: 80px 0 0;

          &-text {
            margin-bottom: 26px;
            font-size: 14px;
          }

          &-item {
            margin-top: 45px;

            &:first-child {
              margin-top: 0;
            }
          }

          .img {
            &-arrow {
              width: 23px;
            }

            &-left {
              width: 183px;
            }

            &-center {
              width: 290px;
            }

            &-right {
              width: 236px;
            }
          }
        }

        .info__thumbnail-list {
          padding: 80px 20px 120px;
        }
        
        .info__title {
          margin-top: 24px;
          padding-bottom: 1px;
        }
      }

      .content05 {
        .cover {
          // TODO
          background-image: url(../img/mo/tech-robotics-img-1.png);
        }

        .half {
          &__list {
            &:first-child {

              // TODO
              .half__box:first-child {
                background-image: url(../img/mo/tech-robotics-img-2.jpg);
              }
            }

            &:nth-child(2) {

              // TODO
              .half__box:first-child {
                background-image: url(../img/mo/tech-robotics-img-2.jpg);
              }
            }

            &:nth-child(3) {

              // TODO
              .half__box:first-child {
                background-image: url(../img/mo/tech-robotics-img-2.jpg);
              }
            }

            &:nth-child(4) {

              // TODO
              .half__box:first-child {
                background-image: url(../img/mo/tech-robotics-img-2.jpg);
              }
            }
          }

          &__box {
            .image {
              &.top-right {
                width: 375px;
              }
            }
          }
        }
      }
    }
  }
}
