.#{$component-name} {
  &.team-naver {
    .container {
      padding-bottom: 0;
    }

    .section__visual {
      height: 100vh;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url(/img/pc/naver-team-main.jpg);

      &::before {
        content:'';
        width: 100%;
        height: 100vh;
        display: block;
      }
    }

    .video__player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .full-video {
      overflow: hidden;
      position: relative;
      padding-top: 56.25%;

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .section__info {
      overflow: hidden;
      position: relative;
      padding-top: 56.25%;

      .cont {
        &__wrap {
          position: absolute;
          top: 50%;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          padding-left: 20.88%;
          padding-right: 19.83%;
          transform: translateY(-50%);
        }

        &__inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          margin: 0;
          padding: 0;
          text-align: left;
        }

        &__title {
          font-weight: 800;
          font-size: 72px;
          line-height: 1.36;
          letter-spacing: -2.4px;
        }

        &__desc {
          padding-left: 40%;
          margin-top: 120px;
          font-weight: 800;
          font-size: 38px;
          line-height: 1.53;
          letter-spacing: -1.5px;
          transform: translateY(-10%);

          @at-root .theme-dark & {
            color: var(--default);
          }

          &.is-active {
            transform: translateY(0);
            transition: all 0.3s cubic-bezier(0.37, 0, 0.63, 1);
            
            .em{
              color: var(--default);
              transition: all 0.8s cubic-bezier(0.37, 0, 0.63, 1);
            }
          }
        }
      }
    }

    .btn-arrow {
      position: absolute;
      left: 50%;
      bottom: 70px;
      z-index: 10;
      @include sp-desktop('ic-arrow-primary-w');
      background: url(/img/common/ic-arrow-down.png) no-repeat;
      background-size: 100%;
      animation: arrowAnimation 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
      margin-left: -25px;

        &.#{$stickyClass} {
          position: sticky;
          left: calc(50% - 25px);
        }
    }

    .half__list {
      &:first-child {
        .half__box:first-child {
          background-image: url(/img/pc/naver-team-cloud.jpg);
        }
      }

      &:nth-child(2) {
        .half__box:first-child {
          background-image: url(/img/pc/naver-team-snow.jpg);
        }
      }

      &:nth-child(3) {
        .half__box:first-child {
          background-image: url(/img/pc/naver-team-labs.jpg);
        }
      }

      &:nth-child(4) {
        .half__box:first-child {
          background-image: url(/img/pc/naver-team-webtoon.jpg);
        }
      }

      &:last-child {
        .half__box:first-child {
          background-image: url(/img/pc/naver-team-financial.jpg);
        }
      }
    }

    .half__box {
      &:first-child {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &:last-child {
        align-items: center;
      }
    }

    .half__info {
      color: var(--default);

      .title {
        font-size: 38px;
        line-height: 1.42;
        letter-spacing: -1.5px;
      }

      .name {
        margin-top: 16px;
        font-size: 26px;
        line-height: 1.46;
        letter-spacing: -0.8px;
      }

      .text {
        margin-top: 50px;
        font-size: 18px;
        line-height: 1.67;
        letter-spacing: -0.5px;
        color: var(--color-sub);
      }

      .ir-common__button {
        margin-top: 60px;

        &::after {
          width: 24px;
          height: 24px;
          background: url(../img/common/ic-outlink-white.svg) no-repeat center;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.team-naver {
      .section__info {
        .cont {
          &__wrap {
            padding-left: 15%;
            padding-right: 14.4%;
          }
        }
      }

      .half__info {
        padding: 0 80px 48px;

        .title {
          font-size: 32px;
          line-height: 1.5;
          letter-spacing: -1px;
        }

        .name {
          margin-top: 14px;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
        }

        .text {
          margin-top: 40px;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.4px;
        }

        .ir-common__button {
          margin-top: 50px;
          font-size: 16px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.team-naver {
      .section__visual {
        background-image: url(/img/mo/naver-team-main-tab.jpg);
      }

      .full-video {
        padding-top: 131.641%;
      }

      .section__info {
        padding-top: 68.36%;
        height: 85vw;

        .cont {
          &__wrap {
            padding-left: 11%;
            padding-right: 8%;
          }

          &__title {
            margin-top: 180px;
            font-size: 64px;
            line-height: 1.38;
            letter-spacing: -2px;
          }

          &__desc {
            margin-top: 80px;
            padding-left: 30%;
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
            margin-bottom: 120px;
          }
        }
      }


      .half__list {
        display: block;
      }

      .half__box {
        &:first-child {
          height: 768px;
        }

        &:last-child {
          height: auto;
        }
      }

      .half__info {
        padding: 50px 30px 140px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.team-naver {
      .full-video {
        padding-top: 133.464%;
      }

      .section__info {
        padding-top: 68.881%;
        height: 88vw;

        .cont {
          &__wrap {
            padding-left: 11.7%;
            padding-right: 3%;
          }

          &__title {
            margin-top: 145px;
            font-size: 48px;
            line-height: 1.42;
            letter-spacing: -1.5px;
          }

          &__desc {
            padding-left: 36.7%;
            margin-top: 50px;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
            margin-bottom: 100px;
          }
        }
      }

      .btn-arrow {
        bottom: 30px;
      }

      .half__box {
        &:first-child {
          height: 576px;
        }
      }

      .half__info {
        padding-top: 40px;

        .ir-common__button {
          width: 200px;
          height: 48px;
          padding: 0 16px;
          font-size: 16px;

          &:after {
            right: 16px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.team-naver {
      .section__visual {
        background-image: url(/img/mo/naver-team-main-mo.jpg);
      }

      .full-video {
        padding-top: 216.534%;
      }

      .section__info {
        padding-top: 100%;
        margin: 120px 0 120px 0;
        justify-content: center;
        height: 100px;
        .cont {
          &__wrap {
            padding: 0 20px;
          }

          &__title {
            padding: 0;
            font-size: 36px;
            line-height: 1.39;
            letter-spacing: -1.8px;
            margin-top: 120px;
          }

          &__desc {
            padding-left: 0;
            margin-top: 40px;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }
      }


      .btn-arrow {
        position: absolute;
        left: 50%;
        bottom: 50px;
        z-index: 10;
        width: 30px;
        height: 34px;
        margin-left: -25px;
        background: url(/img/common/ic-arrow-down.png) no-repeat;
        background-size: 100%;
        animation: arrowAnimation 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
      }

      .half__list {
        &:first-child {
          .half__box:first-child {
            background-image: url(/img/mo/naver-team-cloud.jpg);
          }
        }

        &:nth-child(2) {
          .half__box:first-child {
            background-image: url(/img/mo/naver-team-snow.jpg);
          }
        }

        &:nth-child(3) {
          .half__box:first-child {
            background-image: url(/img/mo/naver-team-labs.jpg);
          }
        }

        &:nth-child(4) {
          .half__box:first-child {
            background-image: url(/img/mo/naver-team-webtoon.jpg);
          }
        }

        &:last-child {
          .half__box:first-child {
            background-image: url(/img/mo/naver-team-financial.jpg);
          }
        }
      }

      .half__box {
        &:first-child {
          height: 375px;
        }
      }

      .half__info {
        padding: 40px 20px 90px;

        .title {
          font-size: 22px;
          line-height: 1.09;
          letter-spacing: normal;
        }

        .name {
          margin-top: 8px;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.5px;
        }

        .text {
          margin-top: 24px;
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;
        }

        .ir-common__button {
          width: 180px;
          height: 44px;
          margin-top: 36px;
          font-size: 14px;
          &::after {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
