// parallax
.track-animation {
  position: relative;
  height: 180vh;

  &.is-not-fixed {
    height: auto;
    
    .track-animation {
      &__fixed {
        position: relative;
      }

      &__highlight-text {
        &.#{$showClass} {
          transform: translateY(-50%);
        }
      }
    }
  }

  &.thema-black {
    .track-animation {
      &__highlight-text {
        color: $color-default;

        .text {
          opacity: .5;

          &.#{$activeClass} {
            opacity: 1;
          }
        }

        &.not-scene {
          &.#{$showClass} {
            transform: translateY(-50%);
          }
    
          .text {
            opacity: 1;
          }
        }
      }

    }

    .btn-arrow {
      background-image: url('/img/common/ic-arrow-down-black.png');
    }
  }
  
  &__cover {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__dimmed {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    opacity: 0;
    transition: opacity .8s ease-in-out;
    
    &.#{$showClass} {
      opacity: .7;
    }
  }
  
  &__fixed {
    overflow: hidden;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &__main-area, &__highlight-area {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  &__main-text, &__main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 80px;
    font-weight: 800;
    color: $color-white;
    letter-spacing: -2.67px;
    line-height: 1.23;
    opacity: 0;
    transform: translateY(calc(-50% + 50px));
    transition: all .6s cubic-bezier(0.37, 0, 0.63, 1);

    span {
      display: block;
    }

    &.#{$showClass} {
      opacity: 1;
      transform: translateY(-50%);
      transition: all .8s cubic-bezier(0.37, 0, 0.63, 1);
    }
    
    &.#{$hideClass} {
      opacity: 0;
      transform: translateY(-170%);
      transition: all .8s cubic-bezier(0.37, 0, 0.63, 1);
    }
  }

  &__main-image {
    transform: translateY(calc(50% + 50px));

    &.#{$hideClass} {
      transform: translateY(-270%);
    }
  }

  &__highlight-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 48px;
    font-weight: 800;
    color: $color-white;
    letter-spacing: -1.5px;
    line-height: 1.58;
    opacity: 0;
    transform: translateY(110px);
    transition: all .6s cubic-bezier(0.37, 0, 0.63, 1);
    
    &.#{$showClass} {
      opacity: 1;
      transform: translateY(0);
      transition: all .8s cubic-bezier(0.37, 0, 0.63, 1);
    }

    &.not-scene {
      &.#{$showClass} {
        transform: translateY(-50%);
      }

      .text {
        opacity: 1;
      }
    }

    &.act02 {
      &.scene01 {
        transform: translateY(-33.3%);
      }

      &.scene02 {
        transform: translateY(-66.6%);
      }
    }

    &.act03 {
      &.scene01 {
        transform: translateY(-25%);
      }

      &.scene02 {
        transform: translateY(-50%);
      }

      &.scene03 {
        transform: translateY(-75%);
      }
    }

    &.act04 {
      &.scene01 {
        transform: translateY(-20%);
      }

      &.scene02 {
        transform: translateY(-40%);
      }

      &.scene03 {
        transform: translateY(-60%);
      }

      &.scene04 {
        transform: translateY(-80%);
      }
    }

    &.scene {
      transition: all .5s cubic-bezier(0.37, 0, 0.63, 1);
    }

    .text {
      opacity: .2;
      transition: opacity .3s ease-in-out;

      &.#{$activeClass} {
        opacity: 1;
      }
    }
  }

  .btn-arrow {
    position: absolute;
    left: 50%;
    bottom: 70px;
    z-index: 10;
    width: 50px;
    height: 57px;
    margin-left: -25px;
    background: url('/img/common/ic-arrow-down.png') no-repeat;
    background-size: 100%;
    animation: arrowAnimation 1.6s cubic-bezier(.455,.03,.515,.955) infinite both;

    &.#{$stickyClass} {
      position: sticky;
      left: calc(50% - 25px);
    }
  }
}

.sticky {
  &__cover {
    position: sticky;
    top: 0;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__area {
    position: relative;
    z-index: 1;
    margin-top: -100vh;
  }
  
  &__box {
    min-height: 100vh;
  }
}

@keyframes arrowAnimation {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}


@include breakpoint(pc) {
  .track-animation {
    &__main-text {
      font-size: 72px;
      line-height: 1.36;
      letter-spacing: -2.4px;
    }

    &__highlight-text {
      font-size: 42px;
      line-height: 1.52;
      letter-spacing: -1.8px;
    }

    .btn-arrow {
      bottom: 60px;
      width: 42px;
      height: 47px;
      margin-left: -21px;

      &.#{$stickyClass} {
        left: calc(50% - 21px);
      }
    }
  }
}

@include breakpoint(tablet){
  
  .track-animation {
    &__highlight-text {
      text-align: center;
    }
  }
}


@include breakpoint(tablet-small) {
  .track-animation {
    &__main-text {
      font-size: 48px;
      line-height: 1.42;
      letter-spacing: -1.5px;
    }

    &__highlight-text {
      font-size: 32px;
      line-height: 1.5;
      letter-spacing: -1px;
    }

    .btn-arrow {
      bottom: 50px;
      width: 34px;
      height: 38px;
      margin-left: -17px;

      &.#{$stickyClass} {
        left: calc(50% - 17px);
      }
    }
  }
}

@include breakpoint(mobile) {
  .track-animation {
    &__main-text {
      font-size: 42px;
      line-height: 1.43;
    }

    &__highlight-text {
      font-size: 26px;
      line-height: 1.62;
      letter-spacing: -.8px;
    }

    .btn-arrow {
      bottom: 40px;
      width: 30px;
      height: 34px;
      margin-left: -15px;

      &.#{$stickyClass} {
        left: calc(50% - 15px);
      }
    }
  }
}
