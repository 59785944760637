.cont {
  &__inner {
    max-width: 1800px;
    margin: 0 auto;
    text-align: center;
  }

  &__label {
    display: inline-flex;
    align-items: center;
    height: 30px;
    padding: 0 12px;
    background-color: $color-point;
    color: $color-default;

    span {
      display: inline-block;
      font-size: 19px;
      font-weight: 800;
      letter-spacing: -.01px;
      text-transform: uppercase;

      &:first-child {
        &::after {
          display: inline-block;
          width: 80px;
          height: 19px;
          margin: 0px 10px;
          background: url(/img/common/label-arrow.svg) no-repeat center;
          background-size: 100% auto;
          vertical-align: -3px;
          content: "";
        }
      }
    }
  }

  &__title {
    margin-top: 40px;
    font-weight: 800;
    font-size: 72px;
    line-height: 1.39;
    letter-spacing: -2.4px;
    color: var(--pageTitle);

    &-wrap {
      padding: 80px 0 80px;
      font-size: 0;
      text-align: center;
    }
  }

  &__img {
    img {
      height: 100vh;
    }
  }

  &__text {

    .em,
    .txt {
      font-weight: 800;
      font-size: 42px;
      line-height: 1.52;
      letter-spacing: -1.8px;
      color: $color-default;
    }

    .txt {
      display: inline-block;
      opacity: 0.5;
    }
  }
}

.section__right-box {
  margin: 0 0 0 auto;
  max-width: 1518px;
}

.half {
  &__list {
    display: flex;
  }

  &__box {
    overflow: hidden;
    position: relative;
    flex: 1;
    flex-shrink: 0;
    height: 1080px;

    &:last-child {
      display: flex;
      align-items: end;
    }

    .image {
      position: absolute;
      top: 60px;
      left: 50%;
      width: 640px;
      transform: translateX(-50%);

      &.top-right {
        top: 0;
        right: 0;
        left: auto;
        transform: none;
      }
    }
  }

  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__img {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      top: 0;
      left: 0;
      ;
      width: 100%;
    }
  }

  &__info {
    width: 100%;
    padding: 0 160px 106px 100px;
    color: $color-white;
    z-index: 1;

    .title {
      display: block;
      font-weight: 800;
      font-size: 48px;
      line-height: 1.42;
      letter-spacing: -0.01px;
    }

    .name {
      margin-top: 24px;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.91;
      letter-spacing: -0.6px;
    }

    .text {
      margin-top: 10px;
      font-size: 16px;
      color: #a1a1a6;
      line-height: 1.88;
      letter-spacing: -0.5px;
    }
  }
}

.info {
  &__list-container {
    padding: 0 60px;

    .info {
      &__thumbnail {
        &-list {
          justify-content: space-between;
        }

        &-item {
          width: 24.16%;
        }
      }

      &__contents {
        padding-right: 51px;
      }
    }

    &.type-right {
      padding-left: 26.82%;

      .info {
        &__thumbnail {
          &-list {
            gap: 0;
          }

          &-item {
            width: 32.34%;
          }
        }
      }
    }
  }

  &__thumbnail {
    width: 100%;

    img {
      width: 100%;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }

    &-item {
      @at-root .column2 & {
        width: 663px;
      }

      @at-root .column3 & {
        width: 510px;
      }
    }
  }

  &__contents {
    padding-right: 155px;

    @at-root .column3 & {
      padding-right: 80px;
    }
  }

  &__title {
    margin-top: 30px;
    font-weight: 700;
    font-size: 22px;
    color: white;
    line-height: 1.91;
  }

  &__outlink {
    position: relative;
    padding-right: 29px;
    color: white;
    font-weight: 700;
    transition: color .3s;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 25px;
      height: 25px;
      background: url(/img/common/ic-outlink-white2.png) no-repeat center;
      background-size: contain;
      transform: translateY(-50%);
      transition: background .3s;
    }

    &:hover {
      color: $color-point;
      &:before {
        background: url(/img/common/btn-outlink-sm-on.png) no-repeat center;
        background-size: contain;
      }
    }
  }

  &__desc {
    margin-top: 15px;
    font-size: 16px;
    color: #a1a1a6;
    line-height: 1.88;
    letter-spacing: -0.5px;
  }
}

.common-layer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-layer + 150;
  width: 100%;
  height: 100%;

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__wrapper {
    position: relative;
    z-index: $z-index-layer + 1;
    background-color: var(--reverse);
  }

  .dimmed {
    display: block;
    z-index: $z-index-layer;
  }

  .one-viewer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & + img {
      position: absolute;
    }
    
    .se-viewer {
      height: 100%;

      .se-main-container {
        height: 100%;
      }
      
      .se-component-content {
        max-width: none;
        height: 100%;
        padding: 0;
      }

      .se-section-video {
        height: 100%;
      }

      
      .se-video {
        height: 100%;

        canvas {
          height: 100%;
        }

        .se-module-video {
          padding: 0;
          height: 100%;
        }

        .se-media-meta {
          display: none;
        }
      }

      @at-root .theme-dark {
        .one-viewer {
          div, p, span, ul, li, a, th, td, h1, h2, h3, h4, h5, h6, button, strong, i {
            color: $color-white;
          }
        }
        .se-viewer .se-module-file {
          background-color: #111;
        }
      }
    }
  }
}

.#{$component-name} {
  position: relative;

  &.bg-black {
    .content01, .content02, .content03, .content04, .content05, .content06, .content07, .content08, .content09, .content10, .content11, .card__sub-wrap, .section__outlink-wrap {
      background: $color-black;
      color: $color-white;
    }

    .card__sub.is-scroll-view {
      h4 {
        color: $color-white;
      }
    }
  }

  .card {
    &__list {
      margin: -10px 0 0 -10px;
      font-size: 0;

      &-wrap {
        overflow: hidden;
        margin-top: 40px;
      }
    }

    &__item {
      display: inline-block;
      width: calc((100% - 30px) / 3);
      margin: 10px 0 0 10px;

      .tit {
        display: block;
        font-weight: 800;
        font-size: 16px;
        line-height: 2.38;
        transition: all 0.3s;
        color: var(--default);
      }

      .txt {
        display: block;
        margin-top: 16px;
        font-weight: 800;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.3px;
        transition: all 0.3s;
        color: var(--default);
      }

      .btn__wrap {
        margin-top: 22px;
        text-align: end;
      }

      .btn-download {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-image: url(/img/common/ic-download-white-sm.png);
      }
    }

    &__link {
      display: block;
      width: 100%;
      height: 100%;
      padding: 12px 24px 20px 24px;
      border-bottom: 4px solid transparent;
      transition: all 0.3s;
      background-color: var(--bgColor02);

      &:hover {
        border-color: $color-point;

        .tit,
        .txt {
          color: $color-point;
        }

        .btn-download {
          background-color: $color-point;
        }
      }
    }

    &__main {
      .main {
        &__link {
          display: flex;
          justify-content: space-between;

          &:hover {

            .category,
            .news-tit {
              color: $color-point;
            }
          }
        }

        &__img {
          width: 100%;
          padding-top: 44.403%;
          background: no-repeat 50%;
          background-size: cover;
          background-image: url('/img/common/thumnail.png');
        }

        &__txt {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex-shrink: 0;
          width: 33.434%;
          padding: 40px;
          background-color: var(--newslistBg);

          .category {
            font-weight: 800;
            font-size: 16px;
            line-height: 1.88;
            color: $color-white;
            transition: all 0.3s;
          }

          .news-tit {
            display: block;
            margin-top: 30px;
            font-weight: 800;
            font-size: 38px;
            line-height: 1.53;
            letter-spacing: -1.5px;
            color: $color-white;
            transition: all 0.3s;
          }

          .news-date {
            display: inline-block;
            margin-top: 30px;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.67;
            color: #a1a1a6;
            color: var(--color-sub04);
          }
        }
      }
    }

    &__sub {
      padding-top: 10px;
    }

    &__btn {
      margin-top: 60px;
      text-align: center;
    }
  }

  .index-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    min-height: 100%;
    margin: 0 auto;
    color: $color-default;

    &.theme-white {
      color: $color-white;

      .btn-arrow {
        background-image: url(/img/common/ic-arrow-down.png);
        background-size: 100% auto;
      }

      .index-section__page-link {
        color: $color-white;

        &:hover {
          color: $color-point;
          &::before {
            background: url(/img/common/btn-circle-arrow-right-hover.png) no-repeat center;
            background-size: 100% auto;
            transition: background .3s;
          }
        }

        &::before {
          background: url(/img/common/btn-circle-arrow-right-w.png) no-repeat center;
          background-size: 100% auto;
        }
      }
    }

    .btn-arrow {
      position: absolute;
      left: 60px;
      bottom: 0px;
      width: 68px;
      height: 77px;
      background-image: url(/img/common/ic-arrow-down-black.png);
      background-size: 100% auto;
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      max-width: 1920px;
      width: 100%;
      margin: 0 auto;
      padding-left: 401px;
    }

    &__text-box {
      width: 600px
    }

    &__thumb-box {
      width: 720px;

      .video-wrap {
        overflow: hidden;
        position: relative;
        padding-top: 100%;
      }

      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
      }
    }

    &__title {
      font-size: 86px;
      font-weight: 800;
      line-height: 110px;
      letter-spacing: -.01px;
      text-transform: uppercase;
    }

    &__sub-title {
      display: block;
      margin-top: 80px;
      font-size: 22px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: -.6px;
    }

    &__desc {
      margin-top: 20px;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -.5px;
    }

    &__page {
      margin-top: 80px;

      &-list {
        &.depth2 {
          padding-left: 10px;

          .index-section__page {
            &-item {
              margin-top: 6px;

              &:first-child {
                margin-top: 8px;
              }
            }

            &-link {
              font-size: 16px;
              line-height: 28px;
              letter-spacing: -.5px;

              &::before {
                width: 18px;
                height: 18px;
                margin-right: 8px;
                background: url(/img/common/ic-arrow.png) no-repeat center;
                background-size: 100% auto;
              }

              &:hover::before {
                background: url(/img/common/ic-arrow-hover.png) no-repeat center;
                background-size: 100% auto;
              }
            }
          }
        }
      }

      &-item {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }

      &-link {
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -.01px;
        transition: color .3s;

        &:hover {
          color: $color-point;
        }

        &::before {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 12px;
          background: url(/img/common/btn-circle-arrow-right.png) no-repeat center;
          background-size: 100% auto;
          transition: background .3s;
          content: '';
        }
        
        &:hover::before {
          background: url(/img/common/btn-circle-arrow-right-hover.png) no-repeat center;
          background-size: 100% auto;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .cont {
    &__text {

      .em,
      .txt {
        font-size: 38px;
        line-height: 1.53;
        letter-spacing: -1.5px;
      }
    }
  }

  .section__right-box {
    max-width: 1272px;
  }

  .half {
    &__box {
      height: 900px;

      .image {
        top: 50px;
        width: 540px;
      }
    }

    &__info {
      padding: 0 48px 48px;
    }
  }

  .info {
    &__list-container {
      padding: 0 40px;

      &.type-right {
        padding-left: 26.56%;

        .info {
          &__thumbnail {
            &-item {
              width: 32.07%;
            }
          }
        }
      }

      .info {
        &__thumbnail {
          &-item {
            width: 23.94%;
          }
        }

        &__contents {
          padding-right: 42px;
        }
      }
    }

    &__thumbnail {
      &-item {
        @at-root .column2 & {
          width: 557px;
        }

        @at-root .column3 & {
          width: 430px;
        }
      }
    }

    &__contents {
      padding-right: 49px;

      @at-root .column3 & {
        padding-right: 49px;
      }
    }
  }

  .#{$component-name} {
    .card {
      &__list {
        margin: -6px 0 0 -6px;
      }

      &__item {
        width: calc((100% - 18px) / 3);
        margin: 6px 0 0 6px;

        .btn__wrap {
          margin-top: 10px;
        }

        .btn-download {
          width: 24px;
          height: 24px;
        }
      }

      &__link {
        padding: 10px 20px 16px 20px;
      }

      &__main {
        .main {
          &__txt {
            width: 33.51%;
            padding: 30px 35px 30px;

            .news-tit {
              margin-top: 26px;
              font-size: 30px;
              line-height: 1.6;
              letter-spacing: -1.18px;
            }

            .news-date {
              margin-top: 26px;
              font-size: 14px;
              line-height: 2.14;
            }
          }
        }
      }

      &__sub {
        padding-top: 6px;
      }
    }

    .index-section {
      &__inner {
        max-width: 1600px;
        padding-left: 328px;

        .btn-arrow {
          left: 40px;
        }
      }

      &__text-box {
        width: 500px;
      }

      &__thumb-box {
        width: 640px;
      }

      &__title {
        font-size: 72px;
        line-height: 92px;
      }

      &__sub-title {
        margin-top: 70px;
      }

      &__page {
        margin-top: 70px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .cont {
    &__title {
      margin-top: 30px;
      font-size: 64px;
      line-height: 1.38;
      letter-spacing: -2px;

      &-wrap {
        padding-bottom: 100px;
      }
    }

    &__text {

      .em,
      .txt {
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1px;
      }
    }
  }

  .section__right-box {
    max-width: none;
    margin: 0;
    padding: 0 30px;
  }

  .half {
    &__box {
      .image {
        top: 60px;
        width: 400px;
      }
    }

    &__info {
      padding: 0 30px 152px 50px;

      .title {
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -0.01px;
      }

      .name {
        margin-top: 16px;
        font-size: 18px;
        line-height: 1.67;
        letter-spacing: -0.5px;
      }

      .text {
        width: 100%;
        font-size: 14px;
        line-height: 1.86;
        letter-spacing: -0.3px;
      }
    }
  }

  .info {
    &__list-container {
      padding: 0 30px;

      &.type-right {
        padding-left: 30px;

        .info {
          &__thumbnail {
            &-item {
              width: calc(50% - 10px);
            }
          }
        }
      }

      .info {
        &__contents {
          padding-right: 40px;
        }
      }
    }

    &__thumbnail {
      &-list {
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 0;
      }

      &-item {
        margin-top: 80px;

        &:nth-child(-n+2) {
          margin-top: 0;
        }

        @at-root .column2 & {
          width: calc(50% - 10px);
          margin-top: 0;
        }

        @at-root .column3 & {
          width: calc(50% - 10px);
        }

        @at-root .column4 & {
          width: calc(50% - 10px);
        }
      }
    }

    &__contents {
      padding-right: 40px;

      @at-root .column3 & {
        padding-right: 40px;
      }
    }

    &__title {
      margin-top: 20px;
    }

    &__desc {
      margin-top: 15px;
    }
  }

  .#{$component-name} {
    .card__main {
      .main {
        &__img {
          padding-top: 44.295%;
        }

        &__txt {
          width: 33.507%;
          padding: 30px 30px 26px;

          .category {
            font-size: 10px;
            line-height: 2.2;
          }

          .news-tit {
            margin-top: 20px;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          .news-date {
            font-size: 10px;
            line-height: 2.2;
          }
        }
      }
    }

    .index-section {
      display: block;
      height: auto;
      min-height: 0;
      padding: 140px 30px;

      &__inner {
        max-width: none;
        padding-left: 0;

        .btn-arrow {
          display: none;
        }
      }

      &__text-box {
        width: 42.532%;
      }

      &__thumb-box {
        width: 48.964%;
      }

      &__title {
        font-size: 64px;
        line-height: 88px;
      }

      &__sub-title {
        margin-top: 80px;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -.5px;
      }

      &__page {
        margin-top: 80px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .cont {
    &__title {
      margin-top: 20px;
      font-size: 48px;
      line-height: 1.42;
      letter-spacing: -1.5px;

      &-wrap {
        padding: 72px 0 80px;
      }
    }

    &__text {

      .em,
      .txt {
        font-size: 26px;
        line-height: 1.54;
        letter-spacing: -0.8px;
      }
    }
  }

  .section__right-box {
    max-width: none;
    margin: 0;
    padding: 0 30px;
  }

  .half {
    &__list {
      display: block;
    }

    &__box {
      height: auto;

      .image {
        top: 18px;
        width: 540px;
      }

      &:first-child {
        height: 576px;
      }

      &:last-child {
        display: block;
        position: static;
      }
    }

    &__img {
      position: relative;
      height: 576px;
    }

    &__info {
      padding: 40px 30px 120px;

      .title {
        letter-spacing: -1px;
      }

      .name {
        margin-top: 24px;
        font-size: 16px;
        line-height: 1.63;
        letter-spacing: -0.34px;
      }

      .text {
        margin-top: 14px;
      }
    }
  }

  .info {
    &__list-container {
      &.type-right {
        .info {
          &__thumbnail {
            &-item {
              width: calc(50% - 6px);
            }
          }
        }
      }
    }

    &__thumbnail {
      &-item {
        margin-top: 100px;

        @at-root .column2 & {
          width: calc(50% - 6px);
          margin-top: 0;
        }

        @at-root .column3 & {
          width: calc(50% - 6px);
        }

        @at-root .column4 & {
          width: calc(50% - 6px);

        }
      }
    }

    &__contents {
      padding-right: 28px;

      @at-root .column3 & {
        padding-right: 40px;
      }
    }

    &__title {
      margin-top: 15px;
      font-size: 18px;
    }

    &__desc {
      font-size: 14px;
    }
  }

  .#{$component-name} {
    .card {
      &__list {
        margin: -20px 0 0 -6px;
      }

      &__item {
        width: calc((100% - 12px) / 2);
        margin: 20px 0 0 6px;

        .btn__wrap {
          margin-top: 0;
        }
      }

      &__main {
        .main {
          &__img {
            padding-top: 45.482%;
          }

          &__txt {
            width: 33.758%;
            padding: 20px 20px 18px;

            .category {
              font-size: 12px;
              line-height: 1.83;
            }

            .news-tit {
              margin-top: 14px;
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.6px;
            }

            .news-date {
              font-size: 12px;
              line-height: 1.83;
            }
          }
        }
      }

      &__btn {
        margin-top: 40px;
      }
    }

    .index-section {
      padding: 120px 30px;

      &__text-box {
        width: 43.787%;
      }

      &__thumb-box {
        width: 49.154%;
      }

      &__title {
        font-size: 48px;
        line-height: 68px;
      }

      &__sub-title {
        margin-top: 60px;
        font-size: 18px;
        line-height: 28px;
      }

      &__desc {
        margin-top: 14px;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -.3px;
      }

      &__page {
        margin-top: 60px;

        &-list {
          &.depth2 {
            padding-left: 4px;

            .index-section__page {
              &-item {
                margin-top: 4px;

                &:first-child {
                  margin-top: 12px;
                }
              }

              &-link {
                font-size: 14px;
                line-height: 26px;
                letter-spacing: -.01px;
              }
            }
          }
        }

        &-item {
          margin-top: 12px;
        }

        &-link {
          font-size: 16px;
          line-height: 22px;

          &::before {
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }
        }

      }
    }
  }
}

@include breakpoint(mobile) {
  .cont {
    &__label {
      height: 20px;
      padding: 0 8px;

      span {
        font-size: 13px;

        &:first-child {
          &::after {
            width: 34px;
            height: 11px;
            margin: 0px 5px;
            vertical-align: -2px;
          }
        }
      }
    }

    &__title {
      font-size: 38px;
      line-height: 1.42;
      letter-spacing: -1.5px;
      padding: 0 10px;

      &-wrap {
        padding: 60px 0 57px;
      }
    }
  }

  .section__right-box {
    padding: 0 20px;
  }

  .half {
    &__box {
      &:first-child {
        height: 406px;
      }

      .image {
        top: 30px;
        width: 336px;
      }
    }

    &__img {
      height: 406px;
    }

    &__info {
      padding: 26px 20px 120px;
    }
  }

  .info {
    &__list-container {
      padding: 0 20px;

      &.type-right {
        padding-left: 20px;

        .info {
          &__thumbnail {
            &-item {
              width: 100%;
            }
          }
        }
      }

      .info {
        &__contents {
          padding-right: 0;
        }
      }
    }

    &__thumbnail {
      &-list {
        flex-direction: column;
      }

      &-item {
        &+& {
          margin-top: 60px;
        }

        &:nth-child(-n+2) {
          margin-top: 60px;
        }

        &:first-child {
          margin-top: 0;
        }

        @at-root .column2 & {
          width: 100%;
        }

        @at-root .column3 & {
          width: 100%;
        }

        @at-root .column4 & {
          width: 100%;
        }
      }
    }

    &__contents {
      padding-right: 0;

      @at-root .column3 & {
        padding-right: 0;
      }
    }

    &__title {
      margin-top: 15px;
      font-size: 18px;
    }

    &__outlink {
      padding-right: 23px;

      &:before {
        width: 20px;
        height: 20px;
      }

      &:hover {
        color: $color-point;        
      }
    }

    &__desc {
      font-size: 14px;
    }
  }

  .#{$component-name} {
    .card {
      &__list {
        margin: -6px 0 0 0;
      }

      &__item {
        width: 100%;
        margin: 6px 0 0 0;

        .tit {
          line-height: 1.25;
        }

        .txt {
          margin-top: 12px;
          line-height: 1.86;
        }

        .btn__wrap {
          margin-top: 18px;
        }
      }

      &__link {
        padding: 20px 16px 16px 20px;
      }

      &__main {
        .main__link {
          flex-direction: column;
        }

        .main__img {
          padding-top: 66.5%;
        }

        .main__txt {
          width: 100%;
          min-height: 223px;
          padding: 14px 20px 20px;

          .news-tit {
            margin-top: 12px;
            font-size: 18px;
            line-height: 1.67;
          }

          .news-date {
            margin-top: 24px;
          }
        }
      }
    }

    .index-section {
      padding: 100px 20px 50px;

      &__inner {
        flex-direction: column;
      }

      &__text-box {
        width: 100%;
      }

      &__thumb-box {
        width: 100%;
        margin-top: 50px;
      }

      &__title {
        font-size: 46px;
        line-height: 58px;
      }

      &__sub-title {
        margin-top: 70px;
      }

      &__page {
        margin-top: 70px;
      }
    }
  }
}

// Editor CSS
.se-viewer .se-section-imageGroup.se-l-slide .se-imageGroup-progress {
  margin-top: 20px;
}
.se-viewer .se-imageGroup.se-l-slide .se-component-content-extend .se-imageGroup-navigation-button {
  top: 50%;
  margin-top: -24px;
}
.se-viewer .se-section-imageGroup.se-l-slide .se-image-resource {
  height: auto !important;
  width: 100% !important;
}
.se-viewer .se-imageGroup.se-l-slide .se-component-content .se-imageGroup-viewer {
  height: auto !important;
}

.se-viewer .se-section-horizontalLine.se-l-line1 .se-hr {
  @at-root .theme-dark & {
    background-color: #2F2E30;
  }
}

@media only screen and (min-width: 1280px) {
  .se-viewer .se-component-content {
    max-width: 890px !important;
  }
}

@media only screen and (min-width: 768px) {
  .se-viewer .se-component-content {
    max-width: none !important;
  }
}

.se_one_viewer_wrapper_880 .se-viewer .se-component-content.se-component-content-extend {
  max-width: 1006px;
  margin: 0 -128px;
}

@include breakpoint(pc) {
  .se_one_viewer_wrapper_880{
    max-width: 750px;
  }
}

@include breakpoint(tablet) {
  .se_one_viewer_wrapper_880{
    max-width: 718px;
  }

  .se_one_viewer_wrapper_880 .se-viewer .se-component-content.se-component-content-extend {
    margin: 0 -50px;
  }
}

@include breakpoint(tablet-small) {
  .se-viewer .se-section-table.se-l-table_layout5 .se-table-content {
    min-width: 768px;
  }

  .se_one_viewer_wrapper_880{
    max-width: none;
  }

  .se_one_viewer_wrapper_880 .se-viewer .se-component-content.se-component-content-extend {
    margin: 0 -30px;
  }
}

@include breakpoint(mobile) {
  .is-old-editor {
    img {
      max-width: 100%;
      height: auto !important;
    }
  }
  .se-viewer .se-image {
    margin: 0 -20px;
  }

  .se_one_viewer_wrapper_880 .se-viewer .se-component-content.se-component-content-extend {
    margin: 0 -20px;
  }
}
