.#{$component-name} {
  &.naver-awards {
    .section__inner {
      padding-top: 100px;
    }

    .history {
      margin-top: 94px;

      &__item {
        display: flex;
        padding: 30px 0;
        border-bottom: 1px solid $color-gray4;
        font-size: 0;

        &:first-of-type {
          border-top: 1px solid $color-gray4;
        }
      }

      &__detail {
        display: flex;

        &-wrap {
          padding-left: 124px;
        }

        +.history__detail {
          margin-top: 14px;
        }
      }

      &__text {
        display: inline-block;
        flex-shrink: 0;
        font-weight: 800;
        font-size: 14px;
        line-height: 1.86;
        color: var(--default);
        letter-spacing: -0.53px;

        &--year {
          width: 40px;
        }

        &--month {
          width: 34px;
          font-weight: 700;
        }
      }

      &__title {
        padding-left: 46px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.86;
        color: var(--color-sub);
        letter-spacing: -0.3px;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {}
}

@include breakpoint(tablet) {
  .#{$component-name} {}
}

@include breakpoint(tablet-small) {
  .#{$component-name} {}
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.naver-awards {
      .section__inner {
        padding-top: 60px;
      }

      .history {
        margin-top: 40px;

        &__item {
          flex-direction: column;
          padding: 10px 0 30px;
        }

        &__detail {
          &-wrap {
            padding: 20px 0 0;
          }
        }

        &__text {
          &--year {
            width: auto;
            font-size: 18px;
            line-height: 1.89;
          }

          &--month {
            color: #a1a1a6;
          }
        }

        &__title {
          padding-left: 36px;

          +.history__title {
            margin-top: 6px;
          }
        }
      }

      &.naver-awards {
        .history {
          &__detail {
            flex-direction: column;

            +.history__detail {
              margin-top: 20px;
            }
          }

          &__title {
            padding-left: 0;

            &-wrap {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
}
