@use "sass:math";

$mobile-vars: (
	'esg-graph-arrow': (
		offset-x: 0px,
		offset-y: 0px,
		width: 76px,
		height: 98px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-anchor-dropdown-w': (
		offset-x: -344px,
		offset-y: -36px,
		width: 28px,
		height: 28px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-anchor-dropdown': (
		offset-x: -344px,
		offset-y: -68px,
		width: 28px,
		height: 28px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-arrow-primary-w': (
		offset-x: -136px,
		offset-y: -102px,
		width: 57px,
		height: 64px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-arrow-primary': (
		offset-x: -232px,
		offset-y: 0px,
		width: 56px,
		height: 64px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-arrow-right': (
		offset-x: -292px,
		offset-y: -236px,
		width: 32px,
		height: 32px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-close-w': (
		offset-x: -232px,
		offset-y: -68px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-close': (
		offset-x: 0px,
		offset-y: -170px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-drop-down': (
		offset-x: -292px,
		offset-y: -104px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-drop-up': (
		offset-x: -292px,
		offset-y: -148px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-grid-g': (
		offset-x: -52px,
		offset-y: -170px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-grid-w': (
		offset-x: -104px,
		offset-y: -170px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-grid': (
		offset-x: -156px,
		offset-y: -170px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-list-g': (
		offset-x: -208px,
		offset-y: -170px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-list-w': (
		offset-x: 0px,
		offset-y: -222px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-list': (
		offset-x: -52px,
		offset-y: -222px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-mo-nav-w': (
		offset-x: -104px,
		offset-y: -222px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-mo-nav': (
		offset-x: -156px,
		offset-y: -222px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-quick-link-black-sm': (
		offset-x: -208px,
		offset-y: -222px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-quick-link-black': (
		offset-x: -292px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-delete-on': (
		offset-x: -292px,
		offset-y: -192px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-delete-sm-g': (
		offset-x: -264px,
		offset-y: -274px,
		width: 32px,
		height: 32px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-delete': (
		offset-x: -232px,
		offset-y: -120px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-mo': (
		offset-x: 0px,
		offset-y: -274px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-small-w': (
		offset-x: -44px,
		offset-y: -274px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-small': (
		offset-x: -88px,
		offset-y: -274px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-tab-on': (
		offset-x: -80px,
		offset-y: 0px,
		width: 72px,
		height: 72px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-tab-sm-on': (
		offset-x: 0px,
		offset-y: -102px,
		width: 64px,
		height: 64px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-tab-sm': (
		offset-x: -68px,
		offset-y: -102px,
		width: 64px,
		height: 64px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-tab': (
		offset-x: -156px,
		offset-y: 0px,
		width: 72px,
		height: 72px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-search-w': (
		offset-x: -292px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-share-small-w': (
		offset-x: -132px,
		offset-y: -274px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-share-small': (
		offset-x: -176px,
		offset-y: -274px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-stock-down': (
		offset-x: -300px,
		offset-y: -274px,
		width: 32px,
		height: 32px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-stock-up-small': (
		offset-x: -220px,
		offset-y: -274px,
		width: 40px,
		height: 40px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
	'ic-stock-up': (
		offset-x: -344px,
		offset-y: 0px,
		width: 32px,
		height: 32px,
		total-width: 376px,
		total-height: 314px,
		imageSrc: '../img/mobile.png'
	),
);
$mobile-origin: (
  total-width: 376px,
  total-height: 314px,
  imageSrc: '../img/mobile.png'
);

@mixin sprite-mobile-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-mobile-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-mobile-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-mobile {
  @include sprite-mobile-size($mobile-origin);
  @include sprite-mobile-image($mobile-origin);
}

@mixin sp-mobile($image, $size: true) {
  @include set-mobile;
	background-position: math.div(map-get(map-get($mobile-vars, $image), 'offset-x'), 2)
	math.div(map-get(map-get($mobile-vars, $image), 'offset-y'), 2);
	@if $size {
		width: math.div(map-get(map-get($mobile-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($mobile-vars, $image), 'height'), 2);
	}
}
