/* tech-common */
.tech {
  color: $color-white;

  .container {
    padding-top: 0;
    background-color: #060505;
  }

  .content01 {
    padding-top: 70px;
    background-color: #111;
  }

  .card__sub {
    &-wrap {
      padding: 63px 0 117px;
    }

    &.is-scroll-view {
      h4 {
        color: white;
      }

      .sub__txt {
        .tit-wrap {
          color: white;
        }
      }
    }

    .sub__txt {
      .news-date {
        color: #6e6e73;
      }
    }

  }

  .cover {
    position: sticky;
    top: 0;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .5);
    }
  }

  .section__visual {
    overflow: hidden;

    .cont {
      &__img {
        height: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      &__text {
        text-align: center;

        .txt, .em {
          font-size: 48px;
          line-height: 1.58;
          letter-spacing: -1.5px;
        }

        .txt {
          display: inline;
          color: rgba(255, 255, 255, 0.2);
          opacity: 1;
        }

        .em {
          color: white;
        }
      }
    }
  }

  .cont__inner {
    padding: 0 30px;
    text-align: left;
  }

  .full-screen {
    .cont__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  .full-sticky {
    overflow: hidden;
    position: relative;
    z-index: 1;
    width: 100%;
    margin-top: -100vh;

    .cont {
      &__wrap {
        z-index: 2;
        width: 100%;
      }

      &__inner {
        min-height: 100vh;
        margin: 0 auto;
        padding: 0;
      }

      &__main {
        max-width: 1920px;
        padding-left: 20.88%;
        padding-right: 10.83%;
      }

      &__title {
        padding: 0;
        font-weight: 800;
        font-size: 64px;
        line-height: 1.38;
        color: white;
      }

      &__desc {
        // padding-left: 48.93%;
        padding-left: 49.93%;
        margin-top: 80px;
        font-weight: 700;
        font-size: 22px;
        letter-spacing: -.6px;
        line-height: 1.75;
        color: white;
      }
    }
  }

  .js-nav-section {
    max-width: 1800px;
    margin: 0 auto;
    padding: 45px 0 155px 342px;

    &.full-screen {
      margin: 0;
      padding: 0;
      max-width: none;
      height: auto;
    }
  }

  .section__title,
  .section__visual {
    .cont__title {
      color: #6e6e73;

      .em {
        color: white;
      }
    }
  }

  .section__outlink {
    position: relative;
    width: 960px;
    height: 120px;
    padding: 0 48px;
    background: $color-default;
    font-size: 22px;
    font-weight: 700;
    color: white;
    line-height: 120px;

    &:hover {
      &:after {
        width: 100%;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #ff5f00;
      transition: width .5s ease;
      content: '';
    }

    .txt {
      display: inline-block;
      position: relative;
      padding-right: 34px;
      z-index: 1;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 24px;
        height: 24px;
        background: url(/img/common/ic-outlink-white2.png) no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
      }
    }

    &-wrap {
      display: flex;
      justify-content: flex-end;
      padding-top: 140px;
    }
      
    &-en {
      display: none;
    }
    
    @at-root .tech.lang-en {
      .section__outlink {
        &-ko {
          display: none;
        }
        &-en {
          display: block;
        }
      }
    }
  }

  .text-box {
    .text {
      &-bullet {
        padding-left: 10px;
        .bul {
          display: inline-block;
          text-indent: -10px;
        }
      }
    }
  }

  .pagination {
    &__area {
      background-color: transparent;
    }

    .page-prev, .page-next {
      background: #424245 url(/img/common/ic-pagination-arrow-black.png) no-repeat center;
      background-size: contain;

      &:hover {
        background-color: #1d1d1f;
        background-image: url(/img/common/ic-pagination-arrow-black-hover.png);
      }
    }

    .page-num-wrap {
      color: $color-white;
    }

    .page-blind {
      color: #424245;
    }

    .page-link {
      &.is-active {
        color: $color-white;
      }

      &:hover {
        background-color: #1d1d1f;
      }
    }
  }

  @keyframes showMap {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes underToTop {
    from {
      top: -45px;
      opacity: 0;
    }

    to {
      top: -54px;
      opacity: 1;
    }
  }

  @keyframes underToTop-pc {
    from {
      top: -37px;
      opacity: 0;
    }

    to {
      top: -47px;
      opacity: 1;
    }
  }

  @keyframes underToTop-tab {
    from {
      top: -30px;
      opacity: 0;
    }

    to {
      top: -40px;
      opacity: 1;
    }
  }

  @keyframes underToTop-tab-sm {
    from {
      top: -20px;
      opacity: 0;
    }

    to {
      top: -30px;
      opacity: 1;
    }
  }
}

@include breakpoint(pc) {

  /* tech-common */
  .tech {
    .js-nav-section {
      max-width: 1520px;
      padding: 45px 0 135px 286px;
    }

    .full-sticky {
      .cont {
        &__main {
          padding-left: 20.5%;
          padding-right: 13%;
        }

        &__desc {
          // padding-left: 45.3%;
          padding-left: 38.3%;
        }
      }
    }

    .section__outlink {
      width: 790px;
      height: 100px;
      padding: 0 40px;
      font-size: 18px;
      line-height: 100px;

      .txt {
        padding-right: 30px;
      }
    }
  }
}

@include breakpoint(tablet) {

  /* tech-common */
  .tech {
    .section__visual {
      .btn-arrow {
        bottom: 40px;
        @include sp-desktop('ic-arrow-primary-w');
      }
    }

    .js-nav-section {
      max-width: none;
      margin: 0;
      padding: 120px 30px 0;
    }

    .full-sticky {
      .cont {
        &__main {
          padding-left: 11.03%;
          padding-right: 2.92%;
        }

        &__desc {
          padding-left: 37.11%;
        }
      }
    }

    .card__sub-wrap {
      padding: 60px 0 70px;
    }

    .section__outlink {
      width: 512px;
      height: 90px;
      line-height: 90px;

      @at-root .navercorp.tech.lang-en {
        .section__outlink {
          width: 100%;
          max-width: 600px;
        }
      }

      &-wrap {
        padding-top: 120px;
      }
    }
  }
}

@include breakpoint(tablet-small) {

  /* tech-common */
  .tech {
    .content01 {
      padding-top: 64px;
    }

    .section__visual {
      .full-video {
        .cont__wrap {
          padding: 0 92px;
        }
      }
    }

    .full-sticky {
      .cont {
        &__main {
          padding-left: 7.81%;
          padding-right: 3.9%;
        }

        &__title {
          font-size: 42px;
        }

        &__desc {
          margin-top: 60px;
          padding-left: 39.82%;
          font-size: 18px;
        }
      }
    }

    .section__outlink {
      width: 384px;
      height: 68px;
      padding: 0 24px;
      font-size: 16px;
      line-height: 68px;

      .txt {
        padding-right: 24px;
        display: flex;

        &:before {
          width: 20px;
          height: 20px;
        }
      }

      &-wrap {
        padding-top: 100px;
      }
    }

    .text-box {
      .text {
        &-bullet {
          padding-left: 8px;
          .bul {
            text-indent: -8px;
          }
        }
      }
    }
    
  }
}

@include breakpoint(mobile) {

  /* tech-common */
  .tech {
    .cont__inner {
      padding: 0 20px;
    }

    .section__visual {
      .full-video {
        padding-top: 216.53%;

        .cont__wrap {
          padding: 0 30px;
        }
      }
    }

    .js-nav-section {
      padding: 85px 20px 15px 20px;
    }

    .full-sticky {
      .cont {
        &__main {
          padding: 0;
        }

        &__wrap {
          padding: 0 20px;
        }

        &__title {
          font-size: 36px;
          letter-spacing: -1.8px;
        }

        &__desc {
          margin-top: 40px;
          padding-left: 0;
          font-size: 16px;
        }
      }
    }

    .card__sub-wrap {
      padding-top: 36px;
    }

    .section__outlink {
      width: 100%;
      height: 66px;
      padding: 10px 20px;
      line-height: 1.4;
      position: relative;

      .txt {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
      }

      &-wrap {
        padding-top: 40px;
      }
    }
  }
}
