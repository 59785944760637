.tab {
  &__area {
    border-bottom: 1px solid var(--buttonType3Border);
  }

  &__list {
    max-width: 1800px;
    margin: 0 auto -1px;
    padding-left: 460px;
    font-size: 0;
  }

  &__cta {
    margin-right: 40px;
    padding: 20px 0 23px;
    font-size: 14px;
    font-weight: 800;
    color: var(--tab-cta);
    transition: color ease-in-out .1s;

    &.is-active{
      color: $color-point;
      .count.is-active{
      color: $color-point;
      }
    }

    .count {
      color: var(--tab-cta);
    }

    &:hover {
      color: var(--color-sub);
    }

    &:last-child {
      margin-right: 0;
    }

    &.#{$activeClass} {
      position: relative;
      color: var(--default);
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #111;
      }

      @at-root .theme-dark &::after {
        border-bottom: 1px solid #a1a1a6;
      }

      .count {
        &.#{$activeClass} {
          color: $color-point;
        }
      }
    }

    @at-root .navercorp.lang-en & {
      &.kor {
        display: none;
      }
    }
  }

  &__panels {
    max-width: 1800px;
    margin: 0 auto;
    padding-left: 460px;
  }

  &__panel {
    display: none;

    &.#{$activeClass} {
      display: block;
    }
  }
}

@include breakpoint(pc) {
  .tab {
    &__list {
      max-width: 1520px;
      padding-left: 386px;
    }

    &__panels {
      max-width: 1520px;
      padding-left: 386px;
    }
  }
}

@include breakpoint(tablet) {
  .tab {
    &__list {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding: 0 30px;
      &::-webkit-scrollbar{
        display: none;
        }
    }

    &__cta {
      padding: 17px 0 18px;
    }

    &__panels {
      max-width: none;
      padding: 0 30px;
    }
  }
}

@include breakpoint(mobile) {
  .tab {
    &__list {
      padding: 0 20px;
    }

    &__cta {
      margin-right: 29px;
      margin-bottom: 0;
    }

    &__panels {
      padding: 0 20px;
    }
  }
}
