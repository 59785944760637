.#{$component-name} {
  .esg-strategy-popup {
    .dimmed {
      background-color: rgba(0,0,0,.7);
    }

    .layer-content {
      align-items: unset;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  
      &__box {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 830px;
        margin: 70px auto;
      }
    }

    .layer-title {
      padding: 63px 60px 50px;

      .title {
        font-size: 38px;
        font-weight: 800;
        line-height: 1.42;
        letter-spacing: -1.5px;
        
        @at-root .theme-dark & {
          color: var(--default);
        }
      }
    }

    .layer-text {
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
      padding: 0 40px 0 60px;

      &__inner {
        margin-bottom: 70px;
      }

      &::-webkit-scrollbar {
        width: 20px;  
      }

      &::-webkit-scrollbar-thumb {
        border: 6px solid transparent;
        background: #f1f1f1;
        background-clip: padding-box;
      }

      &__box {
        & + .layer-text__box {
          margin-top: 52px;
          padding-top: 50px;
          border-top: 1px solid #e4e6ea;

          @at-root .theme-dark & {
            border-color: #2f2e30;
          }
        }

        .strategy {
          &__title {
            font-size: 22px;
            font-weight: 800;
            line-height: 1.55;
            letter-spacing: -0.5px;

            &.green {
              color: #00b2a2;

              @at-root .theme-dark & {
                color: #53cdc2;
              }
            }

            &.blue {
              color: #006eff;

              @at-root .theme-dark & {
                color: #559dff;
              }
            }

            &.purple {
              color: #6d26fc;

              @at-root .theme-dark & {
                color: #9e6fff;
              }
            }
          }

          &__list {
            margin-top: 20px;

            li {
              position: relative;
              padding-left: 11px;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.75;
              letter-spacing: -0.3px;
              opacity: 0.8;

              @at-root .theme-dark & {
                color: var(--default);
              }

              & + li {
                margin-top: 6px;
              }

              &:before {
                display: block;
                position: absolute;
                top: -3px;
                left: 0;
                opacity: 0.8;
                content: '∙';
              }
            }
          }

          &__outlink {
            margin: 8px 0 0 -32px;

            .ir-common__button {
              font-weight: 800;
              letter-spacing:-0.3px;
              margin: 24px 0 0 32px;

              &.type-kor {
                display: inline-flex;

                @at-root html[lang='en'] & {
                  display: none;
                }
              }

              &.type-eng {
                display: none;

                @at-root html[lang='en'] & {
                  display: inline-flex;
                }
              }
            }
          }
        }
      }
    }

    .btn-close {
      display: block;
      position: absolute;
      top: 22px;
      right: 22px;
      width: 24px;
      height: 24px;

      &::after {
        display: block;
        width: 100%;
        height: 100%;
        background: url(../img/common/ic-close-popup-black.png) no-repeat center;
        background-size: contain;
        content: '';
        @at-root .theme-dark & {
          display: block;
          width: 100%;
          height: 100%;
          background: url(../img/common/ic-close-popup-w.png) no-repeat center;
          background-size: contain;
          content: '';
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .esg-strategy-popup {
      .layer-content__box {
        max-width: none;
        margin: 0;
      }

      .layer-title {
        padding: 62px 36px 50px;
      }

      .layer-text {
        padding: 0 16px 0 36px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .esg-strategy-popup {
      .layer-title {
        padding: 50px 20px 40px;

        .title {
          font-size: 32px;
          line-height: 1.5;
          letter-spacing: -0.8px;
        }
      }

      .layer-text {
        padding: 0 20px;

        &::-webkit-scrollbar {
          width: 0;
        }

        &__box {
          & + .layer-text__box {
            margin-top: 42px;
            padding-top: 40px;
          }
  
          .strategy {
            &__title {
              font-size: 20px;
              line-height: 1.6;
            }
  
            &__list {  
              li {
                font-size: 14px;
                line-height: 1.71;

                &:before {
                  top: 0;
                }
              }
            }
  
            &__outlink {
              margin: 21px 0 0 0;
  
              .ir-common__button {
                display: block;
                margin: 24px 0 0 0;

                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }

      .btn-close {
        top: 20px;
        right: 20px;
      }
    }
  }
}
