.#{$component-name} {
  &.lang-en {
    .media-checkbox {
      .data-text {
        display: inline-block;
      }
    }
  }

  .media-checkbox {
    font-size: 0;

    .data-text {
      display: none;
      margin-right: 12px;
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.3px;
      vertical-align: middle;
      pointer-events: none;
      color: var(--default);
    }

    input {
      width: 24px;
      height: 24px;
      border: 1px solid #ccc;
      vertical-align: middle;
      cursor: pointer;

      &:hover {
        border-color: var(--numBg);
      }

      &:checked {
        background: url(/img/common/ic-media-checkbox.png) no-repeat center;
        background-size: contain;
        border: none;
      }
    }

    label {
      display: inline-block;
      margin-left: 12px;
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.3px;
      vertical-align: middle;
      pointer-events: none;
      color: var(--default);
    }

    &.is-checked {
      &+.media-download {
        color: var(--default);
        cursor: pointer;

        &::after {
          background: var(--ic-media-download) no-repeat center;
          background-size: contain;
        }

        &:hover {
          color: var(--default);
          border-color: var(--border04);
    
          &::after {
            background: var(--ic-media-download) no-repeat center;
            background-size: contain;
          }
        }
      }
    }
  }

  .media-download {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 220px;
    height: 54px;
    padding: 0 16px 0 20px;
    border: 1px solid var(--border01);
    color: var(--halfNum);
    font-size: 14px;
    font-weight: 700;
    transition: all .3s;
    cursor: default;

    &::after {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: var(--ic-media-download02) no-repeat center;
      background-size: contain;
      transition: background .3s;
      content: "";
    }
  }

  &.media {
    .section__title {
      font-size: 36px;
      font-weight: 800;
      line-height: 54px;
      letter-spacing: -.01px;
    }

    .ir-list-layout .section {
      padding-left: 455px;
    }
  }

  &.media-brand-guide {
    .tab__area {
      margin-bottom: 55px;
    }
  }

  .section-brand-guide {
    .js-nav-section {
      padding-left: 0;
    }

    .section {
      &__box {
        padding: 45px 0 140px;
        margin-bottom: 15px;
        border-bottom: 1px solid var(--border05);
        font-weight: 700;

        &:first-child {
          .section__head {
            flex-direction: column;
          }

          .section__desc {
            padding-right: 22px;
          }
        }

        &:nth-of-type(3) {
          .section__desc {
            padding-right: 50px;
          }

          .section__img {
            width: calc(50% - 10px);
            padding-top: 32.789%;

            &:nth-of-type(n+3) {
              margin-top: 20px;
            }

            &-wrap {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-top: 34px;
            }
          }
        }

        &:nth-of-type(4) {
          .section__desc {
            padding-right: 195px;
          }
        }

        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
      }

      &__head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &__text {
        display: inline-block;
        width: 40%;
        margin-top: 24px;
        color: #bbbbbe;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: -.8px;
      }

      &__desc {
        width: 49%;
        margin-top: 3px;
        font-size: 18px;
        line-height: 34px;
        letter-spacing: -.5px;
        color: var(--inputColor);
      }

      &__img {
        padding-top: 42.305%;
        margin-top: 70px;

        &+.section__desc {
          float: right;
          margin: 70px 0;
          color: var(--color-sub);

          &+.section__img {
            clear: both;
          }
        }
      }

      &__data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      }
    }

    .brand-img-1 {
      background: url(/img/pc/media-naverbrand-1.png) no-repeat center;
      background-size: cover;
    }

    .brand-img-2 {
      background: url(/img/pc/media-naverbrand-2.png) no-repeat center;
      background-size: cover;
    }

    .brand-img-3 {
      background: url(/img/pc/media-clearspace.png) no-repeat center;
      background-size: cover;
    }

    .brand-img-4 {
      background: url(/img/pc/media-logo-usage-1.png) no-repeat center;
      background-size: cover;
    }

    .brand-img-5 {
      background: url(/img/pc/media-logo-usage-2.png) no-repeat center;
      background-size: cover;
    }

    .brand-img-6 {
      background: url(/img/pc/media-logo-usage-3.png) no-repeat center;
      background-size: cover;
    }

    .brand-img-7 {
      background: url(/img/pc/media-logo-usage-4.png) no-repeat center;
      background-size: cover;
    }

    .brand-img-8 {
      background: url(/img/pc/media-notice-1.png) no-repeat center;
      background-size: cover;
    }

    .brand-img-9 {
      background: url(/img/pc/media-notice-2.png) no-repeat center;
      background-size: cover;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.media {
      .ir-list-layout .section {
        padding-left: 385px;
      }
    }

    .section-brand-guide {
      .section {
        &__box {
          &:first-child {
            .section__desc {
              padding-right: 60px;
            }
          }

          &:nth-of-type(2) {
            .section__desc {
              padding-right: 12px;
            }
          }

          &:nth-of-type(3) {
            .section__img {
              &-wrap {
                margin-top: 0;
              }
            }
          }

          &:nth-of-type(4) {
            .section__desc {
              padding-right: 100px;
            }
          }
        }

        &__text {
          width: 49%;
        }

        &__img {
          &+.section__desc {
            margin: 60px 0 70px;
          }
        }

        &__data {
          margin-top: 20px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .media-checkbox {
      input {
        width: 20px;
        height: 20px;
      }

      .data-text, label {
        font-size: 14px;
        line-height: 26px;
      }
    }

    &.media {
      .section__title {
        font-size: 30px;
        line-height: 48px;
      }

      .ir-list-layout .section {
        padding: 0 30px;
      }
    }

    &.media-brand-guide .tab__area {
      margin-bottom: 0;
    }

    .section-brand-guide {
      .section {
        &__box {
          padding: 120px 0 100px;
          margin-bottom: 0;

          &:first-child {
            padding-top: 100px;

            .section__desc {
              padding-right: 30px;
            }
          }

          &:nth-of-type(4) {
            .section__desc {
              padding-right: 70px;
            }
          }
        }

        &__text {
          margin-top: 16px;
          font-size: 22px;
          line-height: 34px;
          letter-spacing: -.6px;
        }

        &__desc {
          font-size: 16px;
          line-height: 28px;
        }

        &__img {
          margin-top: 60px;

          &+.section__desc {
            margin: 50px 0 60px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .media-checkbox {
      .data-text, label {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0;
      }
    }

    .media-download {
      width: 160px;
      height: 40px;
      padding: 0 13px 0 20px;
      font-size: 12px;

      &::after {
        width: 20px;
        height: 20px;
      }
    }

    &.media {
      .section__title {
        font-size: 24px;
        line-height: 38px;
      }
    }

    .section-brand-guide {
      .section {
        &__box {
          &:first-child {
            padding-top: 60px;

            .section__desc {
              padding-right: 15px;
            }
          }

          &:nth-of-type(3) {
            .section__desc {
              padding-right: 20px;
            }

            .section__img {
              width: calc(50% - 6px);

              &:nth-of-type(n+3) {
                margin-top: 12px;
              }
            }
          }

          &:nth-of-type(4) {
            .section__desc {
              padding-right: 20px;
            }
          }
        }

        &__text {
          font-size: 16px;
          line-height: 30px;
          letter-spacing: -.5px;
        }

        &__desc {
          font-size: 14px;
          line-height: 26px;
          letter-spacing: -.3px;
        }

        &__img {
          &+.section__desc {
            margin: 31px 0 60px;
          }
        }

        &__data {
          margin-top: 16px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.media-brand-guide {
      .container {
        padding-bottom: 80px;
      }
    }
    .media-checkbox {
      input {
        vertical-align: top;
      }

      label {
        margin-top: -2px;
        vertical-align: baseline;
      }
    }

    .media-download {
      margin-top: 32px;
    }

    &.media {
      .ir-list-layout .section {
        padding: 0 20px;
      }
    }

    .section-brand-guide {
      .section {
        &__box {
          padding: 50px 0 80px;

          &:first-child {
            .section__desc {
              padding-right: 0;
            }
          }

          &:nth-of-type(2) {
            .section__desc {
              padding-right: 0;
            }
          }

          &:nth-of-type(3) {
            .section__desc {
              padding-right: 0;
            }

            .section__img {
              width: 100%;
              padding-top: 66.57%;
              margin: 12px 0 0;

              &:first-child {
                margin-top: 60px;
              }
            }
          }

          &:nth-of-type(4) {
            .section__desc {
              padding-right: 0;
            }
          }
        }

        &__text {
          width: 100%;
        }

        &__desc {
          float: none;
          width: 100%;
          margin-top: 20px;
        }

        &__img {
          margin: 60px -20px 0;
          padding-top: 74.63%;
        }

        &__data {
          margin-top: 20px;
          align-items: flex-end;
        }
      }


      .brand-img-1 {
        background: url(/img/mo/media-naverbrand-1.png) no-repeat center;
        background-size: cover;
      }

      .brand-img-2 {
        background: url(/img/mo/media-naverbrand-2.png) no-repeat center;
        background-size: cover;
      }

      .brand-img-3 {
        padding-top: 102.986%;
        background: url(/img/mo/media-clearspace.png) no-repeat center;
        background-size: cover;
      }

      .brand-img-4 {
        background: url(/img/mo/media-logo-usage-1.png) no-repeat center;
        background-size: cover;
      }

      .brand-img-5 {
        background: url(/img/mo/media-logo-usage-2.png) no-repeat center;
        background-size: cover;
      }

      .brand-img-6 {
        background: url(/img/mo/media-logo-usage-3.png) no-repeat center;
        background-size: cover;
      }

      .brand-img-7 {
        background: url(/img/mo/media-logo-usage-4.png) no-repeat center;
        background-size: cover;
      }

      .brand-img-8 {
        background: url(/img/mo/media-notice-1.png) no-repeat center;
        background-size: cover;
      }

      .brand-img-9 {
        padding-top: 188.66%;
        background: url(/img/mo/media-notice-2.png) no-repeat center;
        background-size: cover;
      }
    }
  }
}
