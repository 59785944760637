.#{$component-name} {
  &.contents {
    .section__visual {
      background-image: url(/img/pc/service-contents-main.png);
    }

    .service__img-wrap {
      margin-top: 120px;
    }

    .service__text{
      max-width: 880px;
      margin: 120px auto;
    }

    .service__img {
      padding: 9px;
      border: 0;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 10px solid #2f2e30;
        border-radius: 50px;
        box-sizing: border-box;
        content: "";
      }
    }

    .service__bg {
      height: 87.164%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .webtoon {
      .service__bg {
        height: 87.807%;
        margin-top: -23px;
        background-image: url(/img/pc/service-contents-webtoon-bg.png);
      }
    }

    .series {
      .service__bg {
        background-image: url(/img/pc/service-contents-series-bg.png);
      }
    }

    .serieson {
      .service__bg {
        background-image: url(/img/pc/service-contents-serieson-bg.png);
      }
    }

    .clip {
      .service__bg {
        height: 86.77%;
        background-image: url(/img/pc/service-contents-clip-bg.png);
      }

      .service__img {
        padding: 0;
        border: 0;
        border-radius: 50px;

        &:after {
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          content: '';
        }

        video {
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: 100%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .now {
      .service__img-wrap {
        height: 1006px;
      }

      .service__img-box {
        position: absolute;
        top: 0;
        left: 50%;
        min-width: 1340px;
        height: 100%;
        transform: translateX(-50%);
      }

      .service__img {
        position: absolute;
        bottom: 0;
        width: 380px;
        height: 801px;
        background-color: #0e0e10;

        &:first-child {
          left: 0;
        }

        &:nth-child(2) {
          top: 0;
          bottom: auto;
          left: 50%;
          transform: translateX(-50%);
        }

        &:last-child {
          right: 0;
        }
      }

      .service__bg {
        top: 153px;
        height: 69.186%;
        background-image: url(/img/pc/service-contents-navertv-bg.png);
        transform: translate(-50%, 0);
      }
    }

    .vibe {
      .service__bg {
        height: 91.718%;
        margin-top: -15px;
        background-image: url(/img/pc/service-contents-vibe-bg.png);
      }
    }

    .snow {
      .service__bg {
        background-image: url(/img/pc/service-contents-snow-bg.png);
      }
    }

    .zepeto {
      .service__img {
        border: 0;
      }

      .service__bg {
        background-image: url(/img/pc/service-contents-zepeto-bg.png);
      }
    }

    .contents {
      &__zepeto {
        display: flex;
        justify-content: space-between;
        max-width: 1118px;
        margin: 120px auto 0;
        padding-top: 80px;
        border-top: 1px solid var(--border01);

        &-title {
          font-weight: 800;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
          text-align: left;
          color: var(--default);
        }

        &-img {
          display: flex;
        }

        .img-box {
          width: 322px;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.contents {
      .service__img-wrap {
        margin-top: 116px;
      }

      .service__bg {
        height: 87.626%;
      }

      .webtoon {
        .service__bg {
          height: 88.223%;
          margin-top: -17px;
        }
      }

      .now {
        .service__img-wrap {
          height: 832px;
        }

        .service__img-box {
          min-width: 1128px;
        }

        .service__bg {
          top: 128px;
          height: 69.712%;
        }

        .service__img {
          width: 316px;
          height: 666px;
        }
      }

      
      .vibe {
        .service__bg {
          height: 92.204%;
          margin-top: -10px;
        }
      }

      .contents {
        &__zepeto {
          max-width: 940px;
          margin-top: 100px;
          padding-top: 60px;

          .img-box {
            width: 260px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.contents {
      .service__img-wrap {
        margin-top: 90px;
      }

      .service__bg {
        height: 78.999%;
      }

      .service__img {
        padding: 7px;

        &:after {
          border-width: 7px;
          border-radius: 40px;
        }
      }

      .webtoon {
        .service__bg {
          height: 79.692%;
        }
      }

      .clip {
        .service__img {
          padding: 2px;
          border-radius: 40px;
        }

        .service__bg {
          height: 78.964%;
        }
      }

      .now {
        .service__img-wrap {
          height: 698px;
        }

        .service__img-box {
          min-width: 836px;
        }

        .service__bg {
          top: 138px;
          height: 60.173%;
        }

        .service__img {
          width: 252px;
          height: 531px;
        }
      }

      .vibe {
        .service__bg {
          height: 83.204%;
          margin-top: -12px;
        }
      }

      .contents {
        &__zepeto {
          max-width: none;
          margin: 80px 30px 0;
          padding-top: 60px;

          &-title {
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          .img-box {
            width: 226px;
            height: 226px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.contents {
      .service__img-wrap {
        margin-top: 60px;
      }

      .service__text{
        max-width: 720px;
        margin: 60px auto;
      }

      .service__bg {
        height: 83.895%;
      }

      .service__img {
        padding: 5px;

        &:after {
          border-width: 6px;
          border-radius: 26px;
        }
      }

      .webtoon {
        .service__bg {
          height: 84.534%;
          margin-top: -9px;
        }
      }

      .clip {
        .service__bg {
          height: 83.28%;
        }

        .service__img {
          padding: 0;
          border-radius: 26px;
        }
      }

      .now {
        .service__img-wrap {
          height: 518px;
        }

        .service__img-box {
          min-width: 622px;
        }

        .service__bg {
          top: 91px;
          height: 64.674%;
        }

        .service__img {
          width: 190px;
          height: 400px;
        }
      }

      .vibe {
        .service__bg {
          height: 88.54%;
          margin-top: -6px;
        }
      }

      .contents {
        &__zepeto {
          margin-top: 80px;
          padding-top: 40px;

          &-title {
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }

          .img-box {
            width: 180px;
            height: 180px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.contents {
      .service__bg {
        height: 84.085%;
      }

      .service__text {
        max-width: 335px;
      }

      .webtoon {
        .service__bg {
          height: 88.04%;
          margin-top: -10px;
          background-image: url(/img/mo/service-contents-webtoon-bg.png);
        }
      }

      .series {
        .service__bg {
          background-image: url(/img/mo/service-contents-series-bg.png);
        }
      }

      .serieson {
        .service__bg {
          height: 88.82%;
          background-image: url(/img/mo/service-contents-serieson-bg.png);
        }
      }

      .clip {
        .service__bg {
          background-image: url(/img/mo/service-contents-clip-bg.png);
        }

        .service__img{
          padding: 0;
          border-radius: 26px;
        }
      }

      .now {
        .service__img-wrap {
          height: 527px;
        }

        .service__img-box {
          max-width: 622px;
          min-width: 375px;
        }

        .service__bg {
          top: 86px;
          height: 67.365%;
          background-image: url(/img/mo/service-contents-navertv-bg.png);
        }

        .service__img {
          width: 200px;
          height: 421px;

          &:first-child {
            left: -132px;
          }

          &:last-child {
            right: -132px;
          }
        }
      }

      .vibe {
        .service__bg {
          height: 84.13%;
          margin-top: 0;
          background-image: url(/img/mo/service-contents-vibe-bg.png);
        }
      }

      .snow {
        .service__bg {
          background-image: url(/img/mo/service-contents-snow-bg.png);
        }
      }

      .zepeto {
        .service__bg {
          background-image: url(/img/mo/service-contents-zepeto-bg.png);
        }
      }

      .contents {
        &__zepeto {
          display: block;
          margin: 80px 20px 0;
          padding-top: 30px;

          &-title {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }

          &-img {
            margin-top: 40px;
          }

          .img-box {
            width: calc((100% - 11px) / 2);
            margin-right: 11px;
          }
        }
      }
    }
  }
}
