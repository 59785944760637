.#{$component-name} {
  &.research-detail {
    .container {
      padding: 0;
      margin-bottom: 100px;
    }

    .common {
      &__title {
        background-color: var(--black);
        color: $color-white;

        &-inner {
          padding: 192px 0 52px;
        }

        h2 {
          padding-top: 17px;
          font-size: 48px;
          line-height: 1.5;
          letter-spacing: -1.5px;
          color: $color-white;
        }

        span.research-detail__category {
          display: block;
          font-weight: 700;
          font-size: 14;
          line-height: 2;
          color: $color-white;
        }

        span.research-detail__date {
          display: block;
          padding-top: 78px;
          font-weight: 700;
          font-size: 14px;
          line-height: 2.14;
          color: $color-white;
        }
      }
    }

    .ir-common {
      &__button {
        &.top {
          position: absolute;
          top: 0;
          left: -460px;
        }

        &.is-show-mo {
          display: none;
        }
      }
    }

    .detail {
      font-size: 16px;
      line-height: 1.88;
      letter-spacing: -0.5px;

      &.is-old-editor {
        p, span {
          font-family: "NanumSquareNeo", sans-serif !important;
        }
        div, p, span, ul, li, a, th, td, h1, h2, h3, h4, h5, h6, button, strong, i {
          color: var(--default);
        }
      }

      &__title {
        padding-top: 80px;
        font-weight: 800;
        font-size: 22px;
        line-height: 1.64;
        letter-spacing: -0.6px;
        text-align: center;
      }

      &__date {
        padding-top: 30px;
        font-size: 16px;
        line-height: 1.88;
        letter-spacing: -0.5px;
        color: $color-gray3;
        text-align: center;
      }

      &__img {
        overflow: hidden;
        width: 100%;
        padding-top: 530px;
        background: no-repeat 50%;
        background-size: cover;

        &-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 12px auto 0;
        }

        &-txt {
          font-size: 10px;
          line-height: 1.6;
          color: #6e6e73;
          border-bottom: 1px solid #6e6e73;
        }

        &-btn {
          width: 24px;
          height: 24px;
          background: #f1f1f4 url(../img/common/ic-download-black.png) no-repeat center;
          background-size: contain;
          border-radius: 50%;
          transition: background .3s;

          &:hover {
            background: #ff5f00 url(../img/common/ic-download-white.png) no-repeat center;
            background-size: contain;
          }
        }
      }

      &__item {
        display: flex;
        padding-top: 30px;

        .num {
          display: block;
          margin-right: 5px;
        }

        &.question {
          padding-top: 60px;
        }

        &.answer {
          padding-top: 30px;
        }
      }
    }

    .research-detail {
      position: relative;
      max-width: 880px;
      padding-left: 0;
      margin: 80px auto 120px;

      &__btn {
        display: flex;
        justify-content: space-between;
        margin-top: 160px;
      }
    }

    .card__sub {
      max-width: 1800px;
      margin: 0 auto;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.research-detail {
      .container {
        margin-bottom: 80px;
      }

      .ir-common {
        &__button {
          &.top {
            left: -386px;
          }
        }
      }

      .research-detail {
        max-width: 750px;

        &__btn {
          margin-top: 130px;
        }
      }

      .card__sub {
        max-width: 1520px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.research-detail {
      .common {
        &__title {
          &-inner {
            max-width: 718px;
            padding: 183px 0 40px;
          }

          h2 {
            padding-top: 19px;
            font-size: 42px;
            line-height: 1.52;
            letter-spacing: -1.8px;
          }

          span.research-detail__category {
            font-size: 12px;
            line-height: 2.17;
          }

          span.research-detail__date {
            padding-top: 81px;
          }
        }
      }

      .ir-common {
        &__button {
          &.top {
            display: none;
          }
        }
      }

      .research-detail {
        max-width: 718px;
        margin: 80px auto 80px;

        &__button {
          margin-top: 120px;
        }
      }

      .card__sub {
        max-width: none;
        padding: 0 30px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.research-detail {
      .common {
        &__title {
          &-inner {
            max-width: none;
            padding: 183px 30px 40px;
          }

          h2 {
            padding-top: 17px;
            font-size: 38px;
            line-height: 1.53;
            letter-spacing: -1.5px;
          }

          span.research-detail__category {
            font-size: 14px;
            line-height: 2;
          }

          span.research-detail__date {
            padding-top: 71px;
          }
        }
      }

      .research-detail {
        max-width: none;
        margin: 60px auto 120px;
        padding: 0 30px;
      }

      .detail {
        font-size: 14px;
        line-height: 1.86;
        letter-spacing: -0.3px;

        &__title {
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }

        &__below {
          padding: 40px 0;

          &-txt {
            margin-right: 66px;
          }
        }
      }

      .research-detail {
        &__btn {
          margin-top: 100px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.research-detail {
      .common {
        &__title {
          &-inner {
            padding: 183px 20px 50px
          }

          h2 {
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
          }

          span.research-detail__date {
            padding-top: 69px;
          }
        }
      }

      .ir-common {
        &__button {
          &.list {
            display: none;
          }

          &.is-show-mo {
            display: flex;
            float: right;
            margin-top: 30px;
            margin-right: 20px;

            &:after {
              display: block;
              content: '';
              clear: both;
            }
          }
        }
      }

      .research-detail {
        margin: 60px auto 40px;
        padding: 0 20px;
      }

      .research-detail {
        &__btn {
          margin-top: 100px;
        }

        &__list {
          margin: 40px auto 0;
        }

        &__link {
          flex-direction: column-reverse;
          align-items: start;
          position: relative;
          padding: 20px 0;
        }

        &__item {
          &-text {
            min-width: auto;
            max-width: 236px;
          }

          &-date {
            margin-top: 10px;
          }

          .quick-link__button {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }

      .card__sub {
        padding: 0 20px;

        .sub__txt {
          .tit-wrap {
            display: flex;
            flex-direction: column;

            .news-tit {
              margin-top: 0;
            }

            .category {
              order: 2;
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
}
