.#{$component-name} {
  &.story-detail {
  
    .container {
      padding-bottom: 0;
    }

    .section__title {
      font-weight: 800;
      font-size: 42px;
      line-height: 1.52;
      letter-spacing: -1.8px;
    }
    
    .search-result__text {
      padding: 65px 0 63px;
    }

    .story-detail-area {
      position: relative;
      max-width: 750px;
      margin: 0 auto;
      padding: 100px 0 0;
      line-height: 1.88;
      word-break: break-all;

      .one-viewer img {
        height: auto !important;
      }

      a {
        word-break: break-all;
      }

      .report_content_video {
        width: 1000px;
        height: 560px;
        margin: 50px 0 50px -125px;

        &:first-child {
          margin-top: 0;
        }
        
        .video {
          height: 100%;
        }
      }
    }

    .card-contents {
      margin-top: -10px;

      &__item {
        width: 25%;
        margin-top: 0;

        &-inner {
          width: calc(100% - 20px);
          margin: 10px;
        }
      }


      &__img {
        padding-top: 66.75%;
        background: no-repeat center;
        background-size: cover;
      }

      &__txt {
        .title {
          margin-top: 14px;
          line-height: 30px;
          letter-spacing: -.5px;
        }
      }
    }

    .story-detail {
      &__title {
        &-inner {
          max-width: 760px;
          margin: 0 auto;
          padding: 78px 0 57px;
          font-weight: 800;
          text-align: center;
        }

        h2 {
          // @include multi-ellipsis(2);
          margin-top: 16px;
          font-weight: 800;
          font-size: 48px;
          line-height: 1.42;
          letter-spacing: -1.5px;
          color: var(--default);
        }

        &-category, &-date {
          display: block;
          font-size: 14px;
          line-height: 2;
          color: var(--default);
        }

        &-date {
          font-weight: 700;
          margin-top: 83px;
        }
      }

      &__inner {
        max-width: 750px;
        margin: 0 auto;
      }

      &__main {
        max-width: 1920px;
        height: 840px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &__sub-title {
        font-weight: 800;
        font-size: 26px;
        line-height: 1.46;
        letter-spacing: -0.8px;
        color: var(--default);
      }

      &__img-text {
        margin-top: 16px;
        font-weight: 700;
      }

      &__section {
        &.section-info {
          max-width: 1006px;
          margin: 100px auto 0;

          .story-detail {
            &__sub-title {
              margin-top: 20px;
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.6px;
              color: var(--default);
            }

            &__text {
              margin-top: 80px;
            }

            &__img-text {
              max-width: 544px;
            }
          }
        }

        &.section-interview {
          max-width: 1600px;
          margin: 120px auto 0;

          .story-detail {
            &__text {
              margin-top: 80px;
            }
          }
        }

        &.section-slide {
          margin: 92px auto 0;
        }

        &.section-engine {
          margin: 100px auto 0;

          .story-detail {
            &__text {
              margin-top: 24px;

              &-box {
                &:last-child {
                  margin-top: 40px;
                }
              }
            }
          }
        }

        &.section-association {
          overflow: hidden;
          margin-top: 160px;
          padding: 0 0 80px;

          .section-more-list {
            max-width: 1920px;
            margin: 0 auto;
            padding: 0 60px;
          }

          .story-detail {
            &__sub-title {
              max-width: 1800px;
              margin: 0 auto;
            }
          }
        }
      }

      &__info-img {
        width: 1006px;
        height: 567px;
        margin-top: 100px;
        background: url(../img/pc/story-detail-img02.jpg) no-repeat center;
        background-size: contain;
      }

      &__text {
        font-size: 16px;
        line-height: 1.88;
        letter-spacing: -0.5px;
        color: var(--box-text);

        &-box {
          margin-top: 30px;

          &:first-child {
            margin-top: 0;
          }
        }

        &-point {
          color: #336dff;
        }

        &-dot {
          margin-right: 7px;
        }
      }

      &__img-text {
        margin-top: 16px;
        font-size: 10px;
        line-height: 1.6;
        color: #6e6e73;
      }

      &__quote {
        display: flex;
        margin-top: 66px;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.67;
        letter-spacing: -0.5px;
        color: var(--default);

        &:before {
          display: inline-block;
          width: 1px;
          height: 60px;
          margin-right: 22px;
          background-color: $color-gray4;
          content: '';
        }
      }

      &__introduction {
        &-area {
          display: flex;
          justify-content: center;
          background-color: var(--bgColor02);
        }

        &-box {
          max-width: 800px;
          padding: 158px 139px 30px 168px;
        }

        &-img {
          width: 800px;
          height: 900px;
          background: url(../img/pc/story-detail-img03.jpg) no-repeat center center;
          background-size: cover;
        }
      }

      &__interview {
        margin-top: 100px;

        &-list {
          margin-top: 45px;
        }

        &-item {
          margin-top: 55px;

          &:first-child {
            margin-top: 0;
          }
        }

        &-name {
          font-weight: 800;
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
          color: var(--default);
        }

        &-position {
          font-weight: 700;
          font-size: 12px;
          line-height: 1.83;
          color: $color-gray2;
        }

        &-text {
          margin-top: 10px;
          font-size: 16px;
          line-height: 1.88;
          letter-spacing: -0.5px;
          color: var(--storyDetailTxt);
        }
      }

      &__slide {
        overflow: hidden;
        position: relative;
        padding-bottom: 40px;

        .swiper {
          &-pagination {
            display: flex;
            justify-content: center;
            margin-top: 32px;

            &-bullet {
              display: block;
              width: 8px;
              height: 8px;
              margin: 0 12px 0 0;
              border-radius: 50%;
              background-color: var(--pagination-bullet);
              text-indent: -9999px;
              opacity: 1;

              &:last-child {
                margin: 0;
              }

              &-active {
                background-color: var(--default);
              }
            }
          }
        }

        &-list {
          display: flex;
          margin: 0 auto;
        }

        &-item {
          width: 1006px;
        }

        &-pagination {
          display: flex;
          bottom: 0;
          left: -350px;

          span {
            display: block;
            width: 8px;
            height: 8px;
            margin-right: 12px;
            border-radius: 50%;
            background-color: #cececf;

            &:last-child {
              margin-right: 0;
            }

            &.#{$activeClass} {
              background-color: $color-default;
            }
          }
        }

        &-navigation {
          display: flex;
          justify-content: space-between;
          position: absolute;
          top: 318px;
          left: 50%;
          width: 1006px;
          transform: translateX(-50%);
          z-index: 1;
        }

        &-btn {
          width: 36px;
          height: 36px;
          background: url(../img/common/btn-square-prev.png) no-repeat center center;
          background-size: contain;
          transition: all .3s;

          &.btn-next {
            transform: rotate(180deg);
          }

          &:hover {
            background: url(../img/common/btn-square-prev-on.png) no-repeat center center;
            background-size: contain;
          }
        }
      }

      &__engine-img {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1006px;
        margin: 80px auto 0;

        &-box {
          max-width: 500px;
        }
      }

      &__point {
        margin-top: 100px;

        &-list {
          margin-top: 60px;
          border-top: 1px solid var(--border01);
        }

        &-item {
          display: flex;
          margin-top: 10px;
          font-size: 10px;
          line-height: 1.6;
          color: $color-gray2;

          &:first-child {
            margin-top: 26px;
          }

          .num {
            margin-right: 10px;
          }
        }
      }

      &__btn {
        margin-right: 6px;
        padding: 5px 14px;
        background-color: var(--storyCategory02);
        font-weight: 800;
        font-size: 14px;
        line-height: 1.86;
        letter-spacing: -0.3px;
        transition: all .3s;

        &:last-child {
          margin-right: 0;
        }

        &-box {
          display: flex;
        }

        &.is-show-mo {
          display: none;
        }

        &:hover {
          color: $color-point;
        }

        &:focus {
          background-color: $color-point;
          color: $color-white;
        }
      }
    }
  }

  @at-root .theme-dark &.story-detail {
    .is-old-editor {
      p, span {
        font-family: "NanumSquareNeo", sans-serif !important;
      }
      div, p, span, ul, li, a, th, td, h1, h2, h3, h4, h5, h6, button, strong, i {
        color: $color-white;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.story-detail {
      .card-contents__img {
        padding-top: 66.579%;
      }

      .story-detail {
        &__main {
          height: 700px;
        }

        &__section {
          &.section-info {
            .story-detail {
              &__sub-title {
                margin: 12px auto 0;
              }
            }
          }

          &.section-association {
            margin-top: 120px;
            padding: 0 0 80px;

           .section-more-list {
              padding: 0 40px;
            } 

            .story-detail {
              &__sub-title {
                max-width: 1520px;
                font-size: 20px;
                line-height: 1.7;
                letter-spacing: -0.01px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.story-detail {

      .story-detail__section{

        &.section-association {

          .section-more-list{
            padding: 0 30px;
          }
        }
      }

      .story-detail-area {
        max-width: 620px;
        padding: 86px 0 0;
  
        p{
          @at-root .theme-dark & {
            color: #a1a1a6;
          }
        }

        img {
          left: 50%;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
        }
  
        .report_content_video {
          width: 717px;
          height: 422px;
          margin: 50px 0 50px -48px;
  
          &:first-child {
            margin-top: 0;
          }
        }
      }
      
      .card-contents {
        margin: -8px;

        &__item {
          width: 33.333%;

          &-inner {
            width: calc(100% - 16px);
            margin: 8px;
          }
        }
      }

      .section__title {
        font-size: 38px;
        line-height: 1.42;
        letter-spacing: -1.5px;
      }

      .story-detail {
        &__title {
          &-inner {
            max-width: 718px;
            padding: 62px 0 57px;
          }

          h2 {
            display: block;
            margin-top: 12px;
            font-size: 42px;
            line-height: 1.52;
            letter-spacing: -1.8px;
          }

          &-date {
            margin-top: 61px;
          }
        }

        &__sub-title {
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
        }

        &__inner {
          max-width: 620px;
        }

        &__main {
          height: 448px;
        }

        &__section {
          &.section-info {
            max-width: 717px;
            margin: 86px auto 0;

            .story-detail {
              &__sub-title {
                margin-top: 20px;
              }

              &__img-text {
                max-width: 465px;
              }
            }
          }

          &.section-interview {
            .story-detail {
              &__text {
                margin-top: 40px;
                font-size: 14px;
                line-height: 1.86;
                letter-spacing: -0.3px;
              }

              &__introduction {
                .story-detail__sub-title {
                  font-size: 18px;
                  line-height: 1.67;
                  letter-spacing: -0.5px;
                }
              }
            }
          }

          &.section-slide {
            margin-top: 160px;
          }

          &.section-engine {
            max-width: 717px;
          }

          &.section-association {
            margin-top: 100px;

            .story-detail {
              &__sub-title {
                padding-left: 30px;
                font-size: 22px;
                line-height: 1.55;
                letter-spacing: -0.01px;
              }
            }
          }
        }

        &__info-img {
          width: 717px;
          height: 422px;
          background: url(../img/pc/story-detail-img02-tab.jpg) no-repeat center;
          background-size: contain;
        }

        &__introduction {
          &-box {
            max-width: 511px;
            padding: 80px 80px 30px 79px
          }

          &-img {
            width: 512px;
            height: 702px;
          }
        }

        &__interview {
          &-item {
            margin-top: 85px;
          }
        }

        &__slide {
          &-item {
            width: 620px;
          }

          &-navigation {
            top: 190px;
            max-width: 620px;
          }

          &-pagination {
            left: -286px;
          }
        }

        &__engine {
          &-img {
            width: 717px;

            &-box {
              max-width: 356px;
            }
          }
        }

        &__point {
          margin-top: 80px;

          &-item {
            margin-top: 26px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.story-detail {
      .scroll-title {
        &__list {
          padding-top: 58px;
        }

        &__item-inner {
          width: calc(100% - 10px);
          margin: 14px 5px;
        }
      }

      .story-detail-area {
        max-width: 450px;
        padding: 93px 0 0;
  
        img {
          height: auto !important;
        }
  
        .report_content_video {
          width: 568px;
          height: 334px;
          margin: 50px 0 50px -59px;
  
          &:first-child {
            margin-top: 0;
          }
        }
      }

      .card-contents {
        margin: -14px -5px;

        &__item {
          width: 50%;

          &-inner {
            width: calc(100% - 10px);
            margin: 14px 5px;
          }
        }

        &__txt {
          .title {
            margin-top: 12px;
            line-height: 28px;
          }
        }

        &__img {
          padding-top: 66.77%;
        }
      }
      

      .search {
        &-result {
          &__text {
            padding: 50px 0;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.3px;

            &.no-result {
              padding: 50px 0 40px;
              letter-spacing: -0.5px;
            }
          }

          &__list {
            margin-left: 5px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.4px;
            color: #bbbbbe;

            li {
              position: relative;
              padding-left: 11px;

              &:before {
                position: absolute;
                top: 1px;
                left: 0;
                content: "∙";
              }
            }
          }
        }
      }

      .section__title {
        font-size: 26px;
        line-height: 1.46;
        letter-spacing: -0.8px;
      }

      .story-detail {
        &__title {
          &-inner {
            max-width: 484px;
            padding: 42px 0 58px;
          }

          h2 {
            @include multi-ellipsis(3);
            margin-top: 13px;
            font-size: 38px;
            line-height: 1.42;
            letter-spacing: -1.5px;
          }

          &-date {
            margin-top: 60px;
          }
        }

        &__sub-title {
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }

        &__inner {
          max-width: 450px;
        }

        &__main {
          height: 336px;
        }

        &__text {
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;
        }

        &__section {
          &.section-info {
            max-width: 568px;
            margin: 93px auto 0;

            .story-detail {
              &__sub-title {
                margin-top: 18px;
                font-size: 18px;
                line-height: 1.67;
                letter-spacing: -0.5px;
              }

              &__text {
                margin-top: 62px;
              }

              &__img-text {
                max-width: 500px;
              }
            }
          }

          &.section-interview {
            margin-top: 82px;

            .story-detail {
              &__text {
                margin-top: 32px;

                &-box {
                  margin-top: 26px;
                }
              }

              &__introduction {
                .story-detail__sub-title {
                  font-size: 22px;
                  line-height: 1.55;
                  letter-spacing: -0.6px;
                }
              }
            }
          }

          &.section-slide {
            margin: 40px auto 0;
          }

          &.section-engine {
            max-width: 568px;
            margin: 64px auto 0;

            .story-detail {
              &__sub-title {
                font-size: 18px;
                line-height: 1.67;
                letter-spacing: -0.5px;
              }

              &__text {
                margin-top: 24px;

                &-box {
                  margin-top: 27px;

                  &:last-child {
                    margin-top: 32px;
                  }
                }
              }
            }
          }

          &.section-association {
            margin-top: 80px;

            .story-detail {
              &__sub-title {
                font-size: 16px;
                line-height: 1.88;
              }
            }
          }
        }

        &__info-img {
          width: 100%;
          height: 334px;
          margin-top: 80px;
        }

        &__quote {
          align-items: center;
          margin-top: 58px;
          line-height: 1.89;

          &:before {
            height: 89px;
          }
        }

        &__introduction {
          &-area {
            flex-direction: column-reverse;
          }

          &-box {
            max-width: none;
            padding: 60px 159px 60px 159px;
          }

          &-img {
            width: 100%;
            height: 406px;
            background: url(../img/pc/story-detail-img03-tabsmall.jpg) no-repeat center center;
            background-size: cover;
          }
        }

        &__interview {
          margin-top: 60px;

          &-list {
            margin-top: 40px;
          }

          &-item {
            margin-top: 40px;
          }

          &-name {
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }

          &-position {
            margin-top: 4px;
            font-size: 10px;
            line-height: 1.6;
          }

          &-text {
            margin-top: 14px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__slide {
          padding-bottom: 32px;

          &-item {
            width: 450px;
          }

          &-navigation {
            display: none;
          }

          &-pagination {
            left: -201px;
            margin-top: 24px;
          }
        }

        &__engine {
          &-img {
            width: 568px;

            &-box {
              width: 282px;
            }
          }
        }

        &__point {
          margin-top: 80px;

          &-list {
            margin-top: 40px;
          }

          &-item {
            margin-top: 30px;

            &:first-child {
              margin-top: 20px;
            }

            .num {
              margin-right: 6px;
            }
          }
        }

        &__btn {
          padding: 8.5px 11px;
          font-size: 12px;
          line-height: normal;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.story-detail {
      .story-detail-area {
        max-width: none;
        padding: 60px 20px 0;
        word-break: break-word;
        
  
        img {
          max-width: 100% !important;
          margin-left: 0;
          position: relative;
        }
  
        .report_content_video {
          position: relative;
          width: auto;
          height: auto;
          margin: 30px auto;
          padding-top: 56.42%;
  
          &:first-child {
            margin-top: 0;
          }

          .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      .card-contents {
        margin: 0;

        &__item {
          width: 100%;
          margin-top: 40px;

          &:first-child {
            margin-top: 0;
          }

          &-inner {
            width: auto;
            margin: 0;
          }
        }

        &__img {
          padding-top: 66.77%;
        }
      }

      .search {
        &-result__text {
          padding: 50px 0 40px;
        }
      }

      .scroll-title {

        &__search-area {
          .scroll-title__btn-search {
            width: 335px;
          } 
        }
      }

      .story-detail {
        &__title {
          &-inner {
            padding: 51px 15px 40px;
          }

          &-category, &-date {
            font-size: 12px;
            line-height: 2.17;
          }

          h2 {
            display: block;
            height: 100%;
            margin-top: 15px;
            padding: 0 5px;
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
          }

          &-date {
            margin-top: 61px;
          }
        }

        &__inner {
          max-width: none;
        }

        &__main {
          height: 385px;
        }

        .section__title {
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
        }

        &__section {
          &.section-info {
            margin-top: 60px;
            padding: 0 20px;

            .story-detail {
              &__sub-title {
                margin-top: 18px;
              }

              &__text {
                margin-top: 60px;
              }

              &__img-text {
                max-width: none;
              }
            }
          }

          &.section-slide {
            margin-top: 60px;

            .story-detail {
              &__img-text {
                padding: 0 30px;

                .scroll-wrap{
                  .sub__list{
                    padding: 0 20px;
                  }
                }
              }
            }
          }

          &.section-engine {
            margin-top: 50px;
            padding: 0 20px;
          }

          &.section-association {
            margin-top: 80px;
            padding: 0 0 60px 0;

            .story-detail {
              &__sub-title {
                padding: 0;
                font-size: 22px;
                line-height: 1.55;
                letter-spacing: -0.01px;
              }
            }
          }
        }

        &__info-img {
          height: 189px;
          margin-top: 54px;
          background: url(../img/mo/story-detail-img02-mo.jpg) no-repeat center;
          background-size: contain;
        }

        &__quote {
          margin-top: 38px;

          &:before {
            height: 122px;
          }
        }

        &__introduction {
          &-img {
            background: url(../img/mo/story-detail-img03-mo.jpg) no-repeat center;
            background-size: cover;
          }

          &-box {
            padding: 60px 20px;
          }
        }

        &__interview {
          margin-top: 62px;
          padding: 0 20px;
        }

        &__slide {
          padding-bottom: 24px;

          &-item {
            width: 315px;
            height: 210px;
          }

          &-pagination {
            left: -134px;
          }
        }

        &__engine {
          &-img {
            flex-direction: column;
            width: 100%;

            &-box {
              width: 100%;

              &:last-child {
                margin-top: 6px;
              }
            }
          }
        }

        &__btn {
          &.is-show-mo {
            display: block;
          }

          &.is-hide-mo {
            display: none;
          }
        }

        &__point {
          margin-top: 60px;

          &-list {
            margin-top: 50px;
          }

          &-item {
            margin-top: 14px;
          }
        }
      }

      .card__sub {
        padding: 0;

        .sub {
          &__item {
            .sub__txt {
              .tit-wrap {
                .category {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Editor CSS
@include breakpoint(tablet) {
  .#{$component-name} {
    &.story-detail {
      .se_one_viewer_wrapper_880 .se-viewer .se-component-content.se-component-content-extend {
        max-width: 717px;
        margin: 0 -49px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.story-detail {
      .se_one_viewer_wrapper_880 .se-viewer .se-component-content.se-component-content-extend {
        max-width: 568px;
        margin: 0 -59px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.story-detail {
      .se_one_viewer_wrapper_880 .se-viewer .se-component-content.se-component-content-extend {
        max-width: none;
        margin: 0 -20px;
      }
    }
  }
}
