.#{$component-name} {
  .common-list-layout {
    .common__title {
      border: 0;

      &-inner {
        padding-bottom: 81px;
      }
    }

    .section {
      max-width: 1800px;
      margin: 0 auto;
      padding-left: 460px;
    }

    .section-notice,
    .section-public-letter {
      padding-top: 100px;

      .pagination {
        margin-top: 50px;
      }
    }

    .section-news {
      padding-top: 120px;

      .pagination {
        margin-top: 100px;
      }
    }

    .table__wrap {
      margin-top: 30px;

      .ir-table {
        td:nth-child(1) {
          padding: 0;
          line-height: 2.14;
          letter-spacing: -0.3px;
          color: $color-gray3;

          a {
            display: block;
            padding: 20px 20px 20px 0;
          }
        }

        th:nth-child(2),
        td:nth-child(2) {
          width: 15%;
        }

        th:nth-child(3),
        td:nth-child(3) {
          width: 21.7%;
          text-align: center;
        }

        .ir-common__button.download {
          text-indent: -9999px;

          &::before {
            width: 24px;
            height: 24px;
          }
        }
      }

      .table__info {
        margin-top: 12px;
        line-height: 1.5;
        letter-spacing: -0.3px;

        &.table__info--en {
          display: none;
        }

        @at-root .navercorp.ir-notice {
          &.lang-en .table__info {
            display: none;

            &.table__info--en {
              display: block;
            }
          }
        }

        .outlink {
          font-weight: 800;
          color: #bbbbbe;
          border-bottom: 1px solid #bbbbbe;
          transition: all .3s;

          &:hover {
            color: $color-point;
            border-color: $color-point;
          }
        }
      }
    }

    .card__sub {
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }

      .sub__item:nth-child(n + 4) {
        margin-top: 30px;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    .common-list-layout {
      .section {
        max-width: 1520px;
        padding-left: 386px;
      }

      .section-news {
        .pagination {
          margin-top: 80px;
        }
      }

      .table__wrap .ir-table {

        th:nth-child(3),
        td:nth-child(3) {
          width: 20%;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .common-list-layout {
      .common__title {
        &-inner {
          padding-bottom: 70px;
        }
      }

      .section {
        max-width: none;
        padding: 0 30px;
      }

      .section-notice {
        padding-top: 80px;
      }

      .section-news {
        padding-top: 100px;
      }

      .table__wrap {
        margin-top: 40px;

        .ir-table {

          th:nth-child(2),
          td:nth-child(2) {
            width: 18%;
          }

          th:nth-child(3),
          td:nth-child(3) {
            width: 16%;
          }
        }
      }
    }

    &.ir-public-letter {
      .common__title-inner {
        padding-bottom: 120px;
      }

      .section__inner {
        padding-top: 80px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .common-list-layout {
      .section-notice {
        padding-top: 76px;
      }

      .section-news {
        padding-top: 80px;
      }

      .table__wrap .ir-table {
        colgroup {
          display: table-column-group;
        }

        thead {
          display: table-header-group;
        }

        tbody tr {
          display: table-row;
        }

        th,
        td {
          padding: 14px 0;
          line-height: 2.14;
        }

        th:nth-child(2),
        td:nth-child(2) {
          width: 15.6%;
        }

        th:nth-child(3),
        td:nth-child(3) {
          width: 15%;
        }

        .tit {
          font-size: 14px;
        }

        .ir-common__button.download::before {
          width: 20px;
          height: 20px;
        }
      }

      .card__sub {
        margin-top: 20px;

        .sub__item:nth-child(n + 3) {
          margin-top: 20px;
        }
      }
    }

    &.ir-public-letter {
      .section__inner {
        padding-top: 76px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .common-list-layout {
      .common__title {
        &-inner {
          padding-bottom: 40px;
        }
      }

      .section {
        padding: 0 20px;
      }

      .section-notice {
        padding-top: 60px;
      }

      .section-notice,
      .section-public-letter {
        .pagination {
          margin-top: 80px;
        }
      }

      .section-news {
        padding-top: 60px;
      }

      .table__wrap .ir-table {

        colgroup,
        thead {
          display: none;
        }

        tbody tr {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          min-height: 112px;

          &:first-child {
            border-top-color: #ddd;
            @at-root .theme-dark & {
              border-top-color: var(--border04);
            }
          }
        }

        td.tit {
          padding: 10px 0;
          font-size: 16px;
          line-height: 1.88;
        }

        td.date {
          width: 100%;
          padding: 0;
        }

        td:nth-child(3) {
          width: 100%;
          padding: 30px 0;
          text-align: left;
        }

        .ir-common__button.download {
          font-size: 14px;
          line-height: 1.86;
          text-indent: 0;
          transition: none;
        }
      }

      .card__sub {
        margin-top: 8px;

        .sub__item:nth-child(n + 3) {
          margin-top: 6px;
        }
      }
    }

    &.ir-public-letter {
      .common__title-inner {
        padding-bottom: 92px;
      }

      .section__inner {
        padding-top: 60px;
      }
    }
  }
}
