.#{$component-name} {

  .js-scroll-nav-section {
    position: relative;
  }

  .scroll-title {
    position: sticky;
    top: 0;
    left: 0;
    min-height: 70px;
    background-color: var(--background01);
    z-index: $z-index-layer + 100;
    transition: all ease-in-out .3s;

    .btn-search {
      flex-shrink: 0;
      width: 23px;
      height: 21px;
      background: var(--ic-search-tab) no-repeat center;
      background-size: 100%;
    }

    .btn-share {
      margin-left: 25px;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      background-image: var(--btn-share-link);
      background-size: 100%;
    }

    .pagination {
      display: none;
      padding: 60px 0;
    }

    .card__sub, .card-contents {
      display: none;
    }

    .back__btn {
      visibility: hidden;
      position: absolute;
      z-index: -1;
      top: 15px;
      left: 50px;
      padding: 10px;
      width: 40px;
      height: 40px;
      background: var(--ic-back) no-repeat center;
      background-size: 20px 20px;
      opacity: 0;
      transition: all ease-in-out .1s;
    }

    &.#{$stickyClass}, &.#{$fixedClass} {
      z-index: $z-index-header + 50;

      .scroll-title {
        &__box {
          position: relative;
          z-index: 1;
          background-color: var(--background01);

          &.#{$activeClass} {
            .back__btn {
              visibility: visible;
              z-index: 1;
              opacity: 1;
            }
            .scroll-title__title {
              visibility: hidden;
              opacity: 0;
            }
          }
        }
      }
    }

    &__inner {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      background-color: transparent;
      transition: all ease-in-out .3s;
    }

    &__bar {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 4px;
      width: 100%;

      .progress {
        display: block;
        width: 0;
        height: 100%;
        background-color: $color-point;
      }
    }

    &__box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1920px;
      height: 70px;
      margin: 0 auto;
      padding: 12px 60px 13px;
    }

    &__title {
      width: 100%;
      @include multi-ellipsis(1);
      font-weight: 700;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: -0.3px;
      color: var(--default);
    }

    &__list {
      overflow-y: auto;
      position: relative;
      max-height: 100vh;
      padding-top: 70px;
      background: var(--background01);
      transform: translateY(-100%);
      transition: transform .4s cubic-bezier(0.59, 0.01, 0.28, 1);
      transition-delay: .2s;
    }

    &__btn {
      display: flex;
      align-items: center;

      &.#{$activeClass} {
        // .scroll-title__btn-search {
        //   position: relative;
        //   width: 338px;
        //   border-bottom: 1px solid #e5e5e5;

        //   input {
        //     opacity: 1;
        //     visibility: visible;
        //     width: calc(100% - 80px);
        //   }
        // }
      }

      a {
        width: 24px;
        height: 24px;
      }

      &-search {
        display: flex;
        overflow: hidden;
        align-items: center;
        width: 24px;
        padding: 10px 0;
        //transition: all cubic-bezier(0.78, 0, 0.38, 1.01) .4s;

        &.#{$activeClass} {
          .scroll-title {
            &__btn {
              &-close {

                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        input {
          visibility: hidden;
          opacity: 0;
          margin-left: 16px;
          border: none;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: -0.3px;
          color: var(--default);
          background: transparent;
          transition: all cubic-bezier(0.78, 0, 0.38, 1.01) .4s;

          &::placeholder {
            color: var(--placeHoler);
          }

          &:focus {
            outline: none;
          }
        }
      }

      &-close {
        visibility: hidden;
        position: absolute;
        top: 15px;
        right: 10px;
        opacity: 0;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        background: var(--ic-circle-close) no-repeat center;
        background-size: contain;
        transition: background cubic-bezier(0.78, 0, 0.38, 1.01) .4s;

        &:hover {
          background: url(../img/common/ic-circle-close-hover.png) no-repeat center;
          background-size: contain;
        }
      }
    }

    &__search {
      position: relative;
      z-index: 1;

      &-dimmed {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.4);
      }

      &-box {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        background: var(--background01);
      }

      &-area {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        max-width: 1920px;
        margin: 0 auto;
        padding: 12px 60px 13px;

        .scroll-title__btn {
          &-search {
            position: relative;
            width: 338px;
            border-bottom: 1px solid #e5e5e5;

            input {
              opacity: 1;
              visibility: visible;
              width: calc(100% - 80px);
            }
          }

          &-close {
            position: absolute;
            top: 15px;
            right: 10px;
          }
        }

      }

      &.#{$showClass} {
        z-index: $z-index-header + 52;
        .scroll-title__list {
          transform: translateY(0);
          transition-delay: 0s;
        }
      }

      &.#{$fixedClass} {
        z-index: $z-index-header + 31;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        .scroll-title__list::-webkit-scrollbar {
          display: none;
        }

        .scroll-title__search-box {
          .scroll-title__title{
            display: none;
          }
          .back__btn {
            visibility: visible;
            z-index: 1;
            opacity: 1;
          }
        }
      }
    }

    .search-result {
      &__text {
        &.no-result {
          padding: 33px 0 24px;
        }
      }

      &__list {
        &.no-result {
          margin-left: 3px;
          font-size: 16px;
          letter-spacing: -.5px;
          line-height: 2;
        }
      }

      &__area {
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 60px;

        &.no-result {
          padding-bottom: 45px;
        }

        &.use-result {
          .card__sub {
            display: block;
          }

          .card-contents {
            display: flex;
          }

          .pagination {
            display: block;
          }
        }
      }

      &__text {
        max-width: 1920px;
      }
    }

    .tab__list {
      max-width: 1920px;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 60px;
      white-space: nowrap;

      &::-webkit-scrollbar{
        display: none;
        }
    }

    .tab__panels {
      max-width: 1920px;
      padding: 0;
    }
  }
}

.theme-dark {
  .scroll-title {

    .btn-share {
      background-image: var(--btn-share-link);
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    .scroll-title {

      &__search-area {
        padding: 12px 40px 13px;
      }

      &__box {
        padding: 12px 40px 13px;
      }

      &__search {
        .media-detail {
          &__link {
            max-width: 1520px;
          }
        }

        &-box {
          .back__btn {
            left: 30px;
          }
        }
      }

      .search-result {
        &__area {
          padding: 0 40px;
        }

        &__text {
          padding: 35px 0 47px;
        }
      }

      .tab__list {
        padding: 0 40px;
      }
    }

  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .scroll-title {
      .btn-share {
        margin-left: 17px;
      }

      &__box {
        padding: 12px 30px 13px;
      }

      &__search {
        &-area {
          height: 70px;
          padding: 12px 30px 13px;
        }

        &-box {
          .back__btn {
            left: 20px;
          }
        }
      
        &.#{$stickyClass}, &.#{$fixedClass} {

          .scroll-title {
            &__list {
              .scroll-title__title {
                display: none;
              }
            }
            
            &__btn {
              width: 100% !important;
              padding-left: 16px;
                
              .scroll-title__btn {
                &-search {
                  width: 100%;
                  border-bottom: none;
                  transition: none;
                  margin-right: 0;
                }
    
                &-close {
                  top: 10px;
                  right: 40px;
                }
              }
              
              .btn-search {
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -12px;
                margin-right: 0;
              }
                
            }

          }
        }

      }

      .search-result {
        &__area {
          padding: 0 30px;
        }

        &__text {
          padding: 30px 0;
        }
      }

      .tab__list {
        padding: 0 30px;
      }
    }

  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .scroll-title {
      min-height: 50px;

      &.#{$stickyClass}, &.#{$fixedClass} {
        .scroll-title {
          &__box {
            &.#{$activeClass} {
              .back__btn {
                top: 9px;
              }
            }
          }   
          &__list {
            padding-top: 56px;
          } 
        }
      }

      .pagination {
        padding: 40px 0;
      }

      &__search-area {
        height: 56px;
        padding: 11px 30px 10px;

        .scroll-title__btn {
          &-search {
            width: 306px;
            padding: 10px 0;
          }

          &-close {
            top: 7px;
          }
        }
      }

      &__search {
        &-box {
          .back__btn {
            top: 10px;
            left: 20px;
          }
        }
      }

      &__box {
        height: 56px;
        padding: 7px 30px;

        // &.#{$activeClass} {

        //   .scroll-title {
        //     &__btn {
        //       &-search {
        //         width: 338px;
        //         margin-right: 15px;


        //         input {
        //           // width: 80%;
        //         }
        //       }

        //       &-close {
        //         top: 12px;
        //       }

        //       .btn-search {
        //         margin-right: 8px;
        //       }
        //     }
        //   }
        // }
      }

      &__list {
        padding-top: 56px;
      }

      &__btn {
        // &.#{$activeClass} {
        //   .scroll-title__btn-search {
        //     width: 310px;
        //   }
        // }

        &-close {
          top: 11px;
        }

        a {
          width: 20px;
          height: 20px;
        }
      }

      .search-result {
        &__text {
          padding: 30px 0 26px;

          &.no-result {
            padding: 40px 0 24px;
          }
        }

        &__list {
          &.no-result {
            margin-left: 3px;
            font-size: 14px;
            letter-spacing: -.3px;
            line-height: 1.86;
          }
        }

        &__area {
          &.no-result {
            padding-bottom: 45px;
          }
        }

        &__text {
          max-width: 1920px;
        }
      }

      .tab__list {
        padding: 0 30px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .scroll-title {
      &__search-area {
        padding: 8px 20px 6px;
      }

      &__search {
        &-box {
          .back__btn {
            top: 5px;
            left: 10px;
          }
        }

        &.#{$stickyClass}, &.#{$fixedClass} {
          .scroll-title {
            &__box {
              &.#{$activeClass} {
                .back__btn {
                  left: 10px;
                  top: 6px;
                }
              }
            } 
  
            &__list {
              padding-top: 50px;
            }
  
            &__btn {
              &-search {
                padding: 5px 0;
              }
              .scroll-title__btn-close {
                top: 5px;
              }
            }
          }
        }
      }

      &__box {
        height: 51px;
        padding: 8px 20px 6px;

        // &.#{$activeClass} {

        //   .scroll-title {
        //     &__btn {
        //       &-search {
        //         width: 50vw;
        //       }
        //     }
        //   }
        // }
      }

      &__btn {
        &-search {
          width: 20px;
        }

        &-close {
          top: 9px;
        }
      }

      &__search-area {
        height: 50px;
        padding: 9px 20px 9px;

        &.no-result {
          padding-bottom: 50px;
        }

        .scroll-title__btn {
          &-search {
            width: 310px;
            padding: 6px 0;
          }

          &-close {
            top: 7px;
          }
        }
      }

      .search-result {
        &__area {
          padding: 0 20px;
        }

        &__text {
          padding: 24px 0;

          &.no-result {
            padding: 35px 0 20px;
          }
        }
      }

      .tab__list {
        padding: 0 20px;
      }

      .btn-share {
        margin-left: 12px;
        // @include sp-mobile('ic-share-small');
        // @at-root .theme-dark & {
        //   margin-left: 12px;
        //   @include sp-mobile('ic-share-small-w');
        // }
      }

    }
  }
}
