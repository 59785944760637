.#{$component-name} {
  &.story-main {

    .section__outlink {
      &-ko {
        display: block;

        .txt {
          line-height: 2.22;
          letter-spacing: -0.72px;
        }
      }

      &-en {
        display: none;

        .txt {
          line-height: 1.55;
          letter-spacing: -0.6px;
        }
      }
    }

    @at-root .story-main.lang-en {
      .section__outlink {
        &-ko {
          display: none;
        }

        &-en {
          display: block;
        }
      }
    }

    .container {
      padding-top: 0;
      padding-bottom: 0;
    }

    .anchor-nav__menu {
      display: none;
    }

    .banner-slide {
      height: 960px;

      .swiper-slide {
        align-items: flex-start;
        background-color: transparent;
      }

      .swiper-slide-active {
        .slide__txt-area {
          opacity: 0;
          animation: fade 0.8s linear forwards;
          animation-delay: .8s;
          white-space: nowrap;
        }
      }

      .swiper-pagination-bullet {
        opacity: .3;

        &:hover {
          opacity: .7;
        }
      }

      .swiper-pagination-bullets .swiper-pagination-bullet-active {
        opacity: 1;
      }


      .slide {
        &__txt-area {
          top: auto;
          left: 60px;
          bottom: 130px;
          max-width: 867px;
          text-align: left;
          transform: none;
          animation: fade-out 0.2s linear forwards;
        }

        &__title {
          font-size: 16px;
          font-weight: 800;
          line-height: 28px;
          letter-spacing: -.5px;
        }

        &__sub-title {
          margin-top: 9px;
          font-size: 48px;
          line-height: 68px;
          letter-spacing: -1.5px;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }

        &__link {
          overflow: hidden;
          width: 100%;
          height: 100%;

          img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            height: 100%;
          }
        }
      }
    }

    .main-category {
      padding-top: 130px;

      .dropdown__select {
        display: block;
        width: 300px;
        height: auto;
        margin: 0 auto;
        transform: translateX(17px);
        opacity: 1;
        z-index: 0;

        &-option {
          transform: translateX(60px);
        }

        &.#{$openClass} {
          z-index: $z-index-header + 32;

          .dropdown__selected-value {
            color: var(--default);

            &::after {
              transform: rotate(180deg);
            }
          }
        }

        &.#{$fixedClass} {
          position: fixed;
          top: 0;
          left: 50%;
          z-index: $z-index-header + 51;
          height: 67px;
          transform: translateX(-50%);

          @at-root .is-scroll-lock & {
            z-index: $z-index-header + 31;
          }

          .dropdown__selected-value {
            width: 205px;
            margin: 0 auto;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: -.5px;
            white-space: nowrap;
          }

          .dropdown__select-option {
            margin: -10px auto 0 58px;
            transform: translateX(0);
          }
        }

        &.#{$hideClass} {
          opacity: 0;
          z-index: -1;
        }
      }

      .dropdown__selected-value {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 20px 40px 20px 26px;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.5px;
        color: var(--default);
        font-weight: 700;
        border: none;
        background: none;

        &::after {
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-left: 6px;
          background: var(--ic-cate-dropdown) no-repeat center;
          background-size: 100% auto;
          content: '';
        }

        &:hover {
          color: var(--default);
        }
      }

      .dropdown__select-option {
        top: 100%;
        width: 200px;
        overflow-y: auto;
        padding: 12px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__selector {
        height: 67px;
      }

      &__title {
        .swiper-slide-active {
          .main-category__title-link {
            color: var(--default);
          }
        }

        .swiper-slide-prev[data-swiper-slide-index="7"] {
          .main-category__title-link {
            opacity: 0;
          }
        }

        &-item {
          width: 900px;
          text-align: center;
        }

        &-link {
          color: var(--storyCategorytit);
          font-size: 110px;
          font-weight: 800;
          line-height: 150px;
          letter-spacing: -1px;
          transition: all .3s;
          white-space: nowrap;
        }
      }

      &__tag {
        max-width: 850px;
        height: 88px;
        margin: 60px auto 130px;

        &-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: -4px;
        }

        &-item {
          margin: 4px;
        }

        &-link {
          font-size: 14px;
          font-weight: 800;
          line-height: 26px;
          padding: 5px 14px;
          background-color: var(--storyCategory);

          &:hover {
            color: $color-point;
          }

          &:focus {
            background-color: $color-point;
            color: $color-white;
          }
        }
      }

      &__container {
        position: relative;
      }

      &__title {
        margin-bottom: 120px;
      }

      &__contents {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 60px;

        .swiper {
          padding: 0 60px;
          transform: translate3d(0, 0, 0);

          &:hover {
            .swiper-button-prev, .swiper-button-next {
              &.#{$showClass} {
                .isNotTouchDevice & {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }

        .swiper-slide {
          width: 420px !important;
        }

        &-inner {
          display: none;
        }

        &-title {
          display: block;
          padding: 16px 0 26px;
          font-size: 26px;
          font-weight: 800;
          line-height: 38px;
          letter-spacing: -.01px;
          color: var(--default);
        }

        &-btn {
          position: absolute;
          top: 0;
          left: 50%;
          z-index: 100;
          max-width: 1920px;
          width: 100%;
          height: 267px;
          transform: translateX(-50%);
          pointer-events: none;

          .swiper-button-prev, .swiper-button-next {
            visibility: hidden;
            width: 36px;
            height: 36px;
            margin-top: 0;
            background: url(/img/common/swiper-btn.png) no-repeat center;
            background-size: 100% auto;
            transition: all .3s;
            pointer-events: auto;
            opacity: 0;

            &::after {
              content: none;
            }

            &.swiper-button-disabled {
              background: url(/img/common/swiper-btn-disable.png) no-repeat center;
              background-size: 100% auto;
            }

            &.#{$showClass} {
              .isTouchDevice & {
                opacity: 1;
                visibility: visible;
              }
            }

            &:hover {
              background: url(/img/common/swiper-btn-hover.png) no-repeat center;
              background-size: 100% auto;
            }
          }

          .swiper-button-prev {
            left: 0;
            transform: translateY(-50%);
          }

          .swiper-button-next {
            right: 0;
            transform: rotate(180deg) translateY(50%);
          }
        }

        &.is-show-all {
          margin-top: -70px;
          padding: 0;

          .main-category {
            &__contents {
              &-title {
                // padding: 16px 60px 26px;
                padding: 0 60px 26px;
              }
            }
          }
        }
      }

      .scroll-title {
        background: transparent;
        display: none;

        &.#{$stickyClass} {
          background: var(--blackWhite);
          display: block;
        }

        &.is-show-all {
          display: block;
          opacity: 1;

          &.#{$hideClass} {
            opacity: 0;
            z-index: -1;
          }
        }

        &__inner {
          display: none;
        }

        &__search {

          .scroll-title__btn {
            width: 338px;
            margin: 0;
          }

          .scroll-title__btn-search {
            position: relative;
            width: 100%;
            top: 0;
            right: 0;
          }

          &.#{$fixedClass} {
            .scroll-title__search-area {
              justify-content: flex-end;
            }
          }
        }

        &__box {
          padding: 0;
          max-width: none;
          height: auto;
          transition: all ease-in-out .3s;

          &.#{activeClass} {
            height: 70px;

            .btn-search {
              flex-shrink: 0;
              margin-right: 12px;
            }
          }
        }

        &__btn {
          position: relative;
          max-width: 1800px;
          width: 100%;
          margin: 0 auto;

          &-search {
            position: absolute;
            top: 13px;
            right: 0;
            margin-right: 0;


            input::placeholder {
              padding-left: 4px;
            }
          }

        }
      }

      .card-contents {
        padding-bottom: 100px;
        margin: -10px;

        &__item {
          width: 25%;
          margin-top: 0;

          &-inner {
            margin: 10px;
          }
          
          &-text {
            text-align: center;
          }

          &.is-empty {
            width: 100%;
          }

          &.swiper-slide {
            &.view-slide {
              width: 186px !important;

              .card-contents__item-inner {
                width: 100%;
              }

              .card-contents__more {
                position: relative;
                padding-top: 143.55%;
              }

              .card-contents__more-link {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                & > span {
                  display: flex;
                  align-items: center;
                  margin-left: 16px;
                  font-size: 22px;
                  line-height: 30px;
                  letter-spacing: -0.5px;

                  &::after {
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin-left: 10px;
                    background: url(/img/common/btn-circle-arrow-right.png) no-repeat center;
                    background-size: contain;
                    content: '';

                    @at-root .theme-dark & {
                      background: url(/img/common/btn-circle-arrow-right-dark.png) no-repeat center;
                      background-size: contain;
                    }
                  }
                }
              }
            }
          }
        }

        &__img {
          position: relative;
          padding-top: 66.75%;
          background: no-repeat center;
          background-size: cover;
        }

        &__link {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &__txt {
          min-height: 0;

          .category {
            font-weight: 700;

            &:hover {
              background-color: $color-point;
            }
          }

          .title {
            height: 60px;
            margin-top: 14px;
            line-height: 30px;
            letter-spacing: -.5px;

            &:hover {
              color: $color-point;
            }
          }
        }

        &__keywords {
          overflow: hidden;
          max-height: 59px;
          margin-top: 20px;
          font-size: 0;
        }

        &__keyword {
          display: inline-block;
          margin: 0 5px 5px 0;
          padding: 2px 10px;
          background-color: var(--buttonType3Background);
          font-size: 12px;
          font-weight: 700;
          line-height: 24px;
          color: #2f2e30;

          @at-root .theme-dark & {
            color: #e4e6ea;
          }

          &:hover {
            color: $color-point;
          }
        }
      }

      .pagination {
        margin-top: 100px;
      }
    }

    .section__outlink-wrap {
      display: flex;
      justify-content: flex-end;
      padding-top: 140px;


      .section__outlink {
        position: relative;
        width: 960px;
        height: 120px;
        padding: 0 36px;
        font-size: 22px;
        line-height: 120px;
        letter-spacing: -0.72px;
        background-color: #f1f1f4;
        color: var(--default);

        @at-root .theme-dark & {
          background-color: #1d1d1f;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: #ff5f00;
          transition: width .5s ease;
          content: "";
        }

        .txt {
          display: inline-block;
          position: relative;
          padding-right: 34px;
          z-index: 1;
          font-weight: 700;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 46%;
            right: 7px;
            width: 28px;
            height: 28px;
            background: url(/img/common/ic-outlink-black3.png) no-repeat center;
            background-size: contain;
            transform: translateY(-50%);

            @at-root .theme-dark & {
              background: url(/img/common/ic-outlink-white2.png) no-repeat center;
              background-size: contain;
            }
          }
        }
      }
    }

    .search-result__text {
      padding: 65px 0 63px;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.story-main {
      .banner-slide {
        height: 800px;

        .slide {
          &__txt-area {
            left: 40px;
            bottom: 100px;
          }
        }
      }

      .main-category {
        padding-top: 130px;

        .dropdown__select {
          transform: translateX(9px);
        }

        &__contents {
          max-width: 1600px;
          padding: 0 40px;

          &-btn {
            height: 206.5px;
          }

          .scroll-title {
            &__btn {
              max-width: 1600px;
            }
          }

          .swiper {
            padding: 0 40px;
          }

          .swiper-slide {
            width: 385px !important;
          }

          &.is-show-all {
            .main-category {
              &__contents {
                &-title {
                  padding: 0px 40px 26px;
                }
              }
            }
          }
        }

        .scroll-title {
          &__btn {
            &-search {
              right: 40px;
            }
          }

          &__box {
            max-width: 1600px;
          }
        }

        .card-contents {
          &__img {
            padding-top: 56.579%;
          }

          &__item {
            &.swiper-slide {
              &.view-slide {
                width: 186px !important;
  
                .card-contents__more {
                  padding-top: 111.03%;
                }
              }
            }
          }

        }
      }

      .section__outlink-wrap {
        .section__outlink {
          width: 800px;
          height: 100px;
          padding: 0 30px;
          font-size: 18px;
          line-height: 100px;

          &-ko {
            .txt {
              letter-spacing: -0.6px;
            }
          }

          &-en {
            .txt {
              line-height: 2.22;
            }
          }

          .txt {
            &::before {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.story-main {
      .banner-slide {
        height: 1253px;

        .slide {
          &__txt-area {
            left: 30px;
            bottom: 136px;
          }
        }
      }

      .main-category {
        padding-top: 130px;

        .dropdown__select {
          transform: translateX(6px);

          &.#{$fixedClass} {
            margin-left: -4px;

            .dropdown__select-option {
              margin: -10px auto 0 73px;
            }
          }
        }

        .dropdown__selected-value {
          padding: 13px 15px 13px 26px;
        }

        &__selector {
          height: 60px;
        }

        &__title {
          margin-bottom: 80px;

          &-item {
            width: 592px;
          }

          &-link {
            font-size: 80px;
            line-height: 120px;
            letter-spacing: normal;
          }
        }

        &__tag {
          margin-top: 25px;
        }

        &__contents {
          max-width: none;
          padding: 0 30px;

          &-btn {
            height: 20.6vw;
          }

          .swiper {
            padding: 0 30px;
          }

          .swiper-slide {
            width: 39.938% !important;
          }

          &-warp {
            margin-top: 100px;
          }

          &.is-show-all {
            margin-top: -170px;

            .main-category {
              &__contents {
                &-title {
                  padding: 0 30px 23px;
                }
              }
            }
          }
        }

        .scroll-title {

          &__btn {
            &-search {
              right: 30px;
            }

          }

        }

        .card-contents {
          margin: -8px;
          padding-bottom: 80px;

          &.swiper-wrapper {
            .card-contents__item-inner {
              width: calc(100% - 20px);
              margin: 10px;
            }
          }

          &__item {
            width: 33.333%;

            &-inner {
              width: calc(100% - 16px);
              margin: 8px;
            }

            &.swiper-slide {
              &.view-slide {
                width: 19.295% !important;
  
                .card-contents__more {
                  padding-top: 111.022%;
                }
              }
            }
          }
        }

      }

      .section__outlink-wrap {
        padding-top: 120px;

        .section__outlink {
          width: 512px;
          height: 90px;
          font-size: 16px;
          line-height: 90px;
          padding: 0 30px;

          &-ko {
            .txt {
              line-height: 1.88;
              letter-spacing: -0.3px;
            }
          }

          &-en {
            .txt {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }

          .txt {
            line-height: 1.83;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.story-main {
      .banner-slide {
        height: 940px;

        .slide {
          &__txt-area {
            bottom: 114px;
          }

          &__sub-title {
            margin-top: 10px;
            font-size: 38px;
            line-height: 54px;
            padding-right: 50px;
          }
        }
      }

      .main-category {
        padding-top: 120px;

        .dropdown__select {
          transform: translateX(4px);

          &-option {
            transform: translateX(72px);
          }

          &.#{$fixedClass} {
            height: 56px;
            margin-left: -6px;

            .dropdown__select-option {
              margin: -5px auto 0 74px;
            }
          }
        }

        .dropdown__selected-value {
          padding: 10px 10px 10px 26px;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: -.5px;
        }

        &__selector {
          height: 50px;
        }

        &__title {
          &-item {
            width: 520px;
          }

          &-link {
            font-size: 80px;
            line-height: 110px;
          }
        }

        &__tag {
          max-width: none;
          height: 72px;
          margin-bottom: 120px;
          margin-top: 32px;
          padding: 0 30px;

          &-list {
            margin: -3px;
          }

          &-item {
            margin: 3px;
          }

          &-link {
            padding: 8.5px 11px;
            font-size: 12px;
            line-height: normal;
          }
        }

        .scroll-title {

          &__box {
            &.#{activeClass} {
              height: 56px;

              .btn-search {
                margin-right: 8px;
              }
            }
          }

          &__search {
            .scroll-title__btn-search {
              right: 0;
            }

          }

          &__btn {
            &-search {
              top: 7px;
            }

          }
        }

        &__contents {
          &.is-show-all {
            margin-top: -115px;

            .main-category {
              &__contents {
                &-title {
                  padding: 0 30px 23px;
                }
              }
            }
          }

          .swiper-slide {
            width: 47.317% !important;
          }

          &-title {
            padding: 11px 0 24px;
            font-size: 22px;
            line-height: 34px;
          }

          &-btn {
            display: none;
          }
        }

        .card-contents {
          margin: -14px -5px;

          &.swiper-wrapper {
            margin: -5px;

            .card-contents__item-inner {
              width: calc(100% - 10px);
              margin: 5px;
            }
          }

          &__item {
            width: 50%;

            &-inner {
              width: calc(100% - 10px);
              margin: 14px 5px;
            }

            &.swiper-slide {
              &.view-slide {
                width: 24.012% !important;

                .card-contents__more {
                  padding-top: 108.53%;
                }

                .card-contents__more-link {
                  & > span {
                    margin-left: 16px;
                    font-size: 20px;
                  }
                }
              }
            }
          }

          &__img {
            padding-top: 56.77%;
          }

          &__txt {
            .category {
              padding: 0 8px;
              font-size: 11px;
            }

            .title {
              margin-top: 12px;
              line-height: 28px;
            }
          }

          &__keywords {
            margin-top: 18px;
            max-height: 52px;
          }

          &__keyword {
            margin: 0 4px 4px 0;
            padding: 2px 9px;
            font-size: 11px;
            line-height: 20px;
          }
        }
      }


      .section__outlink-wrap {
        padding-top: 100px;

        .section__outlink {
          width: 384px;
          height: 68px;
          font-size: 12px;
          line-height: 68px;
          padding: 0 23px;

          &-ko {
            width: 100%;

            .txt {
              line-height: 1.83;
            }
          }

          &-en {
            .txt {
              width: 90%;
              line-height: 1.83;
              font-size: 12px;
            }
          }

          .txt {
            &::before {
              width: 18px;
              height: 18px;
              right: 13px;
            }
          }
        }
      }

      .search-result__text {
        padding: 51px 0;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.story-main {
      .banner-slide {
        height: 640px;

        .slide {
          &__txt-area {
            left: 20px;
            bottom: 90px;
            max-width: 320px;
          }

          &__title {
            font-size: 14px;
            line-height: 26px;
            letter-spacing: -.3px;
          }

          &__sub-title {
            font-size: 26px;
            line-height: 40px;
            letter-spacing: -.8px;
            padding-right: 10px;
          }
        }
      }

      .main-category {
        padding-top: 70px;

        .dropdown__select {
          transform: translateX(3px);

          &-option {
            transform: translateX(78px);
          }

          &.#{$fixedClass} {
            width: 200px;
            height: 50px;

            .dropdown__select-option {
              margin: 0 auto 0 25px;
            }
          }
        }

        .dropdown__selected-value {
          font-size: 16px;

          &::after {
            width: 16px;
            height: 16px;
            margin-left: 4px;
          }
        }

        .scroll-title {

          &__box {
            padding: 0 20px;
            transition: all ease-in-out .3s;

            &.#{activeClass} {
              justify-content: normal;
              height: 50px;
              padding: 0 20px;
              background-color: var(--background01);

              .scroll-title__btn-close {
                position: static;
                margin-right: 10px;
              }
            }
          }

          &__btn {
            &-search {
              top: 5px;
              right: 0;

              &.#{$activeClass} {
                //width: 335px;
                background-color: var(--background01);
              }
            }
          }
        }

        &__selector {
          height: 50px;
        }

        &__title {
          margin-top: 3px;
          margin-bottom: 60px;

          &-item {
            width: 80vw;
          }

          &-link {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 48px;
            line-height: 64px;
            letter-spacing: -0.8px;
          }
        }

        &__list {
          padding-top: 63px;
        }

        &__tag {
          overflow: hidden;
          height: 30px;
          margin: 30px auto 67px;
          padding: 0;

          &-list {
            overflow: auto;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin: -3px;
            padding: 0 20px;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          &-link {
            white-space: nowrap;
          }
        }

        &__contents {
          padding: 0 20px;

          .swiper {
            padding: 0 20px;
          }

          .swiper-slide {
            width: 100% !important;
          }

          &-title {
            padding: 8px 0 24px;
          }

          &.is-show-all {
            margin-top: -133px;

            .main-category {
              &__contents {
                &-title {
                  padding: 0 20px 24px;
                }
              }
            }
          }
        }

        .card-contents {
          margin: 0;

          &.swiper-wrapper {
            margin: -5px;

            .swiper-slide {
              margin: 0;
            }

            .card-contents__item-inner {
              width: calc(100% - 10px);
              margin: 5px;
            }
          }

          &__item {
            width: 100%;
            margin-top: 40px;

            &:first-child {
              margin-top: 0;
            }

            &-inner {
              width: auto;
              margin: 0;
            }

            &.swiper-slide {
              &.view-slide {
                width: 50.75% !important;

                .card-contents__more {
                  padding-top: 108.53%;
                }
              }
            }
          }
        }
      }

      .section__outlink-wrap {
        padding-top: 80px;

        .section__outlink {
          width: 100%;
          height: 66px;
          font-size: 12px;
          line-height: 66px;
          padding: 0 22px;

          &-en {
            .txt {
              width: 89%;
              font-size: 12px;
            }
          }
        }
      }

      .search-result__text {
        padding-bottom: 41px;
      }
    }
  }
}


@keyframes wave-in {
  0% {
    width: 0%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
