.accordion {
  &__item {
    &.#{$activeClass} {
      .accordion {
        &__content {
          max-height: calc(350vh);
          transition: max-height cubic-bezier(0.9, 0, 0.1, 1) .5s;
        }
      }
    }
  }

  &__content {
    overflow: hidden;
    max-height: 0;
    transition: max-height cubic-bezier(0.33, 0, 0, 1) .6s, height cubic-bezier(0.33, 0, 0, 1) .6s;

    &-inner {
      
    }
  }
}