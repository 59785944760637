.#{$component-name} {
  &.ir-settlement {
    .menu__item {
      &.is-hide {
        display: none;
      }
    }

    .js-nav-section {
      padding-top: 30px;
      padding-bottom: 160px;

      .section__title {
        padding-bottom: 30px;
      }

      &.ir-settlement {
        &-additional {
          padding-bottom: 0;

          .table {
            &.capital {
              margin-top: 0.5px;
            }

            &__tr {
              &.blank {
                height: 141.5px;
              }
            }
          }
        }
      }
    }

    .dropdown__select-wrap {
      max-width: 880px;
      margin: 0 auto;
      padding: 60px 0 100px;
    }

    .table {
      margin-top: 70px;
      border-top: 1px solid var(--border04);
      border-bottom: 1px solid var(--numBg);
      font-size: 14px;
      color: #424245;
      line-height: 2.14;
      letter-spacing: -0.3px;
      text-align: left;

      .table-blind {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        font-size: 0;
        text-indent: -9999px;
        white-space: nowrap;
      }

      &-wrap {
        display: flex;

        >div {
          flex-basis: 50%;
        }
      }

      &__left {
        .table {
          &__tr {
            &.total {
              div {
                padding-left: 30px;

                &:nth-child(2) {
                  padding-right: 30px;
                }
              }
            }
          }
        }
      }

      &__right {
        .table {
          &__thead {
            &-th {
              &:first-child {
                padding-left: 30px;
                border-left: 1px solid var(--border01);
              }

              &:nth-child(2) {
                padding-right: 10px;
              }
            }
          }

          &__tbody {
            div {
              &:first-child {
                padding-left: 30px;
                border-left: 1px solid var(--border01);
              }

              &:nth-child(2) {
                padding-right: 10px;
              }

              &.is-indent {
                padding-left: 70px;
              }
            }
          }
        }
      }

      &.dept {
        border-bottom: 0;
      }

      &.capital {
        margin-top: 0;

        .table {
          &__thead {
            border-bottom-color: var(--border01);
            font-size: 16px;
            line-height: 30px;
            letter-spacing: -0.4px;
          }
        }
      }

      &.is-caption {
        margin-top: 0;
      }

      &.is-dropdown {
        margin-top: 30px;
      }

      &__caption {
        margin-top: 42px;
        margin-bottom: 12px;
        font-size: 14px;
        text-align: right;
        letter-spacing: -0.3px;
      }

      &__thead {
        display: flex;
        border-bottom: 1px solid var(--border06);

        &-th {
          flex: auto;
          height: 70px;
          padding: 20px 0;
          font-weight: 800;
          color: var(--default);

          &:first-child {
            width: 60%;
          }

          &:nth-child(2) {
            width: 40%;
            padding-right: 30px;
            text-align: right;
          }
        }
      }

      &__tbody {
        &-th {
          padding: 20px 0;
          font-weight: 700;
          color: $color-default;
        }

        div {
          flex: auto;
          padding: 20px 0;
          height: 70px;
          color: var(--box-text);

          &.font-change {
            font-weight: 700;
            color: $color-default;
          }

          &.is-indent {
            padding-left: 40px;
          }

          &:first-child {
            width: 60%;
          }

          &:nth-child(2) {
            width: 40%;
            padding-right: 30px;
            text-align: right;
          }
        }
      }

      &__tr {
        display: flex;
        align-items: center;
        border-top: 0;
        border-bottom: 1px solid var(--border01);

        &.blank {
          height: 495.5px;
          border: 0;
        }

        &.total {
          background-color: var(--bgColor02);
          border-top: 1px solid var(--numBg);
          border-bottom: 0;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: -0.4px;

          div {
            color: #1d1d1f;
          }

          &--dept {
            border-top: 0;
          }

          &--asset {
            border-top: 0;
          }

          div {
            font-weight: 800;

            &:first-child {
              width: 50%;
            }

            &:nth-child(2) {
              width: 50%;
              padding-right: 10px;
            }
          }
        }

        &:first-child {
          div {
            color: var(--storyDetailTxt);
          }
        }
      }

      &__info {
        margin-top: 20px;
        font-size: 14px;
        line-height: 26px;
        color: var(--accordionNum);
        letter-spacing: -0.3px;
        text-align: left;

        strong {
          color: var(--box-text);
        }
      }

      &__summary {
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #1d1d1f;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -0.3px;
        color: var(--flowerDesc);
        text-align: right;

        strong {
          color: var(--box-text);
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ir-settlement {

      .dropdown__select-wrap {
        max-width: 750px;
      }

      .table__tr {
        &.blank {
          height: 495px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir-settlement {
      .js-nav-section {
        padding: 82px 30px 3px;

        &.ir-settlement {
          &-additional {
            padding: 120px 30px 3px;
          }
        }
      }

      .dropdown__select-wrap {
        position: relative;
        max-width: none;
        padding: 40px 30px 0;
        z-index: 11;
      }

      .table {
        &__right {
          .table__tbody {
            div {
              &.is-indent {
                padding-left: 60px;
              }
            }
          }
        }

        &__tbody {
          div {
            &.is-indent {
              padding-left: 30px;
            }
          }
        }

        &__caption {
          margin-top: 32px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ir-settlement {
      .table {
        font-size: 12px;
        line-height: 22px;

        &-wrap {
          flex-direction: column;
        }

        &__right {
          margin-top: 70px;
        }

        &__thead {
          &-th {
            height: 42px;
            padding: 10px 6px;

            &:nth-child(2) {
              padding-right: 6px;
            }
          }
        }

        &__tbody {
          div {
            height: 42px;
            padding: 10px 6px;

            &.is-indent {
              padding-left: 26px;
            }

            &:nth-child(2) {
              padding-right: 6px;
            }
          }
        }

        &__tr {
          &.blank {
            display: none;
          }

          &.total {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.3px;

            div {
              &:nth-child(2) {
                font-size: 12px;
              }
            }
          }
        }

        &.capital {
          .table__thead {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.3px;
          }
        }

        &__left {
          .table {
            &__tr {
              &.total {
                div {
                  padding-left: 10px;

                  &:nth-child(2) {
                    padding-right: 6px;
                  }
                }
              }
            }
          }
        }

        &__right {
          .table {
            &__thead {
              &-th {
                &:first-child {
                  padding-left: 6px;
                  border-left: 0;
                }

                &:nth-child(2) {
                  padding-right: 6px;
                }
              }
            }

            &__tbody {
              div {
                &:first-child {
                  padding-left: 6px;
                  border-left: 0;
                }

                &:nth-child(2) {
                  padding-right: 6px;
                }

                &.is-indent {
                  padding-left: 26px;
                }
              }
            }
          }
        }

        &__caption {
          margin-top: 24px;
          margin-bottom: 8px;
          font-size: 10px;
          line-height: 18px;
        }

        &__info {
          margin-top: 14px;
          font-size: 12px;
          line-height: 22px;
        }

        &__summary {
          margin-top: 10px;
          font-size: 12px;
          line-height: 22px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.ir-settlement {
      .js-nav-section {
        padding: 70px 20px 17px;

        .section__title {
          padding-bottom: 24px;
        }

        &.ir-settlement {
          &-additional {
            padding-bottom: 0px;
          }
        }
      }

      .dropdown__select-wrap {
        padding: 60px 20px 0;

        .dropdown__select.wide {
          width: 100%;
        }
      }

      .table {
        &__thead {
          &-th {
            height: auto;
          }
        }

        &__tbody {
          div {
            height: auto;
          }
        }

        &__caption {
          margin-top: 14px;
        }
      }
    }
  }
}
