.#{$component-name} {
  &.resources {
    .section__title {
      font-size: 36px;
      line-height: 1.61;
      letter-spacing: -1.5px;
    }

    .anchor-section {
      margin-top: 57px;
    }

    .js-nav-section {
      padding: 45px 0 75px 460px;

      &.report {
        .section__head {
          display: flex;
          justify-content: space-between;
        }
      }

      &.policy {
        padding-top: 45px;

        .card {

          &__link {
            padding: 16px 20px 20px 20px;
          }

          &__list {
            width: 100%;
          }

          &__item {
            width: calc((100% - 30px)/3);
            height: 164px;

            .tit {
              line-height: 1.75;
              letter-spacing: -0.5px;
            }

            .txt {
              line-height: 1.57;
              letter-spacing: -0.3px;
              font-weight: 700;
            }
          }
        }

      }
    }

    .card {
      &__list {
        margin-top: 30px;

        &.eng {
          display: none;
        }

        &.kor {
          display: block;
        }

        @at-root .lang-en {
          .js-nav-section.policy .card__list.kor {
            display: none;
          }
          
          .js-nav-section.policy .card__list.eng {
            display: block;

            .btn__wrap {
              &.eng {
                margin-top: 30px;
              }
            }
          }
        }
      }

      &__item {
        margin-top: 10px;
      }

      &__link {
        // &.eng {
        //   display: none;
        // }

        // &.kor {
        //   display: block;
        // }

        // @at-root .lang-en {
        //   .card__item .card__link.kor {
        //     display: none;
        //   }

        //   .card__item .card__link.eng {
        //     display: block;

        //     .btn__wrap {
        //       &.eng {
        //         margin-top: 30px;
        //       }
        //     }
        //   }
        // }
      }

      &__wrap {
        margin-top: 40px;

        .card__main {
          .main__txt {
            background-color: var(--bgColor02);

            .category {
              color: var(--default);
              font-weight: 800;
            }

            .news-tit {
              margin-top: 20px;
              font-weight: 800;
              line-height: 1.55;
              letter-spacing: -0.6px;
              display: block;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              transition: all .3s;
              color: var(--default);
            }

            .news-date {
              margin-top: 40px;
              font-weight: 700;
              font-size: 12px;
              line-height: 2.14;
              color: var(--newsDate);
            }
          }
        }
      }



    }

  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.resources {
      .section__title {
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1px;
      }

      .js-nav-section {
        padding-left: 386px;

        .mt-none {
          margin-top: 0;
        }

        &.policy {
          .card {

            &__link {}

            &__list {}

            &__item {
              width: calc((100% - 30px)/3);
              height: 150px;
              margin-top: 5px;

              .btn__wrap {
                margin-top: 20px;
              }
            }
          }

          // .card__item {
          //   &:nth-child(1) {
          //     @at-root .navercorp.resources.lang-en {
          //       .js-nav-section.policy .card__item {
          //         &:last-child {
          //           .btn__wrap {
          //             margin-top: 0;
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
        }

        &.report {
          .card {
            &__item {
              margin-top: 5px;
            }
          }
        }

      }


      .card {
        &__list {
          margin-top: 24px;
        }

        &__wrap {
          margin-top: 30px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.resources {
      .anchor-section {
        margin-top: 0;
      }

      .js-nav-section {
        padding: 100px 30px 0;

        &.policy {
          padding-top: 80px;

          .card {
            &__list {}

            &__item {
              width: calc((100% - 18px)/3);
              height: 140px;
            }
          }

          .card__item {
            &:nth-child(1) {
              @at-root .navercorp.resources.lang-en {
                .js-nav-section.policy .card__item {
                  .btn__wrap {
                    margin-top: -10px;
                  }

                  &:last-child {
                    .btn__wrap {
                      margin-top: -10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.resources {
      .section__title {
        font-size: 26px;
        line-height: 1.46;
        letter-spacing: -0.8px;
      }

      .js-nav-section {
        padding: 80px 30px 0;

        &.policy {
          padding-top: 60px;

          .card__item {
            &:nth-child(1) {
              @at-root .navercorp.resources.lang-en {
                .js-nav-section.policy .card__item {
                  &:nth-child(1) {
                    .btn__wrap {
                      margin-top: 0;
                    }
                  }

                  &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    .btn__wrap {
                      margin-top: 0;
                    }
                  }
                }
              }
            }
          }

          .card {

            &__link {
              padding: 10px 20px 16px 20px;

              &.eng {
                padding: 16px 20px 16px 20px;

                .tit {
                  line-height: 1;
                }
              }
            }

            &__list {}

            &__item {
              width: calc((100% - 12px)/2);
              height: 130px;

              .tit {
                line-height: 2.38;
                letter-spacing: normal;
              }

              .txt {}

              .btn__wrap {
                margin-top: 0;
              }
            }
          }
        }
      }

      .card {
        &__list {
          margin-top: 24px;
        }

        &__wrap {
          margin-top: 24px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.resources {
      .js-nav-section {
        padding: 80px 20px 0;

        &.report {
          .section__head {
            flex-direction: column;
            align-items: start;
          }

          .dropdown__select {
            width: 100%;
            margin-top: 39px;
          }
        }

        &.policy {
          .card {
            &__list {
              width: 100%;
            }

            &__item {
              width: 100%;
              min-width: 240px;
              height: 130px;
            }
          }
        }
      }

      .card__item {
        &:nth-child(1) {
          @at-root .navercorp.resources.lang-en {
            .js-nav-section.policy .card__item {
              height: 150px;

              .txt {
                margin-top: 12px;
                padding-bottom: 10px;
              }

              &:nth-child(1) {
                .btn__wrap {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }


      .card {
        &__list {
          margin-top: 24px;
        }

        &__wrap {
          .card__main {
            .main__txt {
              .news-date {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
