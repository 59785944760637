.#{$component-name} {
  &.ad {
    .cont__title {
      .em {
        display: block;
      }
    }

    .service {
      &__text {
        margin-top: 100px;
      }

      &__bg {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 696px;
        background-image: url(/img/pc/service-ad-ai-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transform: translateY(-50%);
      }
    }

    .section {
      &__visual {
        background-image: url(/img/pc/service-ad-main.jpg);

        &::before {
          content:'';
          width: 100%;
          height: 100vh;
          display: block;
        }
      }

      &__img {
        height: 696px;
        margin-top: 120px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    .img-box {
      .white {
        display: block;
      }

      .dark {
        display: none;
      }
    }

    .ad {
      &__ai {
        .ad-swiper {
          width: 101%;
          position: relative;
          left: -1px;

        }

        .service {
          &__swiper {
            height: 836px;
          }

          &__img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 76.563%;
            height: 100%;
            border-radius: 45px;
            transform: translate(-50%, -50%);
          }
        }

        &-list {
          max-width: 1118px;
          margin: 100px auto 0;
          text-align: left;
        }

        &-item {
          min-height: 194px;
          padding: 36px 0 52px;
          border-top: 1px solid var(--border01);

          &:last-child {
            border-bottom: 1px solid var(--border01);
          }
        }

        &-box {
          display: flex;
          margin-top: 8px;

          &>* {
            width: 50%;
          }
        }

        &-number {
          font-weight: 700;
          font-size: 16px;
          line-height: 1.88;
          letter-spacing: -0.01px;
          color: var(--default);
        }

        &-title {
          font-weight: 800;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
          color: var(--default);
        }

        &-desc {
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
          color: var(--color-sub);
        }
      }

      &__list-box {
        max-width: 1118px;
        margin: 120px auto 0;
      }

      &__list-title {
        display: block;
        padding: 60px 0 80px;
        border-top: 1px solid var(--border01);
        font-weight: 800;
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1px;
        text-align: left;
        color: var(--default);
      }

      &__accordion {
        position: relative;
        max-width: 1118px;
        min-height: 692px;
        margin: 0 auto;

        .img-box {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          top: 0;
          right: 0;
          width: 549px;
          transition: all 1s;
        }

        &-list {
          width: 460px;
        }

        &-item {
          padding: 20px 0;
          border-top: 1px solid var(--border01);

          &:last-child {
            border-bottom: 1px solid var(--border01);
          }

          &.is-active {
            .accordion__content {
              max-height: none;
              height: 100%;
            }

            .ad__accordion-button {
              &:after {
                background-image: var(--ic-accordion-close);
              }
              @media (hover: hover) {
                &:hover {
                  &:after {
                    background-image: url(/img/common/ic-accordion-close-orange.png);
                  }
                }
              }
            }

            .img-box {
              opacity: 1;
              visibility: visible;
            }
          }

          &:only-child {
            padding: 0;
            border: none;

            .ad__accordion-text {
              margin-top: 0;
            }
          }
        }

        &-button {
          position: relative;
          width: 100%;
          padding-right: 30px;
          text-align: left;

          &:after {
            position: absolute;
            top: 50%;
            right: 10px;
            width: 18px;
            height: 18px;
            background-image: var(--ic-accordion);
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);
            // transition: all 0.3s;
            content: '';
          }

          @media (hover: hover) {
            &:hover {
              &:after {
                background-image: url(/img/common/ic-accordion-orange.png);
              }
  
              .ad__accordion-title {
                color: $color-point;
              }
            }
          }
        }

        &-title {
          font-weight: 800;
          font-size: 22px;
          line-height: 1.91;
          letter-spacing: -0.6px;
          transition: all 0.3s;
        }

        &-text {
          margin-top: 20px;
          padding-right: 30px;
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
          text-align: left;
          color: var(--color-sub);

          .sub-text {
            display: block;
            margin-top: 23px;
          }
        }

        &.is-simple {
          .img-box {
            opacity: 1;
            visibility: visible;
            transition: none;
          }
        }
      }

      &__search {
        .section__img {
          background-image: url(/img/pc/service-ad-search.jpg);
        }
      }

      &__display {
        .section__img {
          background-image: url(/img/pc/service-ad-display.jpg);
        }

        .ad__list-box {
          &:last-of-type {
            .accordion__content {
              max-height: 100%;
            }
          }
        }
      }

      &__guarantee {
        .section__img {
          background-image: url(/img/pc/service-ad-guarantee.jpg);
        }

        .accordion__content {
          max-height: 100%;
        }

        .img-box {
          overflow: hidden;
          height: 692px;
          background-color: var(--privacyBg);
        }

        .img-slide {
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &.is-active {
            animation: slideNext2 2.7s linear forwards;
            animation-timing-function: cubic-bezier(0.8, -0.01, 0.27, 1);
          }
        }

        .img {
          flex-shrink: 0;
          position: relative;
          overflow: hidden;
          width: 549px;

          img {
            position: absolute;
            bottom: -150px;
            left: 50%;
            width: 424px;
            transform: translateX(-50%);

            &.img__guarantee1-tablet {
              bottom: -93px;
              right: -195px;
              left: auto;
              width: 656px;
              transform: unset;
            }
          }
        }
      }

      &__family {
        .section__img {
          background-image: url(/img/pc/service-ad-family.jpg);
        }
      }

      &__search,
      &__family {
        .ad__accordion {
          margin-top: 160px;
        }
      }
    }
  }
}

.theme-dark {
  .navercorp.ad {
    .img-box {
      .white {
        display: none;
      }

      .dark {
        display: block;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ad {
      .service {
        &__text {
          margin-top: 100px;
        }

        &__bg {
          height: 35.5vw;
        }
      }

      .section {
        &__outlink {
          margin-top: 100px;
        }

        &__img {
          height: 580px;
          margin-top: 100px;
        }
      }

      .ad {
        &__ai {
          .service {
            &__text {
              margin-top: 80px;
            }

            &__swiper {
              height: 42vw;
            }

            &__img {
              width: 73.188%;
              border-radius: 37px;
            }
          }

          &-list {
            max-width: 944px;
            margin-top: 80px;
          }
          }

          &-number {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__search,
        &__family {
          .ad__accordion {
            margin-top: 120px;
          }
        }

        &__list-box {
          max-width: 944px;
          margin-top: 100px;
        }

        &__list-title {
          padding: 60px 0 80px;
        }

        &__accordion {
          max-width: 944px;
          min-height: 580px;

          &-list {
            width: 420px;
          }

          .img-box {
            width: 460px;
          }
        }

        &__guarantee {
          .img-box {
            height: 580px;
          }

          .img {
            width: 460px;

            img {
              width: 360px;
            }
          }
        }
      }
    }
  }

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ad {
      .section {
        &__outlink {
          margin-top: 80px;
        }

        &__img {
          height: 420px;
          margin-top: 90px;
        }
      }

      .service {
        &__text {
          margin-top: 80px;
        }

        &__img-wrap {
          height: 420px;
        }

      }

      .ad {
        &__ai {
          .service {
            &__swiper {
              height: 41vw;
            }

            &__img-wrap {
              overflow: hidden;
              height: 100%;
            }

            &__bg {
              position: static;
              transform: unset;
              height: 41vw;

              img {
                width: auto;
                height: 100%;
              }
            }

            &__img {
              top: 62px;
              width: 67.452%;
              border-radius: 22px;
              transform: translate(-50%, 0);
            }
          }

          &-list {
            max-width: 800px;
          }

          &-item {
            min-height: 161px;
            padding: 30px 0;
          }

          &-number {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }

          &-box {
            margin-top: 6px;
          }

          &-title {
            font-size: 18px;
            line-height: 1.78;
            letter-spacing: -0.5px;
            width: 350px;
            margin-right: 60px;
          }

          &-desc {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__list-box {
          max-width: 800px;
          margin-top: 80px;
        }

        &__list-title {
          padding: 50px 0 60px;
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
        }

        &__accordion {
          max-width: 800px;
          min-height: 492px;

          .img-box {
            width: 390px;
          }

          &-list {
            width: 328px;
          }

          &-item {
            padding: 18px 0;
          }

          &-title {
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          &-text {
            margin-top: 12px;
            padding-bottom: 6px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__search,
        &__family {
          .ad__accordion {
            margin-top: 100px;
          }

          .section__title {
            margin: 0 auto;
          }
        }

        &__guarantee {
          .img-box {
            height: 492px;
          }

          .img {
            width: 390px;

            img {
              bottom: -110px;
              width: 300px;

              &.img__guarantee1-tablet {
                bottom: -55px;
                right: -130px;
                width: 450px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ad {
      .service {
        &__text {
          max-width: 680px;
          margin: 60px auto 0;
        }

        &__img-wrap {
          height: 335px;
        }
      }

      .section {
        &__img {
          height: 335px;
          margin-top: 60px;
        }
      }

      .ad {
        &__ai {
          .service {
            &__text {
              margin-top: 60px;
            }

            &__swiper {
              height: 44vw;
            }

            &__bg {
              height: 44vw;
            }

            &__img {
              top: 49px;
              width: 71.71%;
              border-width: 4px;
            }
          }

          &-list {
            max-width: none;
            margin-top: 60px;
            padding: 0 30px;
          }

          &-number {
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }

        }

        &__list-box {
          max-width: 708px;

          &.first {
            .ad__list-title {
              border-top: 1px solid #e4e6ea;

              @at-root .theme-dark & {
                border-top: 1px solid #2f2e30;
              }
            }
          }
        }

        &__list-title {
          padding: 50px 0 40px;
        }

        &__accordion {
          max-width: 708px;
          min-height: 439px;

          .img-box {
            width: 348px;
          }

          &-list {
            width: 310px;
          }
        }

        &__search,
        &__family {
          .ad__accordion {
            margin-top: 80px;
          }
        }

        &__guarantee {
          .img-box {
            height: 439px;
          }

          .img {
            width: 348px;

            img {
              bottom: -110px;
              width: 275px;
            }
          }

          .ad__list-box {
            &.first {
              .img {
                &:last-child {
                  img {
                    bottom: -43px;
                    right: -116px;
                    width: 398px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.ad {
      .js-nav-section+.js-nav-section {
        .ad__list-box {
          &.first {
            margin-top: 60px;
          }
        }
      }

      .section {
        &__visual {
          background-image: url(/img/mo/service-ad-main.jpg);
        }

        &__outlink {
          margin-top: 60px;
        }

        &__img {
          height: auto;
          padding-top: 100%;
        }
      }

      .ad {
        &__ai {
          .service {
            &__swiper{
              height: 100vw;

            }
            &__img-wrap {
              height: auto;
            }

            &__img {
              top: 50%;
              width: 89.334%;
              height: 51vw;
              border-radius: 2.7vw;
              border-width: 2px;
              transform: translate(-50%, -50%);
            }

            &__bg {
              height: 100vw;
              background-image: url(/img/mo/service-ad-ai-bg.jpg);
            }
          }

          &-list {
            padding: 0 20px;
          }

          &-item {
            min-height: 158px;
            padding: 18px 0 40px;
          }

          &-number {
            font-size: 14px;
          }
          &-box {
            display: block;

            &>* {
              width: 100%;
            }
          }

          &-title {
            line-height: 1.67;
          }

          &-desc {
            margin-top: 12px;
          }
        }

        &__list-box {
          margin-top: 40px;
          padding: 0 20px;

          &:first-of-type {
            margin-top: 60px;
          }
        }

        &__list-title {
          padding: 60px 0 40px;
          border-top: 0;
        }

        &__accordion {
          position: static;
          width: 100%;
          min-height: auto;

          .img-box {
            position: static;
            width: 100%;
            margin-top: 34px;
            padding-bottom: 42px;
          }

          &-list {
            width: 100%;
          }

          &-item {
            .ad__accordion-button {
              &.hover-styles {
                &:after {
                  background-image: url(/img/common/ic-accordion-orange.png);
                }
    
                .ad__accordion-title {
                  color: $color-point;
                }
              }
            }
          }
        }

        &__search {
          .section__img {
            background-image: url(/img/mo/service-ad-search.jpg);
          }
              
          .section__title {
            width: 350px;
            margin: 0 auto;
          }

        }

        &__display {
          .section__img {
            background-image: url(/img/mo/service-ad-display.jpg);
          }
        }

        &__guarantee {
          .section__img {
            background-image: url(/img/mo/service-ad-guarantee.jpg);
          }

          .img-slide {
            width: calc(100vw - 40px);
          }

          .img-box {
            position: relative;
            height: 422px;
          }

          .img {
            width: calc(100vw - 20px);

            img {
              width: 265px;
              
              &.img__guarantee1-tablet {
                bottom: -87px;
                right: -104px;
                width: 420px;
              }
            }
          }
        }

        &__family {
          .section__img {
            background-image: url(/img/mo/service-ad-family.jpg);
          }
        }

        &__search,
        &__family {
          .ad__accordion {
            padding: 0 20px;
          }
          .section__title {
            width: 350px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
