.#{$component-name} {
  &.error-404 {
    .error {
      text-align: center;
      padding-top: 100px;

      &__img {
        display: inline-block;
        width: 120px;
      }

      &__text {
        margin-top: 21px;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: -.5px;
        color: var(--default);

        &.en {
          margin-top: 20px;
          color: #86868b;
          letter-spacing: -.01px;
        }
      }

      .btn__wrap {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }

      .ir-common__button {
        justify-content: center;
        text-transform: unset;
        width: 200px;
        height: 48px;
        padding-left: 0;
        letter-spacing: -.01px;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.error-404 {}
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.error-404 {}
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.error-404 {}
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.error-404 {
      .error {
        padding: 60px 20px 0;


        .btn__wrap {
          flex-direction: column;
        }

        .ir-common__button {
          width: 100%;
          height: 54px;
          font-size: 16px;

          &.home {
            order: 1;
            margin-top: 0;
          }

          &.prev {
            order: 2;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
