.#{$component-name} {
  &.service-naver {
    .service-naver__slide-btn {
      position: absolute;
      top: 50%;
      width: 44px;
      height: 44px;
      background: url(../img/common/btn-square-prev.png) no-repeat center center;
      background-size: contain;
      transition: all 0.3s;
      transform: translateY(-50%);
      z-index: 10;

      &:hover {
        background: url(../img/common/btn-square-prev-on.png) no-repeat center center;
        background-size: contain;
      }

      @at-root .theme-dark & {
        background: url(../img/common/btn-square-prev-gray.png) no-repeat center center;
        background-size: contain;
      }

      &.btn-prev {
        left: 0;
      }

      &.btn-next {
        right: 0;
        transform: translateY(-50%) rotate(180deg);
      }
    }

    .swiper {
      &-pagination {
        &-bullets {
          position: absolute;
          bottom: -38px;
          text-align: center;
        }

        &-bullet {
          width: 8px;
          height: 8px;
          margin: 0 6px !important;
          background: var(--pagination-bullet);
          vertical-align: top;
          font-size: 0;
          opacity: 1;

          &-active {
            background: var(--default);
          }
        }
      }

      &-slide {
        .white {
          display: block;
          top: -1px;
          position: relative;
        }

        .dark {
          display: none;
        }
      }
    }

    .section {
      &__visual {
        background-image: url(../img/pc/service-naver-main.jpg);
      }

      &__inner {
        width: 100%;
        max-width: 1118px;
        margin: 0 auto;
        padding: 94px 0;
        border-top: 1px solid var(--border01);

        &.swiper-section {
          padding-bottom: 118px;
        }

        &.service-na {
          .section__mobile-frame {
            width: 354px;
          }
        }
      }

      &__swiper {
        overflow: hidden;

        &-wrap {
          position: relative;
          background: var(--privacyBg);
        }
      }

      &__mobile-frame {
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 355px;
        height: 607px;
        border: 6px solid #393939;
        border-bottom: 0;
        border-radius: 32px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transform: translateX(-50%);

        &-wrap {
          position: relative;
          height: 687px;
          background: var(--privacyBg);

          &.is-active {
            img {
              animation: slideUp 6.6s linear infinite forwards;
              animation-timing-function: cubic-bezier(0.65, -0.02, 0.45, 0.98);
            }

            @at-root .navercorp.service-naver {
              .section__inner {
                &.service-na {
                  .section__mobile-frame-wrap {
                    display: flex;
                    justify-content: center;
                    height: auto;
                    padding: 80px 0;


                    img {
                      animation: none;
                    }

                    // .img-wrap {
                    //   animation: slideUp2 6.6s linear infinite forwards;
                    //   animation-timing-function: cubic-bezier(0.65, -0.02, 0.45, 0.98);
                    // }
                  }

                  .section__mobile-frame {
                    position: relative;
                    left: 0;
                    bottom: 0;
                    transform: translateX(0);
                    width: 324px;
                    height: 100%;
                    border-bottom: 6px solid #393939;
                    border-bottom-left-radius: 32px;
                    border-bottom-right-radius: 32px;

                    &:last-child {
                      margin-left: 60px;
                    }
                  }

                  .swiper-pagination3 {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .naver {
      overflow: hidden;
      padding: 70px 0 80px;
      text-align: center;

      &-swiper {
        position: relative;

        .swiper {
          &-pagination {
            &-bullets {
              height: 12px;
              bottom: -50px;
            }

            &-bullet {
              width: 12px;
              height: 12px;
              margin: 0 8px !important;
            }
          }
        }
      }

      &-sub {
        .section {
          &__title {
            margin-top: 0;
            font-weight: 800;
            font-size: 32px;
            line-height: 1.5;

            &-wrap {
              margin-bottom: 80px;
            }

            &-inner {
              display: flex;
              margin-top: 20px;

              &>* {
                width: 50%;
                padding-right: 50px;
              }
            }

            &-number {
              font-weight: 700;
              font-size: 16px;
              color: var(--default);
            }
          }

          &__desc {
            font-weight: 700;
            font-size: 18px;
            line-height: 1.89;
            color: var(--color-sub);
          }
        }
      }
    }

    .service {
      &__img {
        width: 21.49%;
        border: none;
        border-radius: 0;
        background: transparent;

        &.wide {
          width: 62.76%;
        }

        &-wrap {
          margin-top: 111px;
        }
      }

      &__text {
        margin-top: 148px;
      }
    }

    &.theme-dark {
      .swiper-slide {
        .white {
          display: none;
        }

        .dark {
          display: block;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.service-naver {
      .service-naver__slide-btn {
        width: 36px;
        height: 36px;
      }

      .section {
        &__inner {
          max-width: 944px;
          padding: 67px 0;

          &.swiper-section {
            padding-bottom: 100px;
          }

          &.service-na {
            .section__mobile-frame {
              width: 300px;

              &-wrap {
                padding: 67px 0;
              }
            }
          }
        }

        &__mobile-frame {
          width: 300px;
          height: 513px;

          &-wrap {
            height: 580px;
          }
        }
      }

      .swiper {
        &-pagination {
          &-bullets {
            bottom: -32px;
          }
        }
      }

      .naver {
        .swiper {
          &-pagination {
            &-bullets {
              bottom: -54px;
            }
          }
        }

        &-sub {
          .section {
            &__title {
              &-wrap {
                margin-bottom: 60px;
              }
            }
          }
        }
      }

      .service {
        &__img {
          width: 19.9%;

          &.wide {
            width: 58.31%;
          }

          &-wrap {
            margin-top: 108px;
          }
        }

        &__text {
          margin-top: 134px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.service-naver {
      .section {
        &__inner {
          max-width: none;
          padding: 60px 82px;

          &.swiper-section {
            padding-bottom: 94px;
          }

          &.service-na {
            .section__mobile-frame {
              width: 254px;

              &-wrap {
                padding: 57px 0;
              }
            }
          }
        }

        &__mobile-frame {
          width: 254px;
          height: 435px;

          &-wrap {
            height: 492px;
          }
        }
      }

      .swiper {
        &-pagination {
          &-bullets {
            bottom: -42px;
          }

          &-bullet {
            width: 8px;
            height: 8px;
            margin: 0 6px !important;
          }
        }
      }

      .naver {
        .swiper {
          &-pagination {
            &-bullets {
              bottom: -48px;
            }

            &-bullet {
              width: 8px;
              height: 8px;
              margin: 0 6px !important;
            }
          }
        }

        &-sub {
          padding: 0 30px;

          .section {
            &__title {
              font-size: 26px;

              &-inner {
                margin-top: 16px;
              }
            }

            &__desc {
              font-size: 16px;
            }
          }
        }
      }

      .service {
        &__img {
          &-wrap {
            margin-top: 80px;
          }
        }

        &__text {
          margin-top: 94px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.service-naver {
      .service-naver__slide-btn {
        width: 30px;
        height: 30px;
      }

      .section {
        &__inner {
          padding: 50px 0;

          &.swiper-section {
            padding-bottom: 92px;
          }

          &.service-na {
            .section__mobile-frame {
              width: 225px;

              &:last-child {
                margin-left: 40px;
              }

              &-wrap {
                padding: 51px 0;
              }
            }
          }
        }

        &__mobile-frame {
          width: 225px;
          height: 384px;

          &-wrap {
            height: 435px;
          }
        }
      }

      .naver {
        .swiper {
          &-pagination {
            &-bullets {
              bottom: -43px;
            }
          }
        }

        &-sub {
          .section {
            &__title {
              &-inner {
                margin-top: 10px;
              }
            }

            &__desc {
              font-size: 14px;
            }
          }
        }
      }

      .service {
        &__img {
          &-wrap {
            margin-top: 60px;
          }
        }

        &__text {
          margin-top: 83px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.service-naver {
      .section {
        &__visual {
          background-image: url(../img/mo/service-naver-main.jpg);
        }

        &__swiper {
          overflow: hidden;
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 218px;
          height: 373px;
          border: 6px solid #393939;
          border-bottom: 0;
          border-radius: 30px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          transform: translateX(-50%);

          &-wrap {
            position: relative;
            height: 422px;
          }
        }

        &__inner {
          &.service-na {
            .section__swiper {
              height: 449px;
              top: 50%;
              transform: translate(-50%, -50%);
              border-bottom: 6px solid #393939;
              border-bottom-left-radius: 32px;
              border-bottom-right-radius: 32px;

              &-wrap {
                padding: 49px 0;
                height: 547px;
              }
            }

            .section__mobile-frame {
              width: 218px;
              border: none;
              border-radius: 0;

              &:last-child {
                margin-left: 0;
              }

              &-wrap {
                justify-content: flex-start;
                padding: 0;
              }
            }

            .swiper-pagination3 {
              display: block;
            }
          }
        }

        &__mobile-frame {
          width: 218px;
          height: 373px;

          &-wrap {
            height: 422px;
          }
        }
      }

      .service-naver__slide-navigation {
        display: none;
      }

      .naver {
        &-swiper {
          .swiper-pagination {
            display: none;
          }
        }
      }

      .naver-sub {
        padding: 0 20px;

        .section {
          &__title {
            margin-bottom: 30px;

            &-inner {
              flex-direction: column;

              &>* {
                width: 100%;
              }
            }

            &-wrap {
              margin-bottom: 40px;
            }
          }

          &__desc {
            font-size: 16px;
          }
        }
      }

      .service {
        &__img {
          width: 58.86%;

          &-wrap {
            margin-top: 54px;
          }
        }

        &__text {
          margin-top: 54px;
        }
      }

      .only-pc {
        display: none;
      }
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-60%);
  }
}

@keyframes slideUp2 {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-59%);
  }
}
