@use "sass:math";

$desktop-vars: (
	'btn-blog-on': (
		offset-x: 0px,
		offset-y: -338px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-blog': (
		offset-x: -64px,
		offset-y: -338px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-facebook-on': (
		offset-x: -128px,
		offset-y: -338px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-facebook': (
		offset-x: -192px,
		offset-y: -338px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-google-on': (
		offset-x: -256px,
		offset-y: -338px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-google': (
		offset-x: -320px,
		offset-y: -338px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-insta-on': (
		offset-x: -440px,
		offset-y: 0px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-insta': (
		offset-x: -440px,
		offset-y: -64px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-outlink': (
		offset-x: -312px,
		offset-y: -402px,
		width: 50px,
		height: 50px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-appstore-on': (
		offset-x: -366px,
		offset-y: -402px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-appstore': (
		offset-x: -418px,
		offset-y: -402px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-band-on': (
		offset-x: -504px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-band': (
		offset-x: -504px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-blog-on': (
		offset-x: -504px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-blog': (
		offset-x: -504px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-cafe-on': (
		offset-x: -504px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-cafe': (
		offset-x: -504px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-facebook-on': (
		offset-x: -504px,
		offset-y: -312px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-facebook': (
		offset-x: -504px,
		offset-y: -364px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-googleplay-on': (
		offset-x: 0px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-googleplay': (
		offset-x: -52px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-home-on': (
		offset-x: -104px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-home': (
		offset-x: -156px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-home2-on': (
		offset-x: -208px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-home2': (
		offset-x: -260px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-instagram-on': (
		offset-x: -312px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-instagram': (
		offset-x: -364px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-link-on': (
		offset-x: -416px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-link': (
		offset-x: -468px,
		offset-y: -466px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-navertv-on': (
		offset-x: -556px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-navertv': (
		offset-x: -556px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-onestore-on': (
		offset-x: -556px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-onestore': (
		offset-x: -556px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-twitter-on': (
		offset-x: -556px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-twitter': (
		offset-x: -556px,
		offset-y: -260px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-youtube-on': (
		offset-x: -556px,
		offset-y: -312px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-share-youtube': (
		offset-x: -556px,
		offset-y: -364px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-twitter-on': (
		offset-x: -440px,
		offset-y: -128px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-twitter': (
		offset-x: -440px,
		offset-y: -192px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-youtube-on': (
		offset-x: -440px,
		offset-y: -256px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'btn-youtube': (
		offset-x: -440px,
		offset-y: -320px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'esg-graph-arrow': (
		offset-x: 0px,
		offset-y: -116px,
		width: 94px,
		height: 122px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-arrow-primary-tab-w': (
		offset-x: -96px,
		offset-y: -242px,
		width: 79px,
		height: 88px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-arrow-primary-tab': (
		offset-x: -179px,
		offset-y: -242px,
		width: 78px,
		height: 88px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-arrow-primary-w': (
		offset-x: -98px,
		offset-y: -116px,
		width: 100px,
		height: 114px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-arrow-primary': (
		offset-x: -232px,
		offset-y: 0px,
		width: 100px,
		height: 114px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-arrow-right-on': (
		offset-x: -608px,
		offset-y: -260px,
		width: 46px,
		height: 44px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-arrow-right': (
		offset-x: -608px,
		offset-y: -308px,
		width: 44px,
		height: 44px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-close-popup-w': (
		offset-x: -232px,
		offset-y: -118px,
		width: 100px,
		height: 100px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-close-popup': (
		offset-x: -336px,
		offset-y: 0px,
		width: 100px,
		height: 100px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-download-black': (
		offset-x: -608px,
		offset-y: -356px,
		width: 40px,
		height: 40px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-download': (
		offset-x: -608px,
		offset-y: -400px,
		width: 40px,
		height: 40px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-dropdown-gray': (
		offset-x: -556px,
		offset-y: -416px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-dropdown-white': (
		offset-x: 0px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-grid-g': (
		offset-x: -52px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-grid-w': (
		offset-x: -104px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-grid': (
		offset-x: -156px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-list-g': (
		offset-x: -208px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-list-w': (
		offset-x: -260px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-list': (
		offset-x: -312px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-outlink-b': (
		offset-x: 0px,
		offset-y: -402px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-outlink-circle-w': (
		offset-x: -64px,
		offset-y: -402px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-outlink-w': (
		offset-x: -128px,
		offset-y: -402px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-quick-link-black-md': (
		offset-x: -192px,
		offset-y: -402px,
		width: 60px,
		height: 60px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-quick-link-black': (
		offset-x: -261px,
		offset-y: -242px,
		width: 68px,
		height: 68px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-quick-link-white': (
		offset-x: -333px,
		offset-y: -242px,
		width: 68px,
		height: 68px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-delete-md-on': (
		offset-x: -364px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-delete-md': (
		offset-x: -416px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-delete-on': (
		offset-x: -384px,
		offset-y: -338px,
		width: 52px,
		height: 52px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-delete-sm-b': (
		offset-x: -608px,
		offset-y: -444px,
		width: 32px,
		height: 32px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-delete-sm-g': (
		offset-x: -608px,
		offset-y: -480px,
		width: 32px,
		height: 32px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-delete-sm': (
		offset-x: -608px,
		offset-y: -516px,
		width: 32px,
		height: 32px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-delete': (
		offset-x: -256px,
		offset-y: -402px,
		width: 52px,
		height: 52px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-on': (
		offset-x: -468px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-tab-md-on': (
		offset-x: -336px,
		offset-y: -104px,
		width: 92px,
		height: 92px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-tab-md': (
		offset-x: 0px,
		offset-y: -242px,
		width: 92px,
		height: 92px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-tab-on': (
		offset-x: 0px,
		offset-y: 0px,
		width: 112px,
		height: 112px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-tab': (
		offset-x: -116px,
		offset-y: 0px,
		width: 112px,
		height: 112px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search-w': (
		offset-x: -520px,
		offset-y: -518px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-search': (
		offset-x: -608px,
		offset-y: 0px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-share-w': (
		offset-x: -608px,
		offset-y: -52px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-share': (
		offset-x: -608px,
		offset-y: -104px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-stock-down': (
		offset-x: -608px,
		offset-y: -156px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
	'ic-stock-up': (
		offset-x: -608px,
		offset-y: -208px,
		width: 48px,
		height: 48px,
		total-width: 656px,
		total-height: 566px,
		imageSrc: '../img/desktop.png'
	),
);
$desktop-origin: (
  total-width: 656px,
  total-height: 566px,
  imageSrc: '../img/desktop.png'
);

@mixin sprite-desktop-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-desktop-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-desktop-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-desktop {
  @include sprite-desktop-size($desktop-origin);
  @include sprite-desktop-image($desktop-origin);
}

@mixin sp-desktop($image, $size: true) {
  @include set-desktop;
	background-position: math.div(map-get(map-get($desktop-vars, $image), 'offset-x'), 2)
	math.div(map-get(map-get($desktop-vars, $image), 'offset-y'), 2);
	@if $size {
		width: math.div(map-get(map-get($desktop-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($desktop-vars, $image), 'height'), 2);
	}
}
