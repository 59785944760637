.#{$component-name} {
  &.tech-search {
    .track-animation {
      &__highlight-area {
        width: 1222px;
      }

      &__highlight-text {
        text-align: center;
      }
    }

    .container {
      padding-bottom: 0;
    }

    .cont__inner {
      width: 100%;
    }

    .cover {
      position: relative;

      &:after {
        background: rgba(0, 0, 0, 0.4);
      }

      .video__player {
        height: 100%;
      }
    }

    .half {
      &__list {
        border-top: 1px solid #333;

        &:first-child {
          border-top: 0;
        }
      }

      &__box {
        &:first-child {
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: center;
          background-color: $color-default;
        }

        &:last-child {
          align-items: center;
        }
      }

      &__info {
        padding-left: 123px;
        padding-bottom: 0;

        .num {
          font-weight: 700;
          font-size: 64px;
          line-height: 1.38;
          letter-spacing: -2px;
          color: $color-gray3;
        }

        .title {
          margin-top: 40px;
        }

        .name {
          margin-top: 10px;
        }

        .text {
          margin-top: 46px;
        }
      }
    }

    .content03 {
      .cover {
        background: url(../img/pc/tech-search-bg01.png) no-repeat center / cover;
      }

      .half__list {
        &:first-child {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search01.png);
          }
        }

        &:nth-child(2) {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search02.png);
          }
        }

        &:nth-child(3) {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search03.png);
          }
        }
      }
    }

    .content04 {
      .cover {
        background: url(../img/pc/tech-search-bg02.png) no-repeat center / cover;
      }

      .half__list {
        &:first-child {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search04.png);
          }
        }

        &:nth-child(2) {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search05.png);
          }
        }

        &:nth-child(3) {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search06.png);
          }
        }
      }
    }

    .content05 {

      .cover {
        background: url(../img/pc/tech-search-bg03.png) no-repeat center / cover;
      }

      .half__list {
        &:first-child {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search07.png);
          }
        }

        &:nth-child(2) {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search08.png);
          }
        }

        &:nth-child(3) {
          .half__box:first-child {
            background-image: url(/img/pc/tech-search09.png);
          }
        }
      }
    }

    .card__sub {
      color: $color-white;
    }

    .sub__txt {
      .category,
      .news-tit {
        color: $color-white;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.tech-search {
      .content05 {
        padding-bottom: 140px;
      }

      .half {
        &__info {
          padding-left: 100px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.tech-search {
      .track-animation {
        &__highlight-area {
          width: auto;
        }

        &__highlight-text {
          padding: 0 50px;
        }
      }

      .content05 {
        padding-bottom: 0px;
      }

      .half {
        &__info {
          padding-left: 50px;

          .num {
            font-size: 38px;
            line-height: 1.42;
            letter-spacing: -1.5px;
          }

          .title {
            margin-top: 16px;
          }

          .text {
            margin-top: 40px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.tech-search {
      .half {
        &__list {
          border-top: 0;
        }

        &__info {
          padding-left: 30px;
          padding-bottom: 100px;

          .name {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.tech-search {
      .track-animation {
        &__highlight-area {
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 30px;
          text-align: left;
        }
      }

      .half {
        &__box {
          &:first-child {
            height: 512px;
          }
        }

        &__info {
          padding: 30px 20px 120px;

          .title {
            margin-top: 20px;
          }

          .name {
            margin-top: 8px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }

          .text {
            margin-top: 30px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }
      }

      .content03 {
        .cover {
          // background-image: url(../img/mo/tech-search-bg01.png);
        }
      }
      .content04 {
        // TODO
        .cover {
          // background-image: url(../img/mo/tech-search-bg02.png);
        }
      }
      .content05 {
        // TODO
        .cover {
          // background-image: url(../img/mo/tech-search-bg03.png);
        }
      }

      .section {
        padding-top: 77px;
      }

      .card__sub-wrap {
        padding-bottom: 60px;
      }
    }
  }
}
