.footer {
  width: 100%;
  padding: 42px 0 20px;
  background-color: $color-white;

  .bg-black & {
    background: black;
  }

  .theme-dark & {
    background: #060505;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1800px;
    height: 100%;
    margin: 0 auto;
  }

  &__service {
    display: flex;
    justify-content: space-between;

    .service {
      &__wrap {
        display: flex;
      }

      &__list {
        margin-left: 181px;
        &:first-child {
          margin-left: 0;
        }
      }

      &__link {
        font-weight: 700;
        font-size: 14px;
        line-height: 2.14;
        color: #2f2e30;

        @at-root .bg-black &, .theme-dark & {
          color: white;
        }

        &:hover {
          font-weight: 800;
          color: $color-point;
        }

          &.eng {
            display: none;
          }
          &.kor{
            display: inline-block;
          }
          @at-root .lang-en {
            .service__link.kor {
              display: none; 
            }
            .service__link.eng{
              display: inline-block;
            }
          }
      }
      
      &__item {
        &-policy {
          @at-root .lang-en & {
            display: none;
          }

          .service__link {
            font-weight: 800;
          }
        }
      }
      
    }
  }

  &__btn-wrap {
    a {
      display: block;
      width: 50px;
      height: 50px;

    }

    .btn-top {
      margin-top: 16px;
      background: url(/img/common/btn-top.png) no-repeat center;
      background-size: contain;

      @at-root .bg-black &, .theme-dark & {
        background-image: url(/img/common/btn-top-w.png);
      }

      &:hover {
        background-image: url(/img/common/btn-top-on.png);
      }
    }

    .btn-share {
      background: url(/img/common/btn-share-link.png) no-repeat center;
      background-size: contain;

      @at-root .bg-black &, .theme-dark & {
        background-image: url(/img/common/btn-share-link-w.png);
      }

      &:hover {
        background-image: url(/img/common/btn-share-link-on.svg);
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 148px;

    .social {
      &__wrap {
        padding-bottom: 20px;
      }

      &__list {
        display: flex;
        margin: 0 -6px;
        font-size: 0;
      }

      &__item {
        display: inline-block;
        margin: 0 6px;

        &:nth-child(2) .social__link {
          @include sp-desktop('btn-google');

          &:hover {
            @include sp-desktop('btn-google-on');
          }
        }

        &:nth-child(3) .social__link {
          @include sp-desktop('btn-insta');

          &:hover {
            @include sp-desktop('btn-insta-on');
          }
        }

        &:nth-child(4) .social__link {
          @include sp-desktop('btn-facebook');

          &:hover {
            @include sp-desktop('btn-facebook-on');
          }
        }

        &:nth-child(5) .social__link {
          @include sp-desktop('btn-twitter');

          &:hover {
            @include sp-desktop('btn-twitter-on');
          }
        }

        &:nth-child(6) .social__link {
          @include sp-desktop('btn-youtube');

          &:hover {
            @include sp-desktop('btn-youtube-on');
          }
        }
      }

      &__link {
        display: inline-block;
        @include sp-desktop('btn-blog');

        &:hover {
          @include sp-desktop('btn-blog-on');
        }
      }
    }
  }

  &__logo {
    font-weight: 700;
    font-size: 120px;
    line-height: 1.17;
    letter-spacing: -2px;
    color: $color-default;

    @at-root .bg-black &, .theme-dark & {
      color: white;
    }
  }
}

.navercorp.tech {
  .footer {
    background-color: #060505;

    &__service {
      .service {
        &__link {
          color: $color-white;
          &:hover{
          color: $color-point;
          font-weight: 800;
          }
        }
      }
    }

    &__btn-wrap {
      .btn-top {
        background-image: url(/img/common/btn-top-w.png);

        &:hover {
          background-image: url(/img/common/btn-top-on.png);
        }
      }

      .btn-share {
        background-image: url(/img/common/btn-share-link-w.png);

        &:hover {
          background-image: url(/img/common/btn-share-link-on.svg);
        }
      }
    }

    &__logo {
      color: $color-white;
    }
  }
}

@include breakpoint(pc) {
  .footer {
    &__inner {
      max-width: 1520px;
    }

    &__btn-wrap{

      a{
        width: 50px;
        height: 50px;
      }
    }

  }
}

@include breakpoint(tablet) {
  .footer {
    padding: 40px 0 26px;

    &__inner {
      max-width: none;
      padding: 0 30px;
    }

    &__info {
      margin-top: 56px;
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__logo {
      font-size: 84px;
      line-height: 1.31;
      letter-spacing: -0.1px;
    }

    &__btn-wrap{

      a{
        width: 42px;
        height: 42px;
      }
    }

    .social__wrap {
      padding-bottom: 84px;
    }
  }
}

@include breakpoint(tablet-small) {
  .footer {
    padding-bottom: 26px;

    .service {
      &__list {
        margin-left: 147px;
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .social__wrap {
      padding-bottom: 0;
    }

    &__logo {
      margin-top: 77px;
      font-size: 72px;
      line-height: 1.03;
    }
  }
}

@include breakpoint(mobile) {
  .footer {
    padding: 40px 0 20px;

    &__inner {
      padding: 0 20px;
      width: 100%;
    }

    .service {
      &__wrap {
        flex-direction: column;
      }
      &__list {
        margin-top: 40px;
        margin-left: 0;
        &:first-child {
          margin-top: 0;
        }
      }

      &__link {
        font-weight: 700;
        font-size: 12px;
        line-height: 2.17;
      }
    }

    &__btn-wrap {
      a {
        width: 42px;
        height: 42px;
      }

      .btn-top {
        margin-top: 14px;
      }
    }

    &__info {
      margin-top: 60px;
    }

    &__logo {
      margin-top: 80px;
      font-size: 74px;
      line-height: 1.11;
      letter-spacing: -1.5px;
    }
  }
}

@media screen and (max-width: 375px) {
  .footer {
    &__logo {
      font-size: 65px;
    }
  }
}
