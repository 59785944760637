.#{$component-name} {
  &.community {
    .section__visual {
      background-image: url(/img/pc/service-community-main.jpg);
    }

    .service__img-wrap {
      margin-top: 120px;
    }

    .service__img {
      border: 0;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 9px solid #2f2e30;
        border-radius: 50px;
        box-sizing: border-box;
        content: '';
      }
    }

    .service__bg {
      height: 87.164%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .talk {
      .service__bg {
        height: 90.11%;
        margin-top: -11px;
        background-image: url(/img/pc/service-community-bg01.png);
      }
    }

    .blog {
      .service__bg {
        height: 90.17%;
        margin-top: -11px;
        background-image: url(/img/pc/service-community-bg02.png);
      }
    }

    .cafe {
      .service__img-wrap {
        height: 1006px;
      }

      .service__img-box {
        position: absolute;
        top: 0;
        left: 50%;
        min-width: 1340px;
        height: 100%;
        transform: translateX(-50%);
      }

      .service__img {
        position: absolute;
        bottom: 0;
        width: 380px;
        height: 801px;
        background-color: #0e0e10;

        img {
          width: 101%;
          height: 100%;
        }

        &:first-child {
          left: 0;
        }

        &:nth-child(2) {
          top: 0;
          bottom: auto;
          left: 50%;
          transform: translateX(-50%);
        }

        &:last-child {
          right: 0;
        }
      }

      .service__bg {
        top: 153px;
        height: 69.186%;
        background-color: #0dcb67;
        transform: translate(-50%, 0);
      }
    }

    .band {
      .service__bg {
        background-image: url(/img/pc/service-community-bg03.png);
      }
    }

    .in {
      .service__bg {
        height: 92.867%;
        margin-top: -12px;
        background-image: url(/img/pc/service-community-bg04.png);
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.community {
      .service__img-wrap {
        margin-top: 116px;
      }

      .service__text{
        max-width: 944px;
        margin: 120px auto;
      }

      .talk {
        .service__bg {
          height: 90.538%;
          margin-top: -5px;
        }
      }

      .blog {
        .service__bg {
          height: 90.648%;
          margin-top: -8px;
        }
      }

      .cafe {
        .service__img-wrap {
          height: 866px;
        }

        .service__img-box {
          min-width: 1128px;
        }

        .service__bg {
          top: 128px;
          height: 69.712%;
        }

        .service__img {
          width: 316px;
          height: 666px;
        }
      }

      .in {
        .service__bg {
          height: 93.357%;
          margin-top: -9px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.community {
      .service__img-wrap {
        margin-top: 90px;
      }

      .service__bg {
        height: 78.999%;
      }

      .service__img {
        padding: 7px;

        &:after {
          border-width: 7px;
          border-radius: 40px;
        }
      }

      .talk {
        .service__bg {
          height: 79%;
          margin-top: -13px;
        }
      }

      .blog {
        .service__bg {
          height: 79.1%;
        }
      }

      .cafe {
        .service__img-wrap {
          height: 702px;
        }

        .service__img-box {
          min-width: 836px;
        }

        .service__bg {
          top: 140px;
          height: 59.83%;
        }

        .service__img {
          width: 252px;
          height: 531px;
        }
      }

      .in {
        .service__bg {
          height: 88.41%;
          margin-top: -12px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.community {
      .service__img-wrap {
        margin-top: 60px;
      }

      .service__bg {
        height: 83.895%;
      }

      .service__img {
        padding: 5px;

        &:after {
          border-width: 5px;
          border-radius: 25px;
        }
      }

      .talk {
        .service__bg {
          height: 86.5%;
          margin-top: -2px;
        }
      }

      .blog {
        .service__bg {
          height: 86.8%;
          margin-top: -5px;
        }
      }

      .cafe {
        .service__img-wrap {
          height: 518px;
        }

        .service__img-box {
          min-width: 622px;
        }

        .service__bg {
          top: 91px;
          height: 64.674%;
        }

        .service__img {
          width: 190px;
          height: 400px;
        }
      }

      .in {
        .service__bg {
          height: 89.1%;
          margin-top: -7px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.community {
      .js-nav-section {
        padding-bottom: 0;
        padding-top: 60px;
      }

      .section__visual {
        background-image: url(/img/mo/service-community-main.jpg);
      }

      .service__img {
        &::after {
          border-width: 5px;
          border-radius: 25px;
        }
      }

      .service__text {
        margin: 60px auto;
      }

      .talk {
        .service__bg {
          height: 87.11%;
          margin-top: -5px;
          background-image: url(/img/mo/service-community-bg01.png);
        }
      }

      .blog {
        .service__bg {
          height: 88.82%;
          margin-top: 0px;
          background-image: url(/img/mo/service-community-bg02.png);
        }
      }

      .cafe {
        .service__img-wrap {
          height: 527px;
        }

        .service__img-box {
          max-width: 622px;
          min-width: 375px;
        }

        .service__bg {
          top: 86px;
          height: 67.365%;
        }

        .service__img {
          width: 200px;
          height: 421px;

          &:first-child {
            left: -132px;
          }

          &:last-child {
            right: -132px;
          }
        }
      }

      .band {
        .service__bg {
          height: 84.13%;
          margin-top: 0;
          background-image: url(/img/mo/service-community-bg03.png);
        }
      }

      .in {
        .service__bg {
          height: 85.18%;
          margin-top: 4px;
          background-image: url(/img/mo/service-community-bg04.png);
        }
      }
    }
  }
}
