.#{$component-name} {
  &.esg-privacy {
    .track-animation {
      &__cover {
        background-image: url(../img/pc/esg-privacy-main.png);
      }

      &__dimmed {
        background-color: #f8f7ca;

        &.#{$showClass} {
          opacity: 1;
        }
      }

      &__highlight-area {
        width: 1185px;
        text-align: center;
      }
    }

    .ir-common__button {
      &:before {
        background-color: var(--outlinkBg02);
      }

      &:hover {
        &::before {
          background-color: $color-point;
        }
      }
    }

    .anchor-section {
      .js-nav-section {
        .section__right-box {
          .section__title {
            &.pc_text {
              display: block;
            }
            &.mo_text {
              display: none;
            }
          }
        }
      }
    }

    .esg-privacy {
      &__section {
        max-width: 1920px;
        margin: 0 auto;

        &.digital {
          .section__desc {
            width: 873px;
          }

          .ir-common__button {
            height: 34px;
            margin-top: 40px;
            padding-left: 46px;
            font-weight: 800;
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;

            &::before {
              width: 34px;
              height: 34px;
              top: 0;
              transform: rotate(-90deg);
            }
          }

          .esg-privacy {
            &__img {
              margin-top: 80px;
            }

            &__list {
              margin-top: 80px;
            }

            &__item {
              display: flex;
              align-items: center;
              margin-top: 40px;
              color: var(--color-sub02);

              &:first-child {
                margin-top: 0;

                .esg-privacy__item-img {
                  background: url(../img/pc/esg-privacy-digital-img01.png) no-repeat center;
                  background-size: contain;
                }
              }

              &:nth-child(2) {
                .esg-privacy__item-img {
                  background: url(../img/pc/esg-privacy-digital-img02.png) no-repeat center;
                  background-size: contain;
                }
              }

              &:nth-child(3) {
                .esg-privacy__item-img {
                  background: url(../img/pc/esg-privacy-digital-img03.png) no-repeat center;
                  background-size: contain;
                }
              }

              &:last-child {
                .esg-privacy__item-img {
                  background: url(../img/pc/esg-privacy-digital-img04.png) no-repeat center;
                  background-size: contain;
                }
              }

              &-img {
                flex-shrink: 0;
                width: 434px;
                height: 312px;
              }

              &-text {
                max-width: 624px;
                margin-left: 134px;

                .title {
                  color: var(--color-white);
                }
              }

              .text-box {
                display: flex;

                &.sub {
                  padding-left: 7px;
                }
              }

              .dot {
                margin: 7px 4px 0 0;
              }

              .title {
                display: block;
                margin-bottom: 10px;
                font-weight: 800;
                font-size: 26px;
                line-height: 1.46;
                letter-spacing: -0.8px;
                color: var(--default);
              }

              .text {
                font-size: 16px;
                line-height: 1.75;
                letter-spacing: -0.4px;
                color: var(--inputColor);
              }
            }
          }
        }

        &.global {
          .section__desc {
            width: 992px;
          }
        }
      }

      &__privacy {
        display: flex;
        margin-top: 60px;

        &-item {
          width: 434px;
          min-height: 477px;
          margin-right: 20px;
          background-color: var(--privacyBg);

          &:first-child {
            .esg-privacy__privacy-link {
              .icon-img {
                background: var(--esg-privacy-icon01) no-repeat center;
                background-size: contain;
              }
            }
          }

          &:nth-child(2) {
            .esg-privacy__privacy-link {
              .icon-img {
                background: var(--esg-privacy-icon02) no-repeat center;
                background-size: contain;
              }
            }
          }

          &:last-child {
            margin-right: 0;

            .esg-privacy__privacy-link {
              .icon-img {
                background: var(--esg-privacy-icon03) no-repeat center;
                background-size: contain;
              }
            }
          }
        }

        &-link {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 30px 24px 54px 30px;

          &:hover {
            .ir-common__button {
              &:before {
                background-color: $color-point;
              }
            }
          }

          .icon-img {
            width: 40px;
            height: 40px;
          }

          .title {
            display: block;
            margin-top: 20px;
            font-weight: 800;
            font-size: 22px;
            line-height: 1.91;
            letter-spacing: -0.1px;
            text-transform: uppercase;
            color: var(--color-white);
          }

          .text {
            margin-top: 18px;
            font-weight: 700;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
            color: var(--privacyTxt);
          }

          .ir-common__button {
            position: absolute;
            bottom: 24px;
            right: 24px;
            width: 30px;
            height: 30px;

            &:before {
              width: 30px;
              height: 30px;
            }
          }
        }
      }

      &__global {
        display: flex;
        flex-wrap: wrap;
        margin-top: 100px;

        &-item {
          width: 557px;
          margin-right: 124px;
          margin-top: 80px;

          &:first-child, &:nth-child(2) {
            margin-top: 0;
          }

          &:nth-child(2n + 0) {
            margin-right: 0;
          }

          &:first-child {
            .img-box {
              background: url(../img/pc/esg-privacy-global01.png) no-repeat center;
              background-size: contain;
            }
          }

          &:nth-child(2) {
            .img-box {
              background: url(../img/pc/esg-privacy-global02.png) no-repeat center;
              background-size: contain;
            }
          }

          &:nth-child(3) {
            .img-box {
              background: url(../img/pc/esg-privacy-global03.png) no-repeat center;
              background-size: contain;
            }
          }

          &:nth-child(4) {
            .img-box {
              background: url(../img/pc/esg-privacy-global04.png) no-repeat center;
              background-size: contain;

              &:last-child {
                width: 60px;
                height: 60px;
                margin-left: 10px;
                background: url(../img/pc/esg-privacy-global05.png) no-repeat center;
                background-size: contain;
              }
            }
          }

          &:nth-child(5) {
            .img-box {
              width: 80px;
              height: 64px;
              background: url(../img/pc/esg-privacy-global06.png) no-repeat center;
              background-size: contain;
            }
          }

          &:last-child {
            .img-box {
              width: 80px;
              height: 64px;
              background: url(../img/pc/esg-privacy-global07.png) no-repeat center;
              background-size: contain;
            }
          }

          .img {
            display: flex;

            &-box {
              width: 64px;
              height: 64px;
            }
          }

          .title {
            display: block;
            margin-top: 20px;
            font-weight: 800;
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
            color: var(--default);
          }

          .text {
            margin-top: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
            color: var(--color-sub03);
          }
        }
      }
    }

  }
}

.theme-dark {
  .navercorp.esg-privacy {
    .esg-privacy__section {
      &.digital {
        .esg-privacy__item {
          &:first-child {
            .esg-privacy__item-img {
              background-image: url(/img/pc/esg-privacy-digital-img01-dark.png);
            }
          }

          &:nth-child(2) {
            .esg-privacy__item-img {
              background-image: url(/img/pc/esg-privacy-digital-img02-dark.png);
            }
          }

          &:nth-child(3) {
            .esg-privacy__item-img {
              background-image: url(/img/pc/esg-privacy-digital-img03-dark.png);
            }
          }

          &:last-child {
            .esg-privacy__item-img {
              background-image: url(/img/pc/esg-privacy-digital-img04-dark.png);
            }
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.esg-privacy {
      .esg-privacy {
        &__section {
          padding-top: 180px;

          &.digital {
            .section__desc {
              width: 743px;
            }

            .esg-privacy {
              &__item {
                &-img {
                  width: 365px;
                  height: 262px;
                }

                &-text {
                  max-width: 540px;
                  margin-left: 110px;
                }

                .title {
                  margin-bottom: 12px;
                  font-size: 22px;
                  line-height: 1.91;
                  letter-spacing: -0.6px;
                }

                .text {
                  font-size: 14px;
                  line-height: 2;
                  letter-spacing: -0.3px;
                }
              }
            }
          }

          &.global {
            .section__desc {
              width: 844px;
            }
          }
        }

        &__privacy {
          &-item {
            width: 365px;
            min-height: 401px;
          }
        }

        &__global {
          &-item {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.esg-privacy {
      .track-animation {
        &__highlight-area {
          width: 720px;
        }
        &__highlight-text {
          text-align: center;
        }
      }

      .esg-privacy {
        &__section {
          padding-top: 140px;

          &.privacy {
            padding-top: 160px;
            padding: 160px 0 0 0;
          }

          &.digital {
            .section {
              &__right-box {
                padding: 0;
              }

              &__desc {
                width: 670px;
              }
            }

            .ir-common__button {
              margin-left: 0px;
            }

            .esg-privacy {

              &__item {
                margin-top: 30px;

                &-text {
                  margin-left: 60px;
                  padding-right: 30px;
                }

                .title {
                  font-size: 18px;
                  line-height: 1.67;
                  letter-spacing: -0.5px;
                }
              }
            }
          }

          &.global {
            .section__desc {
              width: 750px;
            }
            .section__right-box {
              padding: 0;
            }
          }
        }

        &__privacy {
          margin-top: 80px;

          &-item {
            width: 308px;
            min-height: 335px;
          }

          &-link {
            .icon-img {
              width: 32px;
              height: 32px;
            }

            .title {
              margin-top: 16px;
              font-size: 18px;
              line-height: 1.89;
              letter-spacing: -0.5px;
            }

            .text {
              margin-top: 12px;
              font-size: 12px;
              line-height: 1.83;
              letter-spacing: normal;
            }

            .ir-common__button {
              bottom: 20px;
              right: 20px;
            }
          }
        }

        &__global {
          display: grid;
          grid-template-columns: repeat(2,1fr);
          grid-template-rows: repeat(2,1fr);
          gap: 20px;
          &-item {
            width: 100%;
            &:nth-child(4) {
              .img-box {
                &:last-child {
                  width: 68px;
                  height: 68px;
                }
              }
            }

            &:nth-child(5) {
              .img-box {
                width: 90px;
                height: 72px;
              }
            }

            &:nth-child(6) {
              .img-box {
                width: 89px;
                height: 72px;
              }
            }

            .img-box {
              width: 72px;
              height: 72px;
            }

            .text {
              font-size: 16px;
              line-height: 1.75;
              letter-spacing: -0.5px;
            }
          }
        }


      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.esg-privacy {
      .track-animation {
        &__highlight-area {
          width: 648px;
          text-align: left;
        }
        &__highlight-text {
          text-align: left;
        }
      }

      .esg-privacy {
        &__section {
          padding-top: 120px;

          &.privacy {
            .section__right-box {
              padding: 0 20px;
            }
          }

          &.digital {
            padding-top: 105px;

            .section__desc {
              width: 665px;
            }

            .esg-privacy {
              &__list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 60px;
              }

              &__item {
                display: block;
                width: 348px;
                margin-top: 60px;
                margin-right: 10px;

                &:first-child {
                  order: 0;
                }

                &:nth-child(2) {
                  order: 2;
                }

                &:nth-child(3) {
                  margin-top: 0;
                  margin-right: 0;
                  order: 1;
                }

                &:last-child {
                  margin-right: 0;
                  order: 3;
                }

                &-img {
                  width: 348px;
                  height: 249px;
                }

                &-text {
                  max-width: none;
                  margin-left: 0;
                }

                .title {
                  margin: 20px 0 10px;
                  font-size: 16px;
                  line-height: 1.75;
                  letter-spacing: -0.5px;
                }

                .text {
                  font-size: 14px;
                  line-height: 1.86;
                  letter-spacing: -0.3px;
                }
              }

              &__img {
                margin-top: 60px;
              }
            }

            .ir-common__button {
              height: 24px;
              margin-top: 30px;
              padding-left: 31px;
              font-size: 16px;
              line-height: normal;
              letter-spacing: -0.5px;

              &:before {
                width: 24px;
                height: 24px;
              }
            }
          }

          &.global {
            .section__desc {
              width: 540px;
            }
          }
        }

        &__privacy {
          width: max-content;
          margin-top: 40px;

          &-wrap {
            overflow-y: auto;
            width: 100%;

            &::-webkit-scrollbar {
              opacity: 0;
            }
          }

          &-item {
            width: 335px;
            margin-right: 10px;
          }

          &-link {
            padding: 24px 24px 60px;

            .icon-img {
              width: 34px;
              height: 34px;
            }

            .title {
              margin-top: 20px;
              font-size: 18px;
              line-height: 1.89;
              letter-spacing: -0.5px;
            }

            .text {
              margin-top: 10px;
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }

            .ir-common__button {
              bottom: 20px;
              right: 20px;
              width: 24px;
              height: 24px;

              &::before {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        &__global {
          display: block;
          margin-top: 60px;

          &-item {
            width: 100%;
            margin-top: 50px;

            &:nth-child(2) {
              margin-top: 50px;
            }

            &:nth-child(4) {
              .img-box {
                &:last-child {
                  width: 60px;
                  height: 60px;
                }
              }
            }

            &:nth-child(5) {
              .img-box {
                width: 80px;
                height: 64px;
              }
            }

            &:nth-child(6) {
              .img-box {
                width: 79px;
                height: 64px;
              }
            }

            .img-box {
              width: 64px;
              height: 64px;
            }

            .title {
              font-size: 16px;
              line-height: 1.75;
              letter-spacing: normal;
            }

            .text {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.esg-privacy {
      .track-animation {
        &__cover {
          background-image: url(/img/mo/esg-privacy-main-mo.png);
        }

        &__highlight-area {
          width: auto;
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 27px;
          text-align: left;
        }
      }

      .anchor-section {
        .js-nav-section {
          .section__right-box {
            .section__title {
              &.pc_text {
                display: none;
              }
              &.mo_text {
                display: block;
              }
            }
          }
        }
      }

      .section__title {
        text-wrap: nowrap;

        .text-highlight {
          text-wrap: nowrap;
          
          @at-root .lang-en & {
            .navercorp.esg-privacy .section__title {
              font-size: 27px;
            }
          }
        }
      }

      .esg-privacy {

        &__section {
          padding-top: 100px;

          &.privacy {
            padding-top: 100px;
          }

          &.digital {
            .section {
              &__title {
                padding: 0;
              }

              &__desc {
                width: 100%;
                padding: 0;
              }
            }

            .ir-common__button {
              margin-left: 0;
            }

            .esg-privacy {

              &__img {
                margin-top: 80px;
              }

              &__list {
                margin-top: 60px;
              }

              &__item {
                width: 100%;
                margin-top: 60px;
                margin-right: 0;
                  
              &:first-child {
                margin-top: 0;

                .esg-privacy__item-img {
                  background-size: cover;
                }
              }

              &:nth-child(2) {
                .esg-privacy__item-img {
                  background-size: cover;
                }
              }

              &:nth-child(3) {
                .esg-privacy__item-img {
                  background-size: cover;
                }
              }

              &:last-child {
                .esg-privacy__item-img {
                  background-size: cover;
                }
              }

                &:nth-child(3) {
                  margin-top: 60px;
                }

                &-img {
                  width: 335px;
                  height: 223px;
                }

                &-text {
                  padding-right: 0;
                }

                .title {
                  margin: 20px 0 12px;
                  font-size: 18px;
                  line-height: 1.67;
                  letter-spacing: -0.5px;
                }
              }
            }
          }

          &.global {
            .section__desc {
              width: 100%;
            }

            .section__right-box {
              .section__title {
                padding-left: 0;
              }
            }
          }
        }

        &__privacy {
          display: block;
          width: 100%;

          &-item {
            width: 100%;
            min-height: 335px;
            margin-top: 10px;

            &:first-child {
              margin-top: 0;
            }
          }

          &-link {
            min-height: 335px;
          }
        }

        &__global {
          margin-top: 80px;
        }
      }
    }
  }
}
