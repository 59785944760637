.#{$component-name} {
  .esg-green-popup {
    .dimmed {
      background-color: rgba(0, 0, 0, 0.7);
    }

    .layer-content {
      align-items: unset;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &__box {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        max-width: 830px;
        margin: 70px auto;
      }
    }

    .layer-title {
      padding: 60px 60px 50px;

      .title {
        font-size: 38px;
        font-weight: 800;
        line-height: 48px;
        letter-spacing: -0.8px;
        @at-root .theme-dark & {
          color: var(--default);
        }
      }

      .green {
        color: #0dcb67;
      }
    }

    .layer-text {
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
      padding-left: 60px;
      padding-right: 59px;
      margin-bottom: 70px;

      @at-root .navercorp.esg-environment.lang-en {
        .esg-green-popup .layer-text {
          padding-right: 35px;
        }
      }

      &::-webkit-scrollbar {
        width: 20px;
      }

      &::-webkit-scrollbar-thumb {
        border: 6px solid transparent;
        background: #f1f1f1;
        background-clip: padding-box;
      }

      &__box {
        .desc {
          color: #111;
          font-size: 18px;
          line-height: 34px;
          letter-spacing: -0.5px;
          @at-root .theme-dark & {
            color: var(--default);
          }
        }

        & > .plan__title {
          margin-bottom: 50px;
        }

        & + .layer-text__box {
          margin-top: 60px;
          padding-top: 50px;
          border-top: 1px solid #ebebeb;
        }
      }
    }

    @at-root .navercorp.esg-environment.lang-en {
      .esg-green-popup .layer-text__box {
        &:first-child,
        &:nth-child(2),
        &:nth-child(4),
        &:last-child {
          padding-right: 24px;
        }
      }
    }

    .plan {
      &__item {
        & + .plan__item {
          margin-top: 60px;
          padding-top: 40px;
          border-top: 1px solid #ebebeb;
        }

        .plan__title {
          margin-bottom: 26px;
          @at-root .theme-dark & {
            color: var(--default);
          }
        }
      }

      &__thumb {
        display: inline-block;
        margin-bottom: 24px;

        img {
          width: auto;
          height: 100px;
        }
      }

      &__title {
        display: block;
        line-height: 22px;
        font-size: 20px;
        font-weight: 800;
        letter-spacing: -0.5px;
        @at-root .theme-dark & {
          color: var(--default);
        }
      }

      &__desc {
        color: #666;
        font-size: 16px;
        letter-spacing: -0.5px;
        line-height: 30px;

        @at-root .theme-dark & {
          color: var(--color-sub03);
        }

        & + .plan__desc {
          margin-top: 20px;
        }

        em {
          font-weight: 800;
        }
      }

      &__graph {
        display: flex;

        &.kor {
          display: flex;
        }
        &.eng {
          display: none;
        }

        @at-root .lang-en {
          .esg-green-popup .layer-text__box .plan__graph.kor {
            display: none;
          }
          .esg-green-popup .layer-text__box .plan__graph.eng {
            display: flex;

            .major-title {
              margin-top: 282px;
            }

            .major-desc-list {
              padding-left: 119px;

              &::before {
                position: absolute;
                top: 21px;
                left: 0;
                width: 119px;
                height: 609px;
                background: url(/img/pc/green-popup-graph-line-en.png) no-repeat;
                background-size: 119px 609px;
                content: '';
              }
            }
          }
        }

        .major-title {
          display: inline-block;
          width: 240px;
          height: 82px;
          margin-top: 190px;
          padding: 16px 25px 18px;
          box-sizing: border-box;
          background-color: #0dcb67;
          color: #fff;
          font-size: 18px;
          font-weight: 800;
          line-height: 24px;
        }

        .major-desc-list {
          position: relative;
          flex: 1;
          padding-left: 119px;
          box-sizing: border-box;

          &::before {
            position: absolute;
            top: 21px;
            left: 0;
            width: 119px;
            height: 392px;
            background: url(/img/pc/green-popup-graph-line.png) no-repeat;
            background-size: 119px 392px;
            content: '';
          }

          > li + li {
            padding-top: 33px;
          }

          @at-root .navercorp.esg-environment.lang-en {
            .esg-green-popup .plan__graph .major-desc-list > li + li {
              padding-top: 31px;
            }
          }
        }

        .sub-title {
          display: inline-block;
          margin-bottom: 13px;
          padding: 4px 16px;
          border: 2px solid #0dcb67;
          box-sizing: border-box;
          color: #0dcb67;
          font-size: 16px;
          font-weight: 800;
          line-height: 30px;
        }

        .sub-desc-list {
          li {
            position: relative;
            padding-left: 15px;
            width: max-content;
            font-size: 16px;
            line-height: 31px;
            letter-spacing: -0.5px;

            @at-root .theme-dark & {
              color: var(--default);
            }

            @at-root .navercorp.esg-environment.lang-en {
              .esg-green-popup .plan__graph .sub-desc-list li {
                max-width: 355px;
              }
            }

            &::before {
              display: block;
              position: absolute;
              top: 0;
              left: 4px;
              color: rgba(0, 0, 0, 0.7);
              content: '∙';

              @at-root .theme-dark & {
                color: var(--default);
              }
            }

            .sup {
              font-size: 9px;
              vertical-align: super;
            }
          }
        }
      }
    }

    .report {
      &__list {
        display: flex;
        gap: 32px;
      }

      &__item {
        margin-left: 16px;

        &:first-child {
          margin-left: 0;
        }

        .btn_area {
          margin: 21px -7px -7px;

          a {
            margin: 7px;
          }

          .btn_sm {
            display: inline-block;
            padding: 0 20px;
            background-color: #eee;
            color: #111;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: -0.5px;
            text-align: center;
            transition: all 0.2s linear;

            &.kor {
              display: flex;
              align-items: center;
              width: fit-content;
              height: 37px;
            }
            &.eng {
              display: none;
            }

            @at-root .lang-en {
              .report__list .report__item .btn_area .btn_sm.kor {
                display: none;
              }
              .report__list .report__item .btn_area .btn_sm.eng {
                display: flex;
                align-items: center;
                width: fit-content;
                height: 37px;
                font-size: 10px;
              }
            }
          }
        }
      }

      &__title {
        display: block;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: -0.45px;
        @at-root .theme-dark & {
          color: var(--default);
        }
      }

      &__desc {
        line-height: 28px;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: #666;
        @at-root .theme-dark & {
          color: var(--color-sub03);
        }
      }
    }

    .join {
      &__list {
        margin: -25px 0;
        font-size: 0;
      }

      &__item {
        display: inline-block;
        width: 50%;
        margin: 25px 0;
      }

      &__thumb {
        display: flex;
        height: 49px;

        img {
          width: auto;
          margin-top: auto;
        }
      }

      &__desc {
        margin-top: 21px;
        color: #444;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.3px;
        @at-root .theme-dark & {
          color: var(--color-sub03);
        }
      }
    }

    .btn-close {
      display: block;
      position: absolute;
      top: 22px;
      right: 22px;
      width: 24px;
      height: 24px;

      &::after {
        display: block;
        width: 100%;
        height: 100%;
        background: url(../img/common/ic-close-popup-black.png) no-repeat center;
        background-size: contain;
        content: '';
        @at-root .theme-dark & {
          display: block;
          width: 100%;
          height: 100%;
          background: url(../img/common/ic-close-popup-w.png) no-repeat center;
          background-size: contain;
          content: '';
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .esg-green-popup {
      .layer-content__box {
        max-width: none;
        margin: 0;
      }

      .layer-title {
        padding: 60px 36px 50px;
      }

      .layer-text {
        padding: 0 16px 0 36px;
      }

      @at-root .navercorp.esg-environment.lang-en {
        .esg-green-popup .layer-text {
          padding-right: 20px;
        }

        .esg-green-popup .layer-text__box {
          &:first-child,
          &:nth-child(2),
          &:nth-child(4),
          &:last-child {
            padding-right: 0;
          }
        }

        .esg-green-popup .plan__title {
          line-height: 30px;
        }

        .esg-green-popup .plan__graph .major-desc-list > li + li {
          padding-top: 31px;
        }

        .esg-green-popup .plan__graph .sub-desc-list li {
          max-width: 322px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .esg-green-popup {
      .layer-title {
        padding: 50px 20px 30px;

        .title {
          font-size: 32px;
          line-height: 48px;
          letter-spacing: -0.8px;
        }
      }

      .layer-text {
        padding: 0 20px;

        &::-webkit-scrollbar {
          width: 0;
        }

        &__box {
          .desc {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }

      .plan {
        &__graph {
          position: relative;
          flex-direction: column;
          padding-left: 30px;

          &.kor {
            display: flex;
          }
          &.eng {
            display: none;
          }

          .sub-desc-list li {
            padding-left: 19px;

            &:before {
              left: 8px;
            }
          }

          &::before {
            position: absolute;
            top: 41px;
            left: 0;
            width: 32px;
            height: 542px;
            background: url(/img/mo/green-popup-graph-line.png) no-repeat;
            background-size: 31px 542px;
            content: '';
          }

          .major-title {
            margin-top: 0;
            margin-bottom: 55px;
          }

          .major-desc-list {
            padding-left: 0;

            &::before {
              content: none;
            }

            // & > li {
            //   &:nth-child(3) {
            //     position: relative;

            //     &::before {
            //       position: absolute;
            //       top: -114px;
            //       left: -16px;
            //       width: 18px;
            //       height: 174px;
            //       background: url(/img/mo/green-popup-graph-line-bottom.png) no-repeat;
            //       background-size: 17px 172px;
            //       content: '';
            //     }
            //   }
            // }
          }

          @at-root .lang-en {
            .esg-green-popup .layer-text__box .plan__graph.kor {
              display: none;
            }
            .esg-green-popup .layer-text__box .plan__graph.eng {
              display: flex;

              .major-title {
                margin-top: 0;
              }

              .major-desc-list {
                padding-left: 0;

                > li + li {
                  padding-top: 33px;
                }

                // > li {
                //   &:nth-child(3) {
                //     &::before {
                //       background-size: 20px 151px;
                //       top: -118px;
                //       left: -16px;
                //       width: 16px;
                //     }
                //   }
                // }

                &::before {
                  display: none;
                }
              }

              .sub-desc-list li {
                position: relative;
                padding-left: 19px;
                font-size: 16px;
                line-height: 31px;
                letter-spacing: -0.5px;
              }

              .sub-title {
                margin-bottom: 10px;
              }
            }
            .esg-green-popup .plan__graph {
              &::before {
                width: 31px;
                height: 754px;
                background: url(/img/mo/green-popup-graph-line-en.png) no-repeat;
                background-size: 31px 754px;
              }
            }
          }

          @at-root .navercorp.esg-environment.lang-en {
            .esg-green-popup .plan__graph .sub-desc-list li {
              padding-left: 13px;
              max-width: 306px;

              &::before {
                left: 4px;
              }
            }
          }
        }
      }

      .report {
        &__list {
          flex-direction: column;
          gap: 0;
        }

        &__item {
          margin-left: 0;
          margin-top: 60px;

          &:first-child {
            margin-top: 10px;
          }
        }
      }

      .join__item {
        width: 100%;
      }

      .btn-close {
        top: 20px;
        right: 20px;
      }
    }
  }
}
