.pagination {
  text-align: center;

  &.#{$hideClass} {
    opacity: 0;
    visibility: hidden;
  }

  .bg-black & {
    .pagination__area {
      background-color: transparent;
    }

    .page-blind {
      color: #424245;
    }

    .page-link {
      color: #424245;

      &:hover {
        background-color: $color-default;
      }

      &.#{$activeClass} {
        color: $color-white;

        &:hover {
          background-color: #060505;
        }
      }
    }

    .page-prev, .page-next {
      background: url(/img/common/ic-pagination-arrow-black.png) no-repeat center;
      background-size: contain;

      &:hover {
        background: url(/img/common/ic-pagination-arrow-black-hover.png) no-repeat center;
        background-size: contain;
      }
    }
  }

  &__area {
    display: inline-block;
    position: relative;
    height: 32px;
    padding: 0 92px;
    background-color: var(--transparent);
    text-align: center;
  }

  .page-prev, .page-next {
    display: none;
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--pagination) var(--ic-pagination) no-repeat center;
    background-size: contain;
    transform: translateY(-50%);
    transition: background-color ease-in-out .15s;
    transition: all 0.3s;

    &:hover {
      background-color: var(--bgColor);
      background-image: var(--ic-pagination02);
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .page-prev {
    left: 0;
  }

  .page-next {
    right: 0;
    transform: rotate(180deg) translateY(50%);
  }

  .page-num-wrap {
    display: inline-block;
    overflow: hidden;
    position: relative;
    font-size: 0;
    box-sizing: border-box;
    color: var(--default);
  }

  .page-blind {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0 3px;
    font-size: 14px;
    font-weight: 700;
    color: var(--pagination-page-blind);
    line-height: 1;
  }

  .page-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0 3px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
    color: #a1a1a6;
    line-height: 1;
    box-sizing: border-box;
    transition: all ease-in-out .15s;

    &.#{$activeClass} {
      font-weight: 800;
      color: var(--default);
    }

    &:hover {
      background-color: var(--bgColor02);
    }
  }
}

@include breakpoint(mobile) {
  .pagination__area {
    padding: 0 51px;
  }
}
