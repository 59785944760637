.#{$component-name} {
  &.gak {
    .track-animation {
      &__highlight-area {
        width: 1628px;
      }

      &__highlight-text {
        text-align: center;
      }
    }

    .container {
      padding-bottom: 0;
    }

    .content03 {
      max-width: none;
      padding: 160px 60px 200px;
      padding-left: 26.82%;

      .section__title {
        font-weight: 800;
        font-size: 48px;
        line-height: 1.38;
        letter-spacing: -1.5px;
        color: $color-white;
      }
    }

    .cover {
      position: relative;

      &:after {
        background: rgba(0, 0, 0, 0.4);
      }
    }

    .content04 {
      .cover {
        background-image: url(/img/pc/tech-gak-sejong.jpg);
      }

      .half__list {
        &:first-child {
          .half__box:first-child {
            background-image: url(/img/pc/tech-gak-sejong01.jpg);
          }
        }

        &:nth-child(2) {
          .half__box:first-child {
            background-image: url(/img/pc/tech-gak-sejong02.jpg);
          }
        }

        &:last-child {
          .half__box:first-child {
            background-image: url(/img/pc/tech-gak-sejong03.jpg);
          }
        }
      }
    }

    .content05 {
      .cover {
        background-image: url(/img/pc/tech-gak-chuncheon.jpg);
      }

      .cont__wrap {
        padding-right: 8%;
      }

      .cont__desc {
        padding-left: 42.3%;
      }

      .half__list {
        &:first-child {
          .half__box:first-child {
            background-image: url(/img/pc/tech-gak-chuncheon01.jpg);
          }
        }

        &:nth-child(2) {
          .half__box:first-child {
            background-image: url(/img/pc/tech-gak-chuncheon02.jpg);
          }
        }

        &:last-child {
          .half__box:first-child {
            background-image: url(/img/pc/tech-gak-chuncheon03.jpg);
          }
        }
      }
    }

    .content06 {
      .cover {
        background-image: url(/img/pc/tech-gak-region.jpg);
        height: 85vh;
      }
    }

    .half__list {
      &:first-child {
        border-top: 0;
        .half__box {
          &:last-child {
            border-top: 0;
          }
        }
      }
    }

    .half__box {
      &:first-child {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: #222;
      }

      &:last-child {
        align-items: center;
        border-top: 1px solid #333;
      }
    }

    .half__info {
      padding-left: 123px;
      padding-bottom: 0;

      .num {
        font-weight: 700;
        font-size: 64px;
        line-height: 1.38;
        letter-spacing: -2px;
        color: $color-gray3;
      }

      .title {
        margin-top: 40px;
      }

      .text {
        margin-top: 30px;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.89;
        letter-spacing: -0.5px;
      }

      .disclaimer {
        margin-top: 40px;
        font-size: 12px;
        line-height: 1.83;
        letter-spacing: -0.26px;
      }

      .logo {
        display: flex;
        align-items: center;
        margin-top: 30px;

        img {
          width: 50px;
          height: 50px;
        }

        span {
          margin-left: 14px;
          font-size: 12px;
          line-height: 1.83;
          letter-spacing: -0.26px;
        }
      }
    }

    .gak {
      &__number {
        margin-top: 80px;

        &-list {
          display: flex;

          &:first-child {
            .gak__number-item {
              &:first-child {
                border: 0;
              }
            }
          }

          &:last-child {
            margin-top: 20px;
          }
        }

        &-item {
          position: relative;
          flex: 1;
          height: 327px;
          margin-right: 20px;
          padding: 30px 40px;
          border: 1px solid #454545;

          &:last-child {
            margin-right: 0;
          }
        }

        .number {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 60px;
          line-height: 1.47;
          letter-spacing: -2px;

          &.plus {
            &:before {
              display: block;
              width: 48px;
              height: 48px;
              margin-right: 10px;
              background-image: url(/img/common/ic-plus.png);
              background-repeat: no-repeat;
              background-size: contain;
              content: '';
            }
          }
        }

        .desc {
          position: absolute;
          left: 40px;
          bottom: 30px;
          top: auto;
          font-weight: 700;
          font-size: 18px;
          line-height: 1.56;
          letter-spacing: -0.5px;
        }
      }

      &__chuncheon {
        display: flex;
        margin-top: 80px;
        padding-left: 42.3%;

        &-item {

          &:nth-child(2) {
            margin-left: 28px;
            margin-right: 40px;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        &-title {
          font-weight: 700;
          font-size: 46px;
          line-height: 1.48;
          letter-spacing: -0.01px;

          span {
            margin-left: 8px;
            font-size: 20px;
            line-height: 1.7;
            letter-spacing: -0.01px;
          }
        }

        &-desc {
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;
        }
      }

      &__global {
        padding-top: 150px;

        &-wrap {
          padding: 0 36px;

          &.is-active {
            .dotted-line {
              animation: showMap 0.3s linear forwards;
              animation-delay: 1s;
            }

            .nation-box {
              animation: showMap 0.3s linear forwards;

              &:after {
                animation: underToTop 0.3s linear forwards;
                animation-delay: 0.3s;
              }
            }
          }
        }

        &-img {
          position: relative;
          width: 1574px;
          margin: 0 0 0 auto;
        }

        .dotted-line {
          position: absolute;
          top: -11px;
          left: 211px;
          width: 1067px;
          opacity: 0;
        }

        .nation {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .nation-box {
          position: absolute;
          width: 19px;
          height: 19px;
          border-radius: 50%;

          &.color-green {
            background-color: #00ca80;
          }

          &.color-blue {
            background-color: #007dff;
          }

          &:after {
            position: absolute;
            top: -54px;
            left: 50%;
            height: 46px;
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateX(-50%);
            opacity: 0;
            content: '';
          }

          &.germany {
            top: 185px;
            left: 201px;

            &:after {
              width: 81px;
              background-image: url(/img/pc/germany.png);
            }
          }

          &.thailand {
            top: 316px;
            left: 483px;

            &:after {
              width: 77px;
              background-image: url(/img/pc/thailand.png);
            }
          }

          &.singapore {
            top: 405px;
            left: 519px;

            &:after {
              width: 88px;
              background-image: url(/img/pc/singapore.png);
            }
          }

          &.hongkong {
            top: 274px;
            left: 542px;

            &:after {
              width: 89px;
              background-image: url(/img/pc/hongkong.png);
            }
          }

          &.vietnam {
            top: 359px;
            left: 593px;

            &:after {
              width: 76px;
              background-image: url(/img/pc/vietnam.png);
            }
          }

          &.korea {
            top: 210px;
            left: 620px;

            &:after {
              width: 59px;
              background-image: url(/img/pc/korea.png);
            }
          }

          &.taiwan {
            top: 286px;
            left: 627px;

            &:after {
              width: 68px;
              background-image: url(/img/pc/taiwan.png);
            }
          }

          &.japan {
            top: 185px;
            left: 697px;

            &:after {
              width: 62px;
              background-image: url(/img/pc/japan.png);
            }
          }

          &.west-us {
            top: 206px;
            right: 439px;

            &:after {
              width: 77px;
              background-image: url(/img/pc/west-us.png);
            }
          }

          &.east-us {
            top: 188px;
            right: 289px;

            &:after {
              width: 71px;
              background-image: url(/img/pc/east-us.png);
            }
          }
        }

        &-info {
          position: absolute;
          bottom: 69px;
          left: 88px;

          .text {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 17px;
            line-height: 1.86;
            letter-spacing: -0.36px;

            &:last-child {
              margin-top: 11px;
            }

            &::before {
              display: block;
              width: 19px;
              height: 19px;
              margin-right: 12px;
              border-radius: 50%;
              content: '';
            }

            &.color-blue {
              &:before {
                background-color: #007dff;
              }
            }

            &.color-green {
              &:before {
                background-color: #00ca80;
              }
            }
          }
        }
      }

      &__region {
        display: flex;
        margin-top: 80px;
        padding: 0 60px 160px;
        padding-left: 26.82%;

        &-item {
          flex: 1;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        .img {
          position: relative;
          width: 100%;
          height: 289px;
          background-color: $color-default;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 197px;
            height: 197px;
            transform: translate(-50%, -50%);
          }
        }

        .title {
          font-weight: 800;
          margin-top: 20px;
          font-size: 22px;
          line-height: 1.64;
          letter-spacing: -0.6px;
        }

        .text {
          margin-top: 12px;
          font-size: 16px;
          line-height: 1.88;
          letter-spacing: -0.5px;
          color: #a1a1a6;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.gak {
      .content03 {
        padding: 140px 40px 200px;
        padding-left: 20.5%;
      }

      .gak {
        &__number {
          margin-top: 72px;

          &-item {
            height: 298px;
            padding: 26px 30px;
          }

          .desc {
            left: 30px;
          }
        }

        &__chuncheon {
          padding-left: 42.3%;
          white-space: nowrap;
        }

        &__global {
          &-wrap {
            padding: 0 30px;

            &.is-active {
              .nation-box {
                &:after {
                  animation: underToTop-tab 0.3s linear forwards;
                  animation-delay: 0.3s;
                }
              }
            }
          }

          &-img {
            width: 1312px;
          }

          .dotted-line {
            top: -8px;
            left: 176px;
            width: 889px;
          }

          .nation-box {
            width: 16px;
            height: 16px;

            &:after {
              top: -47px;
              height: 38px;
            }

            &.germany {
              top: 155px;
              left: 168px;

              &:after {
                width: 67px;
              }
            }

            &.thailand {
              top: 263px;
              left: 403px;

              &:after {
                width: 64px;
              }
            }

            &.singapore {
              top: 335px;
              left: 432px;

              &:after {
                width: 73px;
              }
            }

            &.hongkong {
              top: 228px;
              left: 451px;

              &:after {
                width: 74px;
              }
            }

            &.vietnam {
              top: 299px;
              left: 494px;

              &:after {
                width: 63px;
              }
            }

            &.korea {
              top: 173px;
              left: 517px;

              &:after {
                width: 52px;
              }
            }

            &.taiwan {
              top: 237px;
              left: 523px;

              &:after {
                width: 56px;
              }
            }

            &.japan {
              top: 153px;
              left: 581px;

              &:after {
                width: 52px;
              }
            }

            &.west-us {
              top: 171px;
              right: 365px;

              &:after {
                width: 64px;
              }
            }

            &.east-us {
              top: 157px;
              right: 240px;

              &:after {
                width: 59px;
              }
            }
          }

          &-info {
            bottom: 58px;
            left: 73px;

            .text {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;

              &:last-child {
                margin-top: 10px;
              }

              &::before {
                width: 16px;
                height: 16px;
                margin-right: 10px;
              }
            }
          }
        }

        &__region {
          margin-top: 60px;
          padding: 0 40px 140px;
          padding-left: 20.5%;

          .img {
            height: 264px;

            img {
              width: 180px;
              height: 180px;
            }
          }
        }
      }

      .half {
        &__info {
          padding-left: 100px;

          .text {
            margin-top: 28px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.gak {
      .track-animation {
        &__highlight-area {
          width: auto;
        }

        &__highlight-text {
          padding: 0 50px;
        }
      }

      .content03 {
        padding: 120px 30px;

        .section__title {
          font-size: 38px;
          line-height: 1.42;
          letter-spacing: -1.5px;
        }
      }

      .content05 {
        .cover {
          height: 140vh;
        }

        .full-sticky {
          margin-top: -140vh;
        }

        .cont__wrap {
          height: 140vh;
          padding-right: 2.92%;
        }

        .cont__desc {
          padding-left: 37.11%;
        }
      }

      .gak {
        &__number {
          margin-top: 98px;

          &-item {
            height: 231px;
            padding: 20px;
          }

          .number {
            font-size: 47px;
            line-height: 1.47;
            letter-spacing: -1.55px;

            &.plus {
              &:before {
                width: 38px;
                height: 38px;
                margin-right: 8px;
              }
            }
          }

          .desc {
            left: 20px;
            bottom: 20px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.5px;
          }
        }

        &__chuncheon {
          display: grid;
          grid-template-columns: repeat(2,1fr);
          // flex-wrap: wrap;
          margin-top: 60px;
          padding-left: 37.11%;
          padding-right: 9.874%;

          &-item {
            width: fit-content;
            margin: 0;

            &:nth-child(2) {
              margin-right: 0;
            }

            &:last-child {
              margin-top: 40px;
            }
          }
        }

        &__global {
          padding-top: 76px;

          &-wrap {
            padding: 0 30px;

            &.is-active {
              .nation-box {
                &:after {
                  animation: underToTop-tab-sm 0.3s linear forwards;
                  animation-delay: 0.3s;
                }
              }
            }
          }

          .dotted-line {
            top: -6px;
            left: 129px;
            width: 650px;
          }

          &-img {
            width: 960px;
            margin: 0 auto;
          }

          .nation-box {
            width: 12px;
            height: 12px;

            &:after {
              top: -30px;
              height: 38px;
            }

            &.germany {
              top: 114px;
              left: 123px;

              &:after {
                width: 49px;
              }
            }

            &.thailand {
              top: 193px;
              left: 294px;

              &:after {
                width: 47px;
              }
            }

            &.singapore {
              top: 246px;
              left: 317px;

              &:after {
                width: 54px;
              }
            }

            &.hongkong {
              top: 166px;
              left: 331px;

              &:after {
                width: 55px;
              }
            }

            &.vietnam {
              top: 219px;
              left: 362px;

              &:after {
                width: 47px;
              }
            }

            &.korea {
              top: 127px;
              left: 378px;

              &:after {
                width: 36px;
              }
            }

            &.taiwan {
              top: 174px;
              left: 383px;

              &:after {
                width: 41px;
              }
            }

            &.japan {
              top: 113px;
              left: 425px;

              &:after {
                width: 38px;
              }
            }

            &.west-us {
              top: 126px;
              right: 267px;

              &:after {
                width: 47px;
              }
            }

            &.east-us {
              top: 114px;
              right: 175px;

              &:after {
                width: 44px;
              }
            }
          }

          &-info {
            bottom: 40px;
            left: 52px;

            .text {
              font-size: 11px;
              letter-spacing: -0.22px;

              &:last-child {
                margin-top: 7px;
              }

              &::before {
                width: 12px;
                height: 12px;
                margin-right: 8px;
                border-radius: 50%;
              }
            }
          }
        }

        &__region {
          flex-wrap: wrap;
          margin-top: 80px;
          padding: 0 30px 120px;

          &-item {
            flex: unset;
            width: calc((100% - 20px) / 2);

            &:nth-child(2) {
              margin-right: 0;
            }

            &:last-child {
              margin-top: 80px;
            }
          }

          .img {
            height: 314px;

            img {
              width: 214px;
              height: 214px;
            }
          }

          .text {
            padding-right: 40px;
          }
        }
      }

      .half__info {
        padding-left: 50px;

        .num {
          font-size: 48px;
          line-height: 1.42;
          letter-spacing: -1.5px;
        }

        .title {
          margin-top: 24px;
          font-size: 38px;
          line-height: 1.42;
          letter-spacing: -1.5px;
        }

        .text {
          margin-top: 20px;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.5px;
        }
      }

      .section {
        margin-top: 180px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.gak {
      .cont__desc {
        letter-spacing: -0.5px;
        font-weight: 400;
      }

      .content03 {
        padding: 80px 30px 120px;

        .section__title {
          font-size: 32px;
          line-height: 1.5;
          letter-spacing: -1px;
        }
      }

      .gak {
        &__number {
          margin-top: 80px;

          &-list {
            &:last-child {
              margin-top: 12px;
            }
          }

          &-item {
            height: 171px;
            margin-right: 12px;
            padding: 16px;
          }

          .number {
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;

            &.plus {
              &:before {
                width: 25px;
                height: 25px;
                margin-right: 9px;
              }
            }
          }

          .desc {
            left: 16px;
            bottom: 16px;
            font-size: 12px;
            line-height: 1.67;
            letter-spacing: normal;
          }
        }

        &__chuncheon {
          margin-top: 40px;
          padding-left: 36.5%;
          padding-right: 4.8%;

          &-item {
            &:last-child {
              margin-top: 30px;
            }
          }

          &-title {
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;

            span {
              margin-left: 16px;
              font-size: 16px;
              line-height: 1.88;
              letter-spacing: -0.01px;
            }
          }

          &-desc {
            margin-top: 8px;
            font-size: 12px;
            line-height: 1.83;
            letter-spacing: -0.26px;
          }
        }

        &__global {
          &-wrap {
            overflow-y: auto;
            padding-top: 7px;
          }
        }

        &__region {
          margin-top: 100px;
          padding-bottom: 100px;

          &-item {
            width: calc((100% - 12px) / 2);
            margin-right: 12px;

            &:last-child {
              margin-top: 60px;
            }
          }

          .img {
            height: 232px;

            img {
              width: 158px;
              height: 158px;
            }
          }

          .title {
            margin-top: 16px;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }

          .text {
            margin-top: 20px;
            padding-right: 28px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }
      }

      .half__info {
        padding: 40px 30px 120px;

        .num {
          font-size: 38px;
          line-height: 1.42;
          letter-spacing: -1.5px;
        }

        .title {
          margin-top: 20px;
          font-size: 32px;
          line-height: 1.5;
          letter-spacing: -1px;
        }

        .text {
          max-width: 620px;
          margin-top: 18px;
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;
        }

        .disclaimer {
          margin-top: 32px;
          line-height: 2.2;
        }

        .logo {
          margin-top: 33px;
        }
      }

      .section {
        margin-top: 160px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.gak {
      .track-animation {
        &__highlight-area {
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 30px;
          text-align: left;
        }
      }

      .content03 {
        padding: 130px 20px 120px;

        .section__title {
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
        }
      }

      .content04 {
        .cover {
          background-image: url(/img/mo/tech-gak-sejong-mo.jpg);
        }
      }

      .content05 {
        .cover {
          background-image: url(/img/mo/tech-gak-chuncheon-mo.jpg);
        }

        .cont__wrap {
          padding: 0 20px;
        }

        .cont__desc {
          padding: 0;
        }

        .half__list {
          &:last-child {
            .half__info {
              margin-bottom: 0;
            }
          }
        }
      }

      .content06 {
        .cover {
          background-image: url(/img/mo/tech-gak-region-mo.jpg);
        }
      }

      .gak {
        &__number {
          margin-top: 40px;

          &-list {
            flex-wrap: wrap;

            &:first-child {
              .gak__number-item:first-child {
                display: none;
              }
            }

            &:last-child {
              margin-top: 11px;

              .gak__number-item:nth-child(2) {
                margin-right: 0;
              }

              .gak__number-item:last-child {
                margin-top: 11px;
              }
            }
          }

          &-item {
            flex: unset;
            width: calc((100% - 11px) / 2);
            height: 154px;
            margin-right: 11px;
            padding: 10px 12px 12px 12px;
          }

          .number {
            font-size: 28px;
            line-height: 1.36;
            letter-spacing: -0.86px;

            &.plus {
              &:before {
                width: 22px;
                height: 22px;
                margin-right: 0;
              }
            }
          }

          .desc {
            left: 12px;
            bottom: 12px;
            font-size: 12px;
            line-height: 1.83;
            letter-spacing: -0.26px;
          }
        }

        &__chuncheon {
          margin-top: 60px;
          padding: 0;

          &-item {

            &:nth-child(2) {
              margin-left: 13px;
            }

            &:last-child {
              margin-top: 24px;
            }
          }

          &-title {
            font-size: 38px;
            line-height: 1.53;
            span {
              margin-left: 4px;
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.01px;
            }
          }

          &-desc {
            line-height: 1.83;
            letter-spacing: -0.26px;
          }
        }

        &__global {
          &-wrap {
            padding: 10px 20px 0;
          }

          .dotted-line {
            top: -8px;
            left: 139px;
            width: 697px;
          }

          &-img {
            width: 1029px;
          }

          .nation-box {
            width: 13px;
            height: 13px;

            &:after {
              top: -30px;
              height: 30px;
            }

            &.germany {
              top: 120px;
              left: 133px;

              &:after {
                width: 53px;
              }
            }

            &.thailand {
              top: 204px;
              left: 315px;

              &:after {
                width: 51px;
              }
            }

            &.singapore {
              top: 262px;
              left: 339px;

              &:after {
                width: 58px;
              }
            }

            &.hongkong {
              top: 176px;
              left: 354px;

              &:after {
                width: 58px;
              }
            }

            &.vietnam {
              top: 232px;
              left: 388px;

              &:after {
                width: 50px;
              }
            }

            &.korea {
              top: 135px;
              left: 404px;

              &:after {
                width: 39px;
              }
            }

            &.taiwan {
              top: 184px;
              left: 410px;

              &:after {
                width: 44px;
              }
            }

            &.japan {
              top: 119px;
              left: 454px;

              &:after {
                width: 40px;
              }
            }

            &.west-us {
              top: 132px;
              right: 287px;

              &:after {
                width: 51px;
              }
            }

            &.east-us {
              top: 120px;
              right: 188px;

              &:after {
                width: 47px;
              }
            }
          }

          &-info {
            bottom: 53px;
            left: 80px;

            .text {
              font-size: 14px;
              letter-spacing: -0.3px;

              &:last-child {
                margin-top: 6px;
              }

              &::before {
                width: 16px;
                height: 16px;
                margin-right: 10px;
              }
            }
          }
        }

        &__region {
          margin-top: 60px;
          padding: 0 20px 100px;

          &-item {
            width: 100%;
            margin-top: 60px;
            margin-right: 0;

            &:first-child {
              margin-top: 0;
            }
          }

          .text {
            padding-right: 0;
          }
        }
      }


 
      .half__info {
        padding: 30px 20px 0;
        margin-bottom: 120px;

      }

      .half__box {
        &:first-child {
          height: 422px;
        }
      }
    }
  }
}
