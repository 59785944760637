/* esg-common */
.esg {
  .section__visual {
    padding-top: 56.25%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content03 {
    height: 1080px;
    margin-bottom: 16px;
  }

  .full-screen {
    width: 100%;

    .cont__inner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  .anchor-section {
    margin-top: 130px;
  }

  .js-nav-section {
    max-width: 1800px;
    margin: 0 auto;
    padding: 70px 0 130px 342px;

    &.full-screen {
      margin: 0;
      padding: 0;
      max-width: none;
      height: auto;
    }
  }

    .cont__title {
      color: var(--secDesc);

      em {
        color: var(--default);
      }
    }

  .section {
    &__title {
      font-weight: 800;
      font-size: 62px;
      line-height: 1.42;
      letter-spacing: -2px;
      // color: $color-default;
      color: var(--secTitle);

      &.type2 {
        .section__title {

          &-number,
          &-text {
            display: block;
          }

          &-number {
            font-weight: 700;
            font-size: 64px;
            color: $color-gray4;
          }

          &-text {
            margin-top: 30px;
            font-weight: 800;
            font-size: 48px;
            line-height: 1.5;
          }
        }
      }
    }

    &__desc {
      margin-top: 30px;
      font-weight: 800;
      font-size: 26px;
      line-height: 1.62;
      letter-spacing: -0.8px;
      color: var(--secDesc);

      &.type2 {
        margin-top: 29px;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.89;
        color: $color-default;
      }
    }
  }

  // 이미지 light/dark/kor/eng 케이스 정리 (우선순위 문제로 변경 금지)
  .type-image {
    display: none;
  }
  @at-root & {
    .type-light.type-kor {
      display: block;
    }
  }
  @at-root html.theme-dark {
    .type-dark.type-kor {
      display: block;
    }
    .type-light.type-kor {
      display: none;
    }
  }
  @at-root html[lang='en'] {
    .type-light.type-eng {
      display: block;
    }
    .type-light.type-kor {
      display: none;
    }
  }
  @at-root html[lang='en'].theme-dark {
    .type-dark.type-eng {
      display: block;
    }
    .type-dark.type-kor {
      display: none;
    }
    .type-light.type-eng {
      display: none;
    }
  }
}

@include breakpoint(pc) {
  /* esg-common */
  .esg {
    .content03 {
      height: 900px;
      margin-bottom: 0;
    }

    .anchor-section {
      margin-top: 110px;
    }

    .js-nav-section {
      max-width: 1520px;
      padding: 70px 0 110px 286px;
    }

    .section {
      &__title {
        font-size: 48px;
        line-height: 1.5;
        letter-spacing: -1.5px;
      }

      &__desc {
        font-size: 22px;
        line-height: 1.73;
        letter-spacing: -0.6px;
      }
    }
  }
}

@include breakpoint(tablet) {
  /* esg-common */
  .esg {
    .section__visual {
      padding-top: 768px;
    }

    .content03 {
      height: 768px;
    }

    .anchor-section {
      margin-top: 0;

      .js-nav-section {
        &:first-of-type {
          padding-top: 140px;
        }
      }
    }

    .js-nav-section {
      max-width: none;
      margin: 0;
      padding: 120px 30px 0;
    }

    .section {
      &__title {
        &.type2 {
          .section__title {

            &-number,
            &-text {
              display: block;
            }

            &-number {
              font-size: 56px;
            }

            &-text {
              margin-top: 10px;
            }
          }
        }
      }

      &__desc {
        font-size: 18px;
        line-height: 1.67;
        letter-spacing: -0.5px;

        &.type2 {
          margin-top: 20px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  /* esg-common */
  .esg {
    .anchor-section {
      .js-nav-section {
        &:first-of-type {
          padding-top: 120px;
        }
      }
    }

    .content03 {
      height: 576px;

      .cont__inner {
        padding: 0 60px;
        text-align: left;
      }
    }

    .cont {
      &__text {
        .txt, .em {
          font-size: 32px;
          line-height: 1.5;
          letter-spacing: -1px;
        }
      }
    }

    .section {
      &__title {
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1px;

        &.type2 {
          .section__title {
            &-number {
              font-size: 48px;
            }

            &-text {
              margin-top: 16px;
              font-size: 42px;
            }
          }
        }
      }

      &__desc {
        font-size: 16px;
        line-height: 1.67;
        letter-spacing: -0.5px;
      }
    }
  }
}

@include breakpoint(mobile) {
  /* esg-common */
  .esg {
    .full-screen {
      .cont__inner {
        height: auto;
        min-height: 812px;
        padding: 0 20px;
      }
    }

    .anchor-section {
      .js-nav-section {
        &:first-of-type {
          padding-top: 115px;
        }
      }
    }

    .content03 {
      height: auto;
      min-height: 812px;
    }

    .js-nav-section {
      padding: 85px 20px 15px 20px;
    }

    .section {
      &__title {
        font-size: 32px;

        &.type2 {
          .section__title {
            &-number {
              font-size: 38px;
            }

            &-text {
              font-size: 32px;
            }
          }
        }
      }

      &__desc {
        font-size: 16px;
        line-height: 1.75;
        letter-spacing: -0.5px;

        &.type2 {
          margin-top: 20px;
          font-size: 16px;
        }

        br {
          display: none;
        }
      }
    }
  }
}
