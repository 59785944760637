.#{$component-name} {
  &.ir-events {
    .ir-table {
      colgroup col {
        &:nth-child(1) {
          width: auto;
        }

        &:nth-child(2) {
          width: 381px;
        }

        &:nth-child(3) {
          width: 133px;
        }

        &:nth-child(4) {
          width: 180px;
        }
      }

      tbody td {
        &.is-disabled {
          >a {
            display: none;
          }
        }
      }
    }
  }

  &.ir-meeting, &.ir-complete {
    .ir-common__button {
      &.apply {
        text-align: center;

        &.type2 {
          text-align: center;
        }
      }
    }
  }

  .ir-guide {
    .ir-schedule__procedure {
      padding: 40px 0 80px;
      border-top: 1px solid $color-default;
    }

    .procedure-info__list{
      display: flex;
      flex-wrap: nowrap;
    }
    
    .procedure-info__item {
      display: flex;
      flex-wrap: nowrap;

      &:nth-child(1) {

        .txt {
          width: max-content;
        }
      }

      @at-root .lang-en & {
        display: flex;
        flex-wrap: nowrap;
      }

      .txt {
        flex: 9;
        line-height: 24px;
      }

    }
  }

  .ir-reserve {
    display: flex;
    flex-direction: column;

    .calendar {
      &-btn {
        overflow: hidden;
        margin-top: 22px;
      }
    }

    .ir-common__button.outlink {
      float: right;
      height: auto;
      flex-direction: row-reverse;
      padding-left: 0;
      letter-spacing: -.3px;

      &::before {
        position: unset;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        transform: none;
      }
    }

    .btn-wrap {
      justify-content: center;
      margin-top: 102px;
    }
  }

  &.ir-apply, &.ir-revise {
    .calendar {
      border: 1px solid var(--border01);

      &-nav {
        position: relative;
        justify-content: space-between;
        padding: 20px 20px 15px;

        &::after {
          position: absolute;
          left: 20px;
          right: 20px;
          bottom: 0;
          height: 1px;
          background-color: $color-gray4;
          content: '';
          @at-root .theme-dark & {
            background-color: #2F2E30;
          }
        }

        &__month {
          padding: 0;
          font-size: 20px;
          line-height: 42px;
        }
      }

      &-table {
        font-size: 12px;
        line-height: 18px;

        &__wrap {
          padding: 20px 6px 25px;
          border-bottom: none;
        }

        colgroup col {
          &:first-child {
            width: auto;
          }
        }

        thead th {
          padding: 0 0 8px;
          font-weight: 800;
          text-align: center;

          &:first-child {
            padding-left: 0;
          }
        }

        tbody {
          tr {
            border-top: none;
          }

          td {
            height: 54px;
          }
        }

        .cell {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px 14px;
        }

        .day {
          font-size: 14px;
        }

        .state {
          display: none;
        }

      }
    }

    .col {
      .row-title {
        .desc {
          &.is-hide {
            display: none;
          }
        }
      }

      .dropdown__select {
        width: 100%;
      }
    }

    .btn-wrap {
      position: relative;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      max-width: 1039px;
      width: 100%;

      .ir-common__button.outlink {
        position: absolute;
        right: 0;
        flex-direction: row-reverse;
        margin-left: 0;
        padding-left: 0;
        font-weight: 700;
        letter-spacing: -.3px;

        &::before {
          position: unset;
          width: 24px;
          height: 24px;
          margin-left: 10px;
          transform: none;
        }
      }
    }
  }

  &.ir-apply {
    .ir-schedule {
      &__box {
        &:first-child {
          border-top: 1px solid var(--border03);
        }
      }
    }
  }

  &.ir-revise {
    .ir-schedule {
      &__procedure {
        padding-bottom: 60px;
      }
    }

    .btn-wrap {
      .ir-common__button.outlink {
        top: 12px;
      }
    }

    .revise-desc {
      max-width: 1039px;
      width: 100%;
      margin-top: 30px;
      color: var(--flowerDesc);
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -.3px;
      text-align: center;
    }
  }

  &.ir-confirm {
    .ir-schedule {
      &__head {
        padding-bottom: 0;
      }

      &__box {
        &:first-child {
          padding-bottom: 80px;
        }

        .col-wrap {
          padding-bottom: 80px;
        }
      }

      &__contact {
        padding-top: 20px;

        &-item {
          margin-left: 206px;
          font-size: 16px;
          line-height: 34px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .btn-wrap {
      margin-top: 60px;
    }
  }

  &.ir-settle {
    .ir-schedule {
      &__box {
        &:first-child {
          padding-bottom: 30px;
        }

        &-inner {
          max-width: 1038px;
          width: 100%;
          margin-top: 80px;
        }
      }
    }

    .btn-wrap {
      justify-content: center;
      max-width: 1038px;
      width: 100%;
      margin-top: 100px;
    }
  }

  &.ir-complete, &.ir-cancel {
    .btn-wrap {
      margin-top: 80px;
    }
  }

  &.ir-cancel {
    .ir-schedule {
      &__procedure {
        &-item .num {
          background: $color-point url(../img/common/ic-cancel-orange.png) no-repeat center;
          background-size: contain;
        }
      }
    }
  }

  .registration-num {
    & span {
      font-size: 22px;
      line-height: 1.91;
    }

    &__title {
      font-weight: 800;
      letter-spacing: -0.6px;
      color: var(--default);
    }

    &__order {
      margin-left: 20px;
      font-weight: 900;
      color: $color-point;
    }
  }

  .ir-schedule .registration-num {
    margin: 20px 0 -10px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--border03);
  }

}

.theme-dark {
  .navercorp {
    &.ir-apply, &.ir-revise, &.ir-confirm, &.ir-settle, &.ir-meeting {
      .ir-schedule__box {
        border-top-color: $color-default;
      }
    }

    &.ir-meeting {
      .procedure-info__item {
        background-color: #2f2e30;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ir-events {
      .ir-table {
        colgroup col {
          &:nth-child(2) {
            width: 290px;
          }

          &:nth-child(3) {
            width: 127px;
          }

          &:nth-child(4) {
            width: 142px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir-events {
      .ir-table {
        colgroup col {
          &:nth-child(2) {
            width: 269px;
          }

          &:nth-child(4) {
            width: 122px;
          }
        }
      }
    }

    &.ir-guide {
      .procedure-info__item {
        .txt {
          @at-root .lang-en & {
            width: 176px;
          }
        }
      }
    }

    &.ir-apply, &.ir-revise {
      .calendar {
        &-table {
          colgroup col {

            &:first-child,
            &:last-child {
              width: auto;
            }
          }
        }
      }

      .btn-wrap, .revise-desc {
        max-width: none;
      }
    }


    &.ir-settle {
      .ir-schedule {
        &__box {
          &:first-child {
            padding-bottom: 40px;
          }

          &-inner {
            max-width: none;
          }
        }
      }

      .btn-wrap {
        max-width: none;
      }
    }

    .ir-schedule .registration-num {
      margin: 10px 0 20px;
      padding-bottom: 50px;
    }
    
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .ir-reserve {
      .calendar {
        &-btn {
          order: 4;
          margin-top: 40px;
          text-align: center;
        }
      }

      .ir-common__button.outlink {
        float: none;
      }

      .btn-wrap {
        margin-top: 50px;
      }
    }

    .ir-guide {
      .ir-schedule__procedure {
        &-item {
          .txt {
            @at-root .navercorp.lang-en {
              .procedure-info__item .txt {
                font-size: 14px;
                max-width: 176px;
                line-height: 22px;
                white-space: nowrap;          
              }
            }
          }
        }
      }
    }

    &.ir-apply, &.ir-revise {
      .calendar {
        &-nav {
          border-bottom: none;
        }

        &-table {
          &__wrap {
            padding: 23px 17px 25px;
          }

          .cell {
            padding: 9px 3.5px;
          }
        }

        &-guide {
          display: none;
        }

      }

      .btn-wrap {
        flex-direction: column;

        .ir-common__button {
          &.apply {
            width: 335px;
          }

          &.outlink {
            position: unset;
            justify-content: center;
            margin-top: 40px;
          }
        }
      }
    }

    &.ir-revise {
      .ir-schedule__procedure {
        padding-bottom: 50px;
      }

      .btn-wrap {
        .ir-common__button.outlink {
          width: auto;
          order: 3;
          margin-top: 50px;
        }
      }

      .revise-desc {
        order: 2;
        margin-top: 20px;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: -.26px;
      }
    }

    &.ir-confirm {
      .ir-schedule {
        &__box {
          &:first-child {
            padding-bottom: 60px;
          }

          .col-wrap {
            padding: 60px 0;
          }
        }

        &__contact-item {
          margin-left: 100px;
        }
      }

      .btn-wrap {
        margin-top: 40px;
      }
    }

    &.ir-complete, &.ir-cancel {
      .btn-wrap {
        margin-top: 60px;
      }
    }

    &.ir-settle {
      .ir-schedule {
        &__box-inner {
          margin-top: 70px;
        }
      }

      .btn-wrap {
        margin-top: 60px;
      }
    }
    
    .registration-num {
      & span {
        font-size: 18px;
        line-height: 1.89;
      }
    }

    .ir-schedule .registration-num {
      margin: -10px 0 40px;
      padding-bottom: 30px;
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {

    .ir-guide {
      .ir-schedule__procedure {
        padding: 40px 0 60px;
      }
    }

    .ir-reserve {
      .btn-wrap {
        margin-top: 50px;
      }
    }

    &.ir-apply, &.ir-revise {
      .calendar {
        &-table {
          &__wrap {
            margin: 0;
          }
        }
      }

      .btn-wrap {
        .ir-common__button {
          &.apply {
            width: 100%
          }
        }
      }
    }

    &.ir-apply, &.ir-revise {
      .box-relative {
        .col-wrap {
          position: relative;
          padding-bottom: 274px;
        }
      }

      .row-enquiry {
        position: absolute;
        bottom: 76px;
        width: 100%;
      }
    }

    &.ir-confirm {
      .ir-schedule {
        &__contact {
          padding-top: 30px;

          &-item {
            margin-left: 0;
          }
        }
      }
    }

    &.ir-settle {
      .ir-schedule {
        &__box-inner {
          margin-top: 50px;
        }
      }
    }

    .registration-num {
      & span {
        display: block;
      }

      &__order {
        margin-left: 0;
      }
    }

  }
}
