.#{$component-name} {
  &.naver-main:not(.naver-history, .naver-awards) {
    .container {
      padding: 0;
    }
  }

  &.naver-main {
    .section__inner {
      max-width: 1800px;
      margin: 0 auto;
    }

    .main-visual {
      .track-animation {
        height: 300vh;

        .logo {
          display: inline-block;
          width: 476px;
          height: 90px;
          background: url(/img/pc/naver-main_logo.svg) no-repeat center center;
          background-size: cover;
        }

        &__main-image {
          text-align: center;
        }

        &__main-text {
          font-size: 80px;
        }

        &__box {
          &.box01 {
            .track-animation__main-area {
              width: 790px;
            }
          }
        }

        &__highlight-area {
          width: 1045px;
        }
      }
    }

    .common-copy {
      position: relative;

      .section__inner {
        padding-top: 260px;
        padding-bottom: 160px;
      }

      .copy {
        &__title {
          display: flex;
          flex-direction: column;
          font-weight: 800;
          font-size: 72px;
          line-height: 1.36;
          letter-spacing: -2.4px;
          color: var(--default);
        }

        &__desc {
          margin-top: 120px;
          margin-left: 454px;
          font-weight: 800;
          font-size: 38px;
          line-height: 1.53;
          letter-spacing: -1.5px;
          transform: translateY(-10%);

          @at-root .theme-dark & {
            color: var(--default);
          }

          &.is-active {
            transform: translateY(0);
            transition: all 0.3s cubic-bezier(0.37, 0, 0.63, 1);
            
            .em{
              color: var(--default);
              transition: all 0.8s cubic-bezier(0.37, 0, 0.63, 1);
              @at-root .theme-dark & {
                color: var(--default);
              }
            }
          }

        }
      }

      .btn-arrow {
        bottom: 27px;
        width: 44px;
        height: 50px;

        &__box {
          top: auto;
          bottom: 166px;
        }
      }
    }

    .common-card {
      .section__inner {
        padding-top: 187px;
        padding-bottom: 187px;
      }

      .card {
        overflow: hidden;

        &__list {
          margin: -80px 0 0 -20px;
        }

        &__item {
          width: calc((100% - 40px) / 2);
          margin: 80px 0 0 20px;

          &:nth-child(2) .thumbnail {
            background-image: url(/img/pc/naver-tech-card-img2.jpg);
          }

          &:nth-child(3) .thumbnail {
            background-image: url(/img/pc/naver-tech-card-img3.jpg);
          }

          &:nth-child(4) .thumbnail {
            background-image: url(/img/pc/naver-tech-card-img4.jpg);
          }
        }

        &__img {
          .thumbnail {
            display: inline-block;
            width: 100%;
            height: 100%;
            padding-top: 72.6%;
            background: url(/img/pc/naver-tech-card-img1.jpg) no-repeat center center;
            background-size: cover;
          }
        }

        &__txt {
          margin-top: 20px;

          .tit {
            font-weight: 800;
            font-size: 22px;
            line-height: 1.64;
            letter-spacing: -0.6px;
          }

          .desc {
            max-width: 350px;
            margin-top: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.4px;
            color: var(--color-sub);
          }

          .link {
            position: relative;
            margin-top: 30px;
            padding-bottom: 5px;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: -0.5px;

            &::after {
              position: absolute;
              display: block;
              content: '';
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              background-color: var(--default);
            }
          }
        }
      }

      &.line-3 {
        .card {
          &__list {
            margin: -70px 0 0 -20px;
          }

          &__item {
            width: calc((100% - 60px) / 3);
            margin: 70px 0 0 20px;

            &:nth-child(2) .thumbnail {
              background-image: url(/img/pc/naver-service-card-img2.jpg);
            }

            &:nth-child(3) .thumbnail {
              background-image: url(/img/pc/naver-service-card-img3.jpg);
            }

            &:nth-child(4) .thumbnail {
              background-image: url(/img/pc/naver-service-card-img4.jpg);
            }

            &:nth-child(5) .thumbnail {
              background-image: url(/img/pc/naver-service-card-img5.jpg);
            }

            &:nth-child(6) .thumbnail {
              background-image: url(/img/pc/naver-service-card-img6.jpg);
            }

            &:nth-child(7) .thumbnail {
              background-image: url(/img/pc/naver-service-card-img7.jpg);
            }

            &:nth-child(8) .thumbnail {
              background-image: url(/img/pc/naver-service-card-img8.jpg);
            }

            &:nth-child(9) .thumbnail {
              background-image: url(/img/pc/naver-service-card-img9.jpg);
            }
          }

          &__img {
            .thumbnail {
              padding-top: 100%;
              background-image: url(/img/pc/naver-service-card-img1.jpg);
            }
          }
        }
      }
    }

    .global {
      &__cont {
        padding: 0 30px;

        .cont {
          &__item {
            display: flex;
            margin-top: 14px;

            &:first-child {
              margin-top: 0;
            }

            &:nth-child(2) {
              .cont__txt {
                background-color: #5c46ff;

                .tit, .desc {
                  color: $color-white;
                }
              }

              .cont__link .thumbnail {
                background-image: url(/img/pc/naver-global_img2.png);
              }
            }

            &:nth-child(3) {
              .cont__txt {
                background-color: #004fff;

                .tit, .desc {
                  color: $color-white;
                }
              }

              .cont__link .thumbnail {
                background-image: url(/img/pc/naver-global_img3.png);
              }
            }

            &.#{$activeClass} {

              .cont__txt,
              .cont__link {
                transform: translateY(0);
              }
            }

            &.reverse {
              flex-direction: row-reverse;

              .cont__link {
                margin-left: 0;
                transition-delay: 0s;
              }

              .cont__txt {
                margin-left: 14px;
                transition-delay: .3s;
              }
            }
          }

          &__txt {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 38.5%;
            background-color: #15ef73;
            transform: translateY(30px);
            transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 1s cubic-bezier(0.39, 0.575, 0.565, 1);

            .txt__inner {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              height: 100%;
              padding: 50px;
            }

            &::after {
              display: block;
              content: '';
              padding-bottom: 100%;
            }

            .tit {
              font-weight: 800;
              font-size: 74px;
              line-height: 1.3;
              letter-spacing: -0.1px;
              text-transform: uppercase;
            }

            .desc {
              font-weight: 800;
              font-size: 32px;
              line-height: 1.5;
              letter-spacing: -1px;
            }
          }

          &__link {
            position: relative;
            display: inline-block;
            width: calc(61.5% - 14px);
            margin-left: 14px;
            transform: translateY(30px);
            transition: transform 1s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 1s cubic-bezier(0.39, 0.575, 0.565, 1);
            transition-delay: .3s;

            &:hover {
              .quick-link__button {
                background-color: $color-point;
                background-image: url(/img/common/ic-quicklink.png);
              }
            }

            .thumbnail {
              display: block;
              width: 100%;
              height: 100%;
              background: url(/img/pc/naver-global_img1.png) no-repeat center center;
              background-size: cover;
            }

            .quick-link__button {
              position: absolute;
              right: 50px;
              bottom: 50px;
              display: inline-block;
              background-image: url(/img/common/ic-quicklink-black.png);
              background-color: $color-white;
              transition: all 0.3s;
            }
          }
        }
      }
    }

    .tech {
      margin-top: 119px;

      .track-animation {
        height: 300vh;
        
        .video__player {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: all .6s cubic-bezier(0.37, 0, 0.63, 1);

          &.video01 {
            &.#{$hideClass} {
              height: 0;
            }
          }
        }

        &__main-area {
          width: 1115px;
        }

        &__main-text {
          &.main-text01 {
            &.#{$showClass} {
              transform: translateY(-100%);
            }

            &.#{$hideClass} {
              opacity: 0;
              transform: translateY(-230%);
              transition: all .8s cubic-bezier(0.37, 0, 0.63, 1);
            }
          }

          &.main-text02 {
            &.#{$showClass} {
              transform: translateY(calc(-50% + 50px));
            }

            &.#{$hideClass} {
              opacity: 0;
              transform: translateY(calc(-180% + 50px));
              transition: all .8s cubic-bezier(0.37, 0, 0.63, 1);
            }
          }
        }

        &__highlight-area {
          width: 1060px;
        }

        &__highlight-text {
          font-size: 38px;

          .desc {
            display: block;
            margin-top: 14px;
            margin-left: -5px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -0.5px;
          }

          .num {
            display: block;
            font-size: 90px;
            line-height: 1;
          }

          .per {
            display: inline-block;
            margin-left: 9px;
            font-size: 62px;
            vertical-align: bottom;
          }

          &:nth-child(1) {
            width: 740px;

            &.#{$showClass} {
              transform: translateY(-50%);
            }
          }

          &:nth-child(2) {
            width: 200px;
            right: 0;
            left: auto;

            &.#{$showClass} {
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .naver-service {
      overflow: hidden;

      .copy{
        &__desc{
          padding-left: 201px;
        }
      }

      &__cont {
        position: relative;
        padding-top: 138.3%;

        .cont {
          position: absolute;
          top: 0;
          left: 50%;
          width: 104.6%;
          transform: translateX(-50%);

          &__list {
            margin: -12px 0 0 -12px;
            font-size: 0;

            &:not(:first-of-type) {
              margin-top: 0;
            }

            &:nth-child(2) {
              .cont__item {
                .img {
                  background-image: url(/img/pc/naver-service-img6.png);
                }

                &:nth-child(2) {
                  transition-delay: .2s;

                  .img {
                    background-image: url(/img/pc/naver-service-img7.png);
                  }
                }

                &:nth-child(3) {
                  transition-delay: .6s;

                  .img {
                    background-image: url(/img/pc/naver-service-img8.png);
                  }
                }

                &:nth-child(4) {
                  transition-delay: .2s;

                  .img {
                    background-image: url(/img/pc/naver-service-img9.png);
                  }
                }

                &:nth-child(5) {
                  transition-delay: .6s;

                  .img {
                    background-image: url(/img/pc/naver-service-img10.png);
                  }
                }
              }
            }

            &:nth-child(3) {
              .cont__item {
                .img {
                  background-image: url(/img/pc/naver-service-img11.png);
                }

                &:nth-child(2) {
                  transition-delay: .2s;

                  .img {
                    background-image: url(/img/pc/naver-service-img12.png);
                  }
                }

                &:nth-child(3) {
                  transition-delay: .6s;

                  .img {
                    background-image: url(/img/pc/naver-service-img13.png);
                  }
                }

                &:nth-child(4) {
                  transition-delay: .2s;

                  .img {
                    background-image: url(/img/pc/naver-service-img14.png);
                  }
                }

                &:nth-child(5) {
                  transition-delay: .6s;

                  .img {
                    background-image: url(/img/pc/naver-service-img15.png);
                  }
                }
              }
            }

            &.#{$activeClass} {
              .cont__item {
                transform: translateY(0);
              }
            }
          }

          &__item {
            display: inline-block;
            width: calc((100% - 60px) / 5);
            margin: 12px 0 0 12px;
            transform: translateY(60px);
            transition: transform .8s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform .8s cubic-bezier(0.39, 0.575, 0.565, 1);

            .img {
              display: inline-block;
              width: 100%;
              height: 100%;
              padding-top: 223.6%;
              border-radius: 49px;
              background: url(/img/pc/naver-service-img1.png) no-repeat center center;
              background-size: cover;
            }

            &:nth-child(2) {
              transition-delay: .2s;

              .img {
                background-image: url(/img/pc/naver-service-img2.png);
              }
            }

            &:nth-child(3) {
              transition-delay: .6s;

              .img {
                background-image: url(/img/pc/naver-service-img3.png);
              }
            }

            &:nth-child(4) {
              transition-delay: .2s;

              .img {
                background-image: url(/img/pc/naver-service-img4.png);
              }
            }

            &:nth-child(5) {
              transition-delay: .6s;

              .img {
                background-image: url(/img/pc/naver-service-img5.png);
              }
            }

              @at-root .#{$component-name} {
              &.lang-en {
                .naver-service__cont {
                  .cont__list{
                    &:nth-child(1){
                      .cont__item{
                        &:nth-child(3){
                          .img{
                            background-image: url(/img/pc/naver-service-img3-en.png);
                          }
                        }
                      }
                    }
                    &:nth-child(2){
                      .cont__item{
                        &:nth-child(4){
                          .img{
                            background-image: url(/img/pc/naver-service-img9-en.png);
                          }
                        }
                      }
                    }

                    &:nth-child(3){
                      .cont__item{
                        &:nth-child(2){
                          .img{
                            background-image: url(/img/pc/naver-service-img12-en.png);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .history {
      .common-copy{

        .copy{
          &__desc{
            padding-left: 0;
            margin-left: 289px;
          }
        }  
      }


      .cont {
        &__wrap {
          display: flex;
          margin-top: 120px;

          &:first-child {
            margin-top: 0;
          }

          &.#{$activeClass} {
            .cont__main .title {
              color: var(--default);
            }
          }

          &.no-image {
            .cont__btn .mark {
              display: none;
            }

            .btn__wrap {
              margin-left: 0;
  
              .ir-common__button.btn-more {
                padding-right: 12px;
                width: 205px;
                height: 50px;
                .more {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }

        &__main {
          position: relative;
          width: 21.9%;
          height: 100%;
          text-align: center;

          .title {
            font-weight: 700;
            font-size: 100px;
            line-height: 1.4;
            white-space: nowrap;
            color: var(--halfNum);
            transition: all 0.3s;
          }

          .thumbnail {
            position: absolute;
            display: none;
            left: 50%;
            top: 50%;
            width: 152.5%;
            height: 100%;
            padding-top: 102.7%;
            transform: translateX(-50%);
            background: url(/img/pc/naver-history-img1.png) no-repeat center center;
            background-size: cover;
          }
        }

        &__list {
          width: 60%;
          margin-left: 269px;
        }

        &__item {
          border-bottom: 1px solid $color-gray4;
          @at-root .theme-dark & {
          border-bottom: 1px solid var(--tbodyBorder);
          }

          &:first-child{
          border-top: 1px solid $color-gray4;
          @at-root .theme-dark & {
            border-top: 1px solid var(--tbodyBorder);
          }

          }

          &:hover {
            .wrap .tit {
              text-decoration-color: $color-default;
            }

            .mark {
              background-color: var(--default);
            }
          }

          .thumbnail-mo {
            display: none;

            .thumbnail {
              display: inline-block;
              width: 100%;
              height: 100%;
              padding-top: 67.4%;
              background: url(/img/pc/naver-history-img1.png) no-repeat center center;
              background-size: cover;
            }
          }
        }

        &__btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 30px 20px 30px 0;
          cursor: default;

          .wrap {
            display: flex;

            .date,
            .tit {
              display: flex;
              align-items: center;
              font-weight: 800;
              font-size: 16px;
              line-height: 1.63;
              letter-spacing: -0.3px;
              color: var(--default);
            }

            .date {
              width: 200px;
              margin-right: 40px;
            }

            .tit {
              flex: 1;
              text-decoration: underline;
              text-underline-position: under;
              text-underline-offset: 3px;
              text-decoration-color: transparent;
              transition: all .3s;
              width: 100%;
            }
          }

          .mark {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-left: 26px;
            border-radius: 50%;
            border: 1px solid var(--default);
            background-color: transparent;
            transition: all 0.3s;
          }
        }

        .btn__wrap {
          display: flex;
          margin-top: 60px;

          .ir-common__button.btn-more {
            display: flex;
            justify-content: space-between;

            &::before,
            &::after {
              display: none;
            }

            .more {
              position: relative;
              display: inline-block;


              &::after {
                display: block;
                position: absolute;
                background-image: url(/img/common/ic-more.svg);
                width: 24px;
                height: 24px;
                content: "";
              }
            }
          }
        }
      }
    }

    .info {
      display: flex;
      margin-top: 150px;

      &__img {
        overflow: hidden;
        width: 50%;
        font-size: 0;

        .img {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding-top: 100%;
          background: url(/img/pc/naver-info-img.jpg) no-repeat center center;
          background-size: cover;
          transform: scale(1.3);
          transition: all .8s;
        }

        &.#{$activeClass} {
          .img {
            transform: scale(1);
          }
        }
      }

      &__txt {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        padding: 60px;
        background-color: $color-default;

        .logo {
          display: inline-block;
          width: 156px;
          height: 30px;
          background: url(/img/common/logo-w.svg) no-repeat center center;
          background-size: 100%;
        }

        .txt {
          &__item {
            display: flex;
            align-items: center;
            font-size: 0;
            padding: 4px 0;
            border-top: 1px solid rgb(255, 255, 255, .2);

            &:first-child {
              border: none;
            }

            .tit, .desc {
              display: inline-block;
              font-weight: 400;
              font-size: 14px;
              line-height: 3;
              letter-spacing: -0.56px;
              color: $color-white;
            }

            .tit {
              width: 82px;
              color: #a1a1a6;
              @at-root .#{$component-name} {
                &.lang-en {
                  .info__txt .txt__item .tit {
                    width: 155px;
                  }
                }
              }
            }

            .desc {
              margin-left: 100px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    .relate-content {
      padding: 70px 0 130px;
      background-color: var(--relateContent);
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.naver-main {
      .section__inner {
        max-width: 1520px;
        padding-left: 286px;
      }

      .common-copy {
        .section__inner {
          padding-top: 166px;
          padding-bottom: 166px;
        }

        .copy {

          &__desc {
            margin-left: 280px;
          }
        }
      }

      .common-card {
        
        .section__inner {
          padding-top: 150px;
          padding-bottom: 100px;
        }
      }

      .global {
        &__cont {
          padding: 0 20px;

          .cont {
            &__link {
              .quick-link__button {
                right: 40px;
                bottom: 40px;
              }
            }

            &__txt {
              .txt__inner {
                padding: 40px;
              }

              .tit {
                font-size: 64px;
                line-height: 1.25;
              }

              .desc {
                font-size: 24px;
                letter-spacing: -0.6px;
              }

              &.white {
                color: $color-white;
              }
            }
          }
        }
      }

      .tech {
        margin-top: 150px;

        .track-animation {
          &__main-area {
            width: 945px;
          }

          &__highlight-area {
            width: 1042px;
            margin-left: 48px;
          }

          &__highlight-text {
            .desc {
              margin-left: 0;
            }
          }
        }
      }

      .naver-service {
        &__cont {
          margin-top: 0;
          padding-top: 137.5%;

          .cont {
            width: 104.6%;

            &__item {
              .img {
                padding-top: 223%;
              }
            }
          }
        }
      }

      .history {
        .cont {
          &__main {
            width: 27.7%;
          }

          &__list {
            margin-left: 181px;
          }
        }
      }

      .info {
        &__txt {
          padding: 40px;

          .logo {
            width: 130px;
            height: 25px;
          }

          .txt__item .desc {
            width: 520px;
            margin-left: 50px;
          }
          .txt__item .tit {
            line-height: 1.71;
          }
        }
      }

      .relate-content {
        padding: 63px 0 128px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.naver-main:not(.naver-history, .naver-awards) {
      .container {
        margin-bottom: 140px;
      }
    }

    .track-animation {
      &__highlight {
        &-text {
          text-align: left;
        }
      }
    }

    &.naver-main {
      .section__inner {
        max-width: none;
        padding: 120px 0;
      }

      .history {
        .section__inner {
          padding-top: 40px;
          padding-bottom: 0;
        }

      }

      .main-visual {
        .track-animation {
          &__highlight-area {
            width: 804px;
          }
          &__highlight-text {
            text-align: left;
          }
        }
      }

      .common-copy {
        height: auto;
        padding: 0 30px;

        .section__inner {
          padding-top: 140px;
          padding-bottom: 120px;
        }

        .copy {
          &__title {
            font-size: 64px;
            line-height: 1.38;
            letter-spacing: -2px;
          }

          &__desc {
            margin-left: 0;
            margin-top: 100px;
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
          }
        }

        .btn-arrow {
          width: 42px;
          height: 48px;

          &__box {
            left: 30px;
            bottom: 120px;
            transform: translateX(0);
          }
        }
      }

      .common-card {
        .card {
          padding: 0 30px;

          &__list {
            margin-top: -70px;
          }

          &__item {
            margin-top: 70px;
          }

          &__img {
            .thumbnail {
              padding-top: 66.8%;
            }
          }

          &__txt {
            .desc {
              max-width: none;
            }
          }
        }
      }

      .global {
        .common-copy {
          .section__inner {
            padding-top: 200px;
          }

          .copy {
            &__desc {
              margin-left: 328px;
            }
          }
        }

        &__cont {
          .cont {
            .txt__inner {
              padding: 24px;
            }

            &__link {
              .quick-link__button {
                right: 30px;
                bottom: 30px;
              }
            }

            &__txt {
              .tit {
                font-size: 42px;
                line-height: 1.33;
                letter-spacing: -0.1px;
              }

              .desc {
                font-size: 15px;
                line-height: 1.58;
                letter-spacing: -0.38px;
              }
            }
          }
        }
      }

      .tech {
        margin-top: 120px;
        
        .track-animation {

          &__main-text {
            &.main-text02 {
              &.#{$showClass} {
                transform: translateY(calc(-50% + 100px));
              }
            }
          }

          &__main-area {
            width: 804px;
          }

          &__highlight-area {
            width: 804px;
            margin-left: 0;
            transform: translate(-50%, 50%);
            transition: all .6s cubic-bezier(0.37, 0, 0.63, 1);

            &.#{$showClass} {
              transform: translate(-50%, 0);
            }
          }

          &__highlight-text {
            position: relative;
            transform: none;

            .desc {
              margin-left: 0;
              font-size: 16px;
            }

            .num {
              font-size: 80px;
            }

            .per {
              font-size: 56px;
            }

            &:nth-child(1) {
              width: auto;

              &.#{$showClass} {
                transform: none;
              }
            }

            &:nth-child(2) {
              width: auto;
              margin-top: 52px;

              &.#{$showClass} {
                transform: none;
              }
            }
          }
        }
          
      }

      .naver-service {

        &__cont {
          padding-top: 137.2%;

          .cont {
            width: 104%;

            &__list {
              margin: -6px 0 0 -6px;
            }

            &__item {
              width: calc((100% - 30px) / 5);
              margin: 6px 0 0 6px;

              .img {
                border-radius: 26px;
              }
            }
          }
        }
      }

      .history {

        &__cont {
          margin-top: 0;
        }

        .cont {
          padding: 0 30px 0 113px;

          &__wrap {
            margin-top: 100px;
          }

          &__main {
            width: auto;

            .title {
              font-size: 76px;
              line-height: 1.45;
            }

            .thumbnail {
              width: 144.7%;
              padding-top: 97.4%;
            }
          }

          &__list {
            margin-left: 180px;
          }
          
          &__btn {
            .wrap {

              .date {
                margin-right: 0;
                line-height: 1.63;
                width: 110px;
                margin-right: 40px;
              }

              .tit {
                width: 140%;
                line-height: 1.75;
              }
            }
          }
        }
      }

      .info {
        margin-top: 140px;

        &__txt {
          padding: 26px;

          .logo {
            width: 110px;
            height: 22px;
          }

          .txt__item {
            padding: 8px 0;

            .tit,
            .desc {
              line-height: 1.86;
              font-size: 12px;
            }

            .tit {
              display: flex;
              align-items: center;
            }

            .desc {
              width: 300px;
              margin-left: 26px;
            }
          }
        }
        
      }

      .relate-content {
        padding: 60px 0 80px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.naver-main:not(.naver-history, .naver-awards) {
      .container {
        margin-bottom: 120px;
      }
    }

    &.naver-main {
      .section__inner {
        padding: 80px 0;
      }

      .main-visual {
        .track-animation {
          .logo {
            width: 358px;
            height: 66px;
          }

          &__main-text {
            font-size: 64px;
          }

          &__box {
            &.box01 {
              .track-animation__main-area {
                width: 608px;
              }
            }
          }

          &__highlight-area {
            width: 608px;
          }
        }
      }

      .common-copy {
        .section {
          &__inner {
            padding-top: 80px;
            padding-bottom: 100px;
          }
        }

        .copy {
          &__title {
            font-size: 48px;
            line-height: 1.42;
            letter-spacing: -1.5px;
          }

          &__desc {
            margin-top: 80px;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
          }
        }

        .btn-arrow {
          width: 36px;
          height: 41px;

          &__box {
            bottom: 100px;
          }
        }
      }

      .common-card {

        .card,
        &.line-3 .card {
          &__list {
            margin-left: -12px;
          }

          &__item {
            width: calc((100% - 24px) / 2);
            margin-left: 12px;
          }

          &__txt {
            margin-top: 16px;

            .tit {
              font-size: 18px;
              line-height: 1.67;
              letter-spacing: -0.5px;
            }

            .desc {
              margin-top: 6px;
              font-size: 16px;
              line-height: 1.75;
              letter-spacing: -0.4px;
            }

            .link {
              margin-top: 20px;
              padding-bottom: 0;
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }
        }
      }

      .global {
        .common-copy {
          .section {
            &__inner {
              padding-top: 140px;
            }
          }

          .copy {
            &__desc {
              margin-left: 182px;
            }
          }
        }

        &__cont {
          .cont {
            .txt__inner {
              padding: 16px;
            }

            &__link {
              .quick-link__button {
                right: 20px;
                bottom: 20px;
              }
            }

            &__txt {
              .tit {
                font-size: 32px;
                line-height: 1.31;
                letter-spacing: -0.1px;
              }

              .desc {
                font-size: 12px;
                line-height: 1.83;
                letter-spacing: -0.26px;
              }
            }
          }
        }
      }

      .tech {

        .track-animation {
          &__main-area {
            width: 608px;
          }

          &__main-text {
            &.main-text02 {
              transform: translateY(calc(-50% + 35px));

              &.#{$showClass} {
                transform: translateY(calc(-50% + 35px));
              }

              &.#{$hideClass} {
                transform: translateY(calc(-180% + 35px))
              }
            }
          }

          &__highlight-area {
            width: 608px;
          }

          &__highlight-text {
            &:nth-child(2) {
              margin-top: 52px;
            }
          }
        }
      }

      .naver-service {
      

        &__cont {
          padding-top: 146.4%;

          .cont {
            width: 111%;

            &__list {
              margin: -5px 0 0 -5px;
            }

            &__item {
              width: calc((100% - 25px) / 5);
              margin: 5px 0 0 5px;

              .img {
                border-radius: 20px;
              }
            }
          }
        }
      }

      .history {
        .common-copy {
          .copy {
            &__desc {
              margin-left: 240px;
            }
          }
        }

        .cont {
          padding: 0 30px;

          &__wrap {
            flex-direction: column;
            margin-top: 70px;
          }

          &__main {
            width: auto;
            text-align: left;

            .title {
              font-size: 42px;
              line-height: normal;
            }

            .thumbnail {
              display: none !important;
            }
          }

          &__list {
            width: 100%;
            margin-top: 30px;
            margin-left: 0;
          }

          &__item {
            .thumbnail-mo {
              padding: 0 253px 20px 120px;
              font-size: 0;
            }
          }

          &__btn {
            padding: 20px 28px 20px 0;

            .wrap {

              .date,
              .tit {
                font-size: 14px;
                line-height: 1.71;
                letter-spacing: -0.2px;
                font-weight: 700;            
              }

              .date {
                display: inline-block;
                width: 70px;
                margin-right: 120px;

                @at-root .#{$component-name} {
                  &.lang-en {
                    .history .cont__btn .wrap .date {
                      width: 130px;
                      display: flex;
                    }
                  }
                }
              }

              .tit {
                width: 400px;
              }
            }

            .mark {
              width: 8px;
              height: 8px;
              flex: none;
            }
          }
        }
      }

      .info {
        margin-top: 160px;

        &__img {
          .img {
            padding-top: 147%;
            background-position: 20%;
          }
        }

        &__txt {
          padding: 40px 30px 50px;

          .txt__item {
            padding: 14px 0;

            .tit,
            .desc {
              line-height: 1.57;
            }

            .desc {
              margin-left: 31px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.naver-main:not(.naver-history, .naver-awards) {
      .container {
        margin-bottom: 100px;
      }
    }

    &.naver-main {
      .main-visual {
        .track-animation {
          .logo {
            width: 264px;
            height: 50px;
          }

          &__main-text {
            padding: 0 30px;
            font-size: 38px;
          }

          &__box {
            &.box01 {
              .track-animation__main-area {
                width: auto;
                max-width: 375px;
              }
            }
          }

          &__highlight-area {
            width: auto;
            max-width: 375px;
            
          }

          &__highlight-text {
            padding: 0 20px;
            text-align: left;
            font-size: 24px;
          }
        }
      }

      .common-copy {
        padding: 0 20px;

        .section__inner {
          padding-top: 80px;
          padding-bottom: 115px;
        }

        .copy {
          &__title {
            font-size: 36px;
            line-height: 1.39;
            letter-spacing: -1.8px;
          }

          &__desc {
            max-width: 322px;
            margin-top: 40px;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }

        .btn-arrow {
          width: 30px;
          height: 34px;

          &__box {
            left: 20px;
            bottom: 50px;
          }
        }
      }

      .common-card {

        .card,
        &.line-3 .card {
          padding: 0 20px;

          &__list {
            margin-left: 0;
          }

          &__item {
            width: 100%;
            margin-left: 0;
          }
        }

        .section__inner{
          padding-top: 80px;
        }
      }

      .global {
        .common-copy {
          .section__inner {
            padding-top: 150px
          }

          .copy {
            &__desc {
              margin-left: 0;
              margin-bottom: 20px;
              max-width: 370px;
            }
          }
        }

        &__cont {
          margin-top: 50px;

          .cont {
            .txt__inner {
              padding: 20px;
            }

            &__item {
              flex-direction: column;
              margin-top: 12px;

              &.reverse {
                flex-direction: column;

                .cont__txt {
                  margin-left: 0;
                }
              }

              &:nth-child(2) .cont__link .thumbnail {
                background-image: url(/img/mo/naver-global_img2.png);
              }

              &:nth-child(3) .cont__link .thumbnail {
                background-image: url(/img/mo/naver-global_img3.png);
              }

              
            }

            &__link {
              width: 100%;
              margin-top: 14px;
              margin-left: 0;

              .thumbnail {
                padding-top: 134.3%;
                background-image: url(/img/mo/naver-global_img1.png);
              }
            }

            &__txt {
              width: 100%;

              .tit {
                font-size: 38px;
                line-height: 1.26;
                letter-spacing: -0.01px;
              }

              .desc {
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: -0.5px;
              }
            }
          }
          
        }
      }

      .tech {
        margin-top: 100px;

        .track-animation {
          &__main-area {
            width: auto;
            max-width: 375px;
          }

          &__main-text {
            padding: 0 30px;

            &.main-text02 {
              transform: translateY(calc(-50% + 58px));

              &.#{$showClass} {
                transform: translateY(calc(-50% + 58px));
              }

              &.#{$hideClass} {
                transform: translateY(calc(-180% + 58px))
              }
            }
          }

          &__highlight-area {
            width: auto;
            max-width: 315px;
          }

          &__highlight-text {
            font-size: 24px;
            text-align: left;

            .desc {
              font-size: 14px;
            }

            .per {
              font-size: 36px;
            }

            .num {
              font-size: 64px;
            }

            &:nth-child(2) {
              margin-top: 52px;
            }
          }
        }
      }

      .naver-service {
        .common-copy {
          .copy {
            &__desc {
              margin-left: 0;
              padding-left: 0;
              margin-bottom: 20px;
            }
          }
        }

        &__cont {
          margin-top: 18px;
          padding-top: 315.5%;

          .cont {
            width: 239.7%;

            &__list {
              margin: -6px 0 0 -6px;
              transform: translateX(27%);

              &:nth-child(2) {
                transform: translateX(-6%);
              }

              &:nth-child(3) {
                transform: translateX(-18%);
              }
            }

            &__item {
              width: calc((100% - 30px) / 5);
              margin: 6px 0 0 6px;
            }
          }
        }
      }

      .history {
        .section{
  
          &__inner{
            padding-left: 0;
          }
        }

        .common-copy {
          .section__inner {
            padding-bottom: 115px;
          }

          .copy {
            &__desc {
              margin-left: 0;
              margin-bottom: 20px;
            }
          }
        }

        &__cont {
          margin-top: 18px;


        }

        .cont {
          padding: 0 20px;
          
          &__item {
            .thumbnail-mo {
              padding: 0 0 20px 0;
            }
          }

          &__btn {
            padding-right: 10px;

            .wrap {
              .tit {
                max-width: 195px;
                display: flex;
                align-items: center;

                @at-root .#{$component-name} {
                  &.navercorp.naver-main.lang-en {
                     .history .cont__btn .wrap .tit {
                       display: flex;
                       max-width: 320px;
                    }
                  }
                }

              }

              .date {
                width: 90px;
                margin-right: 0;
              }

              &:last-child {
                .tit {
                  width: 100%;
                  max-width: 250px;
                }
              }
            }
          }

          .btn__wrap {
            flex-direction: column;

            .ir-common__button{
              &.btn-more{
                font-size: 14px;
                line-height: 24px;
                letter-spacing: -0.3;
              }
            }
          }
        }
      }

      .info {
        flex-direction: column;
        margin-top: 60px;

        &__img {
          width: 100%;

          .img {
            padding-top: 100%;
          }
        }

        &__txt {
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;

          .txt__list {
            margin-top: 114px;
          }

          .txt__item {
            padding: 14px 0;

            .tit,
            .desc {
              line-height: 1.57;
            }

            .tit {
              white-space: nowrap;
            }

            .desc {
              margin-left: 30px;
            }
          }
        }
      }

      .relate-content {
        padding: 36px 0 80px;
      }
    }
  }
}
