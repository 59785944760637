/* service-common */
.service {
  .section {
    &__visual {
      position: relative;
      height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &.is-video {
        padding-top: 0;
      }
      
      .btn-arrow {
        position: absolute;
        bottom: 80px;
        left: 50%;
        z-index: 10;
        width: 50px;
        height: 57px;
        background: url('/img/common/ic-arrow-down.png') no-repeat;
        background-size: 100%;
        animation: arrowAnimation 1.6s cubic-bezier(.455, .03, .515, .955) infinite both;
        transition: background-image .4s;
        margin-left: -25px;

        &.#{$stickyClass} {
          position: sticky;
          left: calc(50% - 25px);
        }
      }
    }

    &__contents {
      margin-top: 170px;

      .js-nav-section {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &__title {
      margin-top: 41px;
      font-weight: 700;
      font-size: 64px;
      line-height: 1.38;
      color: $color-default;
      letter-spacing: -2px;

      em {
        font-weight: 800;
      }
    }

    &__outlink {
      display: inline-flex;
      align-items: center;
      position: relative;
      height: 54px;
      padding: 0 62px 0 20px;
      margin-top: 160px;
      font-weight: 700;
      font-size: 16px;
      color: white;
      letter-spacing: -0.4px;
      transition: background 0.3s;
      background-color: var(--outlinkBg03);

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;
        width: 24px;
        height: 24px;
        background: url(/img/common/ic-outlink-white2.png) no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
      }

      &:hover {
        background: $color-point;
      }
    }

    &__mobile-frame {
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 355px;
      height: 607px;
      border: 6px solid #2F2E30;
      border-bottom: 0;
      border-radius: 32px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transform: translateX(-50%);

      &-wrap {
        position: relative;
        height: 687px;
        background: #f2f6ff;
      }
    }
  }

  .video__player {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .cont {
    &__title {
      color: #bbbbbe;

      em {
        color: #1d1d1f;
      }
    }
  }

  .js-nav-section {
    padding: 70px 0 250px;
    text-align: center;
  }

  &__icon {
    width: 110px;
    margin: 0 auto;

    &.is-border {
      overflow: hidden;
      border: 1px solid #e4e6ea;
      border-radius: 24%;

      @at-root .theme-dark & {
        border: 0;
      }
    }
  }

  &__btn {
    overflow: hidden;
    width: 34px;
    height: 34px;
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;
    transition: all 0.3s;

    &-wrap {
      margin-top: 50px;
      font-size: 0;
    }

    +.service__btn {
      margin-left: 18px;
    }

    &.is-text {
      overflow: visible;
      width: auto;
      min-width: 164px;
      padding-left: 44px;
      font-weight: 800;
      font-size: 18px;
      line-height: 1.89;
      color: #111;
      letter-spacing: -0.5px;
      text-indent: unset;

      &:hover{
        color: #86868b;
      }
    }

    &--google {
      background-image: url(../img/pc/service-icon-google.png);
      &:hover{
        background-image: url(../img/pc/service-icon-google-on.png);
      }

      @at-root .theme-dark & {
      background-image: url(../img/pc/service-icon-google-on.png);
      &:hover{
        background-image: url(../img/pc/service-icon-google.png);
      }
      }
    }

    &--apple {
      background-image: url(../img/pc/service-icon-apple.png);
      &:hover{
        background-image: url(../img/pc/service-icon-apple-on.png);
      }
      
      @at-root .theme-dark & {
      background-image: url(../img/pc/service-icon-apple-on.png);
      &:hover{
        background-image: url(../img/pc/service-icon-apple.png);
      }
      }
    }

    &--onestore {
      background-image: url(../img/pc/service-icon-onestore.png);
      &:hover{
        background-image: url(../img/pc/service-icon-onestore-on.png);
      }
      
      @at-root .theme-dark & {
      background-image: url(../img/pc/service-icon-onestore-on.png);
      &:hover{
        background-image: url(../img/pc/service-icon-onestore.png);
      }
      }
    }

    &--home {
      background-image: url(../img/pc/service-icon-home.png);
      &:hover{
        background-image: url(../img/pc/service-icon-home-on.png);
      }
      
      @at-root .theme-dark & {
      background-image: url(../img/pc/service-icon-home-on.png);
      &:hover{
        background-image: url(../img/pc/service-icon-home.png);
      }
      }
    }

    &--onestore {
      background-image: url(../img/pc/service-icon-onestore.png);
      &:hover{
        background-image: url(../img/pc/service-icon-onestore-on.png);
      }
      
      @at-root .theme-dark & {
      background-image: url(../img/pc/service-icon-onestore-on.png);
      &:hover{
        background-image: url(../img/pc/service-icon-onestore.png);
      }
      }
    }
  }

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  &__img {
    position: relative;
    overflow: hidden;
    width: 19.7%;
    margin: 0 auto;
    border-radius: 50px;
    border: 9px solid #2F2E30;
    background: var(--background01);
    isolation: isolate;

    &-wrap {
      position: relative;
      margin-top: 112px;

      &.is-tab {
        .service {
          &__img {
            width: 58.38%;
            border-radius: 37px;
          }
        }
      }
    }
  }

  &__text {
    margin-top: 120px;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.91;
    letter-spacing: -0.6px;
    color: var(--default);
  }

  @keyframes slideUp {
    0% {
      transform: translateY(0%);
    }

    100% {
      transform: translateY(-52%);
    }
  }

  @keyframes slideNext {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  @keyframes slideNext2 {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-50%);
    }
  }

  @keyframes slideNext3 {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-66.66%);
    }
  }

  @keyframes arrowAnimation {
    0% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .service__outlink {
    position: relative;
    width: 960px;
    height: 120px;
    padding: 0 48px;
    background: #333;
    font-size: 22px;
    color: white;
    line-height: 120px;
    font-weight: 700;

    &:hover {
      &:after {
        width: 100%;
      }
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #ff5f00;
      transition: width .5s ease;
      content: '';
    }

    .txt {
      display: inline-block;
      position: relative;
      padding-right: 44px;
      z-index: 1;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 34px;
        height: 34px;
        margin-left: 10px;
        background: url(/img/common/ic-quicklink2-w.png) no-repeat center;
        background-size: contain;
        transform: translateY(-50%);
      }
    }

    &-wrap {
      display: flex;
      justify-content: flex-end;
    }
      
    @at-root .service.lang-en {
      .service__outlink {
        &-wrap {
          display: none;
          }
      }
    }
  }
}

.theme-dark {
  .navercorp.service {
    .cont__title {
      color: #6e6e73;

      em {
        color: $color-white;
      }
    }

    .section__title {
      color: $color-white;
    }

    .service__btn {
      color: $color-white;
    }
  }
}

@include breakpoint(pc) {
  .service {
    .section {
      &__contents {
        margin-top: 130px;
      }

      &__title {
        margin-top: 36px;
      }
    }

    .btn-arrow {
      bottom: 60px;
      width: 42px;
      height: 47px;
      margin-left: -21px;
    }

    .js-nav-section {
      padding-bottom: 190px;
    }

    &__img {
      &-wrap {
        margin-top: 116px;
      }
    }

    &__text {
      margin-top: 120px;
    }
    
    .service__outlink {
      width: 790px;
      height: 100px;
      padding: 0 40px;
      font-size: 18px;
      line-height: 100px;

      .txt {
        padding-right: 30px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .service {
    .section {
      &__visual {
        .btn-arrow {
          bottom: 60px;
          width: 42px;
          height: 47px;
          margin-left: -21px;

          &.#{$stickyClass} {
            left: calc(50% - 21px);
          }
        }
      }

      &__contents {
        margin-top: 80px;
      }

      &__title {
        margin-top: 30px;
        font-size: 48px;
        line-height: 1.42;
        letter-spacing: -1.5px;
      }
    }

    .js-nav-section {
      padding-bottom: 130px;
    }

    &__icon {
      width: 90px;
    }

    &__btn {
      &-wrap {
        margin-top: 40px;
      }
    }

    &__img {
      width: 24.7%;
      border-radius: 40px;
      border-width: 7px;

      &-wrap {
        margin-top: 90px;

        &.is-tab {
          display: flex;
          align-items: flex-end;
          margin-top: 90px;
          height: 420px;

          .service {
            &__bg {
              display: none;
            }

            &__img {
              position: absolute;
              overflow: hidden;
              left: 50%;
              bottom: 0;
              width: 662px;
              height: 355px;
              border-radius: 24px;
              border-bottom: 0;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    &__text {
      margin-top: 80px;
      font-size: 18px;
      line-height: 1.89;
      letter-spacing: -0.5px;
    }
    
    .service__outlink {
      width: 512px;
      height: 100px;
      line-height: 100px;

      .txt {
        padding-right: 36px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .service {
    .section {
      &__visual {
        .btn-arrow {
          bottom: 50px;
          width: 34px;
          height: 38px;

          &.#{$stickyClass} {
            left: calc(50% - 17px);
          }
        }
      }

      &__contents {
        margin-top: 46px;
      }

      &__title {
        margin-top: 26px;
        font-size: 32px;
        line-height: 1.38;
        letter-spacing: -1px;
      }

      &__outlink {
        height: 50px;
        padding-right: 52px;
        font-size: 14px;
        letter-spacing: 0;
      }
    }

    .btn-arrow {
      bottom: 50px;
      width: 34px;
      height: 38px;
      margin-left: -17px;
    }

    .js-nav-section {
      padding: 64px 0 97px;
    }

    &__icon {
      width: 74px;

      &.is-border {
        border-radius: 18px;
      }
    }

    &__btn {
      width: 28px;
      height: 28px;

      &-wrap {
        margin-top: 30px;
      }

      +.service__btn {
        margin-left: 16px;
      }

      &.is-text {
        padding-left: 10px;
        font-size: 16px;
        line-height: 1.88;
      }
    }

    &__img {
      border-radius: 26px;
      border-width: 5px;

      &-wrap {
        margin-top: 60px;

        &.is-tab {
          margin-top: 112px;
          height: 335px;

          .service {
            &__img {
              width: 529px;
              height: 285px;
            }
          }
        }
      }
    }

    &__text {
      margin-top: 60px;
      font-size: 16px;
      line-height: 1.75;
      letter-spacing: -0.5px;
    }
    
    .service__outlink {
      width: 384px;
      height: 80px;
      padding: 0 24px;
      line-height: 80px;
    }
  }
}

@include breakpoint(mobile) {
  .service {
    .section {
      &__visual {
        .btn-arrow {
          bottom: 40px;
          width: 30px;
          height: 34px;

          &.#{$stickyClass} {
            left: calc(50% - 15px);
          }
        }
      }

      &__title {
        margin-top: 27px;
      }

      &__contents {
        margin-top: 46px;
      }
    }

    &__btn-wrap {

      .service__btn {
        &.is-text {
          position: relative;
          left: 10px;
        }
      }

      .service__btn + .service__btn {
        margin-left: 12px;
      } 

    }

    .js-nav-section {
      padding-bottom: 55px;
    }

    &__img {
      width: 53.4%;
      border-width: 5px;

      &-wrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 52px;

        &.is-tab {
          margin-top: 60px;
          height: 100vw;
          background: none;

          .service {
            &__bg {
              top: 50%;
              height: 100vw;
              transform: translate(-50%, -50%);
            }

            &__img {
              left: auto;
              right: 0;
              bottom: auto;
              top: 50%;
              width: 89%;
              max-width: 575px;
              height: 421px;
              margin: 0;
              border-radius: 20px;
              border-right: 0;
              border-bottom: 5px solid #000;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              transform: translate(0, -50%);

              img {
                width: auto;
                height: 100%;
              }
            }
          }
        }
      }
    }

    &__text {
      margin-top: 58px;
      padding: 0 20px;
    }
    
    .service__outlink {
      width: 100%;
      height: 64px;
      padding: 0 20px 0;
      line-height: 64px;
      background: #262626;
      transition: none;
      

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background: #262626;
        transition: none;
        content: '';
      }
      &-wrap {
        background: #262626;
        padding-top: 100px;
      }
    }
  }
}
