.#{$component-name} {
  &.cloud {
    .section__visual {
      background-image: url(/img/pc/service-cloud-main.jpg);
    }

    .service__bg {
      height: 87.963%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .service__img {
      img {
        width: 101%;
      }
    }

    .js-nav-section {
      &.platform,
      &.clova {
        .section__img {
          margin-top: 100px;
          padding-top: 36.574%;
          background-image: url(/img/pc/service-cloud-platform.jpg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }

      &.clova {
        .service__icon {
          img {
            width: 100%;
            border-radius: 24.7px;

            @at-root .theme-dark & {
              border: solid 1.7px #2f2e30;
            }
          }
        }
        .section__img {
          background-image: url(/img/pc/service-cloud-clova.jpg);
        }
      }

      &.works {
        .service__img-wrap {
          margin-top: 160px;
        }
        .service__bg {
          // background-image: url(/img/pc/service-cloud-bg01.png);
          // image-rendering: -webkit-optimize-contrast;
          // transform: translate3d(-50%, -50%, 0);
          // backface-visibility: hidden;

          position: relative;
          height: 36.6vw;
          width: auto;
          top: auto;
          left: auto;
          transform: translate(0, 0);

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100%;
            width: 100%;
            object-fit: cover;
            transform: translate3d(-50%, -50%, 0);
            image-rendering: -webkit-optimize-contrast;
            backface-visibility: hidden;

            &.img-mo {
              display: none;
            }
          }
        }
        .service__img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.whale {
        .service__bg {
          background-image: url(https://static-homepage.pstatic.net/cdn-static/navercorp/service/service-cloud-bg02.png);
        }
      }

      &.papago {
        .service__bg {
          background-image: url(/img/pc/service-cloud-bg03.png);
        }
      }
    }

    .cloud {
      &__inner {
        max-width: 1345px;
        margin: 100px auto 0;
        padding-top: 100px;
        border-top: 1px solid var(--border01);
        text-align: left;
      }

      &__platform {
        display: flex;
        max-width: 1345px;
        margin: 100px auto 0;
        text-align: left;

        &-item {
          width: calc((100% - 40px) / 3);
          margin-right: 20px;

          &:first-child {
            .img {
              &:after {
                background-image: url(/img/pc/service-cloud-platform01.png);
              }
            }
          }

          &:nth-child(2) {
            .img {
              &:after {
                background-image: url(/img/pc/service-cloud-platform02.png);
              }
            }
          }

          &:last-child {
            margin-right: 0;

            .img {
              &:after {
                background-image: url(/img/pc/service-cloud-platform03.png);
              }
            }
          }
        }

        .img {
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 69.65%;
          background-color: var(--privacyBg);

          &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 188px;
            height: 188px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transform: translate(-50%, -50%);
            content: '';
          }
        }

        .title {
          margin-top: 20px;
          font-weight: 800;
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
          color: var(--default);
        }

        .text {
          margin-top: 16px;
          padding-right: 106px;
          font-size: 16px;
          line-height: 1.88;
          letter-spacing: -0.5px;
          color: var(--color-sub03);
        }
      }

      &__title {
        font-weight: 800;
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1px;
        color: var(--default);
      }

      &__desc {
        margin-top: 30px;
        font-size: 16px;
        line-height: 1.75;
        letter-spacing: -0.5px;
        color: var(--color-sub);
      }

      &__solution {
        max-width: 1345px;
        margin: 120px auto 0;
        padding-top: 100px;
        border-top: 1px solid var(--border01);
        text-align: left;

        &-list {
          overflow: hidden;
          height: 260px;
          margin-left: -8px;
          margin-right: -8px;

          &.slide-top {
            margin-top: 60px;
          }

          &.slide-bottom {
            margin-top: 10px;
          }
        }

        &-item {
          position: relative;
          float: left;
          width: 260px;
          height: 260px;
          margin: 0 6px;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            display: block;
          }
        }
      }

      &__service {
        overflow: hidden;

        &-list {
          display: flex;
          width: max-content;
          margin-top: 80px;

          &.slide-top {
            animation: serviceRight 90s linear infinite;
          }

          &.slide-bottom {
            margin-top: 10px;
            animation: serviceLeft 90s linear infinite;
          }
        }

        &-item {
          width: 225px;
          height: 225px;
          margin-right: 10px;

          .img-default {
            display: block;
          }

          .img-dark {
            display: none;
          }
        }
      }

      &__global {
        overflow: hidden;

        &-info {
          display: flex;
          margin-top: 60px;

          .title {
            font-weight: 800;
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
            color: var(--default);
          }

          .text {
            margin-top: 12px;
            font-size: 16px;
            line-height: 1.88;
            letter-spacing: -0.5px;
            color: #6e6e73;
          }
        }

        &-text {
          width: 467px;

          &:first-child {
            margin-right: 216px;
          }
        }

        &-img {
          margin-top: 100px;
          padding: 98px 0;
          background-color: var(--privacyBg);
        }

        &-list {
          display: flex;
          width: max-content;

          &.slide-top {
            animation: globalRight 90s linear infinite;
          }

          &.slide-bottom {
            margin-top: 60px;
            animation: globalLeft 90s linear infinite;
          }
        }

        &-item {
          width: 240px;
          margin-right: 120px;

          .img-default {
            display: block;
          }

          .img-dark {
            display: none;
          }
        }
      }
    }

    @keyframes solutionLeft {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-715px);
      }
    }

    @keyframes solutionRight {
      0% {
        transform: translateX(-715px);
      }

      100% {
        transform: translateX(0);
      }
    }

    @keyframes serviceLeft {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-5160px);
      }
    }

    @keyframes serviceRight {
      0% {
        transform: translateX(-4690px);
      }

      100% {
        transform: translateX(0);
      }
    }

    @keyframes globalLeft {
      0% {
        transform: translateX(0);
      }

      100% {
        transform: translateX(-4920px);
      }
    }

    @keyframes globalRight {
      0% {
        transform: translateX(-4920px);
      }

      100% {
        transform: translateX(0);
      }
    }
  }
}

.theme-dark {
  .navercorp.cloud {
    .cloud {
      &__service {
        &-item {
          .img-default {
            display: none;
          }

          .img-dark {
            display: block;
          }
        }
      }

      &__global {
        &-item {
          .img-default {
            display: none;
          }

          .img-dark {
            display: block;
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.cloud {
      .service__img-wrap {
        height: 87.628%;
      }

      .js-nav-section {
        &.platform,
        &.clova {
          .section__img {
            padding-top: 36.25%;
          }
        }
        &.works {
          .service__img-wrap {
            margin-top: 156px;
          }
          .service__bg {
            height: 36.7vw;
          }
          .service__text {
            margin-top: 162px;
          }
        }
      }

      .cloud {
        &__inner {
          max-width: 1134px;
          margin-top: 80px;
          padding-top: 80px;
        }

        &__platform {
          max-width: 1134px;
          margin-top: 80px;

          .img {
            &:after {
              width: 160px;
              height: 160px;
            }
          }

          .title {
            margin-top: 26px;
          }

          .text {
            margin-top: 24px;
            padding: 0;
          }
        }

        &__solution {
          max-width: 1134px;
          margin-top: 100px;
          &-group {
            overflow: hidden;
            margin: 0 auto;
            margin-top: 60px;
          }
          &-list {
            overflow: hidden;
            height: 219px;
            margin: 0 -6px;
          }
          &-item {
            position: relative;
            float: left;
            width: 219px;
            height: 219px;
            margin: 0 5px;
          }
        }
      }
    }

    &__service {
      &-list {
        margin-top: 60px;
      }
    }

    &__global {
      &-text {
        width: 476px;

        &:first-child {
          margin-right: 102px;
        }
      }

      &-img {
        margin-top: 80px;
        padding: 81px 0;
      }

      &-list {
        &.slide-top {
          animation: globalRight-pc 90s linear infinite;
        }

        &.slide-bottom {
          margin-top: 50px;
          animation: globalLeft-pc 90s linear infinite;
        }
      }

      &-item {
        width: 200px;
        margin-right: 100px;
      }
    }
  }

  @keyframes globalLeft-pc {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-4100px);
    }
  }

  @keyframes globalRight-pc {
    0% {
      transform: translateX(-4100px);
    }

    100% {
      transform: translateX(0);
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.cloud {
      .service__bg {
        height: 79%;
      }

      .js-nav-section {
        &.platform,
        &.clova {
          .section__img {
            padding-top: 41.017%;
            margin-top: 90px;
          }
        }
        &.works {
          .service__img-wrap {
            margin-top: 146px;
          }
          .service__bg {
            height: 41.4vw;
          }
          .service__text {
            margin-top: 137px;
          }
        }
      }

      .cloud {
        &__inner {
          max-width: none;
          margin: 80px 30px 0;
        }

        &__platform {
          max-width: none;
          margin: 80px 30px 0;

          .img {
            &:after {
              width: 135px;
              height: 135px;
            }
          }

          .title {
            font-size: 18px;
            line-height: 1.89;
            letter-spacing: -0.5px;
          }

          .text {
            margin-top: 10px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }

        &__title {
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
        }

        &__desc {
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;
        }

        &__solution {
          max-width: none;
          margin: 80px 30px auto;
          &-group {
            overflow: hidden;
          }

          &-list {
            overflow: hidden;
            width: 970px;
            height: 186px;
            margin: 0 auto;
            &.slide-bottom {
              margin-top: 8px;
            }
          }

          &-item {
            width: 186px;
            height: 186px;
            margin: 0 4px;
          }
        }

        &__service {
          &-list {
            margin-top: 60px;

            &.slide-bottom {
              margin-top: 8px;
            }
          }

          &-item {
            width: 186px;
            height: 186px;
            margin-right: 8px;
          }
        }

        &__global {
          &-info {
            margin-top: 30px;

            .title {
              font-size: 16px;
              line-height: 1.88;
              letter-spacing: -0.5px;
            }

            .text {
              margin-top: 10px;
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }

          &-text {
            width: 460px;

            &:first-child {
              margin-right: 33px;
            }
          }

          &-img {
            margin-top: 50px;
            padding: 62px 0;
          }

          &-list {
            &.slide-bottom {
              margin-top: 36px;
            }
          }

          &-item {
            width: 177px;
            margin-right: 80px;
          }
        }
      }

      @keyframes serviceLeft-tab {
        0% {
          transform: translateX(0);
        }

        100% {
          transform: translateX(-4260px);
        }
      }

      @keyframes serviceRight-tab {
        0% {
          transform: translateX(-3872px);
        }

        100% {
          transform: translateX(0);
        }
      }

      @keyframes globalLeft-tab {
        0% {
          transform: translateX(0);
        }

        100% {
          transform: translateX(-3518px);
        }
      }

      @keyframes globalRight-tab {
        0% {
          transform: translateX(-3518px);
        }

        100% {
          transform: translateX(0);
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.cloud {
      .service__bg {
        height: 83.898%;
      }

      .js-nav-section {
        &.platform,
        &.clova {
          .section__img {
            margin-top: 60px;
            padding-top: 48.83%;
          }
        }

        &.works {
          .service__img-wrap {
            margin-top: 80px;
          }
          .service__bg {
            height: 47vw;
          }
          .service__text {
            margin-top: 80px;
          }
        }

        .service {
          &__text {
            max-width: 680px;
            margin: 60px auto;
          }
        }
      }

      .cloud {
        &__inner {
          margin: 60px 30px 0;
          padding-top: 60px;
        }

        &__platform {
          flex-wrap: wrap;

          &-item {
            width: calc((100% - 12px) / 2);
            margin-right: 12px;

            &:nth-child(2) {
              margin-right: 0;
            }

            &:last-child {
              margin-top: 60px;
            }
          }

          .img {
            padding-top: 66.66%;

            &:after {
              width: 153px;
              height: 153px;
            }
          }

          .title {
            margin-top: 16px;
          }
        }

        &__solution {
          margin: 50px 30px 0;

          &-group {
            overflow: hidden;
          }

          &-list {
            margin-top: 50px;
            width: 100%;
            height: 18.83vw;
            overflow: hidden;
            margin: 0;

            &.slide-top {
              margin-top: 50px;
              margin-bottom: -6px;
            }

            &.slide-bottom {
              margin-top: 4px;
            }
          }

          &-item {
            width: calc((100% - 30px) / 5);
            height: 18.83vw;
            margin: 0 3px;
          }
        }

        &__service {
          &-list {
            margin-top: 50px;
            width: max-content;
            &.slide-top {
              animation: serviceRight-tab-sm 90s linear infinite;
            }

            &.slide-bottom {
              animation: serviceLeft-tab-sm 90s linear infinite;
              margin-top: 6px;
            }
          }

          &-item {
            width: 137px;
            height: 137px;
            margin-right: 6px;
          }
        }

        &__global {
          &-info {
            display: block;
            margin-top: 0;
          }

          &-text {
            margin-top: 40px;

            &:first-child {
              margin-right: 0;
            }
          }

          &-img {
            margin-top: 40px;
            padding: 47px 0;
          }

          &-list {
            &.slide-top {
              animation: globalRight-tab-sm 90s linear infinite;
            }

            &.slide-bottom {
              margin-top: 26px;
              animation: globalLeft-tab-sm 90s linear infinite;
            }
          }

          &-item {
            width: 129px;
            margin-right: 50px;
          }
        }
      }

      @keyframes serviceLeft-tab-sm {
        0% {
          transform: translateX(0);
        }

        100% {
          transform: translateX(-3140px);
        }
      }

      @keyframes serviceRight-tab-sm {
        0% {
          transform: translateX(-2854px);
        }

        100% {
          transform: translateX(0);
        }
      }

      @keyframes globalLeft-tab-sm {
        0% {
          transform: translateX(0);
        }

        100% {
          transform: translateX(-2456px);
        }
      }

      @keyframes globalRight-tab-sm {
        0% {
          transform: translateX(-2456px);
        }

        100% {
          transform: translateX(0);
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .section__contents {
      overflow: hidden;
    }
    &.cloud {
      .section__visual {
        background-image: url(/img/mo/service-cloud-main.jpg);
      }

      .service__bg {
        height: 84.086%;
      }

      .js-nav-section {
        .service {
          &__text {
            max-width: 375px;
            margin: 60px auto;
          }
        }

        &.platform,
        &.clova {
          .section__img {
            margin-top: 60px;
            padding-top: 100%;
            background-image: url(/img/mo/service-cloud-platform.jpg);
          }
        }

        &.clova {
          .section__img {
            background-image: url(/img/mo/service-cloud-clova.jpg);
          }
        }

        &.works {
          .service__img-wrap {
            margin-top: 50px;
          }
          .service__bg {
            // background-image: url(/img/mo/service-cloud-bg01.png);
            // background-size: contain;
            // backface-visibility: unset;

            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 95.4vw;
            transform: translate(-50%, -50%);

            img {
              &.img-pc {
                display: none;
              }
              &.img-mo {
                display: block;
              }
            }
          }
          .service__img {
            position: unset;
            transform: translate(0, 0);
          }
          .service__text {
            margin-top: 62px;
          }
        }

        &.whale {
          .service__bg {
            background-image: url(/img/mo/service-cloud-bg02.png);
          }
        }

        &.papago {
          .service__bg {
            background-image: url(/img/mo/service-cloud-bg03.png);
          }
        }
      }

      .cloud {
        &__inner {
          margin: 60px 20px 0;
        }

        &__desc {
          margin-top: 20px;
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;
        }

        &__platform {
          margin: 80px 20px 0;

          &-item {
            width: 100%;
            margin-right: 0;
            margin-top: 60px;

            &:first-child {
              margin-top: 0;
            }
          }

          .img {
            height: 223px;

            &:after {
              width: 147px;
              height: 147px;
            }
          }

          .title {
            font-size: 18px;
            margin-top: 22px;
            line-height: 30px;
            letter-spacing: -0.5px;
          }

          .text {
            margin-top: 18px;
          }
        }

        &__solution {
          margin: 60px 20px 0;
          padding-top: 60px;

          &-group {
            overflow: visible;
          }

          &-list {
            width: max-content;
            height: 137px;
            margin-top: 40px;

            &.slide-top {
              animation: solutionRight 20s linear infinite;
            }

            &.slide-bottom {
              margin-top: 12px;
              animation: solutionLeft 20s linear infinite;
            }
          }

          &-item {
            width: 137px;
            height: 137px;
            margin: 0 3px;
          }
        }

        &__service {
          border-top: none;

          .cloud__inner {
            margin-top: 40px;
            border-top: none;
          }

          &-list {
            margin-top: 40px;
          }
        }

        &__global {
          .cloud__inner {
            margin-top: 40px;
            border-top: none;
          }

          &-text {
            width: auto;
          }

          &-img {
            padding: 50px 0;
          }

          &-list {
            &.slide-bottom {
              margin-top: 30px;
            }
          }

          &-item {
            width: 121px;
            margin-right: 61px;
          }
        }
      }

      @keyframes globalLeft-mo {
        0% {
          transform: translateX(0);
        }

        100% {
          transform: translateX(-2487px);
        }
      }

      @keyframes globalRight-mo {
        0% {
          transform: translateX(-2487px);
        }

        100% {
          transform: translateX(0);
        }
      }
    }
  }
}
