.#{$component-name} {
  .container {
    &.is-show-event {
      .banner {
        display: block;
      }

      .common__title {
        display: none;
      }
    }
  }

  .media-list-layout {
    .banner {
      display: none;
      margin-top: 10px;
      padding: 0 60px 60px;

      &.#{$showClass} {
        display: block;
      }
    }

    .common__title {
      border-bottom: none;

      &-inner {
        padding-bottom: 81px;
      }
    }

    .section {
      max-width: 1800px;
      margin: 57px auto 0;
      padding-left: 425px;

      &__title {
        margin-top: 80px;
      }
    }
  }

  .section-conference {
    .cont {
      &__wrap {
        margin-top: 40px;
      }

      &__item {
        width: 100%;
        font-size: 0;
        border-top: 1px solid var(--border01);

        &:last-child {
          border-bottom: 1px solid var(--border01);
        }

        &.is-show--time {
          .cont__link {
            padding: 30px 30px 30px 0;
          }

          .date__wrap {
            .comma {
              display: inline-block;
            }

            .time {
              display: block;
            }
          }
        }
      }

      &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 34px 30px 34px 0;

        &:hover {

          .date__wrap .date,
          .date__wrap .comma,
          .date__wrap .time,
          .title {
            color: $color-point;
          }

          .quick-link__button {
            background-color: $color-point;
          }
        }

        .info__wrap {
          display: flex;
          align-items: center;
        }

        .date__wrap {
          font-size: 0;

          .date,
          .comma,
          .time {
            font-weight: 700;
            font-size: 12px;
            line-height: 1.5;
            color: var(--flowerDesc);
            transition: all .3s;
          }

          .comma,
          .time {
            display: none;
          }
        }

        .title {
          margin-left: 147px;
          font-weight: 700;
          font-size: 22px;
          line-height: 1.27;
          letter-spacing: -0.61px;
          color: var(--default);
          transition: all .3s;
        }

        .quick-link__button {
          display: inline-block;
          width: 30px;
          height: 30px;
        }
      }
    }

    .pagination {
      margin-top: 80px;
    }
  }

  .section-event {
    .tag-wrap {
      margin-top: 50px;
      font-size: 0;

      .ir-common__button.tag {
        text-transform: initial;
        font-weight: 800;
      }
    }

    .card__sub {
      margin-top: 40px;

      .sub__item:nth-child(n + 4) {
        margin-top: 30px;
      }
    }

    .pagination {
      margin-top: 80px;
    }
  }

  &.media-conference, &.media-list-layout {
    .tab__area {
      max-width: 1800px;
      margin: 0 auto;
      padding-left: 425px;
      border: 0;
    }

    .tab__list {
      max-width: none;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid var(--buttonType3Border);
    }
  }
      
  .contents-nodata {
    width: 100%;
    
    .search-result__text {
      display: block;
      text-align: center;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    .media-list-layout {
      .banner {
        padding-left: 40px;
        padding-right: 40px;
      }

      .section {
        max-width: 1520px;
        padding-left: 385px;
      }
    }

    .section-conference {

      .cont__item .cont__link,
      .cont__item.is-show--time .cont__link {
        padding-right: 20px;
      }

      .cont__link .title {
        margin-left: 112px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .media-list-layout {
      .banner {
        padding: 0 30px 43px;
      }

      .common__title-inner {
        padding-bottom: 70px;
      }

      .section {
        max-width: none;
        margin-top: 37px;
        padding: 0 30px;

        &__title {
          margin-top: 60px;
        }
      }
    }

    .section-conference {
      .cont__item.is-show--time .cont__link {
        padding-top: 23px;
        padding-bottom: 23px;
      }

      .cont__link {
        padding-top: 26px;
        padding-bottom: 26px;

        .date__wrap {

          .date,
          .comma,
          .time {
            font-size: 12px;
            line-height: 1.5;
          }
        }

        .title {
          margin-left: 166px;
          font-size: 18px;
          line-height: 1.67;
          letter-spacing: -0.5px;
        }

        .quick-link__button {
          width: 24px;
          height: 24px;
        }
      }
    }

    .section-event {
      .tag-wrap {
        margin-top: 40px;
      }

      .card__sub {
        margin-top: 30px;

        .sub__item:nth-child(n + 4) {
          margin-top: 24px;
        }
      }

      .pagination {
        margin-top: 70px;
      }
    }

    &.media-conference, &.media-list-layout {
      .tab__area {
        padding-left: 0;
      }
  
      .tab__list {
        max-width: none;
        padding: 0;
        margin: 0 30px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .media-list-layout {
      .section {
        margin-top: 40px;
      }
    }

    .section-conference {

      .cont__item .cont__link,
      .cont__item.is-show--time .cont__link {
        padding-right: 12px;
      }

      .cont__item.is-show--time .cont__link {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .cont__link {
        padding-top: 22px;
        padding-bottom: 22px;

        .title {
          margin-left: 101px;
          font-size: 16px;
          line-height: 1.88;
          letter-spacing: -0.5px;
        }
      }
    }

    .section-event {
      .card__sub {
        .sub__item:nth-child(n + 3) {
          margin-top: 20px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .media-list-layout {
      .common__title-inner {
        padding-bottom: 40px;
      }

      .banner {
        padding-left: 20px;
        padding-right: 20px;
      }

      .section {
        margin-top: 42px;
        padding: 0 20px;
      }
    }

    .section-conference {

      .cont__item .cont__link,
      .cont__item.is-show--time .cont__link {
        padding: 20px 10px 24px 0;
      }

      .cont__link {
        .info__wrap {
          flex: 1;
          flex-direction: column-reverse;
          align-items: flex-start;
        }

        .date__wrap {
          display: flex;
          margin-top: 10px;

          .comma {
            padding-right: 2px;
          }
        }

        .title {
          max-width: 280px;
          margin-left: 0;
        }
      }
    }

    .section-event {
      .card__sub {
        margin-top: 20px;

        .sub__item:nth-child(n + 3) {
          margin-top: 6px;
        }
      }

      .tag-wrap {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        padding-bottom: 10px;
        
        &::-webkit-scrollbar{
          display: none;
          }

        .ir-common__button {
          flex-shrink: 0;

          &+.ir-common__button {
            margin-top: 0;
          }
        }
      }

      .pagination {
        margin-top: 60px;
      }
    }
    &.media-conference, &.media-list-layout {
      .tab__list {
        margin: 0 20px;
      }
    }
  }
}
