.#{$component-name} {
  &.social-value {
    .container {
      padding-bottom: 0;
    }

    .track-animation {
      &__cover {
        background-image: url(/img/pc/social-value-main.png);
      }

      &__dimmed {
        background-color: #cacafd;

        &.#{$showClass} {
          opacity: 1;
        }
      }

      &__highlight-area {
        width: 1344px;
        text-align: center;
      }
    }

    .anchor-section {
      margin-top: 0;
    }

    .section {
      &__outlink {
        display: flex;
        align-items: center;
        position: relative;
        width: fit-content;
        height: 52px;
        padding: 0 50px 0px 20px;
        margin-top: 60px;
        background: var(--outlinkBg03);
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        transition: background .3s;
        text-wrap: nowrap;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          right: 11px;
          width: 20px;
          height: 20px;
          background: url(/img/common/ic-outlink-white2.png) no-repeat center;
          background-size: contain;
          transform: translateY(-50%);
        }

        &:hover {
          background: $color-point;
        }

      
      }
    }

    .js-nav-section {
      padding: 0;
      max-width: 1920px;
    }

    .half {
      &__list {
        &:last-child {
          .half__box {
            &:last-child {
              border: none;
            }
          }
        }
      }

      &__box {
        height: auto;
        &:last-child {
          align-items: center;
          border-bottom: 1px solid var(--border01);
        }
      }

      &__img {
        position: static;
        // background: #ebf0f2;

        .image {
          position: static;
          width: 100%;
          transform: none;
        }
      }

      &__info {
        padding: 105px 123px;

        .title {
          margin-top: 40px;
          color: var(--default);
        }

        .text {
          margin-top: 32px;
          font-size: 18px;
          font-weight: 700;
          line-height: 1.89;
          color: var(--inputColor);
        }

        .number {
          font-size: 64px;
          font-weight: 700;
          color: var(--halfNum);
        }

        .sub_text {
          position: absolute;
          bottom: 60px;
          width: 500px;
          font-size: 12px;
          font-weight: 700;
          line-height: 1.67;
          color: var(--searchTxt);
        }
      }
    }

    .content04 {
      padding-top: 0;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.social-value {
      .track-animation {
        &__highlight-area {
          width: 1136px;
        }
      }

      .half {
        &__info {
          padding: 105px 106px;

          .text {
            margin-top: 23px;
            line-height: 1.67;
          }
        }
      }

      .content04 {
        .section {
          &__outlink {
            margin-top: 50px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.social-value {
      .track-animation {
        &__highlight-area {
          width: 868px;
        }
        &__highlight-text {
          text-align: center;
        }
      }

      .anchor-section {
        .js-nav-section:first-of-type {
          padding-top: 0;
        }
      }

      .half {
        &__list {
          flex-direction: column;
          border: none;
        }

        &__box {
          height: auto;
          &:last-child {
            border: none;
          }
        }

        &__img {
          position: static;
          height: 768px;

          .image {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
          }
        }

        &__info {
          padding: 45px 30px 190px;

          .title {
            margin-top: 30px;
            font-size: 48px;

            br {
              display: none;
            }
          }

          .text {
            margin-top: 20px;

            br {
              display: none;
            }
          }

          .number {
            font-size: 56px;
          }

          .sub_text {
            position: static;
            width: 100%;
            margin-top: 100px;
            font-size: 10px;
            line-height: 1.8;

            br {
              display: block;
            }
          }

          &-pb {
            padding-bottom: 60px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.social-value {
      .track-animation {
        &__highlight-area {
          width: 648px;
        }
        &__highlight-text {
          text-align: left;
        }
      }

      .half {
        &__img {
          height: 576px;
        }

        &__info {
          padding: 36px 30px 180px;

          .title {
            margin-top: 21px;
            font-size: 42px;

            br {
              display: block;
            }
          }

          .text {
            margin-top: 21px;
            line-height: 1.67;

            br {
              display: block;
            }
          }

          .number {
            font-size: 48px;
          }

          .sub_text {
            width: 435px;
          }

          &-pb {
            padding-bottom: 40px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.social-value {
      .track-animation {
        &__cover {
          background-image: url(/img/mo/social-value-main.png);
        }

        &__highlight-area {
          width: auto;
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 27px;
        }
      }

      .half {
        &__img {
          height: auto;

          .image {
            position: relative;
            top: auto;
            left: auto;
            width: 100%;
            transform: none;
          }
        }

        &__info {
          padding: 26px 20px 120px;

          .title {
            margin-top: 16px;
            font-size: 32px;
          }

          .text {
            margin-top: 20px;
            font-size: 16px;
            line-height: 1.75;
            max-width: 370px;

            br {
              display: none;
            }
          }

          .number {
            font-size: 38px;
          }

          .sub_text {
            width: 327px;
            margin-top: 80px;
            .br_tab-sm {
              display: none;
            }
          }

          &-pb {
            padding-bottom: 40px;
          }
        }
      }

      .content04 {
        .section {
          &__outlink {
            margin-top: 30px;
            min-width: 180px;
            height: 44px;
            font-size: 14px;
            padding: 0 13px 0px 18px;

            &:before {
              width: 18px;
              height: 18px;
              right: 10px;
            }
          }
        }
      }
    }
  }
}
