.#{$component-name} {
  .ir-table-desc {
    margin-top: 20px;
    color: #bbbbbe;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -.26px;
    text-align: right;
  }

  .ir-table-number {
    border-top: 1px solid var(--border04);

    &+.ir-table-number {
      margin-top: 60px;
      border-top: none;
    }

    ul li {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid var(--border01);
    }

    .thead {
      .th {
        font-size: 14px;
        font-weight: 800;
        line-height: 30px;
        letter-spacing: -.3px;
        color: var(--default);

        &:last-child {
          width: 121px;
        }
      }
    }

    .tbody {
      .td {
        &-wrap {
          display: flex;
          justify-content: space-between;
          flex: 1;
        }

        color: var(--box-text);
        font-size: 14px;
        line-height: 30px;
        letter-spacing: -.3px;

        &:last-child {
          flex-shrink: 0;
          width: 121px;
        }
      }

      >.td {
        flex-shrink: 0;
        width: 90px;
      }
    }

  }

  .ir-table-year {
    border-top: 1px solid var(--border04);
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -.3px;

    ul {
      display: flex;
    }

    ul li {
      flex: 1;
    }

    li.thead {
      flex: 1.2;
      font-weight: 700;

      .th {
        border-bottom: 1px solid var(--border01);
        color: var(--default);

        &:first-child {
          font-weight: 800;
        }
      }
    }

    .th, .td {
      padding: 20px 0;
    }

    .tbody {
      .thead {
        display: none;
      }

      &-inner {
        text-align: center;

        .th {
          font-weight: 800;
          color: var(--default);
        }

        .th, .td {
          border-bottom: 1px solid var(--border01);
        }

        .td {
          color: var(--color-sub);
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {}
}

@include breakpoint(tablet) {
  .#{$component-name} {}
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .ir-table-desc {
      margin-top: 16px;
    }

    .ir-table-number {
      ul li {
        padding: 10px 0 30px;
      }

      .thead {
        padding: 10px 0 50px;
        border-color: $color-default;

        .th {
          font-size: 22px;
          line-height: 42px;
          letter-spacing: -.6px;

          .date {
            display: block;
            color: #bbbbbe;
            line-height: 1;
            letter-spacing: 0;
          }

          &:last-child {
            display: none;
          }
        }
      }

      .tbody {
        .td {
          &-wrap {
            flex-direction: column;
          }

          font-weight: 700;
          line-height: 26px;

          &:last-child {
            width: auto;
            margin-top: 10px;
            color: #a1a1a6;
          }
        }

        >.td {
          width: 60px;
        }
      }

    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .ir-table-desc {
      margin-top: 12px;
      font-size: 10px;
      line-height: 18px;
      letter-spacing: -.2px;
    }

    .ir-table-year {
      line-height: 26px;
      letter-spacing: 0;

      ul {
        flex-direction: column-reverse;
      }

      li.thead {
        display: none;
      }

      .th, .td {
        padding: 3px 0;
      }

      .tbody {
        display: flex;
        position: relative;
        padding: 61px 0 27px;
        border-bottom: 1px solid $color-gray4;

        .thead {
          display: block;
          width: 56.5%;
          color: #a1a1a6;

          .th {
            &:first-child {
              display: none;
            }
          }
        }

        &-inner {
          flex: 1;
          text-align: left;

          .th {
            position: absolute;
            top: 10px;
            left: 0;
            padding: 0;
            font-size: 18px;
            line-height: 34px;
          }

          .th, .td {
            border-bottom: none;
          }
        }
      }
    }
  }
}
