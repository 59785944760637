.#{$component-name} {
  &.search {
    .container {
      .search-tab {
        position: static;
        transform: none;
        padding: 68px 0 64px;

        &__box {
          opacity: 1;
        }
      }
    }

    .tab {
      &__list,
      &__panels {
        padding: 0;
        max-width: 1345px;
      }

      &__cta {
        margin-right: 45px;
        margin-left: 0;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .pagination {
      margin-top: 82px;
    }

    .search-result {
      &__area {
        opacity: 0;
        min-height: 750px;
        transition: opacity .2s;

        .card__sub {
          display: none;
        }

        &.use-result, &.no-result {
          opacity: 1;
        }
      }

      &__item {
        display: none;
      }
    }
  }

  .search-result {
    &__area {
      .highlight {
        color: $color-point;
      }
      
      &.no-result {
        .no-result {
          display: block;
        }

        .search-result {
          
          &__list, &__item, &__text {
            &.no-result {
              display: block;
            }

            &:not(.no-result) {
              display: none;
            }
          }
        }
      }

      &.use-result {
        .use-result {
          display: block;
        }

        .search-result {
          &__list, &__item, &__text {
            &.use-result {
              display: block;
            }

            &:not(.use-result) {
              display: none;
            }
          }
        }
      }
    }

    &__text {
      display: none;
      padding: 83px 0 70px;
      font-size: 22px;
      font-weight: 700;
      line-height: 1.55;
      letter-spacing: -.6px;
      color: var(--default);

      &.no-result {
        padding-bottom: 51px;
      }
    }

    &__list {
      display: none;
      margin-left: 5px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.67;
      letter-spacing: -.3px;
      color: var(--secDesc);

      li {
        position: relative;
        padding-left: 11px;

        &:before {
          position: absolute;
          top: 1px;
          left: 0;
          content: '∙';
        }
      }
    }

    &__item {
      padding-bottom: 80px;
      border-top: 1px solid $color-gray4;

      @at-root .theme-dark & {
        border-top: 1px solid var(--buttonType3Border);
      }

      .btn_result {
        float: right;
        margin-top: 2px;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.86;
        letter-spacing: -0.3px;
        border-bottom: 1px solid var(--default);

        &:hover {
          color: $color-point;
          border-bottom: 1px solid var(--$color-point);
        }
      }

      .list-title {
        float: left;
        font-size: 22px;
        font-weight: 800;
        line-height: 1.55;
        color: var(--default);

        &__area {
          overflow: hidden;
          padding: 14px 0 49px;

          .count {
            float: left;
            margin-left: 10px;
            font-size: 22px;
            font-weight: 800;
            line-height: 1.55;
            color: $color-point;
          }
        }
      }

      .list-result {
        &__item {
          margin-top: 40px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .result-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.75;
        color: var(--default);
        a {
          font-weight: 700;
          transition: color .3s;
          &:hover {
            color: $color-point;
          }
        }
      }

      .result-text {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 13px;
        font-size: 14px;
        font-weight: 700;
        color: #a1a1a6;
        line-height: 1.86;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.search {
      .tab {

        &__list,
        &__panels {
          max-width: 1136px;
        }
      }

      .search-result {
        &__area {
          min-height: 571px;
        }
      }
    }

    .search-result {
      &__text {
        padding-bottom: 70px;

        &.no-result {
          padding-bottom: 48px;
        }
      }

      &__list {
        margin-left: 4px;
        letter-spacing: -1px;
      }

      &__item {
        .result-title {
          letter-spacing: -.23px;
        }

        .result-text {
          letter-spacing: -.16px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.search {
      .container {
        .search-tab {
          padding: 64px 0 69px;
        }
      }

      .tab {

        &__list,
        &__panels {
          max-width: none;
          padding: 0 30px;
        }

        &__cta {
          padding: 22px 0 23px;
        }
      }

      .search-result {
        &__area {
          min-height: 1037px;
        }
      }
    }

    .search-result {
      &__text {
        padding-top: 82px;

        &.no-result {
          padding: 63px 0 52px;
        }
      }

      &__list {
        margin-left: 3px;
        letter-spacing: -.3px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.search {
      .container {
        .search-tab {
          padding: 47px 0 38px;
        }
      }

      .tab {
        &__cta {
          margin-left: 35px;
          margin-right: 0;

          &:first-child {
            margin-right: 0;
          }
          @at-root .theme-dark & {
            &:not(.is-active) {
              color: #424245;
            }
          }


        }
      }

      .search-result {
        &__area {
          min-height: 762px;
        }
      }
    }

    .search-result {
      &__text {
        padding: 62px 0 51px;
        font-size: 18px;
        letter-spacing: -.4px;

        &.no-result {
          padding: 62px 0 31px;
        }
      }

      &__list {
        font-size: 14px;
        letter-spacing: -.2px;
        line-height: 1.86;
      }

      &__item {
        padding-bottom: 71px;

        .list-title {
          &__area {
            padding-bottom: 39px;
          }
        }

        .result-text {
          -webkit-line-clamp: 3;
        }

        .btn_result {
          font-size: 12px;
          line-height: 1.83;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.search {
      .container {
        .search-tab {
          padding: 51px 0 43px;
        }
      }

      .tab {

        &__list,
        &__panels {
          padding: 0 20px;
        }

        &__cta {
          &:first-child {
          margin-right: 35px;
          }
          margin-left: 0;
          margin-right: 35px;
        }
        
      }

      .search-result {
        &__area {
          min-height: 550px;
        }
      }
    }

    .search-result {
      &__text {
        &.no-result {
          padding-bottom: 33px;
        }
      }

      &__list {
        margin-left: 3px;

        li {
          padding-left: 10px;
        }
      }
    }
  }
}
