.#{$component-name} {
  &.esg-sustainability {
    .track-animation {
      &__dimmed {
        background-color: #d7eefd;

        &.#{$showClass} {
          opacity: 1;
        }
      }

      &__cover {
        background-image: url(/img/pc/sustainabillity-img.png);
      }

      &__highlight-area {
        text-align: center;
      }
    }

    .js-nav-section {
      .inner {
        max-width: 1800px;
        margin: 0 auto;
        padding-right: 237px;
      }

      .download {

        &.type3 {
          width: 330px;
          height: 54px;
          font-size: 16px;
          letter-spacing: -0.5px;
          color: #fff;
          border: none;
          text-wrap: nowrap;
          background-color: var(--buttonDownload);

          @at-root .navercorp.lang-en {
            .js-nav-section .download.type3 {
              &.kor {
                display: none;
              }
              &.eng {
                display: inline-block;
                width: 350px;

                &::before {
                  right: 12px;
                }
              }
            }
          }

          &::before {
            right: 23px;
            border-radius: 0;
            background-color: transparent;
            @include sp-desktop('ic-download');
          }

          &:hover {
            background-color: $color-point;
          }
        }

        &__btn {
          display: inline-block;
          margin-top: 50px;
        }

        &.eng {
          display: none;
        }
        &.kor {
          display: inline-flex;
        }
        @at-root .navercorp.esg-sustainability.lang-en {
          .js-nav-section .cont__box.type2 .ir-common__button.kor {
            display: none;
          }
          .js-nav-section .ir-common__button.eng {
            display: inline-flex;
          }
        }

      }

      .cont {
        margin-top: 120px;

        &__title {
          margin-top: 0;
          font-weight: 800;
          font-size: 38px;
          line-height: 1.42;
          letter-spacing: -1.5px;
        }

        
        .ir-common__button {

          &.eng {
            margin-top: 20px;
            font-weight: 800;
          }
        }

        .text {
          max-width: 1150px;
          margin-bottom: 80px;
          font-weight: 800;
          font-size: 26px;
          line-height: 1.85;
          letter-spacing: -0.8px;
          color: #bbbbbe;
          @at-root .theme-dark & {
            color: var(--secDesc);
          }
        }

        &__box {
          max-width: 1135px;
          margin-top: 70px;

          .tit {
            font-weight: 800;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.5px;
            color: var(--default);
          }

          .sub-tit {
            margin-top: 50px;
            font-weight: 800;
            font-size: 20px;
            line-height: 1.7;
            letter-spacing: -0.5px;
            color: var(--default);
          }

          .g-tit {
            font-weight: 700;
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
            color: var(--default);
          }

          .txt {
            max-width: 1065px;
            margin-top: 20px;
            font-weight: 700;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.3px;
            color: var(--inputColor);
          }

          .image {
            margin-top: 50px;
          }

          .ir-common__button {
            font-weight: 800;
          }

          &.type2 {
            overflow: hidden;

            .box__list {
              margin: -60px 0 0 -58px;
              font-size: 0;
            }

            .box__item {
              display: inline-block;
              width: calc((100% - 116px) / 2);
              margin: 60px 0 0 58px;
            }

            .txt {
              max-width: none;
            }

            .download__wrap {
              display: flex;
              // margin-top: 10px;
            }

            .ir-common__button {
              margin-top: 20px;
              font-weight: 800;

              &.kor {
                display: block;
                .js-nav-section .cont__box.type2  .download__wrap .ir-common__button {
                  display: block;
                    &:nth-child(2), &:nth-child(3) {
                      margin-top: 10px;
                  }
                }
              }
              &.eng {
                display: none;
              }
              
              @at-root .navercorp.esg-sustainability.lang-en {
                .js-nav-section .cont__box.type2 .download__wrap {
                  display: block;
                }
                .js-nav-section .cont__box.type2  .download__wrap .ir-common__button.kor {
                  display: none; 
                }
                .js-nav-section .cont__box.type2 .ir-common__button.eng {
                  display: block;
                  margin-top: 20px;
                }
                .js-nav-section .cont__box.type2  .download__wrap .ir-common__button {
                  display: block;
                    &:nth-child(2), &:nth-child(3) {
                      margin-top: 10px;
                  }
                }
              }

              & + .ir-common__button {
                margin-left: 0;
                margin-right: 25px;
                // margin-top: 10px;
              }

              &:last-child {
                margin-right: 0;
              }
            }


            // @at-root .navercorp.esg-sustainability.lang-en {
            //   .js-nav-section .cont__box.type2 .ir-common__button.download.kor {
            //      display: none; 
            //    }
            //    .js-nav-section .cont__box.type2 .ir-common__button.download.eng {
            //      display: block;
            //    }
            //  }

            .download__wrap {
              .ir-common__button {
                margin-right: 26px;

                &:first-child,&:nth-child(3){
                  margin-left: 0;
                }
                &:nth-child(2), &:nth-child(3) {
                  margin-top: 20px;
                }
              }
            }
          }

          &.type3 {
            overflow: hidden;

            .box__list {
              display: flex;
              font-size: 0;
              gap: 80px;
            }

            .box__item {
              display: flex;
              flex-direction: column;
              flex: 1;
            }
          }
        }

        &__graph {
          margin-top: 100px;

          .cont__box {
            margin-top: 50px;
          }

          .image {
            margin-top: 30px;
          }
        }

        .image-wrap {
          font-size: 0;

          .image {
            display: inline-block;
            width: calc((100% - 21px) / 2);
            margin-left: 21px;

            &:nth-child(odd) {
              margin-left: 0;
            }
          }
        }
      }
    }

    .promotion-strategy {
      @at-root .navercorp.lang-en {
        .promotion-strategy .download.type3 {
          &.eng {
            margin-left: 0;
          }
        }
      }

      .cont {
        &__title {
          color: var(--default);
        }

        &__box {
          margin-top: 50px;
          text-align: center;

          .image {
            margin-top: 0;
          }
  
          .ir-common__button {
            height: 34px;
            margin-top: 40px;
            padding: 0 46px 0 0;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;

            &.download {
              &:before {
                right: 0;
                left: auto;
                width: 34px;
                height: 34px;
                transform: translateY(-50%) rotate(-90deg);
              }
            }
          }
        }
      }
    }

    .management-system {
      max-width: 1920px;
      padding: 0;

      .section__title {
        padding-top: 45px;
      }

      .cont__box {
        margin-top: 80px;
        max-width: none;
        text-align: right;

        &.type3 {
          margin-top: 50px;
          text-align: left;

          .txt {
            width: 376px;
          }
        }
      }

      .image {
        height: 597px;
        background: #007dff url(/img/pc/esg-sustainability-info9.png) no-repeat center;
        // background-size: 1424px 356px;
        background-size: contain;

        @at-root .navercorp.lang-en {
          .management-system .image {
            background: #007dff url(/img/pc/esg-sustainability-info9-en.png) no-repeat center;
            // background-size: 1424px 356px;
            background-size: contain;
          }
        }
      }
    }
  }
}

.theme-dark {
  .navercorp.esg-sustainability {
    .cont__box {
      .image {
        .white {
          display: none;
        }
        .dark {
          display: block;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.esg-sustainability {
      .js-nav-section {
        .inner {
          max-width: 1520px;
          padding-right: 161px;
        }

        .section__right-box{
          max-width: 1520px;
          padding: 70px 0 110px 105px;
        }
      }

      .management-system {
        .image {
          height: 500px;
          // background-size: 1192px 298px;
        }

        .cont {
          &__box {
            &.type3 {
              .box__list {
                gap: 65px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.esg-sustainability {

      .js-nav-section {
        padding-bottom: 40px;

        .section__title {
          font-size: 32px;
        }

        .section__right-box{
          max-width: none;
          margin: 0;
          padding: 120px 30px 0;
        }

        .inner {
          max-width: none;
          padding: 0;
        }

        .download__btn {
          margin-top: 60px;
        }

        .cont {
          &__box {
            margin-top: 60px;
            max-width: none;

            .tit {
              font-size: 18px;
            }

            .sub-tit {
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.5px;
            }

            .txt {
              max-width: none;
              font-size: 14px;
            }

            &.type2 {
              .download__wrap {
                .download {
                  margin-left: 26px;
  
                  &:first-child,&:nth-child(2){
                    margin-left: 0;
                    margin-right: 26px;
                  }
                }
              }
            }

            &.type3 {
              margin-top: 40px;

              .box__item {
                width: calc((100% - 144px)/3);
              }
            }
          }
        }
      }
      
      .track-animation{
        &__highlight-text{
          padding: 0 64px;
          text-align: center;
        }
      }

      .promotion-strategy {
        .cont {  
          &__box {    
            .ir-common__button {
              font-size: 18px;
              line-height: 1.67;
              letter-spacing: -0.5px;
            }
          }
        }
      }

      .management-system {
        .section__title {
          padding-top: 120px;
        }

        .image {
          height: 406px;
          // background-size: 920px 230px;
        }

        .cont__box {
          &.type3 {
            .box__list {
              gap: 40px;
              
              .txt {
                width: auto;
              }
            }
          }
        }
      }

      @at-root .navercorp.lang-en {
        .js-nav-section .download.type3 {
          &.eng {
            display: flex;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.esg-sustainability {
      .track-animation {
        &__highlight-text {
          text-align: left;
        }
      }
      .js-nav-section {
        padding-bottom: 0;

        .download__btn {
          margin-top: 40px;
        }

        .download.type3 {
          width: 302px;
          height: 48px;
          padding: 11px 56px 11px 20px;
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;

          @at-root .navercorp.lang-en {
            .js-nav-section .download.type3 {
              &.eng {
                display: block;
                width: 320px;
                height: 48px;
              }
            }
          }
        }

        .cont {
          margin-top: 100px;

          &__title {
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }
        }

        .cont__box {
          margin-top: 50px;

          .tit {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }

          .sub-tit {
            margin-top: 30px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.4px;
          }

          .g-tit {
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: -0.4px;
          }

          .txt {
            margin-top: 14px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
            width: 640px;
          }

          .image {
            margin-top: 30px;
          }
        }

        .cont__box.type2 {
          .box__list {
            margin: 0;
          }

          .box__item {
            width: 100%;
            margin: 50px 0 0 0;

            &:first-child {
              margin-top: 0;
            }
          }
        }

        .cont__box.type3 {
          margin-top: 60px;

          .box__list {
            display: flex;
            flex-direction: column;
            margin: 0;
            gap: 40px;
          }

          .box__item {
            width: 100%;
            margin: 0;

            &:first-child {
              margin-top: 0;
            }
          }
        }

        .cont__graph {
          margin-top: 60px;

          .cont__box {
            margin-top: 30px;

            .image {
              margin-top: 20px;
            }
          }
        }
      }

      .promotion-strategy {
        .cont {
          &__title {
            padding: 0;
          }

          &__box {
            margin-top: 40px;

            .image {
              margin-top: 0;
            }

            .ir-common__button {
              height: 30px;
              margin-top: 30px;
              padding-right: 32px;

              &.download {
                &:before {
                  width: 24px;
                  height: 24px;
                }
              }  
            }
          }
        }
      }

      .management-system {
        .image {
          height: 708px;
          background: #007dff url(/img/mo/esg-sustainability-info9.png) no-repeat center;
          // background-size: 600px 600px;
          background-size: contain;

          @at-root .navercorp.lang-en {
            .management-system .image {
              background: #007dff url(/img/mo/esg-sustainability-info9-en.png) no-repeat center;
              // background-size: 600px 600px;
              background-size: contain;
            }
          }
        }
      }

      @at-root .navercorp.lang-en {
        .js-nav-section .download.type3 {
          &.eng {
            display: flex;
          }
        }
      }

    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.esg-sustainability {
      .track-animation {
        &__highlight-area {
          max-width: 375px;
          text-align: left;
        }

        &__highlight-text {
          padding: 0 20px;
        }

        &__cover {
          background-image: url(/img/mo/sustainabillity-img-mo.png);
        }
      }

      .js-nav-section {
        .cont__box {

          &.type2 {
            .ir-common__button + .ir-common__button {
              margin-top: 10px;
            }
          }

          .download__wrap {
            display: flex;
            flex-direction: column;
          }
        }

        .cont {
          .image-wrap {
            display: flex;
            flex-direction: column;
  
            .image {
              width: 100%;
              margin-left: 0;
            }
          }
          &__box {
            .tit {
              font-size: 16px;
              line-height: 1.75;
              letter-spacing: -0.5px;
            }
          }
        }
      }

      .section__visual {
        background-image: url(/img/mo/sustainabillity-img.png);
      }

      .js-nav-section {
        padding-bottom: 15px;

        .download {
          &.type3 {

            @at-root .navercorp.lang-en {
              .js-nav-section .download.type3 {
                &.eng {
                  display: block;
                  min-width: 320px;
                  width: 100%;
                }
              }
            }
          }
        }

        .section{

          &__right-box {
            padding: 15px 20px 0 20px;
          }

          &__title{
            .text-highlight{
              text-wrap: nowrap;
            }
          }
          
        }

        .cont {
          &__title {
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
          }
        }

        
        .ir-common__button {
          margin-top: 20px;
        }

        .ir-common__button + .ir-common__button {
          margin-top: 10px;
        }

        .cont__box.type2 {
          .download__wrap {

            .ir-common__button + .ir-common__button {
              margin-top: 10px;
            }

            .download {
              width: 100%;
              margin-top: 6px;
              margin-left: 0;

              &:first-child {
                margin-top: 20px;
              }
            }
          }
        }

      }

      .promotion-strategy {
        .cont {  
          &__box {
            .ir-common__button {
              height: 28px;
              font-size: 16px;
            }
          }
        }
      }

      .management-system {
        .section__title {
          padding-top: 85px;
        }

        .image {
          height: 335px;
        }

        .cont__box {
          &.type3 {
            .box__list {
              .txt{
                width: 100%;
                max-width: 335px;
              }
            }
          }
        }
      }
    }
  }
}
