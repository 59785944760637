.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-header + 30;
  width: 100%;
  padding: 22px 0;
  background-color: var(--blackWhite);
  transition: transform .4s;

  @at-root .theme-dark & { // 헤더 GNB에선 다크모드인 경우 무조건 다크테마
    background-color: #000;
  }

  .nav {
    &__link {
      @at-root .theme-dark & {
        color: $color-white;
      }
    }

    &__list2 {
      &.#{$activeClass} {
        @at-root .theme-dark & {
          background-color: $color-default;
        }
      }
    }
    
    &__link2 {
      @at-root .theme-dark & {
        color: $color-white;
      }

      &:hover {
        &::before {
          @at-root .theme-dark & {
            background-color: $color-white;
          }
        }
      }
    }
  }

  &.transparent {
    background-color: transparent !important;
  }

  &.thema-white { // 흰색 배경, 검정색 텍스트 (디폴트 상태는 다크모드와 상관없이 해당 스타일 유지)
    background-color: $color-white;

    @at-root .theme-dark & {
      background-color: $color-white;
    }

    &.#{$showClass} {
      background-color: transparent;
      
      .nav__link {
        color: $color-default;
        
        @at-root .theme-dark & {
          color: $color-white;
        }

        &:hover {
          color: $color-point;
          
          @at-root .theme-dark & {
            color: $color-point;
          }
        }
      }

      .header__logo {
        background-image: url('/img/common/logo.svg');
        
        @at-root .theme-dark & {
          background-image: url('/img/common/logo-w.svg');
        }
      }

      .header__utils {
        .btn-search {
          background-image: url('/img/common/ic-search.png');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-search-w.svg');
          }
        }

        .btn-darkmode {
          background-image: url('/img/common/ic-darkmode.svg');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-darkmode-w.svg');
          }

          &.light {
            background-image: url('/img/common/ic-lightmode.png');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-lightmode-w.svg');
            }
          }
        }

        .btn-mo-nav {
          background-image: url('/img/common/ic-mo-nav.svg');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-mo-nav-w.svg');
          }
        }

        .btn-lang button[class^='btn-'].btn-selected {
          color: $color-default;
          
          @at-root .theme-dark & {
            color: $color-white;
          }
        }
  
        .btn-selected {
          &:before {
            background-image: url(/img/common/ic-lang-dropdown.png);
            
            @at-root .theme-dark & {
              background-image: url(/img/common/ic-lang-dropdown-w.png);
            }
          }
        }
      }
    }

    .header {
      &__logo {
        background-image: url(/img/common/logo.png);
        
        @at-root .theme-dark & {
          background-image: url(/img/common/logo.png);
        }
      }

      &__utils {
        .btn-search {
          background-image: url('/img/common/ic-search.png');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-search.png');
          }
  
          &:hover {
            background-image: url('/img/common/ic-search-on.png');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-search-on.png');
            }
          }
        }
  
        .btn-darkmode {
          background-image: url('/img/common/ic-darkmode.svg');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-darkmode.svg');
          }
  
          &:hover {
            background-image: url('/img/common/ic-darkmode-on.png');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-darkmode-on.png');
            }
          }
  
          &.light {
            background-image: url('/img/common/ic-lightmode.png');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-lightmode.png');
            }
  
            &:hover {
              background-image: url('/img/common/ic-lightmode-on.png');
              
              @at-root .theme-dark & {
                background-image: url('/img/common/ic-lightmode-on.png');
              }
            }
          }
        }
  
        .btn-lang button[class^='btn-'].btn-selected {
          color: $color-default;
          
          @at-root .theme-dark & {
            color: $color-default;
          }
  
          &:hover {
            color: $color-point;
            
            @at-root .theme-dark & {
              color: $color-point;
            }
  
            &:before {
              background-image: url(/img/common/ic-lang-dropdown-on.png);
              
              @at-root .theme-dark & {
                background-image: url(/img/common/ic-lang-dropdown-on.png);
              }
            }
          }
        }
  
        .btn-selected {
          &:before {
            background-image: url(/img/common/ic-lang-dropdown.png);
            
            @at-root .theme-dark & {
              background-image: url(/img/common/ic-lang-dropdown.png);
            }
          }
        }
  
        .btn-mo-nav {
          background-image: url('/img/common/ic-mo-nav.svg');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-mo-nav.svg');
          }
        }
      }
    }

    .nav {
      &__link {
        color: $color-default;

        @at-root .theme-dark & {
          color: $color-default;
        }

        &:hover {
          color: $color-point;
          
          @at-root .theme-dark & {
            color: $color-point;
          }
        }
      }
      &__list2 {
        &.#{$activeClass} {
          background-color: $color-white;
          
          @at-root .theme-dark & {
            background-color: $color-default;
          }
        }
      }

      &__link2 {
        color: $color-default;
        
        @at-root .theme-dark & {
          color: $color-white;
        }

        &:hover {
          &::before {
            background-color: $color-default;
            
            @at-root .theme-dark & {
              background-color: $color-white;
            }
          }
        }
      }
    }

    .search-tab {
      background-color: $color-white;
      
      @at-root .theme-dark & {
        background-color: $color-default;
      }
    
      &__box {
        .btn-delete {
          background-image: var('/img/common/ic-search-delete.png');
          
          @at-root .theme-dark & {
            background-image: var('/img/common/ic-search-delete-b.png');
          }
        }
    
        .btn-search {
          background-image: url('/img/common/ic-search-tab.png');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-search-tab-white.png');
          }
        }
      }
    
      .search {
        color: #111;
        
        @at-root .theme-dark & {
          color: $color-white;
        }
      }
    }
  }

  &.thema-black { // 검정색 배경, 흰색 텍스트 (디폴트 상태는 다크모드와 상관없이 해당 스타일 유지)
    background-color: #000;
    
    @at-root .theme-dark & {
      background-color: #000;
    }

    &.#{$scrollUpClass} {
      background-color: rgba(0, 0, 0, .8);
    }

    .nav {
      &__link {
        color: $color-white;
        
        @at-root .theme-dark & {
          color: $color-white;
        }
      }

      &__list2 {
        &.#{$activeClass} {
          background-color: $color-white;
          
          @at-root .theme-dark & {
            background-color: $color-default;
          }
        }
      }

      &__link2 {
        color: $color-default;
        
        @at-root .theme-dark & {
          color: $color-white;
        }

        &:hover {
          &::before {
            background-color: $color-default;
            
            @at-root .theme-dark & {
              background-color: $color-white;
            }
          }
        }
      }
    }

    .btn-selected {
      &::before {
        background-image: url('/img/common/ic-lang-dropdown-w.png');
        
        @at-root .theme-dark & {
          background-image: url('/img/common/ic-lang-dropdown-w.png');
        }
      }

      &:hover {
        color: $color-point;
        
        @at-root .theme-dark & {
          color: $color-point;
        }

        &::before {
          background-image: url('/img/common/ic-lang-dropdown-on.png');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-lang-dropdown-on.png');
          }
        }
      }
    }

    .btn-lang button[class^='btn-'].btn-selected {
      color: $color-white;
      
      @at-root .theme-dark & {
        color: $color-white;
      }

      &:hover {
        color: $color-point;
        
        @at-root .theme-dark & {
          color: $color-point;
        }
      }
    }

    .header {
      &__logo {
        background-image: url('/img/common/logo-w.svg');
        
        @at-root .theme-dark & {
          background-image: url('/img/common/logo-w.svg');
          }
      }

      &__utils {
        .btn-search {
          background-image: url('/img/common/ic-search-w.svg');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-search-w.svg');
          }

          &:hover {
            background-image: url('/img/common/ic-search-on.svg');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-search-on.svg');
          }
          }
        }

        .btn-darkmode {
          background-image: url('/img/common/ic-darkmode-w.png');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-darkmode-w.png');
          }

          &:hover {
            background-image: url('/img/common/ic-darkmode-on.png');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-darkmode-on.png');
          }
          }

          &.light {
            background-image: url('/img/common/ic-lightmode-w.svg');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-lightmode-w.svg');
          }

            &:hover {
              background-image: url('/img/common/ic-lightmode-on.png');
              
              @at-root .theme-dark & {
                background-image: url('/img/common/ic-lightmode-on.png');
          }
            }
          }
        }

        .btn-mo-nav {
          background-image: url('/img/common/ic-mo-nav-w.svg');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-mo-nav-w.svg');
          }

          &:hover {
            background-image: url('/img/common/ic-mo-nav-on.png');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-mo-nav-on.png');
          }
          }
        }
      }
    }

    &.#{$showClass} {
      background-color: transparent;

      .nav__link {
        color: $color-default;
        
        @at-root .theme-dark & {
          color: $color-white;
        }

        &:hover {
          color: $color-point;
          opacity: 1;
          
          @at-root .theme-dark & {
            color: $color-point;
          }
        }
      }

      .header__logo {
        background-image: url('/img/common/logo.svg');
        
        @at-root .theme-dark & {
          background-image: url('/img/common/logo-w.svg');
        }
      }

      .header__utils {
        .btn-search {
          background-image: url('/img/common/ic-search.png');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-search-w.svg');
          }
        }

        .btn-darkmode {
          background-image: url('/img/common/ic-darkmode.svg');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-darkmode.svg');
          }

          &.light {
            background-image: url('/img/common/ic-lightmode.png');
            
            @at-root .theme-dark & {
              background-image: url('/img/common/ic-lightmode-w.svg');
            }
          }
        }

        .btn-mo-nav {
          background-image: url('/img/common/ic-mo-nav.svg');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-mo-nav-w.svg');
          }
        }

        .btn-lang button[class^='btn-'].btn-selected {
          color: $color-default;
          
          @at-root .theme-dark & {
            color: $color-white;
          }
        }
  
        .btn-selected {
          &:before {
            background-image: url(/img/common/ic-lang-dropdown.png);
            
            @at-root .theme-dark & {
              background-image: url(/img/common/ic-lang-dropdown-w.png);
            }
          }
        }
      }
    }

    .search-tab {
      background-color: $color-white;

      @at-root .theme-dark & {
        background-color: $color-default;
      }
    
      &__box {
        .btn-delete {
          background-image: var('/img/common/ic-search-delete.png');
          
          @at-root .theme-dark & {
            background-image: var('/img/common/ic-search-delete-b.png');
          }
        }
    
        .btn-search {
          background-image: url('/img/common/ic-search-tab.png');
          
          @at-root .theme-dark & {
            background-image: url('/img/common/ic-search-tab-white.png');
          }
        }
      }
    
      .search {
        color: $color-default;
        
        @at-root .theme-dark & {
          color: $color-white;
        }
      }
    }
  }

  &.#{$showClass} {
    z-index: $z-index-header + 45;
  }

  &__inner {
    display: flex;
    position: relative;
    z-index: $z-index-header + 5;
    justify-content: space-between;
    align-items: center;
    max-width: 1800px;
    margin: 0 auto;
  }

  &__logo {
    position: relative;
    z-index: 1;
    width: 92px;
    height: 18px;
    background: var(--logo) no-repeat;
    background-size: 100%;
    transition: background-image 0.4s;

    &.#{$hideClass} {
      display: none;
    }
  }

  &__link {
    display: block;
    height: 100%;
  }

  .nav {
    margin: -22px 0 -22px 37px;

    &__list {
      display: flex;

      &.#{$showClass} {
        .nav__list2 {
          transform: translateY(0);
          transition: transform 0.4s cubic-bezier(0.59, 0.01, 0.28, 1);

          .nav__item2 {
            transition: opacity .4s cubic-bezier(0.59, 0.01, 0.28, 1);
            opacity: 1;
          }

          &.#{$activeClass} {
            transform: translateY(0);

            .nav__item2 {
              opacity: 1;
            }
          }
        }
      }

      &.#{$hideClass} {
        .nav__list2 {
          // transform: translateY(-100%);
          transition: all 0.4s cubic-bezier(0.59, 0.01, 0.28, 1);

          .nav__item2 {
            transition: opacity .4s cubic-bezier(0.59, 0.01, 0.28, 1);
          }

          &.#{$activeClass} {
            transform: translateY(0);

            .nav__item2 {
              opacity: 1;
            }
          }
        }
      }
    }

    &__item {
      // position: relative;


      &.is-arrow {
        .nav__link {
          position: relative;

          &::after {
            opacity: 0;
            position: absolute;
            bottom: 1px;
            right: -12px;
            width: 18px;
            height: 18px;
            background: url(/img/common/btn-outlink-sm-on.png) no-repeat center;
            background-size: contain;
            transform: translateY(-50%);
            transition: all 0.4s ease;
            content: '';
          }

          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
      &.#{$activeClass} {
        .nav__link {
          opacity: 1;
        }
      }
    }

    &__link {
      position: relative;
      z-index: 1;
      padding: 22px 5px 5px;
      margin: 0 25px 17px;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.86;
      letter-spacing: -0.3px;
      color: $color-default;
      opacity: 1;
      white-space: pre;
      transition: color 0.4s;
      cursor: pointer;
      
      @at-root .theme-dark & {
        color: $color-white;
      }

      &:hover {
        font-weight: 800;
        color: $color-point;
        opacity: 1;
      }
    }

    &__list2 {
      visibility: hidden;
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      position: fixed;
      top: 0px;
      right: 0;
      left: 0;
      max-height: 100vh;
      padding: 100px 31.4% 0;
      transform: translateY(-100%);
      transition-delay: 0.2s;

      @at-root .lang-en & {
        .nav__item2 {
          &:nth-child(odd) {
            width: 60%;
          }
          &:nth-child(even) {
            width: 40%;
          }
        }
      }

      &.#{$activeClass} {
        display: flex;
        visibility: visible;
        padding-bottom: 55px;
        background-color: var(--reverse);
      }

      &.nav-vertical {
        display: block;

        .nav__item2:nth-child(2) {
          margin-top: 30px;
        }
      }
    }

    &__item2 {
      width: 50%;
      margin-top: 30px;
      cursor: pointer;
      opacity: 0;

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }

      &.#{$activeClass} {
        opacity: 1;
        transition-delay: 0.2s;

        .nav__link2 {
          color: $color-point;

          &::before {
            content: none;
          }
        }
      }
      
      &.kor {
        display: block;
      }
      &.eng {
        display: none;
      }
      @at-root .navercorp.lang-en & {
        &.kor {
          display: none;
        }
        &.eng {
          display: block;
        }
      }

    }

    &__link2 {
      position: relative;
      padding: 5px 0 6px;
      font-weight: 700;
      font-size: 22px;
      letter-spacing: -0.5px;
      white-space: pre;
      cursor: pointer;

      &:hover {
        &::before {
          width: 100%;
        }
      }

      &::before {
        position: absolute;
        left: 0;
        bottom: 0px;
        background-color: #1d1d1f;
        width: 0;
        height: 2px;
        content: '';
        transition: width 0.1s;
      }
    }
  }

  &__utils {
    display: flex;
    position: relative;
    z-index: 1;
    gap: 25px;
    align-items: center;

    .btn-search {
      visibility: visible;
      width: 24px;
      height: 24px;
      background-image: var(--ic-search-tab);
      background-size: 100%;
      transition: background-image 0.4s;

      &:hover {
        background-image: url('/img/common/ic-search-on.png');
      }
    }

    .btn-darkmode {
      width: 24px;
      height: 24px;
      background: url('/img/common/ic-darkmode.svg') no-repeat;
      background-size: 100%;
      transition: background-image 0.4s;

      &:hover {
        background-image: url('/img/common/ic-darkmode-on.png');
      }

      &.light {
        background-image: url('/img/common/ic-lightmode-w.svg');

        &:hover {
          background-image: url('/img/common/ic-lightmode-on.png');
        }
      }
    }

    .btn-mo-nav {
      display: none;
      width: 24px;
      height: 24px;
      background: url('/img/common/ic-mo-nav.svg') no-repeat;
      background-size: 100%;
      transition: background-image 0.4s;
      @at-root .theme-dark & {
        background-image: url('/img/common/ic-mo-nav-w.svg');
      }

      &:hover {
        background-image: url('/img/common/ic-mo-nav-on.png');
      }
    }
  }

  +.dim {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-header + 35;
    content: '';
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.4);
  }

  // &.#{$scrollClass} {
  //   // opacity: 0.8;
  //   background-color: $color-white;
  //   -webkit-backdrop-filter: blur(7px);
  //   backdrop-filter: blur(7px);
  // }

  &.#{$scrollUpClass} {
    background-color: rgba(255, 255, 255, .8);

    @at-root .theme-dark & {
      background-color: rgba(0, 0, 0, .8);
    }
  }

  &.#{$hideClass} {
    transform: translateY(-100%);
  }
}

.btn-lang {
  position: relative;
  padding-right: 11px;

  &.#{$openClass} {
    .btn-selected {
      &::before {
        transform: rotate(180deg);
        transform-origin: 50% 25%;
      }

      &:hover {
        &::before {
          transform: rotate(180deg);
          transform-origin: 50% 25%;
        }
      }
    }
  }

  &__list {
    display: none;
    position: absolute;
    left: 50%;
    bottom: -16px;
    padding: 12px;
    transform: translate(-50%, 100%);
    background-color: var(--reverse);
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.14);
    pointer-events: none;
  }

  &__item {
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }

    &.#{$selectedClass} button[class^='btn-'] {
      color: var(--default);
    }
  }

  button[class^='btn-'] {
    padding: 10px 12px 9px 12px;
    font-weight: 800;
    font-size: 14px;
    color: var(--navRgba);
    text-transform: uppercase;
    pointer-events: auto;

    &:hover {
      color: $color-point;
    }

    &.btn-selected {
      min-width: 21px;
      padding: 0;
      color: var(--default);
      transition: all 0.4s;

      &:hover {
        color: $color-point;
      }
    }
  }
}

.btn-selected {
  position: relative;

  &::before {
    display: block;
    position: absolute;
    top: 50%;
    right: -14px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%);
    background: var(--ic-lang-dropdown) no-repeat;
    background-size: 100%;
    transition: background-image .4s;
    content: "";
  }

  &:hover::before {
    background-image: url('/img/common/ic-lang-dropdown-on.png');
  }
}

.hidden-logo {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-mo-nav + 2;
  padding: 23px 20px;

  .logo__link {
    display: none;
    width: 92px;
    height: 18px;
    background: var(--logo) no-repeat;
    background-size: 100%;
  }
}

.mo-nav {
  overflow-y: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48.9%;
  height: 100%;
  padding: 30px;
  top: 0;
  right: -100%;
  z-index: $z-index-mo-nav + 1;
  background-color: var(--blackWhite);
  transition: right 0.3s ease-out;
  transition-delay: 0.2s;

  &.#{$activeClass} {
    right: 0;

    .mo-nav__item {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;

      &:nth-child(2) {
        transition-delay: 0.4s;
      }

      &:nth-child(3) {
        transition-delay: 0.5s;
      }

      &:nth-child(4) {
        transition-delay: 0.6s;
      }

      &:nth-child(5) {
        transition-delay: 0.7s;
      }

      &:nth-child(6) {
        transition-delay: 0.8s;
      }

      &:nth-child(7) {
        transition-delay: 0.9s;
      }

      &:nth-child(8) {
        transition-delay: 1s;
      }
    }
  }

  &.#{$posClass} {
    .mo-nav__item {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__logo {
    width: 92px;
    height: 18px;
    background: var(--logo) no-repeat;
    background-size: 100%;

    &.#{$showClass} {
      opacity: 0;
    }
  }

  .btn-close {
    position: absolute;
    right: 29px;
    top: 25px;
    width: 24px;
    height: 24px;
    background: var(--ic-nav-close) no-repeat;
    background-size: 100%;
  }

  &__list {
    padding-top: 33px;
  }

  &__item {
    border-top: 1px solid #e4e6ea;
    opacity: 0;
    transform: translateY(15px);
    transition: all 0.4s cubic-bezier(0.57, 0.21, 0.69, 1.25);

    @at-root .theme-dark & {
      border-color: #2f2e30;
    }

    &:first-child {
      border: none;
    }

    &.#{$openClass} {
      .mo-nav__link {
        font-weight: 800;
        color: $color-point;

        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &.no-list .mo-nav__link::after {
      display: none;
    }

    &.is-arrow {
      .mo-nav__link {
        &:after {
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          width: 20px;
          height: 20px;
          background: var(--ic-outlink-nav) no-repeat;
          background-size: 100%;
          transform: translateY(-50%);
          content: '';
        }
      }
    }
  }

  &__item2 {
    &.is-active {
      .mo-nav__link2 {
        color: $color-point;
      }
    }
      
    &.kor {
      display: block;
    }
    &.eng {
      display: none;
    }
    @at-root .navercorp.lang-en & {
      &.kor {
        display: none;
      }
      &.eng {
        display: block;
      }
    }
  }

  a[class^='mo-nav__link'] {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 1.67;
  }

  &__link {
    display: block;
    position: relative;
    padding: 18px 0;

    &::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      height: 20px;
      background: var(--ic-dropdown) no-repeat;
      background-size: 100%;
      transform: translateY(-50%);
      content: '';
    }
  }

  &__list2 {
    display: none;
    padding: 8px 0 23px;
  }

  &__link2 {
    padding: 8px 0;
  }

  &__utils {
    display: flex;
    align-items: center;

    .btn-darkmode {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(/img/common/ic-darkmode.svg) no-repeat center;
      background-size: 100%;
      transition: background-image .4s;
      position: absolute;
      top: 25px;
      right: 66px;

      &:hover {
        background-image: url('/img/common/ic-darkmode-on.png');
      }

      &.light {
        background-image: url('/img/common/ic-lightmode-w.svg');

        &:hover {
          background-image: url('/img/common/ic-lightmode-on.png');
        }
      }
    }

    .btn-lang {
      display: inline-block;
      position: absolute;
      top: 28px;
      right: 112px;

      &__list {
      top: 130px;
      left: 50%;
      bottom: auto;
      transform: translate(-50%, -100%);
      z-index: 1;
      }
    }
  }

  +.dim {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: $z-index-mo-nav;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: none;
    backdrop-filter: none;

    &.#{$activeClass} {
      display: block;
    }
  }
}

.search-tab {
  display: block;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 134px 0 124px;
  transform: translateY(-100%);
  transition: transform 0.4s cubic-bezier(0.59, 0.01, 0.28, 1);
  transition-delay: 0.2s;

  &.#{$activeClass} {
    background-color: var(--reverse);
    transform: translateY(0);

    .search-tab__box {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }

  &__inner {
    max-width: 1346px;
    margin: 0 auto;
  }

  &__box {
    display: flex;
    position: relative;
    align-items: center;
    opacity: 0;
    transition: opacity 0.25s cubic-bezier(0.59, 0.01, 0.28, 1);

    &:hover {
      .btn-search {
        background-image: url('/img/common/ic-search-tab-on.png');
      }

      .btn-delete {
        background-image: url('/img/common/ic-search-delete-on.png');
      }
    }

    .btn-delete {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      order: 2;
      width: 26px;
      height: 26px;
      background: var(--ic-search-delete) no-repeat;
      background-size: 100%;
      transition: background-image 0.4s;
      transform: translateY(-50%);
    }

    .btn-search {
      display: block;
      order: 0;
      width: 56px;
      height: 56px;
      background: var(--ic-search) no-repeat;
      background-size: 100%;
      transition: background-image 0.4s;
    }

    &.#{$activeClass} {
      padding-right: 50px;

      .btn-delete {
        display: block;
      }
    }
  }

  .search {
    order: 1;
    width: 100%;
    margin-left: 20px;
    font-weight: 800;
    font-size: 54px;
    line-height: 1.33;
    letter-spacing: -1.5px;
    border: none;
    outline: none;
    background: none;
    color: var(--blackTit);
    caret-color: $color-point;

    &::placeholder {
      font-size: 54px;
      line-height: 1.33;
      letter-spacing: -1.5px;
      color: #dddee1;
    }
  }
}

@include breakpoint(pc) {
  .header__inner {
    max-width: 1520px;
  }

  .header {
    .nav__list2 {
      padding: 100px 27.7% 55px;
    }
  }

  .search-tab {
    &__inner {
      max-width: 1136px;
    }
  }
}

@include breakpoint(tablet) {
  .header {
    &__inner {
      max-width: none;
      padding: 0 30px;
    }

    .nav {
      &__link {
        padding: 22px 5px 5px;
        margin: 0 13px 17px;
      }

      &__list2 {
        padding: 100px 23% 55px;

        @at-root .lang-en & {
          padding: 100px 18% 55px;
        }
      }
    }
  }

  .search-tab {
    padding: 131px 0;

    &__inner {
      max-width: none;
      padding: 0 30px;
    }

    .btn-search {
      width: 46px;
      height: 46px;
    }

    .search {
      margin-left: 16px;
    }

    &__box {
      .btn-delete {
        width: 20px;
        height: 20px;
      }

      &:hover {
        .btn-search {
          background-image: url('/img/common/ic-search-tab-on.png');
        }
      }
    }

    .search,
    .search::placeholder {
      font-size: 48px;
      line-height: 1.42;
      letter-spacing: -1.5px;
    }
  }
}

@include breakpoint(tablet-small) {
  .header {
    padding: 20px 0;

    &__inner {
      padding: 0 30px;

      .nav,
      .btn-darkmode,
      .btn-lang {
        display: none;
      }

      .btn-mo-nav {
        display: block;
      }
    }

    &__utils {
      gap: initial;

      .btn-search {
        margin-right: 18px;
      }
    }
  }

  .search-tab {
    padding: 110px 0 100px;

    .btn-search {
      width: 36px;
      height: 36px;
      background-image: var(--ic-search-tab);
    }

    .search {
      margin-left: 12px;
    }

    &__box {
      &:hover {
        .btn-search {
          background-image: url('/img/common/ic-search-tab-on.png');
        }
      }

      &.#{$activeClass} {
        padding-right: 30px;
      }
    }

    .search,
    .search::placeholder {
      font-size: 38px;
      letter-spacing: -0.8px;
    }
  }
}

@include breakpoint(mobile) {
  .header {
    &__inner {
      padding: 0 20px;
    }
  }

  .mo-nav {
    width: 100%;
    padding: 23px 20px 40px;
    &__logo {
      &.#{$showClass} {
      opacity: 1;
      }
    }

    .btn-lang {
      top: 24px;
      right: 100px;
    }

    .btn-darkmode {
      top: 20px;
      right: 56px;
    }

    .btn-close {
      top: 20px;
      right: 17px;
    }
  }

  .search-tab {
    padding: 115px 0 105px;

    &__inner {
      padding: 0 17px;
    }

    .search {
      font-size: 32px;
      letter-spacing: -0.5px;

      &::placeholder {
        font-size: 24px;
        letter-spacing: -0.5px;
      }
    }

    .btn-search {
      width: 32px;
      height: 32px;
    }

    .search {
      font-size: 28px;
      margin-left: 10px;

      @at-root .navercorp.lang-en & {
        font-size: 26px;
      }
    }
  }
}