.#{$component-name} {
  &.media-press {
    .board {
      &__inner {
        display: flex;
        max-width: 1800px;
        margin: 0 auto;
      }

      &__main {
        width: calc((100% - 10px) / 2);
        margin-left: 0;

        .main {
          &__link {
            display: inline-block;
            width: 100%;
            height: 100%;
            &:hover {
              .main__img {
                background-size: 105%;
              }

              .main__txt {
                .tit {
                  color: $color-point;
                }
              }
            }
          }

          &__img {
            overflow: hidden;
            width: 100%;
            padding-top: 66.7%;
            background: no-repeat center 0;
            background-image: url('/img/common/thumnail.png');
            background-size: 100%;
            transition: all 0.3s;
            background-color: var(--newslistBg);
          }

          &__txt {
            height: 33.7%;
            padding: 35px 40px 32px;
            text-align: left;
            background-color: var(--newslistBg);



            .category,
            .date {
              font-weight: 800;
              font-size: 14px;
              line-height: 2;
              color: $color-white;
            }

            .date {
              margin-left: 12px;
              font-weight: 700;
              color: #a1a1a6;
            }

            .tit {
              overflow : hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              max-height: 2.84em;
              margin-top: 17px;
              font-weight: 800;
              font-size: 38px;
              line-height: 1.42;
              letter-spacing: -1.5px;
              color: $color-white;
              transition: color .3s;
            }
          }
        }

        .banner-slide {
          height: 100%;

          .swiper-pagination {
            bottom: 32px;
            padding: 0 40px;
          }

          .swiper-pagination-bullets .swiper-pagination-bullet {
            margin-left: 16px;

            &:first-child {
              margin-left: 0;
            }
          }

          .swiper-pagination-bullet-active {
            opacity: 1;
          }
        }
      }

      &__sub {
        width: calc((100% - 10px) / 2);
        margin-left: 10px;

        .sub {
          &__list {
            height: 100%;
          }

          &__item {
            margin-top: 10px;

            &:first-child {
              margin-top: 0;
            }
          }

          &__link {
            display: flex;
            width: 100%;
            height: 100%;
            transition: all 0.3s;

            &:hover {
              .sub__img {
                background-size: auto 110%;
              }
            }
          }

          &__img {
            overflow: hidden;
            width: 100%;
            padding-top: 32.8%;
            background: no-repeat 50%;
            background-image: url('/img/common/thumnail.png');
            background-size: auto 100%;
            transition: all 0.3s;
            background-color: var(--bgColor02);
          }

          &__txt {
            width: 96.2%;
            padding: 28px 32px;
            background-color: var(--bgColor02);

            .category,
            .date {
              font-weight: 800;
              font-size: 14px;
              line-height: 2;
              color: var(--default);
            }

            .date {
              margin-left: 10px;
              font-weight: 700;
              color: $color-gray;
            }

            .tit {
              overflow : hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              max-height: 5.84em;
              margin-top: 13px;
              font-weight: 800;
              font-size: 26px;
              line-height: 1.46;
              letter-spacing: -0.8px;
              color: var(--default);
            }
          }
        }
      }
    }

    .media-press {
      margin-top: 107px;

      &__inner {
        position: relative;
        max-width: 1800px;
        margin: 0 auto;
      }

      &__search {
        margin-top: 44px;
          
        .dropdown__select {
          .dropdown__selected-value {
            font-weight: 700;
            @at-root .theme-dark & {
              color: $color-white;
            }
          }
        }
      }

      .section__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 1.46;
        letter-spacing: -0.8px;
      }

      &__tab {
        overflow: hidden;        
        margin-top: 25px;
        padding: 0 8px 0 8px;

        .btn-reset {
          font-weight: 800;
          font-size: 14px;
          line-height: 2;
          visibility: hidden;
          color: $color-point;

          &.#{$activeClass} {
            visibility: visible;
          }
        }

        .tab {
          &__list {
            float: right;
            max-width: none;
            margin: 0;
            padding: 0;
          }

          &__cta {
            overflow: hidden;
            margin-left: 12px;
            margin-right: 0;
            padding: 0;
            text-indent: -9999px;
            border: none;
            opacity: 0.2;
            @include sp-desktop('ic-list');

            &.is-active {
              &::after {
                border-bottom: none;
              }
            }

            &.#{$activeClass} {
              opacity: 1;
              @at-root .theme-dark & {
                @include sp-desktop('ic-list-w');
            }
            }
          }
        }

        .btn-list {
          opacity: 0.2;
          @include sp-desktop('ic-list');
          @at-root .theme-dark & {
            opacity: 1;
            @include sp-desktop('ic-list-g');
        }
          &.#{$activeClass} {
            opacity: 1;
            @at-root .theme-dark & {
              @include sp-desktop('ic-list-w');
          }
          }
        }

        .btn-grid {
          opacity: 0.2;
          @include sp-desktop('ic-grid');
          @at-root .theme-dark & {
            opacity: 1;
            @include sp-desktop('ic-grid-g');
        }
          &.#{$activeClass} {
            opacity: 1;
            @at-root .theme-dark & {
              @include sp-desktop('ic-grid-w');
          }
          }
        }

        .search-result__count {
          float: left;
          display: none;
          font-size: 14px;
          font-weight: 700;
          color: var(--default);
          line-height: 1.88;

          &-num {
            color: $color-point;
          }

          &.#{$activeClass} {
            display: block;
          }
        }

      }

      &__wrap {
        &.is-search {
          .card__sub {
            margin-top: 0;

            &.is-list-view {
              margin-top: 22px;

              .sub__title {
                display: block;
                color: var(--default);
              }

              .sub__item {
                &:last-child {
                  border-bottom: 0;
                }
              }
            }
          }
        }

        .search-result {
          &__area {
            &.use-result {
              .search-result__text {
                padding: 66px 0 21px;
              }
            }

            &.no-result {
              display: flex;
              justify-content: center;
              padding-bottom: 99px;
              border-bottom: 1px solid #e4e6ea;

              .search-result__text {
                // padding: 57px 225px 22px 0;
                // text-align: center;
              }
            }
          }

          &__text {
            padding: 59px 0 53px;
          }

          &__list {
            max-width: 585px;
            margin: 0 auto;
            font-size: 16px;
            line-height: 2;
          }
        }

        .card__sub {
          margin-top: -9px;
          margin-left: -6px;

          .highlight {
            color: $color-point;
          }

          .sub {
            &__list {
              margin: 0;
              display: inline;

              &:first-of-type {
                margin-top: -60px;
              }
            }

            &__title {
              display: none;
            }

            &__item {
              width: calc((100% - 44px) / 4);
              margin: 30px 0 0 11px;
            }
          }

          &.is-list-view {
            margin-top: 57px;
            margin-left: 0;

            .sub {
              &__list {
                display: block;
                margin: 0;
              }

              &__title {
                display: none;
              }

              &__title + .sub__list {
                margin-top: -1px;
              }

              &__item {
                width: 100%;
                margin: 0;
              }
            }
          }
        }

        .pagination {
          margin-top: 60px;
        }
      }
    }

    .quick-link {
      margin-top: 160px;

      &__inner {
        max-width: 1800px;
        margin: 0 auto;
      }

      &__wrap {
        overflow: hidden;
      }

      &__list {
        margin: -21px 0 0 -21px;
        font-size: 0;
      }

      &__item {
        display: inline-block;
        width: calc((100% - 63px) / 3);
        margin: 21px 0 0 21px;

        &:nth-child(2) .quick-link__link {
          background-image: url(/img/pc/go-brand-resource-bg.png);
        }

        &:nth-child(3) .quick-link__link {
          background-image: url(/img/pc/go-research-bg.png);
        }
      }

      &__link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        min-height: 250px;
        padding: 20px 30px 30px;
        background: url(/img/pc/go-media-event-bg.png) no-repeat 50%;
        background-size: 100%;
        transition: all 0.3s;

        &:hover {
          background-size: 120%;

          .quick-link__button {
            background-color: $color-point;
            background-image: url(/img/common/ic-quicklink.png);
          }
        }
      }

      &__txt {
        .tit {
          font-weight: 800;
          font-size: 32px;
          line-height: 1.5;
          letter-spacing: -1px;
          color: $color-default;
        }

        .desc {
          margin-top: 12px;
          font-weight: 700;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.5px;
          color: $color-default;
        }
      }

      &__btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;

        .quick-link__button {
          background-color: var(--outlinkBg04);
          background-image: var(--ic-quicklink);
        }
      }
    }

    .link__rss {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 16px;
      font-weight: 900;
      line-height: 1.88;
      color: #111;

      &::before{
        display: inline-block;
        position: relative;
        vertical-align: -6px;
        width: 24px;
        height: 24px;
        background: url('/img/common/btn-rss.png') no-repeat 0 0;
        background-size: 100% auto;
        margin-right: 10px;
        content: '';
      }
    }

    .dropdown__select {
      z-index: 1;
      &.wide {
        margin-left: 1px;
      }
    }
  }

  &.lang-en {
    .link__rss {
      display: block;
    }
  }
}

.theme-dark {
  .navercorp.media-press {
    .dropdown__select {
      &:hover {
        box-shadow: 0px 0px 0px 1px #6e6e73;
      }
    }

    .input__box {
      &:hover {
        box-shadow: 0px 0px 0px 1px #6e6e73;
      }

      &.is-active {
        box-shadow: 0px 0px 0px 1px #6e6e73;
        input{
          color: var(--default);
        }
      }
    }

    .link__rss {
      color: #fff;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.media-press {
      .board__inner {
        max-width: 1520px;
      }

      .board__main {
        .main__txt {
          .tit {
            max-height: 3.1em;
            font-size: 32px;
            line-height: 1.5;
            letter-spacing: -1px;
          }
        }

        .banner-slide {
          .swiper-pagination {
            bottom: 26px;
          }

          .swiper-pagination-bullets .swiper-pagination-bullet {
            margin-left: 10px;
          }
        }
      }

      .board__sub {
        .sub__item {
          height: calc((100% - 16px) / 3);
          margin-top: 8px;
        }

        .sub__txt {
          min-height: 248px;
          padding: 22px 26px;

          .tit {
            max-height: 6.2em;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
          }
        }
      }

      .media-press {
        &__inner {
          max-width: 1520px;
        }

        &__tab {
          margin-top: 25px;
          padding-right: 7px;
        }

        &__wrap {
          .search-result {
            &__list {
            }
          }

          .card__sub {
            margin-top: -44px;

            .sub {
              &__item {
                width: calc((100% - 24px) / 4);
                margin: 60px 0 0 6px;
              }
            }

            &.is-list-view {
              .sub {
                &__list {
                  margin: 0;
                }

                &__item {
                  width: 100%;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .quick-link {
        &__inner {
          max-width: 1520px;
        }

        &__link {
          padding: 20px 24px 24px;
          min-height: 210px;
        }

        &__txt {
          .tit {
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
          }

          .desc {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.media-press {

      .board__inner {
        max-width: none;
        padding: 0 30px;
      }

      .board__main {
        width: calc((100% - 8px) / 2);

        .main__txt {
          min-height: 189px;
          padding: 25px 30px 24px;

          .category,
          .date {
            font-size: 12px;
            line-height: 2.17;
          }

          .date {
            margin-left: 10px;
          }

          .tit {
            margin-top: 11px;
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
          }
        }

        .banner-slide {
          .swiper-pagination {
            bottom: 24px;
            padding: 0 30px;
          }

          .swiper-pagination-bullets .swiper-pagination-bullet {
            margin-left: 11px;
            height: 3px;
          }
        }
      }

      .board__sub {
        width: calc((100% - 8px) / 2);
        margin-left: 8px;

        .sub__txt {
          min-height: 164px;
          padding: 15px 20px;

          .category,
          .date {
            font-size: 10px;
            line-height: 2.2;
          }

          .tit {
            -webkit-line-clamp: 3;
            max-height: 5.58em;
            margin-top: 7px;
            font-size: 14px;
            line-height: 1.86;
            letter-spacing: -0.3px;
          }
        }
      }

      .media-press {
        margin-top: 80px;

        &__inner {
          max-width: none;
          padding: 0 30px;
        }

        .section__title {
          font-size: 22px;
          line-height: 1.55;
          letter-spacing: -0.6px;
        }

        &__search {
          margin-top: 33px;
        }

        &__tab {
          margin-top: 23px;
          padding: 0 10px 0 10px;

          .btn-reset {
            font-size: 12px;
            line-height: 2.17;
          }
        }

        &__wrap {
          &.is-search {
            .card__sub {
              &.is-list-view {
                margin-top: 9px;
              }
            }
          }

          .search-result {
            &__area {
              &.no-result {
                padding-bottom: 78px;

                .search-result {
                  &__text {
                    padding: 34px 225px 22px 0;
                    font-size: 18px;
                    line-height: 1.67;
                  }
                }
              }
            }

            &__text {
              padding: 55px 0 36px;
            }

            &__list {
              padding: 0;
              font-size: 14px;
              line-height: 1.86;
            }
          }

          .card__sub {
            margin-top: -5px;
            
            .sub {
              &__item {
                margin: 24px 0 0 6px;
                width: calc((100% - 18px)/3);
              }
            }

            &.is-list-view {
              margin-top: 54px;

              .sub {
                &__list {
                  margin: 0;
                }

                &__item {
                  margin: 0;
                }
              }
            }
          }

          .pagination {
            margin-top: 40px;
          }
        }
      }

      .quick-link {
        margin-top: 120px;

        &__inner {
          max-width: none;
          padding: 0 30px;
        }

        &__list {
          margin: -15px 0 0 -15px;
        }

        &__item {
          width: calc((100% - 45px) / 3);
          margin: 15px 0 0 15px;
        }

        &__link {
          min-height: 132px;
          padding: 16px;
        }

        &__txt {
          .tit {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }

          .desc {
            margin-top: 4px;
            font-size: 12px;
            line-height: 1.83;
          }
        }

        &__btn {
          margin-top: 0;
        }
      }

      .link__rss {
        right: 30px;
        font-size: 14px;
        line-height: 1.86;
  
        &::before{
          vertical-align: -5px;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.media-press {
      .cont__board {
        .board__inner {
          flex-direction: column;
        }

        .board__main {
          width: 100%;

          .main__txt {
            min-height: 250px;

            .date {
              font-size: 10px;
              line-height: 2.2;
            }

            .tit {
              margin-top: 13px;
              font-size: 26px;
              line-height: 1.46;
              letter-spacing: -0.8px;
            }
          }
        }

        .board__sub {
          margin-top: 8px;
          width: 100%;
          margin-left: 0;

          .sub__img {
            padding-top: 31.4%;
          }

          .sub__txt {
            width: 103.5%;
            min-height: 223px;
            padding: 18px 24px;

            .category {
              font-weight: 800;
              font-size: 12px;
              line-height: 2.17;
            }

            .tit {
              font-size: 18px;
              line-height: 1.67;
              letter-spacing: -0.5px;
            }
          }
        }
      }

      .media-press {
        &__search {
          display: flex;
          margin-top: 30px;

          .category-filter {
            flex-shrink: 0;

            .btn-reset {
              margin-left: 16px;
            }
          }
        }

        &__tab {
          width: 100%;
          justify-content: flex-end;
          margin: 4px 0 0 0;
          padding: 0 0 0 16px;
        }

        &__wrap {
          &.is-search {
            .card__sub {
              margin-top: 0;

              &.is-list-view {
                margin-top: 4px;
              }
            }
          }

          .search-result {
            &__area {
              &.no-result {
                margin-top: 28px;
                padding-bottom: 58px;
                border-top: 1px solid $color-gray4;

                .search-result__text {
                  padding: 60px 0 31px;
                  text-align: left;
                }
              }
            }

            &__text {
              padding: 41px 0 31px;
            }

            &__list {
              max-width: none;
              padding-left: 0;
              font-size: 14px;
              line-height: 1.86;
            }
          }

          .card__sub {
            .sub {
              &__item {
                width: calc((100% - 12px) / 2);
                margin: 20px 0 0 6px;
              }
            }

            &.is-list-view {
              margin-top: 28px;

              .sub {
                &__list {
                  margin: 0;
                }

                &__item {
                  width: 100%;
                  margin: 0;
                }
              }
            }
          }

          .pagination {
            margin-top: 60px;
          }
        }
      }

      .quick-link {
        &__list {
          margin: 0;
        }

        &__item {
          width: 100%;
          margin: 0;
          margin-top: 12px;

          &:first-child {
            margin-top: 0;
          }
        }

        &__link {
          min-height: 180px;
          padding: 22px 20px 20px 24px;
        }

        &__txt {
          .tit {
            font-size: 22px;
            line-height: 1.55;
            letter-spacing: -0.6px;
          }

          .desc {
            margin-top: 8px;
          }
        }
      }

      .link__rss {
        position: static;
        margin: -40px 0 70px;
      }

    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.media-press {
      .container{
        padding-bottom: 40px;

        .scroll-title{
          &__bar{

            .progress{
              position: relative;
              top: -1px;
            }
          }
        }
      }
      .cont__board {
        .board__inner {
          padding: 0;
        }

        .board__main {
          .main__txt {
            padding: 19px 20px 24px;

            .tit {
              max-height: 4.38em;
              -webkit-line-clamp: 3;
            }

            .banner-slide {
              .swiper-pagination {
                padding: 0 20px;
              }

              .swiper-pagination-bullets .swiper-pagination-bullet {
                margin-left: 9px;
              }
            }
          }

  
        }

        .board__sub {
          margin-top: 30px;
          padding: 0 20px;

          .sub__item {
            margin-top: 10px;

            &:first-child {
              margin-top: 0;
            }
          }

          .sub__link {
            flex-direction: column;
          }

          .sub__img {
            padding-top: 66.6%;
          }

          .sub__txt {
            width: 100%;
            padding: 14px 20px;
          }
        }
      }

      .media-press {
        &__inner {
          padding: 0 20px;
        }

        &__tab {
          .btn-list {
            @include sp-mobile('ic-list');
            @at-root .theme-dark & {
              @include sp-mobile('ic-list-g');
          }
          }

          .btn-grid {
            @include sp-mobile('ic-grid');
            @at-root .theme-dark & {
              @include sp-mobile('ic-grid-g');
          }
          }
        }

        &__wrap {
          &.is-search {
            .card__sub {
              &.is-list-view {
                margin-top: 11px;
              }
            }
          }

          .search-result {
            &__area {
              &.no-result {
                padding-bottom: 49px;

                .search-result__text {
                  padding: 50px 0 21px;
                }
              }
            }

            &__text {
              padding: 35px 0 30px;
            }
          }

          .card__sub {
            margin-left: 0;
            margin-top: 14px;
            
            .sub {
              &__list {
                margin: 0;
              }

              &__item {
                width: 100%;
                margin: 10px 0 0 0;
              }
            }

            &.is-list-view {
              .sub {
                &__list {
                  margin: 0;
                }

                &__item {
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .quick-link {
        &__inner {
          padding: 0 20px;
        }

        &__item {
          margin-top: 14px;
        }

        &__link {
          min-height: 143px;
          padding: 16px 20px 20px;
        }

        &__txt {
          .tit {
            font-size: 18px;
            line-height: 1.67;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}
