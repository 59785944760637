.#{$component-name} {
  &.tech1784 {
    color: white;

    .track-animation {
      &__highlight-area {
        width: 1252px;
      }

      &__highlight-text {
        text-align: center;
      }
    }

    .container {
      padding-bottom: 0;
    }

    .info{
      &__list {
        &-title {
          width: 42.23%;
          font-weight: 800;
          font-size: 48px;
          line-height: 1.38;
  
          &-wrap {
            display: flex;
            padding: 0 60px 0 26.82%;
            margin-bottom: 80px;
          }
        }

        
        &-desc {
          font-size: 18px;
          line-height: 1.89;
          letter-spacing: -0.5px;
        }
      }

      &__contents {
        padding-right: 70px;
      }

      &__title {
        margin-top: 20px;
        font-size: 22px;
        font-weight: 800;
        line-height: 38px;
      }

      &__desc {
        margin-top: 20px;
        width: 130%;
      }

    }

    .full-sticky {
      &:after {
        background: rgba(0, 0, 0, 0.4);
      }
    }

    .cover {
      position: relative;
    }

    .content {
      &__daily {
        padding: 160px 0 180px;
      }

      &__future {
        padding-bottom: 200px;
      }

      &__system,
      &__design {
        padding: 160px 0 200px;
      }
      &__idea {
        padding-bottom:140px;
      }
    }

    .card__sub {
      &-wrap {
        padding-bottom: 128px;
      }
    }

    .content03 {
      .cover {
        background: url(/img/pc/tech-1784-visual-img-1.jpg) no-repeat center / cover;
      }
    }

    .content04 {
      .cover {
        background: url(/img/pc/tech-1784-visual-img-2.jpg) no-repeat center / cover;
      }
    }

    .content05 {
      .cover {
        background: url(/img/pc/tech-1784-visual-img-3.jpg) no-repeat center / cover;
      }
    }

    .content06 {
      .cover {
        background: url(/img/pc/tech-1784-visual-img-4.jpg) no-repeat center / cover;
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.tech1784 {
      .content {
        &__daily {
          padding: 140px 0 160px;
        }

        &__system,
        &__design {
          padding: 140px 0 200px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.tech1784 {
      .track-animation {
        &__highlight-area {
          width: auto;
        }

        &__highlight-text {
          padding: 0 50px;
        }
      }

      .info__list {
        &-title {
          width: 50%;
          font-size: 38px;

          &-wrap {
            padding: 0 30px;
            margin-bottom: 60px;
          }
        }

        &-desc {
          font-size: 16px;
        }
      }

      .content {
        &__daily {
          padding: 120px 0 140px;
        }

        &__future {
          padding-bottom: 120px;
        }

        &__system,
        &__design {
          padding: 120px 0;
        }

        &__idea {
          padding-bottom: 140px;
        }
      }

      .card__sub {
        &-wrap {
          padding-bottom: 80px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.tech1784 {
      .info__list {
        &-title {
          width: 100%;
          margin-bottom: 20px;
          font-size: 32px;

          &-wrap {
            flex-direction: column;
            margin-bottom: 40px;
          }
        }

        &-container {
          .info__thumbnail {
            &-item {
              .info__contents {
                .info__title {
                  font-size: 18px;
                  line-height: 30px;
                  letter-spacing: -0.3px;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
      
      .content {
        &__idea {
          padding-bottom: 100px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.tech1784 {
      .track-animation {
        &__highlight-area {
          max-width: 375px;
        }

        &__highlight-text {
          padding: 0 30px;
          text-align: left;
        }
      }

      .info__list {
        &-title {
          &-wrap {
            padding: 0 20px;
          }
        }
      }

      .info__desc {
        width: max-content;
      }

      .content {
        &__daily {
          padding: 80px 0 160px;
        }

        &__future {
          padding-bottom: 160px;
        }

        &__system,
        &__design,
        &__idea {
          padding: 80px 0 160px;
        }
      }

      .content03 {
        .cover {
          background-image: url(/img/mo/tech-1784-visual-img-1.jpg);
        }
      }

      .content04 {
        .cover {
          background-image: url(/img/mo/tech-1784-visual-img-2.jpg);
        }
      }

      .content05 {
        .cover {
          background-image: url(/img/mo/tech-1784-visual-img-3.jpg);
        }
      }

      .content06 {
        .cover {
          background-image: url(/img/mo/tech-1784-visual-img-4.jpg);
        }
      }
    }
  }
}
