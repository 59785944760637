// video player
.video {
  &__player {
    overflow: hidden;
    position: relative;
    height: 100%;

    video {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      min-height: 100vh;
      transform: translateX(-50%);
      object-fit: cover;
      object-position: center top;
      // opacity: 0;
    }

    &.loaded {
      video {
        z-index: 1;
      }
    }
  }

  &__image {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: 100vh;
      object-fit: cover;
      object-position: center top;
    }
  }
}