.#{$component-name} {
  &.media-communication {
    .common__title {
      margin-bottom: 55px;
    }

    .#{$ebClass} {
      font-weight: 800;
    }

    .#{$bdClass} {
      font-weight: 700;
    }

    .section {
      max-width: 1800px;
      margin: 0 auto;
      padding-left: 460px;
    }

    .js-nav-section {
      padding: 45px 0 60px;
      margin-bottom: 15px;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .section__title {
      font-weight: 700;
      font-size: 26px;
      line-height: 1.46;
      letter-spacing: -0.8px;
      color: var(--blackTit);
    }

    .table {
      margin-top: 30px;
      border-top-color: #6e6e73;
      border-bottom: none;

      &__tr {
        flex: 1;
      }

      &__name, &__box {
        padding: 0;
        white-space: nowrap;
      }
    }

    .media-communication {
      &-formal {
        .table {
          &__thead {
            border-bottom: 1px solid #dddddd;

            @at-root .theme-dark & {
              border-bottom: 1px solid #2f2e30;
            }

            &-th {
              &:last-child {
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
              }
            }
          }

          &__tbody {
            display: flex;

            &-td {
              display: flex;
              justify-content: flex-end;
              padding-right: 20px;
            }
          }

          &__tr {
            border-top: none;
            border-bottom: 1px solid #dddddd;
            margin-right: 19px;

            @at-root .theme-dark & {
              border-bottom: 1px solid #2f2e30;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &-service, &-affiliate {
        .table {
          &__tbody {
            &-td {
              display: flex;
              align-items: center;
              margin-right: 19px;
              border-top: 1px solid #dddddd;

              @at-root .theme-dark & {
              border-top: 1px solid #2f2e30;
            }

              &:last-child {
                margin-right: 0;
              }

              &.empty {
                min-height: 71px;
              }
            }
          }

          &__box {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
          }

          &__tr {
            border: none;

            &:first-child {
              .table__tbody-td {
                border-top: none;
                @at-root .theme-dark & {
                  border-top: 1px solid #2f2e30;
                }
              }
            }

            &:last-child {
              .table__tbody-td {
                border-bottom: 1px solid #dddddd;

                @at-root .theme-dark & {
                  border-bottom: 1px solid #2f2e30;
                }

                &.empty {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }

      &__link {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        &.blog {
          @include sp-desktop('btn-share-blog');

          &:hover {
            @include sp-desktop('btn-share-blog-on');
          }
        }

        &.n-tv {
          @include sp-desktop('btn-share-navertv');

          &:hover {
            @include sp-desktop('btn-share-navertv-on');
          }
        }

        &.instagram {
          @include sp-desktop('btn-share-instagram');

          &:hover {
            @include sp-desktop('btn-share-instagram-on');
          }
        }

        &.facebook {
          @include sp-desktop('btn-share-facebook');

          &:hover {
            @include sp-desktop('btn-share-facebook-on');
          }
        }

        &.twitter {
          @include sp-desktop('btn-share-twitter');

          &:hover {
            @include sp-desktop('btn-share-twitter-on');
          }
        }

        &.youtube {
          @include sp-desktop('btn-share-youtube');

          &:hover {
            @include sp-desktop('btn-share-youtube-on');
          }
        }

        &.home {
          @include sp-desktop('btn-share-home');

          &:hover {
            @include sp-desktop('btn-share-home-on');
          }
        }

        &.post {
          @include sp-desktop('btn-share-link');

          &:hover {
            @include sp-desktop('btn-share-link-on');
          }
        }

        &.band {
          @include sp-desktop('btn-share-band');

          &:hover {
            @include sp-desktop('btn-share-band-on');
          }
        }

        &.cafe {
          @include sp-desktop('btn-share-cafe');

          &:hover {
            @include sp-desktop('btn-share-cafe-on');
          }
        }
      }
    }
  }
}

.theme-dark {
  .navercorp.media-communication {
    .media-communication__link {
      &.blog {
        @include sp-desktop('btn-share-blog-on');

        &:hover {
          @include sp-desktop('btn-share-blog');
        }
      }

      &.n-tv {
        @include sp-desktop('btn-share-navertv-on');

        &:hover {
          @include sp-desktop('btn-share-navertv');
        }
      }

      &.instagram {
        @include sp-desktop('btn-share-instagram-on');

        &:hover {
          @include sp-desktop('btn-share-instagram');
        }
      }

      &.facebook {
        @include sp-desktop('btn-share-facebook-on');

        &:hover {
          @include sp-desktop('btn-share-facebook');
        }
      }

      &.twitter {
        @include sp-desktop('btn-share-twitter-on');

        &:hover {
          @include sp-desktop('btn-share-twitter');
        }
      }

      &.youtube {
        @include sp-desktop('btn-share-youtube-on');

        &:hover {
          @include sp-desktop('btn-share-youtube');
        }
      }

      &.home {
        @include sp-desktop('btn-share-home-on');

        &:hover {
          @include sp-desktop('btn-share-home');
        }
      }

      &.post {
        @include sp-desktop('btn-share-link-on');

        &:hover {
          @include sp-desktop('btn-share-link');
        }
      }

      &.band {
        @include sp-desktop('btn-share-band-on');

        &:hover {
          @include sp-desktop('btn-share-band');
        }
      }

      &.cafe {
        @include sp-desktop('btn-share-cafe-on');

        &:hover {
          @include sp-desktop('btn-share-cafe');
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.media-communication {
      .section {
        max-width: 1520px;
        padding-left: 386px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.media-communication {
      .common__title {
        margin-bottom: 0;
      }

      .section {
        max-width: none;
        padding: 0 30px;
      }

      .js-nav-section {
        margin-bottom: 0;
        padding-top: 120px;
        padding-bottom: 0;

        &:first-child {
          padding-top: 100px;
        }
      }

    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.media-communication {
      .section__title {
        font-size: 24px;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      .js-nav-section {
        &:first-child {
          padding-top: 100px;
        }
      }

      .table {
        margin-top: 40px;

        &__thead {
          &-th {
            padding: 16px 0;
          }
        }

        &__tbody {
          &-th, &-td {
            padding: 16px 0;
          }
        }
      }

      .media-communication {
        &-formal {
          .table {
            &__thead {
              &-th {
                &:last-child {
                  padding-right: 12px;
                }
              }
            }

            &__tbody {
              &-td {
                padding-right: 12px;
              }
            }

            &__tr {
              margin-right: 12px;
            }
          }
        }

        &-service, &-affiliate {
          .table {
            &__tbody {
              &-td {
                margin-right: 12px;

                &.empty {
                  min-height: 64px;
                }
              }
            }

            &__box {
              padding-right: 12px;
            }
          }
        }

        &__link {
          margin-right: 10px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.media-communication {
      .section__title {
        line-height: 1.58;
      }

      .section {
        padding: 0 20px;
      }

      .js-nav-section {
        padding-top: 80px;
      }

      .media-communication {
        &-formal {
          .table {
            &__thead {
              flex-direction: column;

              &-th {
                &:last-child {
                  padding: 4px 0 20px 0;
                }
              }
            }

            &__tbody {
              flex-direction: column;

              &-td {
                padding-right: 0;
              }
            }

            &__tr {
              margin-right: 0;
            }
          }
        }

        &-service, &-affiliate {
          .table {
            &__tbody {
              &-td {
                width: 100%;
                margin-right: 0;

                &.empty {
                  display: none;
                }
              }
            }

            &__tr {
              flex-direction: column;

              &:first-child {
                .table__tbody-td:last-child {
                  border-top: 1px solid #dddddd;
                  @at-root .theme-dark & {
                    border-top: 1px solid #2f2e30;
                  }
                }
              }

              &:last-child {
                .table__tbody-td:first-child {
                  border-bottom: none;
                }
              }
            }

            &__box {
              padding-right: 0;
            }
          }
        }

        &-affiliate {
          .table {
            &__tr {
              &:last-child {
                .table__tbody-td:first-child {
                  border-bottom: 1px solid #dddddd;
                  @at-root .theme-dark & {
                    border-bottom: 1px solid #2f2e30;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
