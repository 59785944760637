.anchor-section {
  position: relative;
  -webkit-tap-highlight-color: transparent;
  
  .btn-arrow {
    position: absolute;
    bottom: 50px;
    left: 0;
    z-index: 10;
    width: 68px;
    height: 77px;
    background: url('/img/common/ic-arrow-down-black.png') no-repeat;
    background-size: 100%;
    animation: arrowAnimation 1.6s cubic-bezier(.455, .03, .515, .955) infinite both;
    transition: background-image .4s;

    @at-root .theme-dark & {
      position: absolute;
      z-index: 10;
      background: url('/img/common/ic-arrow-down.png') no-repeat;
      background-size: 100%;
      animation: arrowAnimation 1.6s cubic-bezier(.455, .03, .515, .955) infinite both;
      transition: background-image .4s;
    }

    &__box {
      position: absolute;
      top: 100vh;
      left: 50%;
      width: 100%;
      max-width: 1800px;
      transform: translateX(-50%);

      &.#{$fixedClass} {
        position: fixed;
        top: auto;
        bottom: 0;
      }

      &.is-fixed-bottom {
        position: absolute;
        bottom: 0;
      }
    }

    &.thema-black {
      background-image: url('/img/common/ic-arrow-down.png')
    }
  }
}

.anchor-nav {
  position: relative;
  top: 0;
  z-index: $z-index-header + 31;
  width: 100%;
  transition: opacity 0.4s;

  &.#{$fixedClass} {
    position: fixed;

    .anchor-nav__menu {
      .menu__wrap {
        padding: 45px 0 37px;
      }

      &.#{$hideClass} {
        .menu__wrap {
          top: 50px;
          padding: 45px 0 37px;
        }
      }
    }
  }

  &.is-fixed-bottom {
    // opacity: 0;
    position: absolute;
    top: auto;
    bottom: 0;
  }

  &.thema-white {
    .anchor-nav {
      &__menu {
        .menu {
          &__link {
            color: $color-default;

            @at-root .thema-dark & {
              color: #fff;
            }
          }

          &__list {
            &.depth2 {
              .menu__link {
                &::before {
                  background-color: $color-default;

                  @at-root .thema-dark & {
                    color: $color-default;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.thema-black {
    .anchor-nav {
      &__menu {
        .menu {
          &__link {
            color: $color-white;

            @at-root .theme-dark & {
              color: $color-white;
            }
          }

          &__list {
            &.depth2 {
              .menu__link {
                &::before {
                  background-color: $color-white;

                  @at-root .theme-dark & {
                    background-color: $color-white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 100%;

    .progress {
      display: block;
      width: 0;
      height: 100%;
      background-color: $color-point;
      transition: width .2s;
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 1800px;
    margin: 0 auto;
    // transition: opacity 0.4s;

    .menu {
      &__wrap {
        position: absolute;
        top: 0;
        left: 0;
        padding: 57px 0 37px;
        transition: padding 0.4s;
      }

      &__list {
        display: flex;
        flex-direction: column;
        font-size: 0;

        &.depth2 {
          .menu__link {
            &::before {
              display: inline-block;
              width: 3px;
              height: 3px;
              margin-right: 6px;
              background-color: $color-default;
              vertical-align: middle;
              content: '';
            }
          }
        }
      }

      &__link {
        display: inline-block;
        width: 100%;
        height: 100%;
        font-weight: 800;
        font-size: 14px;
        line-height: 2.15;
        color: var(--default);
        opacity: 0.3;
        transition: color .4s;

        &.#{$activeClass} {
          opacity: 1;
        }
      }

      &__selector {
        display: none;
      }
    }
  }
}

@include breakpoint(pc) {
  .anchor-section {
    .btn-arrow {
      &__box {
        max-width: 1520px;
      }
    }
  }

  .anchor-nav {
    &__menu {
      max-width: 1520px;
    }
  }
}

@include breakpoint(tablet) {
  .anchor-section {
    .btn-arrow {
      &__box {
        max-width: 964px;
      }
    }
  }

  .anchor-nav {
    opacity: 0;


    &.#{$fixedClass} {
      opacity: 1;
      &.is-fixed-bottom {
        opacity: 1;
      }
      
      .anchor-nav__menu {
        .menu__wrap {
          padding: 0;
        }

        &.#{$hideClass} {
          padding: 0;
        }
      }
    }

    &.thema-black {
      .anchor-nav {
        &__menu {
          .menu {

            &__selector,
            &__wrap {
              // background: rgba(0, 0, 0, .8);
              background: #000;
              // backdrop-filter: blur(7px);
            }
          }

          .menu {
            &__selector {
              &::after {
                @include sp-mobile('ic-anchor-dropdown-w');
              }
            }
          }
        }
      }
    }

    &.transparent {
      .anchor-nav {
        &__menu:not(.is-active) {
          .menu {
            &__selector {
              background-color: rgba(255,255,255,0);
              color: #fff;
              @at-root .thema-dark & {
                background-color: rgba(0,0,0,0);
                color: #fff;
              }
            }

            &__wrap {
              background-color: rgba(255,255,255,0);
            }
          }
        }
      }
    }

    &__dimmed {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: 0;
      max-height: 0;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(0,0,0,0.4);
    }

    &__bar {
      height: 3px;
      z-index: $z-index-minimum + 1;
    }

    &__menu {
      top: 0;
      max-width: none;

      .menu {
        &__wrap {
          position: static;
          overflow: hidden;
          z-index: $z-index-minimum;
          width: 100%;
          max-height: 0;
          padding: 0;
          // background-color: rgba(255, 255, 255, .8);
          background-color: var(--background01);
          transition: max-height cubic-bezier(.33, 0, 0, 1) .6s;
        }

        &__list {
          padding: 5px 30px 8px;
        }

        &__link {
          padding: 6px 0 5px;
          font-weight: 800;
          font-size: 12px;
          line-height: 2.17;
          opacity: 0.3;
        }

        &__selector {
          display: block;
          position: relative;
          z-index: $z-index-minimum;
          height: 53px;
          padding: 14px 30px 13px;
          opacity: 1;
          background-color: var(--background01Transparent);
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          transition: max-height cubic-bezier(0.33, 0, 0, 1) .6s, background .6s;

          &::after {
            display: inline-block;
            margin: -3px 0 0 5px;
            @include sp-mobile('ic-anchor-dropdown');
            vertical-align: middle;
            content: '';

            @at-root .theme-dark .anchor-nav__menu .menu__selector::after {
              @include sp-mobile('ic-anchor-dropdown-w');
            }

            @at-root .anchor-nav.transparent .anchor-nav__menu:not(.is-active) .menu__selector::after {
              @include sp-mobile('ic-anchor-dropdown-w');
            }
          }
        }
      }

      &.#{$hideClass} {
        overflow: hidden;
        height: 0;
        opacity: 0;

        .menu {
          &__link {
            visibility: hidden;
          }
        }
      }

      &.#{$activeClass} {
        .anchor-nav {
          &__dimmed {
            display: block;
            height: 100vh;
            max-height: 100vh;
            //opacity: .4;
          }
        }

        .menu {
          &__selector {
            &::after {
              transform: rotate(180deg);
            }
            background-color: var(--background01);
            -webkit-backdrop-filter: none;
            backdrop-filter: none;
          }

          &__wrap {
            max-height: calc(100vh - 53px);
            transition: max-height cubic-bezier(.9, 0, .1, 1) .5s;
          }

          &__list {
            animation-name: slideInContent;
            animation-fill-mode: forwards;
            animation-duration: 600ms;
            animation-timing-function: cubic-bezier(.9, 0, .1, 1);
            // transform: translateY(0);
          }
        }
      }

      &.end-animation {
        &::after {
          height: 100vh;
          max-height: 100vh;
        }
      }
    }

    &.theme-white {
      .anchor-nav {
        &__menu {
          .menu {
            &__selector,
            &__wrap {
              background: $color-white;
            }
          }
  
          .menu {
            &__selector {
              &::after {
                @include sp-mobile('ic-anchor-dropdown');
              }
            }
          }
        }
      }
    }

    &.theme-dark {
      .anchor-nav {
        &__menu {
          .menu {
            &__selector,
            &__wrap {
              background: #000;
            }
          }
  
          .menu {
            &__selector {
              &::after {
                @include sp-mobile('ic-anchor-dropdown-w');
              }
            }
          }
        }
      }
    }
  }


}

@include breakpoint(tablet-small) {
  .anchor-section {
    .btn-arrow {
      &__box {
        max-width: 708px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .anchor-section {
    .btn-arrow {
      &__box {
        max-width: 335px;
      }
    }
  }

  .anchor-nav {
    &__menu {
      .menu {
        &__list {
          padding: 5px 20px 8px;
        }

        &__selector {
          padding: 11px 20px 13px;
        }
      }
    }
  }
}

@keyframes slideInContent {
  0% {
    transform: translateY(30%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes arrowAnimation {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}
