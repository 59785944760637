.#{$component-name} {
  .common-list-layout {
    .section {
      position: relative;

      &-schedule {
        padding-top: 100px;
      }
    }

    .btn-back {
      position: absolute;
      top: 114px;
      left: 0;
      width: 30px;
      height: 30px;
      background: url(../img/common/ic-back.png) no-repeat center;
      background-size: contain;
      @at-root .theme-dark & {
        position: absolute;
        top: 114px;
        left: 0;
        width: 30px;
        height: 30px;
        background: url(../img/common/ic-back-white.png) no-repeat center;
        background-size: contain;
      }
    }
  }

  .ir-schedule {
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 30px;
    }

    &__sub-title {
      padding-top: 20px;
      font-size: 22px;
      font-weight: 800;
      line-height: 42px;
      letter-spacing: -.6px;
      color: var(--default);
    }

    &__desc {
      padding-top: 16px;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: -0.3px;
      color: var(--box-text);

      .outlink {
        position: relative;
        font-weight: 800;
        color: $color-gray3;
        &:after {
          position: absolute;
          display: block;
          content: "";
          left: 0;
          bottom: 3px;
          width: 100%;
          height: 1px;
          background-color: $color-gray3;
        }
      }
    }

    &__inner {
      &:nth-child(n+2) {
        margin-top: 160px;
      }
    }

    &__box {
      border-top: 1px solid var(--border06);

      &:first-child {
        border-top: none;
      }

      .col {
        width: 462px;

        &:first-child {
          margin-left: 0;
        }

        &-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 1039px;
          width: 100%;
          padding: 60px 0 100px;
        }
      }

      .row {
        margin-top: 50px;

        &:first-child {
          margin-top: 0;
        }

        &-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: 700;
          line-height: 26px;
          color: var(--default);

          &.choice {
            color: var(--color-sub);
          }
        }

        .desc {
          display: block;
          color: var(--secDesc);
          font-size: 12px;
          line-height: 22px;
          letter-spacing: -.3px;

          &.is-active {
            display: inline-block;
            color: $color-point;
          }

          &.en {
            letter-spacing: 0;
          }
        }

        .choice-box {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-top: 18px;

          .ir-common__button {
            margin-top: 12px;
            margin-left: 0;

            &:nth-child(1) {
              grid-column: 1 / 1;
              grid-row: 1;
            }

            &:nth-child(2) {
              grid-column: 1 / 1;
              grid-row: 2;
            }

            &:nth-child(3) {
              grid-column: 1 / 1;
              grid-row: 3;
            }

            &:nth-child(4) {
              grid-column: 2 / 2;
              grid-row: 1;
            }

            &:nth-child(5) {
              grid-column: 2 / 2;
              grid-row: 2;
            }

            &:nth-child(6) {
              grid-column: 2 / 2;
              grid-row: 3;
            }
          }

          // &__col {
          //   width: 50%;
          //   @at-root .#{$component-name}.lang-en {
          //     .ir-schedule__box .row .choice-box .ir-common__button {
          //       font-size: 13px;
          //       letter-spacing: -0.5px;
          //     }
          //   }
          // }
        }
      }
    }

    &__procedure {
      padding-top: 50px;
    }

    &__contact {
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      font-size: 0;

      &-item {
        display: flex;
        flex-direction: column;
        margin-left: 154px;
        font-size: 20px;
        font-weight: 800;
        line-height: 42px;

        &:first-child {
          margin-left: 0;
        }

        .tit {
          color: var(--accordionNum);
        }

        .txt {
          color: var(--default);
        }
      }
    }

    .inquiry-desc {
      margin-top: 60px;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: -.5px;
      color: var(--color-sub);

      em {
        padding-bottom: 4px;
        border-bottom: 1px solid var(--color-sub);
        font-weight: 800;
      }
    }
  }

  .ir-table {
    .ir-common{
      &__button {
        &.download {
          margin-left: 16px;
          
          @at-root .#{$component-name}.lang-en {
            .ir-table .ir-common__button {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }

  .ir-table-2col {
    width: 100%;
    table-layout: fixed;
    margin-top: 40px;
    font-size: 14px;
    line-height: 26px;
    text-align: left;
    letter-spacing: -.3px;

    tr {
      border-top: 1px solid var(--border01);

      &:last-child {
        border-bottom: 1px solid var(--border01);
      }
    }

    th, td {
      padding: 20px 25px;
      vertical-align: top;
    }

    td {
      color: var(--color-sub);
    }

    th {
      width: 188px;
      font-weight: 800;
      color: var(--default);

      &:first-child {
        padding-left: 0;
      }

      &:nth-child(n+2) {
        border-left: 1px solid var(--border01);
      }
    }
  }

  .ir-common__input.type-text+.desc {
    margin-top: 6px;
  }

  .pagination {
    margin-top: 80px;

    .page-num-wrap{
      min-width: 230px;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    .ir-schedule {
      &__desc {
        padding-top: 20px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .common-list-layout {
      .section-schedule {
        padding-top: 80px;
      }

      .btn-back {
        display: none;
      }
    }

    .ir-schedule {
      &__head {
        padding-bottom: 40px;
      }

      &__inner {
        &:nth-child(n+2) {
          margin-top: 140px;
        }
      }

      &__box {
        .col {
          width: 47.926%;

          &-wrap {
            max-width: none;
            padding: 50px 0 80px;
          }
        }

        .row {
          .choice-box {
            width: 85%;
          }
        }
      }

      &__procedure {
        padding-top: 20px;
      }

      &__desc {
        padding-top: 20px;
      }
    }

    .ir-table-2col {
      th {
        width: 168px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    .ir-schedule {
      &__sub-title {
        padding-top: 10px;
      }

      &__inner {
        &:nth-child(n+2) {
          margin-top: 120px;
        }
      }

      &__box {
        .col {
          width: 47.318%;

          &-wrap {
            padding: 40px 0 60px;
          }
        }

        .row {
          margin-top: 40px;

          &-title {
            font-size: 12px;
            line-height: 22px;
          }

          .choice-box {
            display: flex;
            flex-direction: column;

            // &__col {
            //   width: 100%;
            // }

            .ir-common__button {
              display: flex;
              margin-top: 10px;
            }
          }
        }
      }

      &__procedure {
        padding-top: 0;
      }

      &__contact {
        &-item {
          margin-left: 100px;
          font-size: 16px;
          line-height: 34px;
        }
      }

      &__desc {
        padding-top: 16px;
      }

      .inquiry-desc {
        margin-top: 50px;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -.3px;
      }
    }

    .ir-table-2col {
      th {
        width: 138px;
        padding: 20px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    .common-list-layout {
      .section-schedule {
        padding-top: 60px;
      }
    }

    .ir-schedule {
      &__head {
        flex-direction: column;

        .section__title {
          align-self: flex-start;
        }

        .dropdown__select {
          width: 100%;
          margin-top: 40px;
        }
      }

      &__inner {
        &:nth-child(n+2) {
          margin-top: 100px;
        }
      }

      &__box {
        .col {
          width: 100%;

          &:nth-child(n+2) {
            .row:first-child {
              margin-top: 30px;
            }
          }

          &-wrap {
            flex-direction: column;
            padding: 40px 0 80px;
          }
        }

        .row {
          margin-top: 30px;
        }
      }

      &__contact {
        flex-direction: column;

        &-item {
          margin-top: 20px;
          margin-left: 0;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      &__desc {
        padding-top: 16px;

        @at-root .#{$component-name}.lang-en {
          .ir-schedule__desc br {
            display: none;
          }
        }
      }

      .btn-wrap {
        .ir-common__button.apply {
          height: 54px;
          font-size: 16px;
        }
      }
    }

    .ir-table-2col {
      tr {
        display: flex;
        flex-wrap: wrap;
        border-top: none;
      }

      th, td {
        padding: 16px;
        border-top: 1px solid $color-gray4;
      }

      th {
        width: 41%;
        padding-left: 0;

        &:nth-child(n+2) {
          border-left: none;
        }
      }

      td {
        width: 59%;
      }
    }
  }
}
