.#{$component-name} {
  &.media-brand-image {
    .tab__area {
      margin-bottom: 100px;
    }
  }

  .section-brand-image {
    .input-search__box {
      width: 365px;
      margin-left: auto;
    }

    .ir-common__input.type-text {
      height: 52px;
      padding-left: 20px;
      font-size: 14px;
      font-weight: 700;
    }

    .contents {
      margin-top: 20px;

      &-item {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border-top: 1px solid #e6e6e6;

        @at-root .theme-dark & {
          border-top: 1px solid #2f2e30;
        }

        &:hover {
          .contents-item__title {
            color: $color-point;
          }
        }

        &__img {
          width: 240px;
          height: 160px;
          background: no-repeat center;
          background-size: cover;
        }

        &__txt {
          display: flex;
          flex: 1;
        }

        &__link {
          flex: 1;
          padding-left: 48px;
        }

        &__title {
          display: inline-block;
          font-size: 24px;
          font-weight: 800;
          line-height: 38px;
          transition: all .3s;
        }

        &__download {
          display: flex;
          align-self: flex-start;
          align-items: center;
          font-size: 14px;
          font-weight: 700;
          line-height: 30px;
          transition: all .3s;

          &:hover {
            color: $color-point;

            &::before {
              background-color: #ff5f00;
            }
          }

          &::before {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 6px;
            background: var(--ic-download-w) no-repeat center;
            background-size: contain;
            background-color: #1d1d1f;
            border-radius: 10px;
            content: '';
          }

          @at-root .theme-dark & {
            &::before {
              background: var(--ic-download) no-repeat center;
              background-size: contain;
              background-color: #424245;
            }
            
            &:hover {
              &::before {
                background: var(--ic-download-w) no-repeat center;
                background-size: contain;
                background-color: #ff5f00;
              }
            }
          }

          .num {
            display: inline-block;
            margin-left: 6px;
          }
        }
      }

      &-nodata {
        .search-result__text {
          display: block;
          text-align: center;
        }
      }
    }

    .pagination {
      margin-top: 120px;
    }

    .search-wrap {
      .btn-delete {
        display: none;
        position: absolute;
        top: 50%;
        right: 42px;
        margin-right: 20px;
        transform: translateY(-50%);
        @include sp-desktop('ic-search-delete-sm');
      }
  
      &.#{$activeClass} {
        .btn-delete {
          display: block;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {

  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    .section-brand-image {
      .contents {
        &-item {
          &__img {
            width: 226px;
            height: 151px;
          }

          &__title {
            font-size: 22px;
            line-height: 34px;
            letter-spacing: -.6px;
          }
        }
      }

      .search-wrap {
        .btn-delete {
          margin-right: 10px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.media-brand-image {
      .tab__area {
        margin-bottom: 75px;
      }
    }

    .section-brand-image {
      .input-search__box {
        width: 349px;
      }

      .contents {
        &-item {
          padding: 16px 0;

          &__img {
            width: 168px;
            height: 112px;
          }

          &__link {
            padding-left: 32px;
          }

          &__title {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -.5px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.media-brand-image {
      .tab__area {
        margin-bottom: 60px;
      }
    }

    .section-brand-image {
      .input-search__box {
        width: 100%;
      }

      .contents {
        &-item {
          padding: 16px 0 32px;

          &__img {
            width: 120px;
            height: 80px;
          }

          &__txt {
            flex-direction: column;
            padding-left: 20px;
          }

          &__link {
            padding-left: 0;
          }

          &__title {
            font-size: 16px;
            line-height: 28px;
          }

          &__download {
            margin-top: 22px;
          }
        }
      }

      .pagination {
        margin-top: 68px;
      }
    }
  }
}
