.#{$component-name} {
  &.service-search {
    .section {
      &__visual {
        background-image: url(../img/pc/service-search-main.jpg);
      }

      &__outlink {
        display: inline-flex;
        align-items: center;
        position: relative;
        height: 54px;
        padding: 0 62px 0 20px;
        margin-top: 160px;
        font-weight: 700;
        font-size: 16px;
        color: white;
        letter-spacing: -0.4px;
        transition: background 0.3s;
        background-color: var(--outlinkBg03);

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 15px;
          width: 24px;
          height: 24px;
          background: url(/img/common/ic-outlink-white2.png) no-repeat center;
          background-size: contain;
          transform: translateY(-50%);
        }

        &:hover {
          background: $color-point;
        }
      }
    }

    .service {
      &__subtitle {
        display: inline-block;
        width: 410px;
        font-weight: 800;
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -1px;
        text-align: left;
        vertical-align: top;
        color: var(--default);
      }

      &__desc {
        position: relative;
        padding-left: 10px;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.89;
        letter-spacing: -0.5px;
        color: var(--color-sub);

        &::before {
          position: absolute;
          left: -5px;
          font-weight: 400;
          font-size: 18px;
          line-height: 1.89;
          content: '・';
        }

        &-wrap {
          margin: 24px 0 100px;
        }
      }

      &__detail {
        max-width: 1342px;
        margin: 120px auto 0;
        padding: 0 112px;
        font-size: 0;
        text-align: left;

        &-right {
          display: inline-block;
          width: 549px;
          margin-left: 20px;
        }

        &-list {
          margin-top: 10px;
        }

        &-item {
          display: flex;
          align-items: center;
          padding: 30px 0;
          border-top: 1px solid var(--border01);
          text-align: left;

          &:last-of-type {
            border-bottom: 1px solid var(--border01);
          }

          .title {
            width: 342px;
            font-weight: 800;
            font-size: 26px;
            line-height: 1.46;
            letter-spacing: -0.8px;
            color: var(--default);
          }

          .desc {
            width: 100%;
            font-weight: 700;
            font-size: 22px;
            line-height: 1.91;
            letter-spacing: -0.6px;
            color: var(--default);
          }
        }
      }
    }

    .js-nav-section {
      &.naver-search {
        .service {
          &__detail {
            margin-top: 202px;
          }
        }
      }

      &.naver-search-tech {
        .service {
          &__img {
            &-wrap {
              margin-top: 120px;
              padding-bottom: 40px;
            }
          }

          &__subtitle {
            width: 310px;
            flex-shrink: 0;
          }

          &__detail {
            display: flex;
            justify-content: space-between;

            &-item {
              &:first-child {
                border-top: 0;
              }

              .title {
                width: 50%;
                font-weight: 700;
                font-size: 22px;
                letter-spacing: -0.6px;
              }

              .desc {
                width: 50%;
                font-weight: 700;
                font-size: 18px;
                line-height: 1.86;
                letter-spacing: -0.3px;
                color: var(--color-sub);
              }
            }

            &-img {
              background-color: var(--privacyBg);
            }
          }
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.service-search {
      .section {
        &__outlink {
          margin-top: 100px;
        }
      }

      .service {
        &__detail {
          max-width: 1170px;
          margin-top: 100px;

          &-right {
            width: 462px;
          }
        }

        &__text {
          margin-top: 115px;
        }
      }

      .js-nav-section {
        &.naver-search {
          .service {
            &__detail {
              margin-top: 155px;
            }
          }
        }

        &.naver-search-tech {
          .service {
            &__img {
              &-wrap {
                margin-top: 100px;
                padding-bottom: 19px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.service-search {
      .section {
        &__outlink {
          margin-top: 80px;
        }
      }

      .service {
        &__subtitle {
          font-size: 26px;
          line-height: 1.46;
          letter-spacing: -0.8px;
        }

        &__desc {
          padding-left: 8px;
          font-size: 16px;
          line-height: 1.75;
          letter-spacing: -0.4px;

          &::before {
            font-size: 16px;
            line-height: 1.75;
          }

          &-wrap {
            margin: 30px 0 80px;
          }
        }

        &__detail {
          margin-top: 90px;

          &-right {
            width: 390px;
          }

          &-item {
            .title {
              width: 164px;
              font-size: 22px;
              line-height: 1.55;
              letter-spacing: -0.5px;
            }

            .desc {
              font-size: 18px;
              line-height: 1.89;
              letter-spacing: -0.5px;
            }
          }
        }

        &__text {
          max-width: 840px;
          margin: 80px auto;
        }

        &__img {
          &-wrap {
            &.is-tab {
              background: url(../../../img/pc/service-search-img-2.png) center no-repeat;
              background-size: cover;
            }
          }
        }
      }

      .js-nav-section {
        &.naver-search {
          .service {
            &__detail {
              margin-top: 120px;
            }
          }
        }

        &.naver-search-tech {
          .service {
            &__subtitle {
              width: 256px;
            }

            &__img {
              &-wrap {
                display: flex;
                justify-content: center;
                margin-top: 90px;
                padding-bottom: 10px;
              }
            }

            &__detail {
              &-list {
                margin-top: 14px;
              }

              &-item {
                padding: 26px 0;

                .title {
                  width: 164px;
                  font-size: 18px;
                  letter-spacing: -0.5px;
                }

                .desc {
                  width: auto;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.service-search {
      .section {
        &__outlink {
          height: 50px;
          padding-right: 52px;
          font-size: 14px;
          letter-spacing: 0;

          &::before{
            width: 20px;
            height: 20px;
            background-size: 20px auto;
          }
        }
      }



      .service {
        &__desc {
          &-wrap {
            margin: 30px 0 60px;
          }

          +.service__desc {
            margin-top: 8px;
          }
        }

        &__detail {
          margin-top: 80px;
          padding: 0 30px;

          &-list {
            margin-top: 0;
          }

          &-item {
            padding: 16px 0;

            .title {
              width: 120px;
              font-size: 16px;
              // line-height: 2.13;
            }

            .desc {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.3px;
            }
          }
        }

        &__text {
          margin-top: 60px;
          max-width: 680px;
        }
      }

      .js-nav-section {
        &.naver-search {
          .service {
            &__detail {
              margin-top: 80px;
            }
          }
        }

        &.naver-search-tech {
          .service {
            &__img {
              &-wrap {
                display: block;
                height: auto;
                margin-top: 60px;
                padding-bottom: 0;

                img {
                  width: 100%;
                  height: auto;
                }
              }
            }

            &__detail {
              &-list {
                margin-top: 10px;
              }

              &-item {
                max-width: 740px;

                .title {
                  flex-shrink: 0;
                  width: 140px;
                  font-size: 16px;
                }
              }

              &-right {
                width: 348px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.service-search {
      .section {
        &__visual {
          background-image: url(../img/mo/service-search-main.png);
        }
        &__outlink {
          margin-top: 60px;
          width: auto;
          height: 48px;
          white-space: nowrap;
          font-size: 14px;
        }
      }

      .service {
        &__detail {
          padding: 0 20px;

          &-list {
            margin-top: 10px;
          }

          &-item {
            padding: 20px 0;

            .title {
              width: 66px;
            }
          }

        }

        &__desc {
          &-wrap {
            margin: 20px 0 50px;
          }
        }

        &__text {
          margin-bottom: 0;
          max-width: 335px;
        }
      }

      .js-nav-section {
        &.naver-search {
          .service {
            &__detail {
              margin-top: 60px;
            }
          }
        }

        &.cue {
          .service__img {
            left: 50%;
            right: auto;
            border-width: 3px;
            max-width: none;
            height: 0;
            padding-top: 61%;
            border-radius: 10px;
            border-right: 3px solid #000;
            border-radius: 2.7vw;
            transform: translate(-50%, -50%);

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: auto;
            }
          }
        }

        &.naver-search-tech {
          .service {
            &__detail {
              flex-direction: column;

              &-right {
                width: auto;
                margin-top: 24px;
              }

              &-item {
                padding: 20px 0;

                .title {
                  width: 128px;
                }
              }
            }
          }
        }
      }
    }
  }
}
