.#{$component-name} {
  &.ir-disclosure {
    .container {
      padding-top: 0;
    }

    .common {
      &__title {
        &-inner {
          padding-top: 150px;
        }
      }
    }

    .ir-common {
      &__button {
        &:last-child {
          margin-left: 50px;
        }
      }
    }

    .section__title {
      padding-top: 100px;
    }

    .pagination {
      margin-top: 80px;

      .page-num-wrap{
        min-width: 230px;
      }
    }

    .table {
      margin-top: 20px;

      &__thead {
        &-th {
          flex: none;

          &:first-child {
            width: 18.5%;
          }

          &:nth-child(2) {
            width: 68%;
          }

          &:last-child {
            width: 13.5%;
          }
        }
      }

      &__tbody {
        div {
          flex: none;

          &:first-child {
            width: 18.5%;
          }

          &:nth-child(2) {
            width: 68%;
          }

          &:last-child {
            width: 13.5%;
          }
        }
      }
    }

    .ir-disclosure {
      &__section {
        max-width: 1800px;
        margin: 0 auto;
        padding-left: 460px;
      }

      &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
      }

      &__link {
        float: right;
        margin-top: 30px;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: -0.26px;
        color: var(--secDesc);

        &:after {
          display: block;
          content: '';
          clear: both;
        }

        &:hover {
          & {
            span {
              color: $color-point;
              border-bottom-color: $color-point;
            }
          }
        }

        span {
          margin-left: 3px;
          padding-bottom: 1px;
          border-bottom: 1px solid var(--secDesc);
          font-weight: 800;
          line-height: 1.5;
          transition: all .3s;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ir-disclosure {
      .table {
        &__thead {
          &-th {
            &:first-child {
              width: 20.5%;
            }

            &:last-child {
              width: 11.5%;
            }
          }
        }

        &__tbody {
          div {
            &:first-child {
              width: 20.5%;
            }

            &:last-child {
              width: 11.5%;
            }
          }
        }
      }

      .ir-disclosure {
        &__section {
          max-width: 1520px;
          padding-left: 386px;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir-disclosure {
      .common {
        &__title {
          &-inner {
            padding-top: 140px;
          }
        }
      }

      .section__title {
        padding-top: 80px;
      }

      .table {
        &__thead {
          &-th {
            &:first-child {
              width: 24.5%;
            }

            &:nth-child(2) {
              width: 61.5%;
            }

            &:last-child {
              width: 14%;
            }
          }
        }

        &__tbody {
          div {
            &:first-child {
              width: 24.5%;
            }

            &:nth-child(2) {
              width: 61.5%;
            }

            &:last-child {
              width: 14%;
            }
          }
        }
      }

      .ir-disclosure {
        &__section {
          max-width: none;
          padding: 0 30px;
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ir-disclosure {

      .table {
        &__thead {
          &-th {
            padding: 14px 0;

            &:first-child {
              width: 24%;
            }

            &:nth-child(2) {
              width: 63%;
            }

            &:last-child {
              width: 13%;
            }
          }
        }

        &__tbody {
          div {
            padding: 14px 0;

            &:first-child {
              width: 24%;
            }

            &:nth-child(2) {
              width: 63%;
            }

            &:last-child {
              width: 13%;
            }
          }
        }
      }

      .ir-disclosure {
        &__head {
          margin-top: 30px;
        }

        &__link {
          margin-top: 12px;
        }
      }
    }
  }
}


@include breakpoint(mobile) {
  .#{$component-name} {
    &.ir-disclosure {
      .common {
        &__title {
          &-inner {
            padding-top: 112px;
          }
        }
      }

      .ir-common {
        &__button {
          &:last-child {
            margin-left: 34px;
          }
        }
      }

      .section__title {
        padding-top: 60px;
      }

      .pagination {
        margin-top: 80px;
      }

      .dropdown__select {
        width: 100%;
        margin-top: 35px;
      }

      .table {
        margin-top: 30px;

        &__thead {
          display: none;
        }

        &__tbody {
          div {
            padding: 0;
            font-size: 16px;
            line-height: 1.63;
            color: $color-default;

            &:first-child {
              order: 1;
              width: 100%;
              padding-top: 10px;
              font-size: 12px;
              line-height: 1.83;
              @at-root .theme-dark & {
                color: var(--secDesc);
              }
            }

            &:nth-child(2) {
              order: 0;
              width: 100%;
              font-weight: 700;
              line-height: 1.83;

              @at-root .theme-dark & {
                color: #fff;
              }
            }

            &:last-child {
              display: flex;
              order: 2;
              width: 100%;
              padding-top: 20px;
              @at-root .theme-dark & {
                color: var(--default);
              }
            }
          }

          .table-blind {
            display: block;
            overflow: auto;
            position: static;
            width: auto;
            height: auto;
            margin-right: 6px;
            font-size: 14px;
            text-indent: 0;
            color: #a1a1a6;
            letter-spacing: -0.3px;
            line-height: 1.86;
            @at-root .theme-dark & {
              color: var(--secDesc);
            }
          }
        }

        &__tr {
          flex-direction: column;
          align-items: start;
          padding: 10px 0 30px;
        }
      }

      .ir-disclosure {
        &__section {
          padding: 0 20px;
        }

        &__head {
          flex-direction: column;
          align-items: start;
          margin-top: 40px;
        }

        &__link {
          float: none;
          text-align: left;
        }
      }
    }
  }
}
