.#{$component-name} {
  &.media-detail {

    .container {
      padding: 0;
      padding-bottom: 80px;
    }

    .common {
      &__title {
        background-color: var(--black);
        color: var(--default);
        border-bottom: none;

        &-inner {
          padding: 144px 0 51px;
        }

        h2 {
          margin-top: 17px;
          font-size: 48px;
          line-height: 1.5;
          letter-spacing: -1.5px;
          color: $color-white;
        }

        span {
          display: block;
          font-size: 14px;
          line-height: 2;
          color: $color-white;

          &.media-detail__date {
            margin-top: 81px;
          }

          &.media-detail__time {
            color: #86868b;
          }
        }
      }
    }

    .ir-common {
      &__button {
        &.is-show-mo {
          display: none;
        }
      }
    }

    .#{$ebClass} {
      font-weight: 800;
    }

    .#{$bdClass} {
      font-weight: 700;
    }

    .media-detail {
      font-size: 16px;
      line-height: 1.88;
      letter-spacing: -0.5px;

      &__date {
        margin-top: 81px;

        span.date, span.min, span.comma, span.text {
          display: inline-block;
        }
      }

      &__btn-box {
        position: absolute;
        top: 150px;
        left: 50%;
        margin-left: -900px;

        a {
          display: flex;
          margin: 0;

          &:hover {
            border-color: #e4e6ea;
          }

          &:first-child {
            &:hover {
              z-index: 1;
            }
          }

          &:last-child {
            margin-top: -1px;
          }
        }
      }

      &__section {
        margin-top: 100px;

        &:first-child {
          margin-top: 0;
        }
      }

      &__title {
        display: block;
        font-size: 22px;
        line-height: 1.64;
        letter-spacing: -0.6px;
        color: var(--default);

        &-item {
          display: flex;
          align-items: start;
          margin-top: 14px;

          &:first-child {
            margin-top: 0;
          }
        }

        &-dot {
          margin-right: 13px;
        }
      }

      &__text {
        font-size: 16px;
        line-height: 1.88;
        letter-spacing: -0.5px;
        color: var(--detailColor);

        &-box {
          &:first-child {
            margin-top: 80px;
          }

          display: block;
          margin-top: 30px;
        }
      }

      &__info {
        &-img {
          margin: 40px auto 0;
          width: 890px;
        }

        &-box {
          display: flex;
          justify-content: space-between;
          width: 890px;
          margin: 16px auto 0;

        }

        &-txt {
          font-size: 10px;
          line-height: 1.6;
          color: #6e6e73;
        }

        &-btn {
          width: 24px;
          height: 24px;
          background: var(--buttonType3Background) var(--ic-download) no-repeat center;
          background-size: contain;
          border-radius: 50%;
          transition: background .3s;

          &:hover {
            background: #ff5f00 url(../img/common/ic-download-white.png) no-repeat center;
            background-size: contain;
          }
        }
      }

      &__bottom-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 80px;

        a {
          &:first-child {
            &:hover {
              z-index: 1;
            }
          }

          &:nth-child(2) {
            margin-left: -1px;
          }

          &:last-child {
            margin-left: auto;

            &:hover {
              border-color: #e4e6ea;
              background-color: #f6f7fb;
              color: $color-default;

              &:after {
                background-image: url(/img/common/ic-list.png);
              }
            }
          }
        }

        .ir-common__button {
          &.type3 {
            width: 250px;

            &:hover {
              border-color: #e4e6ea;
            }
          }
        }
      }
    }

    .media-press-detail {
      max-width: 890px;
      margin: 93px auto 0;
      color: var(--color-sub);
    }

    .media-list {
      margin-top: 120px;
    }

    .section-conference {
      max-width: 890px;
      margin: 0 auto;
    }

    .section-more-list {
      max-width: 1920px;
      margin: 0 auto;
      margin-top: 110px;
      padding: 0 60px;
    }
  }
}

.theme-dark {
  .navercorp.media {
    .common__title {
      border-bottom-color: transparent;
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.media-detail {

      .common {
        &__title {
          &-inner {
            max-width: 975px;
            padding: 143px 0 40px;
          }
        }
      }
      .media-detail {
        &__btn {
          &-box {
            left: 40px;
            margin-left: 0;
          }
        }

        &__info {
          &-img {
            width: 750px;
          }

          &-box {
            width: 750px;
          }
        }
      }

      .media-press-detail {
        max-width: 750px;
      }

      .section-conference {
        max-width: 750px;
      }

      .section-more-list {
        padding: 0 40px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.media-detail {
      .common {
        &__title {
          &-inner {
            max-width: 718px;
            padding: 143px 0 40px;
          }

          h2 {
            margin-top: 19px;
            font-size: 42px;
            line-height: 1.52;
            letter-spacing: -1.8px;
          }

          span {
            font-size: 12px;
            line-height: 2.17;

            &.media-detail__date {
              margin-top: 85px;
            }
          }
        }
      }

      .scroll-title {
        .pagination {
          padding: 50px 0;
        }
      }

      .media-detail {
        &__date {
          margin-top: 85px;
        }

        &__btn-box {
          display: none;
        }

        &__info {
          &-img {
            width: 718px;
          }

          &-txt {
            width: 718px;
          }
        }

        &__section {
          margin-top: 70px;
        }
      }

      .media-press-detail {
        max-width: 718px;
      }

      .section-conference {
        max-width: 718px;
      }

      .media-list {
        margin-top: 100px;
      }

      .section-more-list {
        margin-top: 94px;
        padding: 0 30px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.media-detail {
      .common {
        &__title {
          &-inner {
            max-width: none;
            padding: 127px 30px 37px;
          }

          h2 {
            margin-top: 17px;
            font-size: 38px;
            line-height: 1.53;
            letter-spacing: -1.5px;
          }

          span {
            display: block;
            font-size: 14px;
            line-height: 2;
            color: $color-white;

            &.media-detail__date {
              margin-top: 72px;
            }
          }
        }
      }

      .media-detail {
        &__date {
          margin-top: 72px;
        }

        &__bottom-btn {
          margin-top: 100px;
        }

        &__info {
          &-img {
            width: 100%;
          }

          &-txt {
            width: 100%;
          }
        }

        &__section {
          margin-top: 50px;
        }

        &__title {
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;

          &-dot {
            margin-right: 7px;
          }

          &-item {
            margin-top: 10px;
          }
        }

        &__text {
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;

          &-box {
            &:first-child {
              margin-top: 50px;
            }
          }
        }

        &__bottom-btn {
          .ir-common__button {
            &.type3 {
              width: 206px;
            }
          }
        }
      }

      .media-press-detail {
        max-width: none;
        margin-top: 50px;
        padding: 0 30px;
      }

      .section-conference {
        max-width: none;
        padding: 0 30px;
      }

      .section-more-list {
        margin-top: 34px;
      }

      .media-list {
        margin-top: 40px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {

    &.media-detail {
        
      .header{
        &.#{$scrollUpClass} {
          height: 50px;
        }
      }

      .container {
        padding-bottom: 100px;
      }

      .common {
        &__title {
          &-inner {
            padding: 138px 20px 47px;
          }

          @at-root .navercorp.lang-en {
            .common__title-inner h2 {
              max-width: 335px;
              font-size: 30px;
              letter-spacing: 0;
              line-height: 48px;
            }
          }
        }
      }

      .ir-common {
        &__button {
          &.is-show-mo {
            display: flex;
            float: right;
            margin: 30px 20px 100px 0px;
          }
        }
      }

      .scroll-title {
        &__box {
          padding: 7px 20px;
        }

        &__search {
          .media-detail {
            &__img {
              top: 27px;
            }

            &__item {
              .category {
                display: block;
              }

              &:nth-child(5) {
                display: none;
              }
            }
          }
        }

        &__btn {
          .btn-share {
            margin-left: 12px;
          }
        }

        .pagination {
          padding: 30px 0 50px;
        }

        &__list {
          &-txt {
            padding: 15px 20px 30px;
          }
        }
      }

      .media-detail {
        &__bottom-btn {
          flex-direction: column;
          margin-top: 100px;

          .ir-common__button {
            margin: 0;

            &.is-show-pc {
              display: none;
            }

            &:nth-child(2) {
              margin-top: -1px;
              margin-left: 0;
            }

            &.type3 {
              width: 100%;
            }
          }
        }

        &__section {
          margin-top: 50px;
        }

        &__title {
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;

          &-item {
            margin-top: 10px;
          }
        }

        &__text {
          font-size: 14px;
          line-height: 1.86;
          letter-spacing: -0.3px;

          &-box {
            margin-top: 25px;

            &:first-child {
              margin-top: 50px;
            }
          }
        }

        &__item {
          .category {
            display: none;
          }
        }
      }

      .media-press-detail {
        padding: 0 20px;
      }

      .section-conference {
        padding: 0 20px;
      }

      .section-more-list {
        padding: 0 20px;
      }
    }
  }
}
