.#{$component-name} {
  &.ir-finance {
    .empty {
      &-title {
        font-size: 19px;
        line-height: 1.8;
      }

      &-caption {
        margin-top: 0;
        text-align: left;
        color: #878890;
      }

      &-text {
        margin-top: 30px;
      }
    }

    .container {
      &.is-year {
        .table__wrap {
          &.year {
            display: block;
          }

          &.quarter {
            display: none;
          }
        }
      }

      &.is-quarter {
        .table__wrap {
          &.year {
            display: none;
          }

          &.quarter {
            display: block;
          }
        }
      }
    }

    .common {
      &__title-inner h2 {
        line-height: 1.38;
      }
    }

    .select-wrap {
      max-width: 880px;
      margin: 0 auto;
      padding: 60px 0 56px;
    }

    .finance-table {
      padding-top: 45px;
      padding-bottom: 118px;
    }

    .icome-table {
      padding-top: 45px;
      padding-bottom: 118px;
    }

    .cash-table {
      padding-top: 45px;
      padding-bottom: 0;
    }

    .table__wrap {

      .table__thead-th,
      .table__tbody div {
        flex: none;
        -webkit-box-flex: initial;

        &.is-bg {
          background-color: var(--bgColor02);
        }
      }

      .table__tbody-th {
        font-weight: 400;

        &.#{$bdClass} {
          font-weight: 700;
        }
      }
    }

    .table__wrap {

      .table__tbody-th:nth-child(1) {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.5;
      }

      &.year {

        .table__thead-th:nth-child(1),
        .table__tbody-th {
          flex-grow: 1;
        }

        .table__thead-th:nth-child(2),
        .table__tbody-td:nth-child(2) {
          width: 33.3%;
          padding-left: 30px;
        }

        .table__thead-th:nth-child(3),
        .table__tbody-td:nth-child(3) {
          width: 223px;
          padding-left: 30px;
        }
      }

      &.quarter {

        .table__thead-th:nth-child(1),
        .table__tbody-th {
          flex-grow: 1;
        }

        .table__thead-th:nth-child(n + 2),
        .table__tbody-td {
          width: 11.15%;
          padding-left: 20px;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.ir-finance {
      .select-wrap {
        max-width: 759px;
      }

      .table__wrap {
        &.year {

          .table__thead-th:nth-child(2),
          .table__tbody-td:nth-child(2) {
            padding-left: 20px;
          }

          .table__thead-th:nth-child(3),
          .table__tbody-td:nth-child(3) {
            width: 189px;
            padding-left: 20px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.ir-finance {
      .empty {
        padding: 0 20px;
      }

      .select-wrap {
        max-width: none;
        padding: 40px 30px 0;
      }

      .finance-table {
        padding: 82px 30px 3px;
      }

      .icome-table {
        padding: 120px 30px 3px;
      }

      .cash-table {
        padding: 120px 30px 0;
      }

      .table__wrap {
        .table__caption {
          margin-top: 32px;
        }

        .table__tbody-td {
          &.is-fill {
            padding: 35px 0;
          }
        }
      }

      .year .table__wrap {

        .table__thead-th:nth-child(3),
        .table__tbody-td:nth-child(3) {
          width: 160px;
        }
      }

      .quarter .table__wrap {

        .table__thead-th:nth-child(n + 2),
        .table__tbody-td {
          width: 12%;

          &.is-fill {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.ir-finance {
      .common {
        &__title-inner h2 {
          font-size: 42px;
          line-height: 1.52;
          letter-spacing: -1.8px;
        }
      }

      .table__wrap {
        .table__caption {
          margin-top: 24px;
          margin-bottom: 8px;
          font-size: 10px;
          line-height: 1.8;
          letter-spacing: normal;
        }

        .table__thead-th {
          font-size: 12px;
          letter-spacing: -0.26px;
        }

        .table__thead-th,
        .table__tbody div {
          padding: 10px 0;
        }

        .table__thead-th,
        .table__tbody-th {
          font-size: 12px;
          line-height: 1.83;
          letter-spacing: -0.26px;
        }

        .table__tbody-td {
          font-size: 10px;
          line-height: 2.2;

          &.is-fill {
            padding: 20px 0;
          }
        }
      }

      .table__wrap {
        &.year {

          .table__thead-th:nth-child(3),
          .table__tbody-td:nth-child(3) {
            width: 118px;
          }
        }

        &.quarter {

          .table__thead-th:nth-child(n + 2),
          .table__tbody-td {
            width: 11.2%;
            padding-left: 8px;

            &.is-bg {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.ir-finance {
      .container {
        &.is-quarter {
          .js-nav-section {
            position: relative;
          }
        }
      }

      .finance-table {
        padding: 42px 20px 17px;

        .table__wrap {
          &.quarter {
            .table__caption {
              top: 79px;
              right: 17px;
            }
          }
        }
      }

      .icome-table {
        padding: 85px 20px 17px;
      }

      .cash-table {
        padding: 85px 20px 0;
      }

      .common {
        &__title-inner h2 {
          font-size: 38px;
          line-height: 1.53;
          letter-spacing: -1.5px;
        }
      }

      .select-wrap {
        padding: 60px 20px 0;

        .ir-common__select.wide {
          height: 48px;
        }

        .ir-common__selected-value {
          height: 48px;
          padding: 0 16px;
          line-height: 48px;
          background-position: right 12px center;
        }
      }

      .table__wrap {
        .table__caption {
          margin-top: 14px;
        }

        .table__tbody-td.is-fill {
          padding: 21px 0;
        }
      }

      .table__wrap {
        &.year {

          .table__thead-th:nth-child(2),
          .table__tbody-td:nth-child(2) {
            padding-left: 8px;
          }

          .table__thead-th:nth-child(2),
          .table__thead-th:nth-child(3),
          .table__tr .table__tbody-td {
            width: 84px;
          }
        }

        &.quarter {
          overflow-x: auto;
          margin: 39px -20px 0;
          padding: 0 20px;

          &::-webkit-scrollbar {
            height: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cececf;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar-button {
            display: block;
            height: 4px;
            width: 10px;
          }

          .table {
            width: 708px;
          }

          .table__thead-th:nth-child(n + 2),
          .table__tbody-td {
            width: 79px;
          }

          .table__thead-th:nth-child(1),
          .table__tbody-th:nth-child(1) {
            width: 180px;
          }

          .table__wrap {
            margin-top: 40px;
            overflow-x: auto;

            .table {
              width: 708px;
              padding: 0 20px;
              border-top: none;
            }
          }

          .table__thead {
            border-top: 1px solid $color-default;
          }

          .table__caption {
            position: absolute;
            top: 122px;
            right: 17px;
          }
        }
      }
    }
  }
}
