/* category filter */
.category-filter {
  &__btn {
    display: none;
  }

  &__util {
    left: -100%;
  }

  .util {
    &__area {
      display: flex;
    }

    &__btn {
      display: none;
    }

    &__item {
      position: relative;
      flex: 1 1 0;

      &.#{$activeClass} {
        z-index: 12;
      }

      &:hover {
        z-index: 12;
      }

      &:first-child {
        .dropdown__select {
          margin-left: 0;
        }
      }
    }
  }

  .dropdown__select {
    display: block;
    width: auto;
    box-shadow: 0px 0px 0px 1px var(--border01);

    &:not(.is-disabled):hover {
      box-shadow: 0px 0px 0px 1px var(--default);
    }

    &.wide {
      display: block;
      width: auto;
    }

    .dropdown__selected-value {
      border: none;

      &:hover {
        background-image: var(--ic-dropdown);
        color: var(--default);
      }
    }

    &-option {
      top: -1px;
      left: -1px;
      right: -1px;
      max-height: 489px;
    }

    .dropdown__option-item {
      &:hover {
        .dropdown__option-link {
          color: var(--default);
          background-color: var(--bgColor02);
        }
      }
    }

    .dropdown__option-link {
      font-size: 12px;
    }
  }

  .input__box {
    position: relative;
    height: 52px;
    box-shadow: 0px 0px 0px 1px var(--inputBox);

    &:hover {
      box-shadow: 0px 0px 0px 1px $color-default;
    }

    input {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 11px 85px 11px 16px;
      line-height: 52px;
      outline: 0;
      color: var(--default);
      font-weight: 700;
    }

    &.#{$activeClass} {
      z-index: 1;
      box-shadow: 0px 0px 0px 1px $color-default;

      .btn-delete {
        display: block;
      }
    }
  }

  .btn-apply {
    display: none;
  }

  .btn-delete {
    display: none;
    position: absolute;
    top: 50%;
    right: 42px;
    margin-right: 20px;
    transform: translateY(-50%);
    @include sp-desktop('ic-search-delete-sm');
  }

  .btn-search {
    position: absolute;
    top: 50%;
    right: 18px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    background: var(--ic-search) no-repeat;
    background-size: 100%;
  }
}

html.theme-dark {
  .category-filter {
    .btn-delete {
      @include sp-desktop('ic-search-delete-sm-b');
    }
  }
}

@include breakpoint(pc) {}

@include breakpoint(tablet) {
  .category-filter {
    .btn-search {
      right: 14px;
      width: 20px;
      height: 20px;
    }

    .btn-delete {
      margin-right: 10px;
    }

    .dropdown__select {
      &.wide {
        height: 46px;

        .dropdown__selected-value {
          height: 46px;
          padding: 0 52px 0 18px;
          font-size: 12px;
          line-height: 46px;
          background-position: right 16px center;
          background-size: 18px;
        }
      }
    }

    .input__box {
      height: 46px;

      input {
        line-height: 48px;
        font-size: 12px;
      }
    }
  }
}

@include breakpoint(tablet-small) {

  /* category filter */
  .category-filter {
    .category-dimmed {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $z-index-layer + 110;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
      background: rgba(0, 0, 0, .4);
    }

    .btn-filter {
      padding: 2px 6px;
      background-color: #000;
      font-size: 12px;
      font-weight: 700;
      line-height: 26px;
      color: $color-white;

      @at-root .theme-dark & {
        background-color: var(--searchBox);
      }

      .num {
        display: inline-block;
        margin-left: 10px;
        color: $color-point;
        font-weight: 700;
        vertical-align: middle;
      }

      &::before {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background: url(/img/common/ic-filter.png) no-repeat center;
        background-size: cover;
        vertical-align: middle;
        content: '';
      }
    }

    .btn-reset {
      color: $color-point;
      font-size: 12px;
      font-weight: 800;
      line-height: 22px;
    }

    .btn-close {
      position: absolute;
      top: 12px;
      right: 23px;
      @include sp-mobile('ic-close');

      @at-root .theme-dark & {
        @include sp-mobile('ic-close-w');
      }
    }

    .btn-apply {
      display: block;
      height: 54px;
      padding: 18px;
      background-color: $color-default;
      color: $color-white;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      transition: all .3s;

      &:hover {
        background-color: $color-point;
      }
    }

    &__btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__util {
      position: fixed;
      top: 0;
      left: -100%;
      z-index: $z-index-header + 100;
      width: 395px;
      height: 100%;
      background-color: var(--reverse);
      transition: all .3s ease-out;
      transition-delay: .2s;

      @at-root .theme-dark & {
        .btn-apply {
          background: #fcfcfd;
          color: var(--reverse);
        }
      }

      &-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 80px 30px 40px;
      }
    }

    .util {
      &__area {
        display: flex;
        flex-direction: column;
      }

      &__btn {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 9px 30px;
      }

      &__item {
        margin-top: 14px;

        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 2;
        }

        &:nth-child(3) {
          order: 3;
        }

        &.search {
          order: 0;
          margin-top: 0;
        }
      }
    }

    .input__box {
      input {
        padding: 0 85px 0 18px;

        @at-root .theme-dark & {
        border-color: var(--color-sub02);
        }

        &::placeholder {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.3px;
        }
      }

      .btn-search {
        position: absolute;
        top: 50%;
        right: 14px;
        @include sp-mobile('ic-search-mo');
        transform: translateY(-50%);
      }
    }

    .dropdown__select {
      flex: auto;
      width: 100%;
      height: 46px;
      margin: 0;

      .dropdown__selected-value {
        height: 46px;
      }
    }

    &.#{$openClass} {
      .category-filter {
        &__util {
          left: 0;
          // transform: translateX(0);
        }
      }
    }
  }
}

@include breakpoint(mobile) {

  /* category filter */
  .category-filter {
    &__util {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1023px) and (max-height: 812px) {
  .category-filter {
    .dropdown__select-option {
      max-height: 46.06vh;
    }
  }
}
