.dropdown {
  &__select {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 200px;
    height: 48px;
    font-weight: 700;
    font-size: 14px;
    z-index: 11;
    cursor: default;

    &.#{$disabledClass} {
      position: relative;

      .dropdown__selected-value {
        color: #bbbbbe !important;
        cursor: default;
      }
      
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.is-open {
      z-index: 12;
      
      .dropdown__selected-value {
        background-image: url(/img/common/ic-dropdown-hover.png);
        color: $color-point;
      }

      .dropdown__select-option {
        display: block;
      }
    }

    &.is-failed {
      .dropdown__selected-value {
        border-color: #f30;
        box-shadow: 0px 0px 0px 1px #f30 inset;
      }
    }

    &+& {
      .dropdown__selected-value {
        border-left: 0;
      }
    }

    
    &.is-disabled {
      .dropdown__selected-value {
        position: relative;
        width: 100%;
        height: 48px;
        padding: 0 60px 0 20px;
        background: url(/img/common/ic-dropdown-disabled.png) no-repeat right 20px center;
        background-size: 20px;
        border: 1px solid var(--border01);
        font-size: 14px;
        font-weight: 700;
        line-height: 48px;
        text-align: left;
        color: var(--default);
        z-index: 1;
        transition: all ease-in-out .15s;
      }
    }

    .dropdown__selected-value {
      position: relative;
      width: 100%;
      height: 48px;
      padding: 0 60px 0 20px;
      background: var(--transparent) var(--ic-dropdown) no-repeat right 20px center;
      background-size: 20px;
      border: 1px solid var(--border01);
      font-size: 14px;
      font-weight: 700;
      line-height: 48px;
      text-align: left;
      color: var(--default);
      z-index: 1;
      transition: all ease-in-out .15s;

      &:hover {
        background-image: url(/img/common/ic-dropdown-hover.png);
        color: $color-point;
      }
    }

    &-option {
      display: none;
      overflow-y: scroll;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: $z-index-minimum;
      padding: 12px 0 12px 12px;
      max-height: 380px;
      background: var(--selectbox);
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.14);

      &::-webkit-scrollbar {
        width: 18px;
      }

      &::-webkit-scrollbar-thumb {
        background: #f1f1f4;
        background-clip: padding-box;
        border: 6px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }

    .dropdown__option-item {
      margin-top: 8px;

      &.#{$selectedClass} {
        .dropdown__option-link {
          color: $color-point;
        }

        &:hover {
          .dropdown__option-link {
            color: $color-point;
          }
        }
      }

      &.#{$disabledClass} {
        .dropdown__option-link {
          color: #bbbbbe;
          background: transparent;
          cursor: default;
        }

        &:hover {
          .dropdown__option-link {
            color: #bbbbbe;
            background: transparent;
            cursor: default;
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        .dropdown__option-link {
          background: var(--bgColor02);
        }
      }
    }

    .dropdown__option-link {
      display: block;
      padding: 11px 14px;
      font-weight: 700;
      transition: all ease .15s;
    }

    &+& {
      border-left: 0;
    }

    &.wide {
      width: 380px;
      height: 52px;

      .dropdown__selected-value {
        height: 52px;
        line-height: 52px;
      }
    }
  }
}
