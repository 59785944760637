.#{$component-name} {
  &.tech-main {
    .header.is-hide.transparent {
      transform: none;
    }

    .anchor-section {
      // transition: all .6s;
      background-color: #6b0bc7;
    }

    .anchor-nav {
      .anchor-nav__menu {
        &.is-hide {
          opacity: 1;
  
          .menu__link {
            visibility: visible;
          }
        }
  
        .menu__wrap {
          padding: 193px 0;
        }
      }

      &.#{$fixedClass} {
        .menu__wrap {
          padding: 193px 0;
        }
      }
    }

    .container {
      padding-top: 0;
      padding-bottom: 0;
    }

    .track-animation {
      &__main-area {
        width: 945px;
      }

      &__main-text {
        span {
          &:last-child {
            padding-left: 250px;
          }
        }
      }

      &__highlight-text {
        padding-left: 140px;
        text-align: left;
      }

      &__highlight-area {
        max-width: 1400px;
      }
    }

    .index-section {
      &__inner {
        position: static;
      }

      &__thumb-box {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100vh;
        transform: translateX(240px);
        opacity: 0;
        transition: all .6s;

        &.#{$showClass} {
          opacity: 1;
        }

        &.#{$hideClass} {
          opacity: 0;
        }

        &.#{$fixedClass} {
          position: fixed;
          z-index: 10;
        }

        .video-wrap {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
        }

      }

      &__sub-title {
        em {
          font-weight: 800;
        }
      }
    }

    .js-nav-section.full-screen {
      display: flex;
      overflow: hidden;
      position: relative;
      min-height: 100vh;
      align-items: center;
      justify-content: space-between;
      background: transparent;

      .index-section {
        width: 100%;
      }
    }

    .content02 {
      // background-color: #6b0bc7;

      .index-section {
        .video-wrap {
          background: url(../img/pc/tech-ai-main.png) no-repeat center / cover;
        }
      }
    }

    .content03 {
      // background-color: #51ee35;

      .index-section {
        .video-wrap {
          background: url(../img/pc/tech-search-visual.jpg) no-repeat center / cover;
        }

        &__page-link {
          transition: background .3s;
          
          &:hover {
            color: $color-point;
          }
        }
      }
    }

    .content04 {
      // background-color: #464d82;

      .index-section {
        .video-wrap {
          background: url(../img/pc/tech-robotics-img-1.png) no-repeat center / cover;
        }
      }
    }

    .content05 {
      // background-color: #1d2039;

      .index-section {

        // TODO
        .video-wrap {
          background: url(../img/pc/tech-digital-twin-main.png) no-repeat center / cover;
        }
      }
    }

    .content06 {
      // background-color: #d8d4cc;

      .index-section {

        // TODO
        .video-wrap {
          background: url(../img/pc/tech-gak-chuncheon.jpg) no-repeat center / cover;
        }

        &__page-link {
          transition: background .3s;

          &:hover {
            color: $color-point;
          }
        }
      }
    }

    .content07 {
      // background-color: #9facbe;

      .index-section {

        // TODO
        .video-wrap {
          background: url(../img/pc/tech-1784-visual-img-1.jpg) no-repeat center / cover;
        }
      }
    }

    .content08 {
      // background-color: #0046ff;

      .index-section {

        // TODO
        .video-wrap {
          background: url(../img/pc/tech-ai-main.png) no-repeat center / cover;
        }
      }
    }

    .content09 {
      padding-top: 63px;
      color: $color-white;
      background-color: #060505;

      a {
        color: $color-white;
      }

      .card__sub {
        h4 {
          color: $color-white;
        }
      }
    }
  }
}

@include breakpoint(pc) {
  .#{$component-name} {
    &.tech-main {
      .track-animation {
        &__main-area {
          width: 985px;
        }

        &__main-text {
          span {
            &:last-child {
              padding-left: 289px;
            }
          }
        }

        &__highlight-text {
          padding-left: 96px;
        }

        &__highlight-area {
          max-width: 1135px;
        }
      }

      .index-section {
        &__text-box {
          width: 548px;
        }
        &__thumb-box {
          transform: translateX(160px);
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .#{$component-name} {
    &.tech-main {
      .header.is-hide {
        transform: translateY(-100%);
      }

      .btn-arrow__box {
        display: none;
      }

      .index-section {
        &__text-box {
          width: 400px;
        }

        &__thumb-box {
          left: auto;
          right: 30px;
          width: 47.124%;
          transform: none;
        }
      }


      .anchor-nav {
        .anchor-nav__menu {
          &.#{$hideClass} {
            opacity: 0;
  
            .menu__link {
              visibility: hidden;
            }
          }
  
          .menu__wrap {
            padding: 0;
          }
        }

        &.#{$fixedClass} {
          .menu__wrap {
            padding: 0;
          }
        }
      }

      .track-animation {
        &__main-area {
          width: 790px;
        }

        &__main-text {
          span {
            &:last-child {
              padding-left: 164px;
            }
          }
        }

        &__highlight-area {
          max-width: 817px;
        }

        &__highlight-text {
          padding-left: 6px;
        }
      }

      .index-section {
        &__thumb-box {
          width: 45.313%;
        }
      }

      .content09 {
        padding-top: 60px;
      }
    }
  }
}

@include breakpoint(tablet-small) {
  .#{$component-name} {
    &.tech-main {

      .index-section {
        &__text-box {
          width: 310px;
        }
      }

      .track-animation {
        &__main-area {
          width: 540px;
        }

        &__main-text {
          span {
            &:last-child {
              padding-left: 120px;
            }
          }
        }

        &__highlight-area {
          max-width: 640px;
        }

        &__highlight-text {
          padding-left: 7px;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .#{$component-name} {
    &.tech-main {
      .track-animation {
        &__main-area {
          width: auto;
        }

        &__main-text {
          padding: 0 40px;
          font-size: 38px;
          span {
            &:last-child {
              padding-left: 0;
            }
          }
        }

        &__highlight-text {
          padding: 0 30px;
          text-align: left;
        }
      }

      .index-section {
        &__text-box {
          width: auto;
        }

        &__thumb-box {
          position: static;
          width: 100%;
          height: auto;
          transform: none;
          opacity: 1;

          &.#{$fixedClass} {
            position: static;
            z-index: 10;
          }

          &.#{$hideClass} {
            opacity: 1;
          }

          .video-wrap {
            position: relative;
            transform: none;
          }
        }
      }

      .content02 {
        .index-section {
          .video-wrap {
            background-image: url(../img/mo/tech-ai-main.png);
          }
        }
      }

      .content03 {
        .index-section {
          .video-wrap {
            background-image: url(../img/mo/tech-search-visual.png);
          }
        }
      }

      .content04 {
        .index-section {
          .video-wrap {
            background-image: url(../img/mo/tech-robotics-img-1.png);
          }
        }
      }

      .content05 {
        .index-section {

          // TODO
          .video-wrap {
            background-image: url(../img/mo/tech-digital-twin-main.jpg);
          }
        }
      }

      .content06 {
        .index-section {

          // TODO
          .video-wrap {
            background-image: url(../img/mo/tech-gak-chuncheon.jpg);
          }
        }
      }

      .content07 {
        .index-section {

          // TODO
          .video-wrap {
            background-image: url(../img/mo/tech-1784-visual-img-1.jpg);
          }
        }
      }

      .content08 {
        .index-section {

          // TODO
          .video-wrap {
            background-image: url(../img/mo/tech-ai-main.png);
          }
        }
      }

      .content09 {
        padding-top: 36px;
      }
    }
  }
}
